import React from 'react';

import Stack from '@mui/material/Stack';
import {TRACKER_TYPES_CONFIG} from 'config/trackers.config';
import TrackerExecutorTypeToggleButton from './tracker-executor-type-toggle-button';
import {TTrackerExecutorType} from '@my-game-plan/types';

function TrackerExecutorTypeToggle(): JSX.Element {
  /*
   * Hooks n State
   */

  /*
   * Side Effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      {Object.keys(TRACKER_TYPES_CONFIG).map((type) => {
        const _type = type as TTrackerExecutorType;
        return <TrackerExecutorTypeToggleButton key={type} type={_type} />;
      })}
    </Stack>
  );
}

export default TrackerExecutorTypeToggle;
