import {PropsWithChildren, useEffect, useState} from 'react';

import {
  findConcreteObserverIfPossible,
  findInitialExecutorIndex,
  formatDataBeforeSaving,
  isInitialExecutorStillActive,
  validateDataBeforeSaving,
  validateDataOnChange,
} from 'helpers/automation/automation-form.helper';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {TAutomationErrors} from 'types/sentence-form.types';

import {
  createContextHook,
  createCustomContext,
  createProvider,
} from 'helpers/general/context_generators.helper';

import {useSnackbar} from 'notistack';

import {
  FIEventAutomationPostData,
  FIFormattedAutomation,
  isTrackingAction,
  TEventCalculationType,
  TSequence,
  TTrackerExecutorType,
} from '@my-game-plan/types';

import {
  DEFAULT_ERROR_STATE,
  EVENT_AUTOMATION_SKELETONS,
} from 'config/event-automation-builder.config';
import {cloneDeep} from 'lodash';
import {useTeams} from '../team.context';
import {useAnalytics} from '../analytics.context';
import ANALYTICS_EVENT from 'config/analytics/event-names.config';
import {
  generateStatisticDeepDiveURL,
  generateStatisticDeepdiveURLPrefix,
} from 'helpers/automation/automation.helper';

import {
  createTracker,
  deleteTracker,
  editTracker,
} from 'controllers/trackers.controller';
import {ILinkState} from 'types/navigation.types';

export interface AutomationFormAPI {
  initForm: (
    type?: TTrackerExecutorType,
    data?: Partial<FIEventAutomationPostData>,
    optionalInfo?: Partial<FIEventAutomationPostData>,
  ) => void;
  clearForm: (clearEverything?: boolean) => void;
  data: Partial<FIEventAutomationPostData>;
  setData: (
    data: Partial<FIEventAutomationPostData>,
    overrideAllFields?: boolean,
  ) => void;
  onCalculationTypeChange: (type: TEventCalculationType | TSequence) => void;
  isNewTracker: boolean;
  isLoading: boolean;
  isOpened: boolean;
  isValid: boolean;
  setIsOpened: (open: boolean) => void;
  saveAutomation: () => void;
  deleteAutomation: (id: string) => Promise<void>;
  errors: TAutomationErrors;
  changeCount: number;
  resetChangeCount: () => void;

  currentExecutorType: TTrackerExecutorType;
  setCurrentExecutorType: (type: TTrackerExecutorType) => void;
  concreteExecutorIndex: number;
  setConcreteExecutorIndex: (index: number) => void;

  previewingTeamLogo?: string;
}

const context = createCustomContext<AutomationFormAPI>();
export const useAutomationForm = createContextHook(context);

interface IAutomationFormProviderProps {
  onSubmit?: (
    savedAutomation: FIFormattedAutomation | null,
    wasDeleted?: boolean,
  ) => void;
  backLink?: ILinkState;
}
export const AutomationFormProvider = (
  props: PropsWithChildren<IAutomationFormProviderProps>,
): JSX.Element => {
  const [_isOpened, _setIsOpened] = useState<boolean>(false);
  const [_isLoading, _setIsLoading] = useState<boolean>(false);
  const [_data, _setData] = useState<Partial<FIEventAutomationPostData>>({});
  const [_errors, _setErrors] =
    useState<TAutomationErrors>(DEFAULT_ERROR_STATE);
  const [_isValid, _setIsValid] = useState<boolean>(false);
  const [_changeCount, _setChangeCount] = useState(0);
  const [_isNewTracker, _setIsNewTracker] = useState<boolean>(false);

  const [_initialExecutorType, _setInitialExecutorType] =
    useState<TTrackerExecutorType>('team_performance');
  const [_currentExecutorType, _setCurrentExecutorType] =
    useState<TTrackerExecutorType>('team_performance');
  const [_concreteExecutorIndex, _setConcreteExecutorIndex] =
    useState<number>(-1);
  const [_previewingTeamLogo, _setPreviewingTeamLogo] = useState<
    string | undefined
  >(undefined);

  const _teamsContext = useTeams();
  const _analyticsContext = useAnalytics();
  const _location = useLocation();

  const _navigate = useNavigate();

  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  // Define valid state
  useEffect(() => {
    let _newValidState = true;

    const _isSuccessDetailsInvalid =
      _data.calculation === 'ratio' && !_data.success_details?.filters?.length;

    if (!_data.action || _isSuccessDetailsInvalid) {
      _newValidState = false;
    }
    _setIsValid(_newValidState);
  }, [_data]);

  // Set default previewing executor
  useEffect(() => {
    let _newConcreteExecutorIndex = findInitialExecutorIndex(
      _data,
      _currentExecutorType,
    );

    const _executors = _data[_currentExecutorType];

    if (_executors?.length && _newConcreteExecutorIndex === -1) {
      _newConcreteExecutorIndex = _executors.length - 1;
    }

    _setConcreteExecutorIndex(_newConcreteExecutorIndex);
  }, [_currentExecutorType, _data]);

  // Set image_url of the team for pitch zone preview
  useEffect(() => {
    if (!_teamsContext.ownTeam) {
      _setPreviewingTeamLogo(undefined);
    }

    let _newImageURL = _teamsContext.ownTeam?.image_url;
    if (_currentExecutorType === 'opponent_analysis') {
      const _opponent = _teamsContext.all.find(
        (t) => t._id === _teamsContext.currentlyObservingOpponentId,
      );
      _newImageURL = _opponent?.image_url;
    }

    _setPreviewingTeamLogo(_newImageURL);
  }, [
    _teamsContext.ownTeam,
    _teamsContext.currentlyObservingOpponentId,
    _currentExecutorType,
    _teamsContext.all,
  ]);

  /*
   * Handlers
   */
  async function _onChange(
    data: Partial<FIEventAutomationPostData>,
    overrideAllFields = false,
  ) {
    if (overrideAllFields) {
      _setData(data);
      return;
    }
    _setChangeCount(_changeCount + 1);

    /* Update data */
    const _newData: Partial<FIEventAutomationPostData> = {
      ..._data,
      ...data,
    };

    _setData(_newData);

    // console.log('------------------------------');
    // console.log('[BuilderFormContext onChange]');
    // console.log('-- changed data:', data);
    // console.log('-- new data:', _newData);
    // console.log('------------------------------');

    /* Error handling */
    const _newErrors = validateDataOnChange(_newData, _errors);

    _setErrors(_newErrors);
  }

  function _onCalculationTypeChange(value: TEventCalculationType | TSequence) {
    const _postData: Partial<FIEventAutomationPostData> = {
      calculation: value === 'sequence' ? 'occurrences' : value,
    };

    if (value === 'ratio' && _data.action && isTrackingAction(_data.action)) {
      _postData.action = undefined;
    }

    if (value === 'ratio' && _data.details) {
      _postData.details = undefined;
      _postData.success_details = _data.details;
    } else if (value === 'occurrences') {
      if (!_data.details) {
        _postData.details = _data.success_details;
      }
    }

    if (value !== 'ratio') {
      _postData.success_details = undefined;
    }

    /* Rule logic */
    if (value === 'rule') {
      _postData.success_details = undefined;
      _postData.rule_condition = {
        type: 'rule',
        seconds: 5,
        against: false,
        executor: {},
      };
    } else {
      _postData.rule_condition = undefined;
    }

    /* Sequence logic */
    if (value === 'sequence') {
      _postData.action = 'sequence';
      _postData.sequence_actions = [
        {
          action: _data.action,
          details: _data.details,
        },
      ];
      _postData.conditions = undefined;
    }

    if (_data.action === 'sequence') {
      // When changing from a sequence action, sequence_actions must be removed
      _postData.sequence_actions = undefined;
      _postData.action = undefined;

      if (_data.sequence_actions?.length) {
        _postData.action = _data.sequence_actions[0].action;
        _postData.details = _data.sequence_actions[0].details;
      }
    }

    _onChange({
      ..._postData,
    });
  }

  function _validate(data: Partial<FIEventAutomationPostData>): boolean {
    const _newErrors = validateDataBeforeSaving(data);
    _setErrors(_newErrors);
    // console.log('[TrackerBuilderContext] VALIDATING...', _newErrors);
    return Object.keys(_newErrors).length === 0;
  }

  function _initForm(
    type?: TTrackerExecutorType,
    data?: Partial<FIEventAutomationPostData>,
    optionalInfo?: Partial<FIEventAutomationPostData>,
  ) {
    _setIsOpened(true);
    _setIsNewTracker(!data);

    const _defaultType: TTrackerExecutorType = type || 'team_performance';
    _setCurrentExecutorType(_defaultType);
    _setInitialExecutorType(_defaultType);

    if (type) {
      if (data) {
        _setData(data);
      } else {
        const _skeleton = {
          ...cloneDeep(EVENT_AUTOMATION_SKELETONS[type]),
          ...optionalInfo,
        };

        _setData(_skeleton);
      }
    }
  }

  function _clearForm(clearAll?: boolean) {
    _setIsValid(false);
    _setErrors(DEFAULT_ERROR_STATE);
    _setData({});
    _setIsNewTracker(true);

    // _setWithTarget(true);

    if (clearAll) {
      _setCurrentExecutorType('team_performance');
      _setInitialExecutorType('team_performance');
    }
  }

  async function _deleteAutomation(id: string) {
    // Delete
    try {
      await deleteTracker(id);

      if (props.onSubmit) {
        props.onSubmit(null, true);
      }

      enqueueSnackbar(t('eventAutomations.delete.success'), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t('eventAutomations.delete.error.title'), {
        variant: 'error',
      });
    }
  }

  async function _saveAutomation() {
    // console.log('[TrackerBuilderContext] SAVING...', _data);
    if (!_teamsContext.ownTeam) {
      return null;
    }
    _setChangeCount(0);

    // Format data, remove obsolete fields
    const _trackerToSave = formatDataBeforeSaving(
      _data,
      _teamsContext.ownTeam._id,
      _teamsContext.preferences?.tags,
    );

    // Set concrete executor
    const _initialConcreteExecutor = _data.concrete_executor;
    const _defaultConcreteExecutor = findConcreteObserverIfPossible(
      _data,
      _currentExecutorType,
      _concreteExecutorIndex,
      _teamsContext.ownTeam._id,
      _teamsContext.currentlyObservingOpponentId,
    );

    // Check if initial executor is still in data, to determin wheter we should navigate to new deep dive
    let _isInitialExecutorStillActive = false;
    if (_initialExecutorType !== 'scouting_profile_ids') {
      _isInitialExecutorStillActive = isInitialExecutorStillActive(
        _trackerToSave,
        _initialExecutorType,
        _initialConcreteExecutor,
      );
    }
    if (
      (!_trackerToSave.concrete_executor || !_isInitialExecutorStillActive) &&
      _defaultConcreteExecutor.executor
    ) {
      _trackerToSave.concrete_executor = _defaultConcreteExecutor.executor;
    }

    // Init data
    let _tracker: FIFormattedAutomation | null = null;
    const _isCreatingNewAutomation = !_data._id;

    // Valdiate
    const _isValidLocal = _validate(_data);

    if (!_isValidLocal) {
      return null;
    }

    try {
      _setIsLoading(true);

      /* Save to backend */
      if (_isCreatingNewAutomation) {
        _tracker = await createTracker(_trackerToSave);
      } else {
        _tracker = await editTracker(_trackerToSave);
      }

      /*  Mixpanel event */
      const _event = _isCreatingNewAutomation
        ? ANALYTICS_EVENT.CREATED_TRACKER
        : ANALYTICS_EVENT.EDITED_TRACKER;
      _analyticsContext.trackEvent(_event, {
        id: _tracker._id,
        statistic_id: _tracker.statistic_id,
        action: _tracker.action,
        calculation: _tracker.calculation,
        player_development_count: _tracker.player_development?.length,
        team_performance_count: _tracker.team_performance?.length,
        opponent_analysis_count: _tracker.opponent_analysis?.length,
        scouting_profiles_count: _tracker.scouting_profile_ids?.length,
      });

      /* Display snackbar when editing */
      if (!_isCreatingNewAutomation) {
        enqueueSnackbar(t('eventAutomations.edit.success.title'), {
          variant: 'success',
        });
      }
      _setIsLoading(false);

      // console.log(
      //   '[TrackerBuilderContext] SAVED ✅ new tracker?',
      //   _isCreatingNewAutomation,
      //   _tracker,
      // );

      /*
       * Refresh data where needed
       */
      _teamsContext.getOwnTeam();

      _setIsOpened(false);
      if (props.onSubmit) {
        props.onSubmit(_tracker);
      }

      /* Redirect */
      // console.log('[TrackerBuilderContext] REDIRECTING...');
      /* Redirect, set navigation state for better backlinks where possible */
      const _urlPrefix = generateStatisticDeepdiveURLPrefix(
        _defaultConcreteExecutor.executorType,
      );

      const _redirectURL = generateStatisticDeepDiveURL(
        _tracker,
        _defaultConcreteExecutor.subject_id,
        _location.search,
        _urlPrefix,
      );
      // console.log('REDIRECTING TO', _redirectURL);

      _navigate(_redirectURL, {
        replace: !_isInitialExecutorStillActive,
        state: props.backLink,
      });
    } catch (error) {
      const _error = (error as Error).message || t('error-states.default');
      enqueueSnackbar(_error, {
        variant: 'error',
      });
      _setIsLoading(false);
    }

    // return null;
  }

  return createProvider(context, props, {
    clearForm: _clearForm,
    initForm: _initForm,
    data: _data,
    setData: _onChange,
    onCalculationTypeChange: _onCalculationTypeChange,
    isLoading: _isLoading,
    isOpened: _isOpened,
    isValid: _isValid,
    setIsOpened: _setIsOpened,
    saveAutomation: _saveAutomation,
    deleteAutomation: _deleteAutomation,
    errors: _errors,
    changeCount: _changeCount,
    resetChangeCount: () => _setChangeCount(0),
    isNewTracker: _isNewTracker,

    currentExecutorType: _currentExecutorType,
    setCurrentExecutorType: _setCurrentExecutorType,
    concreteExecutorIndex: _concreteExecutorIndex,
    setConcreteExecutorIndex: _setConcreteExecutorIndex,
    previewingTeamLogo: _previewingTeamLogo,
  });
};
