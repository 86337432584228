import i18n from 'localization';
import {
  FIFormattedAutomationPlayerScore,
  getDecimalsForUnit,
  IPhysicalCategoryData,
} from '@my-game-plan/types';
import {formatSingleScore} from './automation/automation-detail-history.helper';
import {IHistoricalTrendLine} from 'components/common/charts/historical-trend.view';
import {Palette} from '@mui/material';

export function getPhysicalStatsTrendLines(
  category: IPhysicalCategoryData,
  isBrokenDown: boolean,
  palette: Palette,
): IHistoricalTrendLine[] {
  const _unitTranslation = i18n.t(
    `physical.units.${category.unit || 'default_90'}`,
  );

  const _lineConfigs: IHistoricalTrendLine[] = [];

  let _values: (keyof IPhysicalCategoryData)[][] = [
    ['benchmark_average', 'average'],
  ];
  let _colors: string[][] = [[palette.warning.dark, palette.common.white]];
  if (isBrokenDown) {
    _values = [
      ['benchmark_average_tip', 'average_tip'],
      ['benchmark_average_otip', 'average_otip'],
    ];

    _colors = [
      [palette.text.secondary, palette.common.white],
      [palette.info.dark, palette.info.main],
    ];
  }

  _values.forEach((group, groupIndex) => {
    const _scores: FIFormattedAutomationPlayerScore[] = [];
    group.forEach((value, valueIndex) => {
      let _diff: number | undefined = undefined;
      const _val = category[value];
      if (valueIndex === 1 && category[value]) {
        const _benchmarkVal = category[group[0]];
        if (typeof _benchmarkVal === 'number' && typeof _val === 'number') {
          _diff = _val - _benchmarkVal;
        }
      }
      const _decimals = getDecimalsForUnit(category.unit);
      const _key: keyof IPhysicalCategoryData =
        valueIndex === 1 ? 'average' : 'benchmark_average';
      const _score = formatSingleScore<IPhysicalCategoryData>(
        _key,
        i18n.t(`players.physical.${_key}`),
        typeof _val === 'number' ? _val : undefined,
        _diff,
        undefined,
        _unitTranslation,

        false,
        _decimals,
      );
      if (_score) _scores.push(_score);
    });

    /* Define config for chart */

    const _key = group[1];
    let _keySuffix = '';
    if (_key.includes('otip')) {
      _keySuffix = '_otip';
    } else if (_key.includes('tip')) {
      _keySuffix = '_tip';
    }

    const _benchmarkValue =
      category[`benchmark_average${_keySuffix}` as keyof IPhysicalCategoryData];

    const _lineConfig: IHistoricalTrendLine = {
      key: `${category.category}${_keySuffix}`,
      name: i18n.t(`physical.${category.category}`),
      color: _colors[groupIndex][1],
      benchmark:
        typeof _benchmarkValue !== 'undefined'
          ? {
              key: `benchmark_${category.category}${_keySuffix}`,
              color: _colors[groupIndex][0],
              value: _benchmarkValue as number,
            }
          : undefined,
      scores: _scores,
    };
    _lineConfigs.push(_lineConfig);
  });

  return _lineConfigs;
}
