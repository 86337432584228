import MuiDrawer from '@mui/material/Drawer';
import {NAV_SIDEBAR_WIDTH_EXPANDED} from 'config/navigation.config';
import {CSSObject, darken, styled, Theme} from '@mui/material';

export const sidebarOpenedMixin = (theme: Theme): CSSObject => ({
  width: NAV_SIDEBAR_WIDTH_EXPANDED,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

export const sidebarClosedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({theme}) => {
  const _backgroundColor = darken(theme.palette.background.default, 0.4);
  return {
    width: NAV_SIDEBAR_WIDTH_EXPANDED,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': {
      borderRight: 'none',
      backgroundColor: _backgroundColor,
    },
    variants: [
      {
        props: ({open}) => open,
        style: {
          ...sidebarOpenedMixin(theme),
          '& .MuiDrawer-paper': sidebarOpenedMixin(theme),
        },
      },
      {
        props: ({open}) => !open,
        style: {
          ...sidebarClosedMixin(theme),
          '& .MuiDrawer-paper': sidebarClosedMixin(theme),
        },
      },
    ],
  };
});
