import i18n from '../../localization';

import {IGroupedTypedOptions, ITypedOption} from 'types/option.types';

import {
  ACTION,
  EVENT_TYPES,
  EventType,
  FIConditionalEventFilters,
  FIPlayer,
  GAME_STATES,
  generateActionConfigs,
  IActionConfigDetails,
  FIEventFilters,
  ITeamFormations,
  PlayerPosition,
  POSITION_IN_MATCHES,
  POSITIONS_PER_LINE,
  TDataProvider,
  TEventAutomationType,
  TPositionGroup,
  IAvailableMetrics,
  FIEventAutomationPostData,
  TTrackerExecutorType,
  FITrackerAbstractExecutor,
  FITag,
  FITrackerSubject,
  isTrackingAction,
  FITrackerConcreteExecutor,
  FIEventCondition,
  FISequenceAction,
  FIFormattedAutomation,
} from '@my-game-plan/types';

import {
  translateAction,
  translateGeneralFilterValue,
} from '../translation.helper';

import {
  DEFAULT_ERROR_STATE,
  TRANSFERED_PLAYER,
} from 'config/event-automation-builder.config';
import {capitalize} from 'lodash';
import {TAutomationErrors, TError} from 'types/sentence-form.types';
import {enqueueSnackbar} from 'notistack';
import {EXECUTOR_TYPES} from 'config/trackers.config';

export function removeEmptyFilters(
  filters: FIConditionalEventFilters,
  returnUndefinedIfNecessary = false,
): FIConditionalEventFilters | undefined {
  const _filteredParams: FIConditionalEventFilters = {
    ...filters,
    filters: filters.filters.filter(
      (selectedFilter) =>
        selectedFilter.values && selectedFilter.values.length > 0,
    ),
  };

  const _hasFilters = _filteredParams.filters.length > 0;

  if (!_hasFilters && returnUndefinedIfNecessary) return undefined;

  return _filteredParams;
}

export function removeEmptyExecutor(
  subjects: FITrackerSubject[] | undefined,
): FITrackerSubject[] | undefined {
  if (!subjects?.length) return undefined;

  const _filteredSubjects = subjects.filter((subject) => {
    return Boolean(subject.executor);
  });

  _filteredSubjects.forEach((subject) => {
    if (!subject.executor.team?.executor_positions?.length) {
      delete subject.executor.team?.executor_positions;
    }
  });

  return _filteredSubjects;
}

/*
 * Players Options
 */

export function getPlayerOptions(
  players: FIPlayer[],
  transferredPlayerOptions?: ITypedOption<string>[],
): ITypedOption<string>[] {
  const _groups = Object.keys(POSITIONS_PER_LINE);
  const _sortedPlayers = players.sort((a, b) =>
    a.last_name.localeCompare(b.last_name),
  );
  const _options: ITypedOption<string>[] = _sortedPlayers.map((player) => {
    let _group: TPositionGroup = 'defender';
    for (const [key, value] of Object.entries(POSITIONS_PER_LINE)) {
      if (value.positions.includes(player.position[0])) {
        _group = key as TPositionGroup;
      }
    }
    const _option: ITypedOption<string> = {
      label: player.display_name,
      value: player._id,
      name: player.name,
      group: _group,
    };

    return _option;
  });
  const _sortedOptions = _options.sort(
    (a, b) => _groups.indexOf(a.group || '') - _groups.indexOf(b.group || ''),
  );

  // If transferred players were passed, add options to uncheck
  if (transferredPlayerOptions) {
    _sortedOptions.unshift(...transferredPlayerOptions);
  }

  return _sortedOptions;
}

export function groupPlayerOptions(
  players: FIPlayer[],
  renderGroupTitle?: (value: string) => string,
  transferredPlayerOptions?: ITypedOption<string>[],
) {
  const _options = getPlayerOptions(players, transferredPlayerOptions);

  const _groups: IGroupedTypedOptions<string>[] = [];

  // Map players. If group exists, add player, else create group
  _options.forEach((option) => {
    if (!option.group) {
      return;
    }

    const _groupIndex = _groups.findIndex(
      (group) => group.value === option.group,
    );
    if (_groupIndex > -1) {
      _groups[_groupIndex].options.push(option);
    } else {
      _groups.push({
        value: option.group,
        label: renderGroupTitle ? renderGroupTitle(option.group) : option.group,
        options: [option],
      });
    }
  });

  return _groups;
}

/*
 * Positions Options
 */
/* Generate options for autocomplete */
export function getPositionOptions(): ITypedOption<PlayerPosition>[] {
  const _formattedPositions: ITypedOption<PlayerPosition>[] = [];
  Object.keys(POSITIONS_PER_LINE).forEach((line) => {
    const _positionGroup = line as TPositionGroup;
    // /* Generate "Any X" or "All X" option */
    // const _allOption: ITypedOption<PlayerPosition> = {
    //   label: i18n.t(`playerPosition.${_positionGroup}sAny`),
    //   value: _positionGroup,
    //   group: _positionGroup,
    //   name: i18n.t(`playerPosition.${_positionGroup}sAny`),
    // };
    // if (POSITIONS_PER_LINE[_positionGroup].positions.length > 1) {
    //   _formattedPositions.push(_allOption);
    // }

    /* For each position of line, create an option too */
    POSITIONS_PER_LINE[_positionGroup].positions.forEach((position) => {
      const _option: ITypedOption<PlayerPosition> = {
        label: position,
        value: position,
        name: `${_positionGroup}-${position}`,
        group: _positionGroup,
      };

      _formattedPositions.push(_option);
    });
  });

  return _formattedPositions;
}

/* Transform value: convert PlayerPosition[] to group if necessary  */
function getPositionGroupLabel(group: string, returnAsAny?: boolean) {
  let _groupLabel = i18n.t(`playerPosition.${group}s`);

  if (returnAsAny) {
    _groupLabel = i18n.t(`playerPosition.${group}sAny`);
  }

  return _groupLabel;
}
export function getSelectedPositionOptions(
  selectedPositions: string[],
  returnAsOptions = true,
  groupItems = false,
  shouldCapitalize = false,
  returnAsAny?: boolean,
): ITypedOption<string>[] | string[] {
  const _allOptions = getPositionOptions();

  const _selectedGroups: string[] = [];
  const _indexesToRemove: number[] = [];

  /* If all items of a group are selected, return the group name */
  Object.keys(POSITIONS_PER_LINE).forEach((group) => {
    const _group = group as TPositionGroup;
    const _groupPositions = POSITIONS_PER_LINE[_group].positions;
    const _allItemsAreSelected = _groupPositions.every((position) =>
      selectedPositions.includes(position),
    );

    if (_allItemsAreSelected && _groupPositions.length > 1) {
      /* Add group to selected items */
      _selectedGroups.push(group);

      /* Remove positions, because entire group should be selected */
      _groupPositions.forEach((position) => {
        const _indexInSelectedPositions = selectedPositions.findIndex(
          (selectedPosition) => selectedPosition === position,
        );
        if (_indexInSelectedPositions > -1) {
          _indexesToRemove.push(_indexInSelectedPositions);
        }
      });
    }
  });

  /* Remove positions that are in _indexesToRemove, replace them with group options */
  const _filteredPositionsWithoutGroup = selectedPositions.filter(
    (selectedPositions, index) => _indexesToRemove.indexOf(index) === -1,
  );

  if (returnAsOptions) {
    /* Return objects */
    const _groupOptions: ITypedOption<string>[] = _selectedGroups.map(
      (group) => {
        let _groupLabel = getPositionGroupLabel(group, returnAsAny);
        if (shouldCapitalize) {
          _groupLabel = capitalize(_groupLabel);
        }
        return {
          label: _groupLabel,
          value: group,
          name: _groupLabel,
          group: group,
        };
      },
    );

    const _positionsToReturn = groupItems
      ? [..._filteredPositionsWithoutGroup]
      : [...selectedPositions];

    const _optionsToReturn: ITypedOption<string>[] = groupItems
      ? [..._groupOptions]
      : [];
    _positionsToReturn.forEach((position) => {
      const _matchingOption = _allOptions.find(
        (option) => option.value === position,
      );
      if (_matchingOption) {
        _optionsToReturn.push(_matchingOption);
      }
    });

    return _optionsToReturn;
  }

  const _selectedGroupLabels = _selectedGroups.map((group) => {
    let _groupLabel = getPositionGroupLabel(group, returnAsAny);
    if (shouldCapitalize) {
      _groupLabel = capitalize(_groupLabel);
    }
    return _groupLabel;
  });
  const _formattedPositions = [
    ..._selectedGroupLabels,
    ..._filteredPositionsWithoutGroup,
  ];

  /* Return strings for values */
  return _formattedPositions;
}

export function handlePositionOptionChange(
  data: ITypedOption<string>[],
): PlayerPosition[] {
  const _selectedValues: string[] = data.map((option) => option.value);

  // return _selectedValues as PlayerPosition[];
  // const _lastSelectedItem = _selectedValues.at(-1);
  const _topLevelGroupNames = Object.keys(POSITIONS_PER_LINE);

  // const _selectedGroupIndexes: string[] = [];
  const _groupIndexesToRemove: number[] = [];

  // const _positionsWithoutHighLevelCheckboxes: PlayerPosition[] = [];

  /* Filter array of positions */
  _selectedValues.forEach((selectedValue, index) => {
    /* Check if "All XXX" has been checked */
    const _indexOfGroupOption = _topLevelGroupNames.findIndex(
      (groupValue) => groupValue === selectedValue,
    );

    /* If so, save the groupIndex to add all needed positions */
    /* and add position index to filter later on */
    if (_indexOfGroupOption > -1) {
      // _selectedGroupIndexes.push(selectedValue);
      _groupIndexesToRemove.push(index);
    }
  });

  // /* Filter out top level items: eg. "All defenders" */
  const _positionsWithoutHighLevelCheckboxes = _selectedValues.filter(
    (value, index) => !_groupIndexesToRemove.includes(index),
  );

  return _positionsWithoutHighLevelCheckboxes as PlayerPosition[];
}

export function getSelectedValuesInSentence(
  values: ITypedOption<string | number>[],
): string {
  let _allValues = '';
  values.forEach((position, index) => {
    _allValues += position.label;
    if (index === values.length - 2) {
      _allValues += ` ${i18n.t('sentenceForm.or')} `;
    } else if (index < values.length - 1) {
      _allValues += ', ';
    }
  });

  return _allValues;
}

/*
 * Generate options for topic dropdown
 */
export const actionConfigsToFormOptions = (
  dataProvider: TDataProvider | undefined,
  available_metrics: IAvailableMetrics | undefined,
  forTrackers = false,
  count = 2,
  capitalze = false,
): ITypedOption<ACTION>[] => {
  const _optionsToReturn: ITypedOption<ACTION>[] = [];
  const _groups = EVENT_TYPES.sort((a, b) => b.localeCompare(a));

  const _actionsConfig = generateActionConfigs(dataProvider, available_metrics);

  Object.keys(_actionsConfig).forEach((action) => {
    const _action = action as ACTION;
    const _actionConfig = _actionsConfig[_action];
    if (_actionConfig) {
      const _shouldIncludeAction =
        !forTrackers || !_actionConfig.isOpponentAction;
      if (_shouldIncludeAction) {
        let _translatedAction = translateAction(_action, count);
        if (capitalze) {
          _translatedAction = capitalize(_translatedAction);
        }
        _optionsToReturn.push({
          label: _translatedAction,
          value: _action,
          name: translateAction(_action, count),
          group: _actionConfig.parentAction,
        });
      }
    }
  });

  const _sortedOptions = _optionsToReturn.sort((a, b) => {
    const _nameSort = a.value.localeCompare(b.value);
    const _groupSort =
      _groups.indexOf((b.group as EventType) || '') -
      _groups.indexOf((a.group as EventType) || '');

    return _groupSort || _nameSort;
  });

  return _sortedOptions;
};

export function translateAutomationType(
  type: TEventAutomationType,
  count = 0,
  short?: boolean,
  lowerCase?: boolean,
): string {
  let _key =
    type === 'opponent-automation' ? 'opponentAutomations' : 'trackers';

  if (short) {
    _key += 'Short';
  }

  const _translation = i18n.t(`eventAutomations.${_key}`, {count: count});

  if (lowerCase) return _translation.toLowerCase();

  return _translation;
}

/*
 * Generate general match details
 */
export function generateGeneralMatchDetails(
  formations: ITeamFormations,
): IActionConfigDetails {
  return {
    'opponent_team.defence': formations.against_defence.map(
      (defence) => defence.label,
    ),
    'team.formation': formations.own.map((formation) => formation.label),
    position_in_match: POSITION_IN_MATCHES,
    time_block: ['0', '1', '2', '3', '4', '5', '6', '7', '8'],
    // 'game_state': GAME_STATE_VALUE_OPTIONS
    game_states: GAME_STATES,
  };
}

export function getGeneralMatchFilterOptions(
  key: keyof FIEventFilters,
  values: string[],
  shouldReturnOptions = true,
): ITypedOption<string>[] | string[] {
  const _options: ITypedOption<string>[] = values.map((value) => {
    const _label = translateGeneralFilterValue(key, value);

    return {
      label: _label,
      value: value,
    };
  });

  if (!shouldReturnOptions) return _options.map((option) => option.label);

  return _options;
}

/* Errors - Transferred players in automation */
export function generateTransferredPlayerOption(
  id: string,
): ITypedOption<string> {
  return {
    value: id,
    name: TRANSFERED_PLAYER,
    label: i18n.t('playerPosition.transferredPlayer'),
    group: 'transferredPlayer',
  };
}

/* Check if observing field is actually a share field */
export function isShareField(field?: string | number | symbol): boolean {
  return field === 'shared_with_players';
}

// Find concrete observer
export function findConcreteObserverIfPossible(
  data: Partial<FIEventAutomationPostData> | FIFormattedAutomation,
  currentExecutorType: TTrackerExecutorType,
  concreteExecutorIndex: number,
  ownTeamId?: string,
  observingOpponentTeamId?: string,
): {
  executor: FITrackerConcreteExecutor | null;
  executorType: TTrackerExecutorType;
  subject_id: string;
} {
  const _lastEditedValue = data[currentExecutorType]?.[concreteExecutorIndex];

  let _concreteExecutorType = currentExecutorType;
  if (!_lastEditedValue) {
    const _executorTypesWithData = EXECUTOR_TYPES.filter(
      (executorType) => data[executorType]?.length,
    );
    const _executorTypesWithOneEntry = _executorTypesWithData.filter(
      (executorType) => data[executorType]?.length === 1,
    );

    _concreteExecutorType =
      _executorTypesWithOneEntry[0] || _executorTypesWithData[0];

    if (
      _executorTypesWithOneEntry.length === 1 &&
      _executorTypesWithData.length === 1
    ) {
      _concreteExecutorType = _executorTypesWithOneEntry[0];
    }
  }

  if (_concreteExecutorType === 'scouting_profile_ids') {
    return {
      executor: null,
      executorType: _concreteExecutorType,
      subject_id: '',
    };
  }
  const _concreteSubject =
    data[_concreteExecutorType]?.[concreteExecutorIndex] ||
    data[_concreteExecutorType]?.[0];
  if (!_concreteSubject) {
    return {
      executor: null,
      executorType: _concreteExecutorType,
      subject_id: '',
    };
  }

  // const _executor: FITrackerConcreteExecutor = _concreteSubject.executor;
  if (_concreteExecutorType === 'player_development') {
    const _executor: FITrackerConcreteExecutor = {
      player_id: _concreteSubject.executor.player_id,
    };

    return {
      executor: _executor,
      executorType: _concreteExecutorType,
      subject_id: _concreteSubject.executor.player_id || '',
    };
  }

  const _teamId =
    _concreteExecutorType === 'team_performance'
      ? ownTeamId
      : observingOpponentTeamId;
  const _executor: FITrackerConcreteExecutor = {
    team: {
      _id: _teamId || '',
      executor_positions: _concreteSubject.executor.team?.executor_positions,
    },
  };

  return {
    executor: _executor,
    executorType: _concreteExecutorType,
    subject_id: '',
  };
}

export function getSingleExecutorTypeWithOneSubject(
  data: Partial<FIEventAutomationPostData> | FIFormattedAutomation,
) {
  let foundProp: TTrackerExecutorType | null = null;
  let foundValue: FITrackerSubject | string | null = null;

  for (const prop of EXECUTOR_TYPES) {
    const value = data[prop];

    if (Array.isArray(value) && value.length === 1) {
      if (foundProp) {
        // More than one property has a single entry
        return null;
      }
      foundProp = prop;
      foundValue = value[0];
    }
  }

  if (!foundProp) return null;

  return foundProp === 'scouting_profile_ids'
    ? {property: foundProp, value: foundValue as string}
    : {property: foundProp, value: foundValue as FITrackerSubject};
}

export function formatSingleExecutor(
  executor: FITrackerAbstractExecutor,
  ownPlayers: FIPlayer[],
  maxItems = 3,
) {
  // Format player is necessary
  if (executor.player_id) {
    const _selectedPlayer = ownPlayers.find(
      (player) => player._id === executor.player_id,
    );
    const _formattedValue = _selectedPlayer?.display_name || 'PLAYER?';
    return _formattedValue;
  }

  let _formattedValue = i18n.t('sentenceForm.players.any');

  if (executor.team?.executor_positions?.length) {
    let _hiddenItemsCount = 0;
    let _positions = getSelectedPositionOptions(
      executor.team.executor_positions,
      false,
      true,
      false,
      true,
    ) as string[];
    if (_positions.length > maxItems && maxItems > 0) {
      _hiddenItemsCount = _positions.length - maxItems;
      _positions = _positions.slice(0, maxItems);
    }
    _formattedValue = _positions.join(', ');
    if (_hiddenItemsCount) {
      _formattedValue += ` +${_hiddenItemsCount}`;
    }
  }

  return _formattedValue;
}

/*
 * Format and clean up data before saving
 */
export function formatDataBeforeSaving(
  data: Partial<FIEventAutomationPostData>,
  teamId: string,
  teamTags?: FITag[],
): Partial<FIEventAutomationPostData> {
  /* INIT */
  const _formattedData: Partial<FIEventAutomationPostData> = {
    ...data,
    team_id: teamId,
  };

  /* TAGS: If new tag is passed, pass them as `newTags` */
  if (_formattedData.tags && _formattedData.tags.length > 0) {
    _formattedData.newTags = teamTags
      ? _formattedData.tags.filter(
          (tag) => !teamTags.some((t) => t.label == tag || t._id == tag),
        )
      : _formattedData.tags;
    _formattedData.tags = _formattedData.tags
      .map((tag) =>
        teamTags
          ? teamTags.find((t) => t.label == tag || t._id == tag)
          : undefined,
      )
      .filter((tag) => typeof tag !== 'undefined')
      .map((tag) => tag?._id || '');
  }

  /* DETAILS: remove empty filters */
  /* Details */
  if (_formattedData.details) {
    _formattedData.details = removeEmptyFilters(_formattedData.details, true);
  }
  /* Success Details */
  if (_formattedData.calculation === 'occurrences') {
    delete _formattedData.success_details;
  } else if (_formattedData.success_details) {
    _formattedData.success_details = removeEmptyFilters(
      _formattedData.success_details,
      true,
    );
  }

  /* EXECUTORS: Remove empty executors */

  _formattedData.team_performance = removeEmptyExecutor(
    _formattedData.team_performance,
  );

  _formattedData.player_development = removeEmptyExecutor(
    _formattedData.player_development,
  );
  _formattedData.opponent_analysis = removeEmptyExecutor(
    _formattedData.opponent_analysis,
  );

  if (!_formattedData.scouting_profile_ids?.length) {
    _formattedData.scouting_profile_ids = undefined;
  }

  /* AGAINST or PLAYER DETAILS - Remove empty executors */
  if (_formattedData.against) {
    _formattedData.player_development = undefined;
    _formattedData.scouting_profile_ids = undefined;
  }

  /* CONDITIONS: Remove empty details */
  if (_formattedData.conditions) {
    _formattedData.conditions.conditions.forEach((condition) => {
      if (condition.details) {
        condition.details = removeEmptyFilters(condition.details, true);
      }

      if (
        (condition.type === 'before' || condition.type === 'after') &&
        !condition.seconds
      ) {
        condition.seconds = 0;
      }

      if (condition.against && condition.executor.player_id) {
        condition.executor.player_id = undefined;
      }
      // Remove empty condition executor
      if (!condition.executor.team?.executor_positions?.length) {
        delete condition.executor.team?.executor_positions;
      }
      if (!condition.executor.player_id) {
        delete condition.executor.player_id;
      }

      // Add required "team" field as backup
      if (!condition.executor.player_id && !condition.executor.team) {
        condition.executor.team = {};
      }
    });

    if (!_formattedData.conditions.conditions.length) {
      _formattedData.conditions = undefined;
    }
  }

  /* TITLE - Delete if empty */
  if (_formattedData.title === '') {
    delete _formattedData.title;
  }

  /* PITCH ZONE - Delete if action is not tracking data */
  if (
    _formattedData.action &&
    !isTrackingAction(_formattedData.action) &&
    _formattedData.pitch_zone
  ) {
    delete _formattedData.pitch_zone;
  }

  // Rule condition
  if (_formattedData.calculation === 'rule') {
    if (
      _formattedData.rule_condition?.action &&
      !isTrackingAction(_formattedData.rule_condition.action) &&
      _formattedData.rule_condition?.pitch_zone
    ) {
      delete _formattedData.rule_condition.pitch_zone;
    }

    if (
      _formattedData.action &&
      isTrackingAction(_formattedData.action) &&
      _formattedData.rule_condition
    ) {
      delete _formattedData.rule_condition.seconds;
    }

    // Remove empty condition executor
    if (
      !_formattedData.rule_condition?.executor.team?.executor_positions?.length
    ) {
      delete _formattedData.rule_condition?.executor.team?.executor_positions;
    }
    if (!_formattedData.rule_condition?.executor.player_id) {
      delete _formattedData.rule_condition?.executor.player_id;
    }

    // Add required "team" field as backup
    if (
      _formattedData.rule_condition &&
      !_formattedData.rule_condition?.executor.player_id &&
      !_formattedData.rule_condition?.executor.team
    ) {
      _formattedData.rule_condition.executor.team = {};
    }
  } else {
    delete _formattedData.rule_condition;
  }

  // SEQUENCE - Delete empty details or delete sequence if not sequence action
  if (_formattedData.sequence_actions) {
    _formattedData.sequence_actions.forEach((action) => {
      if (action.details) {
        action.details = removeEmptyFilters(action.details, true);
      }
    });

    if (_formattedData.action !== 'sequence') {
      delete _formattedData.sequence_actions;
    }
  }

  return _formattedData;
}

export function validateDataBeforeSaving(
  data: Partial<FIEventAutomationPostData>,
): TAutomationErrors {
  const _newErrors: TAutomationErrors = {...DEFAULT_ERROR_STATE};

  /* Action */
  if (!data.action) {
    _newErrors.action = 'sentenceForm.action.error';
  }

  /* Ratio: Success details */
  if (data.calculation === 'ratio' && !data.success_details?.filters?.length) {
    _newErrors.success_details = 'sentenceForm.details.error.successDetails';
  }

  /* Conditions */
  if (data.conditions) {
    _newErrors.conditions = [];
    data.conditions.conditions?.forEach((condition) => {
      const _conditionErrors: TError<FIEventCondition> = {};

      if (condition.type === 'when') {
        if (
          !condition.details?.filters?.length ||
          !condition.details.filters[0].values?.length
        ) {
          _conditionErrors.details = 'sentenceForm.conditions.error.when';
        }
      } else {
        // if (!condition.seconds) {
        //   _conditionErrors.seconds = 'sentenceForm.conditions.error.seconds';
        // }

        if (!condition.action) {
          _conditionErrors.action = 'sentenceForm.action.error';
        }

        if (
          condition.action &&
          isTrackingAction(condition.action) &&
          !condition.pitch_zone
        ) {
          _conditionErrors.pitch_zone = 'sentenceForm.tracking.zone_error';
        }
      }
      if (_newErrors.conditions) {
        _newErrors.conditions.push(_conditionErrors);
      }
    });
  }

  /* Sequence actions */
  if (data.sequence_actions && data.action === 'sequence') {
    _newErrors.sequence_actions = [];
    data.sequence_actions.forEach((action) => {
      const _actionErrors: TError<FISequenceAction> = {};

      if (!action.action) {
        _actionErrors.action = 'sentenceForm.action.error';
      }

      if (_newErrors.sequence_actions) {
        _newErrors.sequence_actions.push(_actionErrors);
      }
    });
  }

  /* Require a zone for tracking actions */
  if (data.action && isTrackingAction(data.action) && !data.pitch_zone) {
    _newErrors.pitch_zone = 'sentenceForm.tracking.zone_error';
  }

  /* Require at least 1 subject with executor */
  const _executorTypes: TTrackerExecutorType[] = [
    'team_performance',
    'player_development',
    'opponent_analysis',
    'scouting_profile_ids',
  ];

  const _executorTypesWithData = _executorTypes.filter(
    (executorType) => data[executorType]?.length,
  );
  if (!_executorTypesWithData.length) {
    _newErrors.concrete_executor = 'sentenceForm.executor.error';
    enqueueSnackbar(i18n.t(_newErrors.concrete_executor), {
      variant: 'error',
    });
  }

  /* Rule trackers */
  if (data.rule_condition && data.calculation === 'rule') {
    const _ruleConditionErrors: TError<FIEventCondition> = {};

    if (!data.rule_condition.action) {
      _ruleConditionErrors.action = 'sentenceForm.action.error';
    }

    if (
      data.rule_condition.action &&
      isTrackingAction(data.rule_condition.action)
    ) {
      if (!data.rule_condition.pitch_zone) {
        _ruleConditionErrors.pitch_zone = 'sentenceForm.tracking.zone_error';
      }
    }

    if (Object.keys(_ruleConditionErrors).length) {
      _newErrors.rule_condition = _ruleConditionErrors;
    }
  }

  if (_newErrors.conditions) {
    let _hasConditionError = false;
    _newErrors.conditions.forEach((condition) => {
      if (Object.keys(condition).length > 0) {
        _hasConditionError = true;
      }
    });

    if (!_hasConditionError) {
      delete _newErrors.conditions;
    }
  }

  if (_newErrors.sequence_actions) {
    let _hasActionError = false;
    _newErrors.sequence_actions.forEach((action) => {
      if (Object.keys(action).length > 0) {
        _hasActionError = true;
      }
    });

    if (!_hasActionError) {
      delete _newErrors.sequence_actions;
    }
  }

  return _newErrors;
}

export function validateDataOnChange(
  data: Partial<FIEventAutomationPostData>,
  existingErrors: TAutomationErrors,
): TAutomationErrors {
  const _errors: TAutomationErrors = {...existingErrors};

  if (data.action && _errors.action) {
    delete _errors.action;
  }

  if (data.success_details?.filters?.length && _errors.success_details) {
    delete _errors.success_details;
  }

  if (data.conditions) {
    data.conditions.conditions?.forEach((condition, conditionIndex) => {
      let _conditionErrors: TError<FIEventCondition> = {};
      if (_errors?.conditions) {
        if (_errors.conditions[conditionIndex]) {
          _conditionErrors = _errors.conditions[conditionIndex];
        }

        if (condition.type === 'when') {
          if (
            condition.details?.filters?.length &&
            condition.details.filters[0].values?.length &&
            _conditionErrors.details
          ) {
            delete _conditionErrors.details;
          }
        } else {
          if (condition.seconds && _conditionErrors.seconds) {
            delete _conditionErrors.seconds;
          }

          if (condition.action && _conditionErrors.action) {
            delete _conditionErrors.action;
          }
        }

        _errors.conditions[conditionIndex] = _conditionErrors;
      }
    });
  }

  if (data.sequence_actions && data.action === 'sequence') {
    data.sequence_actions.forEach((action, actionIndex) => {
      let _actionErrors: TError<FISequenceAction> = {};
      if (_errors?.sequence_actions) {
        if (_errors.sequence_actions[actionIndex]) {
          _actionErrors = _errors.sequence_actions[actionIndex];
        }

        if (action.action) {
          delete _actionErrors.action;
        }

        _errors.sequence_actions[actionIndex] = _actionErrors;
      }
    });
  }

  return _errors;
}

// When editing a tracker, check if concrete executor is still present in data. If not, we should redirect to another deep dive
export function isInitialExecutorStillActive(
  tracker: Partial<FIEventAutomationPostData> | FIFormattedAutomation,
  executorType: TTrackerExecutorType,
  initialConcreteExecutor?: FITrackerConcreteExecutor,
): boolean {
  if (executorType === 'scouting_profile_ids') {
    return false;
  }

  const _subjects = tracker[executorType];
  const _initialExecutorKey = generateUniqueTrackerKey(
    tracker,
    initialConcreteExecutor,
  );
  if (!_subjects?.length || !initialConcreteExecutor) {
    return false;
  }

  const _indexInSubjects = _subjects.findIndex((subject) => {
    // if
    const _subjectKey = generateUniqueTrackerKey(tracker, subject.executor);
    return _initialExecutorKey === _subjectKey;
  });

  return _indexInSubjects > -1;
}

// In lists, we'll be showing trackers with the same id multiple times, we need a unique id.
// This also used to see whether the intial executor is still active, to handle redirects
export function generateUniqueTrackerKey(
  tracker: FIFormattedAutomation | Partial<FIEventAutomationPostData>,
  executor?: FITrackerConcreteExecutor | FITrackerAbstractExecutor,
) {
  let _key = tracker._id;

  if (tracker.against) {
    _key += `_${tracker.against}`;
  }

  const _executor = executor || tracker.concrete_executor;
  if (!_executor) {
    return _key;
  }

  if (_executor.player_id) {
    _key += `_player-${_executor.player_id}`;
  } else if (_executor.team?.executor_positions?.length) {
    _key += `_positions-${_executor.team.executor_positions.join('-')}`;
  } else if (_executor.team) {
    _key += '_team';
  }

  return _key;
}

//
export function findInitialExecutorIndex(
  data: Partial<FIEventAutomationPostData>,
  executorType: TTrackerExecutorType,
): number {
  if (executorType === 'scouting_profile_ids') {
    return -1;
  }

  const _subjects = data[executorType];
  if (!_subjects?.length || !data.concrete_executor) {
    return -1;
  }

  const _initialExecutorKey = generateUniqueTrackerKey(
    data,
    data.concrete_executor,
  );
  const _foundIndex = _subjects.findIndex((subject) => {
    const _subjectKey = generateUniqueTrackerKey(data, subject.executor);
    return _initialExecutorKey === _subjectKey;
  });

  return _foundIndex;
}

export function hasMultipleExecutors(
  data: Partial<FIEventAutomationPostData> | FIFormattedAutomation,
): boolean {
  const _allExecutors = [
    ...(data.team_performance || []),
    ...(data.player_development || []),
    ...(data.opponent_analysis || []),
    // ...(data.scouting_profile_ids || []),
  ];

  return _allExecutors.length > 1;
}
