import {BodyRequest, NormalRequest, RequestMethod} from 'types/api.types';
import {makeRequest} from 'helpers/api.helper';

export function useAPI() {
  async function get<T>(options: NormalRequest) {
    return await makeRequest<T>({
      ...options,
      method: RequestMethod.get,
    });
  }

  async function del<T>(options: NormalRequest) {
    return await makeRequest<T>({
      ...options,
      method: RequestMethod.delete,
    });
  }

  async function post<T>(options: BodyRequest) {
    return await makeRequest<T>({
      ...options,
      method: RequestMethod.post,
    });
  }

  async function put<T>(options: BodyRequest) {
    return await makeRequest<T>({
      ...options,
      method: RequestMethod.put,
    });
  }

  return {get, put, post, del};
}
