import React, {useEffect} from 'react';
import {useAutomationForm} from 'context/event-automations/automations-form.context';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {useTranslation} from 'react-i18next';
import {useTeams} from 'context/team.context';
import {FITag} from '@my-game-plan/types';
import {FilterOptionsState} from '@mui/material';

function AutomationTagInput(): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _automationBuilderContext = useAutomationForm();
  const _teamsContext = useTeams();

  const [_selectedTags, _setSelectedTags] = React.useState<FITag[]>([]);
  const [_tagsOptions, _setTagsOptions] = React.useState<FITag[]>([]);

  /*
   * Side effects
   */
  // Set default tags value
  useEffect(() => {
    // Set tags found in props.data
    const _tagsToSelect: FITag[] = [];
    if (_automationBuilderContext.data.tags?.length) {
      _automationBuilderContext.data.tags.forEach((tag) => {
        const _matchingTag = _teamsContext.preferences?.tags?.find(
          (t) => t._id === tag,
        );
        if (_matchingTag) {
          _tagsToSelect.push(_matchingTag);
        }
      });
    }

    // Set tags found in props.newTags
    if (_automationBuilderContext.data.newTags?.length) {
      _automationBuilderContext.data.newTags.forEach((tag) => {
        _tagsToSelect.push({
          _id: `${tag}`,
          label: tag,
        });
      });
    }

    _setSelectedTags(_tagsToSelect);
  }, [
    _automationBuilderContext.data.tags,
    _teamsContext.preferences,
    _automationBuilderContext.data.newTags,
  ]);

  // Define tags options
  useEffect(() => {
    let _generatedOptions: FITag[] = [];
    if (_teamsContext.preferences?.tags) {
      _generatedOptions = [..._teamsContext.preferences.tags];
    }

    // Generate options for new tags
    _automationBuilderContext.data.newTags?.forEach((tag) => {
      _generatedOptions.push({
        _id: `${tag}`,
        label: tag,
      });
    });

    _setTagsOptions(_generatedOptions);
  }, [_teamsContext.preferences, _automationBuilderContext.data.newTags]);

  /*
   * Handlers
   */

  function _onChange(event: React.SyntheticEvent, value: FITag[]) {
    const _selectedTagIds: string[] = [];
    const _tagsToCreate: string[] = [];

    value.forEach((tag) => {
      const _isTagInPreferences = _teamsContext.preferences?.tags?.some(
        (preferenceTag) => {
          return preferenceTag._id === tag._id;
        },
      );
      if (_isTagInPreferences) {
        _selectedTagIds.push(tag._id);
      } else {
        _tagsToCreate.push(tag._id);
      }
    });

    _automationBuilderContext.setData({
      tags: _selectedTagIds,
      newTags: _tagsToCreate,
    });
  }

  function _filterOptionsForTagCreation(
    options: FITag[],
    state: FilterOptionsState<FITag>,
  ): FITag[] {
    const _newOptions = [...options];

    const _doesTagExist = options.some(
      (option) => option.label === state.inputValue,
    );
    if (state.inputValue && !_doesTagExist) {
      _newOptions.push({
        _id: state.inputValue,
        label: `Create tag: "${state.inputValue}"`,
      });
    }

    return _newOptions;
  }

  /*
   * Render
   */
  return (
    <Autocomplete
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={
            _selectedTags.length
              ? undefined
              : t('sentenceForm.tags.placeholder')
          }
          //   label={t('sentenceForm.tags.label')}
          sx={{minWidth: 240}}
        />
      )}
      size="small"
      multiple
      limitTags={2}
      options={_tagsOptions}
      value={_selectedTags}
      onChange={_onChange}
      filterOptions={_filterOptionsForTagCreation}
      selectOnFocus
      clearOnBlur
      isOptionEqualToValue={(option, value) => option._id === value._id}
    />
  );
}

export default AutomationTagInput;
