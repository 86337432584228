import {FIPlayerObjective, FIPlayerObjectiveComment} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import PlayerObjectiveCreateButton from './player-objective-create-button.view';

import moment from 'moment';
import PlayerObjectiveStatusIcon from './comments/player-objective-status-icon.view';
import {useExecutorDeepDive} from 'context/executor-deep-dive.context';

interface IPlayerObjectiveCardProps {
  objective: FIPlayerObjective;
}

function PlayerObjectiveCard(props: IPlayerObjectiveCardProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _executorDeepDiveContext = useExecutorDeepDive();

  const [_dateText, _setDateText] = useState<string>('');
  const [_lastCommment, _setLastComment] =
    useState<FIPlayerObjectiveComment | null>(null);

  /*
   * Handlers
   */

  useEffect(() => {
    // Format "x days ago" from created_at
    const _timeSince = moment(props.objective.created_at).fromNow();
    const _lastCommentTime = t('playerObjectives.comments.lastComment', {
      time: _timeSince,
    });
    _setDateText(_lastCommentTime);

    // Get last comment
    let _newLastComment: FIPlayerObjectiveComment | null = null;
    if (props.objective.comments.length) {
      _newLastComment =
        props.objective.comments[props.objective.comments.length - 1];
    }
    _setLastComment(_newLastComment);
  }, [props.objective]);

  /*
   * Render
   */
  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        // width: '100%',
        flex: 1,
      }}>
      <CardHeader
        content
        title={props.objective.title}
        titleTypographyProps={{
          color: 'text.primary',
          fontSize: 16,
          fontWeight: 600,
        }}
        action={
          <Chip
            label={t(`playerObjectives.category.${props.objective.category}`)}
            // size="small"
            color="secondary"
            variant="outlined"
          />
        }
        right={{}}
      />
      <CardContent>
        <Stack gap={1}>
          {/* <Typography variant="body2">{props.objective.category}</Typography> */}
          {_lastCommment ? (
            <Typography>{`“${_lastCommment.text}”`}</Typography>
          ) : (
            <Typography color="text.disabled" fontStyle="italic">
              {t('playerObjectives.comments.count', {count: 0})}
            </Typography>
          )}
          <Stack direction="row" spacing={1} alignItems="center">
            {Boolean(props.objective.comments.length) && (
              <>
                <Typography variant="body2" color="text.disabled">
                  {_dateText}
                </Typography>
                <Box
                  bgcolor="text.disabled"
                  height={4}
                  width={4}
                  borderRadius="50%"
                />
                <Typography variant="body2" color="text.disabled">
                  {t('playerObjectives.comments.count', {
                    count: props.objective.comments.length,
                  })}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </CardContent>
      <CardActions>
        <Box flex={1}>
          {_lastCommment && (
            <PlayerObjectiveStatusIcon status={_lastCommment.status} />
          )}
        </Box>
        <PlayerObjectiveCreateButton
          playerId={_executorDeepDiveContext.player?._id || ''}
          data={props.objective}
          Button={<Button size="small">{t('playerObjectives.view')}</Button>}
        />
      </CardActions>
    </Card>
  );
}

export default PlayerObjectiveCard;
