import React, {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';
import {FITeam} from '@my-game-plan/types';
import TeamSearchInput from 'components/filter/team-search-input.view';
import {searchTeams} from 'controllers/teams.controller';
import {LOADING_STATE} from 'types/screen.types';

interface IBenchmarkSubjectTeamsSelectorProps {
  value?: string[];
  onChange: (value: string[]) => void;
}

function BenchmarkSubjectTeamsSelector(
  props: IBenchmarkSubjectTeamsSelectorProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const [_loadedTeams, _setLoadedTeams] = useState<FITeam[]>([]);
  const [_selectedTeams, _setSelectedTeams] = useState<FITeam[]>([]);
  const [_loadingState, _setLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );

  /*
   * Side effects
   */
  useEffect(() => {
    async function _setAndLoadTeams() {
      const _teamsToDisplay: FITeam[] = _loadedTeams.filter(
        (team) => props.value?.length && props.value.includes(team._id),
      );

      // Find teams that havent been loaded yet and fetch them
      let _missingTeams: FITeam[] = [];
      const _teamIdsToLoad = props.value?.filter(
        (teamId) =>
          !_loadedTeams.find((loadedTeam) => loadedTeam._id === teamId),
      );
      if (_teamIdsToLoad?.length) {
        //
        _setLoadingState(LOADING_STATE.LOADING);
        _missingTeams = await searchTeams({team_id: _teamIdsToLoad});

        _setLoadedTeams([..._loadedTeams, ..._missingTeams]);
      }
      _setLoadingState(LOADING_STATE.SUCCESS);

      const _teamsToSet = Array.from(
        new Set([..._teamsToDisplay, ..._missingTeams]),
      );
      _setSelectedTeams(_teamsToSet);
    }

    _setAndLoadTeams();
  }, [props.value, _loadedTeams]);

  /*
   * Handlers
   */
  function _onTeamSelect(team: FITeam | null) {
    // Add to loaded teams if not already loaded
    const _indexInLoadedTeams = _loadedTeams.findIndex(
      (loadedTeam) => loadedTeam._id === team?._id,
    );
    if (_indexInLoadedTeams === -1 && team) {
      _setLoadedTeams([..._loadedTeams, team]);
    }

    // Call onChange
    const _indexInSelectedTeams = _selectedTeams.findIndex(
      (selectedTeam) => selectedTeam._id === team?._id,
    );
    if (_indexInSelectedTeams === -1 && team) {
      props.onChange([...(props.value || []), team._id]);
    }
  }

  function _onDeleteTeam(teamId: string) {
    const _filteredTeams = _selectedTeams.filter((team) => team._id !== teamId);
    props.onChange(_filteredTeams.map((team) => team._id));
  }

  /*
   * Render
   */
  let _ListContent = null;
  if (_loadingState === LOADING_STATE.SUCCESS && !_selectedTeams.length) {
    _ListContent = (
      <Box
        p={1}
        pt={3}
        alignItems="center"
        textAlign="center"
        justifyContent="center">
        <Typography color="text.secondary">No teams selected</Typography>
      </Box>
    );
  } else {
    _ListContent = (
      <List>
        {_selectedTeams.map((team) => (
          <ListItem
            key={team._id}
            secondaryAction={
              <IconButton onClick={() => _onDeleteTeam(team._id)}>
                <DeleteIcon fontSize="small" color="secondary" />
              </IconButton>
            }>
            <ListItemText>{team.name}</ListItemText>
          </ListItem>
        ))}
      </List>
    );
  }
  return (
    <Stack>
      <Box padding={1}>
        <TeamSearchInput
          onTeamSelect={_onTeamSelect}
          size="small"
          clearOnSelect
        />
      </Box>
      <Box minHeight={200}>{_ListContent}</Box>
    </Stack>
  );
}

export default BenchmarkSubjectTeamsSelector;
