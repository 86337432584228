import React, {useState} from 'react';

import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Logo from '../../common/Logo/Logo';
import {LOADING_STATE} from 'types/screen.types';
import {useExecutorDeepDive} from 'context/executor-deep-dive.context';
import {useTeams} from 'context/team.context';

function TeamDeepDiveHeader(): JSX.Element | null {
  /*
   * Hooks n State
   */
  const _executorDeepDiveContext = useExecutorDeepDive();
  const _teamsContext = useTeams();

  const [_teamDropdownAnchorEl, _setTeamDropdownAnchorEl] =
    useState<null | HTMLElement>(null);

  /*
   * Side effects
   */

  /*
   * Handlers
   */
  function _onTeamSelectorDropdownOpen(event: React.MouseEvent<HTMLElement>) {
    _setTeamDropdownAnchorEl(event.currentTarget);
  }

  function _onTeamSelectorDropdownClose() {
    _setTeamDropdownAnchorEl(null);
  }

  function _onOpponentChange(teamId: string) {
    _teamsContext.setCurrentlyObservingOpponentId(teamId);
    _onTeamSelectorDropdownClose();
  }

  /*
   * Render
   */
  let _LeftContent = null;
  let _Menu = null;
  if (_executorDeepDiveContext.loadingState === LOADING_STATE.INITING) {
    _LeftContent = (
      <>
        <Stack direction="row" gap={2} flex={1} pb={2}>
          <Skeleton
            variant="circular"
            sx={{
              width: 24,
              height: 24,
            }}
          />
          <Skeleton variant="text" sx={{width: 120}} />
        </Stack>
      </>
    );
  } else if (_executorDeepDiveContext.team) {
    const _TeamInfo = (
      <Stack direction="row" alignItems="center" flex={1} gap={2}>
        <Logo src={_executorDeepDiveContext.team.image_url} />
        <Typography fontWeight={600} fontSize={24} lineHeight={1}>
          {_executorDeepDiveContext.team.name}
        </Typography>
      </Stack>
    );

    _LeftContent = _TeamInfo;

    /* Opponent analysis */
    if (_executorDeepDiveContext.executorType === 'opponent_analysis') {
      _LeftContent = (
        <ButtonBase onClick={_onTeamSelectorDropdownOpen} sx={{gap: 1}}>
          {_TeamInfo}
          {_teamDropdownAnchorEl ? (
            <KeyboardArrowUpIcon color="secondary" fontSize="small" />
          ) : (
            <KeyboardArrowDownIcon color="secondary" fontSize="small" />
          )}
        </ButtonBase>
      );

      _Menu = (
        <Menu
          anchorEl={_teamDropdownAnchorEl}
          open={Boolean(_teamDropdownAnchorEl)}
          onClose={_onTeamSelectorDropdownClose}>
          {_teamsContext.opponentsOptions.map((option) => {
            return (
              <MenuItem
                key={option.value}
                onClick={() => _onOpponentChange(option.value)}>
                {option.label}
              </MenuItem>
            );
          })}
        </Menu>
      );
    }
  }
  return (
    <Stack direction="row" alignItems="center" width="100%" gap={2}>
      {_LeftContent}
      {_Menu}
    </Stack>
  );
}

export default TeamDeepDiveHeader;
