import React from 'react';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

interface IExecutorDeleteButtonProps {
  onClick: () => void;
}

function ExecutorDeleteButton(props: IExecutorDeleteButtonProps) {
  return (
    <IconButton onClick={props.onClick} color="secondary">
      <DeleteIcon />
    </IconButton>
  );
}

export default ExecutorDeleteButton;
