import {FITrackerSubject, TTrackerExecutorType} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {TRACKER_TYPES_CONFIG} from 'config/trackers.config';
import ManageTrackersPreviewTooltipRow from './manage-trackers-preview-tooltip-row';
import {lighten, useTheme} from '@mui/material';

interface IManageTrackersPreviewTooltipProps {
  data?: FITrackerSubject[] | string[];
  executorType: TTrackerExecutorType;
  children: React.ReactNode;
}

function ManageTrackersPreviewTooltip(
  props: IManageTrackersPreviewTooltipProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _theme = useTheme();
  const [_translatedExecutorType, _setTranslatedExecutorType] =
    useState<string>('');

  /*
   * Side Effects
   */
  useEffect(() => {
    const _executorConfig = TRACKER_TYPES_CONFIG[props.executorType];
    if (_executorConfig) {
      _setTranslatedExecutorType(_executorConfig.title.toLowerCase());
    }
  }, [props.executorType]);

  /*
   * Render
   */
  let _TooltipTitle: React.ReactNode = t(
    'sentenceForm.executor.noPlayersForType',
    {
      type: _translatedExecutorType,
    },
  );

  if (props.data?.length) {
    _TooltipTitle = (
      <Stack>
        <Typography variant="caption" color="text.secondary">
          {t('sentenceForm.observing.observing')}
        </Typography>
        <Stack>
          {props.data.map((subject, index) => {
            return (
              <ManageTrackersPreviewTooltipRow
                key={index}
                data={subject}
                executorType={props.executorType}
                divider={props.data && index < props.data.length - 1}
              />
            );
          })}
        </Stack>
      </Stack>
    );
  }

  const _bgColor = lighten(_theme.palette.background.paper, 0.05);
  return (
    <Tooltip
      title={_TooltipTitle}
      placement="bottom-start"
      slotProps={{
        tooltip: {
          sx: {
            bgcolor: _bgColor,
            maxWidth: 480,
            p: 2,
          },
        },
      }}>
      <span>{props.children}</span>
    </Tooltip>
  );
}

export default ManageTrackersPreviewTooltip;
