import {FIStyleOfPlayPerformance} from '@my-game-plan/types';
import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {alpha, useTheme} from '@mui/material';

interface IProfileListItemProps {
  profile: FIStyleOfPlayPerformance;
  isSelected: boolean;
  isLast?: boolean;
}
function ProfileListItem(props: IProfileListItemProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _theme = useTheme();

  /*
   * Side effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */
  return (
    <FormControlLabel
      control={<Radio />}
      value={props.profile.style_of_play}
      sx={{
        py: 3,
        px: 3,
        borderBottom: props.isLast ? 0 : 1,
        transition: 'background-color 0.2s',
        gap: 0.5,
        borderColor: 'divider',
        marginRight: 0,

        bgcolor: props.isSelected
          ? alpha(_theme.palette.primary.main, 0.12)
          : 'transparent',

        '&:hover': {
          bgcolor: props.isSelected
            ? alpha(_theme.palette.primary.main, 0.2) // Slightly stronger hover effect
            : _theme.palette.action.hover,
        },
        '& .MuiFormControlLabel-label': {
          flexGrow: 1,
          width: '100%',
        },
      }}
      label={
        <Stack gap={0.5}>
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography flexGrow={1}>
              {t(
                `profileSetup.styleOfPlay.${props.profile.style_of_play}.title`,
              )}
            </Typography>
            {/* <Indicator
              value={props.profile.performance.benchmarked_score}
              valueDisplay="none"
              width={80}
            /> */}
          </Stack>
          <Typography variant="body2" color="text.secondary">
            {t(
              `profileSetup.styleOfPlay.${props.profile.style_of_play}.description`,
            )}
          </Typography>
        </Stack>
      }
    />
  );
}

export default ProfileListItem;
