import React, {useEffect} from 'react';

import Select, {SelectChangeEvent} from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

import {ITrackerSentenceSelectProps} from 'types/trackers/tracker-sentence.types';
import SentenceTextSegment, {
  FONT_SIZE_MAP,
} from '../text/sentence-text-segment';

function TrackerSentenceSelect<T extends string | number>(
  props: ITrackerSentenceSelectProps<T>,
): JSX.Element {
  /*
   * Hooks n State
   */
  const [_formattedValue, _setFormattedValue] = React.useState<string>('');

  /*
   * Side Effects
   */
  useEffect(() => {
    let _newValue = props.placeholder || '';

    if (props.valueFormatter && props.value) {
      _newValue = props.valueFormatter(props.value);
    } else if (props.value) {
      _newValue = props.value.toString();
    }

    _setFormattedValue(_newValue);
  }, [props.value, props.valueFormatter, props.placeholder]);

  /*
   * Handlers
   */
  function _onChange(event: SelectChangeEvent<T>) {
    const _typedValue = event.target.value as T;
    if (_typedValue && props.onChange) {
      props.onChange(_typedValue);
    }
  }

  /*
   * Render
   */

  if (props.readonly) {
    const {onChange, options, ..._commonProps} = props;
    return (
      <SentenceTextSegment
        {..._commonProps}
        value={_formattedValue}
        type="value"
      />
    );
  }

  const _fontSize = FONT_SIZE_MAP[props.size];

  return (
    <FormControl variant="standard">
      <Select<T>
        value={props.options.length ? props.value : ''}
        onChange={_onChange}
        sx={{fontSize: _fontSize, fontWeight: 500}}
        IconComponent={() => null}
        inputProps={{
          sx: {paddingRight: '0 !important', py: 0.2},
        }}>
        {props.options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default TrackerSentenceSelect;
