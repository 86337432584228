import React, {useEffect, useState} from 'react';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {hasMultipleExecutors} from 'helpers/automation/automation-form.helper';
import {
  FIEventAutomationPostData,
  FITrackerSubject,
  TTrackerExecutorType,
} from '@my-game-plan/types';
import {useTranslation} from 'react-i18next';
import ExecutorPreviewContent, {
  ISelectedExecutorsState,
  TSelectedExecutorsForTypes,
} from './executor-preview/executor-preview-content';
import {EXECUTOR_TYPES} from 'config/trackers.config';
import {useTeams} from 'context/team.context';
import {Breakpoint} from '@mui/material';

interface IDeleteTrackerDialogProps {
  data: Partial<FIEventAutomationPostData>;
  open: boolean;
  onClose: () => void;

  onDeleteTracker: () => void;
  onDeleteSubjects: (data: Partial<FIEventAutomationPostData>) => void;
}

function DeleteTrackerDialog(props: IDeleteTrackerDialogProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const [_hasMultipleExecutors, _setHasMultipleExecutors] = useState(false);
  const [_submitButtonLabel, _setSubmitButtonLabel] = useState<string>('');
  const [_selectedExecutors, _setSelectedExecutors] =
    useState<ISelectedExecutorsState>({
      totalCount: 0,
      selectedCount: 0,
      executors: {},
    });

  /*
   * Side Effects
   */
  /* Define "hasMultipleExecutors" and selected indexes to display data in correct dialog */
  useEffect(() => {
    _setHasMultipleExecutors(hasMultipleExecutors(props.data));

    // Set selected executor indexes
    const _newSelectedExecutors = _selectAllExecutors();
    _setSelectedExecutors(_newSelectedExecutors);
  }, [props.data, props.open]);

  useEffect(() => {
    let _newSubmitButtonLabel = t('eventAutomations.delete.title');
    if (
      _hasMultipleExecutors &&
      _selectedExecutors.totalCount !== _selectedExecutors.selectedCount
    ) {
      _newSubmitButtonLabel = t(
        'eventAutomations.delete.deleteSubjects.deleteSubjects',
        {count: _selectedExecutors.selectedCount},
      );
    }
    _setSubmitButtonLabel(_newSubmitButtonLabel);
  }, [_hasMultipleExecutors, _selectedExecutors]);

  /*
   * Handlers
   */
  function _onCancel() {
    props.onClose();
  }

  function _onSubmit() {
    const _shouldDeleteTracker =
      !_hasMultipleExecutors ||
      _selectedExecutors.selectedCount === _selectedExecutors.totalCount;

    // Delete all executors
    if (_shouldDeleteTracker) {
      props.onDeleteTracker();
      return;
    }

    // Delete selected executors
    const _newData = {...props.data};
    EXECUTOR_TYPES.forEach((executorType) => {
      const _selectedIndexes = _selectedExecutors.executors[executorType];

      if (_selectedIndexes?.length) {
        if (executorType === 'scouting_profile_ids') {
          const _selectedProfileIds = props.data[executorType];
          _newData[executorType] = _selectedProfileIds?.filter(
            (executor: string, index: number) =>
              !_selectedIndexes.includes(index),
          );
        } else {
          const _selectedSubjects = props.data[executorType];
          _newData[executorType] = _selectedSubjects?.filter(
            (executor: FITrackerSubject, index: number) =>
              !_selectedIndexes.includes(index),
          );
        }
      }

      if (!_newData[executorType]?.length) {
        _newData[executorType] = undefined;
      }
    });

    props.onDeleteSubjects(_newData);
  }

  function _onExecutorCheck(executorType: TTrackerExecutorType, index: number) {
    const _executorIndexes = _selectedExecutors.executors[executorType];
    if (!_executorIndexes) {
      return;
    }

    // Delete or add index
    const _indexInSelectedExecutors = _executorIndexes.indexOf(index);
    if (_indexInSelectedExecutors === -1) {
      // Add it
      _executorIndexes.push(index);
    } else {
      // Remove it
      _executorIndexes.splice(_indexInSelectedExecutors, 1);
    }

    // Define new selected count
    const _selectedCount = Object.values(_selectedExecutors.executors).reduce(
      (acc, executors) => acc + executors.length,
      0,
    );

    const _executors = {
      ..._selectedExecutors.executors,
      [executorType]: _executorIndexes,
    };
    const _newSelectedExecutors: ISelectedExecutorsState = {
      ..._selectedExecutors,
      selectedCount: _selectedCount,
      executors: _executors,
    };

    _setSelectedExecutors(_newSelectedExecutors);
  }

  function _onDeleteAllCheck() {
    if (_selectedExecutors.selectedCount === _selectedExecutors.totalCount) {
      // Unselect all
      const _newSelectedExecutors: ISelectedExecutorsState = {
        ..._selectedExecutors,
        selectedCount: 0,
        executors: {},
      };

      _setSelectedExecutors(_newSelectedExecutors);
    } else {
      // Select all
      const _newSelectedExecutors = _selectAllExecutors();
      _setSelectedExecutors(_newSelectedExecutors);
    }
  }

  function _selectAllExecutors(): ISelectedExecutorsState {
    const _newSelectedExecutorIndexes: TSelectedExecutorsForTypes = {};
    let _totalExecutors = 0;
    EXECUTOR_TYPES.forEach((executorType) => {
      const _value = props.data[executorType];
      if (!_value?.length) {
        return;
      }

      _newSelectedExecutorIndexes[executorType] = _value.map(
        (executor, index: number) => index,
      );

      _totalExecutors += _value.length;
    });

    const _result: ISelectedExecutorsState = {
      totalCount: _totalExecutors,
      selectedCount: _totalExecutors,
      executors: _newSelectedExecutorIndexes,
    };

    return _result;
  }

  /*
   * Render
   */
  let _Content = null;
  if (_hasMultipleExecutors) {
    _Content = (
      <Stack gap={3}>
        <Typography>
          {t('eventAutomations.delete.deleteSubjects.message')}
        </Typography>
        <ExecutorPreviewContent
          data={props.data}
          selectedExecutors={_selectedExecutors}
          onExecutorSelect={_onExecutorCheck}
          ownPlayers={_teamsContext.ownPlayers}
        />
        <Divider flexItem />
        <FormControlLabel
          control={
            <Checkbox
              checked={
                _selectedExecutors.selectedCount ===
                _selectedExecutors.totalCount
              }
            />
          }
          label={
            <Stack>
              <Typography>
                {t('eventAutomations.delete.deleteSubjects.all.title')}
              </Typography>
              <Typography
                variant="body2"
                textTransform="none"
                color="text.secondary">
                {t('eventAutomations.delete.deleteSubjects.all.message')}
              </Typography>
            </Stack>
          }
          onChange={_onDeleteAllCheck}
        />
      </Stack>
    );
  } else {
    _Content = <Typography>{t('eventAutomations.delete.message')}</Typography>;
  }

  let _maxWidth: Breakpoint = 'sm';
  if (
    _hasMultipleExecutors &&
    Object.keys(_selectedExecutors.executors).length > 2
  ) {
    _maxWidth = 'md';
  }

  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth={_maxWidth}
      onClose={props.onClose}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description">
      <DialogTitle id="delete-dialog-title">
        {t('eventAutomations.delete.title')}
      </DialogTitle>
      <DialogContent>{_Content}</DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={_onCancel}>
          {t('general.cancel')}
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={_onSubmit}
          disabled={_hasMultipleExecutors && !_selectedExecutors.selectedCount}>
          {_submitButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteTrackerDialog;
