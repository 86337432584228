import {useTeams} from 'context/team.context';
import {ITypedOption} from 'types/option.types';
import {FITrackerSubject} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Stack from '@mui/material/Stack';

interface IPlayerCellContentProps {
  data: FITrackerSubject[];
}

const MAX_AVATARS = 5;
function PlayerCellContent(props: IPlayerCellContentProps): JSX.Element {
  /*
   * Hooks n State
   */
  const _teamsContext = useTeams();
  const [_avatars, _setAvatars] = useState<ITypedOption<string>[]>([]);

  /*
   * Side Effects
   */
  useEffect(() => {
    const _generatedAvatars: ITypedOption<string>[] = [];
    props.data.forEach((subject) => {
      const _player = _teamsContext.ownPlayers.find(
        (player) => player._id === subject.executor.player_id,
      );
      if (subject.executor.player_id && _player) {
        _generatedAvatars.push({
          label: _player.display_name,
          value: _player._id,
          name: _player.jersey_num,
          image_url: _player.image_url,
        });
      }
    });

    _setAvatars(_generatedAvatars);
  }, [props.data, _teamsContext.ownPlayers]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  return (
    <Stack alignItems="flex-start">
      <AvatarGroup
        max={MAX_AVATARS}
        spacing={6}
        total={_avatars.length}
        sx={{
          '& .MuiAvatar-root': {width: 24, height: 24, fontSize: 9},
        }}>
        {_avatars.map((player) => {
          return (
            <Avatar
              key={player.value}
              alt={player.label}
              src={player.image_url}
              sx={{
                bgcolor: 'background.default',
                color: 'text.secondary',
              }}>
              {player.name}
            </Avatar>
          );
        })}
      </AvatarGroup>
    </Stack>
  );
}

export default PlayerCellContent;
