import {FITrackerSubject, TTrackerExecutorType} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import {ITypedOption} from 'types/option.types';

import {formatSingleExecutor} from 'helpers/automation/automation-form.helper';
import {useTeams} from 'context/team.context';

import {useTranslation} from 'react-i18next';

interface IManageTrackersPreviewTooltipRowProps {
  data?: FITrackerSubject | string;
  executorType: TTrackerExecutorType;
  divider?: boolean;
}

function ManageTrackersPreviewTooltipRow(
  props: IManageTrackersPreviewTooltipRowProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const [_formattedValue, _setFormattedValue] =
    useState<ITypedOption<string> | null>();

  const [_isShared, _setIsShared] = useState<boolean>(false);
  const [_sharedText, _setSharedText] = useState<string>('');

  /*
   * Side Effects
   */
  useEffect(() => {
    if (typeof props.data === 'string') {
      _setFormattedValue({label: props.data, value: props.data});
    } else if (props.data?.executor) {
      // Format executor: "any player", positions or players
      const _label = formatSingleExecutor(
        props.data?.executor,
        _teamsContext.ownPlayers,
        -1,
      );
      _setFormattedValue({label: _label, value: _label});

      // Set shared text
      let _newSharedText = t('sentenceForm.share.notSharedInAppShort');
      let _newIsShared = false;
      if (props.data?.shared_subjects?.share_with_executor) {
        _newSharedText = t('sentenceForm.share.sharedInApp');
        _newIsShared = true;
      } else if (props.data.shared_subjects?.team) {
        _newSharedText = t('sentenceForm.share.sharedWithAllPlayers');
      } else if (props.data.shared_subjects) {
        _newIsShared = true;
        let _suffix = '';
        if (props.data.shared_subjects.player_ids?.length) {
          _suffix = t('sentenceForm.share.playersCount', {
            count: props.data.shared_subjects.player_ids.length,
          });
        } else if (props.data.shared_subjects.player_positions?.length) {
          _suffix = t('sentenceForm.share.positionsCount', {
            count: props.data.shared_subjects.player_positions.length,
          });
        }

        _newSharedText = t('sentenceForm.share.sharedWithValue', {
          value: _suffix,
        });
      }

      _setIsShared(_newIsShared);
      _setSharedText(_newSharedText);
    }
  }, [props.data, _teamsContext.ownPlayers]);

  /*
   * Render
   */
  if (!_formattedValue) {
    return null;
  }

  let _SharedIcon = PhonelinkEraseIcon;
  if (_isShared) {
    _SharedIcon = MobileFriendlyIcon;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={6}
      py={1.5}
      sx={{
        borderBottom: props.divider ? 1 : 0,
        borderBottomColor: 'divider',
      }}>
      <Typography variant="body2" flexGrow={1}>
        {_formattedValue.label}
      </Typography>

      <Stack direction="row" alignItems="center" gap={0.5}>
        <Typography
          variant="caption"
          textTransform="none"
          color={_isShared ? 'text.secondary' : 'text.disabled'}>
          {_sharedText}
        </Typography>
        <_SharedIcon
          fontSize="small"
          color={_isShared ? 'success' : 'secondary'}
        />
      </Stack>
    </Stack>
  );
}

export default ManageTrackersPreviewTooltipRow;
