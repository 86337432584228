import React from 'react';

import Stack from '@mui/material/Stack';

import Indicator from 'components/dashboard/Indicator/Indicator';

interface ITrackersTablePercentileCellProps {
  score: number;
}

function TrackersTablePercentileCell(
  props: ITrackersTablePercentileCellProps,
): JSX.Element {
  /*
   * Hooks n State
   */

  /*
   * Side effects
   */

  /*
   * Render
   */
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Stack width={160}>
        <Indicator value={props.score} valueDisplay="percentile" />
      </Stack>
    </Stack>
  );
}

export default TrackersTablePercentileCell;
