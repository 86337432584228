import React, {useEffect, useState} from 'react';
import {ITrackerSentenceInputProps} from 'types/trackers/tracker-sentence.types';
import {ITypedOption} from 'types/option.types';
import {generateActionConfigs, IEventFilters} from '@my-game-plan/types';
import {useTeams} from 'context/team.context';
import {useCompetitions} from 'context/competitions.context';
import {translateEventProperty} from 'helpers/translation.helper';
import {useTranslation} from 'react-i18next';
import SentenceTextSegment from '../../variants/text/sentence-text-segment';
import TrackerSentenceSelect from '../../variants/select/sentence-select-segment';

function MetricSegment<T extends keyof IEventFilters | undefined>(
  props: ITrackerSentenceInputProps<T>,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const _competitionsContext = useCompetitions();
  const [_options, _setOptions] = useState<
    ITypedOption<keyof IEventFilters | 'all'>[]
  >([]);

  /*
   * Side Effects
   */
  // Generate options based on data provider
  useEffect(() => {
    if (!_teamsContext.ownTeam || !props.tracker?.action) {
      _setOptions([]);
      return;
    }

    const _actionsConfig = generateActionConfigs(
      _competitionsContext.domesticCompetition?.event_provider,
      _competitionsContext.domesticCompetition?.available_metrics,
    );
    const _metricsForAction =
      _actionsConfig[props.tracker.action]?.availableMetrics || [];

    const _metricsOptions: ITypedOption<keyof IEventFilters | 'all'>[] =
      _metricsForAction.map((metric) => {
        const _option: ITypedOption<keyof IEventFilters | 'all'> = {
          value: metric,
          label: translateEventProperty(metric),
          name: translateEventProperty(metric),
        };
        return _option;
      });

    const _defaultOption: ITypedOption<keyof IEventFilters | 'all'> = {
      value: 'all',
      label: t('sentenceForm.amount'),
      name: t('sentenceForm.amount'),
    };
    _setOptions([_defaultOption, ..._metricsOptions]);
  }, [_competitionsContext.domesticCompetition, props.tracker?.action]);

  function _onMetricChange(value: keyof IEventFilters | 'all') {
    if (!props.onChange) {
      return;
    }
    const _value = (value === 'all' ? undefined : value) as T;
    props.onChange(_value);
  }

  /*
   * Render
   */

  if (props.readonly || _options.length < 2) {
    const {onChange, ..._commonProps} = props;
    return (
      <SentenceTextSegment
        {..._commonProps}
        value={_formatValue(props.value || 'all')}
        type={props.value ? 'value' : 'default'}
      />
    );
  }

  function _formatValue(metric: keyof IEventFilters | 'all') {
    if (metric === 'all') {
      return t('sentenceForm.amount');
    }
    return translateEventProperty(metric);
  }

  return (
    <TrackerSentenceSelect<keyof IEventFilters | 'all'>
      {...props}
      options={_options}
      value={props.value || 'all'}
      onChange={_onMetricChange}
      valueFormatter={_formatValue}
    />
  );
}

export default MetricSegment;
