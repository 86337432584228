import React from 'react';
import cn from 'classnames';
import styles from './sentence.module.scss';
import {TSentenceSize} from 'types/sentence-form.types';

/*
 * SENTENCE - PARENT COMPONENT
 */
interface ISentenceProps {
  size: TSentenceSize;
  readonly?: boolean;
  children: React.ReactNode;
}
function SentenceComponent(props: ISentenceProps): JSX.Element {
  const _sentenceClasses = cn(
    styles.sentence,
    styles[`sentence-${props.size}`],
    {
      [styles.sentenceReadonly]: props.readonly,
    },
  );

  return <div className={_sentenceClasses}>{props.children}</div>;
}

/*
 *  SECTION
 */
interface ISectionProps {
  children: React.ReactNode;
  offset?: boolean;
}
function SectionComponent(props: ISectionProps): JSX.Element {
  const _sectionClasses = cn(styles.section, {
    [styles.sectionOffset]: props.offset,
  });
  return <div className={_sectionClasses}>{props.children}</div>;
}

/*
 *  LINE
 */
interface ILineProps {
  children: React.ReactNode;
  buttonLine?: boolean;
}
function LineComponent(props: ILineProps): JSX.Element {
  const _lineClasses = cn(styles.line, {
    [styles.buttonLine]: props.buttonLine,
  });
  return <div className={_lineClasses}>{props.children}</div>;
}

/*
 *  Segment
 */
interface ISegmentProps {
  children: React.ReactNode;
}
function SegmentComponent(props: ISegmentProps): JSX.Element {
  return <div className={styles.lineSegment}>{props.children}</div>;
}

/*
 *  EXPORTS
 */
const Sentence = {
  Sentence: SentenceComponent,
  Section: SectionComponent,
  Line: LineComponent,
  Segment: SegmentComponent,
};

export default Sentence;
