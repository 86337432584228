import ANALYTICS_EVENT from 'config/analytics/event-names.config';
import {useAnalytics} from 'context/analytics.context';
import React, {useEffect} from 'react';

import TrackersView from './automations/trackers.view';

function HomeScreen(): JSX.Element {
  /*
   * Hooks n State
   */
  const _analyticsContext = useAnalytics();

  /*
   * Side effects
   */

  useEffect(() => {
    _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_HOME_SCREEN);
  }, []);

  /*
   * Render
   */
  return <TrackersView executorType="team_performance" />;
}

export default HomeScreen;
