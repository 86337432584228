import {
  FIAutomationPlayerMatch,
  FIAutomationPlayer,
  FIConditionalEventFilter,
  FIConditionalEventFilters,
  FIFormattedAutomation,
  FIPlayer,
  FIEventFilters,
  TQueryOperatorType,
  getReferencedPlayersInDetails,
  FIAutomationDataQueryParams,
  PlayerPosition,
  TTrackerExecutorType,
  parseQueryWithRules,
} from '@my-game-plan/types';
import QueryString from 'qs';

import {NAV_LEVEL_1_ROUTE} from 'config/navigation.config';
import {serializeParams} from '../api.helper';

export function getLastMatches(
  array: FIAutomationPlayerMatch[],
  observingMatchIds: string[],
  matchesWithEventsOnly?: boolean,
  playedMatchesOnly?: boolean,
) {
  let _matches = [...array];

  if (playedMatchesOnly) {
    _matches = array.filter((match) => match.did_play);
  }

  if (matchesWithEventsOnly) {
    _matches = _matches.filter((match) => match.total_events);
  }

  return _matches.filter((match) =>
    observingMatchIds.includes(match.match._id),
  );
}

export function filterQueryParamsToConditionalParams(
  params: FIEventFilters,
  operator?: TQueryOperatorType,
): FIConditionalEventFilters {
  const _conditionalFilterParams: FIConditionalEventFilters = {
    operator: 'and',
    filters: [],
  };

  Object.keys(params).forEach((key) => {
    const _typedKey = key as keyof FIEventFilters;

    if (params[_typedKey]) {
      const _filter: FIConditionalEventFilter = {
        property: _typedKey,
        values: params[_typedKey] || [],
        operator: operator || 'or',
      };

      _conditionalFilterParams.filters.push(_filter);
    }
  });

  return _conditionalFilterParams;
}

/*
 * Check if data contains references to players that were transfered
 */

export function getTransferredPlayersForAutomations(
  data: FIFormattedAutomation,
  players: FIPlayer[],
): string[] {
  // Check executors
  const _playersInExecutors: string[] = [];
  if (data.player_development?.length) {
    data.player_development.forEach((subject) => {
      if (subject.executor.player_id) {
        _playersInExecutors.push(subject.executor.player_id);
      }
    });
  }
  // Check details
  // for all details, check pass.receiving_player._id
  const _playersInDetails = getReferencedPlayersInDetails(data.details);

  // Check success_details
  // for all success details, check pass.receiving_player._id
  const _playersInSuccessDetails = getReferencedPlayersInDetails(
    data.success_details,
  );

  // For all conditions
  // check observing_players
  // for all details, check pass.receiving_player._id
  const _conditionsPlayers: string[] = [];
  if (data.conditions?.conditions.length) {
    data.conditions?.conditions.forEach((condition) => {
      // const _conditionObservingPlayers = getReferencedPlayersInSubject(
      //   condition.observing_players,
      // );
      if (condition.executor.player_id) {
        _conditionsPlayers.push(condition.executor.player_id);
      }

      const _conditionDetails = getReferencedPlayersInDetails(
        condition.details,
      );
      _conditionsPlayers.push(..._conditionDetails);
    });
  }
  // Rule condition
  if (data.calculation === 'rule' && data.rule_condition?.executor.player_id) {
    _conditionsPlayers.push(data.rule_condition.executor.player_id);
  }

  const _allReferencedPlayerIDs: string[] = [
    ..._playersInExecutors,
    ..._playersInDetails,
    ..._playersInSuccessDetails,
    ..._conditionsPlayers,
  ];

  // Check all referenced players and players that are not on team
  const _playerIDsWithoutMatchingPlayerIds = _allReferencedPlayerIDs.filter(
    (playerID) => !players.some((player) => player._id === playerID),
  );

  return _playerIDsWithoutMatchingPlayerIds;
}

/*
 * Get all players that are referenced in the automation and fill data
 */
export function addPlayersToAutomations(
  automations: FIFormattedAutomation[],
  players: FIPlayer[],
): FIFormattedAutomation[] {
  const _automationsWithPlayers: FIFormattedAutomation[] = [];

  automations.forEach((automation) => {
    if (automation.players?.length) {
      const _players: FIAutomationPlayer[] = automation.players?.map(
        (player) => {
          const _matchingPlayer = players.find((p) => p._id === player._id);

          const _player: FIAutomationPlayer = {
            ...player,
            display_name: _matchingPlayer?.display_name || '',
            name: _matchingPlayer?.name || '',
            image_url: _matchingPlayer?.image_url || '',
            // position: automation.benchmark_filters?.position?.[0],
            history: [],
          };

          return _player;
        },
      );
      _automationsWithPlayers.push({
        ...automation,
        players: _players,
        // created_by: undefined,
      });
    }
  });

  return _automationsWithPlayers;
}

// Format URL Search params to FIDataQueryParams
export function formatSearchParamsToQueryParams(
  searchParams: URLSearchParams,
): FIAutomationDataQueryParams {
  const _parsedSearchParams = QueryString.parse(searchParams.toString());

  const _parsedQuery = parseQueryWithRules<FIAutomationDataQueryParams>(
    _parsedSearchParams as Record<string, string>,
    true, // use string instead of object id
  );
  return _parsedQuery;
}

// Get default benchmark and data filters
export function getDefaultBenchmarkAndDataFilters(
  competitionId: number[],
  seasonId: number[],
  positions?: PlayerPosition[],
): FIAutomationDataQueryParams {
  const _filters: FIAutomationDataQueryParams = {
    benchmark: {
      observing: {
        competition_id: competitionId,
      },
      time: {
        season_id: seasonId,
      },
      calculation: 'per_90',
    },
    own_data: {
      time: {
        season_id: seasonId,
      },
    },
  };

  if (positions?.length) {
    _filters.benchmark.position = positions;
    _filters.benchmark.min_minutes_played = 90;
  }

  return _filters;
}

export function generateStatisticDeepDiveURL(
  tracker: FIFormattedAutomation,
  subjectId?: string,
  urlSearchParams?: string,
  urlPrefix?: string,
): string {
  let _url = `statistics/${tracker.statistic_id}`;

  // Subject Id is not always passed, might already be in URL
  if (subjectId) {
    _url = `${subjectId}/${_url}`;
  }

  // const _statisticQueryFilters = {
  //   ...statisticQueryFilters,
  //   against: statisticQueryFilters.against || undefined,
  // };

  /* Parse query to searchParams and back to string */
  /* Als we dit doen ipv tracker.benchmark_fitlers en tracker.own_data, zetten we geen overbodige props in de URL */
  const _searchParams = new URLSearchParams(urlSearchParams);
  const _parsedQuery = formatSearchParamsToQueryParams(_searchParams);

  _parsedQuery.against = tracker.against || undefined;
  _parsedQuery.executor_positions =
    tracker.concrete_executor?.team?.executor_positions || undefined;

  const _urlQueryParams = serializeParams(_parsedQuery);

  if (_urlQueryParams) {
    _url = `${_url}?${_urlQueryParams}`;
  }

  if (urlPrefix) {
    _url = `${urlPrefix}/${_url}`;
  }
  return _url;
}

export function generateStatisticDeepdiveURLPrefix(
  executorType: TTrackerExecutorType,
): string {
  let _prefix = '';
  if (executorType === 'team_performance') {
    _prefix = NAV_LEVEL_1_ROUTE.TEAM;
  } else if (executorType === 'player_development') {
    _prefix = NAV_LEVEL_1_ROUTE.PLAYERS;
  } else if (executorType === 'opponent_analysis') {
    _prefix = NAV_LEVEL_1_ROUTE.OPPONENT_AUTOMATIONS;
  } else if (executorType === 'scouting_profile_ids') {
    //TODO-TRACKERS: Add scouting profile prefix - extra complexity: scouting_profile_id should be param in player URL
  }

  return _prefix;
}
