import React, {useEffect, useState} from 'react';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import {useTranslation} from 'react-i18next';

import {DETAIL_OPERATOR_OPTIONS} from 'config/event-automation-builder.config';
import {ITypedOption} from 'types/option.types';
import {
  IActionConfigDetails,
  FIConditionalEventFilter,
  FIEventFilters,
  TQueryOperatorType,
  ACTION,
} from '@my-game-plan/types';
import {
  translateAction,
  translateEventProperty,
  translateValue,
} from 'helpers/translation.helper';
import {
  getGeneralMatchFilterOptions,
  getPlayerOptions,
  getPositionOptions,
} from 'helpers/automation/automation-form.helper';
import {useTeams} from 'context/team.context';
import CustomAutocomplete from 'components/material-customised/autocomplete/autocomplete.view';
import {capitalize} from 'lodash';
import {useZones} from 'context/zones.context';
import {generateZoneOptions} from 'helpers/pitch-zones.helper';
import {useAutomationForm} from 'context/event-automations/automations-form.context';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TrackerSentenceSelect from '../../variants/select/sentence-select-segment';
import SentenceTextSegment from '../../variants/text/sentence-text-segment';
import {useCompetitions} from 'context/competitions.context';

interface IFilterValueSelectProps {
  action?: ACTION;
  filter: FIConditionalEventFilter;
  possibleDetails: IActionConfigDetails;
  onChange: (filter: FIConditionalEventFilter) => void;
  onDelete: (field: keyof FIEventFilters) => void;
  onPopupToggle: (hasOpenPopup: boolean) => void;
  isGameStateCondition?: boolean;
  against?: boolean;
  readonly?: boolean;
}

function DetailsValuesSelectRow(props: IFilterValueSelectProps): JSX.Element {
  /* Hooks n State */
  const [_options, _setOptions] = useState<ITypedOption<string>[]>([]);
  const [_value, _setValue] = useState<ITypedOption<string>[]>([]);
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const _competitionsContext = useCompetitions();
  const _pitchZonesContext = useZones();
  const _tarckerBuilderContext = useAutomationForm();

  useEffect(() => {
    if (props.filter?.values) {
      const _matchingValueOptions: ITypedOption<string>[] = [];
      _options.forEach((option) => {
        const _isSelected =
          props.filter.values &&
          props.filter.values.some((value) => value === option.value);
        if (_isSelected) {
          _matchingValueOptions.push(option);
        }
      });

      _setValue(_matchingValueOptions);
    }
  }, [props.filter.values, _options]);

  useEffect(() => {
    const _detailsConfig = props.possibleDetails[props.filter.property];

    if (props.filter.property === 'pass.receiving_player._id') {
      // Get player options and group by position line
      const _possibleOptions = getPlayerOptions(
        _teamsContext.ownPlayers,
        // props.transferredPlayers,
      );

      _setOptions(_possibleOptions);
    } else if (props.filter.property === 'pass.receiving_player.position') {
      // Get positions options by position line
      const _possibleOptions = getPositionOptions();
      _setOptions(_possibleOptions);
    } else if (props.filter.property.includes('coordinates')) {
      const _shouldHideDynamicZones =
        !_competitionsContext.isTrackingDataSupported;
      const _possibleOptions = generateZoneOptions(
        _pitchZonesContext.all,
        props.action,
        props.against,
        _tarckerBuilderContext.previewingTeamLogo,
        _shouldHideDynamicZones,
      );
      _setOptions(_possibleOptions);
    } else if (props.isGameStateCondition && _detailsConfig) {
      // _setOptions(GAME_STATE_VALUE_OPTIONS);
      const _generalOptions = getGeneralMatchFilterOptions(
        props.filter.property,
        _detailsConfig,
      ) as ITypedOption<string>[];
      _setOptions(_generalOptions);
    } else if (_detailsConfig) {
      const _possibleOptions: ITypedOption<string>[] = _detailsConfig.map(
        (value) => {
          const _label = translateValue(value, props.filter.property);
          return {
            label: _label,
            name: _label,
            value: value,
          };
        },
      );

      _setOptions(_possibleOptions);
    }
  }, [
    props.action,
    props.possibleDetails,
    props.filter.property,
    props.isGameStateCondition,
    props.against,
    _teamsContext.ownPlayers,
    _pitchZonesContext.all,
    _tarckerBuilderContext.previewingTeamLogo,
    _competitionsContext.isTrackingDataSupported,
  ]);

  /* Handlers */
  function _onDeleteClick() {
    props.onDelete(props.filter.property);
  }

  function _onSelectChangeNew<T extends keyof FIEventFilters>(
    data: ITypedOption<string> | ITypedOption<string>[] | null,
  ) {
    if (Array.isArray(data)) {
      const _selectedValues = data.map((selectedValue) => selectedValue.value);
      const _typedValues = _selectedValues as FIEventFilters[T];

      // TODO: Typing is off here! F<> should support IEventFilters[keyof IEventFilters]
      const _filterToChange: FIConditionalEventFilter = {
        ...props.filter,
        values: _typedValues as FIEventFilters[keyof FIEventFilters],
      };

      props.onChange(_filterToChange);
    } else {
      // TODO? Handle no array field
    }
  }

  function _onSelectFocus() {
    props.onPopupToggle(true);
  }

  function _onSelectBlur() {
    props.onPopupToggle(false);
  }

  function _onOperatorSelectChange(value: TQueryOperatorType) {
    const _filterToSave: FIConditionalEventFilter = {
      ...props.filter,
      operator: value,
    };

    props.onChange(_filterToSave);
  }

  /* Render */
  const _translatedProperty = translateEventProperty(props.filter.property);

  let _OperatorContent = null;
  if (
    // _propertyConfig?.mutuallyExclusive &&
    props.filter?.values &&
    props.filter.values.length > 1 &&
    !props.filter.property.includes('coordinates') &&
    !props.readonly
  ) {
    /* Display dropdown to select operator */
    _OperatorContent = (
      <TrackerSentenceSelect<TQueryOperatorType>
        options={DETAIL_OPERATOR_OPTIONS}
        onChange={_onOperatorSelectChange}
        size="small"
        value={props.filter.operator}
        currentExecutorType="team_performance"
      />
    );
  } else {
    let _operatorKey = 'sentenceForm.details.is';
    if (props.filter?.operator === 'and') {
      _operatorKey = 'sentenceForm.details.isAllOf';
    } else if (props.filter?.values && props.filter.values.length > 1) {
      _operatorKey = 'sentenceForm.details.isOneOf';
    }

    _OperatorContent = (
      <SentenceTextSegment
        size="small"
        currentExecutorType="team_performance"
        value={t(_operatorKey)}
      />
    );
  }

  function _groupHeader(text: string): string {
    if (props.filter.property.includes('coordinates')) {
      const _actionTranslation = props.action
        ? translateAction(props.action, 2)
        : '';
      return capitalize(
        t(`zones.groups.${text}`, {
          action: _actionTranslation,
        }),
      );
    }
    return capitalize(t(`playerPosition.${text}s`));
  }

  const _shouldGroupOptions =
    props.filter.property === 'pass.receiving_player._id' ||
    props.filter.property === 'pass.receiving_player.position' ||
    props.filter.property.includes('coordinates');

  let _Value = null;
  if (props.readonly) {
    const _valueToRender = _value.map((value) => value.label).join(', ');
    _Value = (
      <SentenceTextSegment
        value={_valueToRender}
        currentExecutorType="team_performance"
        size="small"
        type="value"
      />
    );
  } else {
    _Value = (
      <CustomAutocomplete<string, true>
        disableCloseOnSelect
        multiple={true}
        options={_options}
        value={_value}
        onChange={_onSelectChangeNew}
        onOpen={_onSelectFocus}
        onClose={_onSelectBlur}
        groupHeader={_shouldGroupOptions ? _groupHeader : undefined}
        fullWidth
      />
    );
  }

  return (
    <Stack
      key={props.filter.property}
      direction="row"
      gap={1}
      alignItems="center"
      width="100%">
      <Box whiteSpace="nowrap">
        <SentenceTextSegment
          value={_translatedProperty}
          size="small"
          currentExecutorType="team_performance"
        />
      </Box>
      <Box whiteSpace="nowrap">{_OperatorContent}</Box>
      {_Value}
      {!props.readonly && (
        <IconButton
          size="small"
          color="secondary"
          onClick={_onDeleteClick}
          title={t('sentenceForm.details.remove')}>
          <DeleteIcon />
        </IconButton>
      )}
    </Stack>
  );
}

export default DetailsValuesSelectRow;
