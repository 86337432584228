import {FICompactMatchInfo, FICompetition, FIMatch} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Checkbox from '@mui/material/Checkbox';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import {useTranslation} from 'react-i18next';

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import {TSelectedCompetitionInfo} from 'types/event-automations-misc.types';

interface IMatchesFilterCompetitionSelectorProps {
  seasonId: number;
  seasonLabel: string;
  competitions: FICompetition[];
  matches: FIMatch[] | FICompactMatchInfo[];
  observingMatchIds: string[];
  onObservingMatchIdChange: (matchIds: string[]) => void;
}

function MatchesFilterCompetitionSelector(
  props: IMatchesFilterCompetitionSelectorProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_anchorEl, _setAnchorEl] = useState<null | HTMLElement>(null);
  const [_selectedMatchesInfo, _setSelectedMatchesInfo] =
    useState<TSelectedCompetitionInfo>({});

  /*
   * Side effects
   */
  useEffect(() => {
    //
    const _newSelectedMatchesInfo: TSelectedCompetitionInfo = {};
    props.competitions.forEach((competition) => {
      const _competitionMatches = props.matches.filter(
        (match) => match.competition_id === competition._id,
      );
      const _totalMatches = _competitionMatches.length;
      const _selectedMatches = props.observingMatchIds.filter((matchId) =>
        _competitionMatches.some((match) => match._id === matchId),
      ).length;

      _newSelectedMatchesInfo[competition._id] = {
        total: _totalMatches,
        selected: _selectedMatches,
      };

      _setSelectedMatchesInfo(_newSelectedMatchesInfo);
    });
  }, [props.observingMatchIds, props.competitions, props.matches]);

  /*
   * Handlers
   */
  function _onMenuOpen(event: React.MouseEvent<HTMLElement>) {
    _setAnchorEl(event.currentTarget);
  }

  function _onMenuClose() {
    _setAnchorEl(null);
  }

  function _onCompetitionSelect(competitionId: number) {
    // _onMenuClose();
    const _competitionMatchIds = props.matches
      .filter((match) => match.competition_id === competitionId)
      .map((match) => match._id);

    const _newMatchIds = props.observingMatchIds.filter(
      (matchId) => !_competitionMatchIds.includes(matchId),
    );

    if (!_selectedMatchesInfo[competitionId]?.selected) {
      _newMatchIds.push(..._competitionMatchIds);
    }
    props.onObservingMatchIdChange(_newMatchIds);
  }

  /*
   * Render
   */
  return (
    <>
      <ListItemButton color="secondary" onClick={_onMenuOpen}>
        <ListItemText
          primary={props.seasonLabel}
          secondary={t('eventAutomations.benchmark.competitionsCount', {
            count: props.competitions.length,
          })}
          // primaryTypographyProps={{color: 'text.secondary'}}
        />
        <ListItemIcon sx={{minWidth: 0}}>
          <EmojiEventsIcon color="secondary" />
        </ListItemIcon>
      </ListItemButton>

      <Menu
        open={Boolean(_anchorEl)}
        anchorEl={_anchorEl}
        onClose={_onMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {props.competitions.map((competition) => {
          const _isChecked =
            _selectedMatchesInfo[competition._id]?.selected ===
            _selectedMatchesInfo[competition._id]?.total;
          const _isIndeterminate =
            Boolean(_selectedMatchesInfo[competition._id]?.selected) &&
            _selectedMatchesInfo[competition._id]?.total !==
              _selectedMatchesInfo[competition._id]?.selected;

          return (
            <ListItemButton
              onClick={() => _onCompetitionSelect(competition._id)}
              dense
              key={`${props.seasonId}-competition-${competition._id}`}>
              <Checkbox
                checked={_isChecked}
                indeterminate={_isIndeterminate}
                onChange={() => _onCompetitionSelect(competition._id)}
              />
              <ListItemText primary={competition.name} />
            </ListItemButton>
          );
        })}
      </Menu>
    </>
  );
}

export default MatchesFilterCompetitionSelector;
