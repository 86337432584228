import React, {PropsWithChildren} from 'react';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from 'helpers/general/context_generators.helper';
import {INavigationState} from 'types/navigation.types';
import {LOCAL_STORAGE_SIDEBAR_EXPANDED} from 'config/api.config';

export interface AppAPI {
  navigation: INavigationState;
  toggleSidebar: () => void;
}

const context = createCustomContext<AppAPI>();
export const useApp = createContextHook(context);

export const AppContextProvider = (
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element => {
  /*
   * Hooks n State
   */
  const _sidebarStateFromStorage =
    localStorage.getItem(LOCAL_STORAGE_SIDEBAR_EXPANDED) || 'true';

  const [_navigationState, _setNavigationState] =
    React.useState<INavigationState>({
      isSidebarExpanded: _sidebarStateFromStorage === 'true',
    });

  /*
   * Handlers
   */
  function _toggleSidebar() {
    const _newState = !_navigationState.isSidebarExpanded;
    localStorage.setItem(LOCAL_STORAGE_SIDEBAR_EXPANDED, _newState.toString());
    _setNavigationState({
      isSidebarExpanded: _newState,
    });
  }

  /*
   * Return context
   */
  const _contextValue: AppAPI = {
    navigation: _navigationState,
    toggleSidebar: _toggleSidebar,
  };
  return createProvider(context, props, _contextValue);
};
