import {ITrackerSentenceProps} from 'types/trackers/tracker-sentence.types';
import {FISequenceAction} from '@my-game-plan/types';
import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AddActionButton from '../add-action-button';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd';
import SequenceAction from './sequence-action';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ActionSegment from '../../segment/instances/action/action-segment';

function SequenceActions(props: ITrackerSentenceProps): JSX.Element {
  /*
   * Hooks n State
   */

  /*
   * Handlers
   */
  function _onActionChange(index: number, data: Partial<FISequenceAction>) {
    if (!props.tracker?.sequence_actions || !props.onChange) {
      return;
    }
    const _sequenceActionsList: FISequenceAction[] = [
      ...(props.tracker.sequence_actions || []),
    ];
    _sequenceActionsList[index] = data;

    props.onChange({
      sequence_actions: _sequenceActionsList,
    });
  }

  function _onActionDelete(index: number) {
    if (!props.onChange || !props.tracker?.sequence_actions) {
      return;
    }
    const _sequenceActionsList: FISequenceAction[] = [
      ...(props.tracker.sequence_actions || []),
    ];
    _sequenceActionsList.splice(index, 1);

    props.onChange({
      sequence_actions: _sequenceActionsList,
    });
  }

  function _onDragEnd(result: any) {
    const {source, destination} = result;
    if (!destination || !props.onChange) return;

    const items = [...(props.tracker?.sequence_actions || [])];
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);

    props.onChange({sequence_actions: items});
  }

  function _onAddAction() {
    if (!props.onChange) {
      return;
    }

    const _newSequenceActions = [...(props.tracker?.sequence_actions || [])];
    _newSequenceActions.push({});
    props.onChange({
      sequence_actions: _newSequenceActions,
    });
  }

  /*
   * Render
   */
  let _ActionsList = null;
  if (props.readonly) {
    //
    _ActionsList = (
      <Stack direction="row" alignItems={'center'} gap={0.5} flexWrap={'wrap'}>
        {props.tracker?.sequence_actions?.map((sequenceAction, actionIndex) => {
          if (!sequenceAction.action) {
            return null;
          }
          return (
            <React.Fragment key={actionIndex}>
              <ActionSegment
                {...props}
                key={`action-${actionIndex}`}
                value={{action: sequenceAction.action}}
                readonly
                displayArticle
              />
              {props.tracker?.sequence_actions?.length &&
                actionIndex < props.tracker.sequence_actions.length - 1 && (
                  <ArrowForwardIcon
                    key={`arrow-${actionIndex}`}
                    fontSize="small"
                    sx={{color: 'text.secondary'}}
                  />
                )}
            </React.Fragment>
          );
        })}
      </Stack>
    );
  } else {
    _ActionsList = (
      <DragDropContext onDragEnd={_onDragEnd}>
        <Droppable droppableId="sequenceActions">
          {(provided: DroppableProvided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {props.tracker?.sequence_actions?.map((action, index) => {
                let _canDelete = true;
                if (
                  (!props.tracker?.sequence_actions?.length ||
                    props.tracker?.sequence_actions?.length <= 1) &&
                  index === 0
                ) {
                  _canDelete = false;
                }

                return (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}>
                    {(provided, snapshot) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          opacity: snapshot.isDragging ? 0.8 : 1, // Optional: adding a visual cue when dragging
                        }}>
                        <SequenceAction
                          {...props}
                          key={index}
                          actionIndex={index}
                          sequenceAction={action}
                          onChange={_onActionChange}
                          onDelete={_canDelete ? _onActionDelete : undefined}
                          errors={props.errors?.sequence_actions?.[index]}
                          readonly={props.readonly}
                        />
                      </Box>
                    )}
                  </Draggable>
                );
              })}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
  return (
    <Stack>
      {_ActionsList}

      {props.isValid && <AddActionButton onChange={_onAddAction} />}
    </Stack>
  );
}

export default SequenceActions;
