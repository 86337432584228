import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';

interface IAddConditionButtonProps {
  conditionsCount: number;
  ConditionsList: React.ReactNode;
}

function ToggleConditionsButton(
  props: IAddConditionButtonProps,
): JSX.Element | null {
  /* Hooks n State */
  // const [_isOpened, _setIsOpened] = useState<boolean>(false);
  const [_anchorEl, _setAnchorEl] = useState<HTMLElement | null>(null);
  const {t} = useTranslation();
  const [_buttonLabel, _setButtonLabel] = useState<string>('');

  /*
   * Side effects
   */
  useEffect(() => {
    const _newButtonLabel = `+ ${props.conditionsCount} ${t(
      'sentenceForm.conditions.conditions',
      {count: props.conditionsCount},
    )}`;
    _setButtonLabel(_newButtonLabel);
  }, [props.conditionsCount]);

  /* Handlers */
  function _onPopupTrigger(event: React.MouseEvent<HTMLButtonElement>) {
    _setAnchorEl(event.currentTarget);
  }

  function _onPopupClose() {
    _setAnchorEl(null);
  }

  /* Render */
  const _isOpen = Boolean(_anchorEl);
  return (
    <>
      <Button
        variant="text"
        color="secondary"
        onClick={_onPopupTrigger}
        size="small"
        sx={{alignSelf: 'flex-start'}}>
        {_buttonLabel}
      </Button>
      <Popover
        anchorEl={_anchorEl}
        open={_isOpen}
        onClose={_onPopupClose}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}>
        <Box p={2}>{props.ConditionsList}</Box>
      </Popover>
    </>
  );
}

export default ToggleConditionsButton;
