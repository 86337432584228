import {IDetailsSegmentProps} from 'types/trackers/tracker-sentence.types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import TrackerSentencePopup from 'components/trackers/sentence/segment/variants/popup/sentence-popup-segment';
import {IButtonValueSegment} from 'components/filter/header-filter-bar/buttons/header-filter-button.view';
import {
  ACTIONS_CONFIG,
  FIConditionalEventFilter,
  FIConditionalEventFilters,
} from '@my-game-plan/types';
import SentenceTextSegment from '../../variants/text/sentence-text-segment';
import {useTeams} from 'context/team.context';
import {useZones} from 'context/zones.context';
import {translateValue} from 'helpers/translation.helper';
import i18n from 'localization';
import DetailsPopupContent from './details-popup-content';

const READONLY_VISIBLE_DETAILS = 2;
function DetailsSegment(props: IDetailsSegmentProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const _pitchZonesContext = useZones();
  const [_anchorEl, _setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [_isDisabled, _setIsDisabled] = useState(false);

  const [_hiddenItemsCount, _setHiddenItemsCount] = useState<number>(0);
  const [_hasOpenedNestedPopups, _setHasOpenedNestedPopups] =
    useState<boolean>(false);

  /*
   * Side Effects
   */
  // Set button disabled state
  useEffect(() => {
    let _shouldDisable = false;
    if (
      (!props.action && !props.isGameStateCondition) ||
      (props.action &&
        !props.isGameStateCondition &&
        !ACTIONS_CONFIG[props.action]?.possibleDetails)
    ) {
      _shouldDisable = true;
    }

    _setIsDisabled(_shouldDisable);
  }, [props.action, props.isGameStateCondition]);

  // Define button segments and tooltip content
  useEffect(() => {
    if (!props.value?.filters.length) {
      _setHiddenItemsCount(0);
      return;
    }
    const _filtersWithValues = props.value.filters.filter(
      (filter) => filter.values && filter.values.length,
    );

    const _hasMultipleValues = _filtersWithValues.length > 1;

    // When readonly, only show the first filter
    if (props.readonly && _hasMultipleValues) {
      _setHiddenItemsCount(
        _filtersWithValues.length - READONLY_VISIBLE_DETAILS,
      );
    }
  }, [props.value, props.readonly]);

  /*
   * Handlers
   */
  function _onNestedPopupToggle(hasOpenPopup: boolean) {
    _setHasOpenedNestedPopups(hasOpenPopup);
  }

  function _getValueLabelForFilter(
    filter: FIConditionalEventFilter,
  ): IButtonValueSegment {
    let _valuesLabel = '';

    let _prefix = '';
    const _prefixKey = `sentenceForm.details.valuePrefix.${filter.property}`;
    if (i18n.exists(_prefixKey)) {
      _prefix = t(_prefixKey);
    }

    filter.values?.forEach((value, valueIndex) => {
      let _valueLabel = '';
      if (filter.property === 'pass.receiving_player._id') {
        const _matchingPlayer = _teamsContext.ownPlayers.find(
          (player) => player._id === value,
        );
        if (_matchingPlayer) {
          _valueLabel += _matchingPlayer.display_name;
        } else {
          _valueLabel += t('playerPosition.transferredPlayer');
        }
      } else if (filter.property.includes('coordinates')) {
        const _zone = _pitchZonesContext.all.find((zone) => zone._id === value);
        if (_zone) {
          _valueLabel += _zone.name;
        } else {
          _valuesLabel += t('sentenceForm.tracking.deletedZone');
        }
      } else {
        _valueLabel += translateValue(value?.toString() || '', filter.property);
      }

      _valuesLabel += _valueLabel;

      if (filter.values && valueIndex < filter.values.length - 1) {
        let _operator = ', ';
        if (valueIndex === filter.values.length - 2) {
          _operator = ` ${t(`sentenceForm.${filter.operator}`)} `;
        }
        _valuesLabel += _operator;
      }
    });

    if (_prefix) {
      _valuesLabel = `${_prefix} ${_valuesLabel}`;
    }

    const _filterSegment: IButtonValueSegment = {
      type: 'value',
      value: _valuesLabel,
    };

    return _filterSegment;
  }

  function _onOpenPopup(element: HTMLElement) {
    _setAnchorEl(element);
  }

  function _onClosePopup() {
    _setAnchorEl(null);
  }

  function _valueFormatter(value: FIConditionalEventFilters) {
    //
    if (!value?.filters.length) {
      return '';
    }

    const _operator = value.operator;
    let _filtersWithValues = value.filters.filter(
      (filter) => filter.values && filter.values.length,
    );

    const _hasMultipleValues = _filtersWithValues.length > 1;
    const _generatedButtonSegments: IButtonValueSegment[] = [];

    // When readonly, only show the first filter
    if (props.readonly && _hasMultipleValues) {
      _filtersWithValues = _filtersWithValues.slice(
        0,
        READONLY_VISIBLE_DETAILS,
      );
    }

    _filtersWithValues.forEach((filter, filterIndex) => {
      const _filterSegment = _getValueLabelForFilter(filter);
      _generatedButtonSegments.push(_filterSegment);

      // Add comma or operator
      const _shouldAddOperator = filterIndex < _filtersWithValues.length - 1;
      if (_shouldAddOperator) {
        let _operatorText = ',';
        if (filterIndex === _filtersWithValues.length - 2) {
          _operatorText = t(`sentenceForm.${_operator}`);
        }
        _generatedButtonSegments.push({
          type: 'operator',
          value: _operatorText,
        });
      }
    });

    const _text = _generatedButtonSegments
      .map((segment) => segment.value)
      .join(' ');

    return _text;
  }

  function _isEmptyValue(value?: FIConditionalEventFilters) {
    const _filtersWithValues = value?.filters.filter(
      (f) => f.values && f.values.length,
    );
    return (
      !value ||
      !value.filters ||
      !value.filters.length ||
      !_filtersWithValues?.length
    );
  }

  /*
   * Render
   */
  const {value, onChange, ..._commonProps} = props;

  const _placeholder =
    props.placeholder || t('sentenceForm.details.placeholder.details') || '';

  if (props.readonly) {
    const _value = value ? _valueFormatter(value) : _placeholder;

    return (
      <SentenceTextSegment
        {..._commonProps}
        value={_value}
        type="value"
        hiddenItemsCount={_hiddenItemsCount}
        tooltipContent={
          <DetailsPopupContent
            {...props}
            onNestedPopupToggle={_onNestedPopupToggle}
            isGameStateCondition={props.isGameStateCondition}
            readonly
          />
        }
      />
    );
  }

  // Return a single button with placholder by default
  const _Button = (
    <TrackerSentencePopup.Button
      {...props}
      disabled={_isDisabled}
      placeholder={_placeholder}
      onOpen={_onOpenPopup}
      isPopupOpen={Boolean(_anchorEl)}
      required={props.required}
      value={value}
      valueFormatter={_valueFormatter}
      isEmptyValue={_isEmptyValue}
    />
  );

  return (
    <>
      {_Button}

      <TrackerSentencePopup.Popup
        {...props}
        value={value}
        isEmptyValue={_isEmptyValue}
        anchorEl={_anchorEl}
        onClose={_hasOpenedNestedPopups ? undefined : _onClosePopup}>
        <DetailsPopupContent
          {...props}
          onNestedPopupToggle={_onNestedPopupToggle}
          isGameStateCondition={props.isGameStateCondition}
          against={props.against}
        />
      </TrackerSentencePopup.Popup>
    </>
  );
}

export default DetailsSegment;
