import {TSentenceSize} from 'types/sentence-form.types';
import {InputBaseComponentProps, SxProps, Theme} from '@mui/material';
import {InputHTMLAttributes} from 'react';

export const inlineInputSX: SxProps<Theme> = {
  paddingRight: 0,
  '& .MuiInput-underline:before': {
    borderBottomColor: 'rgba(255,255,255, .1)',
  },
};

export function inlineInputProps(
  inputProps: InputHTMLAttributes<HTMLInputElement>,
  inputSize: number,
  size: TSentenceSize,
  isRequired?: boolean,
): InputBaseComponentProps {
  return {
    ...inputProps,
    style: {
      ...inputProps.style,
      fontSize: size === 'medium' ? 24 : 16,
      fontWeight: '500',
      height: 'auto',
      width: 'auto',
      paddingLeft: 2,
    },
    size: inputSize,
    sx: {
      '&::placeholder': {
        color: isRequired ? 'secondary.main' : 'text.primary',
        opacity: 1, // otherwise firefox shows a lighter color
      },
    },
  };
}
