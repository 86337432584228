import React, {useEffect, useState} from 'react';

import {useAutomationForm} from 'context/event-automations/automations-form.context';
import {useTranslation} from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {TRACKER_CALCULATION_OPTIONS} from 'config/event-automation-builder.config';
import {TEventCalculationType, TSequence} from '@my-game-plan/types';

function TrackerCalculationDropdown(): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _automationBuilderContext = useAutomationForm();
  const [_calculationType, _setCalculationType] = useState<
    TEventCalculationType | TSequence
  >('occurrences');

  /*
   * Side effects
   */
  useEffect(() => {
    let _newCalculationType: TEventCalculationType | TSequence =
      _automationBuilderContext.data.calculation || 'occurrences';
    if (_automationBuilderContext.data.action === 'sequence') {
      _newCalculationType = 'sequence';
    }
    _setCalculationType(_newCalculationType);
  }, [
    _automationBuilderContext.data.calculation,
    _automationBuilderContext.data.action,
  ]);

  /*
   * Handlers
   */
  function _onTypeSelect(
    event: SelectChangeEvent<TEventCalculationType | TSequence>,
  ) {
    _automationBuilderContext.onCalculationTypeChange(
      event.target.value as TEventCalculationType | TSequence,
    );
  }

  /*
   * Render
   */
  return (
    <Select
      value={_calculationType}
      onChange={_onTypeSelect}
      renderValue={() =>
        t(`eventAutomations.calculationType.${_calculationType}`)
      }>
      {TRACKER_CALCULATION_OPTIONS.map((option) => {
        return (
          <MenuItem key={option.value} value={option.value}>
            <ListItemIcon>
              <option.icon />
            </ListItemIcon>
            <ListItemText
              primary={option.label}
              secondary={option.description}
            />
          </MenuItem>
        );
      })}
    </Select>
  );
}

export default TrackerCalculationDropdown;
