import React, {useEffect, useState} from 'react';
import SentenceBlock from 'components/trackers/sentence/blocks/sentence-block';
import {ITrackerSentenceProps} from 'types/trackers/tracker-sentence.types';
import SentenceTextSegment from 'components/trackers/sentence/segment/variants/text/sentence-text-segment';
import ActionSegment from 'components/trackers/sentence/segment/instances/action/action-segment';
import {useTranslation} from 'react-i18next';
import TeamSelectSegment from 'components/trackers/sentence/segment/instances/team/team-select-segment';
import MetricSegment from '../segment/instances/metric/metric-segment';
import {
  FIConditionalEventFilters,
  IEventFilters,
  isTrackingAction,
  TTrackerExecutorType,
} from '@my-game-plan/types';
import DetailsSegment from '../segment/instances/details/details-segment';
import SentenceExecutorSegment from '../segment/instances/executor/executor-segment';
import SentenceNumberSegment from '../segment/variants/number/sentence-number-segment';

function SentenceBaseLine(props: ITrackerSentenceProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_shouldShowDetails, _setShouldShowDetails] = useState(false);
  const [_shouldDisplayTeam, _setShouldDisplayTeam] = useState(false);
  const [_shouldDisplayExecutor, _setShouldDisplayExecutor] = useState(false);

  /*
   * Side Effects
   */
  // Define whether details should be shown
  useEffect(() => {
    let _showDetails = true;

    const _hasDetails = props.tracker?.details?.filters.some(
      (f) => f.values?.length,
    );
    if (props.readonly && !_hasDetails) {
      _showDetails = false;
    }

    _setShouldShowDetails(_showDetails);
  }, [props.tracker, props.readonly]);

  useEffect(() => {
    let _newShouldDisplayExecutor = true;
    if (props.readonly && !props.currentExecutorType) {
      _newShouldDisplayExecutor = false;
    }

    if (
      props.readonly &&
      props.hideExecutorIfPossible &&
      !props.tracker?.concrete_executor?.team?.executor_positions?.length
    ) {
      _newShouldDisplayExecutor = false;
    }

    _setShouldDisplayExecutor(_newShouldDisplayExecutor);

    //Define whether "by [team]" should be displayed
    const _typesWithoutTeam: TTrackerExecutorType[] = [
      'player_development',
      'scouting_profile_ids',
    ];
    let _shouldDisplay =
      !props.currentExecutorType ||
      !_typesWithoutTeam.includes(props.currentExecutorType);

    if (
      props.hideExecutorIfPossible &&
      props.readonly &&
      !props.tracker?.against
    ) {
      _shouldDisplay = false;
    }

    _setShouldDisplayTeam(_shouldDisplay && _newShouldDisplayExecutor);
  }, [
    props.readonly,
    props.currentExecutorType,
    props.tracker?.against,
    props.tracker?.concrete_executor,
    props.hideExecutorIfPossible,
  ]);

  /*
   * Handlers
   */
  function _onMetricChange(value: keyof IEventFilters | undefined) {
    if (!props.onChange) {
      return;
    }

    props.onChange({
      metric: value,
    });
  }

  function _onDetailsChange(value: FIConditionalEventFilters) {
    if (!props.onChange) {
      return;
    }

    props.onChange({
      details: value,
    });
  }

  function _onRuleSecondsChange(value: number) {
    if (!props.onChange || !props.tracker?.rule_condition) {
      return;
    }

    props.onChange({
      rule_condition: {
        ...props.tracker?.rule_condition,
        seconds: value,
      },
    });
  }

  /*
   * Render
   */
  const _isRatio = props.tracker?.calculation === 'ratio';
  const _isRule = props.tracker?.calculation === 'rule';

  /* Text - "Count" */
  const _CountTextSegment = (
    <SentenceTextSegment
      {...props}
      value={t('sentenceForm.count')}
      capitalize
    />
  );

  /* Text "amount of" or "percent of" */
  let _PercentOrMetricSegment = null;
  if (_isRatio) {
    _PercentOrMetricSegment = (
      <SentenceTextSegment {...props} value={t('sentenceForm.percentOf')} />
    );
  } else {
    _PercentOrMetricSegment = (
      <>
        <MetricSegment
          {...props}
          value={props.tracker?.metric}
          onChange={_onMetricChange}
        />
        <SentenceTextSegment {...props} value={t('sentenceForm.of')} />
      </>
    );
  }

  /* Action Segment */
  const _includeTrackingActions = props.tracker?.calculation !== 'ratio';
  const _ActionSegment = (
    <ActionSegment
      {...props}
      value={props.tracker}
      verb={_isRule ? 'default' : undefined}
      includeTrackingActions={_includeTrackingActions}
      error={props.errors?.action || props.errors?.pitch_zone}
    />
  );

  /* Text - "by" */
  const _BySegment = (
    <SentenceTextSegment {...props} value={t('sentenceForm.by')} />
  );

  /* Executor */
  const _ExecutorSegment = <SentenceExecutorSegment {...props} />;

  /* by [xxx] OF [xxx] segment */
  let _OfTeamSegment = null;
  if (_shouldDisplayTeam) {
    _OfTeamSegment = (
      <>
        <SentenceTextSegment {...props} value={t('sentenceForm.of')} />
        <TeamSelectSegment {...props} value={props.tracker} />
      </>
    );
  }

  let _DetailsSegment = null;
  if (props.tracker?.action && props.tracker?.calculation === 'ratio') {
    _DetailsSegment = (
      <DetailsSegment
        {...props}
        placeholder={t('sentenceForm.all')}
        value={props.tracker?.details}
        onChange={_onDetailsChange}
        action={props.tracker?.action}
        against={props.tracker?.against}
      />
    );
  }

  /* Rule trackers */
  if (_isRule) {
    const _isTrackingAction =
      props.tracker?.action && isTrackingAction(props.tracker?.action);
    const _MustTextSegment = (
      <SentenceTextSegment {...props} value={t('sentenceForm.must')} />
    );

    let _WithinTextSegment = null;
    let _TimeSegment = null;
    let _SecondsTextSegment = null;
    if (!_isTrackingAction) {
      _WithinTextSegment = (
        <SentenceTextSegment
          {...props}
          value={t('sentenceForm.conditions.within')}
        />
      );

      _TimeSegment = (
        <SentenceNumberSegment
          {...props}
          value={props.tracker?.rule_condition?.seconds}
          onChange={_onRuleSecondsChange}
        />
      );

      _SecondsTextSegment = (
        <SentenceTextSegment
          {...props}
          value={t('sentenceForm.conditions.seconds', {
            count: props.tracker?.rule_condition?.seconds,
          })}
        />
      );
    }

    return (
      <SentenceBlock.Line {...props}>
        {_ExecutorSegment}
        {_OfTeamSegment}
        {_MustTextSegment}
        {_ActionSegment}

        {_WithinTextSegment}
        {_TimeSegment}
        {_SecondsTextSegment}
      </SentenceBlock.Line>
    );
  }

  /* Non-rule trackers */
  return (
    <SentenceBlock.Line {...props}>
      {_CountTextSegment}
      {_PercentOrMetricSegment}
      {_shouldShowDetails && _DetailsSegment}
      {_ActionSegment}
      {_shouldDisplayExecutor && (
        <>
          {_BySegment}
          {_ExecutorSegment}
          {_OfTeamSegment}
        </>
      )}
    </SentenceBlock.Line>
  );
}

export default SentenceBaseLine;
