import {useVideo} from 'context/video/video.context';
import {TVideoSourceType} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import SwitchVideoIcon from '@mui/icons-material/SwitchVideo';

import {useTranslation} from 'react-i18next';
import {lighten, useTheme} from '@mui/material';

function VideoSourceSelector(): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _videoContext = useVideo();
  const _theme = useTheme();
  const [_availableSources, _setAvailableSources] = useState<
    TVideoSourceType[]
  >([]);
  const [_anchorEl, _setAnchorEl] = useState<null | HTMLElement>(null);

  /*
   * Side effects
   */
  // Define available video sources
  useEffect(() => {
    const _foundSourcesWithVideo: TVideoSourceType[] = [];
    if (!_videoContext.videoSourceConfig) {
      _setAvailableSources([]);
      return;
    }

    for (const [_sourceType, _videoSourceConfig] of Object.entries(
      _videoContext.videoSourceConfig,
    )) {
      if (
        _videoSourceConfig.offsets &&
        _videoSourceConfig.status === 'finished'
      ) {
        _foundSourcesWithVideo.push(_sourceType as TVideoSourceType);
      }
    }

    _setAvailableSources(_foundSourcesWithVideo);
  }, [_videoContext.videoSourceConfig]);

  /*
   * Handlers
   */
  function _onSourceChange(source: TVideoSourceType) {
    _videoContext.setDefaultVideoSource(source); // User has chosen video source so has default gets overwritten
    _videoContext.changeVideoSource(source);
  }

  function _onMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
    _setAnchorEl(event.currentTarget);
  }
  function _onMenuClose() {
    _setAnchorEl(null);
  }

  /*
   * Render
   */
  if (!_videoContext.videoSource || _availableSources.length < 2) {
    return null;
  }

  const PAPER_BG = lighten(_theme.palette.background.default, 0.15);
  return (
    // <Select
    //   value={_videoContext.videoSource}
    //   sx={{fontSize: 14}}
    //   onChange={_onSourceChange}>
    //   {_availableSources.map((source) => {
    //     return (
    //       <MenuItem key={source} value={source}>
    //         {t(`video-player.source.${source}`)}
    //       </MenuItem>
    //     );
    //   })}
    // </Select>
    <>
      <Button
        onClick={_onMenuOpen}
        startIcon={<SwitchVideoIcon />}
        sx={{color: 'text.secondary'}}>
        {t(`video-player.source.${_videoContext.videoSource}`)}
      </Button>
      <Menu
        open={Boolean(_anchorEl)}
        onClose={_onMenuClose}
        anchorEl={_anchorEl}
        transformOrigin={{vertical: 'bottom', horizontal: 'right'}}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        slotProps={{
          paper: {
            sx: {
              bgcolor: PAPER_BG,
            },
          },
        }}>
        {_availableSources.map((source) => {
          return (
            <MenuItem
              key={source}
              value={source}
              selected={source === _videoContext.videoSource}
              onClick={() => _onSourceChange(source)}>
              {t(`video-player.source.${source}`)}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default VideoSourceSelector;
