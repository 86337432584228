import {IButtonValueSegment} from 'components/filter/header-filter-bar/buttons/header-filter-button.view';
import {IPagination} from 'types/api.types';
import React, {useEffect, useState} from 'react';

import PersonIcon from '@mui/icons-material/Person';
import {useTranslation} from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {SxProps, Theme} from '@mui/material';

export const LABEL_STYLE: SxProps<Theme> = {
  whiteSpace: 'pre',
  textTransform: 'none',
  fontWeight: '300',
  lineHeight: 1,
  fontSize: 14,
  color: 'secondary.main',
};
const LABEL_VALUE_STYLE: SxProps<Theme> = {
  fontWeight: '600',
  color: 'secondary.main',
};

export interface IFilterBarPaginationButtonProps {
  pagination: IPagination | null;
}
function FilterBarPaginationButton(
  props: IFilterBarPaginationButtonProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_buttonValues, _setButtonValues] = useState<IButtonValueSegment[][]>(
    [],
  );

  /*
   * Side Effects
   */
  useEffect(() => {
    let _startEndText = '-';
    let _totalText = '-';

    if (props.pagination) {
      const _start =
        (props.pagination.current_page - 1) * props.pagination.per_page;
      const _end = Math.min(
        props.pagination.total_results,
        _start + props.pagination.per_page,
      );

      _startEndText = `${_start + 1}-${_end}`;

      _totalText = props.pagination.total_results.toString();
    }
    _setButtonValues([
      [
        {
          value: _startEndText,
          type: 'value',
        },
        {
          value: t('scouting.pagination.of'),
          type: 'default',
        },
        {
          value: _totalText,
          type: 'value',
        },
        {
          value: t('scouting.pagination.players', {
            count: props.pagination?.total_results || 0,
          }),
          type: 'default',
        },
      ],
    ]);
  }, [props.pagination]);

  /*
   * Handlers
   */

  /*
   * Render
   */

  return (
    <Stack component="span" direction="row" gap={0.5} alignItems="center">
      <PersonIcon fontSize="small" sx={{color: 'secondary.main'}} />

      {/* Prefix */}
      <Typography sx={LABEL_STYLE}>
        {t('scouting.pagination.showing')}
      </Typography>

      {/* Values */}
      {_buttonValues.map((value, valueIndex) => {
        return value.map((subValue, subValueIndex) => {
          // Text
          let _style = LABEL_STYLE;
          if (subValue.type === 'value') {
            _style = {
              ..._style,
              ...LABEL_VALUE_STYLE,
            } as SxProps<Theme>;
          }

          return (
            <Typography
              key={`${valueIndex}-${subValueIndex}-${subValue.value}`}
              sx={_style}>
              {subValue.value}
            </Typography>
          );
        });
      })}
    </Stack>
  );
}

export default FilterBarPaginationButton;
