import {INavigationRoute} from 'types/navigation.types';
import React, {useEffect, useState} from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {NavLink, useLocation} from 'react-router-dom';
import {getRoutePath} from 'helpers/navigation.helpers';

interface IScreenHeaderTabsProps {
  tabs?: INavigationRoute[];
}

function ScreenHeaderTabs(props: IScreenHeaderTabsProps): JSX.Element | null {
  /*
   * Hooks n State
   */
  const _location = useLocation();
  const [_activeTab, _setActiveTab] = useState<string>(
    props.tabs?.[0]?.path || '',
  );

  /*
   * Side Effects
   */
  useEffect(() => {
    if (!props.tabs?.length) {
      return;
    }
    let _newActiveTab = props.tabs[0].path;
    props.tabs.forEach((tab) => {
      if (_location.pathname.includes(tab.path)) {
        _newActiveTab = tab.path;
      }
    });

    _setActiveTab(_newActiveTab);
  }, [_location.pathname, props.tabs]);

  /*
   * Render
   */

  if (!props.tabs) {
    return null;
  }
  return (
    <Tabs value={_activeTab} sx={{ml: -2, minHeight: 0}}>
      {props.tabs.map((tab) => {
        let _icon: undefined | JSX.Element = undefined;
        if (tab.icon) {
          _icon = <tab.icon />;
        }
        return (
          <Tab
            icon={_icon}
            iconPosition="start"
            key={tab.path}
            label={tab.label}
            value={tab.path}
            component={NavLink}
            to={getRoutePath(tab.path, true)}
            sx={{
              minHeight: 0,
              fontSize: 12,
            }}
          />
        );
      })}
    </Tabs>
  );
}

export default ScreenHeaderTabs;
