import {Resource} from 'types/api.types';
import {AUTH_API_CONFIG, USERS_API_CONFIG} from 'config/api.config';
import {useAPI} from 'hooks/api.hooks';
import {REQUEST_ERRORS} from 'config/errors.config';
import {FIUserFormatted, PLATFORM} from '@my-game-plan/types';

const api = useAPI();

const authenticateUser = async (name: string, password: string) => {
  try {
    const {data} = await api.post<FIUserFormatted>({
      data: {name, password},
      resource: Resource.auth,
      url: AUTH_API_CONFIG.authenticateUser,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (err: any) {
    throw new Error(err.message);
  }
};

const fetchUser = async (id: string, initial?: true) => {
  try {
    const {data} = await api.get<FIUserFormatted>({
      resource: Resource.users,
      url: USERS_API_CONFIG.fetchUser(id),
      params: {initial: initial, source: PLATFORM.WEB},
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (err) {
    return null;
  }
};

async function logoutUser() {
  await api.get({
    resource: Resource.auth,
    url: AUTH_API_CONFIG.logoutUser,
  });
}

export {authenticateUser, fetchUser, logoutUser};
