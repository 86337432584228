import React from 'react';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';

import {ButtonProps} from '@mui/material';
export interface ISetupScreenButton {
  cta: string;
  onClick: () => void;
  isLoading?: boolean;
  muiButtonProps?: ButtonProps;
  hidden?: boolean;
}
export interface IProfileSetupBottomControlsProps {
  currentStepIndex?: number;
  totalSteps?: number;
  prevButton?: ISetupScreenButton;
  nextButton?: ISetupScreenButton;
}

function ProfileSetupBottomControls(
  props: IProfileSetupBottomControlsProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */

  /*
   * Side effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */
  if (!props.totalSteps) {
    return null;
  }

  let _Buttons = null;

  if (props.prevButton || props.nextButton) {
    _Buttons = (
      <Stack direction="row" gap={3}>
        {props.prevButton && (
          <Button color="secondary" onClick={props.prevButton.onClick}>
            {props.prevButton.cta}
          </Button>
        )}
        {props.nextButton && (
          <LoadingButton
            {...props.nextButton.muiButtonProps}
            loading={props.nextButton.isLoading}
            color="primary"
            variant="contained"
            onClick={props.nextButton.onClick}>
            {props.nextButton.cta}
          </LoadingButton>
        )}
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      borderTop={1}
      borderColor="divider"
      px={4}
      height={80}
      gap={4}
      alignItems={'center'}
      justifyContent="space-between">
      <LinearProgress
        sx={{width: 160}}
        variant="determinate"
        value={((props.currentStepIndex || 0) / (props.totalSteps - 1)) * 100}
      />
      {_Buttons}
    </Stack>
  );
}

export default ProfileSetupBottomControls;
