import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import {useTranslation} from 'react-i18next';
import {TFileCategory, UPLOAD_CATEGORIES} from '@my-game-plan/types';
import {useFileUploads} from 'context/file-uploads.context';

function FilesTableFilters(): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _fileUploadsContest = useFileUploads();

  /*
   * Handlers
   */
  function _onCategoriesChange(event: SelectChangeEvent<TFileCategory[]>) {
    const _values = event.target.value as TFileCategory[];
    _fileUploadsContest.setFilters({
      ..._fileUploadsContest.filters,
      category: _values,
    });
    //
  }

  function _onIncludeExpiredChange() {
    _fileUploadsContest.setFilters({
      ..._fileUploadsContest.filters,
      include_expired: !_fileUploadsContest.filters.include_expired,
    });
  }

  /*
   * Render
   */

  return (
    <Stack direction="row" spacing={4}>
      <FormControlLabel
        componentsProps={{typography: {color: 'text.secondary'}}}
        control={
          <Checkbox
            onChange={_onIncludeExpiredChange}
            checked={_fileUploadsContest.filters.include_expired || false}
          />
        }
        label={t('uploads.filters.showExpired')}
      />
      <FormControl sx={{width: 240}}>
        <InputLabel id="filter-categories-label">
          {t('uploads.fields.category')}
        </InputLabel>
        <Select
          label={t('uploads.fields.category')}
          multiple
          placeholder={t('uploads.values.allCategories')}
          variant="outlined"
          labelId="filter-categories-label"
          value={_fileUploadsContest.filters.category || []}
          renderValue={(selected) =>
            selected.map((cat) => t(`uploads.categories.${cat}`)).join(', ')
          }
          onChange={_onCategoriesChange}>
          {UPLOAD_CATEGORIES.map((category) => (
            <MenuItem key={category} value={category}>
              <ListItemText>{t(`uploads.categories.${category}`)}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}

export default FilesTableFilters;
