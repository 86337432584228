import React from 'react';

import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

function ListItemSkeleton() {
  return (
    <Stack gap={1} px={3} py={3} borderTop={1} borderColor="divider">
      <Typography variant="body1" width={120}>
        <Skeleton />
      </Typography>
      <Typography variant="body2">
        <Skeleton />
      </Typography>
    </Stack>
  );
}

export default ListItemSkeleton;
