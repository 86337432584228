import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import {useAutomationForm} from 'context/event-automations/automations-form.context';

function TrackerTitleInput(): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  const _automationBuilderContext = useAutomationForm();
  const _spanRef = useRef<HTMLElement | null>(null);

  /*
   * Side Effects
   */
  const [width, setWidth] = useState(0);

  // Update width based on text content
  useEffect(() => {
    if (_spanRef.current) {
      setWidth(_spanRef.current.offsetWidth);
    }
  }, [_automationBuilderContext.data.title]);

  /*
   * Handlers
   */
  function _onTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
    _automationBuilderContext.setData({
      title: event.target.value,
    });
  }

  /*
   * Render
   */

  const _placeholder = t('eventAutomations.titleInput.placeholder');
  const _valueText = _automationBuilderContext.data.title || _placeholder;
  return (
    <>
      <span
        ref={_spanRef}
        style={{
          top: 0,
          left: 0,
          transform: 'translate(-100%, -100%)',
          position: 'absolute',
          visibility: 'hidden',
          whiteSpace: 'pre',
          fontFamily: 'inherit',
        }}>
        <Typography variant="body1">{_valueText}</Typography>
      </span>
      <TextField
        size="small"
        onChange={_onTitleChange}
        value={_automationBuilderContext.data.title || ''}
        placeholder={t(_placeholder)}
        variant="outlined"
        sx={{
          width: width + 32,
          maxWidth: 640,
        }}
        inputProps={{
          style: {
            minWidth: '20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
      />
    </>
  );
}

export default TrackerTitleInput;
