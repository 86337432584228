import React, {useEffect, useState} from 'react';

import {FITrackerSubject} from '@my-game-plan/types';
import {useAutomationForm} from 'context/event-automations/automations-form.context';

import ArrowDropdownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';

import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';

import {useTranslation} from 'react-i18next';
import {ITypedOption} from 'types/option.types';
import {useTeams} from 'context/team.context';
import {getPlayerOptions} from 'helpers/automation/automation-form.helper';
import OptionsList from '../../variants/popup/options/options-list';

interface IAddExecutorButtonProps {
  subjects: FITrackerSubject[];
  onChange: (value: FITrackerSubject[]) => void;
}

function AddExecutorButton(props: IAddExecutorButtonProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _trackerBuilderContext = useAutomationForm();
  const _teamsContext = useTeams();
  const [_popupAnchorEl, _setPopupAnchorEl] = React.useState<Element | null>(
    null,
  );
  const [_playerOptions, _setPlayerOptions] = useState<ITypedOption<string>[]>(
    [],
  );
  const [_selectedPlayerIds, _setSelectedPlayerIds] = useState<string[]>([]);

  /*
   * Side effects
   */
  // Define players options
  useEffect(() => {
    const _generatedPlayerOptions = getPlayerOptions(_teamsContext.ownPlayers);
    _setPlayerOptions(_generatedPlayerOptions);
  }, [_teamsContext.ownPlayers]);

  useEffect(() => {
    const _foundPlayerIds: string[] = [];
    props.subjects.forEach((subject) => {
      if (subject.executor.player_id) {
        _foundPlayerIds.push(subject.executor.player_id);
      }
    });

    _setSelectedPlayerIds(_foundPlayerIds);
  }, [props.subjects]);

  /*
   * Handlers
   */
  function _onClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (_trackerBuilderContext.currentExecutorType === 'player_development') {
      _setPopupAnchorEl(event.currentTarget);
    } else {
      const _newSubjects = [...props.subjects];
      _newSubjects.push({
        executor: {team: {}},
      });
      props.onChange(_newSubjects);
    }
  }

  function _onPopoverClose() {
    _setPopupAnchorEl(null);
  }

  function _onPlayersChange(value: string[]) {
    // const _newSubjects = [...props.subjects];
    // _newSubjects.push({
    //   executor: {player_id: value},
    // });
    // props.onChange(_newSubjects);
    // _onPopoverClose();
    // console.log('[AddExecutorButton] _onPlayersChange - Value:', value);

    // Filter out subjects that have been removed;
    const _exitstingSubjectsToKeep = props.subjects.filter(
      (subject) =>
        subject.executor.player_id &&
        value.includes(subject.executor.player_id),
    );

    const _playerIdsToAdd = value.filter(
      (playerId) =>
        !_exitstingSubjectsToKeep.some(
          (subject) => subject.executor.player_id === playerId,
        ),
    );
    const _newSubjects: FITrackerSubject[] = _playerIdsToAdd.map(
      (playerId) => ({
        executor: {player_id: playerId},
        linked_subjects: {
          player_ids: [playerId],
        },
      }),
    );

    props.onChange([..._exitstingSubjectsToKeep, ..._newSubjects]);
  }

  /*
   * Render
   */
  let _LeftIcon = null;
  let _RightIcon = null;
  let _label = t('sentenceForm.executor.addPlayers');
  if (_trackerBuilderContext.currentExecutorType === 'player_development') {
    _label = t('sentenceForm.executor.selectPlayers');
    _RightIcon = <ArrowDropdownIcon />;
  } else {
    _LeftIcon = <AddIcon />;
  }

  function _positonGroupHeader(text: string): string {
    return t(`playerPosition.${text}s`);
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={_LeftIcon}
        endIcon={_RightIcon}
        onClick={_onClick}>
        {_label}
      </Button>
      <Popover
        open={Boolean(_popupAnchorEl)}
        anchorEl={_popupAnchorEl}
        anchorOrigin={{vertical: 'center', horizontal: 'right'}}
        transformOrigin={{vertical: 'center', horizontal: 'left'}}
        onClose={_onPopoverClose}>
        <OptionsList
          multiple
          options={_playerOptions}
          value={_selectedPlayerIds}
          onChange={_onPlayersChange}
          groupHeader={_positonGroupHeader}
        />
      </Popover>
    </>
  );
}

export default AddExecutorButton;
