import React from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import Stack from '@mui/material/Stack';

import CloseIcon from '@mui/icons-material/Close';
import TrackerCalculationDropdown from '../inputs/tracker-calculation-dropdown';
import TrackerExecutorTypeToggle from './executor-type-toggle/tracker-executor-type-toggle';

interface ITrackerBuilderHeaderProps {
  onClose: () => void;
}

function TrackerBuilderHeader(props: ITrackerBuilderHeaderProps): JSX.Element {
  /*
   * Hooks n State
   */

  /*
   * Side Effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */

  return (
    <DialogTitle>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {/* Calculation */}
        <TrackerCalculationDropdown />

        <Stack direction="row" alignItems="center" gap={6}>
          {/* Type Switcher */}
          <TrackerExecutorTypeToggle />

          {/* Close */}
          <IconButton
            aria-label="close"
            onClick={props.onClose}
            color="secondary">
            <CloseIcon />
          </IconButton>
        </Stack>
      </Stack>
    </DialogTitle>
  );
}

export default TrackerBuilderHeader;
