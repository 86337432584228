import React from 'react';
import {Route, Routes} from 'react-router-dom';

import OpponentAutomationsView from 'views/automations/opponent-automations.view';
import OpponentAutomationDetail from 'views/automations/opponent-automation_detail.view';

export const OpponentAutomationRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<OpponentAutomationsView />} />

      <Route path="statistics/:id" element={<OpponentAutomationDetail />} />
    </Routes>
  );
};
