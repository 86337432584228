import React from 'react';
import {ITypedOption} from 'types/option.types';
import {AutocompleteRenderOptionState} from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

interface IAutocompleteOptionProps<T> {
  optionProps: React.HTMLAttributes<HTMLLIElement>;
  option: ITypedOption<T>;
  state: AutocompleteRenderOptionState;
  multiple?: boolean;
}

function AutocompleteOption<T>(props: IAutocompleteOptionProps<T>) {
  const _Icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const _CheckedIcon = <CheckBoxIcon fontSize="small" />;

  let _Content = (
    <Stack
      {...props.optionProps}
      component="li"
      key={`${props.option.value}`}
      paddingRight={'24px !important'}
      flexDirection="row"
      gap={1}
      alignItems={'center'}>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        {props.option.image_url && (
          <img
            src={props.option.image_url}
            alt={''}
            style={{width: 20, height: 20, borderRadius: '4px'}}
          />
        )}
        <Box>{props.option.label}</Box>
      </Stack>
    </Stack>
  );

  if (props.multiple) {
    _Content = (
      <Stack
        {...props.optionProps}
        component="li"
        key={`${props.option.value}`}
        sx={{paddingLeft: '8px !important', pr: 3}}
        flexDirection="row"
        alignItems="center">
        <Checkbox
          icon={_Icon}
          checkedIcon={_CheckedIcon}
          sx={{mr: 0.5}}
          size="small"
          checked={props.state.selected}
        />
        <Stack flexDirection="row" alignItems="center" gap={1}>
          {props.option.image_url && (
            <img
              src={props.option.image_url}
              alt={''}
              style={{width: 20, height: 20, borderRadius: '4px'}}
            />
          )}
          <Box>{props.option.label}</Box>
        </Stack>
      </Stack>
    );
  }

  if (!props.option.Tooltip) {
    return _Content;
  }

  return (
    <Tooltip
      key={`tooltip-${props.option.value}`}
      title={props.option.Tooltip}
      placement="right-start"
      sx={{
        padding: 0,
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'transparent',
            p: 0,
            borderWidth: 8,
            borderColor: 'background.default',
            borderStyle: 'solid',
          },
        },
      }}
      PopperProps={{
        sx: {
          width: 200,
        },
      }}>
      {_Content}
    </Tooltip>
  );
}

export default AutocompleteOption;
