import React, {useEffect} from 'react';

import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import {IGroupedTypedOptions} from 'types/option.types';
import {darken, lighten, useTheme} from '@mui/material';

interface IOptionsListSubheaderProps<
  T extends string | number,
  Multiple extends boolean = false,
> {
  selectedValues: T[];
  multiple?: Multiple;
  onChange: (value: T[]) => void;
  groupHeader?: (key: string) => string;
  optionGroup: IGroupedTypedOptions<T>;
}

function OptionsListSubheader<
  T extends string | number,
  Multiple extends boolean = false,
>(props: IOptionsListSubheaderProps<T, Multiple>): JSX.Element {
  /*
   * Hooks n State
   */
  const _theme = useTheme();

  const [_areAllItemsSelected, _setAreAllItemsSelected] =
    React.useState<boolean>(false);
  const [_paperBG, _setPaperBG] = React.useState<string>(
    _theme.palette.background.paper,
  );
  const [_paperBGHover, _setPaperBGHover] = React.useState<string>(
    _theme.palette.background.paper,
  );

  /*
   * Side effects
   */
  // Define colors
  useEffect(() => {
    _setPaperBG(darken(_theme.palette.background.paper, 0.1));
    _setPaperBGHover(lighten(_theme.palette.background.paper, 0.1));
  }, [_theme]);

  // Check whether all items are selected
  useEffect(() => {
    if (!props.multiple) {
      return;
    }

    const _selectedValues = props.optionGroup.options.map(
      (option) => option.value,
    );
    const _areAllItemsSelected = _selectedValues.every((value) =>
      props.selectedValues.includes(value),
    );
    _setAreAllItemsSelected(_areAllItemsSelected);
  }, [props.selectedValues, props.optionGroup]);

  /*
   * Handlers
   */
  function _onChange() {
    if (!props.multiple) {
      return;
    }

    let _newSelectedValues: T[] = [];

    if (_areAllItemsSelected) {
      // Deselect all
      const _groupValues = props.optionGroup.options.map(
        (option) => option.value,
      );
      _newSelectedValues = props.selectedValues.filter(
        (value) => !_groupValues.includes(value),
      );
    } else {
      const _newSelectedValuesToAdd = props.optionGroup.options
        .filter((option) => !props.selectedValues.includes(option.value))
        .map((option) => option.value);
      _newSelectedValues = [
        ...props.selectedValues,
        ..._newSelectedValuesToAdd,
      ];
    }

    props.onChange(_newSelectedValues);
  }

  /*
   * Render
   */
  let _Checkbox = null;
  if (props.multiple) {
    _Checkbox = (
      <ListItemIcon>
        <Checkbox
          edge="start"
          tabIndex={-1}
          disableRipple
          checked={_areAllItemsSelected}
        />
      </ListItemIcon>
    );
  }
  return (
    <MenuItem
      sx={{
        position: 'sticky',
        zIndex: 1,
        top: 0,
        bgcolor: _paperBG,
        '&:hover': {
          bgcolor: props.multiple ? _paperBGHover : _paperBG,
        },
        '&.Mui-disabled': {
          opacity: 1,
        },
        py: props.multiple ? undefined : 1,
      }}
      disabled={!props.multiple}
      onClick={props.multiple ? _onChange : undefined}>
      {_Checkbox}
      <ListItemText
        primary={props.optionGroup.label}
        primaryTypographyProps={{variant: 'caption'}}
      />
    </MenuItem>
  );
}

export default OptionsListSubheader;
