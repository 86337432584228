import React, {useEffect, useState} from 'react';
import {
  ITrackerSentenceProps,
  TError,
} from 'types/trackers/tracker-sentence.types';

import Stack from '@mui/material/Stack';
import {
  FIEventCondition,
  FIEventConditions,
  TEventConditionType,
  TQueryOperatorType,
} from '@my-game-plan/types';
import AddConditionButton from './add-condition-button';
import SentenceCondition from './condition';
import TrackerSentenceSelect from '../segment/variants/select/sentence-select-segment';
import {OPERATOR_OPTIONS} from 'config/event-automation-builder.config';
import SentenceTextSegment from '../segment/variants/text/sentence-text-segment';
import {useTranslation} from 'react-i18next';
import ToggleConditionsButton from './toggle-conditions-button';
import {SxProps, Theme} from '@mui/material';

function SentenceConditions(props: ITrackerSentenceProps): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_shouldDisplayConditionsInPopup, _setShouldDisplayConditionsInPopup] =
    useState(false);

  /*
   * Side effects
   */
  useEffect(() => {
    let _newDisplayConditionsInPopupValue = false;
    if (
      (props.readonly &&
        props.size === 'large' &&
        props.tracker?.conditions?.conditions.length &&
        props.tracker?.conditions?.conditions.length > 1) ||
      (props.readonly &&
        props.tracker?.conditions?.conditions.length &&
        props.size !== 'large')
    ) {
      _newDisplayConditionsInPopupValue = true;
    }

    _setShouldDisplayConditionsInPopup(_newDisplayConditionsInPopupValue);
  }, [props.tracker?.conditions, props.size, props.readonly]);

  /*
   * Handlers
   */
  function _onOperatorChange(value: TQueryOperatorType) {
    if (!props.tracker?.conditions || !props.onChange) {
      return;
    }
    props.onChange({
      conditions: {
        ...props.tracker.conditions,
        operator: value,
      },
    });
  }
  function _onNewConditionTypeSelect(type: TEventConditionType) {
    if (!props.onChange) {
      return;
    }
    const _newConditionsList = [
      ...(props.tracker?.conditions?.conditions || []),
    ];
    _newConditionsList.push({
      type: type,
      ...(type !== 'when' && {
        seconds: 5,
      }),
      executor: {},
      against: props.tracker?.against || false,
      details: {
        operator: 'and',
        filters: [],
      },
    });
    const _newConditions: FIEventConditions = {
      operator: props.tracker?.conditions?.operator || 'and',
      conditions: _newConditionsList,
    };
    props.onChange({
      conditions: _newConditions,
    });
  }

  function _onConditionChange(index: number, data: Partial<FIEventCondition>) {
    if (props.tracker?.conditions && props.onChange) {
      const _conditionsList: FIEventCondition[] = [
        ...(props.tracker.conditions.conditions || []),
      ];

      const _updatedCondition: FIEventCondition = {
        ..._conditionsList[index],
        ...data,
      };

      _conditionsList[index] = _updatedCondition;

      props.onChange({
        conditions: {
          ...props.tracker.conditions,
          conditions: _conditionsList,
        },
      });
    }
  }

  function _onConditionDelete(index: number) {
    if (props.onChange && props.tracker?.conditions) {
      const _conditionsList: FIEventCondition[] = [
        ...(props.tracker.conditions?.conditions || []),
      ];
      _conditionsList.splice(index, 1);

      props.onChange({
        conditions: {
          ...props.tracker.conditions,
          conditions: _conditionsList,
        },
      });
    }
  }

  /*
   * Render
   */
  // If no conditions exist, return null
  if (
    (!props.isValid && !props.tracker?.conditions?.conditions.length) ||
    (props.readonly && !props.tracker?.conditions?.conditions.length)
  ) {
    return null;
  }

  //Define conditions list with "and/or" operators in between
  let _ConditionsList = null;
  if (props.tracker?.conditions?.conditions.length) {
    _ConditionsList = (
      <Stack gap={2}>
        {props.tracker.conditions.conditions.map(
          (condition, conditionIndex) => {
            let _Operator = null;
            let _size = props.size;
            if (_shouldDisplayConditionsInPopup) {
              _size = 'medium';
            }

            if (
              props.tracker?.conditions &&
              !props.readonly &&
              props.tracker.conditions.conditions.length > 1 &&
              conditionIndex === 0
            ) {
              _Operator = (
                <Stack alignItems="flex-start">
                  <TrackerSentenceSelect
                    {...props}
                    size={_size}
                    options={OPERATOR_OPTIONS}
                    value={props.tracker.conditions.operator}
                    onChange={_onOperatorChange}
                  />
                </Stack>
              );
            } else if (
              props.tracker?.conditions &&
              conditionIndex < props.tracker.conditions.conditions.length - 1
            ) {
              const _operatorLabel = t(
                `sentenceForm.${props.tracker.conditions.operator}`,
              );
              _Operator = (
                <SentenceTextSegment
                  {...props}
                  size={_size}
                  value={_operatorLabel}
                />
              );
            }

            let _errors = undefined;
            if (
              props.errors?.conditions &&
              props.errors.conditions[conditionIndex]
            ) {
              _errors = props.errors.conditions[
                conditionIndex
              ] as TError<FIEventCondition>;
            }

            return (
              <Stack key={conditionIndex} gap={2}>
                <SentenceCondition
                  {...props}
                  size={_size}
                  condition={condition}
                  conditionIndex={conditionIndex}
                  onChange={_onConditionChange}
                  onDelete={_onConditionDelete}
                  errors={_errors}
                />
                {_Operator}
              </Stack>
            );
          },
        )}
      </Stack>
    );
  }

  let _Content = _ConditionsList;
  if (_shouldDisplayConditionsInPopup) {
    _Content = (
      <ToggleConditionsButton
        conditionsCount={props.tracker?.conditions?.conditions.length || 0}
        ConditionsList={_ConditionsList}
      />
    );
  }

  // Render component
  let _mY = 1;
  let _pY = 1;
  let _pL = 2;
  let _border: SxProps<Theme> = {
    borderLeftStyle: 'dashed',
    borderColor: 'secondary.main',
    borderLeftWidth: 1,
  };
  if (_shouldDisplayConditionsInPopup) {
    _mY = 0;
    _pY = 0.5;
    _pL = 0;
    _border = {};
  }
  return (
    <Stack
      my={_mY}
      pl={_pL}
      py={_pY}
      gap={4}
      sx={{
        ..._border,
      }}>
      {_Content}
      {props.isValid && !props.readonly && (
        <AddConditionButton onChange={_onNewConditionTypeSelect} />
      )}
    </Stack>
  );
}

export default SentenceConditions;
