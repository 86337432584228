import React, {useEffect} from 'react';
import {generateUniqueTrackerKey} from 'helpers/automation/automation-form.helper';
import {
  FICompactMatchInfo,
  FIDisplayPlayer,
  FIFormattedAutomation,
  TTrackerExecutorType,
} from '@my-game-plan/types';
import TrackersTableRow, {ITrackerTableCell} from './trackers-table-row';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import {useTranslation} from 'react-i18next';
import {ILinkState} from 'types/navigation.types';

interface ITrackersTableProps {
  trackers: FIFormattedAutomation[];
  executorType: TTrackerExecutorType;
  concreteExecutorInfo?: FIDisplayPlayer;
  per90?: boolean;
  backLink?: ILinkState;
  matches: FICompactMatchInfo[];
}

function TrackersTable(props: ITrackersTableProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_cells, _setCells] = React.useState<ITrackerTableCell[]>([]);

  /*
   * Side Effects
   */
  useEffect(() => {
    const _generatedCells: ITrackerTableCell[] = [
      // Title cell
      {
        label: t('eventAutomations.trackers', {count: 1}),
        key: 'title',
      },

      // Percentile
      {
        label: t('eventAutomations.benchmark.ranking.title'),
        key: 'percentile',
      },

      // Form
      {
        label: t('eventAutomations.properties.form'),
        key: 'form',
      },

      // Average
      {
        label: t(
          `eventAutomations.benchmark.ranking.${
            props.per90 ? 'averagePer90' : 'averageShort'
          }`,
        ),
        key: 'average',
        align: 'center',
      },

      // Buttons
      {
        label: '',
        key: 'buttons',
        align: 'right',
      },
    ];
    _setCells(_generatedCells);
  }, [props.trackers, props.per90]);

  /*
   * Handlers
   */

  /*
   * Render
   */

  return (
    <Table>
      <TableHead>
        <TableRow>
          {_cells.map((cell) => {
            return (
              <TableCell
                key={cell.key}
                align={cell.align || 'left'}
                sx={{borderBottom: 0, pb: 0}}>
                {cell.label || ' '}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.trackers.map((tracker) => {
          const _key = generateUniqueTrackerKey(
            tracker,
            tracker.concrete_executor,
          );
          return (
            <TrackersTableRow
              key={_key}
              tracker={tracker}
              executorType={props.executorType}
              cells={_cells}
              per90={props.per90}
              concreteExecutorInfo={props.concreteExecutorInfo}
              backLink={props.backLink}
              matches={props.matches}
            />
          );
        })}
      </TableBody>
    </Table>
  );
}

export default TrackersTable;
