import React, {memo, useEffect, useRef, useState} from 'react';
import styles from './edit.module.scss';
import Draggable, {DraggableData} from 'react-draggable'; // The default
import {formatVideoTime} from 'helpers/video.helper';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import classNames from 'classnames';
import {isFinite, isNaN} from 'lodash';
import {editVideoConstants, MAX_EDIT_OFFSET} from 'config/clips.config';
import {useTranslation} from 'react-i18next';
import {useVideoEdit} from 'context/video/video-edit.context';
import {useVideo} from 'context/video/video.context';
import {Skeleton} from '@mui/material';

// TODO The logic of this component is always running when the video player is open. Maybe look at this when we encounter performance issues (should be pretty light though)
function Edit() {
  const {
    currentClip,
    isEditMenuOpen,
    played,
    setTime,
    setPlaying,
    setVideoBufferingStatus,
    videoBufferingStatus,
  } = useVideo();

  const {
    editLeftTrimPos,
    editRightTrimPos,
    setEditLeftTrimPos,
    setEditRightTrimPos,
    changeVideoDuration,
    totalVideoOffsets,
  } = useVideoEdit();

  const _timeline = useRef<HTMLDivElement>(null);
  const _dummy_timestamp = useRef<HTMLParagraphElement>(null);

  const [_timelineWidth, _setTimelineWidth] = useState(0);
  const [_isDragging, _setIsDragging] = useState(false);
  const [_selectedTimePos, _setSelectedTimePos] = useState(editLeftTrimPos);
  const [_selectedTimeWidth, _setSelectedTimeWidth] = useState(
    editRightTrimPos - editLeftTrimPos,
  );

  const [_canLoadVideoBefore, _setCanLoadVideoBefore] =
    useState<boolean>(false);
  const [_canLoadVideoAfter, _setCanLoadVideoAfter] = useState<boolean>(false);

  const {t} = useTranslation();

  useEffect(() => {
    let _newCanLoadVideoBeforeState = true;
    let _newCanLoadVideoAfterState = true;
    if (totalVideoOffsets[0] >= MAX_EDIT_OFFSET) {
      _newCanLoadVideoBeforeState = false;
    }

    if (totalVideoOffsets[1] >= MAX_EDIT_OFFSET) {
      _newCanLoadVideoAfterState = false;
    }

    _setCanLoadVideoBefore(_newCanLoadVideoBeforeState);
    _setCanLoadVideoAfter(_newCanLoadVideoAfterState);
  }, [totalVideoOffsets]);

  useEffect(() => {
    if (_isDragging || !isEditMenuOpen) return;
    if (videoBufferingStatus == 'buffering') {
      if (played.loaded >= editVideoConstants.endLoadTime) {
        setVideoBufferingStatus('complete');
      } else setTime(played.loaded);
    } else if (
      played.played > editRightTrimPos ||
      played.played < editLeftTrimPos
    ) {
      setTime(editLeftTrimPos);
    }
  }, [isEditMenuOpen, played, _isDragging, videoBufferingStatus]);

  // // Update location and width of overlay
  useEffect(() => {
    if (
      !(
        isFinite(editLeftTrimPos || isNaN(editLeftTrimPos)) &&
        (isFinite(editRightTrimPos) || isNaN(editRightTrimPos))
      )
    )
      return;

    _setSelectedTimePos(editLeftTrimPos);
    _setSelectedTimeWidth(editRightTrimPos - editLeftTrimPos);
  }, [editLeftTrimPos, editRightTrimPos]);

  const _handleResize = () => {
    if (!_timeline.current) return;
    _setTimelineWidth(_timeline.current.offsetWidth);
  };

  // Once timeline is mounted
  useEffect(() => {
    _handleResize();
    window.addEventListener('resize', _handleResize, false);

    return () => {
      window.removeEventListener('resize', _handleResize);
    };
  }, [_timeline, _timeline.current]);

  function _posOnTimeline(x: number, offset = 0): number {
    if (!_timeline.current) return 0;
    const timelineBoundingRect = _timeline.current.getBoundingClientRect();
    const x_pos =
      (x - timelineBoundingRect.left - offset) / timelineBoundingRect.width;

    if (x_pos < 0) return 0;
    else if (x_pos > 0.99)
      return 0.99; // triggers onEnded of videoplayer instead
    else return x_pos;
  }

  function _lineAbsPos(): React.CSSProperties {
    return {left: played.played * _timelineWidth};
  }

  function _selectedBoxStyle(): React.CSSProperties {
    return {
      width: _selectedTimeWidth * _timelineWidth,
    };
  }

  function _onDragStart() {
    _setIsDragging(true);
    setPlaying(false);
  }

  function _onDragHandle(e: any, isLeftHandle: boolean) {
    const x = _posOnTimeline(e.pageX);
    isLeftHandle ? setEditLeftTrimPos(x) : setEditRightTrimPos(x);

    setTime(x);
  }

  function _onDragOverlay(e: any, d: DraggableData) {
    const deltaX = d.deltaX / _timelineWidth;
    setEditLeftTrimPos(editLeftTrimPos + deltaX);
    setEditRightTrimPos(editRightTrimPos + deltaX);

    setTime(editLeftTrimPos + deltaX);
  }

  function _onDragEnd() {
    _setIsDragging(false);
    setPlaying(true);
  }

  function _handleChangeVideoDurationRequest(
    xSecondsBackward = 0,
    xSecondsForward = 0,
  ) {
    changeVideoDuration('edit-add-time', xSecondsBackward, xSecondsForward);
  }

  // TODO should only be rendered on every totalVideoOffsets change
  function _drawTimestamps(drawEveryN = 0.1) {
    const timestamps = [];
    const duration = totalVideoOffsets[0] + totalVideoOffsets[1];

    const dummyTimestampWidth = _dummy_timestamp.current
      ? _dummy_timestamp.current.getBoundingClientRect().width / 2
      : 0;

    for (let i = 0; i < 1; i += drawEveryN) {
      timestamps.push(
        <p
          className={styles.timestamp}
          key={i}
          style={{left: `calc(${i * 100}% - ${dummyTimestampWidth}px)`}}>
          {formatVideoTime(currentClip, i * duration, totalVideoOffsets[0])}
        </p>,
      );
    }
    return timestamps;
  }

  const _progressLine = !_isDragging ? (
    <div className={styles.timeline_line} style={_lineAbsPos()}></div>
  ) : (
    <></>
  );

  return (
    <div className={styles.container}>
      {videoBufferingStatus != 'complete' ? (
        <Skeleton
          variant={'rectangular'}
          width={'100%'}
          height={'100%'}
          animation={'pulse'}
          className={styles.skeleton}
        />
      ) : (
        <div className={styles.timeline_container}>
          {/* Add extra seconds BEFORE video */}
          <div>
            <Tooltip
              placement="top"
              title={t('video-player.tooltip.ten_more_seconds', {
                count: editVideoConstants.videoEditorDurationIncrease,
              })}>
              <span>
                <IconButton
                  disabled={!_canLoadVideoBefore}
                  className={classNames(styles.icon_btn, styles.left_chevron)}
                  onClick={() =>
                    _handleChangeVideoDurationRequest(
                      editVideoConstants.videoEditorDurationIncrease,
                    )
                  }>
                  <ChevronLeft className={styles.icon} />
                </IconButton>
              </span>
            </Tooltip>
          </div>

          {/* TIMELINE - Timestamps + Draggable Handles */}
          <div ref={_timeline} className={styles.timeline}>
            <p className={styles.dummy_timestamp} ref={_dummy_timestamp}>
              00:00
            </p>
            {_drawTimestamps()}
            {_progressLine}

            <Draggable
              axis="x"
              handle=".lefthandle"
              position={{x: editLeftTrimPos * _timelineWidth, y: 0}}
              bounds={{
                left: 0,
                right:
                  editRightTrimPos * _timelineWidth -
                  editVideoConstants.handleWidth,
              }}
              grid={[1, 1]}
              scale={1}
              onStart={_onDragStart}
              onDrag={(e) => _onDragHandle(e, true)}
              onStop={_onDragEnd}>
              <div
                className={classNames(
                  'lefthandle',
                  styles.timeline_handle,
                )}></div>
            </Draggable>

            <Draggable
              axis="x"
              handle=".righthandle"
              position={{x: editRightTrimPos * _timelineWidth, y: 0}}
              bounds={{
                left:
                  editLeftTrimPos * _timelineWidth +
                  editVideoConstants.handleWidth,
                right: _timelineWidth - editVideoConstants.handleWidth,
              }}
              grid={[1, 1]}
              scale={1}
              onStart={_onDragStart}
              onDrag={(e) => _onDragHandle(e, false)}
              onStop={_onDragEnd}>
              <div
                className={classNames(
                  'righthandle',
                  styles.timeline_handle,
                )}></div>
            </Draggable>

            <Draggable
              axis="x"
              handle=".overlay"
              position={{
                x:
                  _selectedTimePos * _timelineWidth +
                  editVideoConstants.handleWidth / 2,
                y: 0,
              }}
              grid={[1, 1]}
              bounds={{
                left: 0,
                right:
                  _timelineWidth -
                  (_selectedTimeWidth * _timelineWidth +
                    editVideoConstants.handleWidth / 2),
              }}
              scale={1}
              onStart={_onDragStart}
              onDrag={(e, d) => _onDragOverlay(e, d)}
              onStop={_onDragEnd}>
              <div
                className={classNames(
                  'overlay',
                  styles.timeline_selected_overlay,
                )}
                style={_selectedBoxStyle()}></div>
            </Draggable>
          </div>

          {/* Add extra seconds after video */}
          <div>
            <Tooltip
              placement="top"
              title={t('video-player.tooltip.ten_more_seconds', {
                count: editVideoConstants.videoEditorDurationIncrease,
              })}>
              <span>
                <IconButton
                  disabled={!_canLoadVideoAfter}
                  className={classNames(styles.icon_btn, styles.right_chevron)}
                  onClick={() =>
                    _handleChangeVideoDurationRequest(
                      0,
                      editVideoConstants.videoEditorDurationIncrease,
                    )
                  }>
                  <ChevronRight className={styles.icon} />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(Edit);
