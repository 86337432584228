import React, {useEffect, useState} from 'react';

import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {useTranslation} from 'react-i18next';
import {useAutomationForm} from 'context/event-automations/automations-form.context';
import AutomationTitleInput from '../inputs/tracker-title-input';
import AutomationTagInput from '../inputs/tracker-tag-input.view';
import DeleteTrackerButton from './delete/delete-tracker-button';
import {hasMultipleExecutors} from 'helpers/automation/automation-form.helper';
import {useConfirm} from 'material-ui-confirm';
import ExecutorPreviewContent from '../dialog/executor-preview/executor-preview-content';
import {useTeams} from 'context/team.context';

function TrackerBuilderToolbar(): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _confirm = useConfirm();
  const _teamsContext = useTeams();
  const _trackerBuilderContext = useAutomationForm();
  const [_hasMultipleExecutors, _setHasMultipleExecutors] = useState(false);
  const _i18nKeyPrefix = _trackerBuilderContext.isNewTracker
    ? 'create'
    : 'edit';
  const _btnSaveLabel = t(`eventAutomations.${_i18nKeyPrefix}.save`);

  /*
   * Side Effects
   */
  useEffect(() => {
    _setHasMultipleExecutors(hasMultipleExecutors(_trackerBuilderContext.data));
  }, [_trackerBuilderContext.data]);

  /*
   * Handlers
   */

  async function _onSave() {
    if (!_hasMultipleExecutors) {
      return await _trackerBuilderContext.saveAutomation();
    }

    try {
      const _description = t(`eventAutomations.${_i18nKeyPrefix}.description`);
      await _confirm({
        title: _btnSaveLabel,
        confirmationText: _btnSaveLabel,
        dialogProps: {
          fullWidth: true,
          maxWidth: 'xs',
        },
        content: (
          <Stack gap={3}>
            <Typography>{_description}</Typography>
            <ExecutorPreviewContent
              data={_trackerBuilderContext.data}
              ownPlayers={_teamsContext.ownPlayers}
            />
          </Stack>
        ),
      });
      await _trackerBuilderContext.saveAutomation();
    } catch (error) {
      // User canceled
    }
  }

  /*
   * Render
   */

  return (
    <DialogActions
      sx={{
        justifyContent: 'flex-start',
        bgcolor: 'background.default',
        py: 2,
        px: 3,
      }}>
      {/* "Delete" button + "Title" input */}
      <Stack gap={3} direction="row" alignItems="center" flexGrow={1}>
        {!_trackerBuilderContext.isNewTracker && (
          <>
            <DeleteTrackerButton />
            <Divider orientation="vertical" flexItem />
          </>
        )}
        <AutomationTitleInput />
      </Stack>

      {/* Tags + "Save" button */}
      <Stack gap={3} direction="row" alignItems="center">
        <AutomationTagInput />
        <Divider orientation="vertical" flexItem />
        <LoadingButton
          size="large"
          color="primary"
          variant="contained"
          loading={_trackerBuilderContext.isLoading}
          onClick={_onSave}>
          {_btnSaveLabel}
        </LoadingButton>
      </Stack>
    </DialogActions>
  );
}

export default TrackerBuilderToolbar;
