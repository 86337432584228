import React from 'react';
import {getLabelLength, getTagIcon} from '../helper/Filter.helper';
import styles from './FilterTags.module.scss';
import {useFilters} from 'context/filters.context';
import {TFilterField} from 'types/filter.types';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const FilterTags = () => {
  const {isLoading, filters, clearFiltersByKey, possibleFilters} = useFilters();

  if (isLoading) return <></>;

  return (
    <Stack direction="row" spacing={1}>
      {Object.keys(filters).map((filterKey: string) => {
        const _key = filterKey as TFilterField;
        const _filter = possibleFilters[_key];
        if (!_filter) return null;
        return (
          filters[_key].length > 0 && (
            <div className={styles.tags} key={filterKey}>
              <Chip
                size="small"
                icon={getTagIcon(_key, 'xsmall')}
                label={`${_filter.label} (${getLabelLength(
                  filters[_key].length,
                  _filter.options.length - 1,
                )})`}
                onDelete={() => clearFiltersByKey(filterKey)}
              />
            </div>
          )
        );
      })}
    </Stack>
  );
};

export default FilterTags;
