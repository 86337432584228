import React from 'react';
import AutomationDetail from 'components/trackers/deep-dive/automation-detail.view';
import {useTeams} from 'context/team.context';
import {useParams} from 'react-router-dom';

function TeamTrackerView() {
  /*
   * Hooks n State
   */
  const _params = useParams();
  const _teamsContext = useTeams();

  /*
   * Render
   */
  if (!_teamsContext.ownTeam) {
    return null;
  }
  return (
    <AutomationDetail
      id={_params.id}
      subjectId={_teamsContext.ownTeam._id}
      executorType="team_performance"
    />
  );
}

export default TeamTrackerView;
