import React, {useEffect, useState} from 'react';

import classNames from 'classnames';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import {Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

import {FIMatchEvent, formatMatchTimeFromTimestamp} from '@my-game-plan/types';
import styles from './playlistItem.module.scss';
import thumbnail from 'assets/images/video-thumbnail/thumbnail.png';
import {
  isNullOrUndefined,
  roundAndFormatNumber,
} from 'helpers/general/general.helper';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import {translateEventProperty} from 'helpers/translation.helper';
import {get} from 'lodash';
import {getAvailableSourcesForMatch} from 'helpers/video.helper';
import {useTranslation} from 'react-i18next';
import PlaylistItemEvent from './PlaylistItemEvent';
import {PlaylistItemProps} from './PlaylistItemGeneral';

function PlaylistItemSequence({
  playlistItem,
  isActive,
  isSelected,
  playClip,
  toggleSelect,
  canSelect = false,
  isRatio,
  observingMetric,
  match,
  sequenceEvents,
  disableCollapse,
}: PlaylistItemProps) {
  /* Hooks n State */
  const {t} = useTranslation();

  const [_infoLine, _setInfoLine] = useState<string[]>([]);
  const [isExpanded, setIsExpanded] = useState(false); // State for managing expand/collapse

  useEffect(() => {
    const _newInfoLine = [];
    if (sequenceEvents) {
      _newInfoLine.push(
        `${sequenceEvents.length} ${t('eventAutomations.sequence.events')}`,
      );
    }
    if (playlistItem.sequence?.result) {
      const _translatetionKey = `eventAutomations.sequence.sequence_result.${playlistItem.sequence?.result}`;
      _newInfoLine.push(`${t(_translatetionKey)}`);
    }
    _setInfoLine(_newInfoLine);
  }, [playlistItem, sequenceEvents]);

  /* Functions */
  function _onPlay() {
    if (!isActive) {
      playClip(playlistItem);
    }
  }

  function handleExpand() {
    setIsExpanded(!isExpanded);
  }

  function _toggleSelect(
    e: React.ChangeEvent<HTMLInputElement>,
    clips: FIMatchEvent[],
  ) {
    if (toggleSelect) {
      toggleSelect(e, clips);
    }
  }

  const _availableVideoSources = getAvailableSourcesForMatch(match.video);
  const _hasVideo = Boolean(_availableVideoSources.length);

  let _SuccessFulIcon = null;
  if (isRatio) {
    _SuccessFulIcon = playlistItem.successful ? (
      <CheckCircleOutlineIcon
        sx={{fontSize: 18, marginBottom: '-3px'}}
        color={'success'}
      />
    ) : (
      <CloseIcon color={'error'} sx={{fontSize: 18, marginBottom: '-3px'}} />
    );
  }

  let _MetricValue = null;
  if (observingMetric) {
    const _label = translateEventProperty(observingMetric);
    let _metricValue = get(playlistItem, observingMetric);
    if (!isNullOrUndefined(_metricValue)) {
      _metricValue = roundAndFormatNumber(_metricValue, 2);
      _MetricValue = (
        <Tooltip title={_label}>
          <span className={styles.metric}>{_metricValue}</span>
        </Tooltip>
      );
    }
  }

  const _Button = (
    <Button
      disabled={!_hasVideo}
      variant={'text'}
      onClick={_onPlay}
      sx={{px: 1, py: 2, bgcolor: isActive ? 'secondary.dark' : undefined}}
      className={classNames({
        [styles.playlistItemBase]: true,
        [styles.playlistItemFocused]: isActive,
        [styles.playlistItemNoVideo]: !_hasVideo,
      })}>
      <Stack
        spacing={1.5}
        direction="row"
        alignItems="center"
        flex={1}
        minWidth={0}>
        {canSelect && toggleSelect && (
          <Box className={styles.center}>
            <Checkbox
              checked={isSelected}
              value="select-all"
              name="select-all"
              size="small"
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => _toggleSelect(e, [playlistItem])}
            />
          </Box>
        )}

        <Box className={styles.playlistItemThumbnail}>
          <Box
            sx={{borderRadius: 0.5, backgroundImage: `url(${thumbnail})`}}
            className={styles.playlistItemThumbnailImage}
          />
          <Box className={styles.playlistItemThumbnailIcon}>
            <PlayArrowIcon />
          </Box>
        </Box>

        {playlistItem.sequence && (
          <Box className={styles.generalInfo}>
            <Box gap={0.5}>
              {isRatio && <Grid item>{_SuccessFulIcon}</Grid>}
              <Typography variant="body2" lineHeight={1} fontWeight={600}>
                {`${formatMatchTimeFromTimestamp(
                  playlistItem.sequence?.period ?? 1,
                  playlistItem.sequence?.start,
                )} - ${formatMatchTimeFromTimestamp(
                  playlistItem.sequence?.period ?? 1,
                  playlistItem.sequence?.end,
                )}`}
              </Typography>
            </Box>

            {_infoLine && (
              <Box className={styles.generalInfo}>
                {_infoLine.map((part, index) => (
                  <Typography
                    key={index}
                    component="span"
                    fontSize={11}
                    // lineHeight={14}
                    color="text.secondary">
                    {part}
                    {index < _infoLine.length - 1 && ' - '}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
        )}
        {_MetricValue}
      </Stack>

      {!disableCollapse && (
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={(event) => event.stopPropagation()} // Prevent button click propagation
        ></AccordionSummary>
      )}
    </Button>
  );

  if (disableCollapse) {
    return _Button;
  }

  return (
    <Accordion
      defaultExpanded={false} //`false` means it starts collapsed
      onChange={handleExpand}
      sx={{bgcolor: 'secondary.dark'}}
      slotProps={{
        transition: {unmountOnExit: true},
      }}>
      {_Button}

      <AccordionDetails>
        <div>
          {sequenceEvents?.map((ev, index) => (
            <PlaylistItemEvent
              playlistItem={ev}
              key={ev._id}
              playClip={playClip}
              isRatio={isRatio}
              observingMetric={observingMetric}
              match={match}
              isShowingSequences={true}
              eventNumber={index + 1}
            />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default PlaylistItemSequence;
