import React, {useEffect} from 'react';

import IconButton from '@mui/material/IconButton';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import {useAutomationForm} from 'context/event-automations/automations-form.context';
import {EXECUTOR_COLOR} from 'theme';

interface IExecutorPreviewToggleProps {
  subjectIndex: number;
}

function ExecutorPreviewToggle(
  props: IExecutorPreviewToggleProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const _trackerBuilderContext = useAutomationForm();
  const [_isChecked, _setIsChecked] = React.useState(false);

  /*
   * Side effects
   */
  useEffect(() => {
    _setIsChecked(
      props.subjectIndex === _trackerBuilderContext.concreteExecutorIndex,
    );
  }, [_trackerBuilderContext.concreteExecutorIndex, props.subjectIndex]);

  /*
   * Handlers
   */
  function _onClick() {
    if (_isChecked) {
      return;
    }

    _trackerBuilderContext.setConcreteExecutorIndex(props.subjectIndex);
  }

  let _Icon = <CenterFocusWeakIcon fontSize="small" />;
  if (_isChecked) {
    _Icon = (
      <CenterFocusStrongIcon fontSize="small" sx={{color: EXECUTOR_COLOR}} />
    );
  }
  return <IconButton onClick={_onClick}>{_Icon}</IconButton>;
}

export default ExecutorPreviewToggle;
