import {
  FIAutomationDataQueryParams,
  FIPerformanceOverview,
} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';
import PerformanceHero from '../executor-deep-dive/performance/hero/performance-hero';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import GroupIcon from '@mui/icons-material/Group';

import {useTeams} from 'context/team.context';
import {LOADING_STATE} from 'types/screen.types';
import {getTeamOverview} from 'controllers/teams.controller';
import Loader from '../common/Loader/Loader.view';
import CustomEmptyState from '../error-states/CustomEmptyState';
import {useTranslation} from 'react-i18next';
import {NAV_LEVEL_1_ROUTE} from 'config/navigation.config';
import {Link, useLocation} from 'react-router-dom';
import {formatSearchParamsToQueryParams} from 'helpers/automation/automation.helper';
import {serializeParams} from 'helpers/api.helper';

interface IOverviewScreenHeroProps {
  filters?: FIAutomationDataQueryParams;
  parentLoadingState?: LOADING_STATE;
}
function OverviewScreenHero(
  props: IOverviewScreenHeroProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _location = useLocation();
  const _teamsContext = useTeams();
  const [_teamPerformance, _setTeamPerformance] =
    useState<FIPerformanceOverview | null>(null);
  const [_loadingState, _setLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );

  const [_buttonLink, _setButtonLink] = useState<string>('');

  /*
   * Side effects
   */
  useEffect(() => {
    if (!_teamsContext.ownTeam || !props.filters) {
      return;
    }

    _fetchTeamPerformance(props.filters);
  }, [props.filters]);

  useEffect(() => {
    const _searchParams = new URLSearchParams(_location.search);
    const _parsedQuery = formatSearchParamsToQueryParams(_searchParams);

    const _urlQueryParams = serializeParams(_parsedQuery);

    let _newLink: string = NAV_LEVEL_1_ROUTE.TEAM;
    if (_urlQueryParams) {
      _newLink += `?${_urlQueryParams}`;
    }

    _setButtonLink(_newLink);
  }, [_location.search]);

  /*
   * Handlers
   */
  // Only fetching own team performance for now. Might change later
  async function _fetchTeamPerformance(options?: FIAutomationDataQueryParams) {
    try {
      const _fetchedData = await getTeamOverview(options);

      _setTeamPerformance(_fetchedData.performance_overview);
      _setLoadingState(LOADING_STATE.SUCCESS);
    } catch (error) {
      _setLoadingState(LOADING_STATE.ERROR);
    }
  }

  /*
   * Render
   */

  if (_loadingState === LOADING_STATE.ERROR) {
    return (
      <CustomEmptyState
        imageType="error"
        header={t('error-states.not-found.header')}
        description={t('error-states.not-found.description')}
      />
    );
  } else if (
    _loadingState === LOADING_STATE.SUCCESS &&
    !_teamPerformance?.automations_count
  ) {
    return null;
  }
  // console.log(props.parentLoadingState, _loadingState);
  return (
    <Stack position="relative">
      <PerformanceHero
        performanceOverview={
          _loadingState === LOADING_STATE.SUCCESS && _teamPerformance
            ? _teamPerformance
            : undefined
        }
        benchmarkOptions={props.filters?.benchmark}
        isPreview
        subject={_teamsContext.ownTeam?.name || t('teams.teams', {count: 1})}
        SummaryButton={
          <Button
            component={Link}
            to={_buttonLink}
            variant="outlined"
            size="large"
            startIcon={<GroupIcon />}>
            {t('navigation.team')}
          </Button>
        }
      />

      <Loader
        fixed={false}
        isVisible={
          _loadingState === LOADING_STATE.INITING &&
          props.parentLoadingState !== LOADING_STATE.LOADING &&
          props.parentLoadingState !== LOADING_STATE.INITING
        }
      />
    </Stack>
  );
}

export default OverviewScreenHero;
