import {ITypedOption} from 'types/option.types';
import React from 'react';

import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

interface IDataDisplayOptionProps<T> {
  title: string;
  description: string;
  value: T;
  options: ITypedOption<T>[];
  onChange: (value: T) => void;
}

function DataDisplayOption<T extends string>(
  props: IDataDisplayOptionProps<T>,
): JSX.Element {
  /*
   * Hooks n State
   */

  /*
   * Side effects
   */

  /*
   * Handlers
   */
  function _onToggleButtonChange(
    event: React.MouseEvent<HTMLElement>,
    value: T,
  ) {
    if (value !== null) {
      props.onChange(value);
    }
  }

  /*
   * Render
   */
  return (
    <Stack
      gap={5}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between">
      <Stack spacing={1}>
        <Typography fontWeight={600}>{props.title}</Typography>
        <Typography color="text.fsecondary">{props.description}</Typography>
      </Stack>
      <ToggleButtonGroup
        value={props.value}
        exclusive
        onChange={_onToggleButtonChange}
        size="small">
        {props.options.map((option) => (
          <ToggleButton
            key={option.value}
            value={option.value}
            sx={{whiteSpace: 'nowrap'}}>
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
}

export default DataDisplayOption;
