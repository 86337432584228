import React from 'react';

import ListItemText from '@mui/material/ListItemText';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import ShieldIcon from '@mui/icons-material/Shield';

import {FilterMatchOption} from './FilterMatchOption';
import {FilterPlayerOption} from './FilterPlayerOption';
import {TFilterField, TFilterIconsMap} from 'types/filter.types';

export const getLabelLength = (
  filtersLength: number,
  possisbleFiltersLength: number,
) => {
  let length = filtersLength;
  if (!filtersLength) {
    length = possisbleFiltersLength;
  }

  return `${length}/${possisbleFiltersLength}`;
};

const FILTER_ICONS_MAP: TFilterIconsMap = {
  'match._id': DateRangeIcon,
  time_block: AccessTimeIcon,
  position_in_match: ScoreboardIcon,
  'player._id': PersonIcon,
  'team.formation': GroupsIcon,
  'opponent_team.defence': ShieldIcon,
};

export const getTagIcon = (
  label: TFilterField,
  size?: any,
  color?: 'secondary' | 'primary',
) => {
  const _Icon = FILTER_ICONS_MAP[label];
  return <_Icon fontSize={size} color={color} />;
};

export function getStyledDropdownOption(
  filterKey: TFilterField,
  value: string,
  label: string,
  sublabel?: string | number,
): JSX.Element | null {
  let _sublabel = sublabel;
  if (
    _sublabel &&
    ['team.formation', 'opponent_team.defence'].includes(filterKey)
  ) {
    _sublabel += "'";
  }
  const _DefaultContent = (
    <ListItemText
      primary={label}
      secondary={_sublabel}
      sx={{display: 'flex', gap: 2, justifyContent: 'space-between'}}
    />
  );

  if (value === '*') {
    return _DefaultContent;
  }

  let _Content = _DefaultContent;

  switch (filterKey) {
    case 'player._id':
      _Content = <FilterPlayerOption label={label} value={value} />;
      break;

    case 'match._id':
      _Content = <FilterMatchOption label={label} value={value} />;
      break;

    default:
      _Content = _DefaultContent;
      break;
  }

  return _Content;
}
