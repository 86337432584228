import React from 'react';
import {useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {IDataExplorer} from 'types/dashboard/dashboard-explorer.types';
import {CustomBarChart} from '../../common/custom-bar-chart/CustomBarChart';
import {Card} from '../../common/card/Card';
import styles from './dataExplorer.module.scss';
import {useDashboard} from 'context/dashboard/dashboard.context';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from 'components/material-customised/tabs/tabs.view';

interface DataExplorerProps {
  data: IDataExplorer;
}

const DataExplorer = ({data}: DataExplorerProps) => {
  const {t} = useTranslation();
  const {activePlayerStatsTab, getDataExplorerTitle} = useDashboard();

  const [selected, setSelected] = useState(0);

  const handleUpdate = useCallback((event, index) => {
    setSelected(index);
  }, []);

  const _tabNames = data.tabs[activePlayerStatsTab].groups.map(
    (tab) => tab.type,
  );

  if (_tabNames.length < 1) {
    return (
      <Card>
        <div className={styles.dataExplorerEmpty}>
          <p>{t('dashboard.player-statistics.no-data')}</p>
        </div>
      </Card>
    );
  }

  return (
    <div style={{width: '100%'}}>
      <Card>
        <h4>{getDataExplorerTitle(_tabNames[selected])}</h4>
        <div>
          <Tabs
            value={selected}
            onChange={handleUpdate}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example">
            {_tabNames.map((label) => (
              <Tab key={label} label={t(`dashboard.dataExplorer.${label}`)} />
            ))}
          </Tabs>
          {data.tabs[activePlayerStatsTab].groups.map((group, index) => {
            if (group.fields.length < 1) {
              return (
                <Box key={group.type} hidden={selected !== index}>
                  <span className={styles.emptyTab}>
                    {t('dashboard.player-statistics.no-data')}
                  </span>
                </Box>
              );
            }

            return (
              <Box key={group.type} hidden={selected !== index}>
                <div className={styles.chart}>
                  <CustomBarChart group={group} />
                </div>
              </Box>
            );
          })}
        </div>
      </Card>
    </div>
  );
};

export default DataExplorer;
