import {FITrackerSubject, TTrackerExecutorType} from '@my-game-plan/types';
import React, {useEffect} from 'react';

import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import GppBadIcon from '@mui/icons-material/GppBad';

import {ITypedOption} from 'types/option.types';
import {useTeams} from 'context/team.context';
import {useTranslation} from 'react-i18next';
import {getSelectedPositionOptions} from 'helpers/automation/automation-form.helper';

interface ITeamCellContentProps {
  executorType: TTrackerExecutorType;
  data: FITrackerSubject[];
  against?: boolean;
}

const MAX_POSITIONS = 2;
const MAX_CHIPS = 1;
function TeamCellContent(props: ITeamCellContentProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const [_chipsData, _setChipsData] = React.useState<ITypedOption<string>[]>(
    [],
  );
  const [_hiddenChipsCount, _setHiddenChipsCount] = React.useState<number>(0);

  /*
   * Side Effects
   */
  useEffect(() => {
    if (!_teamsContext.ownTeam) {
      _setChipsData([]);
      return;
    }

    let _generatedChipsData: ITypedOption<string>[] = [];
    let _newHiddenChipsCount = 0;
    let _teamImageURL = _teamsContext.ownTeam.image_url;
    if (props.executorType === 'opponent_analysis') {
      const _opponent = _teamsContext.all.find(
        (team) => team._id === _teamsContext.currentlyObservingOpponentId,
      );
      _teamImageURL = _opponent?.image_url || '';
    }

    props.data.forEach((subject, subjectIndex) => {
      let _label = t('sentenceForm.players.any');
      if (subject.executor.team?.executor_positions?.length) {
        let _positions = getSelectedPositionOptions(
          subject.executor.team.executor_positions,
          false,
          true,
        );
        const _hiddenPositionsCount = _positions.length - MAX_POSITIONS;
        if (_hiddenPositionsCount > 0) {
          _positions = _positions.slice(0, MAX_POSITIONS);
        }

        _label = _positions.join(', ');
        if (_hiddenPositionsCount > 0) {
          _label += ` +${_hiddenPositionsCount}`;
        }
      }
      const _option: ITypedOption<string> = {
        label: _label,
        value: `subject-${subjectIndex.toString()}`,
        image_url: _teamImageURL,
      };
      _generatedChipsData.push(_option);

      if (_generatedChipsData.length >= MAX_CHIPS) {
        _generatedChipsData = _generatedChipsData.slice(0, MAX_CHIPS);
        _newHiddenChipsCount = props.data.length - MAX_CHIPS;
      }
    });

    _setHiddenChipsCount(_newHiddenChipsCount);

    _setChipsData(_generatedChipsData);
  }, [
    props.executorType,
    _teamsContext.ownTeam,
    _teamsContext.currentlyObservingOpponentId,
    _teamsContext.all,
  ]);

  /*
   * Handlers
   */

  /*
   * Render
   */

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {_chipsData.map((subject) => {
        let _Avatar = undefined;
        let _Icon = undefined;
        if (props.against) {
          _Icon = <GppBadIcon />;
        } else {
          _Avatar = (
            <Avatar
              src={subject.image_url || ''}
              slotProps={{
                img: {
                  sx: {objectFit: 'contain'},
                },
              }}
            />
          );
        }
        return (
          <Chip
            variant="filled"
            sx={{bgcolor: 'background.default', color: 'text.secondary'}}
            avatar={_Avatar}
            icon={_Icon}
            key={subject.value}
            label={subject.label}
          />
        );
      })}

      {Boolean(_hiddenChipsCount) && (
        <Typography
          variant="body2"
          color="text.secondary">{`+ ${_hiddenChipsCount}`}</Typography>
      )}
    </Stack>
  );
}

export default TeamCellContent;
