import {TMGPCategory} from '@my-game-plan/types';
import React from 'react';

import CheckIcon from '@mui/icons-material/Check';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import {useTranslation} from 'react-i18next';
import {useProfileSetup} from 'context/profile-setup.context';

function ProfileTrackersCategoriesList(): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _profileSetupContext = useProfileSetup();

  /*
   * Side effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */
  const {selectedProfile} = _profileSetupContext;
  if (!selectedProfile) {
    return null;
  }

  return (
    <List>
      {selectedProfile.performance.categories.map((category, categoryIndex) => {
        const _categoryName = category.name as TMGPCategory;
        const _isCompleted =
          _profileSetupContext.completedCategories.includes(_categoryName);
        const _isActive = _profileSetupContext.activeCategory === _categoryName;

        const _selectedTrackersCount =
          _profileSetupContext.selectedTrackersPerCategory[_categoryName]
            ?.length || 0;

        let _primaryTextColor:
          | 'text.primary'
          | 'text.secondary'
          | 'text.disabled' = 'text.disabled';

        let _secondaryTextColor: 'text.secondary' | 'text.disabled' =
          'text.disabled';
        if (_isActive) {
          _primaryTextColor = 'text.primary';
          _secondaryTextColor = 'text.secondary';
        } else if (_isCompleted) {
          _primaryTextColor = 'text.primary';
          _secondaryTextColor = 'text.secondary';
        }
        return (
          <ListItem
            key={category.name}
            disablePadding
            divider={
              categoryIndex !==
              selectedProfile.performance.categories.length - 1
            }>
            <ListItemButton
              sx={{p: 3}}
              selected={_isActive}
              onClick={() =>
                _profileSetupContext.onCategoryChange(_categoryName)
              }>
              <ListItemIcon sx={{minWidth: 24}}>
                {_isCompleted ? (
                  <CheckIcon
                    color="primary"
                    // fontSize="small"
                    // sx={{color: 'secondary.main'}}
                  />
                ) : null}
              </ListItemIcon>
              <ListItemText
                primary={t(`categories.${category.name}`)}
                primaryTypographyProps={{color: _primaryTextColor}}
                secondary={t('profileSetup.trackers.trackersSelected', {
                  count: _selectedTrackersCount,
                })}
                secondaryTypographyProps={{color: _secondaryTextColor}}
                sx={{
                  pl: 2,
                }}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}

export default ProfileTrackersCategoriesList;
