import React, {useEffect, useState} from 'react';
import {SCOUTING_VIEW_TYPES, TScoutingViewType} from 'types/scouting.types';
import {FIPlayerSearchFilters} from '@my-game-plan/types';
import {useTranslation} from 'react-i18next';

import Stack from '@mui/material/Stack';

import SearchIcon from '@mui/icons-material/Search';
import ViewListIcon from '@mui/icons-material/ViewList';

import {ITypedOption} from 'types/option.types';
import HeaderFilterMenu from '../../filter/header-filter-bar/buttons/header-filter-menu.view';
import {IPagination} from 'types/api.types';
import FilterBarPaginationButton from './pagination-button.view';
import HeaderFilterButton, {
  IButtonValueSegment,
} from 'components/filter/header-filter-bar/buttons/header-filter-button.view';

interface IScoutingHeaderFilterBarProps {
  searchFilters: FIPlayerSearchFilters;

  view: TScoutingViewType;
  onViewChange: (view: TScoutingViewType) => void;

  pagination: IPagination | null;

  onSearchDialogOpen: () => void;

  // onResultsTypeChange: (resultsType: TScoutingPlayersType) => void;
}

function ScoutingHeaderFilterBar(props: IScoutingHeaderFilterBarProps) {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_viewOptions, _setViewOptions] = useState<
    ITypedOption<TScoutingViewType>[]
  >([]);

  const [_filtersButtonValues, _setFilterButtonValues] = useState<
    IButtonValueSegment[][]
  >([]);

  /*
   * Side effects
   */
  useEffect(() => {
    // Define view options
    const _generatedViewOptions: ITypedOption<TScoutingViewType>[] =
      SCOUTING_VIEW_TYPES.map((viewType) => {
        return {
          value: viewType,
          label: t(`scouting.view.${viewType}`),
        };
      });
    _setViewOptions(_generatedViewOptions);
  }, []);

  useEffect(() => {
    const {mirroring, ...otherSearchFilters} = props.searchFilters;

    const _filtersCount = Object.keys(otherSearchFilters).length;
    let _newButtonValues: IButtonValueSegment[][] = [];
    if (_filtersCount) {
      _newButtonValues = [
        [
          {
            type: 'default',
            value: t('scouting.filters.filtering'),
          },
          {
            type: 'value',
            value: _filtersCount.toString(),
          },
          {
            type: 'default',
            value: t('scouting.filters.properties', {count: _filtersCount}),
          },
        ],
      ];
    }
    _setFilterButtonValues(_newButtonValues);
  }, [props.searchFilters]);

  /*
   * Handlers
   */

  /*
   * Render
   */

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={4}
      justifyContent="space-between">
      <Stack direction="row" alignItems="center" gap={4}>
        {/* Pagination */}
        <FilterBarPaginationButton pagination={props.pagination} />

        {/* View */}
        <HeaderFilterMenu
          options={_viewOptions}
          value={props.view}
          onChange={props.onViewChange}
          buttonProps={{
            ButtonIcon: ViewListIcon,
            buttonPrefix: t('scouting.view.viewAs'),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" gap={4}>
        {/* Active filters */}
        {Boolean(_filtersButtonValues.length) && (
          <HeaderFilterButton
            onPopupOpen={props.onSearchDialogOpen}
            buttonValues={_filtersButtonValues}
            ButtonIcon={SearchIcon}
            isOpen
          />
        )}

        {/* Benchmarking */}
      </Stack>
    </Stack>
  );
}

export default ScoutingHeaderFilterBar;
