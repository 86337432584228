import React, {useState} from 'react';

import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';

import styles from './tooltip.module.scss';
import {useTranslation} from 'react-i18next';
import {TSentenceSize} from 'types/sentence-form.types';

interface ITooltipProps {
  // isOpened: boolean;
  // onTriggerClick: () => void;
  // onClose: () => void;
  content: string | React.ReactNode;
  countSuffixKey?: string;
  count: number;
  size?: TSentenceSize;
}

function Tooltip(props: ITooltipProps): JSX.Element {
  /*
   * Hooks n State
   */
  // const [_isPopupOpened, _setIsPopupOpened] = useState<boolean>(false);
  const [_anchorEl, _setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {t} = useTranslation();

  /* Listeners */
  function _onPopupClose() {
    _setAnchorEl(null);
  }
  function _onPopupToggle(event: React.MouseEvent<HTMLButtonElement>) {
    _setAnchorEl(event.currentTarget);
  }

  /*
   * Handlers
   */

  /*
   * Render
   */
  const _isPopupOpened = Boolean(_anchorEl);

  // let _Lozenge: JSX.Element | null = null;
  let _label = '';
  if (props.count) {
    _label += `+${props.count}`;
  }

  if (props.countSuffixKey) {
    _label += ` ${t(props.countSuffixKey, {count: props.count})}`;
  }
  // if (props.count) {
  //   _Lozenge = <Lozenge>{_label}</Lozenge>;
  // }

  let _buttonSize = 24;
  if (props.size === 'small') {
    _buttonSize = 16;
  } else if (props.size === 'xsmall') {
    _buttonSize = 14;
  }
  return (
    <>
      <Button
        onClick={_onPopupToggle}
        size="small"
        variant="text"
        color="secondary"
        sx={{
          minWidth: 0,
          paddingX: 0,
          paddingY: 0,
          fontSize: _buttonSize,
          textTransform: 'none',
        }}>
        {_label}
      </Button>
      <Popover
        onMouseLeave={_onPopupClose}
        open={_isPopupOpened}
        anchorEl={_anchorEl}
        onClose={_onPopupClose}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}>
        <div className={styles.tooltip}>{props.content}</div>
      </Popover>
    </>
  );
}

export default Tooltip;
