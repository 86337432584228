import React, {useEffect, useState} from 'react';
import {
  ICommonSentenceInputProps,
  ISubSegment,
} from 'types/sentence-form.types';
import {PlayerPosition} from '@my-game-plan/types';
import CustomAutocomplete from 'components/material-customised/autocomplete/autocomplete.view';
import {useTranslation} from 'react-i18next';
import {ITypedOption} from 'types/option.types';
import {
  getPositionOptions,
  getSelectedPositionOptions,
} from 'helpers/automation/automation-form.helper';
import Sentence from '../../sentence';
import Segment from '..';

interface IPositionsDropdownProps
  extends Omit<
    ICommonSentenceInputProps<PlayerPosition[]>,
    'onChange' | 'property'
  > {
  onChange: (value: PlayerPosition[]) => void;
  hideBy?: boolean;
  hideIfEmpty?: boolean;
}

const TAGS_LIMIT = 3;

function PositionsDropdown(props: IPositionsDropdownProps): JSX.Element | null {
  /* 
    Hooks n State 
    */
  const {t} = useTranslation();
  const [_options, _setOptions] = useState<ITypedOption<PlayerPosition>[]>([]);
  const [_selectedOptions, _setSelectedOptions] = useState<
    ITypedOption<PlayerPosition>[]
  >([]);
  const [_textInfo, _setTextInfo] = useState<ISubSegment | null>(null);
  const {onChange, ..._commonProps} = props;

  /* Define positions options */
  useEffect(() => {
    _setOptions(getPositionOptions());
  }, []);

  /* Define selected options */
  useEffect(() => {
    // Get selected options
    const _newSelectedOptions = _options.filter((option) => {
      return props.value?.includes(option.value);
    });
    _setSelectedOptions(_newSelectedOptions);

    // Fabricate sentence value, eg. LCM, RCM +3
    const _selectedPositionsOrGroups = getSelectedPositionOptions(
      props.value || [],
      false,
      true,
    );
    const _valuesToDisplay = _selectedPositionsOrGroups.slice(0, TAGS_LIMIT);

    let _text = _valuesToDisplay.join(', ');
    if (!_valuesToDisplay.length) {
      _text = props.placeholder || t('playerPosition.any');
    }
    let _hiddenValuesCount = 0;
    let _tooltipContent = '';

    if (_selectedPositionsOrGroups.length > TAGS_LIMIT) {
      _hiddenValuesCount = _selectedPositionsOrGroups.length - TAGS_LIMIT;
      _tooltipContent = _selectedPositionsOrGroups
        .map((option) => option)
        .join(', ');

      if (!props.readonly) {
        _text += ` +${_hiddenValuesCount}`;
      }
    }

    const _newTextInfo: ISubSegment = {
      type: 'value',
      hiddenItemsCount: _hiddenValuesCount,
      tooltipContent: _tooltipContent,
      text: _text,
    };

    _setTextInfo(_newTextInfo);
  }, [_options, props.value, props.readonly, props.placeholder]);

  /*
    Handlers
    */
  function _onChange(
    data: ITypedOption<PlayerPosition>[] | ITypedOption<PlayerPosition> | null,
  ) {
    if (data && Array.isArray(data)) {
      props.onChange(data.map((option) => option.value));
    } else {
      // TODO: handle single pos (is multiselect now)
    }
  }

  /*
    Render
    */

  function _groupHeaderKey(text: string): string {
    return t(`playerPosition.${text}s`);
  }

  // Return nothing for read only components with no positions
  let _ByTextSegment = null;
  if (!props.hideBy) {
    _ByTextSegment = (
      <Sentence.Segment>
        <Segment.Text {..._commonProps} translationKey={'sentenceForm.by'} />
      </Sentence.Segment>
    );
  }

  if (props.readonly && !props.value?.length && props.hideIfEmpty) {
    return null;
  } else if (props.readonly && _textInfo) {
    return (
      <>
        {_ByTextSegment}
        <Segment.Text
          {..._commonProps}
          text={_textInfo.text}
          type={_textInfo.type}
          tooltipContent={_textInfo.tooltipContent}
          hiddenItemsCount={_textInfo.hiddenItemsCount}
        />
      </>
    );
  }

  let _placeholder = props.placeholder || t('playerPosition.any');
  if (props.required) {
    _placeholder += '*';
  }

  return (
    <>
      {_ByTextSegment}

      <CustomAutocomplete<PlayerPosition, true>
        disableCloseOnSelect
        multiple={true}
        isInlineInput
        options={_options}
        value={_selectedOptions}
        required={props.required}
        onChange={_onChange}
        placeholder={_placeholder}
        groupHeader={_groupHeaderKey}
        tagsSentenceValue={_textInfo?.text}
      />
    </>
  );
}

export default PositionsDropdown;
