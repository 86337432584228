import {NAV_LEVEL_1_ROUTE} from 'config/navigation.config';
import React from 'react';
import {NavLink} from 'react-router-dom';
import MGP_LOGO_ICON from 'assets/images/logo/logomark.svg';

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

const SIZE = 24;

function HomeButton(): JSX.Element {
  return (
    <Box pt={3} pl={1.5}>
      <IconButton component={NavLink} to={NAV_LEVEL_1_ROUTE.HOME}>
        <Box style={{width: SIZE, height: SIZE}}>
          <img
            src={MGP_LOGO_ICON}
            style={{height: `${SIZE}px`}}
            alt="MyGamePlan"
          />
        </Box>
      </IconButton>
    </Box>
    // </Link>
  );
}

export default HomeButton;
