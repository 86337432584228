import i18n from 'localization';
import {ITypedOption} from 'types/option.types';
import {TBenchmarkCalculation} from '@my-game-plan/types';

export const BENCHMARK_AVERAGE_OPTIONS: ITypedOption<TBenchmarkCalculation>[] =
  [
    {
      label: i18n.t('eventAutomations.benchmark.average'),
      name: i18n.t('eventAutomations.benchmark.average'),
      value: 'default',
    },
    {
      label: i18n.t('eventAutomations.benchmark.average_90'),
      name: i18n.t('eventAutomations.benchmark.average_90'),
      value: 'per_90',
    },
  ];
