import React from 'react';
import {TPlayerObjectiveStatus} from '@my-game-plan/types';

import EastIcon from '@mui/icons-material/East';
import NorthIcon from '@mui/icons-material/North';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthIcon from '@mui/icons-material/South';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import {TIcon} from 'types/filter.types';

interface IPlayerObjectiveStatusIconProps {
  status: TPlayerObjectiveStatus;
}

interface ISingleStatusConfig {
  icon: TIcon;
  color: string;
}

const STATUS_CONFIG: Record<TPlayerObjectiveStatus, ISingleStatusConfig> = {
  poor: {
    icon: SouthIcon,
    color: 'error',
  },
  needs_improvement: {
    icon: SouthEastIcon,
    color: 'warning',
  },
  fair: {
    icon: EastIcon,
    color: 'text',
  },
  good: {
    icon: NorthEastIcon,
    color: 'success',
  },
  great: {
    icon: NorthIcon,
    color: 'success',
  },
};

function PlayerObjectiveStatusIcon(
  props: IPlayerObjectiveStatusIconProps,
): JSX.Element {
  const Icon = STATUS_CONFIG[props.status].icon;

  return (
    <Icon
      color={STATUS_CONFIG[props.status].color}
      // sx={{height: 16, width: 16}}
    />
  );
}

export default PlayerObjectiveStatusIcon;
