import React from 'react';
import {useTranslation} from 'react-i18next';
import {IPlayerStatisticsWidgetData} from 'types/dashboard/overview-dashboards.types';
import {Card} from '../../../../common/card/Card';
import {Spacer} from '../../../../common/spacer/Spacer';
import Indicator from '../../../Indicator/Indicator';
import {PlayerStatistics} from '../../../players';
import {WidgetHeader} from '../widget-header/WidgetHeader';
import {WidgetSubtitle} from '../widget-subtitle/WidgetSubtitle';
import {
  getCurrentTeamScore,
  getMinMaxScores,
} from 'helpers/dashboard/dashboard.helper';
import {useTeams} from 'context/team.context';

interface IPlayerStatisticsWidgetProps {
  action: string;
  data: IPlayerStatisticsWidgetData;
  label?: string;
}

export const PlayerStatisticsWidget = ({
  action,
  data,
  label,
}: IPlayerStatisticsWidgetProps) => {
  const {t} = useTranslation();
  const _teamsContext = useTeams();

  // Data
  const {playerStatistics, scores} = data;

  // Scores
  const getScoreIndicator = () => {
    if (scores?.scores.length && _teamsContext.current) {
      const {min, max} = getMinMaxScores(scores.scores);
      const value = getCurrentTeamScore(
        scores.scores,
        _teamsContext.current._id,
      );

      return (
        <>
          <WidgetSubtitle
            text={t('overview-dashboards.widgets.labels.team-strength')}
          />
          <Indicator
            value={value}
            min={min}
            max={max}
            teamId={_teamsContext.current._id}
            scores={scores.scores}
            action={action}
            matchLimit={scores.match_limit}
            showInfo={true}
            valueDisplay="score"
          />
          <Spacer />
        </>
      );
    }
  };

  return (
    <Card>
      <WidgetHeader action={action} label={label} />

      {scores && getScoreIndicator()}

      <PlayerStatistics
        data={playerStatistics}
        hasCard={false}
        hasBorders={false}
      />
    </Card>
  );
};
