import React from 'react';
import {AutocompleteRenderGroupParams} from '@mui/material/Autocomplete';
import {darken, useTheme} from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Box from '@mui/material/Box';

// const GroupHeader = styled('div')(({theme}) => ({
//   position: 'sticky',
//   top: '-8px',
//   // px: 1,
//   // py: 4,
//   // padding: '4px 16px 4px 4px',
//   // paddingLeft: 20,
//   pl: 3,
//   color: theme.palette.secondary.light,
//   // backgroundColor: darken(theme.palette.background.paper, 0.1),
//   backgroundColor: 'red',
//   fontSize: 12,
//   zIndex: 1,
//   fontWeight: '500',
//   minHeight: 24,
//   display: 'flex',
//   alignItems: 'center',
//   // textTransform: 'uppercase',
// }));

// const GroupItems = styled('ul')({
//   padding: 0,
//   // pl: 1,
//   fontSize: 14,
//   backgroundColor: 'green',
// });

interface IAutocompleteGroupHeaderProps {
  params: AutocompleteRenderGroupParams;
  groupHeader: (key: string) => string;
  selected: boolean;
  onCheckboxChange: (checked: boolean, group: string) => void;
  multiple?: boolean;
}

function AutocompleteGroupHeader(props: IAutocompleteGroupHeaderProps) {
  function _onCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) {
    props.onCheckboxChange(checked, props.params.group);
  }
  const _theme = useTheme();

  const _Icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const _CheckedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <li key={props.params.key}>
      {/* Group Header */}
      <Box
        position="sticky"
        color="text.disabled"
        py={1}
        pl={props.multiple ? 0 : 2}
        pr={3}
        top={'-8px'}
        bgcolor={darken(_theme.palette.background.paper, 0.1)}
        fontSize={12}
        fontWeight={500}
        textTransform="uppercase"
        zIndex={1}>
        {props.multiple && (
          <Checkbox
            icon={_Icon}
            checkedIcon={_CheckedIcon}
            style={{marginRight: 4}}
            size="small"
            checked={props.selected}
            onChange={_onCheckboxChange}
          />
        )}
        {props.groupHeader(props.params.group)}
      </Box>

      {/* Group Options */}
      <Box component="ul" pl={0}>
        {props.params.children}
      </Box>
    </li>
  );
}

export default AutocompleteGroupHeader;
