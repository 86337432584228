import React from 'react';
import {
  DASHBOARD_STATE,
  useDashboard,
} from 'context/dashboard/dashboard.context';

import Indicator from '../Indicator';
import styles from './DashboardIndicator.module.scss';
import {getMinMaxScores} from 'helpers/dashboard/dashboard.helper';
import {useDashboardScores} from 'context/dashboard/dashboard-scores.context';
import {DASHBOARD_STATUS} from 'types/dashboard/dashboard.types';

export const DashboardIndicator = () => {
  const _dashboard = useDashboard();
  const {current, all, loading, matchLimit} = useDashboardScores();
  const {min, max} = getMinMaxScores(all);

  if (
    _dashboard.current?.status === DASHBOARD_STATUS.IN_BUILD ||
    _dashboard.state === DASHBOARD_STATE.ERROR
  )
    return <></>;

  if (!current || loading || _dashboard.state === DASHBOARD_STATE.LOADING)
    return null;

  return (
    <div className={styles.container}>
      <Indicator
        value={current.score}
        teamId={current.team._id}
        min={min}
        max={max}
        scores={all}
        action={_dashboard.current?.action}
        matchLimit={matchLimit}
        showInfo={true}
        valueDisplay="score"
      />
    </div>
  );
};
