import {
  Dimensions,
  IPitchVisualEventGroup,
} from 'types/dashboard/dashboard-pitch-visual.types';
import {
  ACTION,
  FIMatchEvent,
  isTrackingAction,
  PitchVisualFocusType,
} from '@my-game-plan/types';
import {DataPoint} from 'heatmap-ts';
import {
  calculateFinishingPosition,
  calculatePosition,
} from './pitch-visual.helper';

export interface HeatmapProps {
  action: ACTION;
  dimensions: Dimensions;
  eventGroups: IPitchVisualEventGroup[];
  linkedTable: string;
  defaultFocusType: PitchVisualFocusType;
  showPath?: boolean;
  defending?: boolean;
  isHalf?: boolean;
  isGoal?: boolean;
  shouldDisplayOnOwnHalf?: boolean;
}

export function transformDefaultFocusTypeForHeatmap(
  focusType: PitchVisualFocusType,
): PitchVisualFocusType {
  if (
    focusType === PitchVisualFocusType.START_END ||
    focusType === PitchVisualFocusType.START_FOCUS
  ) {
    return PitchVisualFocusType.START;
  } else if (focusType === PitchVisualFocusType.END_FOCUS) {
    return PitchVisualFocusType.END;
  }
  return focusType;
}

export function generateHeatmapData(props: HeatmapProps): DataPoint[] {
  const _positionCalculator = props.isGoal
    ? calculateFinishingPosition
    : calculatePosition;

  const _isTrackingAction = isTrackingAction(props.action);

  const heatmapValue = _isTrackingAction ? 10 : 50;

  const _isEndType =
    props.defaultFocusType === PitchVisualFocusType.START_END ||
    props.defaultFocusType === PitchVisualFocusType.END_FOCUS ||
    props.defaultFocusType === PitchVisualFocusType.END;

  const _events: FIMatchEvent[] = [];
  props.eventGroups.forEach((group) => {
    group.events.forEach((matchEvent) => {
      //
      if (matchEvent.tracking?.locations?.length) {
        matchEvent.tracking.locations.forEach((location) => {
          const _syntheticEvent: FIMatchEvent = {
            ...matchEvent,
            coordinates: location.location,
          };
          _events.push(_syntheticEvent);
        });
      } else {
        _events.push(matchEvent);
      }
    });
  });

  const _results: DataPoint[] = [];

  for (const event of _events) {
    const {startX, startY, endX, endY} = _positionCalculator(
      event,
      props.dimensions,
      props.isHalf,
      props.defending,
      props.shouldDisplayOnOwnHalf,
    );

    if (_isEndType && endX !== null && endY !== null) {
      _results.push({
        x: endX,
        y: endY,
        value: heatmapValue,
      });
    } else {
      _results.push({
        x: startX,
        y: startY,
        value: heatmapValue,
      });
    }
  }

  return _results;
}
