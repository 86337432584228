import React, {useEffect, useState} from 'react';

import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import {
  FITrackerSharedSubjects,
  PlayerPosition,
  TTrackerExecutorType,
} from '@my-game-plan/types';
import {ITypedOption} from 'types/option.types';
import {useTranslation} from 'react-i18next';
import CustomAutocomplete from 'components/material-customised/autocomplete/autocomplete.view';
import {
  getPlayerOptions,
  getPositionOptions,
  handlePositionOptionChange,
} from 'helpers/automation/automation-form.helper';
import {capitalize} from 'lodash';
import {useTeams} from 'context/team.context';

type TTeamDisplay = 'any' | 'all';
export interface ITeamPositionPlayerPickerProps<Multiple extends boolean> {
  against?: boolean;
  currentExecutorType: TTrackerExecutorType;
  value?: FITrackerSharedSubjects;
  onChange: (subjects?: FITrackerSharedSubjects) => void;
  multiple?: Multiple;
  teamDisplay?: TTeamDisplay;
  noValueOption?: boolean;
}

type TConditionExecutorRadio = 'any' | 'player_id' | 'player_position' | 'none';

function TeamPositionPlayerPicker<Multiple extends boolean>(
  props: ITeamPositionPlayerPickerProps<Multiple>,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const [_activeRadio, _setActiveRadio] =
    useState<TConditionExecutorRadio>('any');
  const [_radioOptions, _setRadioOptons] = useState<
    ITypedOption<TConditionExecutorRadio>[]
  >([]);

  const [_positionOptions, _setPositionOptions] = useState<
    ITypedOption<PlayerPosition>[]
  >([]);
  const [_selectedPositionOptions, _setSelectedPositionOptions] = useState<
    ITypedOption<PlayerPosition>[]
  >([]);
  const [_playerOptions, _setPlayerOptions] = useState<ITypedOption<string>[]>(
    [],
  );
  const [_selectedPlayerOptions, _setSelectedPlayerOptions] = useState<
    ITypedOption<string>[]
  >([]);

  /*
   * Side effects
   */

  // Set active radio based on value
  useEffect(() => {
    let _newRadioToSet: TConditionExecutorRadio = 'any';
    if (typeof props.value?.player_ids !== 'undefined') {
      _newRadioToSet = 'player_id';
    } else if (props.value?.player_positions) {
      _newRadioToSet = 'player_position';
    } else if (!props.value && props.noValueOption) {
      _newRadioToSet = 'none';
    }

    _setActiveRadio(_newRadioToSet);
  }, [props.value, props.noValueOption]);

  useEffect(() => {
    // Set radio options based on props.against
    const _teamDisplay = props.teamDisplay || 'any';
    const _anyPlayersLabel =
      _teamDisplay === 'any'
        ? t('sentenceForm.players.any')
        : t('sentenceForm.players.all');
    const _newOptions: ITypedOption<TConditionExecutorRadio>[] = [
      {value: 'any', label: _anyPlayersLabel},
      {
        value: 'player_position',
        label: t('sentenceForm.observing.playersByPosition'),
      },
    ];

    if (!props.against) {
      _newOptions.push({
        value: 'player_id',
        label: t('sentenceForm.observing.playersByName'),
      });
    }

    // Add optional "Nobody" option
    if (props.noValueOption) {
      _newOptions.unshift({
        value: 'none',
        label: t('sentenceForm.share.staffOnly'),
      });
    }
    _setRadioOptons(_newOptions);

    // If a player value is set for against condition, remove it.
    if (
      props.against &&
      typeof props.value?.player_ids !== 'undefined' &&
      props.onChange
    ) {
      props.onChange({team: true});
    }
  }, [props.against, props.value, props.teamDisplay, props.noValueOption]);

  /* Get position options */
  useEffect(() => {
    _setPositionOptions(getPositionOptions());
  }, []);

  // Set selected position options
  useEffect(() => {
    let _newSelectedOptions: ITypedOption<PlayerPosition>[] = [];
    if (props.value?.player_positions) {
      _newSelectedOptions = _positionOptions.filter((option) => {
        return props.value?.player_positions?.some(
          (position) => position === option.value,
        );
      });
    }
    _setSelectedPositionOptions(_newSelectedOptions);
  }, [props.value?.player_positions, _positionOptions]);

  /* Get player options */
  useEffect(() => {
    const _newPlayerOptions = getPlayerOptions(_teamsContext.ownPlayers || []);
    _setPlayerOptions(_newPlayerOptions);
  }, [_teamsContext.ownPlayers]);

  // Set selected player options
  useEffect(() => {
    let _newSelectedPlayerOptions: ITypedOption<string>[] = [];
    if (props.value?.player_ids) {
      _newSelectedPlayerOptions = _playerOptions.filter((option) => {
        return props.value?.player_ids?.includes(option.value);
      });
    }
    _setSelectedPlayerOptions(_newSelectedPlayerOptions);
  }, [props.value?.player_ids, _playerOptions]);

  /*
   * Handlers
   */
  function _onRadioChange(
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) {
    if (!props.onChange) {
      return;
    }
    // Update radio
    // _setActiveRadio(value as TConditionExecutorRadio);
    const _selectedValue = value as TConditionExecutorRadio;
    let _newSelectedValue: FITrackerSharedSubjects | undefined = {};

    if (_selectedValue === 'none') {
      _newSelectedValue = undefined;
    } else if (_selectedValue === 'any') {
      _newSelectedValue = {team: true};
    } else if (_selectedValue === 'player_position') {
      _newSelectedValue.player_positions = [];
    } else if (_selectedValue === 'player_id') {
      _newSelectedValue.player_ids = [];
    }

    props.onChange(_newSelectedValue);
  }

  function _onPositionsSelectChange(
    data: ITypedOption<string> | ITypedOption<string>[] | null,
  ) {
    if (!props.onChange) {
      return;
    }

    let _selectedPositions: PlayerPosition[] | undefined = undefined;
    if (data && Array.isArray(data)) {
      _selectedPositions = handlePositionOptionChange(data);
    }

    props.onChange({
      player_positions: _selectedPositions,
    });
  }

  function _onPlayersSelectChange(
    data: ITypedOption<string> | ITypedOption<string>[] | null,
  ) {
    if (!props.onChange) {
      return;
    }
    // let _playersToSet: string[] | undefined = undefined;
    if (Array.isArray(data)) {
      // const _playersToSet = data?.map((selectedOption) => selectedOption.value);
      // TODO - Handle arrays
      props.onChange({player_ids: data.map((option) => option.value)});
    } else if (data) {
      // _playersToSet = [data.value];
      props.onChange({player_ids: [data.value]});
    }
  }

  /*
   * Render
   */

  function _positionsGroupHeaderKey(text: string): string {
    return capitalize(t(`playerPosition.${text}s`));
  }

  function _renderAutocomplete(type: TConditionExecutorRadio) {
    if (type === 'any') {
      return null;
    }

    if (type === 'player_position') {
      return (
        <CustomAutocomplete<string, true>
          disableCloseOnSelect
          placeholder={t('sentenceForm.observing.positionsPlaceholder')}
          options={_positionOptions}
          onChange={_onPositionsSelectChange}
          groupHeader={_positionsGroupHeaderKey}
          multiple={true}
          value={_selectedPositionOptions}
        />
      );
    }

    if (type === 'player_id' && props.multiple) {
      return (
        <CustomAutocomplete<string, true>
          placeholder={t('sentenceForm.observing.playersPlaceholder')}
          options={_playerOptions}
          onChange={_onPlayersSelectChange}
          groupHeader={_positionsGroupHeaderKey}
          multiple
          value={_selectedPlayerOptions}
        />
      );
    } else if (type == 'player_id') {
      return (
        <CustomAutocomplete<string, false>
          placeholder={t('sentenceForm.observing.playersPlaceholder')}
          options={_playerOptions}
          onChange={_onPlayersSelectChange}
          groupHeader={_positionsGroupHeaderKey}
          value={_selectedPlayerOptions[0] || null}
        />
      );
    }

    return null;
  }
  return (
    <Stack p={2} minWidth={240} maxWidth={400}>
      <FormControl fullWidth>
        <RadioGroup
          value={_activeRadio}
          onChange={_onRadioChange}
          sx={{gap: 1}}>
          {_radioOptions.map((option) => (
            <Stack key={option.value} gap={1}>
              <FormControlLabel
                value={option.value}
                control={<Radio size="small" />}
                label={option.label}
              />
              {option.value === _activeRadio &&
                option.value !== 'any' &&
                option.value !== 'none' && (
                  <Stack mb={2} pl={1}>
                    {_renderAutocomplete(option.value)}
                  </Stack>
                )}
            </Stack>
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
}

export default TeamPositionPlayerPicker;
