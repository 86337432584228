import {
  createContextHook,
  createCustomContext,
  createProvider,
} from 'helpers/general/context_generators.helper';
import {
  FIFormattedAutomation,
  FIFormattedAutomationPlayerScores,
  FIMatchEvent,
  FIPitchZone,
  FIBenchmarkOptions,
  generateTotalScoresForPlayer,
  TBenchmarkDisabledReason,
  shouldInvertScore,
  getBenchmarkDisabledReason,
  getPercentileScore,
  FIBenchmarkRankingItem,
  FIAutomationPlayerScores,
  FIAutomationPlayerMatch,
  FICompactMatchInfo,
  FIAutomationDataQueryParams,
  TBenchmarkState,
  FIBenchmarkData,
  FIBenchmarkTimeOptions,
  TTrackerExecutorType,
} from '@my-game-plan/types';
import {PropsWithChildren, useEffect, useRef, useState} from 'react';
import {useTeams} from '../team.context';
import {useZones} from '../zones.context';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import {formatPlayerScores} from 'helpers/automation/automation-detail-history.helper';
import {extractZonesFromAutomation} from 'helpers/pitch-zones.helper';
import {useAnalytics} from '../analytics.context';
import ANALYTICS_EVENT from 'config/analytics/event-names.config';

import {useVideo} from '../video/video.context';
import {
  formatSearchParamsToQueryParams,
  getLastMatches,
} from 'helpers/automation/automation.helper';
import {serializeParams} from 'helpers/api.helper';
import {LOADING_STATE} from 'types/screen.types';
import {IAutomationDeepDiveParams, ILinkState} from 'types/navigation.types';
import {
  fetchBenchmarkForStatistic,
  fetchEventsForTracker,
  fetchTrackerByStatisticId,
} from 'controllers/trackers.controller';
import {NAV_LEVEL_1_ROUTE} from 'config/navigation.config';
import {useTranslation} from 'react-i18next';

export interface AutomationDetailAPI {
  automation: FIFormattedAutomation | null;
  onEditAutomation: (
    automation: FIFormattedAutomation | null,
    wasDeleted?: boolean,
  ) => void;
  executorType: TTrackerExecutorType;
  automationLoadingState: LOADING_STATE;
  isFetchingEvents: boolean;
  events: FIMatchEvent[];
  visibleEvents: FIMatchEvent[];
  history: FIAutomationPlayerMatch[];
  scores: FIFormattedAutomationPlayerScores | null;

  observingMatchIds: string[];
  observingMatches: FICompactMatchInfo[];
  onObservingMatchIdChange: (selectedMatchIds: string[]) => void;

  observingTeamImageUrl: string | undefined;

  observingTeamId: string | undefined;
  onOwnDataFiltersChange: (filters: FIBenchmarkTimeOptions) => Promise<void>;

  zones: FIPitchZone[];

  benchmarkAndDateFilters: FIAutomationDataQueryParams | null;
  benchmarkStatus: TBenchmarkState;
  benchmarkData: FIBenchmarkData | undefined;
  benchmarkError: string | null;
  benchmarkDisabledReason: TBenchmarkDisabledReason | null;
  getBenchmarkInfo: (benchmarkFilters: FIBenchmarkOptions) => Promise<void>;

  benchmarkedScore: number | undefined;
  benchmarkAverage: number | undefined;

  error: boolean;
  allMatches: FICompactMatchInfo[];

  // clear: () => void;

  isOnlyShowingObjectiveEvents: boolean;
  setIsOnlyShowingObjectiveEvents: (value: boolean) => void;

  openVideoPlayer(
    clips: FIMatchEvent[],
    selectedClip?: FIMatchEvent,
    eventsPerSequence?: Record<string, FIMatchEvent[]>,
  ): void;

  isSinglePlayerTracker: boolean;
  backButton?: ILinkState;
}

const context = createCustomContext<AutomationDetailAPI>();
export const useAutomationDetail = createContextHook(context);

interface IProviderProps {
  id?: string;
  subjectId?: string;
  executorType: TTrackerExecutorType;
}
export function AutomationDetailProvider(
  props: PropsWithChildren<IProviderProps>,
): JSX.Element {
  /*
   * Hooks n State
   */
  const [_automation, _setAutomation] = useState<FIFormattedAutomation | null>(
    null,
  );
  const [_automationLoadingState, _setAutomationLoadingState] =
    useState<LOADING_STATE>(LOADING_STATE.INITING);
  const [_isFetchingEvents, _setIsFetchingEvents] = useState(true);
  const [_events, setEvents] = useState<FIMatchEvent[]>([]);
  const [_visibleEvents, setVisibleEvents] = useState<FIMatchEvent[]>([]);
  const [_history, _setHistory] = useState<FIAutomationPlayerMatch[]>([]);
  const [_isOnlyShowingObjectiveEvents, _setIsOnlyShowingObjectiveEvents] =
    useState<boolean>(false);

  const [_scores, _setScores] =
    useState<FIFormattedAutomationPlayerScores | null>(null);

  const [_totalScores, _setTotalScores] =
    useState<FIAutomationPlayerScores | null>(null);

  const [_observingMatchIds, _setObservingMatchIds] = useState<string[]>([]);
  const [_observingMatches, _setObservingMatches] = useState<
    FICompactMatchInfo[]
  >([]);
  const [_observingTeamId, _setObservingTeamId] = useState<string | undefined>(
    undefined,
  );
  const [_observingTeamImageUrl, _setObservingTeamImageUrl] = useState<
    string | undefined
  >(undefined);

  const [_zones, _setZones] = useState<FIPitchZone[]>([]);

  const [_benchmarkDisabledReason, _setBenchmarkDisabledReason] =
    useState<TBenchmarkDisabledReason | null>(null);
  // const [_benchmarkInfo, _setBenchmarkInfo] = useState<FIBenchmarkInfo | null>(
  //   null,
  // );
  const [_benchmarkAndDateFilters, _setBenchmarkAndDateFilters] =
    useState<FIAutomationDataQueryParams | null>(null);
  const [_benchmarkStatus, _setBenchmarkStatus] =
    useState<TBenchmarkState>('none');
  const [_benchmarkData, _setBenchmarkData] = useState<
    FIBenchmarkData | undefined
  >(undefined);
  const [_benchmarkError, _setBenchmarkError] = useState<string | null>(null);
  const [_benchmarkedScore, _setBenchmarkedScore] = useState<
    number | undefined
  >(undefined);
  const [_benchmarkAverage, _setBenchmarkAverage] = useState<
    number | undefined
  >(undefined);

  const [_error, _setError] = useState<boolean>(false);

  const [_allMatches, _setAllMatches] = useState<FICompactMatchInfo[]>([]);
  const [_searchParams, _setSearchParams] = useSearchParams();

  const [_isSinglePlayerTracker, _setIsSinglePlayerTracker] =
    useState<boolean>(false);
  const [_backButton, _setBackButton] = useState<ILinkState | undefined>(
    undefined,
  );
  const _sentIntialMixpanelEvent = useRef<boolean>(false);

  const {t} = useTranslation();
  const _params = useParams<IAutomationDeepDiveParams>();
  const _location = useLocation();
  const _navigate = useNavigate();

  const _teamsContext = useTeams();
  const _zonesContext = useZones();
  const _analyticsContext = useAnalytics();
  const _videoContext = useVideo();

  /*
   * Side effects
   */
  useEffect(() => {
    if (!props.id || !props.subjectId) {
      return;
    }

    _fetchAutomation();
  }, [props.id, props.subjectId, _searchParams]);

  // Define back link
  useEffect(() => {
    let _newBackButton: ILinkState = {
      route: '',
      label: '',
    };

    if (_location?.state) {
      _newBackButton = _location.state as ILinkState;
    }

    if (!_newBackButton.route && props.executorType === 'opponent_analysis') {
      _newBackButton.route = NAV_LEVEL_1_ROUTE.OPPONENT_AUTOMATIONS;
      _newBackButton.label = t('navigation.opponent-automations');
    } else if (
      !_newBackButton.route &&
      props.executorType === 'scouting_profile_ids'
    ) {
      _newBackButton.label = t('navigation.scouting');
      _newBackButton.route = NAV_LEVEL_1_ROUTE.SCOUTING;
    } else if (
      !_newBackButton.route &&
      props.executorType === 'team_performance'
    ) {
      _newBackButton.label = t('navigation.team');
      _newBackButton.route = NAV_LEVEL_1_ROUTE.TEAM;
    } else if (
      !_newBackButton.route &&
      props.executorType === 'player_development'
    ) {
      _newBackButton.label = t('navigation.idp');
      _newBackButton.route = NAV_LEVEL_1_ROUTE.PLAYERS;

      const _player = _automation?.players?.[0];
      if (_player) {
        (_newBackButton.label = _player.display_name),
          (_newBackButton.route = `${NAV_LEVEL_1_ROUTE.PLAYERS}/${_player._id}`);
      }
    }

    // Define query params for backbutton
    // Parse _searchParams to FIAutomationDataQueryParams
    const _parsedQuery = formatSearchParamsToQueryParams(_searchParams);

    // Delete against and executor_positions from URL -hacky?
    delete _parsedQuery.against;
    delete _parsedQuery.executor_positions;
    const _searchQuery = serializeParams(_parsedQuery);

    if (_searchQuery) {
      _newBackButton.route += `?${_searchQuery}`;
    }

    _newBackButton.from = 'tracker-deep-dive';
    _setBackButton(_newBackButton);
  }, [props.executorType, _location.state, _searchParams]);

  /* Get observing team */
  useEffect(() => {
    let _teamToObserve = _teamsContext.ownTeam?._id;

    if (props.executorType === 'opponent_analysis') {
      _teamToObserve = _teamsContext.currentlyObservingOpponentId;

      // If there is no next opponent OR no team _id is passe, show error
      if (!_teamToObserve) {
        _setError(true);
        return;
      }
    } else if (props.executorType === 'scouting_profile_ids') {
      const historyEntry = _history[0];
      if (historyEntry) {
        _teamToObserve = historyEntry.own_team_id;
      }
    }

    _setObservingTeamId(_teamToObserve);
  }, [
    _teamsContext.ownTeam,
    _teamsContext.currentlyObservingOpponentId,
    props.executorType,
  ]);
  useEffect(() => {
    if (_observingTeamId && _teamsContext.all.length) {
      const _observingTeam = _teamsContext.all.find(
        (team) => team._id === _observingTeamId,
      );
      if (_observingTeam) {
        _setObservingTeamImageUrl(_observingTeam.image_url);
      } else if (_history[0]?.match) {
        const {home_team, away_team} = _history[0].match;
        const _teamUrl =
          home_team._id === _observingTeamId
            ? home_team.image_url
            : away_team.image_url;
        _setObservingTeamImageUrl(_teamUrl);
      }
    }
  }, [_observingTeamId, _teamsContext.all, _history]);

  /* When automation was fetched, get events */
  useEffect(() => {
    if (!props.id || !props.subjectId || !_automation) {
      return;
    }

    _fetchAutomationEvents();
  }, [_automation, props.id, props.subjectId]);

  /* Recalculate scores */
  useEffect(() => {
    if (_observingMatchIds?.length && _automation?.players?.length) {
      _setScoresHandler(
        _benchmarkAverage,
        _benchmarkAndDateFilters?.benchmark?.calculation === 'per_90',
      );
    } else {
      _setScores(null);
    }
  }, [
    _observingMatchIds,
    _automation?.players,
    _benchmarkAverage,
    _benchmarkAndDateFilters,
  ]);

  // update the position and scores of the observing player in the benchmark ranking
  useEffect(() => {
    _updateObservingPlayerInBenchmark();
  }, [_totalScores, _benchmarkAndDateFilters]);

  // update the position and scores of the observing player in the benchmark ranking

  /* Parse zone info */
  useEffect(() => {
    let _zones: FIPitchZone[] = [];
    const _zoneIds = extractZonesFromAutomation(_automation);
    _zones = _zonesContext.all.filter((zone) => _zoneIds.includes(zone._id));
    _setZones(_zones);
  }, [_automation, _zonesContext.all]);

  /* Init benchmark filters */
  useEffect(() => {
    if (!_automation) {
      return;
    }

    _setIsSinglePlayerTracker(
      Boolean(_automation.concrete_executor?.player_id),
    );

    //}
    const _shouldBenchmark = !getBenchmarkDisabledReason(_automation);
    if (_shouldBenchmark) {
      const _initialFilters: FIAutomationDataQueryParams = {
        benchmark: _automation.benchmark_filters || {},
        own_data: {
          time: _automation.own_filters,
        },
        against: _automation.against,
        executor_positions:
          _automation.concrete_executor?.team?.executor_positions,
      };
      _getAutomationBenchmarkData(_initialFilters);

      return;
    }

    _setBenchmarkData(undefined);
    _setBenchmarkStatus('none');
    // _setBenchmarkInfo(null);
  }, [_automation]);

  /* Define filtered events */
  useEffect(() => {
    const _filteredEvents = _events.filter((event) => {
      let _includeObjectiveEvent = true;
      if (_isOnlyShowingObjectiveEvents) {
        _includeObjectiveEvent = Boolean(event.successful);
      }
      return (
        _includeObjectiveEvent && _observingMatchIds.includes(event.match._id)
      );
    });

    setVisibleEvents(_filteredEvents);
  }, [_events, _isOnlyShowingObjectiveEvents, _observingMatchIds]);

  /* Set history on automation load */
  useEffect(() => {
    const _firstPlayer = _automation?.players?.[0];
    let _playerHistory: FIAutomationPlayerMatch[] = [];
    if (_firstPlayer) {
      _playerHistory = getLastMatches(
        _firstPlayer.history,
        _observingMatchIds,
        true,
      ).sort(
        (a, b) =>
          new Date(b.match.date).getTime() - new Date(a.match.date).getTime(),
      );
    }
    _setHistory(_playerHistory);
  }, [_automation, _observingMatchIds]);

  /* Set observing matches on _observingMatchIds change */
  useEffect(() => {
    const _newObservingMatches = _allMatches.filter((match) =>
      _observingMatchIds.includes(match._id),
    );
    _setObservingMatches(_newObservingMatches);
  }, [_observingMatchIds, _allMatches]);

  /*
   * Handlers
   */
  // Calculate scores based on benchmark data, players and observing matches
  function _setScoresHandler(
    benchmarkAverage?: number,
    calculatePer90?: boolean,
    benchmarkedScore?: number,
  ) {
    if (!_automation?.players?.length || !_observingMatchIds?.length) {
      _setScores(null);
      _setBenchmarkedScore(undefined);
      return;
    }

    const _newBenchmarkedScore =
      typeof benchmarkedScore !== 'undefined'
        ? benchmarkedScore
        : _benchmarkedScore;
    _setBenchmarkedScore(_newBenchmarkedScore);

    /* Filter history */
    const _filteredHistory = _automation.players[0].history.filter((history) =>
      _observingMatchIds.includes(history.match._id),
    );
    const _isRatioTracker =
      _automation.calculation === 'ratio' || _automation.calculation === 'rule';

    const _totalScores = generateTotalScoresForPlayer(
      _filteredHistory,
      !_isSinglePlayerTracker,
      _isRatioTracker,
      Boolean(_automation.metric),
      benchmarkAverage,
      _newBenchmarkedScore,
      calculatePer90,
    );

    const _shouldInvert = shouldInvertScore(_automation);
    _setTotalScores(_totalScores);
    _setScores(
      formatPlayerScores(
        _automation.action,
        _totalScores,
        _automation.calculation,
        _automation.rule_condition?.action,
        true,
        calculatePer90,
        _shouldInvert,
        _automation.metric,
      ),
    );
  }

  // Fetch automation
  async function _fetchAutomation(filters?: FIAutomationDataQueryParams) {
    //
    if (!props.id || !props.subjectId) {
      return;
    }
    try {
      if (_automationLoadingState !== LOADING_STATE.INITING) {
        _setAutomationLoadingState(LOADING_STATE.LOADING);
      }
    } catch (error) {
      _setError(true);
      _setAutomationLoadingState(LOADING_STATE.ERROR);
      _setAutomation(null);
    }

    const _parsedQuery = formatSearchParamsToQueryParams(_searchParams);
    const _queryParams = filters || _parsedQuery;
    const _fetchedTracker = await fetchTrackerByStatisticId(
      props.id,
      props.subjectId,
      _queryParams,
    );

    /* Track Mixpanel Event */
    if (!_sentIntialMixpanelEvent.current) {
      _sentIntialMixpanelEvent.current = true;
      _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_TRACKERS_DETAIL, {
        type: props.executorType,
        id: _fetchedTracker._id,
        statistic_id: _fetchedTracker.statistic_id,
        subject_id:
          _fetchedTracker.concrete_executor?.player_id ||
          _fetchedTracker.concrete_executor?.team?._id ||
          '',
        executor_team: Boolean(_fetchedTracker.concrete_executor?.team),
        executor_player_id: _fetchedTracker.concrete_executor?.player_id,
        executor_positions:
          _fetchedTracker.concrete_executor?.team?.executor_positions,
        against: _fetchedTracker.against,
      });
    }

    if (_fetchedTracker.players?.length) {
      // Define default match filters based on response
      const _allMatchIds = _fetchedTracker.players[0].history
        .filter((historyItem) => historyItem.checked === true) // Only include items with `checked: true`
        .map((historyItem) => historyItem.match._id);

      const _allMatchesByTeam = _fetchedTracker.players[0].history
        .map((historyItem) => historyItem.match)
        .sort(
          (a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf(),
        );
      _setAllMatches(_allMatchesByTeam);
      _setObservingMatchIds(_allMatchIds);
    }

    /* Benchmark */
    /* Set default benchmark score */
    _setBenchmarkedScore(_fetchedTracker.benchmark_scores?.benchmarked_score);
    _setBenchmarkAverage(_fetchedTracker.benchmark_scores?.benchmark_average);

    // Set filters
    _setBenchmarkAndDateFilters({
      own_data: {
        time: _fetchedTracker.own_filters,
      },
      benchmark: _fetchedTracker.benchmark_filters || {},
      against: _fetchedTracker.against,
      executor_positions:
        _fetchedTracker.concrete_executor?.team?.executor_positions,
    });

    // Check if benchmark is disabled
    const _newBenchmarkDisabledReason =
      getBenchmarkDisabledReason(_fetchedTracker);

    if (_newBenchmarkDisabledReason) {
      _setBenchmarkDisabledReason(_newBenchmarkDisabledReason);

      /* Set automation */

      _setAutomation(_fetchedTracker);
      _setAutomationLoadingState(LOADING_STATE.SUCCESS);
      return;
    }

    if (_fetchedTracker.benchmark_data) {
      _setBenchmarkStatus('active');
      _setBenchmarkData(_fetchedTracker.benchmark_data);
    }

    /* Set automation */

    _setAutomation(_fetchedTracker);
    _setAutomationLoadingState(LOADING_STATE.SUCCESS);
  }

  function onEditAutomation(
    newAutomation: FIFormattedAutomation | null,
    wasDeleted?: boolean,
  ) {
    if (wasDeleted) {
      _navigate(_backButton?.route || NAV_LEVEL_1_ROUTE.HOME);
      return;
    }

    // const _newQueryParams: FIAutomationDataQueryParams = {
    //   ...(_benchmarkAndDateFilters || {benchmark: {}, own_data: {time: {}}}),
    //   against: newAutomation?.against,
    //   executor_positions:
    //     newAutomation?.concrete_executor?.team?.executor_positions,
    // };

    // _fetchAutomation(_newQueryParams);
  }

  async function _onOwnDataFiltersChange(filters: FIBenchmarkTimeOptions) {
    if (!_params.id || !_benchmarkAndDateFilters) {
      return;
    }

    const _filtersForRequest: FIAutomationDataQueryParams = {
      ..._benchmarkAndDateFilters,
      own_data: {
        time: filters,
      },
    };
    _setSearchParams(serializeParams(_filtersForRequest));
    _fetchAutomation(_filtersForRequest);
  }

  async function _fetchAutomationEvents() {
    if (!props.id || !props.subjectId || !_automation) {
      return;
    }

    try {
      _setIsFetchingEvents(true);

      const _filters: FIAutomationDataQueryParams = {
        own_data: {time: _automation.own_filters},
        against: _automation.against,
        executor_positions:
          _automation.concrete_executor?.team?.executor_positions,
        benchmark: {},
      };
      const _fetchedEvents = await fetchEventsForTracker(
        props.id,
        props.subjectId,
        _filters,
      );

      setEvents(_fetchedEvents);
      _setIsFetchingEvents(false);
    } catch (error) {
      _setIsFetchingEvents(false);
    }
  }

  async function _getAutomationBenchmarkData(
    benchmarkFilters: FIAutomationDataQueryParams,
  ) {
    if (!props.id || !props.subjectId) {
      return;
    }
    _setBenchmarkError(null);
    _setBenchmarkStatus('loading');
    _setBenchmarkAndDateFilters(benchmarkFilters);
    if (_automation) {
      try {
        let _result = await fetchBenchmarkForStatistic(
          props.id,
          props.subjectId,
          benchmarkFilters,
        );

        let waitTime = 1000; // Start with 1 second

        while (_result.in_progress) {
          // Wait for the current wait time
          await new Promise((resolve) => setTimeout(resolve, waitTime));

          // Call benchmarkAutomation again
          _result = await fetchBenchmarkForStatistic(
            props.id,
            props.subjectId,
            benchmarkFilters,
          );

          // Increase the wait time (add 1 second each time)
          waitTime = Math.min(waitTime + 1000, 10000); // Cap at 10 seconds to prevent excessively long waits
        }

        if (_result.ranking?.length > 1) {
          _setBenchmarkData(_result);
          _setBenchmarkStatus('active');

          _setBenchmarkAverage(_result.benchmark_average);
          _setScoresHandler(
            _result.benchmark_average,
            benchmarkFilters.benchmark.calculation === 'per_90',
            _result.benchmarked_score,
          );
        } else {
          _setBenchmarkData(undefined);
          _setBenchmarkStatus('error');
          // _setBenchmarkInfo(
          //   filters: benchmarkFilters,
          //   state: 'error',
          //   benchmark_data: undefined,
          //   error: 'no-data',
          // });
          _setBenchmarkedScore(undefined);
        }

        // _analyticsContext.trackEvent(ANALYTICS_EVENT.BENCHMARKED_TRACKER, {
        //   ...benchmarkFilters,
        //   id: _automation._id,
        // });
      } catch (error) {
        _setBenchmarkData(undefined);
        _setBenchmarkStatus('error');
        _setBenchmarkError(error ? (error as any).message : 'default');
      }
    }
  }

  async function _getBenchmarkHandler(benchmarkOptions: FIBenchmarkOptions) {
    const _filters: FIAutomationDataQueryParams = {
      benchmark: benchmarkOptions,
      own_data: _benchmarkAndDateFilters?.own_data || {time: undefined},
      against: _benchmarkAndDateFilters?.against,
      executor_positions: _benchmarkAndDateFilters?.executor_positions,
    };

    _getAutomationBenchmarkData(_filters);

    const _serializeduery = serializeParams(_filters);
    _setSearchParams(_serializeduery);
  }

  function _updateObservingPlayerInBenchmark() {
    if (_automation?.players && _teamsContext.ownTeam) {
      const _benchmarkItem: FIBenchmarkRankingItem = {
        team: _teamsContext.ownTeam,
        total_events: _totalScores?.total_events ?? 0,
        total_successful_events: _totalScores?.total_successful_events ?? 0,
        matches_played: _totalScores?.matches_played ?? 0,
        minutes_played: _totalScores?.minutes_played ?? 0,
        average: _totalScores?.average ?? 0,
      };
      const _currentRanking = _benchmarkData?.ranking;

      if (_currentRanking) {
        _addItemToRanking(
          _automation.players[0]._id,
          _benchmarkItem,
          _currentRanking,
        );
      }
    }
  }

  function _addItemToRanking(
    id: string,
    item: FIBenchmarkRankingItem,
    ranking: FIBenchmarkRankingItem[],
  ) {
    const _existingIndex = ranking.findIndex((r) => r.player?._id === id);
    if (_existingIndex !== -1) {
      // Replace the scores
      ranking[_existingIndex].total_events = item.total_events;
      ranking[_existingIndex].total_successful_events =
        item.total_successful_events;
      ranking[_existingIndex].matches_played = item.matches_played;
      ranking[_existingIndex].minutes_played = item.minutes_played;
      ranking[_existingIndex].average = item.average;
      _resortBenchmarkRanking(id, ranking);
    }
  }

  function _resortBenchmarkRanking(
    id: string,
    ranking: FIBenchmarkRankingItem[],
  ) {
    let _invertScores = false;
    if (_automation) _invertScores = shouldInvertScore(_automation);

    ranking = ranking
      .sort((a, b) => {
        const aScore = a?.average ?? 0;
        const bScore = b?.average ?? 0;

        // If _invertScores is true, sort in the opposite order
        return _invertScores ? aScore - bScore : bScore - aScore;
      })
      .map((item, index) => {
        return {
          ...item,
          benchmarked_score: getPercentileScore(ranking.length, index + 1),
        };
      });
    _updateIdToFindInRanking(id, ranking);
  }

  function _updateIdToFindInRanking(
    id: string,
    ranking: FIBenchmarkRankingItem[],
  ) {
    const _observingIndex = ranking.findIndex((r) => r.player?._id === id);
    if (_benchmarkData) {
      _benchmarkData.position_in_rank = _observingIndex;
      _benchmarkData.ranking = ranking;
      _setBenchmarkedScore(
        _benchmarkData.ranking[_observingIndex].benchmarked_score,
      );
    }
  }

  /* Match filter */
  function _onObservingMatchIdsChanged(selectedMatchIds: string[]) {
    _setObservingMatchIds(selectedMatchIds);
  }

  /* Video Player */
  function _openVideoPlayer(
    clips: FIMatchEvent[],
    selectedClip?: FIMatchEvent,
  ) {
    const _matchIdsInClips = clips.map((clip) => clip.match._id);
    const _uniqueMatchIdsInClips = Array.from(new Set(_matchIdsInClips));

    const _includedMatches = _allMatches.filter((match) =>
      _uniqueMatchIdsInClips.includes(match._id),
    );

    if (!_automation) return;
    _videoContext.openVideoPlayer(
      clips,
      _includedMatches,
      _automation.calculation === 'ratio' || _automation.calculation === 'rule',
      undefined,
      {
        action: _automation.rule_condition?.action || _automation.action,
        details: _automation.details || _automation.success_details,
        metric: _automation.metric,
      },
      selectedClip,
      _automation.action === 'sequence',
    );
  }

  /*
   * RETURN PROVIDER
   */
  return createProvider<AutomationDetailAPI, IProviderProps>(context, props, {
    automation: _automation,
    onEditAutomation: onEditAutomation,
    executorType: props.executorType,
    automationLoadingState: _automationLoadingState,
    isFetchingEvents: _isFetchingEvents,
    events: _events,
    visibleEvents: _visibleEvents,
    history: _history,
    scores: _scores,

    observingMatchIds: _observingMatchIds,
    observingMatches: _observingMatches,
    onObservingMatchIdChange: _onObservingMatchIdsChanged,

    observingTeamId: _observingTeamId,
    observingTeamImageUrl: _observingTeamImageUrl,
    onOwnDataFiltersChange: _onOwnDataFiltersChange,

    zones: _zones,

    benchmarkAndDateFilters: _benchmarkAndDateFilters,
    benchmarkStatus: _benchmarkStatus,
    benchmarkData: _benchmarkData,
    benchmarkError: _benchmarkError,

    getBenchmarkInfo: _getBenchmarkHandler,
    benchmarkDisabledReason: _benchmarkDisabledReason,

    benchmarkedScore: _benchmarkedScore,
    benchmarkAverage: _benchmarkAverage,
    error: _error,
    allMatches: _allMatches,

    isOnlyShowingObjectiveEvents: _isOnlyShowingObjectiveEvents,
    setIsOnlyShowingObjectiveEvents: _setIsOnlyShowingObjectiveEvents,

    openVideoPlayer: _openVideoPlayer,

    isSinglePlayerTracker: _isSinglePlayerTracker,
    backButton: _backButton,
  });
}
