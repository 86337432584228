import React, {useEffect, useState} from 'react';
import {IAutomationFilters} from 'types/event-automations-misc.types';
import {
  ENTIRE_TEAM_LABEL,
  FIPlayerWithData,
  POSITIONS_PER_LINE,
  TPositionGroup,
} from '@my-game-plan/types';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PlayerPerformanceCard from 'components/overview-screen/cards/player-performance-card.view';
import PlayerCard from 'components/players/player-card/player-card.view';

interface IPerformanceCardsOverviewProps {
  data: FIPlayerWithData[];
  selectedFilters: IAutomationFilters;
}

interface IPositionLineWithPlayers {
  line?: string;
  label?: string;
  players: FIPlayerWithData[];
}

function PerformanceCardsOverview(
  props: IPerformanceCardsOverviewProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_positionLines, _setPositionLines] = useState<
    IPositionLineWithPlayers[]
  >([]);
  /* Generate position lines with filtered players and tags */
  useEffect(() => {
    const _generatedPositionLines: IPositionLineWithPlayers[] = [];
    const _filteredPlayers = props.data.filter((player) => {
      return (
        !props.selectedFilters?.players?.length ||
        props.selectedFilters.players?.includes(player._id)
      );
    });

    Object.keys(POSITIONS_PER_LINE).forEach((line) => {
      const _line = line as TPositionGroup;
      if (POSITIONS_PER_LINE[_line]) {
        const _positionPlayers = _filteredPlayers.filter((player) => {
          return (
            player.position &&
            player.position.some((position) =>
              POSITIONS_PER_LINE[_line].positions.includes(position),
            )
          );
        });

        // if (props.selectedFilters.tags?.length) {
        //   _positionPlayers = _positionPlayers.map((player) => {
        //     const _categories = player.performance?.categories.filter((category) => {
        //       return (
        //         !props.selectedFilters.tags?.length ||
        //         props.selectedFilters.tags?.includes(category._id)
        //       );
        //     });
        //     return {
        //       ...player,
        //       categories: _categories,
        //     };
        //   });
        // }

        const _generatedPositionLine: IPositionLineWithPlayers = {
          line: _line,
          label: capitalize(t(`playerPosition.${_line}s`)),
          players: _positionPlayers,
        };

        if (_generatedPositionLine.players.length) {
          _generatedPositionLines.push(_generatedPositionLine);
        }
      }
    });

    _setPositionLines(_generatedPositionLines);
  }, [props.data, props.selectedFilters]);

  // Hack - Set players with trackers as default filters
  // useEffect(() => {
  //   const _playersWithTrackers = props.data.filter(
  //     (player) => player.automations_count,
  //   );
  //   const _playerIds = _playersWithTrackers.map((player) => player._id);

  //   const _teamPlayer = props.data.find(
  //     (player) => player.display_name === ENTIRE_TEAM_LABEL,
  //   );
  //   if (_teamPlayer?.automations_count) {
  //     _playerIds.unshift(ENTIRE_TEAM_LABEL);
  //   }

  //   if (!props.selectedFilters.players) {
  //     props.onUpdateFilters({
  //       ...props.selectedFilters,
  //       players: _playerIds,
  //     });
  //   }
  // }, [props.data, props.selectedFilters]);

  /*
   * Handlers
   */

  /*
   * Render
   */

  return (
    <Stack spacing={8}>
      {_positionLines.map((line) => {
        return (
          <Stack key={line.line || 'team'} gap={4}>
            {line.label && (
              <Stack gap={2}>
                <Typography color="text.secondary" fontSize={20}>
                  {line.label}
                </Typography>
                <Divider orientation="horizontal" />
              </Stack>
            )}
            <Grid container direction="row" columnSpacing={2} rowSpacing={2}>
              {line.players.map((player) => {
                return (
                  <Grid item key={player._id} lg={4} md={6} xs={12}>
                    {player.display_name === ENTIRE_TEAM_LABEL ? (
                      <PlayerPerformanceCard
                        player={player}
                        isTeamCard={player.display_name === ENTIRE_TEAM_LABEL}
                      />
                    ) : (
                      <PlayerCard player={player} displayPerformance />
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        );
      })}
    </Stack>
  );
}

export default PerformanceCardsOverview;
