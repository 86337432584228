import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Indicator from '../dashboard/Indicator/Indicator';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
export interface IScoredTab {
  value: string;
  benchmarked_score?: number;
  label: string;
  isOtherCat?: boolean;
}

interface IScoredTabsProps {
  tabs: IScoredTab[];
  activeTabId: string;
  onTabChange: (value: string) => void;
}

function ScoredTabs(props: IScoredTabsProps): JSX.Element {
  /*
   * Hooks n State
   */

  /*
   * Hooks n State
   */

  /*
   * Handlers
   */
  function _onTabChange(event: React.SyntheticEvent, newValue: string) {
    props.onTabChange(newValue);
  }

  /*
   * Render
   */
  return (
    <Tabs
      value={props.activeTabId}
      onChange={_onTabChange}
      scrollButtons="auto"
      variant="fullWidth"
      TabIndicatorProps={{sx: {display: 'none'}}}
      sx={{gap: 2}}>
      {props.tabs.map((tab, tabIndex) => {
        const _isLastTab = tabIndex === props.tabs.length - 1;
        return (
          <Tab
            key={tab.value}
            value={tab.value}
            sx={{
              borderWidth: 1,
              borderColor: 'secondary.dark',
              borderRadius: 2,
              borderStyle: 'solid',
              p: 2,
              alignItems: 'flex-start',
              textAlign: 'left',
              mr: _isLastTab ? 0 : 2,

              '&.Mui-selected': {
                color: 'text.primary',
                borderColor: 'text.primary',
                bgcolor: 'background.paper',
              },
            }}
            label={
              <Stack
                gap={1}
                flexGrow={1}
                width={'100%'}
                alignItems={'flex-start'}>
                <Box flexGrow={1}>
                  <Typography
                    fontSize={12}
                    textTransform="none"
                    lineHeight={1.2}
                    fontWeight={600}>
                    {tab.label}
                  </Typography>
                </Box>
                <Box width="100%">
                  <Indicator
                    value={tab.benchmarked_score}
                    valueDisplay="none"
                  />
                </Box>
              </Stack>
            }
          />
        );
      })}
    </Tabs>
  );
}

export default ScoredTabs;
