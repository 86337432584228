import {FIFormattedAutomation, FITag} from '@my-game-plan/types';
import React, {useEffect} from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTranslation} from 'react-i18next';
import EditTagButton from './edit-tag-button';
import ManageTrackersTable from './manage-trackers-table/manage-trackers-table';

interface ManageTrackersAccordionProps {
  tag: FITag;
  trackers: FIFormattedAutomation[];
}

function ManageTrackersAccordion(
  props: ManageTrackersAccordionProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_lowercaseLabel, _setLowercaseLabel] = React.useState<string>(
    props.tag.label,
  );

  /*
   * Side Effects
   */
  useEffect(() => {
    const _labelWithoutSpaces = props.tag.label.replace(/[^A-Z0-9]/gi, '_');
    _setLowercaseLabel(_labelWithoutSpaces.toLowerCase());
  }, [props.tag]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  return (
    <Accordion defaultExpanded={props.trackers.length > 0}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon color="secondary" />}
        aria-controls={`panel-${_lowercaseLabel}-content`}
        id={`panel-${_lowercaseLabel}-header`}>
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          justifyContent="space-between"
          flexGrow={1}
          pr={3}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="h6" lineHeight={1}>
              {props.tag.label}
            </Typography>
            {props.tag._id && <EditTagButton tag={props.tag} />}
          </Stack>
          <Typography variant="body2" color="text.disabled">
            {t('eventAutomations.trackersCount', {
              count: props.trackers.length,
            })}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {/* <Stack gap={4}>
          {props.trackers.map((tracker) => {
            return (
              <div key={tracker._id}>
                {tracker.title || `tracker ${tracker._id}`}
              </div>
            );
          })} 
        </Stack>*/}
        <ManageTrackersTable trackers={props.trackers} />
      </AccordionDetails>
    </Accordion>
  );
}

export default ManageTrackersAccordion;
