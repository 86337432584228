import {
  FIBenchmarkOptions,
  FICategoryPerformance,
  FIPerformanceOverview,
} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import PentagonIcon from '@mui/icons-material/Pentagon';
import BarChartIcon from '@mui/icons-material/BarChart';
import PerformanceRadarChart from './performance-radar-chart';
import PerformanceBarChart from './performance-bar-chart';
import {addGeneralToCategories} from 'helpers/event-automations.helper';

interface IPerformanceHeroChartsProps {
  performanceOverview?: FIPerformanceOverview;
  benchmarkOptions?: FIBenchmarkOptions;
  isPreview?: boolean;
}

type TChartType = 'radar' | 'bar';

const MIN_CATEGORIES = 2;
function PerformanceHeroCharts(
  props: IPerformanceHeroChartsProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const [_activeChart, _setActiveChart] = useState<TChartType>('radar');
  const [_categories, _setCategories] = useState<FICategoryPerformance[]>([]);
  const [_canSelectChartType, _setCanSelectChartType] = useState<boolean>(true);

  /*
   * Side effects
   */
  useEffect(() => {
    if (!props.performanceOverview) {
      _setCategories([]);
      _setCanSelectChartType(false);
      return;
    }
    const _formattedCategories = addGeneralToCategories(
      props.performanceOverview.categories,
    );

    if (_formattedCategories.length <= MIN_CATEGORIES) {
      _setActiveChart('bar');
    }
    _setCategories(_formattedCategories);
  }, [props.performanceOverview]);

  useEffect(() => {
    _setCanSelectChartType(
      !props.isPreview && _categories.length > MIN_CATEGORIES,
    );
  }, [props.isPreview, _categories]);

  /*
   * Handlers
   */
  function _onChartTypeChange(
    event: React.MouseEvent<HTMLElement>,
    newChartType: TChartType | null,
  ) {
    if (!newChartType) {
      return;
    }

    _setActiveChart(newChartType);
  }

  /*
   * Render
   */
  let _Chart = null;

  if (_activeChart === 'radar') {
    _Chart = <PerformanceRadarChart categories={_categories} />;
  } else if (_activeChart === 'bar') {
    _Chart = (
      <PerformanceBarChart
        categories={_categories}
        benchmarkOptions={props.benchmarkOptions}
      />
    );
  }
  return (
    <Box
      position="relative"
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
      display="flex">
      <Box
        width="100%"
        alignItems="center"
        justifyContent="center"
        display={'flex'}
        height={{
          sm: 250,
          md: 400,
          lg: props.isPreview ? 440 : 540,
          xl: props.isPreview ? 540 : 640,
        }}>
        {Boolean(props.performanceOverview) && _Chart}
      </Box>
      {/* Toggle buttons */}
      {_canSelectChartType && (
        <ToggleButtonGroup
          size="small"
          sx={{position: 'absolute', bottom: 0, right: 0}}
          value={_activeChart}
          exclusive
          onChange={_onChartTypeChange}>
          <ToggleButton value="radar">
            <PentagonIcon />
          </ToggleButton>
          <ToggleButton value="bar">
            <BarChartIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Box>
  );
}

export default PerformanceHeroCharts;
