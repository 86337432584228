import {LOADING_STATE} from 'types/screen.types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import CustomEmptyState from '../error-states/CustomEmptyState';
import Loader from '../common/Loader/Loader.view';

interface IScreenContentProps {
  loadingState?: LOADING_STATE;
  errorPrefix?: string;
  children: React.ReactNode;
}

function ScreenContent(props: IScreenContentProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Handlers
   */

  /*
   * Render
   */
  if (props.loadingState === LOADING_STATE.ERROR) {
    const _errorPrefix = props.errorPrefix || 'error-states.not-found';

    return (
      <CustomEmptyState
        imageType="error"
        header={t(`${_errorPrefix}.header`)}
        description={t(`${_errorPrefix}.description`)}
      />
    );
  }

  return (
    <>
      {props.children}
      <Loader
        fixed={false}
        translucent={props.loadingState === LOADING_STATE.LOADING}
        isVisible={
          props.loadingState === LOADING_STATE.LOADING ||
          props.loadingState === LOADING_STATE.INITING
        }
      />
    </>
  );
}

export default ScreenContent;
