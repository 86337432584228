import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {useTranslation} from 'react-i18next';
import {
  FIEventAutomationPostData,
  TTrackerExecutorType,
} from '@my-game-plan/types';
import {
  formatDataBeforeSaving,
  formatSingleExecutor,
  generateUniqueTrackerKey,
} from 'helpers/automation/automation-form.helper';
import {useTeams} from 'context/team.context';
import {useSnackbar} from 'notistack';
import {editTracker} from 'controllers/trackers.controller';
import ExecutorType from '../executor/executor-type';
import {EXECUTOR_TYPES} from 'config/trackers.config';

interface IPreEditExecutorDialogProps {
  open: boolean;
  onClose: () => void;
  onEditAllExecutors: () => void;
  onEditSingleExecutor: (
    newTrackerData: Partial<FIEventAutomationPostData>,
  ) => void;
  executorType: TTrackerExecutorType;
  data: Partial<FIEventAutomationPostData>;
}

type TTypeWithExecutors = Partial<Record<TTrackerExecutorType, string[]>>;

function PreEditExecutorDialog(props: IPreEditExecutorDialogProps) {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _snackbar = useSnackbar();
  const _teamsContext = useTeams();
  const [_isLoading, _setIsLoading] = useState<boolean>(false);
  const [_formattedConcreteExecutor, _setFormattedConcreteExecutor] =
    useState<string>('');
  const [_typesWithExecutors, _setTypesWithExecutors] =
    useState<TTypeWithExecutors>({});

  /*
   * Side Effects
   */

  useEffect(() => {
    /* Format single concrete executor */
    if (!props.data.concrete_executor) {
      _setFormattedConcreteExecutor('');
    } else {
      const _formattedExecutor = formatSingleExecutor(
        props.data.concrete_executor,
        _teamsContext.ownPlayers,
      );
      _setFormattedConcreteExecutor(_formattedExecutor);
    }

    /* Format all executors */
    const _generatedTypesWithExecutors: TTypeWithExecutors = {};
    EXECUTOR_TYPES.forEach((type) => {
      if (!props.data[type]?.length || type === 'scouting_profile_ids') {
        return;
      }

      const _value = props.data[type];
      const _formattedExecutors = _value?.map((executor) =>
        formatSingleExecutor(executor.executor, _teamsContext.ownPlayers),
      );
      _generatedTypesWithExecutors[type] = _formattedExecutors;
    });

    _setTypesWithExecutors(_generatedTypesWithExecutors);
  }, [props.executorType, props.data, _teamsContext.ownPlayers]);

  /*
   * Handlers
   */
  async function _onEditSingleExecutor() {
    if (!_teamsContext.ownTeam) {
      return;
    }

    if (props.executorType === 'scouting_profile_ids') {
      // scouting is n/a here, makes no sense so we can just skip
      return;
    }
    /* Save current tracker, remove concrete executor from values */
    const _intialConcreteExecutor = props.data.concrete_executor;

    const _executors = props.data[props.executorType];

    if (!_intialConcreteExecutor || !_executors) {
      props.onClose();
      return;
    }
    const _concreteExecutorId = generateUniqueTrackerKey(
      props.data,
      _intialConcreteExecutor,
    );
    const _indexOfExecutor = _executors.findIndex((executor) => {
      const _executorId = generateUniqueTrackerKey(
        props.data,
        executor.executor,
      );

      return _executorId === _concreteExecutorId;
    });

    let _trackerWithoutExecutor = {
      ...props.data,
      [props.executorType]: _executors.filter(
        (_, index) => index !== _indexOfExecutor,
      ),
    };
    _trackerWithoutExecutor = formatDataBeforeSaving(
      _trackerWithoutExecutor,
      _teamsContext.ownTeam?._id,
      _teamsContext.preferences?.tags,
    );

    try {
      _setIsLoading(true);
      await editTracker(_trackerWithoutExecutor);
      _setIsLoading(false);
    } catch (error) {
      _setIsLoading(false);
      _snackbar.enqueueSnackbar(t('error-states.default'), {
        variant: 'error',
      });
    }

    /* Create new tracker, with concrete executor only */
    const _newData = {...props.data};
    delete _newData._id;

    // Remove all performance properties
    _newData.team_performance = undefined;
    _newData.player_development = undefined;
    _newData.opponent_analysis = undefined;
    _newData.scouting_profile_ids = undefined;

    // Re-add initial executor
    _newData[props.executorType] = [{executor: _intialConcreteExecutor}];

    props.onEditSingleExecutor(_newData);
  }

  /*
   * Render
   */
  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
      <DialogContent>
        <Stack direction="row" gap={4}>
          {/* Edit all executors */}
          <Stack flex={1} alignItems="flex-start" gap={5}>
            <Stack gap={1}>
              <Typography variant="h6">
                {t('eventAutomations.edit.preEdit.all.title')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t('eventAutomations.edit.preEdit.all.message')}
              </Typography>
            </Stack>

            <Stack gap={2}>
              {Object.keys(_typesWithExecutors).map((type) => {
                const _type = type as TTrackerExecutorType;
                const _subjectsCount = t(
                  'eventAutomations.edit.preEdit.all.subjectsCount',
                  {count: _typesWithExecutors[_type]?.length},
                );

                return (
                  <Stack gap={0.5} key={type}>
                    <ExecutorType type={_type} />
                    <Typography>{_subjectsCount}</Typography>
                  </Stack>
                );
              })}
            </Stack>

            <Button variant="contained" onClick={props.onEditAllExecutors}>
              {t('eventAutomations.edit.preEdit.all.cta')}
            </Button>
          </Stack>

          <Divider orientation="vertical" flexItem />

          {/* Edit single executor */}
          <Stack flex={1} alignItems="flex-start" gap={5}>
            <Stack gap={1}>
              <Typography variant="h6">
                {t('eventAutomations.edit.preEdit.single.title')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t('eventAutomations.edit.preEdit.single.message')}
              </Typography>
            </Stack>

            <Stack gap={0.5}>
              <ExecutorType type={props.executorType} />
              <Typography>{_formattedConcreteExecutor}</Typography>
            </Stack>

            <LoadingButton
              variant="outlined"
              onClick={_onEditSingleExecutor}
              loading={_isLoading}>
              {t('eventAutomations.edit.preEdit.single.cta')}
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default PreEditExecutorDialog;
