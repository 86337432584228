import React from 'react';
import {useParams} from 'react-router-dom';

import AutomationDetail from 'components/trackers/deep-dive/automation-detail.view';
import {useTeams} from 'context/team.context';

// import {Tab, Tabs} from '@mui/material';

function OpponentAutomationDetail() {
  /* Hooks n State */
  const _params = useParams();
  const _teamsContext = useTeams();

  /* Render */
  return (
    <AutomationDetail
      id={_params.id}
      executorType="opponent_analysis"
      subjectId={_teamsContext.currentlyObservingOpponentId}
    />
  );
}

export default OpponentAutomationDetail;
