import {
  createContextHook,
  createCustomContext,
  createProvider,
} from 'helpers/general/context_generators.helper';
import {ILinkState} from 'types/navigation.types';
import {LOADING_STATE} from 'types/screen.types';
import {
  FIAutomationDataQueryParams,
  FIBenchmarkedPhysicalData,
  FIBenchmarkOptions,
  FIBenchmarkTimeOptions,
  FICompactMatchInfo,
  FIPerformanceOverview,
  FIPlayer,
  FIPlayerObjective,
  FIPlayerSummary,
  FIShadowPlayerPostData,
  FITeam,
  TTrackerExecutorType,
} from '@my-game-plan/types';
import {PropsWithChildren, useEffect, useState} from 'react';
import {useAnalytics} from './analytics.context';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useTeams} from './team.context';
import {useCompetitions} from './competitions.context';
import {useSnackbar} from 'notistack';
import {useScouting} from './scouting.context';
import {getPlayerObjectives} from 'controllers/player-objectives.controller';
import {NAV_LEVEL_1_ROUTE} from 'config/navigation.config';
import {getPlayerOverviewByScoutingProfile} from 'controllers/scouting.controller';
import {
  getPlayerOverview,
  getScoutedPlayerPerfornance,
} from 'controllers/players.controller';
import ANALYTICS_EVENT from 'config/analytics/event-names.config';
import {serializeParams} from 'helpers/api.helper';
import {getTeamOverview} from 'controllers/teams.controller';
import {formatSearchParamsToQueryParams} from 'helpers/automation/automation.helper';
import {IViewedPlayerPageProps} from 'config/analytics/events.config';
import {getOpponentTeamOverview} from 'controllers/opponent-analysis.controllers';

export interface IExecutorDeepDiveProps {
  playerId?: string;
  executorType: TTrackerExecutorType;
}

export interface IExecutorDeepDiveAPI {
  // Common
  performanceStats: FIPerformanceOverview | null;
  hasPhysicalData: boolean;
  physicalData: FIBenchmarkedPhysicalData | null;
  subjectId: string;
  matches: FICompactMatchInfo[];

  // Player page
  player: FIPlayer | null;
  playerSummary: FIPlayerSummary | null;
  objectives: FIPlayerObjective[];
  objectivesLoadingState: LOADING_STATE;
  fetchObjectives: () => Promise<void>;

  // Team page
  team: FITeam | null;

  // Scouting
  isSettingMirrorInfo: boolean;
  mirrorringLoadingState: LOADING_STATE;
  mirrorPlayer: (mirrorringPlayerId?: string) => Promise<void>;
  reloadPlayerWithScoutingProfile: (scoutingProfileId: string) => Promise<void>;

  // Benchmark x Filter
  benchmarkAndDateFilters: FIAutomationDataQueryParams | null;
  onDateFilterChange: (filters: FIBenchmarkTimeOptions) => Promise<void>;
  onBenchmarkFilterChange: (filters: FIBenchmarkOptions) => Promise<void>;

  // Page
  loadingState: LOADING_STATE;
  backLinkState: ILinkState | null;
  trackerBackLink: ILinkState | null;
  screenName: TScreenName;
  executorType: TTrackerExecutorType;
  isPlayerPage: boolean;
  analyticsData: IViewedPlayerPageProps | null;
}

const context = createCustomContext<IExecutorDeepDiveAPI>();
export const useExecutorDeepDive = createContextHook(context);

type TScreenName = 'team-screen' | 'player-screen' | 'opponent-screen';
export function ExecutorDeepDiveProvider(
  props: PropsWithChildren<IExecutorDeepDiveProps>,
) {
  /*
   * Hooks n State
   */
  const _navigate = useNavigate();
  const {t} = useTranslation();
  const _snackbar = useSnackbar();
  const _params = useParams();
  const _analyticsContext = useAnalytics();
  const _teamContext = useTeams();
  const _competitionsContext = useCompetitions();
  const _scoutingContext = useScouting();

  // Common
  const [_performanceStats, _setPerformanceStats] =
    useState<FIPerformanceOverview | null>(null);
  const [_hasPhysicalData, _setHasPhysicalData] = useState<boolean>(false);
  const [_physicalData, _setPhysicalData] =
    useState<FIBenchmarkedPhysicalData | null>(null);
  const [_subjectId, _setSubjectId] = useState<string>('');
  const [_matches, _setMatches] = useState<FICompactMatchInfo[]>([]);

  // Player page
  const [_player, _setPlayer] = useState<FIPlayer | null>(null);
  const [_playerSummary, _setPlayerSummary] = useState<FIPlayerSummary | null>(
    null,
  );
  const [_objectives, _setObjectives] = useState<FIPlayerObjective[]>([]);
  const [_objectivesLoadingState, _setObjectivesLoadingState] =
    useState<LOADING_STATE>(LOADING_STATE.INITING);

  // Team page
  const [_team, _setTeam] = useState<FITeam | null>(null);

  // Scouting
  const [_isSettingMirrorInfo, _setIsSettingMirrorInfo] =
    useState<boolean>(false);
  const [_mirrorringLoadingState, _setMirrorringLoadingState] =
    useState<LOADING_STATE>(LOADING_STATE.INITING);

  // Benchmark x Filter
  const [_searchParams, _setSearchParams] = useSearchParams();
  const [_benchmarkAndDateFilters, _setBenchmarkAndDateFilters] =
    useState<FIAutomationDataQueryParams | null>(null);

  // Page
  const [_loadingState, _setLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );
  const [_screenName, _setScreenName] = useState<TScreenName>('team-screen');
  const [_isPlayerPage, _setIsPlayerPage] = useState<boolean>(true);
  const [_backLinkState, _setBackLinkState] = useState<ILinkState | null>(null);
  const [_trackerBackLink, _setTrackerBackLink] = useState<ILinkState | null>(
    null,
  );
  const [_analyticsData, _setAnalyticsData] =
    useState<IViewedPlayerPageProps | null>(null);

  /*
   * Side effects
   */
  /* Define team/player pge, set _subjectId and _team */
  useEffect(() => {
    if (!_teamContext.ownTeam) {
      return;
    }

    // Define whether we're on player or team page
    _setIsPlayerPage(Boolean(props.playerId));

    let _newScreenName: TScreenName = 'team-screen';
    if (props.playerId) {
      _newScreenName = 'player-screen';
    }

    // Define team
    let _teamToSet: FITeam | undefined = _teamContext.ownTeam;
    if (props.executorType === 'opponent_analysis') {
      _newScreenName = 'opponent-screen';
      _teamToSet = _teamContext.all.find(
        (t) => t._id === _teamContext.currentlyObservingOpponentId,
      );
    } else if (props.executorType === 'scouting_profile_ids') {
      _teamToSet = undefined;
    }
    _setTeam(_teamToSet || null);
    _setScreenName(_newScreenName);

    const _newSubjectId = props.playerId || _teamToSet?._id || '';

    _setSubjectId(_newSubjectId);

    const _newAnalyticsData: IViewedPlayerPageProps = {
      team_id: _teamToSet?._id || '',
      player_id: props.playerId || '',
      executor_type: props.executorType,
    };

    _setAnalyticsData(_newAnalyticsData);
    _analyticsContext.trackEvent(
      ANALYTICS_EVENT.VIEWED_PLAYER_PAGE,
      _newAnalyticsData,
    );
  }, [
    props.playerId,
    props.executorType,
    _teamContext.ownTeam,
    _teamContext.currentlyObservingOpponentId,
  ]);

  /* Fetch data when _subjectId changes */
  useEffect(() => {
    if (!_subjectId) {
      return;
    }

    let _parsedQuery: FIAutomationDataQueryParams | undefined = undefined;
    if (_searchParams.toString()) {
      _parsedQuery = formatSearchParamsToQueryParams(_searchParams);
    }

    _fetchData(_subjectId, _parsedQuery, false);
  }, [_subjectId, _isPlayerPage, _searchParams]);

  /* TEAM - Define whether physical data is available */
  useEffect(() => {
    if (_isPlayerPage || !_team) {
      return;
    }

    if (!_teamContext.ownTeam || !_competitionsContext.domesticCompetition) {
      _setHasPhysicalData(false);
      return;
    }

    /* Physical data */
    const _physicalDataCheck =
      !!_competitionsContext.domesticCompetition.physical_provider; //TODO - Check if physical data is available for the right team, not only own team
    _setHasPhysicalData(_physicalDataCheck);
  }, [
    _team,
    _teamContext.ownTeam,
    _competitionsContext.domesticCompetition,
    _isPlayerPage,
  ]);

  /* PLAYER - Define whether physical data is available */
  useEffect(() => {
    if (!_isPlayerPage) {
      return;
    }

    if (
      !_teamContext.ownTeam ||
      !_competitionsContext.domesticCompetition ||
      !_playerSummary ||
      !_player
    ) {
      _setHasPhysicalData(false);
      return;
    }

    if (!_player.teams?.length) {
      return;
    }
    const _playerCompetition = _competitionsContext.getCompetitionForTeam(
      _player.teams[0]._id,
    );

    const _physicalProvider = _playerSummary.is_own_player
      ? _competitionsContext.domesticCompetition.physical_provider
      : _playerCompetition?.physical_provider;

    const _physicalDataCheck = !!_physicalProvider;
    _setHasPhysicalData(_physicalDataCheck);

    /* Fetch objectives */
    _fetchObjectives();
  }, [
    _isPlayerPage,
    _player,
    _playerSummary,
    _teamContext.ownTeam,
    _competitionsContext.domesticCompetition,
  ]);

  /* Set back link state (when navigating back from executor overview) */
  useEffect(() => {
    if (!_isPlayerPage) {
      _setBackLinkState(null);
      return;
    }

    // Define URL query parameters
    const _urlQueryParams = _searchParams
      ? formatSearchParamsToQueryParams(_searchParams)
      : undefined;

    if (_urlQueryParams?.benchmark?.position?.length) {
      delete _urlQueryParams.benchmark.position;
    }

    const _newURLQuery = serializeParams(_urlQueryParams);

    let _newBacklinkState: ILinkState = {
      label: '',
      route: NAV_LEVEL_1_ROUTE.HOME,
    };

    if (props.executorType === 'scouting_profile_ids') {
      _newBacklinkState = {
        label: t('scouting.title'),
        route: NAV_LEVEL_1_ROUTE.SCOUTING,
      };

      const _scoutingProfile = _scoutingContext.profiles.find(
        (profile) => profile._id === _params.scouting_profile_id,
      );
      if (_scoutingProfile) {
        _newBacklinkState.route += `/profiles/${_scoutingProfile._id}`;
        _newBacklinkState.label = _scoutingProfile.description;
      }
    } else if (props.executorType === 'player_development') {
      _newBacklinkState = {
        label: t('playerPerformance.title'),
        route: NAV_LEVEL_1_ROUTE.PLAYERS,
      };
    }

    _newBacklinkState.route += `?${_newURLQuery}`;
    _setBackLinkState(_newBacklinkState);
  }, [
    _params,
    _searchParams,
    props.executorType,
    _scoutingContext.profiles,
    _isPlayerPage,
  ]);

  /* Define tracker backlink (back button on tracker deep dive) */
  useEffect(() => {
    let _newTrackerBackLink: ILinkState | null = null;

    if (props.executorType === 'team_performance') {
      _newTrackerBackLink = {
        label: t('navigation.team'),
        route: NAV_LEVEL_1_ROUTE.TEAM,
      };
    } else if (props.executorType === 'opponent_analysis') {
      _newTrackerBackLink = {
        label: t('navigation.opponent-automations'),
        route: NAV_LEVEL_1_ROUTE.OPPONENT_AUTOMATIONS,
      };
    } else if (props.executorType === 'player_development' && _player) {
      _newTrackerBackLink = {
        label: _player.display_name,
        route: `${NAV_LEVEL_1_ROUTE.PLAYERS}/${_player._id}`,
      };
    } else if (props.executorType === 'scouting_profile_ids' && _player) {
      let _routePrefix = `${NAV_LEVEL_1_ROUTE.SCOUTING}`;
      if (_params.scouting_profile_id) {
        _routePrefix += `/profiles/${_params.scouting_profile_id}`;
      }
      _newTrackerBackLink = {
        label: _player.display_name,
        route: `${_routePrefix}/players/${_player._id}`,
      };
    }

    _setTrackerBackLink(_newTrackerBackLink);
  }, [_params, _player, props.executorType]);

  /*
   * Handlers
   */
  async function _getPlayerRequest(
    playerId: string,
    filters?: FIAutomationDataQueryParams,
  ) {
    if (_params.scouting_profile_id) {
      return getPlayerOverviewByScoutingProfile(
        _params.scouting_profile_id,
        playerId,
        filters,
      );
    }

    return getPlayerOverview(playerId, filters);
  }

  async function _getTeamRequest(filters?: FIAutomationDataQueryParams) {
    if (props.executorType === 'opponent_analysis') {
      return getOpponentTeamOverview(
        _teamContext.currentlyObservingOpponentId,
        filters,
      );
    }

    return getTeamOverview(filters);
  }

  async function _fetchData(
    subjectId: string,
    filters?: FIAutomationDataQueryParams,
    shouldSetURLQuery = false,
  ) {
    if (!_teamContext.ownTeam) {
      return;
    }

    if (_loadingState !== LOADING_STATE.INITING) {
      _setLoadingState(LOADING_STATE.LOADING);
    }

    if (_isPlayerPage) {
      try {
        /* Fetch player info */

        const _fetchedPlayerWithInfo = await _getPlayerRequest(
          subjectId,
          filters,
        );

        if (!_fetchedPlayerWithInfo) {
          _setLoadingState(LOADING_STATE.ERROR);
          return;
        }

        if (!_fetchedPlayerWithInfo.player_info) {
          _setLoadingState(LOADING_STATE.ERROR);
          return;
        }
        /* Show mirror builder if player is not own player and has no automations */
        if (
          (!_teamContext.ownTeam ||
            !_fetchedPlayerWithInfo.player_summary?.is_own_player) &&
          _fetchedPlayerWithInfo.performance_overview.automations_count === 0
        ) {
          _setIsSettingMirrorInfo(true);
        }

        _setMatches(_fetchedPlayerWithInfo.matches_in_scope || []);

        _setPlayer(_fetchedPlayerWithInfo.player_info);

        _setPlayerSummary(_fetchedPlayerWithInfo.player_summary || null);
        _setPerformanceStats(
          _fetchedPlayerWithInfo.performance_overview || null,
        );
        _setPhysicalData(_fetchedPlayerWithInfo.physical || null);
        _setLoadingState(LOADING_STATE.SUCCESS);
        _setBenchmarkAndDateFilters(_fetchedPlayerWithInfo.filters || null);

        if (shouldSetURLQuery) {
          _setSearchParams(serializeParams(_fetchedPlayerWithInfo.filters));
        }
      } catch (error) {
        _setLoadingState(LOADING_STATE.ERROR);
      }
    } else {
      const _teamInfo = await _getTeamRequest(filters);

      _setMatches(_teamInfo.matches_in_scope || []);
      _setPerformanceStats(_teamInfo.performance_overview || null);
      _setPhysicalData(_teamInfo.physical || null);
      _setLoadingState(LOADING_STATE.SUCCESS);
      _setBenchmarkAndDateFilters(_teamInfo.filters || null);
      if (shouldSetURLQuery) {
        _setSearchParams(serializeParams(_teamInfo.filters));
      }
    }
  }

  /* Benchmark x Filter */
  async function _onDateFilterChange(filters: FIBenchmarkTimeOptions) {
    if (!_benchmarkAndDateFilters) {
      return;
    }
    const _filters: FIAutomationDataQueryParams = {
      ..._benchmarkAndDateFilters,
      own_data: {
        time: filters,
      },
    };

    const _serializedParams = serializeParams(_filters);
    _setSearchParams(new URLSearchParams(_serializedParams));
  }

  async function _onBenchmarkFilterChange(filters: FIBenchmarkOptions) {
    if (!_benchmarkAndDateFilters) {
      return;
    }
    const _filters: FIAutomationDataQueryParams = {
      ..._benchmarkAndDateFilters,
      benchmark: filters,
    };

    const _serializedParams = serializeParams(_filters);
    _setSearchParams(new URLSearchParams(_serializedParams));
  }

  /* Player */
  async function _fetchObjectives() {
    if (!_teamContext.ownTeam || !_player || !_playerSummary) {
      return;
    }

    if (!_playerSummary.is_own_player) {
      _setObjectivesLoadingState(LOADING_STATE.SUCCESS);
      return;
    }

    try {
      _setObjectivesLoadingState(LOADING_STATE.LOADING);
      const _fetchedObjectives = await getPlayerObjectives(_player._id);

      _setObjectives(_fetchedObjectives);
      _setObjectivesLoadingState(LOADING_STATE.SUCCESS);
    } catch (error) {
      _setObjectivesLoadingState(LOADING_STATE.ERROR);
    }
  }

  /* Scouting */
  async function _reloadPlayerWithScoutingProfile(scoutingProfileId: string) {
    if (!props.playerId) {
      return;
    }
    try {
      _setMirrorringLoadingState(LOADING_STATE.LOADING);
      const _newPlayerData = await getPlayerOverviewByScoutingProfile(
        scoutingProfileId,
        props.playerId,
      );
      _setPerformanceStats(_newPlayerData?.performance_overview || null);
      _setIsSettingMirrorInfo(false);
      _setPlayerSummary(_newPlayerData?.player_summary || null);
      _setMirrorringLoadingState(LOADING_STATE.SUCCESS);
      _navigate(
        `${NAV_LEVEL_1_ROUTE.SCOUTING}/profiles/${scoutingProfileId}/players/${props.playerId}`,
      );
    } catch (error) {
      _setMirrorringLoadingState(LOADING_STATE.ERROR);
    }
  }

  async function _mirrorPlayer(mirroringPlayerId?: string) {
    if (!_teamContext.ownTeam || !_player) {
      return;
    }

    if (!mirroringPlayerId) {
      _setMirrorringLoadingState(LOADING_STATE.INITING);
      _setIsSettingMirrorInfo(true);
      return;
    }

    try {
      _setMirrorringLoadingState(LOADING_STATE.LOADING);
      _setIsSettingMirrorInfo(true);
      const _postData: FIShadowPlayerPostData = {
        team_id: _teamContext.ownTeam._id,
        observing_player_id: _player._id,
        mirroring_player_id: mirroringPlayerId,
        benchmark_options: {
          position: _player.position,
        },
      };

      _analyticsContext.trackEvent(
        ANALYTICS_EVENT.PLAYER_PAGE_MIRRORED_PERFORMANCE,
        {
          team_id: _teamContext.ownTeam._id,
          player_id: _player._id,
          mirroring_player_id: mirroringPlayerId,
        },
      );

      const _fetchedPerformanceStats = await getScoutedPlayerPerfornance(
        _postData,
      );

      if (_fetchedPerformanceStats.automations_count === 0) {
        _setMirrorringLoadingState(LOADING_STATE.SUCCESS);
        _setIsSettingMirrorInfo(true);
      } else {
        /* Fetch player performance stats */
        _setPerformanceStats(_fetchedPerformanceStats);
        _setIsSettingMirrorInfo(false);

        _setMirrorringLoadingState(LOADING_STATE.SUCCESS);
      }
    } catch (error) {
      _setMirrorringLoadingState(LOADING_STATE.INITING);
      // _setMirrorringLoadingState(LOADING_STATE.ERROR);
      _snackbar.enqueueSnackbar(
        t('players.performance.mirrorring.builder.error.header'),
        {variant: 'error'},
      );
    }
  }

  /*
   * Return context
   */
  const _value: IExecutorDeepDiveAPI = {
    // Common
    performanceStats: _performanceStats,
    hasPhysicalData: _hasPhysicalData,
    physicalData: _physicalData,
    subjectId: _subjectId,
    matches: _matches,

    // Player page
    player: _player,
    playerSummary: _playerSummary,
    objectives: _objectives,
    objectivesLoadingState: _objectivesLoadingState,
    fetchObjectives: _fetchObjectives,

    // Team page
    team: _team,

    // Scouting
    isSettingMirrorInfo: _isSettingMirrorInfo,
    mirrorringLoadingState: _mirrorringLoadingState,
    mirrorPlayer: _mirrorPlayer,
    reloadPlayerWithScoutingProfile: _reloadPlayerWithScoutingProfile,

    // Benchmark x Filter
    benchmarkAndDateFilters: _benchmarkAndDateFilters,
    onDateFilterChange: _onDateFilterChange,
    onBenchmarkFilterChange: _onBenchmarkFilterChange,

    // Page
    loadingState: _loadingState,
    executorType: props.executorType,
    backLinkState: _backLinkState,
    trackerBackLink: _trackerBackLink,
    screenName: _screenName,
    isPlayerPage: _isPlayerPage,
    analyticsData: _analyticsData,
  };
  return createProvider(context, props, _value);
}
