import React from 'react';

import Box from '@mui/material/Box';
import MGP_LOGO_ICON from 'assets/images/logo/logomark.svg';
import {keyframes} from '@emotion/react';

const SIZE = 48;
function AppLoader() {
  const _pulseAnimation = keyframes`
    0% {
      transform: scale(1);
    }
      50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  `;
  return (
    <Box
      position="absolute"
      top="45%"
      left="50%"
      sx={{
        transform: 'translate(-50%, -50%)',
        animation: `${_pulseAnimation} 1s linear infinite`,
      }}>
      <img src={MGP_LOGO_ICON} style={{height: `${SIZE}px`}} alt="MyGamePlan" />
    </Box>
  );
}

export default AppLoader;
