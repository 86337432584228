import AutomationDetail from 'components/trackers/deep-dive/automation-detail.view';
import React from 'react';
import {useParams} from 'react-router-dom';

function PlayerTrackerView() {
  /*
   * Hooks n State
   */
  const _params = useParams();

  /*
   * Render
   */

  return (
    <AutomationDetail
      id={_params.id}
      subjectId={_params.player_id}
      executorType="player_development"
    />
  );
}

export default PlayerTrackerView;
