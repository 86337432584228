import React, {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Stack from '@mui/material/Stack';

import {useTranslation} from 'react-i18next';
import {
  FICompactMatchInfo,
  FIFormattedAutomation,
  TTrackerExecutorType,
} from '@my-game-plan/types';
import {Link, useLocation} from 'react-router-dom';
import {useVideo} from 'context/video/video.context';
import {useSnackbar} from 'notistack';
import {fetchEventsForTracker} from 'controllers/trackers.controller';
import {useTheme} from '@mui/material';
import {generateStatisticDeepDiveURL} from 'helpers/automation/automation.helper';
import {useTeams} from 'context/team.context';
import {ILinkState} from 'types/navigation.types';

interface ITrackersTableActionsCellProps {
  tracker: FIFormattedAutomation;
  executorType: TTrackerExecutorType;
  backLink?: ILinkState;
  matches: FICompactMatchInfo[];
}

function TrackersTableActionsCell(
  props: ITrackersTableActionsCellProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const {breakpoints} = useTheme();
  const _snackbar = useSnackbar();
  const _location = useLocation();

  const _teamsContext = useTeams();
  const _videoPlayerContext = useVideo();

  /* Videos */
  const [_isLoadingVideos, _setIsLoadingVideos] = useState<boolean>(false);
  const [_trackerURL, _setTrackerURL] = useState<string>('');

  /*
   * Side effects
   */
  // Define URL for tracker deep dive
  useEffect(() => {
    const _URL = generateStatisticDeepDiveURL(
      props.tracker,
      undefined,
      _location.search,
    );
    _setTrackerURL(_URL);
  }, [
    props.tracker,
    props.executorType,
    _location.search,
    _teamsContext.setCurrentlyObservingOpponentId,
  ]);

  /*
   * Handlers
   */
  async function _onWatchVideos() {
    try {
      _setIsLoadingVideos(true);

      const _subjectId =
        props.tracker.concrete_executor?.player_id ||
        props.tracker.concrete_executor?.team?._id ||
        '';
      const _events = await fetchEventsForTracker(
        props.tracker.statistic_id,
        _subjectId,
        {
          against: props.tracker.against,
          own_data: {time: props.tracker.own_filters},
          benchmark: props.tracker.benchmark_filters,
        },
      );
      const _matchIdsInEvents = _events.map((event) => event.match._id);

      const _matchesToDisplay = props.matches.filter(
        (match) => _matchIdsInEvents.includes(match._id) && match.video,
      );

      _videoPlayerContext.openVideoPlayer(
        _events,
        _matchesToDisplay,
        props.tracker.calculation === 'ratio' ||
          props.tracker.calculation === 'rule',
        props.executorType === 'team_performance' ||
          props.executorType === 'player_development',
        {
          action: props.tracker.action,
          details: props.tracker.details || props.tracker.success_details,
        },
        undefined,
        props.tracker.action === 'sequence',
      );
    } catch (error) {
      _snackbar.enqueueSnackbar(t('video-player.error-body'), {
        variant: 'error',
      });
    } finally {
      _setIsLoadingVideos(false);
    }
  }

  /*
   * Render
   */
  const _linkLabel = t('eventAutomations.viewCta');
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={3}
      justifyContent="flex-end">
      {/* Videos */}
      <Box
        position="relative"
        sx={{
          [breakpoints.down('md')]: {
            display: 'none',
          },
        }}>
        <IconButton color="secondary" onClick={_onWatchVideos}>
          <PlayArrowIcon />
        </IconButton>
        <Fade in={_isLoadingVideos}>
          <Box
            bgcolor="background.default"
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{top: 0, bottom: 0, left: 0, right: 0}}>
            <CircularProgress color="secondary" size={16} />
          </Box>
        </Fade>
      </Box>

      {/* LINK - Desktop */}
      <Button
        variant="text"
        size="small"
        color="secondary"
        component={Link}
        to={_trackerURL}
        state={props.backLink}
        sx={{
          [breakpoints.down('md')]: {
            display: 'none',
          },
        }}>
        {_linkLabel}
      </Button>

      {/* LINK - Small screens */}
      <IconButton
        size="small"
        sx={{
          [breakpoints.up('md')]: {
            display: 'none',
          },
        }}
        color="primary"
        component={Link}
        to={_trackerURL}
        state={props.backLink}
        title={_linkLabel}>
        <ArrowForwardIcon />
      </IconButton>
    </Stack>
  );
}

export default TrackersTableActionsCell;
