import ProfileSetupScreen from 'components/profile-setup-screen/profile-setup-screen';
import React from 'react';

import {
  ProfileSetupProvider,
  useProfileSetup,
} from 'context/profile-setup.context';

function PerformanceProfileSetupWithoutContext(): JSX.Element | null {
  /*
   * Hooks n State
   */
  const _profileSetupContext = useProfileSetup();

  /*
   * Side effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */
  const _currentStep =
    _profileSetupContext.steps[_profileSetupContext.currentStepIndex];
  if (!_currentStep) {
    return null;
  }

  return (
    <ProfileSetupScreen
      leftHeader={_currentStep.leftHeader}
      leftCentered={_currentStep.leftCentered}
      LeftContent={_currentStep.LeftContent}
      LeftFooter={_currentStep.LeftFooter}
      RightContent={_currentStep.RightContent}
      totalSteps={_profileSetupContext.totalSteps}
      currentStepIndex={_profileSetupContext.currentStepIndex}
      prevButton={_currentStep.prevButton}
      nextButton={_currentStep.nextButton}
    />
  );
}

function PerformanceProfileSetup(): JSX.Element {
  return (
    <ProfileSetupProvider>
      <PerformanceProfileSetupWithoutContext />
    </ProfileSetupProvider>
  );
}

export default PerformanceProfileSetup;
