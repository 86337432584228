import {ITrackerSentenceInputProps} from 'types/trackers/tracker-sentence.types';
import {
  ACTION,
  FIEventAutomationPostData,
  FIEventCondition,
  isTrackingAction,
} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';
import TrackerSentencePopup from 'components/trackers/sentence/segment/variants/popup/sentence-popup-segment';
import {useTranslation} from 'react-i18next';
import {
  getVerbKey,
  startsWithVowel,
  translateAction,
} from 'helpers/translation.helper';
import {useZones} from 'context/zones.context';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import {ITypedOption} from 'types/option.types';
import {useCompetitions} from 'context/competitions.context';
import {actionConfigsToFormOptions} from 'helpers/automation/automation-form.helper';

import {generateZoneOptions} from 'helpers/pitch-zones.helper';
import OptionsList from '../../variants/popup/options/options-list';
import SentenceTextSegment from '../../variants/text/sentence-text-segment';
import {useAutomationForm} from 'context/event-automations/automations-form.context';

type TActionVerbType = 'default' | 'third';
interface IActionSegmentProps
  extends ITrackerSentenceInputProps<
    Partial<FIEventAutomationPostData> | Partial<FIEventCondition>
  > {
  includeTrackingActions?: boolean;
  displayArticle?: boolean;
  verb?: TActionVerbType;
}
type TActionSegmentTab = 'event' | 'zone';
const ACTION_SEGMENT_TABS: TActionSegmentTab[] = ['event', 'zone'];
function ActionSegment(props: IActionSegmentProps): JSX.Element {
  /*
   * Hooks n state
   */
  const {t} = useTranslation();
  const _pitchZonesContext = useZones();
  const _competitionsContext = useCompetitions();
  const _trackerBuilderContext = useAutomationForm();

  const [_anchorEl, _setAnchorEl] = useState<null | HTMLElement>(null);
  const [_activeTab, _setActiveTab] =
    React.useState<TActionSegmentTab>('event');
  const [_placeholder, _setPlaceholder] = useState<string>('');
  const [_verbAndArticle, _setVerbAndArticle] = useState<string>('');
  const [_displaySingularAction, _setDisplaySingularAction] = useState(false);
  const [_actionsOptions, _setActionsOptions] = useState<
    ITypedOption<ACTION>[]
  >([]);
  const [_displayTrackingActions, _setDisplayTrackingActions] =
    useState<boolean>(false);
  const [_pitchZoneOptions, _setPitchZoneOptions] = useState<
    ITypedOption<string>[]
  >([]);

  /*
   * Side Effects
   */
  useEffect(() => {
    let _newPlaceholder = '';
    const _count = _displaySingularAction ? 1 : 2;

    if (_activeTab === 'event') {
      _newPlaceholder += t('sentenceForm.action.placeholder', {
        count: _count,
      });
    } else if (_activeTab === 'zone') {
      _newPlaceholder += t('sentenceForm.pitchZone.placeholder', {
        count: _count,
      });
    }

    _setPlaceholder(_newPlaceholder);
  }, [_activeTab, _displaySingularAction]);

  useEffect(() => {
    if (props.verb || props.displayArticle) {
      _setDisplaySingularAction(true);
    } else {
      _setDisplaySingularAction(false);
    }
  }, [props.verb, props.displayArticle]);

  // Define actions options
  useEffect(() => {
    _setActionsOptions(
      actionConfigsToFormOptions(
        _competitionsContext.domesticCompetition?.event_provider,
        _competitionsContext.domesticCompetition?.available_metrics,
        true,
        _displaySingularAction ? 1 : 2,
      ),
    );
  }, [
    _competitionsContext.domesticCompetition?.event_provider,
    _competitionsContext.domesticCompetition?.available_metrics,
    _displaySingularAction,
  ]);

  // Define pitch zone options
  useEffect(() => {
    const _shouldDisplayTrackingActions = Boolean(
      props.includeTrackingActions &&
        _competitionsContext.isTrackingDataSupported,
    );

    _setDisplayTrackingActions(_shouldDisplayTrackingActions);

    let _newZonesOptions: ITypedOption<string>[] = [];
    if (_shouldDisplayTrackingActions) {
      _newZonesOptions = generateZoneOptions(
        _pitchZonesContext.all,
        props.value?.action,
        props.value?.against,
        _trackerBuilderContext.previewingTeamLogo,
      );
    }

    _setPitchZoneOptions(_newZonesOptions);
  }, [
    props.includeTrackingActions,
    props.value,
    _competitionsContext.isTrackingDataSupported,
    _pitchZonesContext.all,
    _trackerBuilderContext.previewingTeamLogo,
  ]);

  // Set "zone" as active tab if action is a tracking action
  useEffect(() => {
    if (props.value?.action && isTrackingAction(props.value.action)) {
      _setActiveTab('zone');
    }
  }, [props.value?.action, props.value?.pitch_zone]);

  // Define verb and article
  useEffect(() => {
    let _verbAndArticle = '';
    let _article = t('sentenceForm.an');

    if (props.value?.action && isTrackingAction(props.value.action)) {
      _article = '';
    } else if (props.value?.action && !startsWithVowel(props.value?.action)) {
      _article = t('sentenceForm.a');
    }

    if (props.verb) {
      const _translatedVerb = t(
        getVerbKey(props.value?.action, props.verb === 'third'),
      );

      _verbAndArticle = _translatedVerb;

      if (_article) {
        _verbAndArticle += ` ${_article}`;
      }
    }

    if (!props.verb && props.displayArticle) {
      _verbAndArticle = _article;
    }

    _setVerbAndArticle(_verbAndArticle);
  }, [props.value?.action, props.verb, _placeholder]);

  /*
   * Handlers
   */
  function _handleActionSelect(
    value: ACTION | undefined,
    hasSelectedAction?: boolean,
  ) {
    if (!props.onChange) return;

    const _updatedData: Partial<FIEventAutomationPostData> = {
      action: value,
      metric: undefined,
    };

    if (hasSelectedAction) {
      _updatedData.details = undefined;
      _updatedData.success_details = undefined;
      _updatedData.pitch_zone = undefined;
    }

    props.onChange(_updatedData);

    if (hasSelectedAction) {
      _onPopupClose();
    }
  }

  function _formatValue(data: Partial<FIEventAutomationPostData>): string {
    const _count = _displaySingularAction ? 1 : 2;
    if (!data.action) return '';
    const _isTrackingAction = isTrackingAction(data.action);
    if (_isTrackingAction) {
      let _prefix = '';

      if (!_verbAndArticle) {
        _prefix = t('sentenceForm.tracking.entriesIn');
      }
      let _zonesValue = t('sentenceForm.tracking.zone', {count: 1});
      const _zone = _pitchZonesContext.all.find(
        (z) => z._id === data.pitch_zone,
      );
      if (_zone) {
        _zonesValue = _zone.name;
      }

      let _valueToReturn = _zonesValue;
      if (_prefix) {
        _valueToReturn = `${_prefix} ${_zonesValue}`;
      }

      return _valueToReturn;
    }

    // Return translated regular action
    const _translatedAction = translateAction(data.action, _count);

    return _translatedAction;
  }

  function _onTabChange(
    event: React.MouseEvent<HTMLElement>,
    newTab: TActionSegmentTab,
  ) {
    if (!newTab) return;
    _setActiveTab(newTab);

    _handleActionSelect(undefined);
  }

  function _onActionSelect(action: ACTION) {
    _handleActionSelect(action, true);
  }
  function _onZoneSelect(zoneId: string) {
    if (!props.onChange) return;

    const _updatedData: Partial<FIEventAutomationPostData> = {
      action: 'zone_visit',
      pitch_zone: zoneId,
    };

    props.onChange(_updatedData);
    _onPopupClose();
  }

  function _onPopupOpen(anchorEl: HTMLElement) {
    _setAnchorEl(anchorEl);
  }
  function _onPopupClose() {
    _setAnchorEl(null);
  }

  /*
   * Render
   */
  function _renderZoneGroupHeader(group: string) {
    return t(`zones.groups.${group}`);
  }
  function _renderActionGroupHeader(group: string) {
    return translateAction(group);
  }

  const _ActionOptionsList = (
    <OptionsList
      options={_actionsOptions}
      onChange={_onActionSelect}
      groupHeader={_renderActionGroupHeader}
      key="actions-list"
    />
  );
  const _ZoneOptionsList = (
    <OptionsList
      options={_pitchZoneOptions}
      onChange={_onZoneSelect}
      groupHeader={_renderZoneGroupHeader}
      key="zones-list"
    />
  );

  let _PopupContent = _ActionOptionsList;
  if (_displayTrackingActions) {
    const _OptionsList =
      _activeTab === 'event' ? _ActionOptionsList : _ZoneOptionsList;
    _PopupContent = (
      <Stack>
        <Box p={2} pb={0}>
          <ToggleButtonGroup
            value={_activeTab}
            fullWidth
            exclusive
            onChange={_onTabChange}>
            {ACTION_SEGMENT_TABS.map((tab) => {
              return (
                <ToggleButton key={tab} value={tab} size="small">
                  {tab}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Box>
        <Stack>{_OptionsList}</Stack>
      </Stack>
    );
  }

  const _value = props.value?.action ? props.value : undefined;

  return (
    <>
      {_verbAndArticle && (
        <SentenceTextSegment
          {...props}
          value={_verbAndArticle}
          type="default"
        />
      )}
      <TrackerSentencePopup.Button
        {...props}
        onOpen={_onPopupOpen}
        isPopupOpen={Boolean(_anchorEl)}
        placeholder={_placeholder}
        required
        valueFormatter={_formatValue}
        value={_value}
      />

      <TrackerSentencePopup.Popup
        {...props}
        disablePadding
        placeholder={_placeholder}
        valueFormatter={_formatValue}
        value={_value}
        anchorEl={_anchorEl}
        onClose={_onPopupClose}>
        {_PopupContent}
      </TrackerSentencePopup.Popup>
    </>
  );
}

export default ActionSegment;
