import {F, TShotAnalysisPlayer} from '@my-game-plan/types';
import React from 'react';

import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import {useTranslation} from 'react-i18next';
import {roundAndFormatNumber} from 'helpers/general/general.helper';

interface IInsightDetailsPlayersProps {
  players: F<TShotAnalysisPlayer>[];
}

function InsightDetailsPlayers(
  props: IInsightDetailsPlayersProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Handlers
   */

  /*
   * Render
   */
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('shotAnalysis.details.players.player')}</TableCell>
          <TableCell align="center">
            {t('shotAnalysis.result.shots', {count: 0})}
          </TableCell>
          <TableCell align="center">
            {t('shotAnalysis.result.goals', {count: 0})}
          </TableCell>
          <TableCell align="center">{t('shotAnalysis.goalRate')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.players.map((player) => {
          return (
            <TableRow key={player._id}>
              <TableCell sx={{display: 'flex', alignItems: 'center', gap: 0.5}}>
                <Avatar
                  src={player.image_url}
                  title={player.display_name}
                  sx={{height: 24, width: 24, bgcolor: 'background.default'}}
                />
                {player.display_name}
              </TableCell>
              <TableCell align="center">{player.shots}</TableCell>
              <TableCell align="center">{player.goals}</TableCell>
              <TableCell align="center">
                {roundAndFormatNumber(player.goal_rate, 2)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default InsightDetailsPlayers;
