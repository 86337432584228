import {TEAMS_API_CONFIG} from 'config/api.config';
import {REQUEST_ERRORS} from 'config/errors.config';
import {useAPI} from 'hooks/api.hooks';
import {Resource} from 'types/api.types';
import {
  CountTagUsageBody,
  EditTagBody,
  F,
  FITeamPreferences,
} from '@my-game-plan/types';

const api = useAPI();

// General team preferences
export async function getTeamPreferences(): Promise<FITeamPreferences> {
  try {
    const _URL = 'teams/preferences';
    const {data} = await api.get<FITeamPreferences>({
      url: _URL,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function updateTeamPreferences(
  body: FITeamPreferences,
): Promise<FITeamPreferences> {
  try {
    const _URL = 'teams/preferences';
    const {data} = await api.put<FITeamPreferences>({
      url: _URL,
      data: body,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

// Tags
export async function getTagCount(teamID: string) {
  try {
    const {data} = await api.get<F<CountTagUsageBody>>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.getTagCount(teamID),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (e) {
    console.log(e);
  }
}

export async function deleteTag(tagToDelete: string) {
  try {
    const URL = `teams/preferences/tag/${tagToDelete}`;
    const {data} = await api.del({
      url: URL,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (e) {
    console.log(e);
  }
}

export async function editTag(editTagBody: EditTagBody) {
  try {
    const URL = 'teams/preferences/tag';
    await api.put({
      url: URL,
      data: editTagBody,
    });
  } catch (e) {
    console.log(e);
  }
}
