import React, {useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {ICommonSentenceInputProps} from 'types/sentence-form.types';

import Segment from '..';
import {capitalize} from 'lodash';
import {
  generateTransferredPlayerOption,
  getPlayerOptions,
} from 'helpers/automation/automation-form.helper';
import {useTeams} from 'context/team.context';
import {ITypedOption} from 'types/option.types';
import Autocomplete from 'components/material-customised/autocomplete/autocomplete.view';
import {
  FIEventAutomationPostData,
  FIEventAutomationSubject,
} from '@my-game-plan/types';
import {TRANSFERED_PLAYER} from 'config/event-automation-builder.config';

function PlayersDropdown<DataType = FIEventAutomationPostData>(
  props: ICommonSentenceInputProps<FIEventAutomationSubject, DataType>,
): JSX.Element {
  /* Hooks n State */
  const [_players, _setPlayers] = useState<ITypedOption<string>[]>([]);

  const [_valueOption, _setValueOption] = useState<ITypedOption<string> | null>(
    null,
  );

  const [_hasTransferredPlayer, _setHasTransferredPlayer] =
    useState<boolean>(false);

  const _teamsContext = useTeams();
  const {t} = useTranslation();

  let _translatedPlaceholder =
    props.placeholder || `${t('sentenceForm.observing.entireTeam')}`;
  if (props.capitalize) {
    _translatedPlaceholder = capitalize(_translatedPlaceholder);
  }

  useEffect(() => {
    // Find obersving player based on value prop
    let _selectedPlayer = _players.find(
      (player) =>
        props.value?.players?.includes(player.value) &&
        player.name !== TRANSFERED_PLAYER,
    );

    //Search additional player info
    const _matchingPlayerFromAdditionalInfo = props.additionalPlayerInfo?.find(
      (p) => props.value?.players?.includes(p._id),
    );
    if (_matchingPlayerFromAdditionalInfo) {
      _selectedPlayer = {
        value: _matchingPlayerFromAdditionalInfo._id,
        label: _matchingPlayerFromAdditionalInfo.name,
      };
      _setHasTransferredPlayer(false);
    } else if (
      _players?.length &&
      props.value?.players?.length &&
      !_selectedPlayer
    ) {
      // If observing player is not found, player has probably been transferred
      const _previouslyAddedTransferredPlayer = _players.find(
        (player) => player.value === props.value?.players?.[0],
      );

      const _emptyPlayer = generateTransferredPlayerOption(
        props.value.players[0],
      );

      // Add "transferred player" option if necessary so user can uncheck
      if (!_previouslyAddedTransferredPlayer) {
        const _newPlayers = [..._players];
        _newPlayers.unshift(_emptyPlayer);
        _setPlayers(_newPlayers);
      }

      _selectedPlayer = _emptyPlayer;

      _setHasTransferredPlayer(true);
    } else {
      _setHasTransferredPlayer(false);
    }

    _setValueOption(_selectedPlayer || null);
  }, [_players, props.value, props.additionalPlayerInfo]);

  useEffect(() => {
    const _sortedPlayers = getPlayerOptions(_teamsContext.ownPlayers || []);
    _setPlayers(_sortedPlayers);
  }, [_teamsContext.ownPlayers]);

  /* Handlers */
  function _onChange(
    value: ITypedOption<string> | ITypedOption<string>[] | null,
  ) {
    if (Array.isArray(value)) {
      // Handle multiselect
    } else {
      if (props.onChange) {
        props.onChange({
          [props.property]: value ? {players: [value.value]} : undefined,
        } as Partial<DataType>);
      }
    }
  }

  /* Render */
  if (props.required) {
    _translatedPlaceholder += '*';
  }

  if (props.readonly) {
    const _readonlyText = _valueOption?.label || _translatedPlaceholder;

    return (
      <Segment.Text
        size={props.size}
        data={props.data}
        text={_readonlyText}
        type="value"
        color={_hasTransferredPlayer ? 'warning' : undefined}
      />
    );
  }

  function _groupHeaderKey(text: string): string {
    return t(`playerPosition.${text}s`);
  }

  return (
    <Autocomplete<string>
      size={props.size}
      error={props.error}
      isInlineInput
      blurOnSelect
      disabled={props.disabled}
      property={props.property as string}
      options={_players}
      value={_valueOption}
      onChange={_onChange}
      placeholder={_translatedPlaceholder}
      groupHeader={_groupHeaderKey}
      required={props.required}
    />
  );
}

export default PlayersDropdown;
