import {getColorForScore} from 'helpers/benchmark.helper';
import {darken, useTheme} from '@mui/material';

import {FIBenchmarkOptions, FICategoryPerformance} from '@my-game-plan/types';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  LabelProps,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import PerformanceChartTooltip from './performance-chart-tooltip';
import {useTranslation} from 'react-i18next';

import ArrowDropdownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

interface IPerformanceBarChartProps {
  categories: FICategoryPerformance[];
  benchmarkOptions?: FIBenchmarkOptions;
}

function PerformanceBarChart(props: IPerformanceBarChartProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _theme = useTheme();

  /*
   * Side effects
   */

  /*
   * Handlers
   */
  function truncatedLabel(label: string): string {
    const MAX_CHARS_MANY_CATEGORIES = 10;
    const MAX_CHARS = 20;
    if (
      props.categories.length > 7 &&
      label.length > MAX_CHARS_MANY_CATEGORIES
    ) {
      return label.substring(0, MAX_CHARS_MANY_CATEGORIES) + '...';
    } else if (label.length > MAX_CHARS) {
      return label.substring(0, MAX_CHARS) + '...';
    }

    return label;
  }

  /*
   * Render
   */

  function _renderYAxisTick(value: number): string {
    const _isBenchmarkingASingleCompetition =
      props.benchmarkOptions?.observing?.competition_id?.length === 1;

    const _translationPrefix = _isBenchmarkingASingleCompetition
      ? 'league'
      : 'benchmark';

    let _translationKey = `eventAutomations.benchmark.${_translationPrefix}Average`;
    if (value === 0) {
      _translationKey = `eventAutomations.benchmark.${_translationPrefix}Bottom`;
    } else if (value == 10) {
      _translationKey = `eventAutomations.benchmark.${_translationPrefix}Top`;
    }
    return t(_translationKey);
  }

  function _renderFormArrow(labelProps: LabelProps) {
    if (!labelProps.value) {
      return null;
    }

    const _arrowSize = 24;
    if (
      labelProps.x === undefined ||
      labelProps.y === undefined ||
      labelProps.value === undefined
    ) {
      return null;
    }

    const _y =
      typeof labelProps.y === 'string' ? parseInt(labelProps.y) : labelProps.y;
    const _x =
      typeof labelProps.x === 'string' ? parseInt(labelProps.x) : labelProps.x;
    const _width =
      (typeof labelProps.width === 'string'
        ? parseInt(labelProps.width)
        : labelProps.width) || 0;

    const _yPos = _y - _arrowSize;
    const _xPos = _x + _width / 2 - _arrowSize / 2;

    let _Icon = ArrowDropUpIcon;
    let _color: 'success' | 'error' = 'success';
    if (labelProps.value === 'down') {
      _Icon = ArrowDropdownIcon;
      _color = 'error';
    }

    return (
      <foreignObject x={_xPos} y={_yPos} width={_arrowSize} height={_arrowSize}>
        <_Icon color={_color} sx={{fontSize: _arrowSize}} />
      </foreignObject>
    );
  }

  return (
    <ResponsiveContainer width={'100%'} height={'100%'}>
      <BarChart data={props.categories} margin={{bottom: 96, top: 96}}>
        <XAxis
          // padding={{bottom: 1, left:1, right: 1, top: 1, }
          dataKey="name"
          fontSize={props.categories.length > 7 ? 10 : 12}
          interval={0}
          axisLine={false}
          tickLine={false}
          tick={{fill: _theme.palette.text.secondary}}
          tickFormatter={(value: string) => {
            return truncatedLabel(value);
          }}
          dy={8}
        />
        <YAxis
          domain={[0, 10]}
          strokeWidth={0}
          fontSize={10}
          fontStyle="italic"
          tickCount={3}
          tick={{
            fill: _theme.palette.text.disabled,
          }}
          tickFormatter={_renderYAxisTick}
          width={160}
        />
        <CartesianGrid
          vertical={false}
          stroke={_theme.palette.divider}
          strokeDasharray={4}
        />

        <Tooltip
          cursor={{fill: _theme.palette.divider, fillOpacity: 0.1}}
          content={(tooltipProps: TooltipProps<ValueType, NameType>) => {
            if (!tooltipProps.active || !tooltipProps.payload?.length) {
              return null;
            }

            const _categoryData = tooltipProps.payload[0]
              .payload as FICategoryPerformance;

            return <PerformanceChartTooltip data={_categoryData} />;
          }}
        />

        {/* Chart */}
        <defs>
          {props.categories.map((category, index) => {
            const _color = getColorForScore(category.benchmarked_score);
            return (
              <linearGradient
                key={index}
                id={`gradient-${index}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1">
                <stop offset="0%" stopColor={_color} stopOpacity={1} />
                <stop offset="100%" stopColor={_color} stopOpacity={0.3} />
              </linearGradient>
            );
          })}
        </defs>
        <Bar
          maxBarSize={64}
          dataKey="benchmarked_score"
          radius={[8, 8, 0, 0]}
          background={{
            fill: darken(_theme.palette.background.default, 0.1),
            radius: 8,
          }}>
          <LabelList
            dataKey="form.trend"
            position="top"
            content={_renderFormArrow}
          />
          {props.categories.map((category, catIndex) => {
            return (
              <Cell
                key={category._id}
                fill={`url(#gradient-${catIndex})`}></Cell>
            );
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default PerformanceBarChart;
