export const PREFERENCES_TRANSLATIONS = {
  title: 'Team preferences',

  pitch_zones: 'Pitch zones',
  tags: {
    create: {
      helperText: 'Press Enter to create a new category',
    },
    title: 'Automation categories',
    performance_trackers: 'performance trackers',
    opponent_automations: 'opponent automations',
    delete: {
      title: 'Delete category',
      text: 'Deleting this category will also remove it from automations.',
    },
    deleteSuccess: 'Category deleted successfully',
    edit: 'Rename category',
    tag: 'Category',
    editSuccess: 'Category edited successfully',
    cancel: 'Cancel',
    save: 'Save',
    noTags: {
      title: 'You do not have any categories yet.',
      text: 'Create or edit a tracker/opponent automation to add categories',
    },
  },
  switchTeam: {
    title: 'Change team',
    text: 'Search for a team to link to your account',
    search: 'Start typing a team name',
    submit: 'Change team',
    empty: 'No teams found',
  },

  dataDisplay: {
    title: 'Data display',
    intro:
      'Customize how you want to see data in the web app and mobile player app. Changes will be applied to the entire team, including all staff and players.',

    units: {
      title: 'Units and calculation',
      speed: {
        title: 'Speed',
        text: 'Choose the unit of speed (eg. max speed) you want to see in the app. Defaults to km/h.',
      },
      distance: {
        title: 'Distance',
        text: "Choose the unit of distance (eg. average sprint distance) you want to see in the app. Defaults to meters/90'.",
      },
      count: {
        title: 'Absolute units',
        text: "Choose the unit of absolute values (eg. average amount of sprints/90') you want to see in the app. Defaults to data per 90 minutes.",
      },
    },
  },

  general: {
    save: 'Save preferences',
    success: 'Your preferences have been saved',
    error: 'Something went wrong. Please try again later',
  },

  manageTrackers: {
    title: 'Manage trackers',
    noTrackersInCategory: 'There are no trackers in this category',
  },
};
