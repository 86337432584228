import React, {useState} from 'react';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {useTranslation} from 'react-i18next';
import DeleteTrackerDialog from 'components/trackers/builder/dialog/delete-tracker-dialog';
import {useAutomationForm} from 'context/event-automations/automations-form.context';
import {FIEventAutomationPostData} from '@my-game-plan/types';

function DeleteTrackerButton() {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _trackerBuilderContext = useAutomationForm();
  const [_isDialogOpen, _setIsDialogOpen] = useState(false);

  /*
   * Side effects
   */

  /*
   * Handlers
   */
  async function _onDeleteTracker() {
    if (!_trackerBuilderContext.data._id) {
      return;
    }

    await _trackerBuilderContext.deleteAutomation(
      _trackerBuilderContext.data._id,
    );
    //
  }

  function _onDeleteSubjects(data: Partial<FIEventAutomationPostData>) {
    _trackerBuilderContext.setData(data);

    _onDialogClose();
  }

  function _onDialogClose() {
    _setIsDialogOpen(false);
  }

  function _onDialogOpen() {
    _setIsDialogOpen(true);
  }

  return (
    <>
      <IconButton
        color="secondary"
        onClick={_onDialogOpen}
        title={t('eventAutomations.delete.title')}>
        <DeleteIcon />
      </IconButton>
      <DeleteTrackerDialog
        data={_trackerBuilderContext.data}
        open={_isDialogOpen}
        onClose={_onDialogClose}
        onDeleteTracker={_onDeleteTracker}
        onDeleteSubjects={_onDeleteSubjects}
      />
    </>
  );
}

export default DeleteTrackerButton;
