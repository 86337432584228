import React from 'react';

import {translateValue} from 'helpers/translation.helper';
import {IPitchVisualEventGroupLegend} from 'types/dashboard/dashboard-pitch-visual.types';
import styles from './legend.module.scss';

import Stack from '@mui/material/Stack';
import LayersIcon from '@mui/icons-material/Layers';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {TEventCalculationType} from '@my-game-plan/types';
import {useTranslation} from 'react-i18next';

interface LegendProps {
  linkedTable: string;
  groups: IPitchVisualEventGroupLegend[];
  visibleGroupIndexes: number[];
  onVisibleGroupIndexesChanged: (indexes: number[]) => void;
  onHighlightSuccessfulHandler?: () => void;
  isHighlightingSuccessfulEvents?: boolean;
  calculation?: TEventCalculationType;
}

function Legend({
  // linkedTable,
  groups,
  visibleGroupIndexes,
  onVisibleGroupIndexesChanged,
  onHighlightSuccessfulHandler,
  calculation,
  isHighlightingSuccessfulEvents,
}: LegendProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Handlers
   */
  const crossedOut = (index: number) => {
    return !visibleGroupIndexes.includes(index);
  };

  const handleClick = (index: number) => {
    let _newVisibleGroupIndexes = [...visibleGroupIndexes];
    const _indexInCurrentVisibleGroups = _newVisibleGroupIndexes.indexOf(index);
    if (visibleGroupIndexes.length === groups.length) {
      _newVisibleGroupIndexes = [index];
    } else {
      if (_indexInCurrentVisibleGroups > -1) {
        _newVisibleGroupIndexes.splice(_indexInCurrentVisibleGroups, 1);
      } else {
        _newVisibleGroupIndexes.splice(index, 0, index);
      }
    }
    // _dashboard.updateGroupFilters(linkedTable, label);
    onVisibleGroupIndexesChanged(_newVisibleGroupIndexes);
  };

  /*
   * Render
   */
  const _isRatioOrRule = calculation === 'ratio' || calculation === 'rule';
  const _successFullToggleTooltip = t(
    `eventAutomations.pitchVisual.successfulToggle.${calculation}`,
  );
  return (
    <Stack
      direction="row"
      mb={_isRatioOrRule ? 0 : 1}
      mt={_isRatioOrRule ? 0 : 2}>
      <Stack direction="row" gap={1} flex={1}>
        {groups.map(({label, color}, index) => {
          const _label = translateValue(label);
          return (
            <div
              className={`${styles.item} ${
                crossedOut(index) ? styles.crossed : ''
              }`}
              onClick={() => handleClick(index)}
              key={label}>
              <div
                className={styles.itemDot}
                style={{backgroundColor: color}}></div>
              <div className={styles.itemLabel}>{_label}</div>
            </div>
          );
        })}
      </Stack>
      {_isRatioOrRule && (
        <Tooltip title={_successFullToggleTooltip}>
          <IconButton
            onClick={onHighlightSuccessfulHandler}
            color={isHighlightingSuccessfulEvents ? 'primary' : 'secondary'}>
            <LayersIcon />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
}

export default Legend;
