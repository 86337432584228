import React, {useEffect, useState} from 'react';
import HeaderFilterButton, {
  IButtonValueSegment,
  IHeaderFilterButtonProps,
} from './header-filter-button.view';
import {ITypedOption} from 'types/option.types';

import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useTranslation} from 'react-i18next';
import {getKeyForTypedOption} from 'helpers/general/general.helper';
import {lighten, useTheme} from '@mui/material';

interface IPartialHeaderFilterButtonProps
  extends Omit<
    IHeaderFilterButtonProps,
    'isOpen' | 'onPopupOpen' | 'PopupContent' | 'buttonValues'
  > {
  buttonValues?: IButtonValueSegment[][];
}
interface IHeaderFilterMenuProps<
  T = any,
  Multiple extends boolean | undefined = false,
> {
  multiple?: Multiple;
  options: ITypedOption<T>[];
  value: Multiple extends true ? T[] : T | null;
  onChange?: (value: Multiple extends true ? T[] : T) => void;
  buttonProps: IPartialHeaderFilterButtonProps;
  buttonLabelCountTranslationKey?: string;
  canSelectAll?: boolean;
}

function HeaderFilterMenu<
  T = any,
  Multiple extends boolean | undefined = false,
>(props: IHeaderFilterMenuProps<T, Multiple>): JSX.Element | null {
  /*
   * Hooks n State
   */
  const _theme = useTheme();
  const {t} = useTranslation();
  const [_anchorEl, _setAnchorEl] = useState<HTMLElement | null>(null);
  const [_selectedSingleOption, _setSelectedSingleOption] =
    useState<ITypedOption<T> | null>(null);
  const [_selectedMultipleOptions, _setSelectedMultipleOptions] = useState<
    ITypedOption<T>[]
  >([]);
  const [_labelValues, _setLabelValues] = useState<IButtonValueSegment[][]>([]);

  /*
   * Side effects
   */

  // Set selected option or options
  useEffect(() => {
    if (props.multiple) {
      _setSelectedMultipleOptions(
        props.options.filter((option) =>
          (props.value as T[]).includes(option.value),
        ),
      );
    } else {
      _setSelectedSingleOption(
        props.options.find((option) => option.value === (props.value as T)) ||
          null,
      );
    }
  }, [props.value, props.options]);

  // Set label values
  useEffect(() => {
    if (props.buttonProps.buttonValues) {
      _setLabelValues(props.buttonProps.buttonValues);
      return;
    }

    if (_selectedSingleOption) {
      // Single select
      _setLabelValues([[{type: 'value', value: _selectedSingleOption.label}]]);
    } else if (
      _selectedMultipleOptions.length &&
      props.buttonLabelCountTranslationKey
    ) {
      // Multi select, show count
      _setLabelValues([
        [
          {
            type: 'value',
            value: t(props.buttonLabelCountTranslationKey, {
              count: _selectedMultipleOptions.length,
            }),
          },
        ],
      ]);
    } else if (_selectedMultipleOptions.length) {
      // Multi select, show all options
      _setLabelValues([
        [
          {
            type: 'value',
            value: _selectedMultipleOptions
              .map((option) => option.label)
              .join(', '),
          },
        ],
      ]);
    }
  }, [
    props.buttonProps.buttonValues,
    _selectedSingleOption,
    _selectedMultipleOptions,
    props.buttonLabelCountTranslationKey,
  ]);

  /*
   * Handlers
   */
  function _onPopupOpen(event: React.MouseEvent<HTMLElement>) {
    _setAnchorEl(event.currentTarget);
  }

  function _onPopupClose() {
    _setAnchorEl(null);
  }

  function _onSingleOptionSelect(option: ITypedOption<T>) {
    if (!props.multiple && props.onChange) {
      props.onChange(option.value as Multiple extends true ? T[] : T);
      _onPopupClose();
    }
  }

  function _onMultipleOptionSelect(value: T) {
    if (!props.multiple || !props.onChange) {
      return;
    }

    const _indexOfSelectedItem = (props.value as T[]).indexOf(value);
    const _newValue: T[] = [...(props.value as T[])];

    if (_indexOfSelectedItem === -1) {
      _newValue.push(value);
    } else {
      _newValue.splice(_indexOfSelectedItem, 1);
    }

    props.onChange(_newValue as Multiple extends true ? T[] : T);
  }

  function _onSelectAll() {
    if (!props.multiple || !props.onChange) {
      return;
    }

    let _newSelectedOptions: T[] = [];
    if (_selectedMultipleOptions.length !== props.options.length) {
      _newSelectedOptions = props.options.map((option) => option.value);
    }

    props.onChange(_newSelectedOptions as Multiple extends true ? T[] : T);
  }

  /*
   * Render
   */

  const _isOpen = Boolean(_anchorEl);
  let _PopupContent = null;
  const _menuSlotProps = {
    paper: {
      sx: {
        maxHeight: 400,
        bgcolor: lighten(_theme.palette.background.paper, 0.03),
      },
    },
  };
  if (props.multiple) {
    // Multi select
    const _arAllSelected =
      _selectedMultipleOptions.length === props.options.length;
    _PopupContent = (
      <Menu
        open={_isOpen}
        anchorEl={_anchorEl}
        onClose={_onPopupClose}
        slotProps={_menuSlotProps}>
        {/* "Select all" */}
        {props.canSelectAll && (
          <ListItem disablePadding onClick={_onSelectAll}>
            <ListItemButton role={undefined} onClick={_onSelectAll} dense>
              <ListItemIcon>
                <Checkbox edge="start" checked={_arAllSelected} tabIndex={-1} />
              </ListItemIcon>
              <ListItemText>{t('general.selectAll')}</ListItemText>
            </ListItemButton>
          </ListItem>
        )}

        {/* Options */}
        {props.options.map((option) => {
          const _isChecked = (props.value as T[]).some(
            (valueOption) => valueOption === option.value,
          );
          const _optionKey = getKeyForTypedOption(option);
          return (
            <ListItem key={_optionKey} disablePadding>
              <ListItemButton
                role={undefined}
                onClick={() => _onMultipleOptionSelect(option.value)}
                dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={_isChecked}
                    tabIndex={-1}
                    inputProps={{'aria-labelledby': _optionKey}}
                  />
                </ListItemIcon>
                <ListItemText id={_optionKey}>{option.label}</ListItemText>
              </ListItemButton>
            </ListItem>
          );
        })}
      </Menu>
    );
  } else {
    // Single select
    _PopupContent = (
      <Menu
        open={_isOpen}
        anchorEl={_anchorEl}
        onClose={_onPopupClose}
        slotProps={_menuSlotProps}>
        {props.options.map((option) => (
          <MenuItem
            key={getKeyForTypedOption(option)}
            selected={option.value === _selectedSingleOption?.value}
            onClick={() => _onSingleOptionSelect(option)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    );
  }

  if (!_PopupContent) return null;
  return (
    <>
      <HeaderFilterButton
        {...props.buttonProps}
        disabled={!props.onChange}
        isOpen={_isOpen}
        onPopupOpen={_onPopupOpen}
        PopupContent={_PopupContent}
        buttonValues={_labelValues}
      />
    </>
  );
}

export default HeaderFilterMenu;
