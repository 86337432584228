import ScreenContent from 'components/screen/screen-content.view';
import ManageTrackersAccordion from 'components/trackers/manage/manage-trackers-accordion';
import {
  MangeTrackersProvider,
  useManageTrackers,
} from 'context/manage-trackers.context';
import React, {useEffect, useRef} from 'react';

import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';

import AddIcon from '@mui/icons-material/Add';

import TrackerCreateButton from 'components/trackers/misc/tracker-create-button/tracker-create-button.view';
import {useTranslation} from 'react-i18next';
import {ILinkState} from 'types/navigation.types';
import {NAV_LEVEL_1_ROUTE} from 'config/navigation.config';
import {useAnalytics} from 'context/analytics.context';
import ANALYTICS_EVENT from 'config/analytics/event-names.config';

function ManageTrackersVieWithoutContext(): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _manageTrackersContext = useManageTrackers();
  const _analyticsContext = useAnalytics();
  const _backLink = useRef<ILinkState>({
    route: NAV_LEVEL_1_ROUTE.PREFERENCES,
    label: t('preferences.manageTrackers.title'),
  });

  /*
   * Handlers
   */
  useEffect(() => {
    _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_PREFERENCES_TRACKERS);
  }, []);

  /*
   * Render
   */
  return (
    <ScreenContent loadingState={_manageTrackersContext.loadingState}>
      <Stack>
        {_manageTrackersContext.groupedTrackers.map((group) => {
          return (
            <ManageTrackersAccordion
              trackers={group.trackers}
              tag={group.tag}
              key={group.tag._id}
            />
          );
        })}
      </Stack>
      <TrackerCreateButton
        type={'team_performance'}
        backLink={_backLink.current}
        Button={
          <Fab
            color="primary"
            aria-label="add"
            sx={{position: 'fixed', bottom: '1rem', right: '1rem'}}
            title={t('eventAutomations.trackersNew')}>
            <AddIcon />
          </Fab>
        }
      />
    </ScreenContent>
  );
}

function ManageTrackersView(): JSX.Element {
  return (
    <MangeTrackersProvider>
      <ManageTrackersVieWithoutContext />
    </MangeTrackersProvider>
  );
}

export default ManageTrackersView;
