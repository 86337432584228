const EVENT_AUTOMATIONS_TRANSLATIONS = {
  createdAt: 'Created {{date}}',
  updatedAt: 'last updated {{date}}',
  sharedWith: 'Shared with',
  comment: 'Comment',
  action: 'Action',
  details: 'Details',
  successDetails: 'Success details',
  by: 'By',
  in: 'in',
  general: 'General',
  description: 'Description',
  title: 'Title',
  matchLimit: {
    matches: 'Matches',
    last: 'Last {{count}}',
  },

  allPlayers: 'All players',
  entireTeam: 'Entire team',

  titleInput: {
    placeholder: 'Untitled tracker',
  },

  notify: {
    label: 'Notify players',
    daysBefore: '{{count}} day before match',
    daysBefore_one: '{{count}} day before match',
    daysBefore_other: '{{count}} days before match',
  },

  automations_one: 'Automation',
  automations_other: 'Automations',
  automationsNew: 'New automation',
  automationsEdit: 'Edit automation',

  trackersShort_one: 'Tracker',
  trackersShort_other: 'Trackers',
  trackers_one: 'Performance tracker',
  trackers_other: 'Performance trackers',
  trackersNew: 'New tracker',
  trackersEdit: 'Edit tracker',

  trackersCount_one: '{{count}} tracker',
  trackersCount_other: '{{count}} trackers',

  opponentAutomationsShort_one: 'Automation',
  opponentAutomationsShort_other: 'Automations',
  opponentAutomations_one: 'Opponent automation',
  opponentAutomations_other: 'Opponent automations',
  opponentAutomationsNew: 'New opponent automation',
  opponentAutomationsEdit: 'Edit opponent automation',
  opponentAutomationsIntro:
    "Schedule a weekly playlist for next opponent's actions",

  viewCta: 'View tracker',
  chooseSubjectToView:
    'Select the player(s) you would like to see this player for',

  calculationType: {
    occurrences: 'Count occurrences',
    ratio: 'Calculate ratio',
    rule: 'Define a rule',
    sequence: 'Count sequences',

    description: {
      occurrences: 'Count # of [events] per match',
      ratio: 'Count % of [X events] that are [Y]',
      rule: 'When [X event] happens, [Y event] must happen',
      sequence: 'Count sequences containing [events]',
    },
  },

  // TODO: delete target copy
  target: {
    withTarget: 'With  target',
    noTarget: 'Without target',
  },
  properties: {
    average: 'Average',
    tags: 'Tags',
    lastMatch: 'Last match',
    score: 'Score',
    videos: 'Videos',
    minutesPlayed: 'Min. played',
    matchesPlayed: 'Matches played',
    totalEvents: 'Total events',
    successfulEvents: 'Objective/total',
    objective: 'Objective',
    totalSum: 'Total {{metric}}',
    averagePerEvent: 'Average / event',
    percentile: 'Percentile',
    form: 'Form',
  },

  values: {
    notAvailable: 'n/a',
    perMatch: '/match',
    per90: "/90'",
    noTags: 'no tags',
  },

  benchmark: {
    benchmark: 'Benchmark',

    update: 'Update benchmark',
    error: {
      default: {
        title: 'Something went wrong loading benchmark data',
        description: 'Please try again later',
      },
      'no-data': {
        title: 'No data to benchmark.',
        description: 'Try changing benchmark filters or tracker details.',
      },
    },

    competitions_one: 'competition',
    competitions_other: 'competitions',
    competitionsCount_one: '{{count}} competition',
    competitionsCount_other: '{{count}} competitions',
    notAvailable: 'No benchmark available',

    belowBenchmark: 'below benchmark',
    aboveBenchmark: 'above benchmark',

    belowBenchmarkProp: 'below {{benchmark}}',
    aboveBenchmarkProp: 'above {{benchmark}}',

    benchmarkAgainst: 'Benchmarking',
    benchmarkTrackersCount_one: 'Benchmarking {{count}} tracker',
    benchmarkTrackersCount_other: 'Benchmarking {{count}} trackers',

    restore: 'Restore defaults',

    ranking: {
      title: 'Ranking',

      player: 'Player',
      team: 'Team',
      notInRanking: 'not in ranking',
      successfulEvents: 'Obj./Total',
      totalEvents: 'Events',
      sequences: 'Sequences',
      averageRate: 'Average',
      averageShort: 'Avg.',
      averagePerMatch: 'Avg./match',
      averagePer90: "Avg./90'",
      matchesPlayed: 'Matches',
      minutesPlayed: 'Min. played',
      minutesPlayedShort: 'Min.',
      fullRankingCta: 'Full ranking',
    },

    disabled: {
      disabled: 'Not benchmarking',
      player_details:
        'Benchmarking is disabled for automations with player-specific details. Try removing these to allow benchmarking.',
      tracking_data: 'Benchmarking is disabled for positioning automations',
      passes_no_details:
        'Benchmarking is disabled for automations combining passes with under 2 details and conditions',
    },
    baseline: {
      benchmarkAgainst: 'Benchmarking',
      compareTo: 'Compare to',
      otherTeams: 'other teams',
      otherPlayers: 'other players',
    },

    playingIn: 'playing in',

    average: 'average',
    average_90: "average per 90'",

    belowAverage: 'below average',
    aboveAverage: 'above average',

    in: 'in',
    of: 'of',
    all: 'all',
    ownLeague: 'own league',
    teams: 'teams',
    players: {
      placeholder: 'all players',
    },

    league: 'league',
    yourTeam: 'Your team',

    whoPlayedAtLeast: 'who played at least',
    minutes: 'minutes',
    atLeast: 'at least',
    includePlayers: 'Include players who played at least',

    // time_range: {
    //   in: 'in',
    //   inCurrentSeason: 'current season',
    //   last3Months: 'last 3 months',
    // },

    positionAverage: '{{position}} average',
    positionAverageShort: '{{position}} avg.',

    leagueAverageShort: 'League avg.',
    leagueAverage: 'League average',
    leagueTop: 'Top of league',
    leagueBottom: 'Bottom of league',

    benchmarkTop: 'Top of benchmark',
    benchmarkAverage: 'Benchmark average',
    benchmarkBottom: 'Benchmark bottom',
  },

  error: {
    no_group: {
      title: 'No groups to show',
      description:
        'Try creating an automation observing specific players/positions.',
    },
  },

  noTags: 'No category',

  nextOpponent: 'Next opponent',
  noUpcomingOpponent: 'No upcoming opponent',

  filter: {
    filter: 'Filter',
    filterData: 'Filter data',

    automationsFilter: 'Filter automations',
    trackersFilter: 'Filter trackers',

    actions: 'Actions',
    tags: 'Tags',
    players_other: 'players',
    players_one: 'player',
    players: 'Observing players',

    allActions: 'All actions',
    allTags: 'All tags',
    allPlayers: 'All players (+ team)',
    entireTeam: 'Entire team',

    data: 'data',
    showing: 'Showing',
    showingData: 'Showing data',
    matches_other: 'matches',
    matches_one: 'match',
    showingCountMatches_one: 'Showing {{count}} match',

    view: 'View',
    viewType: {
      table: 'Table',
      cards: 'Cards',
    },

    time: {
      currentSeason: 'current season',
      seasonsCount_one: '{{count}} season',
      seasonsCount_other: '{{count}} seasons',
      seasons_one: 'season',
      seasons_other: 'seasons',

      lastGames: 'last {{count}} matches',
      lastCountGames_other: 'last {{count}} matches',
      lastCountGames_one: 'last match',
      last: 'last',
      games_other: 'matches',
      games_one: 'match',

      since: 'from',
      until: 'until',
      sinceDate: 'since {{date}}',

      period: 'period',
    },

    observing: {
      observing: 'Observing',
      competition_id: 'Competition',
      team_id: 'Teams',
      leagueOrTeams: 'league or teams',
      teams_one: '{{count}} team',
      teams_other: '{{count}} teams',
      leagues_one: '{{count}} league',
      leagues_other: '{{count}} leagues',

      noTeamsSelected: 'No teams selected',
    },
  },

  empty: {
    trackers: {
      header: "Couldn't find any trackers.",
      description:
        'You can create a performance tracker by clicking the button below.',
    },
    opponentAutomations: {
      header: "Couldn't find any opponent automations.",
      description:
        'You can create an opponent automations by clicking the button below.',
    },
    app: {
      header: 'No data available',
      description: 'Ask your staff to share content.',
    },
  },

  create: {
    save: 'Create tracker',
    title: 'Create tracker',
    description: 'Tracker will be created for these subjects:',
    titleCustom: 'Create custom tracker',
    cancelWarning: {
      title: 'Discard changes?',
      text: 'Would you like to cancel and discard changes? Unsaved changes might be lost.',
      cta: 'Discard changes',
    },
    success: 'Tracker has been created',
    error: {
      request: {
        title: "Couldn't save tracker",
        text: 'Seems like something went wrong here. Please try again later',
      },
    },
  },
  edit: {
    save: 'Save tracker',
    edit: 'Edit tracker',
    description: 'Tracker will be updated for these subjects:',
    success: {
      title: 'Tracker has been updated!',
    },

    preEdit: {
      all: {
        title: 'Edit for all subjects',
        message:
          'Changes you make will affect all subjects this tracker is assigned to.',
        cta: 'Edit tracker',
        subjectsCount_one: '{{count}} subject',
        subjectsCount_other: '{{count}} subjects',
      },
      single: {
        title: 'Edit current subject only',
        message:
          'This will remove the current subject from this tracker and create a new one, not affecting any other subjects.',
        cta: 'Create new tracker',
      },
    },
  },
  delete: {
    success: 'Tracker has been deleted',
    error: {
      title: 'Something went wrong trying to delete tracker',
      text: 'Please try again later',
    },
    title: 'Delete tracker',
    message:
      "Deleting this tracker wil permanently remove it and can't be undone",

    deleteSubjects: {
      message:
        'This tracker is assigned to multiple subjects, select the ones you would like to delete it for.',
      deleteSubjects_one: 'Delete subject',
      deleteSubjects_other: 'Delete {{count}} subjects',

      all: {
        title: 'Delete entire tracker, for all subjects',
        message:
          'This will permantly delete the tracker and remove the tracker from all performance',
      },
    },
  },

  examples: {
    title: 'Calculation type differences',
    description: 'Description',
    examples: 'Examples',
    useTemplate: 'Use template',
    explanationText:
      'Choose an example and customize as you like, or start from scratch by creating your own custom automation.',
  },

  trackerNotFound: "The tracker you are trying to find doesn't seem to exist!",
  noData: 'No data available',
  noVideos: 'No videos to display',

  matchFilter: {
    showDataFor: 'Show data for',
    allMatches: 'all matches',
    last5Matches: 'last 5 matches',
    lastMatch: 'last match',
    selectedMatches: '{{count}} selected  matches',
    matches: 'Matches',
    matchesCount_one: '{{count}} match',
    matchesCount_other: '{{count}} matches',
    of: 'of',
  },
  transferredPlayerWarning:
    'This tracker references a player that is no longer on your squad. We recommend replacing this player or deleting this tracker.',

  trend: {
    noData: 'No data available',
    showingMatchday: 'Showing matchday {{matchday1}}-{{matchday2}}',
  },
  matchday: 'Matchday {{day}}',
  footer: {
    createdAt: 'Created on {{date}}',
    created: 'Created on {{date}}',
    update: 'Last updated on {{date}}',
  },
  displayObjectiveOnly: 'Only show events matching objective',

  pitchVisual: {
    successfulToggle: {
      ratio: 'Highlight events matching success details',
      rule: 'Highlight events matching rule conditions',
    },
  },

  views: {
    table: 'Table view',
    cards: 'Player cards view',
  },

  missingData: {
    info: 'Some trackers were not included in calculations',
    tracking_data: 'No tracking data available',
    metric: 'Metric not available',
    action: 'Action not available',
    not_calculated: 'Tracker not calculated yet',
  },

  sequence: {
    sequences: 'sequences',
    containing: 'containing',
    add_action: 'Add action',
    //sequence_action_separator: ' followed by ',
    sequence_action_separator: '->',
    events: 'events',
    sequence_result: {shot: 'shot ending', goal: 'goal ending'},
    switchTitle: 'Track sequences of events',
  },

  percentile: {
    top: {
      title: 'Great overall performance',
      percentile: 'Top {{value}}%',
      text: 'Performance of {{subject}} is great, belonging to the <strong>{{percentile}}</strong> of {{benchmark}}',
    },
    upper: {
      title: 'Good overall performance',
      percentile: 'Upper {{value}}%',
      text: 'Performance of {{subject}} is good, belonging to the <strong>{{percentile}}</strong> of {{benchmark}}',
    },
    average: {
      title: 'Average overall performance',
      percentile: 'Average',
      text: 'Performance of {{subject}} is at an average level compared to {{benchmark}}',
    },
    lower: {
      title: 'Below average overall performance',
      percentile: 'Lower {{value}}%',
      text: 'Performance of {{subject}} is below average, belonging to the <strong>{{percentile}}</strong> of {{benchmark}}',
    },
    bottom: {
      title: 'Poor overall performance',
      percentile: 'Bottom {{value}}%',
      text: 'Performance of {{subject}} is poor, belonging to the <strong>{{percentile}}</strong> of {{benchmark}}',
    },
  },
};

export default EVENT_AUTOMATIONS_TRANSLATIONS;
