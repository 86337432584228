import {ITrackerSentenceInputProps} from 'types/trackers/tracker-sentence.types';
import React, {useEffect} from 'react';
import SentenceBlock from '../blocks/sentence-block';
import SentenceTextSegment from '../segment/variants/text/sentence-text-segment';
import {useTranslation} from 'react-i18next';
import DetailsSegment from '../segment/instances/details/details-segment';
import {ACTION, FIConditionalEventFilters} from '@my-game-plan/types';

interface ISentenceDetailsLineProps
  extends ITrackerSentenceInputProps<FIConditionalEventFilters> {
  action?: ACTION;
  count?: number;
}

function SentenceDetailsLine(
  props: ISentenceDetailsLineProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_hasEmptyOrNoDetails, _setHasEmptyOrNoDetails] =
    React.useState<boolean>(false);
  /*
   * Side Effects
   */
  useEffect(() => {
    _setHasEmptyOrNoDetails(!props.value || !props.value?.filters?.length);
  }, [props.value]);

  /*
   * Handlers
   */
  function _onDetailsChange(data: FIConditionalEventFilters) {
    if (!props.onChange) {
      return;
    }

    props.onChange(data);
  }

  /*
   * Render
   */
  const {onChange, ..._commonProps} = props;

  if (props.readonly && _hasEmptyOrNoDetails) {
    return null;
  }
  return (
    <SentenceBlock.Line {..._commonProps}>
      <SentenceTextSegment
        {..._commonProps}
        value={t('sentenceForm.details.thatAre', {count: props.count || 2})}
      />

      <DetailsSegment
        {..._commonProps}
        onChange={_onDetailsChange}
        against={props.against}
        // value={props.tracker?.details}
      />
    </SentenceBlock.Line>
  );
}

export default SentenceDetailsLine;
