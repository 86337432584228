import {roundAndFormatNumber} from 'helpers/general/general.helper';

import {FIPerformanceOverview} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Typography from '@mui/material/Typography';
import {getColorForScore} from 'helpers/benchmark.helper';

interface IScoreCellProps {
  performance?: FIPerformanceOverview;
}

function ScoreCell(props: IScoreCellProps): JSX.Element | null {
  /*
   * Hooks n State
   */
  const [_valueText, _setValueText] = useState<string>('');

  /*
   * Side effects
   */
  useEffect(() => {
    if (typeof props.performance?.benchmarked_score !== 'undefined') {
      const _formattedValue = roundAndFormatNumber(
        props.performance.benchmarked_score,
      );
      _setValueText(_formattedValue);
    }
  }, [props.performance]);

  /*
   * Render
   */

  if (
    !props.performance ||
    typeof props.performance.benchmarked_score === 'undefined'
  ) {
    return null;
  }

  const _color = getColorForScore(props.performance.benchmarked_score);
  return (
    <Typography variant="body2" color={_color} fontWeight={600}>
      {_valueText}
    </Typography>
  );
}

export default ScoreCell;
