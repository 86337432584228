import {COMPETITIONS_API_CONFIG} from 'config/api.config';
import {REQUEST_ERRORS} from 'config/errors.config';
import {useAPI} from 'hooks/api.hooks';
import {Resource} from 'types/api.types';
import {
  FICompetition,
  FICompetitionSeason,
  FIMatch,
  FITeam,
} from '@my-game-plan/types';

const api = useAPI();

export async function getCompetitions(): Promise<FICompetition[]> {
  try {
    const {data} = await api.get<FICompetition[]>({
      resource: Resource.competitions,
      url: '',
    });
    if (!data) {
      return [];
    }
    return data;
  } catch (error) {
    return [];
  }
}

export async function getCompetitionSeasons(): Promise<FICompetitionSeason[]> {
  try {
    const {data} = await api.get<FICompetitionSeason[]>({
      resource: Resource.competitions,
      url: COMPETITIONS_API_CONFIG.getCompetitionSeasons(),
    });
    if (!data) {
      return [];
    }
    return data;
  } catch (error) {
    return [];
  }
}

export async function getTeamsByCompetitionSeason(
  seasonId: number,
  competitionId: number,
): Promise<FITeam[]> {
  try {
    const _URL = `competitions/${competitionId}/seasons/${seasonId}/teams`;
    const {data} = await api.get<FITeam[]>({
      url: _URL,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
}

export async function getMatchesByCompetitionSeason(
  seasonId: number,
  competitionId: number,
): Promise<FIMatch[]> {
  try {
    const _URL = `competitions/${competitionId}/seasons/${seasonId}/matches`;
    const {data} = await api.get<FIMatch[]>({
      url: _URL,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
}
