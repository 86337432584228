import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {TTrackerExecutorType} from '@my-game-plan/types';
import {TRACKER_TYPES_CONFIG} from 'config/trackers.config';

interface IExecutorTypeProps {
  type: TTrackerExecutorType;
}
function ExecutorType(props: IExecutorTypeProps): JSX.Element | null {
  const _type = TRACKER_TYPES_CONFIG[props.type];

  if (!_type) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <_type.icon color="secondary" sx={{fontSize: 16}} />
      <Typography variant="caption" color="secondary">
        {_type.title}
      </Typography>
    </Stack>
  );
}

export default ExecutorType;
