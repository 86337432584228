import {IProfileSetupScreenProps} from 'components/profile-setup-screen/profile-setup-screen';
import {
  fetchTeamProfiles,
  saveTeamProfile,
} from 'controllers/performance-profiles.controller';
import {
  createContextHook,
  createCustomContext,
} from 'helpers/general/context_generators.helper';
import {LOADING_STATE} from 'types/screen.types';
import {FIStyleOfPlayPerformance, TMGPCategory} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';
import {useAuth} from './auth.context';
import {useConfirm} from 'material-ui-confirm';
import {useTranslation} from 'react-i18next';
import ProfilesList from 'components/profile-setup-screen/profile/profiles-list';
import ProfileChart from 'components/profile-setup-screen/profile/profile-chart';
import ProfileTrackersCategoriesList from 'components/profile-setup-screen/trackers/profile-tracker-categories-list';
import ProfileTrackersSelector from 'components/profile-setup-screen/trackers/profile-trackers-selector';
import {AUTH_STATE} from 'types/user/user.types';

import Button from '@mui/material/Button';
import {useTeams} from './team.context';
import {useSnackbar} from 'notistack';
import {useAnalytics} from './analytics.context';
import ANALYTICS_EVENT from 'config/analytics/event-names.config';

type TCategoryOptionsMap = Partial<Record<TMGPCategory, string[]>>;
export interface IProfileSetupContextAPI {
  profiles: FIStyleOfPlayPerformance[];
  profilesLoadingState: LOADING_STATE;

  currentStepIndex: number;
  totalSteps: number;
  steps: IProfileSetupScreenProps[];

  nextStep: () => void;
  prevStep: () => void;
  onComplete: () => void;
  onSkip: () => void;
  onTrackerStepComplete: () => void;
  onProfileStepComplete: () => void;

  selectedProfile: FIStyleOfPlayPerformance | null;
  setSelectedProfile: (profile: FIStyleOfPlayPerformance) => void;

  activeCategory: TMGPCategory | null;
  completedCategories: TMGPCategory[];
  onCategoryChange: (category: TMGPCategory) => void;
  selectedTrackersPerCategory: TCategoryOptionsMap;
  onTrackersSelect: (trackerIds: string[]) => void;
}

const _context = createCustomContext<IProfileSetupContextAPI>();

export const useProfileSetup = createContextHook(_context);
export function ProfileSetupProvider(
  props: React.PropsWithChildren<React.ReactNode>,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _analyticsContext = useAnalytics();
  const _authContext = useAuth();
  const _teamsContext = useTeams();
  const _confirm = useConfirm();
  const _snackbar = useSnackbar();
  const [_profiles, _setProfiles] = useState<FIStyleOfPlayPerformance[]>([]);
  const [_profilesLoadingState, _setProfilesLoadingState] =
    useState<LOADING_STATE>(LOADING_STATE.INITING);

  const [_currentStepIndex, _setCurrentStepIndex] = useState<number>(0);

  const [_selectedProfile, _setSelectedProfile] =
    useState<FIStyleOfPlayPerformance | null>(null);

  const [_activeCategory, _setActiveCategory] = useState<TMGPCategory | null>(
    null,
  );
  const [_completedCategories, _setCompletedCategories] = useState<
    TMGPCategory[]
  >([]);
  const [_selectedTrackersPerCategory, _setSelectedTrackersPerCategory] =
    useState<TCategoryOptionsMap>({});

  const [_submitLoadingState, _setSubmitLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );

  const _steps: IProfileSetupScreenProps[] = [
    {
      leftHeader: {
        title: t('profileSetup.team.intro.title'),
        subtitle: t('profileSetup.team.intro.subtitle'),
        button: {
          onClick: _nextStep,
          cta: t('profileSetup.team.intro.cta'),
          muiButtonProps: {
            variant: 'contained',
          },
        },
      },
      leftCentered: true,
      LeftFooter: (
        <Button color="secondary" onClick={_onSkip}>
          {t('profileSetup.skip.cta')}
        </Button>
      ),
    },
    {
      leftHeader: {
        title: t('profileSetup.team.profile.title'),
        subtitle: t('profileSetup.team.profile.subtitle'),
      },
      LeftContent: <ProfilesList />,
      RightContent: <ProfileChart />,
      prevButton: {
        cta: t('general.back'),
        onClick: _prevStep,
      },
      nextButton: {
        cta: t('profileSetup.team.profile.cta'),
        onClick: _onProfileStepComplete,
        muiButtonProps: {
          disabled: !_selectedProfile,
        },
      },
    },
    {
      leftHeader: {
        title: t('profileSetup.team.trackers.title'),
        subtitle: t('profileSetup.team.trackers.subtitle'),
      },
      LeftContent: <ProfileTrackersCategoriesList />,
      RightContent: <ProfileTrackersSelector />,
      nextButton: {
        onClick: _onTrackerStepComplete,
        cta: t('profileSetup.team.trackers.cta'),
        isLoading: _submitLoadingState === LOADING_STATE.LOADING,
        muiButtonProps: {
          disabled:
            !_selectedProfile ||
            _completedCategories.length <
              _selectedProfile?.performance.categories.length,
        },
      },
      prevButton: {
        cta: t('general.back'),
        onClick: _prevStep,
        muiButtonProps: {
          disabled: _submitLoadingState === LOADING_STATE.LOADING,
        },
      },
    },
    {
      leftHeader: {
        title: t('profileSetup.team.done.title'),
        subtitle: t('profileSetup.team.done.subtitle'),
        button: {
          onClick: _onComplete,
          cta: t('profileSetup.team.done.cta'),
          muiButtonProps: {
            variant: 'contained',
          },
        },
      },
      leftCentered: true,
    },
  ];

  /*
   * Side effects
   */
  useEffect(() => {
    /* Fetch team profiles on load */
    _fetchTeamProfiles();

    /* Track screen view */
    _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_PROFILE_SETUP, {
      subject: 'team',
    });
  }, []);

  /* When selected profile changes, reset the selected trackers */
  useEffect(() => {
    _setCompletedCategories([]);

    if (!_selectedProfile) {
      return;
    }

    if (_selectedProfile?.performance.categories.length) {
      const _categoryName = _selectedProfile.performance.categories[0]
        .name as TMGPCategory;
      _setActiveCategory(_categoryName);

      _setSelectedTrackersPerCategory(_selectedProfile.options_per_category);
    } else {
      _setSelectedTrackersPerCategory({});
    }
  }, [_selectedProfile, _profiles]);

  useEffect(() => {
    if (_activeCategory && !_completedCategories.includes(_activeCategory)) {
      _setCompletedCategories((prev) => [...prev, _activeCategory]);
    }
  }, [_activeCategory, _completedCategories]);

  /* Analytics: track "viewed style of play" */
  useEffect(() => {
    if (_currentStepIndex === 1 && _selectedProfile) {
      _analyticsContext.trackEvent(
        ANALYTICS_EVENT.VIEWED_PROFILE_SETUP_STYLE_OF_PLAY,
        {
          subject: 'team',
          style_of_play: _selectedProfile.style_of_play,
        },
      );
    }
  }, [_selectedProfile, _currentStepIndex]);

  /* Analytics: track "viewed category" */
  useEffect(() => {
    if (_currentStepIndex === 2 && _activeCategory && _selectedProfile) {
      _analyticsContext.trackEvent(
        ANALYTICS_EVENT.VIEWED_PROFILE_SETUP_CATEGORY,
        {
          subject: 'team',
          style_of_play: _selectedProfile.style_of_play,
          category: _activeCategory,
        },
      );
    }
  }, [_activeCategory, _currentStepIndex, _selectedProfile]);

  /*
   * Handlers
   */
  async function _fetchTeamProfiles() {
    try {
      const _fetchedProfiles = await fetchTeamProfiles();
      _setProfilesLoadingState(LOADING_STATE.SUCCESS);

      if (_fetchedProfiles.length) {
        _setSelectedProfile(_fetchedProfiles[0]);
      }
      _setProfiles(_fetchedProfiles);
    } catch (error) {
      _setProfilesLoadingState(LOADING_STATE.ERROR);
    }
  }

  function _nextStep() {
    _setCurrentStepIndex((prev) => prev + 1);
  }

  function _prevStep() {
    _setCurrentStepIndex((prev) => prev - 1);
  }

  async function _onComplete() {
    _authContext.setAuthState(AUTH_STATE.READY);
  }

  async function _onSkip() {
    if (!_teamsContext.ownTeam) {
      return;
    }

    try {
      await _confirm({
        confirmationText: t('profileSetup.skip.confirm'),

        title: t('profileSetup.skip.title'),
        description: t('profileSetup.skip.text'),
      });
      await saveTeamProfile({
        style_of_play: 'none',
        tracker_ids: [],
      });
      _analyticsContext.trackEvent(ANALYTICS_EVENT.SKIPPED_PROFILE_SETUP);
      _authContext.setAuthState(AUTH_STATE.READY);
    } catch (error) {
      // user cancelled
    }
  }

  function _onProfileStepComplete() {
    _nextStep();
  }

  async function _onTrackerStepComplete() {
    if (!_selectedProfile) {
      return;
    }

    _setSubmitLoadingState(LOADING_STATE.LOADING);

    const _selectedTrackerIds = Object.values(
      _selectedTrackersPerCategory,
    ).flat();
    try {
      const _newPreferences = await saveTeamProfile({
        style_of_play: _selectedProfile?.style_of_play,
        tracker_ids: _selectedTrackerIds,
      });

      _teamsContext.updatePreferences(_newPreferences, false);

      _analyticsContext.trackEvent(
        ANALYTICS_EVENT.CREATED_PERFORMANCE_PROFILE,
        {
          subject: 'team',
          style_of_play: _selectedProfile.style_of_play,
          tracker_ids: _selectedTrackerIds,
        },
      );
      _setSubmitLoadingState(LOADING_STATE.SUCCESS);
      _nextStep();
    } catch (error) {
      _setSubmitLoadingState(LOADING_STATE.INITING);
      _snackbar.enqueueSnackbar(t('error-states.default'), {
        variant: 'error',
      });
    }
  }

  function _onCategorySelect(category: TMGPCategory) {
    _setActiveCategory(category);
  }

  function _onTrackersSelect(trackerIds: string[]) {
    if (!_activeCategory) {
      return;
    }

    const _newSelectedTrackers = {..._selectedTrackersPerCategory};
    _newSelectedTrackers[_activeCategory] = trackerIds;

    /* Set trackers */
    _setSelectedTrackersPerCategory(_newSelectedTrackers);
  }

  /*
   * Return context value
   */
  const _value: IProfileSetupContextAPI = {
    profiles: _profiles,
    profilesLoadingState: _profilesLoadingState,

    steps: _steps,
    currentStepIndex: _currentStepIndex,
    totalSteps: _steps.length,
    nextStep: _nextStep,
    prevStep: _prevStep,
    onProfileStepComplete: _onProfileStepComplete,
    onTrackerStepComplete: _onTrackerStepComplete,
    onComplete: _onComplete,
    onSkip: _onSkip,

    selectedProfile: _selectedProfile,
    setSelectedProfile: _setSelectedProfile,

    completedCategories: _completedCategories,
    onCategoryChange: _onCategorySelect,
    selectedTrackersPerCategory: _selectedTrackersPerCategory,
    onTrackersSelect: _onTrackersSelect,
    activeCategory: _activeCategory,
  };

  return <_context.Provider value={_value}>{props.children}</_context.Provider>;
}
