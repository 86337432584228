import React from 'react';

import {ITypedOption} from 'types/option.types';

import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
interface IOptionsListItemProps<
  T extends string | number,
  Multiple extends boolean = false,
> {
  option: ITypedOption<T>;
  selected?: boolean;
  multiple?: Multiple;
  onClick: (value: T) => void;
}

function OptionsListItem<
  T extends string | number,
  Multiple extends boolean = false,
>(props: IOptionsListItemProps<T, Multiple>): JSX.Element {
  /*
   * Render
   */
  function _onListItemClick() {
    props.onClick(props.option.value);
  }

  let _Checkbox = null;
  if (props.multiple) {
    _Checkbox = (
      <ListItemIcon>
        <Checkbox
          edge="start"
          tabIndex={-1}
          disableRipple
          checked={props.selected || false}
        />
      </ListItemIcon>
    );
  }
  const _MenuItem = (
    <MenuItem
      selected={props.selected}
      onClick={() => _onListItemClick()}
      tabIndex={1}>
      {_Checkbox}
      <ListItemText primary={props.option.label} />
    </MenuItem>
  );

  if (!props.option.Tooltip) {
    return _MenuItem;
  }

  return (
    <Tooltip
      title={props.option.Tooltip}
      placement="right"
      slotProps={{
        tooltip: {
          sx: {
            bgcolor: 'transparent',
            p: 0,
            borderWidth: 4,
            borderColor: 'background.default',
            borderStyle: 'solid',
          },
        },
        popper: {
          sx: {
            width: 200,
          },
        },
      }}>
      {_MenuItem}
    </Tooltip>
  );
}

export default OptionsListItem;
