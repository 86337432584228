import {REQUEST_ERRORS} from 'config/errors.config';
import {useAPI} from 'hooks/api.hooks';
import {
  FIAutomationDataQueryParams,
  FIPlayerOverview,
} from '@my-game-plan/types';

const api = useAPI();
export async function getOpponentTeamOverview(
  teamId: string,
  filters?: FIAutomationDataQueryParams,
): Promise<FIPlayerOverview> {
  try {
    const URL = `opponent-automations/${teamId}/overview`;
    const {data} = await api.get<FIPlayerOverview>({url: URL, params: filters});

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}
