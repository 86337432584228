import React from 'react';

import Popover from '@mui/material/Popover';
import Typography, {TypographyProps} from '@mui/material/Typography';

interface IHiddenItemsPopupProps extends TypographyProps {
  hiddenItemsCount: number;
  TooltipContent: string | React.ReactNode;
}

function HiddenItemsPopup(props: IHiddenItemsPopupProps): JSX.Element | null {
  /*
   * Hooks n State
   */
  const [_popupAnchorEl, _setPopupAnchorEl] =
    React.useState<HTMLElement | null>(null);

  /*
   * Handlers
   */
  function _onClick(event: React.MouseEvent<HTMLElement>) {
    _setPopupAnchorEl(event.currentTarget);
  }

  function _onClose() {
    _setPopupAnchorEl(null);
  }

  /*
   * Render
   */
  const {hiddenItemsCount, TooltipContent, ..._typographyProps} = props;

  if (!props.hiddenItemsCount) {
    return null;
  }

  return (
    <>
      <Typography {..._typographyProps} onClick={_onClick}>
        {` +${hiddenItemsCount}`}
      </Typography>
      <Popover
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        open={Boolean(_popupAnchorEl)}
        anchorEl={_popupAnchorEl}
        onClose={_onClose}>
        {TooltipContent}
      </Popover>
    </>
  );
}

export default HiddenItemsPopup;
