import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Button, {ButtonProps} from '@mui/material/Button';

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useVideo} from 'context/video/video.context';
import {
  FICompactMatchInfo,
  FIEventAutomationPostData,
  FIMatch,
  FIMatchEvent,
} from '@my-game-plan/types';
import {getAvailableSourcesForMatch} from 'helpers/video.helper';

interface VideoPlayerButtonProps extends ButtonProps {
  events: FIMatchEvent[] | null;
  displayTotalEvents?: boolean;
  actionInfo?: Partial<FIEventAutomationPostData>;
  matches?: FIMatch[] | FICompactMatchInfo[];
  relative?: boolean;
  isShowingSequences?: boolean;
}

function VideoPlayerButton({
  events,
  matches,
  actionInfo,
  displayTotalEvents = true,
  relative,
  isShowingSequences,
  ...buttonProps
}: VideoPlayerButtonProps) {
  /*
   * Hooks n State
   */
  const _video = useVideo();
  const {t} = useTranslation();

  const [_hasVideo, _setHasVideo] = useState<boolean>(false);

  /*
   * Side effects
   */

  useEffect(() => {
    if (!events?.length) {
      _setHasVideo(false);
      return;
    }

    const _matchIdsInEvents = new Set(events?.map((event) => event.match._id));
    const _filteredMatches = matches?.filter((match) =>
      _matchIdsInEvents.has(match._id),
    );

    const _calculatedHasVideo = _filteredMatches?.some((match) => {
      const _sourcesWithVideo = getAvailableSourcesForMatch(match.video);
      return _sourcesWithVideo.length > 0;
    });
    _setHasVideo(_calculatedHasVideo || false);
  }, [events, matches]);

  /*
   * Render
   */

  if (!events) {
    return (
      <Button startIcon={<PlayArrowIcon />} disabled>
        {t('video-player.button.play')}
      </Button>
    );
  }

  const handleClick = () => {
    _video.openVideoPlayer(
      events,
      matches || [],
      relative,
      true,
      actionInfo,
      undefined,
      isShowingSequences,
    );
  };

  let _size;
  if (displayTotalEvents) {
    _size = isShowingSequences
      ? new Set(events.map((event) => event.sequence?._id)).size
      : events.length;
  }
  return (
    // <Button
    //   iconBefore={<VidPlayIcon label="Play icon" />}
    //   isDisabled={events.length <= 0}
    //   onClick={handleClick}
    //   appearance="primary">
    //   {t('video-player.button.play')} ({events.length})
    // </Button>
    <Button
      startIcon={<PlayArrowIcon />}
      disabled={!_hasVideo}
      onClick={handleClick}
      color={buttonProps.color || 'primary'}
      variant={buttonProps.variant || 'contained'}
      size={buttonProps.size || 'small'}>
      {`${t('video-player.button.play')}${
        displayTotalEvents ? ` (${_size})` : ''
      }`}
    </Button>
  );
}

export default VideoPlayerButton;
