import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {TEventConditionType} from '@my-game-plan/types';

interface IAddConditionButtonProps {
  onChange: (type: TEventConditionType) => void;
}

const CONDITIONS: TEventConditionType[] = ['when', 'before', 'after'];

function AddConditionButton(
  props: IAddConditionButtonProps,
): JSX.Element | null {
  /* Hooks n State */
  // const [_isOpened, _setIsOpened] = useState<boolean>(false);
  const [_anchorEl, _setAnchorEl] = useState<HTMLElement | null>(null);
  const {t} = useTranslation();

  /* Handlers */
  function _onPopupTrigger(event: React.MouseEvent<HTMLButtonElement>) {
    _setAnchorEl(event.currentTarget);
  }

  function _onPopupClose() {
    _setAnchorEl(null);
  }

  function _onOptionSelect(value: TEventConditionType) {
    //
    props.onChange(value);
    _onPopupClose();
  }

  /* Render */
  const _isOpen = Boolean(_anchorEl);
  const _addConditionLabel = t('sentenceForm.conditions.add');
  return (
    <>
      <Button
        variant="text"
        color="secondary"
        onClick={_onPopupTrigger}
        startIcon={<AddCircleIcon />}
        size="small"
        sx={{alignSelf: 'flex-start'}}>
        {_addConditionLabel}
      </Button>
      <Menu
        anchorEl={_anchorEl}
        open={_isOpen}
        onClose={_onPopupClose}
        PaperProps={{
          sx: {boxShadow: 4},
        }}>
        {CONDITIONS.map((condition) => {
          return (
            <MenuItem
              key={condition}
              onClick={() => _onOptionSelect(condition)}>
              {t(`sentenceForm.conditions.${condition}Title`)}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default AddConditionButton;
