import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';

import {useAutomationForm} from 'context/event-automations/automations-form.context';
import {useTranslation} from 'react-i18next';
import {useConfirm} from 'material-ui-confirm';
import TrackerBuilderToolbar from './layout/tracker-builder-toolbar';
import {TransitionProps} from '@mui/material/transitions';

import {darken, useTheme} from '@mui/material';

import TrackerBuilderHeader from './layout/tracker-builder-header';
import TrackerSentence from '../sentence/tracker-sentence';

import TrackerBuilderExecutorInfo from './layout/tracker-builder-executor-info';

// Slide in animation
const DialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Actual component

function TrackerBuilderDialog(): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _trackerBuilderContext = useAutomationForm();
  const _confirm = useConfirm();
  const _theme = useTheme();
  /*
   * Side Effects
   */

  /*
   * Handlers
   */
  /* Handlers */
  async function _onClose() {
    if (_trackerBuilderContext.changeCount > 0) {
      // Show modal
      try {
        await _confirm({
          confirmationText: t('general.discard'),
          title: t('eventAutomations.create.cancelWarning.title'),
          description: t('eventAutomations.create.cancelWarning.text'),
        });
        _onCloseConfirm();
      } catch (error) {
        // User closed modal
      }
    } else {
      _onCloseConfirm();
    }
  }

  function _onCloseConfirm() {
    _trackerBuilderContext.resetChangeCount();
    _trackerBuilderContext.setIsOpened(false);
  }

  /*
   * Render
   */
  return (
    <Dialog
      open={_trackerBuilderContext.isOpened}
      fullScreen
      onClose={_onClose}
      PaperProps={{
        sx: {bgcolor: darken(_theme.palette.background.default, 0.2)},
      }}
      TransitionComponent={DialogTransition}>
      {/* Header */}
      <TrackerBuilderHeader onClose={_onClose} />

      {/* Body */}
      <DialogContent sx={{p: 0}}>
        {/* Automation Builder */}
        <Stack flexGrow={1} gap={4} px={6} py={6}>
          <TrackerBuilderExecutorInfo />

          {/* MAIN COMPONENT: SENTENCE */}

          <TrackerSentence
            size="large"
            tracker={_trackerBuilderContext.data}
            onChange={_trackerBuilderContext.setData}
            currentExecutorType={_trackerBuilderContext.currentExecutorType}
            isValid={_trackerBuilderContext.isValid}
            errors={_trackerBuilderContext.errors}
          />
        </Stack>
      </DialogContent>

      {/* Bottom toolbar */}
      <TrackerBuilderToolbar />
    </Dialog>
  );
}

export default TrackerBuilderDialog;
