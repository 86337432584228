import React, {useState} from 'react';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next';
import {FITag} from '@my-game-plan/types';
import {useTeams} from 'context/team.context';

interface IEditTagButtonProps {
  tag: FITag;
}

function EditTagButton(props: IEditTagButtonProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const [_isModalOpen, _setIsModalOpen] = useState<boolean>(false);
  const [_title, _setTitle] = useState<string>(props.tag.label);

  /*
   * Handlers
   */
  function _onEditClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    _setIsModalOpen(true);
  }

  function _onClose() {
    _setIsModalOpen(false);
  }

  function _onTitleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    _setTitle(e.target.value);
  }

  function _onDeleteClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    _teamsContext.deleteTag(props.tag);
    _onClose();
  }

  function _onSave(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    _teamsContext.editTag(props.tag._id, _title);
    _onClose();
  }

  /*
   * Render
   */
  return (
    <>
      <IconButton size="small" color="secondary" onClick={_onEditClick}>
        <EditIcon fontSize="small" />
      </IconButton>
      <Dialog
        open={_isModalOpen}
        onClose={_onClose}
        fullWidth
        maxWidth="sm"
        onClick={(e) => e.stopPropagation()}>
        <DialogTitle>{t('preferences.tags.edit')}</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <TextField
              label={t('preferences.tags.tag')}
              value={_title}
              onChange={_onTitleInputChange}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2} flex={1}>
            <Stack flexGrow={1} alignItems="flex-start">
              <IconButton
                onClick={_onDeleteClick}
                color="secondary"
                title={t('preferences.tags.delete.title')}>
                <DeleteIcon />
              </IconButton>
            </Stack>
          </Stack>
          <Button onClick={_onClose} color="secondary">
            {t('preferences.tags.cancel')}
          </Button>
          <Button onClick={_onSave} variant="contained">
            {t('preferences.tags.edit')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditTagButton;
