import React, {useEffect, useState} from 'react';
import {IGroupedTypedOptions, ITypedOption} from 'types/option.types';

import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';

import {useTranslation} from 'react-i18next';
import {createFilterOptions} from '@mui/material';
import OptionsListItem from './options-list-item';
import OptionsListSubheader from './options-list-subheader';

interface IOptionsListProps<
  T extends string | number,
  Multiple extends boolean = false,
> {
  options: ITypedOption<T>[];
  value?: Multiple extends true ? T[] : T;
  onChange: (value: Multiple extends true ? T[] : T) => void;
  multiple?: Multiple;
  groupHeader?: (key: string) => string;
}

function OptionsList<
  T extends string | number,
  Multiple extends boolean = false,
>(props: IOptionsListProps<T, Multiple>): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_filteredOptions, _setFilteredOptions] = useState<ITypedOption<T>[]>(
    [],
  );
  const [_optionGroups, _setOptionGroups] = useState<IGroupedTypedOptions<T>[]>(
    [],
  );
  const [_selectedValues, _setSelectedValues] = useState<T[]>([]);
  const [_searchValue, _setSearchValue] = useState<string>('');

  /*
   * Side effects
   */
  useEffect(() => {
    const _newOptionGroups: IGroupedTypedOptions<T>[] = [];
    _filteredOptions.forEach((option) => {
      const _existingGroup = _newOptionGroups.find(
        (group) => group.value === option.group,
      );
      if (_existingGroup) {
        _existingGroup.options.push(option);
      } else {
        let _groupHeader = option.group || '';
        if (props.groupHeader) {
          _groupHeader = props.groupHeader(option.group || '');
        }
        _newOptionGroups.push({
          label: _groupHeader,
          value: option.group || '',
          options: [option],
        });
      }
    });
    _setOptionGroups(_newOptionGroups);
  }, [_filteredOptions, props.groupHeader]);

  // Define selected values
  useEffect(() => {
    if (props.multiple) {
      _setSelectedValues((props.value || []) as T[]);
    } else {
      _setSelectedValues(props.value ? [props.value as T] : []);
    }
  }, [props.value, props.multiple]);

  // Define filtered options, based on search value
  useEffect(() => {
    const _optionsFilter = createFilterOptions<ITypedOption<T>>({
      matchFrom: 'any',
      stringify: (option) => `${option.group}-${option.name || ''}`,
    });

    const _newFilteredOptions = _optionsFilter(props.options, {
      inputValue: _searchValue,
      getOptionLabel: (option) => option.name || '',
    });
    _setFilteredOptions(_newFilteredOptions);
  }, [props.options, _searchValue]);

  /*
   * Handlers
   */
  function _onListItemClick(value: T) {
    if (props.multiple) {
      // Multi value

      const _newSelectedValues = _selectedValues.includes(value)
        ? _selectedValues.filter((v) => v !== value)
        : [..._selectedValues, value];
      props.onChange(_newSelectedValues as Multiple extends true ? T[] : T);
    } else {
      // Single value
      props.onChange(value as Multiple extends true ? T[] : T);
    }
  }

  function _onSubheaderCheckboxChange(value: T[]) {
    props.onChange(value as Multiple extends true ? T[] : T);
  }

  function _onSearchInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    _setSearchValue(event.target.value);
  }

  /*
   * Render
   */
  return (
    <Stack gap={1} pt={2}>
      {/* SEARCH FIELD */}
      <Stack px={2}>
        <TextField
          onChange={_onSearchInputChange}
          value={_searchValue}
          fullWidth
          size="small"
          placeholder={t('general.search')}
          autoFocus
          variant="outlined"
        />
      </Stack>
      <List>
        {/* GROUPED LIST */}
        {_optionGroups.map((group) => {
          return (
            <React.Fragment key={`group-${group.value}`}>
              {/* <ListSubHeader
              sx={{
                textTransform: 'uppercase',
                color: 'text.disabled',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <ListItemIcon sx={{minWidth: 0}}>
                <Checkbox
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  checked={_areAllItemsSelected}
                />
              </ListItemIcon>
              <ListItemText
                primary={group.label}
                primaryTypographyProps={{variant: 'caption'}}
              />
            </ListSubHeader> */}
              <OptionsListSubheader
                selectedValues={_selectedValues}
                multiple={props.multiple}
                onChange={_onSubheaderCheckboxChange}
                groupHeader={props.groupHeader}
                optionGroup={group}
              />

              {group.options.map((option) => {
                const _isSelected = _selectedValues.includes(option.value);
                return (
                  <OptionsListItem
                    key={`option-${group.value}-${option.value}`}
                    option={option}
                    selected={_isSelected}
                    onClick={() => _onListItemClick(option.value)}
                    multiple={props.multiple}
                  />
                );
              })}
            </React.Fragment>
          );
        })}
      </List>
    </Stack>
  );
}

export default OptionsList;
