import React from 'react';
import Stack from '@mui/material/Stack';
import {
  ITrackerSentenceProps,
  TSentenceSize,
} from 'types/trackers/tracker-sentence.types';

/*
 * SENTENCE - PARENT COMPONENT
 */
interface ISentenceContainerProps extends ITrackerSentenceProps {
  children: React.ReactNode;
}
function SentenceContainer(props: ISentenceContainerProps): JSX.Element {
  let _gap = 6;
  if (props.readonly) {
    _gap = 2;
  }
  return <Stack gap={_gap}>{props.children}</Stack>;
}

/*
 * SECTION
 */
interface ISentenceSectionProps extends ITrackerSentenceProps {
  children: React.ReactNode;
}

function SentenceSection(props: ISentenceSectionProps): JSX.Element {
  let _gap = 2;
  if (props.readonly) {
    _gap = 0.5;
  }
  return <Stack gap={_gap}>{props.children}</Stack>;
}

/*
 * LINE
 */
interface ISentenceLineProps extends ITrackerSentenceProps {
  children: React.ReactNode;
}

const GAP_MAP: Record<TSentenceSize, number> = {
  small: 0.5,
  medium: 0.5,
  large: 0.8,
};
function SentenceLine(props: ISentenceLineProps): JSX.Element {
  return (
    <Stack
      gap={GAP_MAP[props.size]}
      direction="row"
      alignItems="center"
      alignSelf={'flex-start'}
      flexWrap="wrap"
      position={'relative'}>
      {props.children}
    </Stack>
  );
}

/*
 * EXPORT SINGLE SentenceBlock OBJECT
 */

const SentenceBlock = {
  Container: SentenceContainer,
  Section: SentenceSection,
  Line: SentenceLine,
};

export default SentenceBlock;
