import React, {useEffect, useState} from 'react';

import cn from 'classnames';
import {ICommonSentenceInputProps} from 'types/sentence-form.types';

import TextField from '@mui/material/TextField';

import styles from './number-input.module.scss';
import Segment from '..';
import {FIEventAutomationPostData} from '@my-game-plan/types';

interface INumberInputProps<K> extends ICommonSentenceInputProps<number, K> {
  isRatio?: boolean;
}

function NumberInputSegment<DataType = FIEventAutomationPostData>(
  props: INumberInputProps<DataType>,
): JSX.Element {
  /* Hooks n State */
  const [_inputSize, _setInputSize] = useState<number>(0);

  /* Handlers */
  useEffect(() => {
    const _calculatedInputSize = props.value
      ? (props.value?.toString().length || 0) + 2
      : 4;

    _setInputSize(_calculatedInputSize);
    //   const _value = props.value || 0;

    //   let _baseWidth = 24;
    //   if (props.size === 'small') {
    //     _baseWidth = 18;
    //   }

    //   let _widthFactor = 1.3;

    //   if (_value > 99) {
    //     _widthFactor *= 1.9;
    //   } else if (_value > 9) {
    //     _widthFactor *= 1.4;
    //   }

    //   const _newWidth = _widthFactor * _baseWidth + 8;
    //   _setInputWidth(_newWidth);
  }, [props.value, props.size]);

  /* Render */
  function _onChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (props.onChange) {
      props.onChange({
        [props.property]: parseInt(event.target.value),
      } as unknown as Partial<DataType>);
    }
  }

  function _onBlur(event: React.FocusEvent<HTMLInputElement>) {
    const inputValue = parseInt(event.target.value);
    const maxValue = props.isRatio ? 100 : undefined;
    if (typeof maxValue !== 'undefined' && inputValue > maxValue) {
      event.target.value = maxValue.toString();
      _onChange(event);
    }
  }

  let _placeholder = props.placeholder || '0';
  if (props.required && !props.readonly) {
    _placeholder += '*';
  }
  if (props.readonly) {
    return (
      <Segment.Text
        size={props.size}
        data={props.data}
        text={props.value ? props.value.toString() : '0'}
        type="value"
      />
    );
  }

  const _widthFactor = props.size === 'medium' ? 12 : 10;
  const _inputWidth = _inputSize * _widthFactor;
  const _containerClasses = cn(
    styles.container,
    styles[`container-${props.size}`],
  );

  return (
    <div className={_containerClasses}>
      <TextField
        size="small"
        variant="standard"
        inputProps={{
          min: 0,
          max: props.isRatio ? 100 : 99,
          step: props.isRatio ? 5 : 1,
          type: 'number',
          size: _inputSize,
          style: {
            fontSize: props.size === 'medium' ? 24 : 16,
            fontWeight: 500,
            height: 'auto',
            // width: 'auto',
            width: _inputWidth,
            paddingLeft: 2,
            paddingBottom: 2,
          },
          sx: {
            '&::placeholder': {
              color: 'secondary.main',
              opacity: 1, // otherwise firefox shows a lighter color
            },
          },
        }}
        sx={{
          '& .MuiInput-underline:before': {
            borderBottomColor: 'rgba(255,255,255, .1)',
          },
        }}
        onChange={_onChange}
        onBlur={_onBlur}
        placeholder={_placeholder}
        required={props.required}
        value={props.value || ''}
        error={!!props.error}
        disabled={props.disabled}
      />
    </div>
  );
}

export default NumberInputSegment;
