import {useTheme} from '@mui/material';
import {FICategoryPerformance} from '@my-game-plan/types';
import React from 'react';
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import PerformanceChartTooltip from './performance-chart-tooltip';

interface IPerformanceRadarChartProps {
  categories: FICategoryPerformance[];
}
function PerformanceRadarChart(
  props: IPerformanceRadarChartProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const _theme = useTheme();

  /*
   * Side effects
   */

  /*
   * Render
   */

  return (
    <ResponsiveContainer width={'100%'} height={'100%'}>
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={props.categories}>
        <PolarGrid stroke={_theme.palette.divider} radialLines={false} />
        <PolarAngleAxis
          dataKey="name"
          fontSize={12}
          color={_theme.palette.text.secondary}
          fontWeight={500}
        />
        <PolarRadiusAxis
          angle={90}
          domain={[0, 10]}
          tickCount={6}
          axisLine={false}
          tick={({payload, x, y}) => {
            if (!payload.value) {
              return <></>;
            }
            return (
              <text
                x={x}
                y={y + 20} // Move the tick below the grid
                textAnchor="middle"
                fill={_theme.palette.text.disabled}
                fontSize={10}>
                {payload.value * 10}
              </text>
            );
          }}
        />
        <Tooltip
          cursor={{stroke: _theme.palette.primary.dark, strokeDasharray: 4}}
          content={(tooltipProps: TooltipProps<ValueType, NameType>) => {
            if (!tooltipProps.active || !tooltipProps.payload?.length) {
              return null;
            }

            const _categoryData = tooltipProps.payload[0]
              .payload as FICategoryPerformance;

            return <PerformanceChartTooltip data={_categoryData} />;
          }}
        />
        <Radar
          name="Performance"
          dataKey="benchmarked_score"
          stroke={_theme.palette.primary.main}
          fill={_theme.palette.primary.main}
          fillOpacity={0.25}
          strokeWidth={2}
          dot={{fill: _theme.palette.primary.main, fillOpacity: 1, r: 4}}
          activeDot={{
            fill: _theme.palette.primary.main,
            stroke: _theme.palette.primary.main,
            fillOpacity: 1,
            r: 6,
          }}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
}

export default PerformanceRadarChart;
