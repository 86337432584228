import React, {useEffect, useState} from 'react';
import {
  FIMatch,
  FIMatchVideoUploadPostData,
  TVideoSourceType,
} from '@my-game-plan/types';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {MatchScore} from 'components/common/match-score/MatchScore';
import {useFormik} from 'formik';
import {LOADING_STATE} from 'types/screen.types';
import FileUploadInput from 'components/file-upload/file-upload-input.view';
import {ITypedOption} from 'types/option.types';
import {useSnackbar} from 'notistack';
import {useConfirm} from 'material-ui-confirm';

import {useMatchVideos} from 'context/match-videos-context';

interface IMatchVideosUploadFormProps {
  match: FIMatch;
  isOpened: boolean;
  onClose: () => void;
  canDisableOffsets?: boolean;
}

interface IFormData extends FIMatchVideoUploadPostData {
  file: File;
}

function MatchVideosUploadForm(
  props: IMatchVideosUploadFormProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _matchVideosContext = useMatchVideos();

  const _formData: Partial<IFormData> = {
    match_id: props.match._id,
    source_type: 'tactical',
    // file: null,
    offsets: [0, 0],
  };
  const _snackbar = useSnackbar();
  const _confirm = useConfirm();
  const [_loadingState, _setLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );

  const [_hasAutomaticOffsets, _setHasAutomaticOffsets] =
    useState<boolean>(false);
  const [_videoSourceOptions, _setVideoSourceOptions] = useState<
    ITypedOption<TVideoSourceType>[]
  >([]);

  const _validationSchema = yup.object<IFormData>({
    file: yup.mixed().required(),
    source_type: yup.string().required(),
    offsets: _hasAutomaticOffsets
      ? undefined
      : yup
          .array()
          .of(yup.number().min(0))
          .length(2)
          .test(
            'second-half-positive',
            'Second half video start must be greater than 0',
            (value) =>
              Array.isArray(value) &&
              typeof value[1] !== 'undefined' &&
              value[1] > 0,
          ),
  });

  const _formik = useFormik<Partial<IFormData>>({
    onSubmit: _onSubmit,
    initialValues: _formData,
    validationSchema: _validationSchema,
  });

  /*
   * Side effects
   */
  // Define video source options
  useEffect(() => {
    const _availableSources: TVideoSourceType[] = ['broadcast', 'tactical'];
    const _generatedOptions: ITypedOption<TVideoSourceType>[] =
      _availableSources.map((source) => ({
        value: source,
        label: t(`matches.properties.video.source.${source}`),
      }));
    _setVideoSourceOptions(_generatedOptions);
  }, []);

  /*
   * Handlers
   */
  async function _onSubmit() {
    try {
      const {file, ..._formikValues} = _formik.values;

      if (!file) {
        return;
      }

      _setLoadingState(LOADING_STATE.LOADING);

      // Upload to S3 + update match doc
      await _matchVideosContext.onMatchVideoUpload(
        props.match._id,
        file,
        _formikValues,
      );

      _onClose(false);
    } catch (error) {
      _matchVideosContext.setUploadProgress(0);
      _setLoadingState(LOADING_STATE.INITING);
      _snackbar.enqueueSnackbar(t('matches.uploadVideo.error'), {
        variant: 'error',
      });
    }
  }

  async function _onClose(shouldConfirm = true) {
    if (!shouldConfirm) {
      props.onClose();
      return;
    }

    try {
      if (_formik.dirty) {
        await _confirm({
          title: t('uploads.uploadForm.closeWarning.title'),
          confirmationText: t('uploads.uploadForm.closeWarning.text'),
          description: t('uploads.uploadForm.closeWarning.description'),
        });

        _clearForm();
        props.onClose();
      } else {
        props.onClose();
      }
    } catch (error) {
      // user closed alert model
    }
  }

  function _onExited() {
    _clearForm();
  }

  function _clearForm() {
    _formik.resetForm();
    _setHasAutomaticOffsets(false);
    _setLoadingState(LOADING_STATE.INITING);
    _matchVideosContext.setUploadProgress(0);
  }

  function _onFileSelect(file: File) {
    _formik.setFieldValue('file', file);
  }

  function _onHasTimerCheck() {
    const _shouldSetAutomaticOffsets = !_hasAutomaticOffsets;
    _setHasAutomaticOffsets(_shouldSetAutomaticOffsets);

    if (_shouldSetAutomaticOffsets) {
      _formik.setFieldValue('offsets', undefined);
    } else {
      _formik.setFieldValue('offsets', [0, 0]);
    }
  }

  /*
   * Render
   */

  return (
    <Dialog
      open={props.isOpened}
      scroll="body"
      onClose={() => _onClose()}
      maxWidth="sm"
      PaperProps={{
        component: 'form',
        onSubmit: _formik.handleSubmit,
      }}
      fullWidth
      TransitionProps={{
        onExited: _onExited,
      }}>
      <DialogTitle>{t('matches.uploadVideo.uploadMatchVideo')}</DialogTitle>
      <DialogContent>
        <Stack gap={6}>
          <MatchScore match={props.match} displayTeamNames />
          {/* File upload */}
          <FileUploadInput
            onChange={_onFileSelect}
            error={
              (_formik.touched.file || Boolean(_formik.submitCount)) &&
              Boolean(_formik.errors.file)
            }
            value={_formik.values.file}
            uploadProgress={_matchVideosContext.uploadProgress}
            supportedFileTypes={['video/mp4', 'video/quicktime']}
          />

          {/* Source type - Tactical for now */}
          <FormControl fullWidth>
            <InputLabel id="source_type">
              {t('matches.properties.video.source.source')}
            </InputLabel>
            <Select
              disabled
              labelId="source_type"
              label={t('matches.properties.video.source.source')}
              name="source_type"
              value={_formik.values.source_type}
              onBlur={_formik.handleBlur}
              error={
                _formik.touched.source_type &&
                Boolean(_formik.errors.source_type)
              }
              fullWidth
              MenuProps={{
                slotProps: {
                  paper: {
                    sx: {
                      backgroundColor: 'background.default',
                    },
                  },
                },
              }}>
              {_videoSourceOptions.map((source) => {
                return (
                  <MenuItem key={source.value} value={source.value}>
                    {source.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Divider />

          {/* Offsets: checkbox + 2 inputs */}
          <Stack gap={2}>
            {props.canDisableOffsets && (
              <FormControl>
                <FormControlLabel
                  control={<Checkbox />}
                  label={t('matches.uploadVideo.timerOnDisplay.title')}
                  onChange={_onHasTimerCheck}
                  checked={_hasAutomaticOffsets}
                />
                {_hasAutomaticOffsets && (
                  <FormHelperText>
                    {t('matches.uploadVideo.timerOnDisplay.automaticOffsets')}
                  </FormHelperText>
                )}
              </FormControl>
            )}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={_hasAutomaticOffsets}
                  label={t('matches.uploadVideo.firstHalfVideoStart')}
                  onChange={_formik.handleChange}
                  name="offsets[0]"
                  type="number"
                  value={
                    typeof _formik.values.offsets?.[0] === 'undefined'
                      ? ''
                      : _formik.values.offsets[0]
                  }
                  inputProps={{min: 0}}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={_hasAutomaticOffsets}
                  label={t('matches.uploadVideo.secondHalfVideoStart')}
                  onChange={_formik.handleChange}
                  name="offsets[1]"
                  type="number"
                  value={
                    typeof _formik.values.offsets?.[1] === 'undefined'
                      ? ''
                      : _formik.values.offsets[1]
                  }
                  inputProps={{min: 0}}
                  error={
                    _formik.touched.offsets && Boolean(_formik.errors.offsets)
                  }
                />
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => _onClose()}>
          {t('general.cancel')}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          loading={_loadingState === LOADING_STATE.LOADING}
          type="submit">
          {t('matches.uploadVideo.cta')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default MatchVideosUploadForm;
