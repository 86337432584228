import React from 'react';
import {useParams} from 'react-router-dom';

import AutomationDetail from 'components/trackers/deep-dive/automation-detail.view';

// import {Tab, Tabs} from '@mui/material';

function TrackerDetailView() {
  /* Hooks n State */
  const _params = useParams();

  /* Render */
  return <AutomationDetail id={_params.id} executorType="team_performance" />;
}

export default TrackerDetailView;
