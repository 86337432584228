import React from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import {TTrackerExecutorType} from '@my-game-plan/types';
import {TRACKER_TYPES_CONFIG} from 'config/trackers.config';

interface IManageTrackersTHProps {
  executorType: TTrackerExecutorType;
}

function ManageTrackersTH(props: IManageTrackersTHProps): JSX.Element | null {
  const _executorTypeConfig = TRACKER_TYPES_CONFIG[props.executorType];
  if (!_executorTypeConfig) {
    return null;
  }
  return (
    <TableCell sx={{verticalAlign: 'bottom', pt: 0}}>
      <Stack component="span" gap={0.5}>
        <_executorTypeConfig.icon fontSize="small" />
        <Typography color="secondary.light" fontSize={12} fontWeight={500}>
          {_executorTypeConfig.title}
        </Typography>
      </Stack>
    </TableCell>
  );
}

export default ManageTrackersTH;
