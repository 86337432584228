import React, {useEffect, useState} from 'react';
import {ICommonSentenceInputProps} from 'types/sentence-form.types';
import {FIBenchmarkOptions, FIBenchmarkTimeOptions} from '@my-game-plan/types';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';

import dropdownStyles from './dropdown.module.scss';
import {useTranslation} from 'react-i18next';
import {timeRangeFiltersToText} from 'helpers/event-automations.helper';
import classNames from 'classnames';
import TimeFilterContent from 'components/filter/time-filter-content.view';
import {useCompetitions} from 'context/competitions.context';

function hasTimeValue(value?: FIBenchmarkTimeOptions): boolean {
  return (
    Boolean(value) &&
    Boolean(value?.match_limit || value?.season_id?.length || value?.since_date)
  );
}

interface TimeRangeDropdownProps
  extends ICommonSentenceInputProps<
    FIBenchmarkTimeOptions,
    FIBenchmarkOptions
  > {
  selectedCompetitions?: number[];
}

function TimeRangeDropdown(props: TimeRangeDropdownProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _competitionsContext = useCompetitions();
  const [_anchorEl, _setAnchorEl] = useState<HTMLElement | null>(null);
  const [_valueText, _setValueText] = useState<string>('');

  /*
   * Side effects
   */
  useEffect(() => {
    let _defaultValueText = t('eventAutomations.filter.time.period');

    if (props.required && !hasTimeValue(props.value)) {
      _defaultValueText += '*';
    }
    const _formattedValueText = timeRangeFiltersToText(
      props.value,
      undefined,
      _competitionsContext.displayMultiYear,
    );
    _setValueText(_formattedValueText.value || _defaultValueText);
  }, [props.value, props.required, _competitionsContext.displayMultiYear]);

  /*
   * Handlers
   */
  function _onPopupOpen(event: React.MouseEvent<HTMLElement>) {
    _setAnchorEl(event.currentTarget);
  }

  function _onPopupClose() {
    _setAnchorEl(null);
  }

  function _onChange(value: FIBenchmarkTimeOptions) {
    if (!props.onChange) return;

    props.onChange({
      [props.property]: value,
    } as Partial<FIBenchmarkOptions>);
  }

  /*
   * Render
   */
  const _isOpen = Boolean(_anchorEl);
  const _hasValue = hasTimeValue(props.value);

  const _triggerItemClasses = classNames(dropdownStyles.triggerItem, {
    [dropdownStyles.triggerItemOpened]: _isOpen,
    [dropdownStyles.triggerItemRequired]: !_hasValue && props.required,
    [dropdownStyles.triggerItemWithValues]: _hasValue,
    [dropdownStyles.triggerItemReadOnly]: props.readonly,
    [dropdownStyles.triggerItemError]: props.error,
  });

  return (
    <>
      <button
        className={dropdownStyles.trigger}
        onClick={_onPopupOpen}
        disabled={props.disabled}>
        <span
          className={classNames(
            _triggerItemClasses,
            dropdownStyles[`triggerItem-${props.size}`],
          )}>
          {_valueText}
        </span>
      </button>
      <Popover
        open={_isOpen}
        anchorEl={_anchorEl}
        onClose={_onPopupClose}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        slotProps={{
          paper: {sx: {bgcolor: 'background.default', boxShadow: 4}},
        }}>
        <Stack p={2}>
          <TimeFilterContent
            onChange={_onChange}
            value={props.value}
            selectedCompetitions={props.selectedCompetitions}
          />
        </Stack>
      </Popover>
    </>
  );
}

export default TimeRangeDropdown;
