import React, {useEffect, useState} from 'react';

import Stack from '@mui/material/Stack';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import Typography from '@mui/material/Typography';
import ExecutorType from '../executor/executor-type';
import {useAutomationForm} from 'context/event-automations/automations-form.context';

function TrackerBuilderExecutorInfo(): JSX.Element {
  /*
   * Hooks n State
   */
  const _trackerBuilderContext = useAutomationForm();
  const [_executorsCount, _setExecutorsCount] = useState(0);

  /*
   * Side Effects
   */
  useEffect(() => {
    const _executors =
      _trackerBuilderContext.data[_trackerBuilderContext.currentExecutorType];
    _setExecutorsCount(_executors?.length || 0);
  }, [_trackerBuilderContext.data, _trackerBuilderContext.currentExecutorType]);

  /*
   * Render
   */
  return (
    <Stack direction="row" gap={4} alignItems="center">
      <ExecutorType type={_trackerBuilderContext.currentExecutorType} />
      {_executorsCount > 1 && (
        <Stack direction="row" gap={0.5} alignItems="center">
          <CenterFocusStrongIcon
            color="secondary"
            sx={{
              fontSize: 16,
            }}
          />
          <Typography variant="caption" color="text.secondary">
            {_executorsCount}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}

export default TrackerBuilderExecutorInfo;
