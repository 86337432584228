import {
  FITrackerSharedSubjects,
  FITrackerSubject,
  TTrackerExecutorType,
} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';

import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import {useTranslation} from 'react-i18next';
import ExecutorSharePopupContent from './executor-share-popup-content';
import {lighten, useTheme} from '@mui/material';

interface IExecutorShareButtonProps {
  executorType: TTrackerExecutorType;
  subject: FITrackerSubject;
  onChange: (subject: FITrackerSubject) => void;
  against: boolean;
}

function ExecutorShareButton(props: IExecutorShareButtonProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _theme = useTheme();
  const [_sharePopupAnchorEl, _setSharePopupAnchorEl] =
    useState<null | HTMLElement>(null);
  const [_tooltipText, _setTooltipText] = useState<string>('');

  /*
   * Side Effects
   */
  useEffect(() => {
    let _newTooltipText = t('sentenceForm.share.notSharedInApp');
    if (props.subject.shared_subjects) {
      _newTooltipText = t('sentenceForm.share.sharedInApp');

      let _withValueText = '';
      if (props.subject.shared_subjects.player_ids?.length) {
        _withValueText = t('sentenceForm.share.playersCount', {
          count: props.subject.shared_subjects.player_ids.length,
        });
      } else if (props.subject.shared_subjects.player_positions?.length) {
        _withValueText = t('sentenceForm.share.positionsCount', {
          count: props.subject.shared_subjects.player_positions.length,
        });
      }

      if (_withValueText) {
        _newTooltipText = t('sentenceForm.share.sharedWithValue', {
          value: _withValueText,
        });
      }
    }

    _setTooltipText(_newTooltipText);
  }, [props.subject.shared_subjects]);

  /*
   * Handlers
   */
  function _onClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (props.executorType === 'scouting_profile_ids') {
      return;
    }

    if (props.executorType === 'player_development') {
      // Player development
      let _newSharedSubject: FITrackerSharedSubjects | undefined = undefined;
      if (!props.subject.shared_subjects) {
        _newSharedSubject = {
          share_with_executor: true,
        };
      }
      const _newSubject: FITrackerSubject = {
        ...props.subject,
        shared_subjects: _newSharedSubject,
      };
      props.onChange(_newSubject);
      return;
    }

    // Opponent analysis + team performance
    if (!props.subject.shared_subjects) {
      const _shouldEnableTeam =
        (props.subject.executor?.team &&
          !props.subject.executor?.team.executor_positions?.length) ||
        props.against;
      const _newSharedSubject: FITrackerSharedSubjects = {
        team: _shouldEnableTeam,
      };

      if (
        props.subject.executor?.team?.executor_positions?.length &&
        !props.against
      ) {
        _newSharedSubject.player_positions =
          props.subject.executor.team.executor_positions;
      }

      const _newSubject: FITrackerSubject = {
        ...props.subject,
        shared_subjects: _newSharedSubject,
      };
      props.onChange(_newSubject);
    }

    // Open popup
    _onPopupOpen(event);
  }

  function _onPopupOpen(event: React.MouseEvent<HTMLElement>) {
    _setSharePopupAnchorEl(event.currentTarget);
  }

  function _onPopupClose() {
    _setSharePopupAnchorEl(null);

    // If no values are set, remove shared_subjects
    if (
      !props.subject.shared_subjects?.team &&
      !props.subject.shared_subjects?.player_positions?.length &&
      !props.subject.shared_subjects?.player_ids?.length
    ) {
      const _newSubject: FITrackerSubject = {
        ...props.subject,
        shared_subjects: undefined,
      };
      props.onChange(_newSubject);
    }
  }

  function _onPopupShareValueChange(data?: FITrackerSharedSubjects) {
    props.onChange({
      ...props.subject,
      shared_subjects: data,
    });
  }

  /*
   * Render
   */
  let _Icon = PhonelinkEraseIcon;
  let _color: 'success' | 'secondary' = 'secondary';
  if (props.subject.shared_subjects) {
    _Icon = MobileFriendlyIcon;
    _color = 'success';
  }

  const _highlightColor = lighten(_theme.palette.background.paper, 0.1);
  return (
    <>
      <Tooltip title={_tooltipText}>
        <IconButton
          size="small"
          color={_color}
          onClick={_onClick}
          sx={{
            bgcolor: _sharePopupAnchorEl ? _highlightColor : undefined,
          }}>
          <_Icon />
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(_sharePopupAnchorEl)}
        anchorEl={_sharePopupAnchorEl}
        onClose={_onPopupClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}>
        <ExecutorSharePopupContent
          executorType={props.executorType}
          onChange={_onPopupShareValueChange}
          sharedSubjects={props.subject.shared_subjects}
        />
      </Popover>
    </>
  );
}

export default ExecutorShareButton;
