import {
  FIEventAutomationSubject,
  FIPlayer,
  FIBenchmarkOptions,
} from '@my-game-plan/types';

import {PALETTE_TEXT} from 'theme';
import {
  DEFAULT_PERCENTILE_COLOR,
  IPercentileGroupConfig,
  PERCENTILE_GROUPS_CONFIG,
} from 'config/trackers.config';
import i18n from 'localization';

/*
 * Get the position filter for the benchmark based on observing players' position
 */
export function getPositionFilterByObservingPlayers(
  ownPlayers: FIPlayer[],
  observingPlayers?: FIEventAutomationSubject,
): Partial<FIBenchmarkOptions> {
  const _filters: Partial<FIBenchmarkOptions> = {
    position: undefined,
  };

  if (observingPlayers?.players?.length) {
    const _player = ownPlayers.find(
      (player) => player._id === observingPlayers?.players?.[0],
    );
    if (_player) {
      _filters.position = _player.position;
    }
  }

  return _filters;
}

/*
 * Get indexes to show in ranking
 */
export function getRankingIndicesToDisplay(
  currentIndex: number,
  totalEntries: number,
) {
  const displayCount = 5;
  const halfDisplayCount = Math.floor(displayCount / 2);

  // Calculate the start and end indices for the display
  let startIndex = Math.max(0, currentIndex - halfDisplayCount);
  let endIndex = Math.min(totalEntries - 1, startIndex + displayCount - 1);

  // Adjust the start index if needed
  startIndex = Math.max(0, endIndex - displayCount + 1);

  // Adjust the end index if needed
  endIndex = Math.min(totalEntries - 1, startIndex + displayCount - 1);

  // Create an array of display indices
  const displayIndices = [];
  for (let i = startIndex; i <= endIndex; i++) {
    displayIndices.push(i);
  }

  return displayIndices;
}

/*
 * Get color based on score 0-10
 */
export function getColorForScore(score?: number): string {
  if (typeof score === 'undefined') return PALETTE_TEXT.primary as string;

  let _scoreColor = DEFAULT_PERCENTILE_COLOR;

  const _percentileGroup = getPercentileGroupForScore(score);
  _scoreColor = _percentileGroup.color;

  return _scoreColor;
}

export function getPercentileGroupForScore(
  score: number,
): IPercentileGroupConfig {
  let _percentileGroup = PERCENTILE_GROUPS_CONFIG[0];

  for (let i = 0; i < PERCENTILE_GROUPS_CONFIG.length; i++) {
    const groupConfig = PERCENTILE_GROUPS_CONFIG[i];
    const isLastGroup = i === PERCENTILE_GROUPS_CONFIG.length - 1;

    if (
      score >= groupConfig.range[0] &&
      (isLastGroup
        ? score <= groupConfig.range[1]
        : score < groupConfig.range[1])
    ) {
      _percentileGroup = groupConfig;
      break;
    }
  }

  return _percentileGroup;
}

export function getPercentileTextForScore(score?: number) {
  if (typeof score === 'undefined') return '';

  const _group = getPercentileGroupForScore(score);
  const _translationKey = `eventAutomations.percentile.${_group.group}.percentile`;
  const _percentile = _group.getPercentile(score);
  const _newPercentileText = i18n.t(_translationKey, {
    value: _percentile,
  });

  return _newPercentileText;
}
