import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  EMPTY_STATE_IMAGE,
  ERROR_STATE_IMAGE,
} from 'config/empty-state-image.config';
import styles from './ErrorState.module.scss';
import Typography from '@mui/material/Typography';

type TSize = 'small' | 'medium';

interface Props {
  header?: string;
  description?: string;
  primaryAction?: React.ReactNode;
  secondaryAction?: React.ReactNode;
  imageType?: 'default' | 'error';
  bottomText?: string;
  size?: TSize;
}

const CustomEmptyState = ({
  header,
  description,
  primaryAction,
  secondaryAction,
  bottomText,
  imageType = 'default',
  size = 'medium',
}: Props) => {
  const {t} = useTranslation();

  let _image = EMPTY_STATE_IMAGE;
  if (imageType === 'error') {
    _image = ERROR_STATE_IMAGE;
  }

  return (
    <div className={styles.container} style={{height: 'auto', maxWidth: 640}}>
      <img
        src={_image}
        alt="MyGamePlan"
        height={size == 'small' ? 96 : 128}
        // height={128}
        style={{marginBottom: size == 'small' ? 16 : 50}}
      />

      <Typography
        sx={{mb: size === 'small' ? 0.5 : 2, textAlign: 'center'}}
        color="text.secondary"
        variant={size === 'small' ? 'body1' : 'h5'}
        component="h2">
        {header ? header : t('error-states.empty.header')}
      </Typography>

      {Boolean(description) && (
        <Typography
          color="text.secondary"
          textAlign="center"
          variant={size === 'small' ? 'body2' : 'body1'}
          sx={{mb: size === 'small' ? 0 : 4}}>
          {description}
        </Typography>
      )}
      {primaryAction && (
        <div className={styles.primaryAction}>{primaryAction}</div>
      )}
      {secondaryAction && (
        <div className={styles.secondaryAction}>{secondaryAction}</div>
      )}
      {/* MaterialUI end */}
      {bottomText && <div className={styles.bottomText}>{bottomText}</div>}
    </div>
  );
};

export default CustomEmptyState;
