import React, {useEffect, useState} from 'react';

import MUIAvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

import {useTeams} from 'context/team.context';
import {FIPlayer} from '@my-game-plan/types';
import {useTranslation} from 'react-i18next';

interface IAvatarGroupProps {
  players: string[];
  max?: number;
}

function AvatarGroup(props: IAvatarGroupProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_players, _setPlayers] = useState<Partial<FIPlayer>[]>([]);
  const _teamsContext = useTeams();

  useEffect(() => {
    const _matchingPlayers = _teamsContext.ownPlayers.filter((player) =>
      props.players.includes(player._id),
    );

    const _transferredPlayerIDs = props.players.filter(
      (playerID) =>
        !_teamsContext.ownPlayers.some((player) => player._id === playerID),
    );

    const _transferredPlayers: Partial<FIPlayer>[] = _transferredPlayerIDs.map(
      (playerID) => ({
        _id: playerID,
        name: t('playerPosition.transferredPlayer'),
        display_name: t('playerPosition.transferredPlayer'),
      }),
    );

    _setPlayers([..._transferredPlayers, ..._matchingPlayers]);
  }, [props.players, _teamsContext.ownPlayers]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  const _allPlayerNames = _players
    .map((player) => player.display_name)
    .join(', ');

  return (
    <Tooltip title={_allPlayerNames} placement="bottom-start">
      <MUIAvatarGroup
        max={props.max || 4}
        total={props.players.length}
        sx={{
          justifyContent: 'flex-end',
          '& .MuiAvatar-root': {width: 32, height: 32, fontSize: 14},
        }}>
        {_players.map((player) => {
          const _isTransferred =
            player.name === t('playerPosition.transferredPlayer');
          return (
            <Avatar
              key={player._id}
              alt={player.name}
              src={player.image_url}
              sx={{
                bgcolor: _isTransferred ? 'warning.light' : 'secondary.dark',
                borderColor: 'background.paper',
              }}
            />
          );
        })}
      </MUIAvatarGroup>
    </Tooltip>
  );
}

export default AvatarGroup;
