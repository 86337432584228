import {IconButton} from '@mui/material';

import React from 'react';
import {Link} from 'react-router-dom';

import {Spacer} from '../../../../common/spacer/Spacer';
import styles from './WidgetHeader.module.scss';
import {useTeams} from 'context/team.context';
import {
  toggleActionSnakeCase,
  translateValue,
} from 'helpers/translation.helper';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {useTranslation} from 'react-i18next';
import {NAV_LEVEL_1_ROUTE} from 'config/navigation.config';

interface WidgetHeaderProps {
  action?: string;
  label?: string;
}

export const WidgetHeader = ({action, label}: WidgetHeaderProps) => {
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const _to = `${NAV_LEVEL_1_ROUTE.DASHBOARDS}/${
    _teamsContext.current?._id
  }/${toggleActionSnakeCase(action || '', true)}`;

  const _translatedTitle = translateValue(label || action || '');

  if (action) {
    return (
      <>
        <div className={styles.header}>
          <Link to={_to} className={styles.link}>
            <h2 className={styles.title}>{_translatedTitle}</h2>
          </Link>
          <IconButton
            component={Link}
            to={_to}
            color="primary"
            title={t('dashboard.view')}>
            <OpenInNewIcon />
          </IconButton>
        </div>
        <Spacer />
      </>
    );
  }

  return (
    <>
      <div className={styles.header}>
        <h2>{_translatedTitle}</h2>
      </div>
      <Spacer />
    </>
  );
};
