import React, {useEffect, useState} from 'react';
import {FIBenchmarkOptions, FIPerformanceOverview} from '@my-game-plan/types';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {Trans, useTranslation} from 'react-i18next';
import Indicator from 'components/dashboard/Indicator/Indicator';
import {
  getPercentileGroupForScore,
  getPercentileTextForScore,
} from 'helpers/benchmark.helper';
import {useCompetitions} from 'context/competitions.context';
import {IPercentileGroupConfig} from 'config/trackers.config';
import {getSelectedPositionOptions} from 'helpers/automation/automation-form.helper';

interface IPerformanceSummaryTextProps {
  performanceOverview: FIPerformanceOverview;
  benchmarkOptions?: FIBenchmarkOptions;
  isPreview?: boolean;
  subject: string;
  Button?: React.ReactNode;
}

function PerformanceSummaryText(
  props: IPerformanceSummaryTextProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _competitionsContext = useCompetitions();
  const [_group, _setGroup] = useState<IPercentileGroupConfig | null>(null);
  const [_benchmarkText, _setBenchmarkText] = useState<string>('');
  const [_percentileText, _setPercentileText] = useState<string>('');

  /*
   * Side effects
   */
  useEffect(() => {
    if (typeof props.performanceOverview.benchmarked_score === 'undefined') {
      return;
    }

    const _matchingGroup = getPercentileGroupForScore(
      props.performanceOverview.benchmarked_score,
    );
    _setGroup(_matchingGroup);

    const _newPercentileText = getPercentileTextForScore(
      props.performanceOverview.benchmarked_score,
    );
    _setPercentileText(_newPercentileText.toLowerCase());

    /* Benchmark text */
    let _benchmarkPrefix = '';
    let _newBenchmarkText = t(
      'eventAutomations.benchmark.benchmark',
    ).toLowerCase();

    if (props.benchmarkOptions?.position?.length) {
      const _formattedPositions = getSelectedPositionOptions(
        props.benchmarkOptions?.position,
        false,
        true,
        false,
      );

      _benchmarkPrefix = `${_formattedPositions} ${t('eventAutomations.in')} `;
    }

    if (
      props.benchmarkOptions?.observing?.competition_id &&
      props.benchmarkOptions?.observing?.competition_id.length === 1
    ) {
      const _competition = _competitionsContext.all.find(
        (c) => c._id === props.benchmarkOptions?.observing?.competition_id?.[0],
      );
      if (_competition) {
        _newBenchmarkText = _competition.name;
      }
    }

    if (_benchmarkPrefix) {
      _newBenchmarkText = `${_benchmarkPrefix} ${_newBenchmarkText}`;
    }
    _setBenchmarkText(_newBenchmarkText);
  }, [
    props.performanceOverview.benchmarked_score,
    props.benchmarkOptions,
    _competitionsContext.all,
  ]);

  /*
   * Render
   */
  if (!_group) {
    return null;
  }
  return (
    <Stack gap={4} alignItems={'flex-start'}>
      <Stack gap={1}>
        {props.isPreview && (
          <Typography variant="caption">{t('navigation.team')}</Typography>
        )}
        <Typography variant="h5" fontSize={24} fontWeight={600}>
          {t(`eventAutomations.percentile.${_group.group}.title`)}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {/* {_descriptionText} */}
          <Trans
            i18nKey={`eventAutomations.percentile.${_group.group}.text`}
            values={{
              benchmark: _benchmarkText,
              percentile: _percentileText,
              subject: props.subject,
            }}
            components={{
              strong: (
                <Typography
                  fontSize="inherit"
                  fontWeight={600}
                  component="strong"
                />
              ),
            }}
          />
        </Typography>
      </Stack>
      <Stack width={'100%'} maxWidth={180}>
        <Indicator
          value={props.performanceOverview.benchmarked_score}
          valueDisplay="none"
        />
      </Stack>

      {props.Button || null}
    </Stack>
  );
}
export default PerformanceSummaryText;
