import React from 'react';

import Stack from '@mui/material/Stack';

import ScoutPlayerButton from 'components/scouting/scout-player-button.view';
import {useExecutorDeepDive} from 'context/executor-deep-dive.context';

function PlayerDeepDiveHeaderActions(): JSX.Element | null {
  /*
   * Hooks n State
   */
  const _executorDeepDiveContext = useExecutorDeepDive();

  /*
   * Handlers
   */
  function _onFollowPlayer(newScoutingProfileId?: string) {
    if (newScoutingProfileId) {
      _executorDeepDiveContext.reloadPlayerWithScoutingProfile(
        newScoutingProfileId,
      );
    }
  }

  /*
   * Render
   */

  if (
    !_executorDeepDiveContext.player ||
    _executorDeepDiveContext.playerSummary?.is_own_player
  ) {
    return null;
  }

  return (
    <Stack gap={2} direction="row" alignItems="center">
      <ScoutPlayerButton
        player={_executorDeepDiveContext.player}
        layout="button"
        scouting={_executorDeepDiveContext.playerSummary?.scouting}
        onChange={_onFollowPlayer}
        shouldSelectProfile
      />
    </Stack>
  );
}

export default PlayerDeepDiveHeaderActions;
