import {NAV_LEVEL_1_ROUTE} from 'config/navigation.config';

export function getRoutePath(
  path: string,
  shouldRemoveSlash?: boolean,
): string {
  if (shouldRemoveSlash) {
    return path.replace('/', '');
  }
  return path;
}

export function getPlayerDeepDivePath(
  playerId: string,
  isScouting?: boolean,
  scoutingProfileId?: string,
): string {
  let _url = `${NAV_LEVEL_1_ROUTE.PLAYERS}/${playerId}`;
  if (isScouting) {
    let _baseURL = `${NAV_LEVEL_1_ROUTE.SCOUTING}`;
    if (scoutingProfileId) {
      _baseURL = `${_baseURL}/profiles/${scoutingProfileId}`;
    }

    _url = `${_baseURL}${_url}`;
  }
  return _url;
}
