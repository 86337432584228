import React, {useEffect, useState} from 'react';
import {
  FIEventAutomationPostData,
  FIPlayer,
  FITrackerSubject,
  TTrackerExecutorType,
} from '@my-game-plan/types';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ExecutorType from '../../executor/executor-type';

import {
  formatSingleExecutor,
  generateUniqueTrackerKey,
} from 'helpers/automation/automation-form.helper';
import {EXECUTOR_TYPES} from 'config/trackers.config';

interface IExecutorPreviewContentProps {
  data: Partial<FIEventAutomationPostData>;
  selectedExecutors?: ISelectedExecutorsState;
  onExecutorSelect?: (
    executorType: TTrackerExecutorType,
    index: number,
  ) => void;
  ownPlayers: FIPlayer[]; // We cant use teamsContext.ownPlayers because this component is shown by _confirm()
}

interface IExecutorPreview {
  key: string;
  label: string;
}
interface ITypeWithExecutors {
  executor_type: TTrackerExecutorType;
  executors: IExecutorPreview[];
}

export interface ISelectedExecutorsState {
  totalCount: number;
  selectedCount: number;
  executors: TSelectedExecutorsForTypes;
}

export type TSelectedExecutorsForTypes = Partial<
  Record<TTrackerExecutorType, number[]>
>;

function ExecutorPreviewContent(props: IExecutorPreviewContentProps) {
  /*
   * Hooks n State
   */
  const [_typesWithExecutors, _setTypesWithExecutors] = useState<
    ITypeWithExecutors[]
  >([]);

  /*
   * Side effects
   */
  useEffect(() => {
    const _newTypesWithExecutors: ITypeWithExecutors[] = [];
    EXECUTOR_TYPES.forEach((executorType) => {
      if (!props.data[executorType]) {
        return;
      }

      let _executors: IExecutorPreview[] = [];
      if (executorType === 'scouting_profile_ids') {
        _executors = _formatExecutorsForScoutingProfiles(
          props.data[executorType] || [],
        );
      } else {
        _executors = _formatExecutorsForType(props.data[executorType] || []);
      }

      if (_executors.length) {
        _newTypesWithExecutors.push({
          executor_type: executorType,
          executors: _executors,
        });
      }
    });

    _setTypesWithExecutors(_newTypesWithExecutors);
  }, [props.data, props.ownPlayers]);

  /*
   * Handlers
   */
  function _formatExecutorsForType(
    subjects: FITrackerSubject[],
  ): IExecutorPreview[] {
    const _formattedExecutors: IExecutorPreview[] = [];

    subjects.forEach((subject, index) => {
      const _key = generateUniqueTrackerKey(props.data, subject.executor);
      const _formattedExecutor = formatSingleExecutor(
        subject.executor,
        props.ownPlayers,
      );
      _formattedExecutors.push({
        key: _key || index.toString(),
        label: _formattedExecutor,
      });
    });

    return _formattedExecutors;
  }

  function _formatExecutorsForScoutingProfiles(
    scoutingProfileIds: string[],
  ): IExecutorPreview[] {
    const _formattedExecutors: IExecutorPreview[] = [];
    scoutingProfileIds.forEach((scoutingProfileId) => {
      _formattedExecutors.push({
        key: scoutingProfileId,
        label: scoutingProfileId,
      });
    });

    return _formattedExecutors;
  }

  function _onExecutorSelect(
    executorType: TTrackerExecutorType,
    index: number,
  ) {
    if (!props.onExecutorSelect) {
      return;
    }

    props.onExecutorSelect(executorType, index);
  }

  /*
   * Render
   */
  const _isInteractive = Boolean(props.onExecutorSelect);
  return (
    <Stack direction={_isInteractive ? 'row' : 'column'} gap={3}>
      {_typesWithExecutors.map((typeWithExecutors) => {
        return (
          <Stack gap={1} key={typeWithExecutors.executor_type} flexGrow={1}>
            <ExecutorType type={typeWithExecutors.executor_type} />
            {typeWithExecutors.executors.map((executor, index) => {
              if (!props.onExecutorSelect) {
                return (
                  <Stack key={executor.key}>
                    <Typography variant="body2">{executor.label}</Typography>
                  </Stack>
                );
              }

              const _isSelected =
                props.selectedExecutors?.executors[
                  typeWithExecutors.executor_type
                ]?.includes(index);
              return (
                <Stack key={executor.key}>
                  <FormControlLabel
                    control={<Checkbox checked={_isSelected} />}
                    label={
                      <Typography variant="body2">{executor.label}</Typography>
                    }
                    onChange={() =>
                      _onExecutorSelect(typeWithExecutors.executor_type, index)
                    }
                  />
                </Stack>
              );
            })}
          </Stack>
        );
      })}
    </Stack>
  );
}

export default ExecutorPreviewContent;
