import React from 'react';

import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

interface IDeleteButtonProps {
  onClick: () => void;
}

function DeleteConditionButton(props: IDeleteButtonProps) {
  return (
    <Box
      className="deleteButton"
      display="none"
      position="absolute"
      right={-48}
      top="50%"
      pl={1}
      sx={{
        transform: 'translateY(-50%)',
      }}>
      <IconButton onClick={props.onClick} color="secondary">
        <DeleteIcon />
      </IconButton>
    </Box>
  );
}

export default DeleteConditionButton;
