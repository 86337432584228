import {FIFormattedAutomation, TTrackerExecutorType} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Select, {SelectChangeEvent} from '@mui/material/Select';
import {ITypedOption} from 'types/option.types';
import {TRACKER_TYPES_CONFIG} from 'config/trackers.config';
import {
  formatSingleExecutor,
  generateUniqueTrackerKey,
} from 'helpers/automation/automation-form.helper';
import {useTeams} from 'context/team.context';
import {ILinkState} from 'types/navigation.types';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

interface IDeepDiveLinkSelectorDialogProps {
  open: boolean;
  onClose: () => void;

  tracker: FIFormattedAutomation;
  defaultExecutorType: TTrackerExecutorType;

  generateDeepDiveURL: (
    executorType: TTrackerExecutorType,
    executorIndex: number,
  ) => string;
  backLinkState: ILinkState;
}

function DeepDiveLinkSelectorDialog(
  props: IDeepDiveLinkSelectorDialogProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const [_categoryOptions, _setCategoryOptions] = useState<
    ITypedOption<TTrackerExecutorType>[]
  >([]);
  const [_selectedExecutorType, _setSelectedExecutorType] =
    useState<TTrackerExecutorType>('team_performance');
  const [_executorOptions, _setExecutorOptions] = useState<
    ITypedOption<string>[]
  >([]);

  /*
   * Side Effects
   */
  useEffect(() => {
    const _generatedCategoryOptions: ITypedOption<TTrackerExecutorType>[] = [];
    Object.keys(TRACKER_TYPES_CONFIG).forEach((category) => {
      const _typedCategory = category as TTrackerExecutorType;
      const _categoryConfig = TRACKER_TYPES_CONFIG[_typedCategory];
      if (props.tracker[_typedCategory]?.length && _categoryConfig) {
        _generatedCategoryOptions.push({
          value: _typedCategory,
          label: _categoryConfig.title,
        });
      }
    });

    _setCategoryOptions(_generatedCategoryOptions);
  }, [props.tracker, props.open]);

  useEffect(() => {
    _setSelectedExecutorType(props.defaultExecutorType);
  }, [props.defaultExecutorType]);

  useEffect(() => {
    _setExecutorOptions([]);
    if (_selectedExecutorType !== 'scouting_profile_ids') {
      const _generatedOptions: ITypedOption<string>[] = [];
      const _values = props.tracker[_selectedExecutorType];

      _values?.forEach((value, index) => {
        const _formattedExecutor = formatSingleExecutor(
          value.executor,
          _teamsContext.ownPlayers,
        );
        const _generatedDeepDiveURL = props.generateDeepDiveURL(
          _selectedExecutorType,
          index,
        );

        const _key = generateUniqueTrackerKey(props.tracker, value.executor);

        _generatedOptions.push({
          value: _generatedDeepDiveURL,
          label: _formattedExecutor,
          name: _key || `${_selectedExecutorType}-${index.toString()}`, // Hacky - using name to store unique key
        });
      });

      _setExecutorOptions(_generatedOptions);
    }
  }, [_selectedExecutorType, props.generateDeepDiveURL, props.tracker]);

  /*
   * Handlers
   */
  function _handleExecutorTypeChange(
    event: SelectChangeEvent<TTrackerExecutorType>,
  ) {
    _setSelectedExecutorType(event.target.value as TTrackerExecutorType);
  }

  /*
   * Render
   */

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="xs">
      <DialogTitle>{t('eventAutomations.chooseSubjectToView')}</DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          <Select
            disabled={_categoryOptions.length === 1}
            value={_selectedExecutorType}
            onChange={_handleExecutorTypeChange}>
            {_categoryOptions.map((category) => {
              return (
                <MenuItem key={category.value} value={category.value}>
                  {category.label}
                </MenuItem>
              );
            })}
          </Select>

          <Stack gap={1}>
            {_executorOptions.map((executor, index) => {
              const _isLast = index === _executorOptions.length - 1;

              return (
                <Stack
                  key={executor.name || index}
                  direction="row"
                  alignItems="center"
                  py={1}
                  gap={2}
                  sx={{
                    borderBottom: _isLast ? 0 : 1,
                    borderBottomColor: 'divider',
                  }}>
                  <Typography flexGrow={1}>{executor.label}</Typography>
                  <Button
                    sx={{whiteSpace: 'nowrap'}}
                    component={Link}
                    endIcon={<ArrowForwardIcon />}
                    to={executor.value}
                    state={props.backLinkState}
                    variant="text"
                    size="small"
                    color="primary">
                    {t('eventAutomations.viewCta')}
                  </Button>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default DeepDiveLinkSelectorDialog;
