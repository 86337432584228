import React, {useEffect, useState} from 'react';

import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';

import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import StartIcon from '@mui/icons-material/Start';

import {useAuth} from 'context/auth.context';
import {useTeams} from 'context/team.context';
import Logo from 'components/common/Logo/Logo';
import {useTrackers} from 'context/event-automations/tracker.context';
import {useTranslation} from 'react-i18next';
import SwitchTeamDialog from 'components/preferences/switch-team-dialog/switch-team-dialog.view';
import {FITeam, USER_ROLE} from '@my-game-plan/types';
import {USER_MANUAL_URL} from 'config/links.config';
import {darken, useTheme} from '@mui/material';
import {useApp} from 'context/app.context';

function UserButton(): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _authContext = useAuth();
  const _teamsContext = useTeams();
  const _trackersContext = useTrackers();
  const _appContext = useApp();
  const _theme = useTheme();

  const [_isSwitchingTeam, _setIsSwitchingTeam] = useState<boolean>(false);
  const [_isAdmin, _setIsAdmin] = useState<boolean>(false);
  const [_availableTeams, _setAvailableTeams] = useState<FITeam[]>([]);
  const [_menuAnchorEl, _setMenuAnchorEl] = useState<null | HTMLElement>(null);

  /*
   * Side effects
   */
  // Check if user is admin to disable Switch Team
  useEffect(() => {
    if (!_authContext.user) {
      return;
    }

    _setIsAdmin(_authContext.user.role === USER_ROLE.ADMIN);

    if (_authContext.user.available_teams?.length) {
      _setAvailableTeams(
        _authContext.user.available_teams.filter(
          (t) => t._id !== _authContext.user?.team,
        ),
      );
    }
  }, [_authContext.user]);

  /*
   * Handlers
   */
  function _handleMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
    _setMenuAnchorEl(event.currentTarget);
  }
  function _onMenuClose() {
    _setMenuAnchorEl(null);
  }

  function _handleLogout() {
    _trackersContext.clear();
    _teamsContext.clear();
    _authContext.logoutUser();
  }

  function _onSwitchTeamPress() {
    _setIsSwitchingTeam(true);
    _onMenuClose();
  }
  function _onSwitchTeamClose() {
    _setIsSwitchingTeam(false);
  }

  function _onSidebarToggle() {
    _appContext.toggleSidebar();
  }

  /*
   * Render
   */
  if (!_authContext.user) {
    return null;
  }

  const _menuBGColor = darken(_theme.palette.background.default, 0.2);
  return (
    <Stack pb={3} px={1.5} gap={2}>
      <Stack direction="row">
        {/* AVATAR */}
        <IconButton onClick={_handleMenuOpen} sx={{p: 0}}>
          <Badge
            overlap="circular"
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            badgeContent={
              <Logo
                src={_teamsContext.ownTeam?.image_url || ''}
                size="xsmall"
                isBadge
              />
            }>
            <Avatar
              alt={`${_authContext.user.firstName} ${_authContext.user.lastName}`}
              sx={{
                bgcolor: 'secondary.dark',
                color: 'secondary.light',
                width: 40,
                height: 40,
                fontSize: 14,
              }}
              src="">
              {_authContext.user.firstName[0]}
              {_authContext.user.lastName[0]}
            </Avatar>
          </Badge>
        </IconButton>

        <Stack
          ml={2}
          sx={{opacity: _appContext.navigation.isSidebarExpanded ? 1 : 0}}>
          <Typography
            variant="body2"
            color="text.secondary">{`${_authContext.user.firstName} ${_authContext.user.lastName}`}</Typography>
          {_teamsContext.ownTeam && (
            <Typography variant="caption" color="text.disabled">
              {_teamsContext.ownTeam.name}
            </Typography>
          )}
        </Stack>
      </Stack>

      <IconButton
        onClick={_onSidebarToggle}
        sx={{
          alignSelf: 'flex-end',
          mr: 1,
          transform: _appContext.navigation.isSidebarExpanded
            ? 'rotate(180deg)'
            : 'rotate(0deg)',
        }}
        color="secondary"
        size="small">
        <StartIcon sx={{fontSize: 16}} />
      </IconButton>

      {/* MENU */}
      <Menu
        anchorEl={_menuAnchorEl}
        open={Boolean(_menuAnchorEl)}
        onClose={_onMenuClose}
        slotProps={{
          paper: {
            sx: {
              bgcolor: _menuBGColor,
            },
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {/* Switch team - Admin */}
        {(_isAdmin || _availableTeams.length > 0) && (
          <MenuItem onClick={_onSwitchTeamPress} dense>
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText>{t('settings.switch_team')}</ListItemText>
          </MenuItem>
        )}
        {/* User manual */}
        <MenuItem
          dense
          component="a"
          href={USER_MANUAL_URL}
          target="_blank"
          rel="noopener">
          <ListItemIcon>
            <MenuBookIcon />
          </ListItemIcon>
          <ListItemText>{t('settings.user_manual')}</ListItemText>
        </MenuItem>

        {/* Log out */}
        <MenuItem onClick={_handleLogout} dense>
          <ListItemIcon>
            <LogoutIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{color: 'secondary'}}>
            {t('settings.log_out')}
          </ListItemText>
        </MenuItem>
      </Menu>

      {/* Switch Team Dialog  */}
      <SwitchTeamDialog
        open={_isSwitchingTeam}
        available_teams={_availableTeams}
        onClose={_onSwitchTeamClose}
      />
    </Stack>
  );
}

export default UserButton;
