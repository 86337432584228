import TeamPositionPlayerPicker from 'components/trackers/misc/team-position-player-picker/team-position-player-picker';
import {
  FITrackerSharedSubjects,
  TTrackerExecutorType,
} from '@my-game-plan/types';
import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';

interface IExecutorSharePopupContentProps {
  executorType: TTrackerExecutorType;
  sharedSubjects?: FITrackerSharedSubjects;
  onChange: (sharedSubjects?: FITrackerSharedSubjects) => void;
}

function ExecutorSharePopupContent(
  props: IExecutorSharePopupContentProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Side effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */
  return (
    <Stack pt={2}>
      <Typography variant="caption" px={2}>
        {t('sentenceForm.share.shareWith')}
      </Typography>
      <TeamPositionPlayerPicker
        currentExecutorType={props.executorType}
        value={props.sharedSubjects}
        onChange={props.onChange}
        multiple
        teamDisplay="all"
        noValueOption
      />
    </Stack>
  );
}

export default ExecutorSharePopupContent;
