import React, {useEffect, useState} from 'react';
import SentenceBlock from './blocks/sentence-block';
import SentenceBaseLine from './line/sentence-base-line';
import SentenceDetailsLine from './line/sentence-details-line';
import {ITrackerSentenceProps} from 'types/trackers/tracker-sentence.types';
import SentenceConditions from './conditions/conditions';
import {
  FIConditionalEventFilters,
  FIEventAutomationPostData,
  isTrackingAction,
} from '@my-game-plan/types';
import SentenceRuleCondition from './conditions/rule-condition';
import SequenceSentence from './sequence/sequence-sentence';

function TrackerSentence(props: ITrackerSentenceProps): JSX.Element {
  /*
   * Hooks n State
   */
  const [_isRatio, _setIsRatio] = useState<boolean>(false);
  const [_isRule, _setIsRule] = useState<boolean>(false);
  const [_isSequence, _setIsSequence] = useState<boolean>(false);

  /*
   * Side effects
   */
  // Define whether the calculation is a ratio
  useEffect(() => {
    _setIsRatio(props.tracker?.calculation === 'ratio');
    _setIsRule(props.tracker?.calculation === 'rule');
  }, [props.tracker?.calculation]);

  useEffect(() => {
    _setIsSequence(props.tracker?.action === 'sequence');
  }, [props.tracker?.action]);

  /*
   * Handler
   */
  function _onDetailsChange(data: FIConditionalEventFilters) {
    if (!props.onChange) {
      return;
    }
    const _property: keyof FIEventAutomationPostData = _isRatio
      ? 'success_details'
      : 'details';
    props.onChange({[_property]: data});
  }

  /*
   * Render
   */
  if (_isSequence) {
    return <SequenceSentence {...props} />;
  }
  const _Baseline = <SentenceBaseLine {...props} />;

  let _DetailsLine = null;
  if (props.tracker?.action && !isTrackingAction(props.tracker?.action)) {
    _DetailsLine = (
      <SentenceDetailsLine
        {...props}
        value={
          _isRatio ? props.tracker?.success_details : props.tracker?.details
        }
        onChange={_onDetailsChange}
        required={_isRatio}
        count={_isRule ? 1 : 2}
        action={props.tracker?.action}
        error={_isRatio ? props.errors?.success_details : props.errors?.details}
        against={props.tracker?.against}
      />
    );
  }

  const _Conditions = <SentenceConditions {...props} />;

  if (_isRule) {
    return (
      <SentenceBlock.Container {...props}>
        <SentenceBlock.Section {...props}>
          <SentenceRuleCondition {...props} />
          {_Conditions}
        </SentenceBlock.Section>
        <SentenceBlock.Section {...props}>
          {_Baseline}
          {_DetailsLine}
        </SentenceBlock.Section>
      </SentenceBlock.Container>
    );
  }

  return (
    <SentenceBlock.Container {...props}>
      <SentenceBlock.Section {...props}>
        {_Baseline}

        {_Conditions}

        {_DetailsLine}
      </SentenceBlock.Section>
    </SentenceBlock.Container>
  );
}

export default TrackerSentence;
