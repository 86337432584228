import ShotAnalysis from 'views/shot-analysis.view';
import Stack from '@mui/material/Stack';
import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {useTranslation} from 'react-i18next';
import Button from '@mui/material/Button';
import Screen from 'components/screen/screen.view';
import {SHOT_ANALYSIS_ROUTES} from 'config/navigation.config';

function ShotAnalysisRoutes(): JSX.Element {
  /*
   * Hooks n State
   */

  const {t} = useTranslation();

  /*
   * Handlers
   */

  /*
   * Render
   */

  return (
    <Screen
      title={t('shotAnalysis.title')}
      coloredHeader
      tabs={SHOT_ANALYSIS_ROUTES}>
      <Stack gap={4}>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            color="secondary"
            sx={{textTransform: 'none'}}
            disabled
            size="small">
            {`${t('shotAnalysis.benchmark.benchmarkingAgainst')} ${t(
              'shotAnalysis.benchmark.league',
            )}`}
          </Button>
        </Stack>
      </Stack>
      <Routes>
        <Route path="" element={<Navigate to={'created'} />} />
        <Route path={'created'} element={<ShotAnalysis type="created" />} />
        <Route path={'conceded'} element={<ShotAnalysis type="conceded" />} />

        {/* Redirect */}
        <Route path="*" element={<Navigate to="created" />} />
      </Routes>
    </Screen>
  );
}

export default ShotAnalysisRoutes;
