import React, {useEffect, useState} from 'react';
import {LOADING_STATE} from 'types/screen.types';
import {
  FIPlayerSearchFilters,
  FIPlayerWithData,
  TPlayerWithDataSortKey,
  TSortParams,
} from '@my-game-plan/types';
import {useTranslation} from 'react-i18next';

import {Link, useLocation, useSearchParams} from 'react-router-dom';

import {getPlayersForScoutingProfile} from 'controllers/scouting.controller';
import {IPagination} from 'types/api.types';

import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import ScoutingHeaderFilterBar from './filter-bar/scouting-header-filter-bar.view';
import {TScoutingPlayersType, TScoutingViewType} from 'types/scouting.types';
import PlayerCardsGrid from '../players/player-card/player-cards-grid.view';
import PlayersTable from '../players/players-table/players-table.view';

import CustomEmptyState from '../error-states/CustomEmptyState';
import ScreenContent from '../screen/screen-content.view';
import qs from 'qs';
import {useAnalytics} from 'context/analytics.context';
import ANALYTICS_EVENT from 'config/analytics/event-names.config';

interface IScoutingListScreenViewProps {
  scoutingProfileId: string;
  resultsViewType: TScoutingPlayersType;
  filters: FIPlayerSearchFilters;
  onSearchDialogOpen: () => void;
}

function ScoutingListScreenView(
  props: IScoutingListScreenViewProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _location = useLocation();
  const _analyticsContext = useAnalytics();

  const [_playersLoadingState, _setPlayersLoadingState] =
    useState<LOADING_STATE>(LOADING_STATE.INITING);
  const [_pagination, _setPagination] = useState<IPagination | null>(null);
  const [_sort, _setSort] = useState<TSortParams | null>(null);
  const [_visiblePlayers, _setVisiblePlayers] = useState<FIPlayerWithData[]>(
    [],
  );

  const [_viewType, _setViewType] = useState<TScoutingViewType>('table');
  const [_searchParams, _setSearchParams] = useSearchParams();

  /*
   * Side Effects
   */

  // Fetch players on load
  useEffect(() => {
    const _parsedSearchParams = qs.parse(_searchParams.toString(), {
      allowDots: true,
    });

    _fetchPlayers(props.filters, _parsedSearchParams);
  }, [_searchParams, props.resultsViewType, props.filters]);

  // Scroll to top on load + set sort from query
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    const _sortKeyFromQuery = _searchParams.get('sort.key');
    const _sortOrderFromQuery = _searchParams.get('sort.order');

    if (_sortKeyFromQuery && _sortOrderFromQuery) {
      const _newSortToSet: TSortParams = {
        key: _sortKeyFromQuery as TPlayerWithDataSortKey,
        order: parseInt(_sortOrderFromQuery as string) as -1 | 1,
      };

      _setSort(_newSortToSet);
    }
  }, [_searchParams]);

  // Analytics

  useEffect(() => {
    if (!_pagination) {
      return;
    }

    _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_SCOUTING_PROFILE, {
      scouting_profile_id: props.scoutingProfileId,
      pagination: _pagination || undefined,
      view: props.resultsViewType,
    });
  }, [props.scoutingProfileId, props.resultsViewType, _pagination]);

  /*
   * Handlers
   */
  async function _fetchPlayers(
    filters: FIPlayerSearchFilters,
    searchParams: qs.ParsedQs,
  ) {
    try {
      if (_playersLoadingState !== LOADING_STATE.INITING) {
        _setPlayersLoadingState(LOADING_STATE.LOADING);
      }

      const _fetchedPlayerData = await getPlayersForScoutingProfile(
        props.scoutingProfileId,
        props.resultsViewType,
        searchParams,
      );

      _setVisiblePlayers(_fetchedPlayerData.data || []);
      _setPagination(_fetchedPlayerData.pagination || null);

      _setPlayersLoadingState(LOADING_STATE.SUCCESS);
    } catch (error) {
      _setPlayersLoadingState(LOADING_STATE.ERROR);
    }
  }

  function _onTableSort(params: TSortParams) {
    const _searchParamsToSet = {
      sort: params,
    };
    _setSearchParams(qs.stringify(_searchParamsToSet, {allowDots: true}));
  }

  /*
   * Render
   */

  const _shouldDisplayPerformance = Boolean(props.filters.mirroring);

  /* Content: cards grid or table */
  let _Content = (
    <CustomEmptyState
      header={t(`scouting.empty.${props.resultsViewType}.header`)}
    />
  );
  if (_viewType === 'cards' && _visiblePlayers.length) {
    _Content = (
      <PlayerCardsGrid
        isScouting
        scoutingProfileId={props.scoutingProfileId}
        players={_visiblePlayers}
        displayPlayerBio
        displayPlayerPerformance={_shouldDisplayPerformance}
      />
    );
  } else if (_viewType === 'table' && _visiblePlayers.length) {
    _Content = (
      <PlayersTable
        players={_visiblePlayers}
        scoutingProfileId={props.scoutingProfileId}
        displayPlayerPerformance={_shouldDisplayPerformance}
        sort={_sort || undefined}
        onSort={_onTableSort}
      />
    );
  }

  return (
    <ScreenContent loadingState={_playersLoadingState}>
      <ScoutingHeaderFilterBar
        searchFilters={props.filters}
        view={_viewType}
        onViewChange={_setViewType}
        pagination={_pagination}
        onSearchDialogOpen={props.onSearchDialogOpen}
      />
      {/* Content */}
      <Stack>{_Content}</Stack>
      {_pagination && _pagination.total_pages > 1 && (
        <Pagination
          page={_pagination.current_page}
          count={_pagination.total_pages}
          sx={{display: 'flex', justifyContent: 'center', width: '100%'}}
          renderItem={(item) => {
            let _to = `${_location.pathname}?${_searchParams.toString()}`;
            const _itemSearchParams = new URLSearchParams(_searchParams);
            if (item.page) {
              _itemSearchParams.set('page', item.page.toString());
            }

            _to = `${_location.pathname}?${_itemSearchParams.toString()}`;
            return <PaginationItem component={Link} to={_to} {...item} />;
          }}
        />
      )}
    </ScreenContent>
  );
}

export default ScoutingListScreenView;
