import React, {useEffect, useState} from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import CircularProgress from '@mui/material/CircularProgress';
import SyncIcon from '@mui/icons-material/Sync';
import {useTranslation} from 'react-i18next';
import {useTeams} from 'context/team.context';

import {ITypedOption} from 'types/option.types';
import {getPlayerOptions} from 'helpers/automation/automation-form.helper';
import CustomAutocomplete from 'components/material-customised/autocomplete/autocomplete.view';

import {LOADING_STATE} from 'types/screen.types';
import SegmentedControl from 'components/common/segmented-control/segmented-control.view';
import {useScouting} from 'context/scouting.context';
import {useExecutorDeepDive} from 'context/executor-deep-dive.context';

type TMirrorType = 'scouting_profile' | 'player';

const translationsPrefix = 'players.performance.mirrorring.builder';
function PlayerMirrorBuilder(): JSX.Element {
  /*
   * Hooks n State
   */
  const _scoutingContext = useScouting();
  const _executorDeepDiveContext = useExecutorDeepDive();
  const _teamsContext = useTeams();
  const {t} = useTranslation();

  const TYPE_OPTIONS: ITypedOption<TMirrorType>[] = [
    {
      label: t(`${translationsPrefix}.type.profile`),
      value: 'scouting_profile',
    },
    {
      label: t(`${translationsPrefix}.type.player`),
      value: 'player',
    },
  ];

  const [_selectedType, _setSelectedType] =
    useState<TMirrorType>('scouting_profile');

  const [_playerOptions, _setPlayerOptions] = useState<ITypedOption<string>[]>(
    [],
  );
  const [_selectedPlayerOption, _setSelectedPlayerOption] =
    useState<ITypedOption<string> | null>(null);

  const [_profileOptions, _setProfileOptions] = useState<
    ITypedOption<string>[]
  >([]);
  const [_selectedProfileOption, _setSelectedProfileOption] =
    useState<ITypedOption<string> | null>(null);

  /*
   * Side effects
   */

  useEffect(() => {
    const _newPlayerOption = getPlayerOptions(_teamsContext.ownPlayers);
    _setPlayerOptions(_newPlayerOption);

    const _filteredScoutingProfiles = _scoutingContext.profiles.filter(
      (profile) => profile.mirroring_player_id,
    );
    const _generatedProfileOptions = _filteredScoutingProfiles.map(
      (profile) => {
        const _option: ITypedOption<string> = {
          value: profile._id,
          label: profile.description,
          name: 'profile',
        };

        return _option;
      },
    );
    _setProfileOptions(_generatedProfileOptions);
  }, [_teamsContext.ownPlayers, _scoutingContext.profiles]);

  /*
   * Handlers
   */
  function _onPlayerSelect(
    data: ITypedOption<string> | ITypedOption<string>[] | null,
  ) {
    if (!Array.isArray(data)) {
      _setSelectedPlayerOption(data);
    }
  }
  function _onProfileSelect(
    data: ITypedOption<string> | ITypedOption<string>[] | null,
  ) {
    if (!Array.isArray(data)) {
      _setSelectedProfileOption(data);
    }
  }

  function _onTypeChange(value: TMirrorType) {
    _setSelectedType(value);
  }

  function _onApplyTrackersClick() {
    if (!_selectedPlayerOption) {
      return;
    }

    _executorDeepDiveContext.mirrorPlayer(
      _selectedPlayerOption.value as string,
    );
  }

  function _onApplyProfileClick() {
    if (!_selectedProfileOption) {
      return;
    }

    _executorDeepDiveContext.reloadPlayerWithScoutingProfile(
      _selectedProfileOption.value as string,
    );
  }

  /*
   * Render
   */
  let _Content = null;
  function _playersGroupHeader(text: string): string {
    return t(`playerPosition.${text}s`);
  }

  if (
    _executorDeepDiveContext.mirrorringLoadingState === LOADING_STATE.LOADING
  ) {
    // Loader
    _Content = (
      <Stack spacing={6} alignItems="center" minWidth={240}>
        <Stack spacing={1} alignItems="center" textAlign="center">
          <Typography fontSize={18}>
            {t('players.performance.mirrorring.builder.syncing.header')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('players.performance.mirrorring.builder.syncing.description')}
          </Typography>
        </Stack>
        <CircularProgress />
      </Stack>
    );
  } else {
    const _copySuffix =
      _selectedType === 'scouting_profile' ? 'selectProfile' : 'selectPlayer';
    const _titleText = t(`${translationsPrefix}.${_copySuffix}.title`);
    const _descriptionText = t(
      `${translationsPrefix}.${_copySuffix}.description`,
      {name: _executorDeepDiveContext.player?.display_name},
    );

    const _buttonText = t(`${translationsPrefix}.${_copySuffix}.cta`);

    let _Dropdown = (
      <CustomAutocomplete
        options={_playerOptions}
        value={_selectedPlayerOption}
        onChange={_onPlayerSelect}
        groupHeader={_playersGroupHeader}
        placeholder={t(`${translationsPrefix}.selectPlayer.placeholder`)}
      />
    );

    if (_selectedType === 'scouting_profile') {
      _Dropdown = (
        <CustomAutocomplete
          options={_profileOptions}
          value={_selectedProfileOption}
          onChange={_onProfileSelect}
          placeholder={t(`${translationsPrefix}.selectProfile.placeholder`)}
        />
      );
    }

    _Content = (
      <Stack gap={4}>
        <Stack alignItems={'center'}>
          <SegmentedControl
            options={TYPE_OPTIONS}
            value={_selectedType}
            onChange={_onTypeChange}
          />
        </Stack>

        {/* Select player */}
        <Stack spacing={3} alignItems="center" textAlign="center">
          <Stack
            spacing={1}
            alignItems="center"
            whiteSpace="pre-line"
            maxWidth={400}>
            <Typography fontWeight={500} fontSize={18}>
              {_titleText}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {_descriptionText}
            </Typography>
          </Stack>
          {/* <Divider orientation="horizontal" /> */}
          <Box minWidth={320}>{_Dropdown}</Box>
          <Button
            onClick={
              _selectedType === 'scouting_profile'
                ? _onApplyProfileClick
                : _onApplyTrackersClick
            }
            startIcon={<SyncIcon />}
            variant="contained"
            size="large"
            disabled={
              _selectedType === 'scouting_profile'
                ? !_selectedProfileOption
                : !_selectedPlayerOption
            }>
            {_buttonText}
          </Button>
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack
      spacing={3}
      minHeight={480}
      alignItems="center"
      justifyContent="center">
      {_Content}
    </Stack>
  );
}

export default PlayerMirrorBuilder;
