import {
  ITrackerSentenceProps,
  TSentenceSize,
  TSentenceTextType,
} from 'types/trackers/tracker-sentence.types';
import React from 'react';

import Typography from '@mui/material/Typography';
import HiddenItemsPopup from './hidden-items-popup';

interface ISentenceTextSegmentProps extends ITrackerSentenceProps {
  value: string;
  type?: TSentenceTextType;
  hiddenItemsCount?: number;
  tooltipContent?: string | React.ReactNode;
  capitalize?: boolean;
  disabled?: boolean;
}

export const FONT_SIZE_MAP: Record<TSentenceSize, number> = {
  small: 14,
  medium: 16,
  large: 24,
};

export const LINE_HEIGHT_MAP: Record<TSentenceSize, number> = {
  small: 1.2,
  medium: 1.5,
  large: 1.6,
};

function SentenceTextSegment(props: ISentenceTextSegmentProps): JSX.Element {
  const _type = props.type || 'default';
  const _fontSize = FONT_SIZE_MAP[props.size];
  const _lineHeight = LINE_HEIGHT_MAP[props.size];
  const _fontWeight = _type === 'value' ? 500 : 400;
  let _color = 'text.primary';
  if (props.disabled && !props.readonly) {
    _color = 'text.disabled';
  } else if (_type === 'default') {
    _color = 'text.secondary';
  }

  return (
    <Typography
      fontWeight={_fontWeight}
      fontSize={_fontSize}
      lineHeight={_lineHeight}
      textTransform={props.capitalize ? 'capitalize' : 'none'}
      color={_color}>
      {props.value}

      {Boolean(props.hiddenItemsCount) && (
        <HiddenItemsPopup
          color="secondary.light"
          component="span"
          fontSize={_fontSize}
          lineHeight={_lineHeight}
          hiddenItemsCount={props.hiddenItemsCount || 0}
          TooltipContent={props.tooltipContent || ''}
        />
      )}
    </Typography>
  );
}

export default SentenceTextSegment;
