import {REQUEST_ERRORS} from 'config/errors.config';

import {useAPI} from 'hooks/api.hooks';
import {HTTPResponse} from 'types/api.types';
import {S3} from '@aws-sdk/client-s3';
import {Upload} from '@aws-sdk/lib-storage';
import {
  FIFileUploadPostData,
  FIUploadedFile,
  IFileUploadFilters,
  IUploadCredentials,
} from '@my-game-plan/types';

const api = useAPI();

export async function createFileDocument(
  postData: Partial<FIFileUploadPostData>,
): Promise<FIUploadedFile> {
  try {
    // const _formData = uploadPostDataToFormData(postData);

    const {data} = await api.post<FIUploadedFile>({
      data: postData,
      url: 'file-uploads',
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function getSingleFile(id: string): Promise<FIUploadedFile> {
  try {
    const URL = `file-uploads/${id}`;
    const {data} = await api.get<FIUploadedFile>({
      url: URL,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function getAllFiles(
  params?: IFileUploadFilters,
): Promise<HTTPResponse<FIUploadedFile[]>> {
  const URL = 'file-uploads/all';
  const response = await api.get<FIUploadedFile[]>({
    url: URL,
    params: params,
  });

  return response;
}

export async function editFileDocument(
  id: string,
  postData: Partial<FIUploadedFile>,
): Promise<FIUploadedFile> {
  try {
    // const _formData = uploadPostDataToFormData(postData);
    const URL = `file-uploads/${id}`;
    const {data} = await api.put<FIUploadedFile>({
      url: URL,
      data: postData,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function deleteFile(id: string): Promise<boolean> {
  try {
    const URL = `file-uploads/${id}`;
    const {data} = await api.del<boolean>({
      url: URL,
    });

    if (typeof data === 'undefined') {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function getUploadCredentials(): Promise<IUploadCredentials> {
  try {
    const _URL = 'file-uploads/keys';

    const {data} = await api.get<IUploadCredentials>({
      url: _URL,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export function generateFileUploader(
  credentials: IUploadCredentials,
  type: 'file_upload' | 'match_video_upload',
  key: string,
  file: File,
): Upload {
  /* Define bucket */
  let _bucket = '';
  let _region = process.env.REACT_APP_AWS_REGION || '';
  if (type === 'file_upload') {
    _bucket = process.env.REACT_APP_S3_FILE_UPLOAD_BUCKET || '';
  } else if (type === 'match_video_upload') {
    _bucket = process.env.REACT_APP_S3_MATCH_VIDEO_UPLOAD_BUCKET || '';
    _region = process.env.REACT_APP_AWS_MATCH_VIDEO_UPLOAD_REGION || '';
  }

  /* Init S3 client */
  const _s3Client = new S3({
    region: _region,
    credentials: {
      accessKeyId: credentials.access_key_id,
      secretAccessKey: credentials.secret_access_key,
      sessionToken: credentials.session_token,
    },
  });

  /* Create Uploader */
  const _uploader = new Upload({
    client: _s3Client,
    params: {
      Bucket: _bucket,
      Key: key,
      Body: file,
      ContentType: file.type,
    },
    partSize: 5 * 1024 * 1024,
  });

  return _uploader;
}
