import React from 'react';

import {useParams} from 'react-router-dom';

import ExecutorDeepDive from 'components/executor-deep-dive/executor-deep-dive';
import {TTrackerExecutorType} from '@my-game-plan/types';

interface IPlayerViewProps {
  executorType: TTrackerExecutorType;
}
function PlayerView(props: IPlayerViewProps) {
  const _params = useParams();
  return (
    <ExecutorDeepDive
      playerId={_params.player_id || ''}
      executorType={props.executorType}
    />
  );
}
export default PlayerView;
