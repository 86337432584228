import React, {useEffect, useState} from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import {LOADING_STATE} from 'types/screen.types';
import {FIPlayer} from '@my-game-plan/types';
import {debounce} from 'lodash';
import SearchAsyncAutocompleteOption from './search-async-autocomplete-option.view';
import {useTranslation} from 'react-i18next';
import {searchPlayersByName} from 'controllers/players.controller';
import {darken, useTheme} from '@mui/material';

interface ISearchAsyncAutocompleteProps {
  label: string;
}

function SearchAsyncAutocomplete(
  props: ISearchAsyncAutocompleteProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _theme = useTheme();

  const [_options, _setOptions] = useState<FIPlayer[]>([]);
  const [_loadingState, _setLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.SUCCESS,
  );
  const [_inputValue, _setInputValue] = useState<string>('');

  const _debouncedSearchHandler = debounce(_searchHandler, 500);
  useEffect(() => {
    return () => {
      _debouncedSearchHandler.cancel();
    };
  }, [_debouncedSearchHandler]);

  /*
   * Handlers
   */
  function _onInputChange(event: React.SyntheticEvent, newValue: string) {
    _debouncedSearchHandler(newValue);
  }

  async function _searchHandler(query: string) {
    _setInputValue(query);
    if (!query) {
      _setOptions([]);
      return;
    }
    _setLoadingState(LOADING_STATE.LOADING);
    try {
      const _fetchedPlayers = await searchPlayersByName({q: query});

      _setOptions(_fetchedPlayers || []);
      _setLoadingState(LOADING_STATE.SUCCESS);
    } catch (error) {
      _setLoadingState(LOADING_STATE.ERROR);
    }
  }

  /*
   * Render
   */
  const PAPER_BG = darken(_theme.palette.background.default, 0.2);
  return (
    <Autocomplete
      clearOnBlur={false}
      options={_options}
      onInputChange={_onInputChange}
      // Disable filtering (matching input with options)
      filterOptions={(x) => x}
      // isOptionEqualToValue={(option, value) => option._id === value._id}
      getOptionLabel={(option) => option.name}
      renderInput={(textfieldProps) => (
        <TextField
          {...textfieldProps}
          color="secondary"
          label={props.label}
          size="small"
        />
      )}
      noOptionsText={
        _inputValue
          ? t('scouting.empty.search.header')
          : t('scouting.searchForm.namePlaceholder')
      }
      loading={_loadingState === LOADING_STATE.LOADING}
      slotProps={{
        popper: {
          sx: {boxShadow: 4},
        },
        paper: {
          sx: {bgcolor: PAPER_BG, width: 320},
        },
      }}
      sx={{width: 240}}
      renderOption={(optionProps, option) => {
        return (
          <SearchAsyncAutocompleteOption player={option} key={option._id} />
        );
      }}
    />
  );
}

export default SearchAsyncAutocomplete;
