import React from 'react';
import {truncate} from 'helpers/general/general.helper';

interface Props {
  value: string;
  dy?: number;
  isTruncate?: boolean;
}

export const ChartSvgText = ({value, dy, isTruncate = true}: Props) => {
  const textProps: React.SVGProps<SVGTextElement> = {
    fill: 'var(--captions)',
    textAnchor: 'middle',
    fontSize: 12,
  };

  return (
    <text {...textProps} dy={dy}>
      {isTruncate ? truncate(value) : value}
    </text>
  );
};
