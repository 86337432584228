import i18n from 'localization';

import {
  FITrackerSubject,
  PlayerPosition,
  TTrackerExecutorType,
} from '@my-game-plan/types';
import {TIcon} from 'types/filter.types';

import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import ShieldIcon from '@mui/icons-material/Shield';
import {PALETTE_ERROR, PALETTE_SECONDARY, PALETTE_SUCCESS} from 'theme';

// import PersonSearchIcon from '@mui/icons-material/PersonSearch';

/*
 * Different types of trackers and matching title/icon
 * team_performance, player_development, opponent_analysis, scouting_profile_ids
 */
export interface ITrackerTypeConfig {
  icon: TIcon;
  title: string;
}

export const TRACKER_TYPES_CONFIG: Partial<
  Record<TTrackerExecutorType, ITrackerTypeConfig>
> = {
  team_performance: {
    icon: PeopleIcon,
    title: i18n.t('sentenceForm.executor.teamPerformance.title'),
  },
  player_development: {
    icon: PersonIcon,
    title: i18n.t('sentenceForm.executor.playerDevelopment.title'),
  },
  opponent_analysis: {
    icon: ShieldIcon,
    title: i18n.t('sentenceForm.executor.opponentAnalysis.title'),
  },
  // scouting_profile_ids: {
  //   icon: PersonSearchIcon,
  //   title: i18n.t('sentenceForm.executor.scouting.title'),
  // },
};

/*
 * DEFAULT EMPTY TRACKER VALUES
 */
export function defaultExecutorForPlayer(playerId: string): FITrackerSubject {
  return {
    executor: {
      player_id: playerId,
    },
  };
}

export function defaultExecutorForTeam(
  positions?: PlayerPosition[],
): FITrackerSubject {
  if (!positions) {
    return {
      executor: {
        team: {},
      },
    };
  }
  return {
    executor: {
      team: {
        executor_positions: positions,
      },
    },
  };
}

export const EXECUTOR_TYPES: TTrackerExecutorType[] = [
  'team_performance',
  'player_development',
  'opponent_analysis',
  // 'scouting_profile_ids',
];

export type TPercentileGroup = 'top' | 'upper' | 'average' | 'lower' | 'bottom';
export interface IPercentileGroupConfig {
  group: TPercentileGroup;
  range: [number, number];
  color: string;
  getPercentile: (score: number) => number;
}

export function getTopPercentile(score: number): number {
  const _topPercentile = Math.round((10 - score) * 10);
  return Math.max(1, _topPercentile);
}

export function getBottomPercentile(score: number): number {
  const _bottomPercentile = Math.round(score * 10);
  return Math.max(1, _bottomPercentile);
}
export const DEFAULT_PERCENTILE_COLOR = PALETTE_SECONDARY.main;
export const PERCENTILE_GROUPS_CONFIG: IPercentileGroupConfig[] = [
  {
    group: 'bottom',
    range: [0, 2],
    color: PALETTE_ERROR.main,
    getPercentile: getBottomPercentile,
  },
  {
    group: 'lower',
    range: [2, 4],
    color: PALETTE_ERROR.main,
    getPercentile: getBottomPercentile,
  },
  {
    group: 'average',
    range: [4, 6],
    color: DEFAULT_PERCENTILE_COLOR,
    getPercentile: getTopPercentile,
  },
  {
    group: 'upper',
    range: [6, 8],
    color: PALETTE_SUCCESS.main,
    getPercentile: getTopPercentile,
  },
  {
    group: 'top',
    range: [8, 10],
    color: PALETTE_SUCCESS.main,
    getPercentile: getTopPercentile,
  },
];
