import React, {useEffect, useState} from 'react';
import Alert from '@mui/material/Alert';
import {FIFormattedAutomation, TTrackerExecutorType} from '@my-game-plan/types';
import {useTranslation} from 'react-i18next';
import {useTeams} from 'context/team.context';
import {getTransferredPlayersForAutomations} from 'helpers/automation/automation.helper';

interface IAutomationWarningProps {
  data?: FIFormattedAutomation;
  type: TTrackerExecutorType;
}

function AutomationWarning(props: IAutomationWarningProps): JSX.Element | null {
  const [_hasTransferredPlayer, _setHasTransferredPlayer] =
    useState<boolean>(false);
  const {t} = useTranslation();
  const _teamsContext = useTeams();

  useEffect(() => {
    if (props.data && _teamsContext.ownPlayers.length) {
      //
      // Update state
      const _transferredPlayers = getTransferredPlayersForAutomations(
        props.data,
        _teamsContext.ownPlayers,
      );

      _setHasTransferredPlayer(Boolean(_transferredPlayers.length));
    }
  }, [props.data, _teamsContext.ownPlayers]);

  if (
    !_hasTransferredPlayer ||
    props.type === 'opponent_analysis' ||
    props.type === 'scouting_profile_ids'
  )
    return null;

  return (
    <Alert severity="warning">
      {t('eventAutomations.transferredPlayerWarning')}
    </Alert>
  );
}

export default AutomationWarning;
