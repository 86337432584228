import {useAPI} from 'hooks/api.hooks';
import {HTTPResponse, Resource} from 'types/api.types';
import {PLAYERS_API_CONFIG} from 'config/api.config';
import {
  FIAutomationDataQueryParams,
  FIPerformanceOverview,
  FIPlayer,
  FIPlayerOverview,
  FIPlayerSearchFilters,
  FIShadowPlayerPostData,
  FITrackersOverview,
  IPlayerSearchQuery,
} from '@my-game-plan/types';
import {REQUEST_ERRORS} from 'config/errors.config';

const api = useAPI();

export const getPlayersByTeam = async (
  _team: string | undefined,
): Promise<FIPlayer[]> => {
  try {
    const {data} = await api.get<FIPlayer[]>({
      resource: Resource.players,
      url: PLAYERS_API_CONFIG.getPlayersByTeam(_team),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch {
    return new Promise<FIPlayer[]>((res) => res([]));
  }
};

/*
 * Search
 */

export async function searchPlayersByName(
  query: IPlayerSearchQuery,
): Promise<FIPlayer[]> {
  try {
    const _URL = 'players/search';
    const {data} = await api.get<FIPlayer[]>({
      url: _URL,
      params: query,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

/*
 * Search - Get recent searches
 */
export async function getScoutedPlayers(
  teamID: string,
  filters?: FIPlayerSearchFilters,
): Promise<HTTPResponse<FITrackersOverview>> {
  try {
    const _response = await api.get<FITrackersOverview>({
      resource: Resource.players,
      url: PLAYERS_API_CONFIG.scoutedPlayers(teamID),
      params: filters,
    });

    return _response;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

/*
 * Scouting: get performance info
 */
export async function getScoutedPlayerPerfornance(
  postData: FIShadowPlayerPostData,
): Promise<FIPerformanceOverview> {
  try {
    const _URL = 'scouting/players/mirror';
    const {data} = await api.post<FIPerformanceOverview>({
      url: _URL,
      data: postData,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function stopMirroringPlayer(playerId: string): Promise<boolean> {
  try {
    const _URL = `scouting/players/mirror/${playerId}`;
    const {data} = await api.del<boolean>({
      url: _URL,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

/*
 * Countries
 */
export async function getPlayerCountries(): Promise<string[]> {
  try {
    const {data} = await api.get<string[]>({
      resource: Resource.players,
      url: PLAYERS_API_CONFIG.getCountries(),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

/*
 * Player overview
 * - Player info
 * - Performance data
 * - Physical data
 */
export async function getPlayerOverview(
  playerId: string,
  filters?: FIAutomationDataQueryParams,
): Promise<FIPlayerOverview> {
  try {
    const _URL = `players/${playerId}/overview`;
    const {data} = await api.get<FIPlayerOverview>({
      url: _URL,
      params: filters,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}
