import {ITypedOption} from 'types/option.types';
import {
  FICompetition,
  FICompetitionSeason,
  TCompetitionGroup,
} from '@my-game-plan/types';
import {getCountryFlagImage} from './general/general.helper';

export function competitionsToTypedOptions(
  competitions: FICompetition[],
  domesticCountryCode?: string,
): ITypedOption<number>[] {
  const _competitionDropdownOptions: ITypedOption<number>[] = [];

  sortCompetitions(competitions);
  competitions.forEach((competition) => {
    let _group: TCompetitionGroup = 'other';

    if (competition.country_code === domesticCountryCode) {
      _group = 'domestic';
    } else if (competition.type === 'international_cup') {
      _group = 'international';
    }
    _competitionDropdownOptions.push({
      name: `${competition.name} ${competition.country_code || ''}`,
      label: competition.name,
      value: competition._id,
      group: _group,
      ...(competition.country_code && {
        image_url: getCountryFlagImage(competition.country_code),
      }),
    });
  });

  return _competitionDropdownOptions;
}

export function sortCompetitions(competitions: FICompetition[]) {
  competitions.sort((a, b) => {
    // String comparison with fallback to 'friendly'
    const countryCodeComparison = (a.country_code || 'friendly').localeCompare(
      b.country_code || 'friendly',
    );

    const typeComparison = (b.type || 'aa').localeCompare(a.type || 'aa'); // 'domestic_league' before 'domestic_cup' before 'aa'

    // Numeric comparison for tier
    const tierComparison = a.tier - b.tier;

    // Combine comparisons
    return countryCodeComparison || typeComparison || tierComparison;
  });
}

export function competitionSeasonsToTypedOptions(
  competitionSeasons: FICompetitionSeason[],
): ITypedOption<number>[] {
  const _competitionSeasonsDropdownOptions: ITypedOption<number>[] = [];

  competitionSeasons.forEach((competitionSeason) => {
    _competitionSeasonsDropdownOptions.push({
      label:
        competitionSeason.competition_id.toString() +
        '-' +
        competitionSeason.season_id.toString(),
      name: competitionSeason._id.toString(),
      value: competitionSeason._id,
      group: 'domestic-cup-international',
    });
  });

  return _competitionSeasonsDropdownOptions;
}
