import {ITrackerSentenceProps} from 'types/trackers/tracker-sentence.types';
import React from 'react';
import SentenceCondition from './condition';
import {FIEventCondition} from '@my-game-plan/types';

function SentenceRuleCondition(
  props: ITrackerSentenceProps,
): JSX.Element | null {
  /*
   * Handlers
   */
  function _onConditionChange(
    conditionIndex: number,
    data: Partial<FIEventCondition>,
  ) {
    if (!props.onChange || !props.tracker?.rule_condition) {
      return;
    }

    const _newConditionData = {
      ...props.tracker.rule_condition,
      ...data,
    };
    props.onChange({rule_condition: _newConditionData});
  }

  /*
   * Render
   */
  if (!props.tracker?.rule_condition) {
    return null;
  }

  return (
    <SentenceCondition
      {...props}
      condition={props.tracker?.rule_condition}
      conditionIndex={-1}
      onChange={_onConditionChange}
    />
  );
}

export default SentenceRuleCondition;
