import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {useDraggable} from 'muuri-react';
import React from 'react';
import styles from './PlaceholderWidget.module.scss';
import {useDragAndDrop} from 'context/drag-and-drop.context';

export const EmptyPlaceholderWidget = () => {
  const _dragAndDrop = useDragAndDrop();
  const setDraggable = useDraggable();

  // Disable dragging for this item.
  setDraggable(false);

  return (
    <div style={_dragAndDrop}>
      <div className={`${styles.widget} ${styles.emptyWidget}`}>
        <AddCircleOutlineIcon fontSize="large" />
      </div>
    </div>
  );
};
