import React, {useState} from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import {useTranslation} from 'react-i18next';
import {IDashboardScore} from 'types/dashboard/dashboard-score.types';
import Indicator from '../Indicator';
import styles from './IndicatorPopup.module.scss';
import {getMinMaxScores} from 'helpers/dashboard/dashboard.helper';

interface IndicatorPopupProps {
  openModal: () => void;
  scores: IDashboardScore[];
}

export const IndicatorPopup = ({openModal, scores}: IndicatorPopupProps) => {
  /*
   * Hooks n State
   */
  const [_anchorEl, _setAnchorEl] = useState<HTMLElement | null>(null);
  const {t} = useTranslation();

  /*
   * Handlers
   */
  function _onClick(event: React.MouseEvent<HTMLButtonElement>) {
    _setAnchorEl(event.currentTarget);
  }

  function _onClose() {
    _setAnchorEl(null);
  }

  function _openModal() {
    _onClose();
    openModal();
  }

  /*
   * Render
   */
  const _isOpened = Boolean(_anchorEl);
  return (
    <>
      <IconButton
        color="secondary"
        title={t('dashboard.scores.view')}
        onClick={_onClick}
        size="small"
        disabled={!scores.length}>
        <InfoIcon />
      </IconButton>
      <Popover
        anchorEl={_anchorEl}
        open={_isOpened}
        onClose={_onClose}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}>
        <IndicatorPopupContent openModal={_openModal} scores={scores} />
      </Popover>
    </>
  );
};

interface IndicatorPopupContentProps {
  openModal: () => void;
  scores: IDashboardScore[];
}

const IndicatorPopupContent = ({
  openModal,
  scores,
}: IndicatorPopupContentProps) => {
  const {t} = useTranslation();
  const {min, max} = getMinMaxScores(scores);

  return (
    <div className={styles.container}>
      {scores[0].subscores && (
        <Button
          onClick={openModal}
          variant="contained"
          className={styles.button}>
          {t('dashboard.scores.buttons.see-details')}
        </Button>
      )}

      {scores.map((score, i) => (
        <Indicator
          key={i}
          value={score.score}
          min={min}
          max={max}
          teamId={score.team._id}
          scores={scores}
          valueDisplay="score"
        />
      ))}
    </div>
  );
};
