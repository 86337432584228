import {
  ExecutorDeepDiveProvider,
  IExecutorDeepDiveProps,
  useExecutorDeepDive,
} from 'context/executor-deep-dive.context';
import React, {useEffect, useState} from 'react';
import Screen from '../screen/screen.view';
import HeaderFilterBar from '../filter/header-filter-bar/header-filter-bar.view';
import PlayerDeepDiveHeader from './header/player-deep-dive-header';
import TeamDeepDiveHeader from './header/team-deep-dive-header';
import {INavigationRoute} from 'types/navigation.types';
import {useTranslation} from 'react-i18next';
import {Navigate, Route, Routes} from 'react-router-dom';
import ExecutorPerformanceTab from './performance/executor-performance-tab';
import ExecutorPhysicalTab from './physical/executor-physical-tab';
import ExecutorObjectivesTab from './objectives/executor-objectives-tab';

function ExecutorDeepDiveWithoutProvider(): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _executorDeepDiveContext = useExecutorDeepDive();
  const [_tabs, _setTabs] = useState<INavigationRoute[]>([]);

  /*
   * Side effects
   */
  useEffect(() => {
    //
    const _generatedTabs: INavigationRoute[] = [
      {
        label: t('players.performance.title'),
        path: '',
      },
    ];

    if (_executorDeepDiveContext.hasPhysicalData) {
      _generatedTabs.push({
        label: t('players.physical.title'),
        path: 'physical',
      });
    }

    if (_executorDeepDiveContext.executorType === 'player_development') {
      _generatedTabs.push({
        label: t('playerObjectives.objectives'),
        path: 'objectives',
      });
    }

    _setTabs(_generatedTabs);
  }, [
    _executorDeepDiveContext.executorType,
    _executorDeepDiveContext.hasPhysicalData,
  ]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  let _HeaderContent = <TeamDeepDiveHeader />;

  if (_executorDeepDiveContext.isPlayerPage) {
    _HeaderContent = <PlayerDeepDiveHeader />;
  }

  return (
    <Screen
      coloredHeader
      loadingState={_executorDeepDiveContext.loadingState}
      tabs={_tabs.length ? _tabs : undefined}
      backLink={_executorDeepDiveContext.backLinkState || undefined}
      HeaderContent={_HeaderContent}
      HeaderBottomContent={
        <HeaderFilterBar
          benchmark={{
            benchmarkOptions:
              _executorDeepDiveContext.benchmarkAndDateFilters?.benchmark,
            onBenchmark: _executorDeepDiveContext.onBenchmarkFilterChange,
          }}
          timeFilters={{
            filters:
              _executorDeepDiveContext.benchmarkAndDateFilters?.own_data?.time,
            onFiltersChange: _executorDeepDiveContext.onDateFilterChange,
          }}
          trackerInfo={{
            isObservingPlayer: _executorDeepDiveContext.isPlayerPage,
          }}
          screen={_executorDeepDiveContext.screenName}
        />
      }>
      <Routes>
        <Route path="" element={<ExecutorPerformanceTab />} />
        <Route path="physical" element={<ExecutorPhysicalTab />} />
        <Route path="objectives" element={<ExecutorObjectivesTab />} />

        {/* Redirect */}
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </Screen>
  );
}

function ExecutorDeepDive(props: IExecutorDeepDiveProps): JSX.Element {
  return (
    <ExecutorDeepDiveProvider {...props}>
      <ExecutorDeepDiveWithoutProvider />
    </ExecutorDeepDiveProvider>
  );
}

export default ExecutorDeepDive;
