import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Brush,
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import {
  FIAutomationPlayerMatch,
  FIFormattedAutomation,
  FIMatchEvent,
  TCompetitionType,
} from '@my-game-plan/types';

import {
  getCompactTooltip,
  getTooltip,
  getXAxisTick,
} from './TrackerTrend.helper';
import styles from './TrackerTrend.module.scss';

import {getOpponent, toPercentage} from 'helpers/general/general.helper';
import {useAuth} from 'context/auth.context';
import {useAutomationDetail} from 'context/event-automations/automation-detail.context';

import Slider from '@mui/material/Slider';

import {cloneDeep} from 'lodash';
import {getAvailableSourcesForMatch} from 'helpers/video.helper';

interface TrackerTrendProps {
  tracker: FIFormattedAutomation;
  events?: FIMatchEvent[];
  height?: number;
  tooltipAppearance?: 'default' | 'compact';
  displayScales?: boolean;
  displayReferenceLine?: boolean;
  displayBrush?: boolean;
  observingMatchIds?: string[];
  observingTeamId?: string;
  isOnlyShowingObjectiveEvents?: boolean;
}

const SLIDER_MATCH_LIMIT = 20;
const DEFAULT_MATCHES_TO_DISPLAY = 18;

export const TrackerTrend = ({
  tracker,
  events,
  height = 400,
  tooltipAppearance = 'default',
  displayScales = false,
  displayReferenceLine = false,
  displayBrush = false,
  observingMatchIds,
  observingTeamId,
  isOnlyShowingObjectiveEvents,
}: TrackerTrendProps) => {
  const {t} = useTranslation();

  const {user} = useAuth();

  const [_filteredData, _setFilteredData] = useState<
    (null | FIAutomationPlayerMatch)[]
  >([]);
  const [_history, _setHistory] = useState<FIAutomationPlayerMatch[]>([]);
  const [_benchmarkAverage, _setBenchmarkAverage] = useState<
    undefined | number
  >(undefined);
  const [sliderValues, setSliderValues] = useState([0, 0]);
  const [_chartKey, _setChartKey] = useState<number>(0);
  const _shouldDisplaySlider =
    _filteredData.length > SLIDER_MATCH_LIMIT ? displayBrush : null;

  const _automationDetailContext = useAutomationDetail();

  useEffect(() => {
    const _firstPlayer = tracker.players?.[0];
    let _newHistory: FIAutomationPlayerMatch[] = [];
    if (_firstPlayer?.history) {
      _newHistory = _firstPlayer.history;
      _newHistory.sort((a, b) => {
        return (
          new Date(a.match.date).getTime() - new Date(b.match.date).getTime()
        );
      });
    }

    _setHistory(_newHistory);
  }, [tracker]);

  useEffect(() => {
    _setBenchmarkAverage(_automationDetailContext.benchmarkAverage);
  }, [_automationDetailContext.benchmarkAverage]);

  useEffect(() => {
    const _slicedHistory: (FIAutomationPlayerMatch | null)[] =
      cloneDeep(_history);

    if (_slicedHistory.length > 1 && _slicedHistory.length < 5) {
      const _diff = 5 - _slicedHistory.length;

      for (let _i = 0; _i < _diff; _i++) {
        _slicedHistory.push(null);
      }
    }

    let _earliestCheckedMatchIndex: number | undefined = undefined;

    if (observingMatchIds?.length) {
      _slicedHistory.forEach((match, index) => {
        if (match && !observingMatchIds.includes(match.match?._id)) {
          // If the match ID is not in observingMatchIds, modify the match fields
          match.did_play = false;
          match.score = undefined;
          match.total_events = undefined;
          match.total_successful_events = undefined;
        } else if (_earliestCheckedMatchIndex === undefined) {
          // Set the earliest match index where the match is in observingMatchIds
          _earliestCheckedMatchIndex = index;
        }
      });
    }

    _setFilteredData(_slicedHistory);

    const _lastValue = _slicedHistory.length - 1;
    if (
      observingMatchIds?.length &&
      _earliestCheckedMatchIndex &&
      observingMatchIds?.length < SLIDER_MATCH_LIMIT
    ) {
      setSliderValues([_earliestCheckedMatchIndex, _lastValue]);
    } else {
      setSliderValues(
        _slicedHistory.length > SLIDER_MATCH_LIMIT
          ? [_slicedHistory.length - DEFAULT_MATCHES_TO_DISPLAY, _lastValue]
          : [0, _lastValue],
      );
    }
  }, [_history, observingMatchIds]);

  useEffect(() => {
    _setChartKey((previousKey) => previousKey + 1);
  }, [sliderValues]);

  const _isRelative =
    tracker.calculation === 'ratio' || tracker.calculation === 'rule';

  // TODO remove this events fetching and passing down to TrackerDetailVideos and TrackerDetailVideo when the backend returns the trackers.history as a map with events grouped by match
  const getEventsForMatch = (matchId: string) => {
    if (!events) return [];

    // Filter events based on objective events if required
    const filteredEvents = events.filter((event) => {
      if (isOnlyShowingObjectiveEvents) {
        return Boolean(event.successful);
      }
      return true;
    });

    // Further filter by match ID
    const matchEvents = filteredEvents.filter(
      (event) => event.match._id === matchId,
    );

    return matchEvents;
  };

  const handleTooltip = ({
    active,
    payload,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      const event = payload?.[0]?.payload as FIAutomationPlayerMatch;

      switch (tooltipAppearance) {
        case 'compact':
          return getCompactTooltip(event);
        default:
          return getTooltip(
            observingTeamId,
            _isRelative,
            event,
            _automationDetailContext.isSinglePlayerTracker,
            tracker.metric,
          );
      }
    }

    return null;
  };

  const getMatchCompetitionType = (matchId: string): TCompetitionType => {
    const _firstPlayer = tracker.players?.[0];
    if (_firstPlayer && user) {
      const _history = _firstPlayer.history;
      const _match = _history.find((event) => event.match._id === matchId);

      if (_match) {
        return _match.match.competition_type;
      }
    }

    return 'domestic_league';
  };
  const getMatchOpponentImage = (matchId: string): string => {
    const _firstPlayer = tracker.players?.[0];
    if (_firstPlayer && user) {
      const _history = _firstPlayer.history;
      const event = _history.find((event) => event.match._id === matchId);
      return event
        ? getOpponent(event.own_team_id, event.match)?.image_url || ''
        : '';
    }
    return '';
  };

  const getMatchDate = (matchId: string) => {
    const _firstPlayer = tracker.players?.[0];
    if (_firstPlayer) {
      const _history = _firstPlayer.history;
      const event = _history.find((event) => event.match._id === matchId);
      return event ? event.match.date : undefined;
    }
    return undefined;
  };

  const handleClick = ({payload}: any) => {
    const event = payload as FIAutomationPlayerMatch;
    const _availableSources = getAvailableSourcesForMatch(event.match.video);
    if (_availableSources.length)
      _automationDetailContext.openVideoPlayer(
        getEventsForMatch(event.match._id),
      );
  };

  if (_filteredData.length <= 0) {
    const _emptyMessageKey = 'eventAutomations.trend.noDataFor';

    return (
      <div className={styles.emptyTrend} style={{height}}>
        {t(_emptyMessageKey)}
      </div>
    );
  }

  return (
    <>
      <div className={styles.trend}>
        <ResponsiveContainer width="99%" height={height}>
          <LineChart
            key={_chartKey}
            data={_filteredData}
            margin={
              displayBrush
                ? {top: 16, bottom: 8, right: 16}
                : {top: 8, bottom: 8, left: 5, right: 5}
            }>
            {/* Scales */}
            <CartesianGrid
              stroke="var(--hover)"
              opacity={0.5}
              vertical={false}
              strokeDasharray={4}
            />
            {displayScales && (
              <>
                <YAxis
                  stroke="var(--captions)"
                  axisLine={false}
                  tickLine={false}
                  tickMargin={16}
                  tick={{fontSize: 12}}
                  domain={_isRelative ? [0, 1] : undefined}
                  tickFormatter={(tick) =>
                    _isRelative ? toPercentage(tick) : tick
                  }
                />
                <XAxis
                  dataKey="match._id"
                  stroke="var(--captions)"
                  height={_filteredData.length > SLIDER_MATCH_LIMIT ? 48 : 96}
                  axisLine={false}
                  tickLine={false}
                  tickMargin={32}
                  minTickGap={-_filteredData.length}
                  tick={(tick) => {
                    return getXAxisTick(
                      tick,
                      getMatchOpponentImage(tick.payload.value),
                      getMatchDate(tick.payload.value),
                      sliderValues[1] - sliderValues[0],
                      observingMatchIds?.includes(tick.payload.value),
                      getMatchCompetitionType(tick.payload.value),
                    );
                  }}
                  padding={{left: 0, right: 16}}
                  interval={0}
                  domain={sliderValues}
                />
              </>
            )}

            {/* Tooltip */}
            <Tooltip
              cursor={{stroke: 'var(--hover)', strokeWidth: 2}}
              content={handleTooltip}
              wrapperStyle={{outline: 'none'}}
            />

            {/* Target line */}
            {/* {tracker.target && displayReferenceLine && (
              <ReferenceLine
                ifOverflow="extendDomain"
                y={tracker.target}
                stroke="var(--light-green)"
                strokeWidth={1}
                opacity={0.4}
                strokeDasharray={4}>
                <Label
                  position="insideBottomRight"
                  fontSize={8}
                  // offset={}
                  fontWeight={600}
                  opacity={0.6}
                  fill="var(--light-green)">
                  {t('trackers.performance.target').toUpperCase()}
                </Label>
              </ReferenceLine>
            )} */}

            {displayReferenceLine && typeof _benchmarkAverage !== 'undefined' && (
              <ReferenceLine
                ifOverflow="extendDomain"
                y={_benchmarkAverage}
                stroke="var(--warning)"
                strokeWidth={1}
                opacity={0.4}>
                <Label
                  position="insideBottomRight"
                  fontSize={8}
                  fontWeight={600}
                  opacity={0.6}
                  fill="var(--warning)">
                  {t('eventAutomations.benchmark.benchmark').toUpperCase()}
                </Label>
              </ReferenceLine>
            )}

            {/* Line last so its rendered on top of the reference line and axis lines */}
            <Line
              dataKey="score"
              type="linear"
              stroke="white"
              strokeWidth={1}
              animationDuration={1000}
              activeDot={
                events && {
                  r: 6,
                  onClick: (_, payload) => handleClick(payload),
                  cursor: 'pointer',
                }
              }
            />
            {displayBrush && _filteredData.length > SLIDER_MATCH_LIMIT && (
              <>
                <Brush
                  dataKey="match.match_day"
                  height={0}
                  stroke="none"
                  fill="transparent"
                  startIndex={sliderValues[0]}
                  endIndex={sliderValues[1]}
                  className={styles.brush}
                />
              </>
            )}
          </LineChart>
        </ResponsiveContainer>
        {_shouldDisplaySlider && (
          <>
            <p className={styles.showingMatches}>
              {t('eventAutomations.trend.showingMatchday', {
                matchday1: _history[sliderValues[0]]?.match.match_day,
                matchday2: _history[sliderValues[1]]?.match.match_day,
              })}
            </p>
            <Slider
              sx={{
                mt: -1,
                // py: 0.5,
                '& .MuiSlider-valueLabel': {
                  backgroundColor: 'background.paper',
                  padding: '6px 12px',
                },
              }}
              color="secondary"
              size="small"
              value={sliderValues}
              onChange={(event, newValues) => {
                setSliderValues(newValues as number[]);
              }}
              valueLabelDisplay="auto"
              min={0}
              max={_filteredData.length - 1}
              step={1}
              valueLabelFormat={(value) => {
                const _matchDay = _history[value].match?.match_day;
                return _matchDay;
                // return `${value + 1}`;
              }}
            />
          </>
        )}
      </div>
    </>
  );
};
