import {
  FIMatch,
  FIMatchEvent,
  FISingleSend,
  FISingleSendPostData,
} from '@my-game-plan/types';

import {useAPI} from 'hooks/api.hooks';
import {Resource} from 'types/api.types';
import {AUTOMATION_API_CONFIG} from 'config/api.config';
import {REQUEST_ERRORS} from 'config/errors.config';

const api = useAPI();

export const getSingleSends = async (): Promise<FISingleSend[]> => {
  try {
    const URL = 'single-sends/all';
    const {data} = await api.get<FISingleSend[]>({
      url: URL,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch {
    return [];
  }
};

export const getSingleSend = async (id: string): Promise<FISingleSend> => {
  try {
    const {data} = await api.get<FISingleSend>({
      resource: Resource.single_sends,
      url: AUTOMATION_API_CONFIG.getOne(id),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch {
    throw Error;
  }
};

export interface ISingleSendsEventsResponse {
  events: FIMatchEvent[];
  matches: FIMatch[];
}
export const fetchEvents = async (
  id: string,
): Promise<ISingleSendsEventsResponse> => {
  try {
    const {data} = await api.get<ISingleSendsEventsResponse>({
      resource: Resource.single_sends,
      url: AUTOMATION_API_CONFIG.getEvents(id),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error('Error fetching SingleSend events');
  }
};

export const addSingleSend = async (
  singleSend: FISingleSendPostData,
): Promise<FISingleSend | undefined> => {
  try {
    const {data} = await api.post<FISingleSend>({
      resource: Resource.single_sends,
      url: AUTOMATION_API_CONFIG.addOne,
      data: singleSend,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error('Error adding SingleSend');
  }
};

export const editSingleSend = async (
  id: string,
  singleSend: FISingleSendPostData,
): Promise<FISingleSend> => {
  try {
    const {data} = await api.put<FISingleSend>({
      resource: Resource.single_sends,
      url: AUTOMATION_API_CONFIG.editOne(id),
      data: singleSend,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error('Error adding SingleSend');
  }
};

export const deleteSingleSend = async (id: string): Promise<void> => {
  try {
    const {data} = await api.del<void>({
      resource: Resource.single_sends,
      url: AUTOMATION_API_CONFIG.deleteOne(id),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error('Error adding SingleSend');
  }
};
