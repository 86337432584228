import React from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

import {LOADING_STATE} from 'types/screen.types';
import {SxProps} from '@mui/material';
import {Theme} from '@mui/system';
import {getCountryFlagImage} from 'helpers/general/general.helper';

import {useExecutorDeepDive} from 'context/executor-deep-dive.context';
import PlayerDeepDiveHeaderActions from './player-deep-dive-header-actions';

const AVATAR_SIZE = 64;

const avatarStyle: SxProps<Theme> = {
  width: AVATAR_SIZE,
  height: AVATAR_SIZE,
};

function PlayerDeepDiveHeader() {
  /*
   * Hooks n State
   */

  const _executorDeepDiveContext = useExecutorDeepDive();

  /*
   * Handlers
   */

  /*
   * Render
   */
  let _Content = null;

  if (
    (_executorDeepDiveContext.loadingState === LOADING_STATE.SUCCESS ||
      _executorDeepDiveContext.loadingState === LOADING_STATE.LOADING) &&
    _executorDeepDiveContext.player
  ) {
    let _BadgeAvatarContent = null;

    if (_executorDeepDiveContext.player.country_code) {
      _BadgeAvatarContent = (
        <Box
          position="absolute"
          right={0}
          bottom={0}
          width={20}
          overflow="hidden"
          boxShadow={1}
          borderRadius={0.5}>
          <img
            style={{maxWidth: '100%', display: 'block'}}
            src={getCountryFlagImage(
              _executorDeepDiveContext.player.country_code,
            )}
          />
        </Box>
      );
    }
    _Content = (
      <>
        {/* Basic player info */}
        <Stack direction="row" gap={2} alignItems="center" position="relative">
          <Box position="relative">
            <Avatar
              sx={{
                ...avatarStyle,
                borderColor: 'secondary.main',
                borderWidth: 3,
                borderStyle: 'solid',
                bgcolor: 'secondary.light',
              }}
              src={_executorDeepDiveContext.player.image_url}
            />
            {_BadgeAvatarContent}
          </Box>
          <Stack gap={1}>
            {Boolean(_executorDeepDiveContext.player.first_name) && (
              <Typography fontWeight={400} lineHeight={1}>
                {_executorDeepDiveContext.player.first_name}
              </Typography>
            )}
            <Typography fontWeight={600} fontSize={24} lineHeight={1}>
              {_executorDeepDiveContext.player.last_name ||
                _executorDeepDiveContext.player.display_name}
            </Typography>
          </Stack>
          <Typography
            lineHeight={1}
            fontSize={64}
            color="secondary.main"
            fontWeight={900}
            sx={{opacity: 0.2}}
            position={'absolute'}
            right={-24}
            top={0}>
            {_executorDeepDiveContext.player.jersey_num}
          </Typography>
        </Stack>

        {/* Stats */}
        <Stack direction="row" gap={4}>
          {/* More actions */}
          <PlayerDeepDiveHeaderActions />
        </Stack>
      </>
    );
  } else if (_executorDeepDiveContext.loadingState === LOADING_STATE.INITING) {
    _Content = (
      <>
        <Stack direction="row" gap={2}>
          <Skeleton variant="circular" sx={avatarStyle} />
          <Skeleton variant="text" sx={{width: 120}} />
        </Stack>
      </>
    );
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%">
      {_Content}
    </Stack>
  );
}

export default PlayerDeepDiveHeader;
