import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {translateAction} from 'helpers/translation.helper';
import {IDashboardScore} from 'types/dashboard/dashboard-score.types';
import {IndicatorInformationModal} from '../indicator-information-modal/IndicatorInformationModal';
import {IndicatorTable} from '../indicator-table/IndicatorTable';
import {getScoreIndicatorModalWidth} from 'helpers/dashboard/dashboard.helper';
import {capitalize} from 'lodash';
import {useTeams} from 'context/team.context';
import CustomEmptyState from 'components/error-states/CustomEmptyState';

interface IndicatorModalProps {
  isOpen: boolean;
  closeModal: () => void;
  scores: IDashboardScore[];
  action?: string;
  matchLimit?: number;
}

export const IndicatorModal = ({
  isOpen,
  closeModal,
  scores,
  matchLimit,
  action,
}: IndicatorModalProps) => {
  /*
   * Hooks n State
   */
  const [_scores, _setScores] = useState<IDashboardScore[]>([]);
  const {t} = useTranslation();
  const _teamsContext = useTeams();

  /* Add missing team images */
  useEffect(() => {
    const _formattedScores = scores.map((score) => {
      const _matchingTeamFromContext = _teamsContext.all.find(
        (team) => team._id === score.team._id,
      );

      if (_matchingTeamFromContext) {
        return {
          ...score,
          team: {
            ...score.team,
            image_url: _matchingTeamFromContext.image_url,
          },
        };
      }

      return score;
    });

    _setScores(_formattedScores);
  }, [scores, _teamsContext.all]);

  /*
   * Handlers
   */
  const getModalTitle = () => {
    const translatedAction = action ? capitalize(translateAction(action)) : '';
    const matches =
      matchLimit === 1
        ? `(${matchLimit} ${t('dashboard.scores.table.match')})`
        : `(${matchLimit} ${t('dashboard.scores.table.matches')})`;

    return (
      <>
        {translatedAction} {matches}{' '}
        {scores?.length && (
          <IndicatorInformationModal score={scores[0]} action={action} />
        )}
      </>
    );
  };

  function _onClose() {
    closeModal();
  }

  /*
   * Render
   */

  const modalWidth = getScoreIndicatorModalWidth(scores[0]);
  return (
    <Dialog fullWidth open={isOpen} maxWidth={modalWidth} onClose={_onClose}>
      <DialogTitle>{getModalTitle()}</DialogTitle>
      <DialogContent>
        {!scores.length ? (
          <CustomEmptyState header={t('dashboard.scores.error.noData')} />
        ) : (
          <IndicatorTable scores={_scores} action={action} />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={_onClose}>
          {t('general.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
