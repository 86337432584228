import React from 'react';
import {useTheme, alpha} from '@mui/material/styles';

import {
  FIShotAnalysis,
  ShotCategory,
  TShotAnalysisResult,
  TShotAnalysisTeam,
} from '@my-game-plan/types';
import {
  Bar,
  Tooltip,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Scatter,
  XAxis,
  YAxis,
  TooltipProps,
  Legend,
  LabelList,
} from 'recharts';

import {translateValue} from 'helpers/translation.helper';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import {
  roundAndFormatNumber,
  roundNumber,
} from 'helpers/general/general.helper';
import {useTeams} from 'context/team.context';
import {useShotAnalysis} from 'context/shot-analysis.context';

import styles from './shot-analysis-bar-chart.module.scss';

interface IShotAnalysisBarChartProps {
  data: FIShotAnalysis | null;
  team: TShotAnalysisTeam;
  result: TShotAnalysisResult;
  activeInsightCategory?: ShotCategory | 'total';
  onCategoryClick: (category?: ShotCategory) => void;
}

function ShotAnalysisBarChart(
  props: IShotAnalysisBarChartProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {palette} = useTheme();
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const _shotAnalysisContext = useShotAnalysis();

  const _teamColor =
    props.team === 'created'
      ? // ? darken(palette.primary.main, 0.2)
        // : darken(palette.warning.main, 0.2);
        palette.primary.main
      : palette.warning.main;
  const _benchmarkColor =
    props.team === 'created'
      ? alpha(palette.primary.dark, 0.3)
      : alpha(palette.warning.dark, 0.3);
  /*
   * Handlers
   */
  function _handleTooltip({
    active,
    payload,
  }: TooltipProps<ValueType, NameType>) {
    if (active && payload?.length && _teamsContext.ownTeam) {
      const _teamValue = payload[1].value as number;
      const _benchmarkValue = roundNumber((payload[0].value as number) || 0, 2);

      const _diff = roundNumber(_teamValue - _benchmarkValue, 2);
      const _diffText =
        _diff < 0
          ? 'shotAnalysis.benchmark.belowAverage'
          : 'shotAnalysis.benchmark.aboveAverage';

      let _color = palette.success.main;

      if (
        (props.team === 'created' && _diff < 0) ||
        (props.team === 'conceded' && _diff > 0)
      ) {
        _color = palette.error.main;
      }

      return (
        <Card elevation={4}>
          <Stack p={2} spacing={0.5}>
            {/* Diff */}
            <Stack direction="row" alignItems="end" gap={0.5}>
              <Typography fontSize={20} fontWeight="700" color={_color}>
                {roundAndFormatNumber(Math.abs(_diff))}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight="700"
                lineHeight={1.9}
                color={_color}>
                {t(_diffText)}
              </Typography>
            </Stack>

            {/* Team */}
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={0.5}>
              <Box
                width={12}
                height={12}
                borderRadius={6}
                bgcolor={_benchmarkColor}
              />
              <Typography
                color="text.secondary"
                fontSize={
                  14
                }>{`${_shotAnalysisContext.benchmarkLabel}:`}</Typography>
              <Typography fontWeight="600" fontSize={14}>
                {t(`shotAnalysis.result.${props.result}Count`, {
                  count: _benchmarkValue,
                  countFormatted: roundAndFormatNumber(_benchmarkValue),
                })}
              </Typography>
            </Box>

            {/* Benchmark */}
            <Box
              display="flex"
              flexDirection="row"
              gap={0.5}
              alignItems="center">
              <Box
                width={12}
                height={12}
                borderRadius={6}
                bgcolor={_teamColor}
              />
              <Typography
                color="text.secondary"
                fontSize={14}>{`${_teamsContext.ownTeam.name}:`}</Typography>
              <Typography fontWeight="600" fontSize={14}>
                {t(`shotAnalysis.result.${props.result}Count`, {
                  count: _teamValue,
                  countFormatted: roundAndFormatNumber(_teamValue),
                })}
              </Typography>
            </Box>
          </Stack>
        </Card>
      );
    }

    return null;
  }

  function _renderDiffLabel(
    x: number,
    y: number,
    value: number,
    index: number,
  ) {
    const _shotCategory = props.data?.shot_categories[index];
    const _matchedInsight = _shotAnalysisContext.insights.find(
      (insight) =>
        _shotCategory?.shot_category &&
        insight.shot_category === _shotCategory.shot_category,
    );

    // const _color = value < 0 ? palette.error.main : palette.success.main;
    let _color = palette.text.secondary;

    if (
      (value < 0 && props.team === 'created') ||
      (value >= 0 && props.team === 'conceded')
    ) {
      _color = palette.error.main;
    } else if (
      (value >= 0 && props.team === 'created') ||
      (value < 0 && props.team === 'conceded')
    ) {
      _color = palette.success.main;
    }

    const _diff = roundAndFormatNumber(Math.abs(value), 2);
    const _diffSymbol = value < 0 ? '-' : '+';

    return (
      <g transform={`translate(${x + 10},${y + 12})`}>
        <text
          x={0}
          y={0}
          fontSize={12}
          fill={_color}
          opacity={_matchedInsight ? 1 : 0.4}
          fontWeight={600}
          // textAnchor="left"
          dominantBaseline="middle">
          {`${_diffSymbol}${_diff}`}
        </text>
      </g>
    );
  }

  /*
   * Render
   */
  if (!props.data) return null;

  const _totalInsight = _shotAnalysisContext.insights.find(
    (insight) => !insight.shot_category && props.result === insight.result,
  );
  const _isTotalInsightActive =
    _totalInsight &&
    !_totalInsight?.shot_category &&
    props.activeInsightCategory === 'total';

  return (
    <Box sx={{width: '100%'}}>
      <button
        style={{
          background: 'transparent',
          border: 0,
          padding: 0,
          paddingLeft: 4,
          display: 'block',
          position: 'relative',
        }}
        onClick={() => props.onCategoryClick()}>
        {Boolean(_totalInsight) && (
          // Insight dots
          <>
            <Box
              height={16}
              width={16}
              bgcolor="warning.main"
              borderRadius={8}
              position="absolute"
              left={-16}
              top="50%"
              sx={{
                transform: 'translate(0, -50%)',
                transition: 'opacity 0.2s ease-out',
                opacity: _isTotalInsightActive ? 0.3 : 0,
              }}
            />
            <Box
              height={8}
              width={8}
              bgcolor="warning.main"
              borderRadius={4}
              position="absolute"
              left={-12}
              top="50%"
              sx={{
                transition: 'opacity 0.2s ease-out',
                transform: 'translate(0, -50%)',
                opacity: _isTotalInsightActive ? 1 : 0.6,
              }}
            />
          </>
        )}
        <Typography variant="h6">
          {t(`shotAnalysis.result.${props.result}`, {count: 0})}
        </Typography>
      </button>
      <Box ml={-2}>
        <ResponsiveContainer width="99%" height={360}>
          <ComposedChart data={props.data.shot_categories} layout="vertical">
            <CartesianGrid
              className={styles.cartesianGrid}
              horizontal={false}
              strokeDasharray={4}
              stroke={palette.secondary.dark}
            />
            {/* Y Axis (Team) */}
            <YAxis
              axisLine={false}
              dataKey={'shot_category'}
              type="category"
              yAxisId="team"
              stroke={palette.secondary.main}
              tickLine={false}
              tick={(tickProps) => {
                const {y, payload} = tickProps;

                if (!payload.value) return <g></g>;

                const _matchingInsight = _shotAnalysisContext.insights.find(
                  (insight) =>
                    insight.shot_category === payload.value &&
                    insight.result === props.result,
                );

                const _isActive =
                  Boolean(_matchingInsight) &&
                  props.activeInsightCategory === payload.value &&
                  props.result === _matchingInsight?.result;

                let _color = palette.secondary.main;

                if (_isActive) {
                  _color = palette.text.primary;
                } else if (_matchingInsight) {
                  _color = palette.text.secondary;
                }

                return (
                  <g transform={`translate(${0},${y})`}>
                    {_matchingInsight && (
                      <>
                        <circle
                          cx={8}
                          cy={0}
                          r={8}
                          x={0}
                          y={0}
                          opacity={_isActive ? 0.3 : 0}
                          fill={palette.warning.main}
                          // transform={}
                          style={{
                            transition: 'opacity 0.2s ease-out',
                          }}
                        />
                        <circle
                          cx={8}
                          cy={0}
                          r={4}
                          opacity={_isActive ? 1 : 0.6}
                          fill={palette.warning.main}
                          style={{
                            transition: 'opacity 0.2s ease-out',
                          }}
                        />
                      </>
                    )}
                    <text
                      x={20}
                      y={4}
                      fontSize={12}
                      fill={_color}
                      style={{
                        transition: 'fill 0.2s ease-out',
                      }}>
                      {capitalize(translateValue(payload.value))}
                    </text>
                  </g>
                );
              }}
              width={124}
              onClick={(e) => {
                const _data = e as any;
                props.onCategoryClick(_data?.value as ShotCategory);
              }}
            />

            {/* Y Axis (Benchmark) */}
            <YAxis
              axisLine={false}
              dataKey={'shot_category'}
              type="category"
              yAxisId="benchmark"
              hide
              stroke={palette.secondary.main}
              tickLine={false}
              tick={{fontSize: 12}}
            />

            {/* X axis */}
            <XAxis
              axisLine={false}
              type="number"
              // stroke={palette.secondary.main}
              tickLine={false}
              tick={{fontSize: 12}}
              padding={{right: 32}}
            />

            {/* Benchmark */}
            <Bar
              dataKey={`benchmark.${props.result}`}
              fill={_benchmarkColor}
              yAxisId="benchmark"
              barSize={24}
              name={_shotAnalysisContext.benchmarkLabel}
              onClick={(e) => {
                props.onCategoryClick(e?.shot_category as ShotCategory);
              }}>
              {/* Render diff label here when below benchmark */}
              <LabelList
                dataKey={`diff.${props.result}`}
                content={function (labelProps: any) {
                  if (!labelProps) return null;

                  const {x, y, width, value} = labelProps;

                  if (value > 0) return null;

                  return _renderDiffLabel(
                    x + width,
                    y,
                    value,
                    labelProps.index,
                  );
                }}
              />
            </Bar>

            {/* Team */}
            <Bar
              dataKey={`team.${props.result}`}
              fill={_teamColor}
              yAxisId="team"
              barSize={24}
              name={_teamsContext.ownTeam?.name}
              onClick={(e) => {
                props.onCategoryClick(e?.shot_category as ShotCategory);
              }}>
              {/* Render diff label here when above benchmark */}
              <LabelList
                dataKey={`diff.${props.result}`}
                content={function (labelProps: any) {
                  const {x, y, width, value} = labelProps;

                  if (value <= 0) return null;

                  return _renderDiffLabel(
                    x + width,
                    y,
                    value,
                    labelProps.index,
                  );
                }}
              />
            </Bar>

            {/* Benchmark: line */}
            <Scatter
              legendType="none"
              shape={(shapeProps: any) => {
                const {cx, cy} = shapeProps;

                return (
                  <svg x={cx} y={cy - 15}>
                    <rect width={2} height={30} fill={palette.common.white} />
                  </svg>
                );
              }}
              dataKey={`benchmark.${props.result}`}
              yAxisId={'benchmark'}
            />

            {/* Tooltip */}
            <Tooltip
              content={_handleTooltip}
              wrapperStyle={{outline: 'none'}}
              cursor={{stroke: palette.secondary.light, strokeDasharray: 4}}
            />
            <Legend
              iconSize={12}
              wrapperStyle={{fontSize: 12}}
              formatter={(value) => {
                return (
                  <span style={{color: palette.secondary.light}}>{value}</span>
                );
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
}

export default ShotAnalysisBarChart;
