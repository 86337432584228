import {ISentenceConditionProps} from 'types/trackers/tracker-sentence.types';
import React, {useEffect, useState} from 'react';
import SentenceConditionBaseLine from './condition-base-line';

import Stack from '@mui/material/Stack';

import {FIConditionalEventFilters, isTrackingAction} from '@my-game-plan/types';
import SentenceDetailsLine from '../line/sentence-details-line';
import DeleteConditionButton from './delete-condition-button';

function SentenceCondition(props: ISentenceConditionProps): JSX.Element {
  /*
   * Hooks n State
   */
  const [_shouldHideDetails, _setShouldHideDetails] = useState(false);

  /*
   * Side effects
   */
  useEffect(() => {
    let _newHideDetailsValue = false;
    if (
      props.condition.type === 'when' ||
      (props.condition.action && isTrackingAction(props.condition.action))
    ) {
      _newHideDetailsValue = true;
    }

    _setShouldHideDetails(_newHideDetailsValue);
  }, [props.condition]);
  /*
   * Handlers
   */
  function _onDetailsChange(details: FIConditionalEventFilters) {
    if (!props.onChange) {
      return;
    }

    props.onChange(props.conditionIndex, {details: details});
  }

  function _onDelete() {
    if (!props.onDelete) {
      return;
    }

    props.onDelete(props.conditionIndex);
  }

  return (
    <Stack
      position="relative"
      alignSelf={'flex-start'}
      alignItems={'center'}
      gap={1}
      sx={{
        '&:hover': {
          '.deleteButton': {
            display: 'flex',
          },
        },
      }}>
      <SentenceConditionBaseLine {...props} />

      {!_shouldHideDetails && (
        <SentenceDetailsLine
          {...props}
          value={props.condition.details}
          onChange={_onDetailsChange}
          count={1}
          action={props.condition.action}
          against={props.condition.against}
        />
      )}
      {!props.readonly && <DeleteConditionButton onClick={_onDelete} />}
    </Stack>
  );
}

export default SentenceCondition;
