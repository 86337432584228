import {t} from 'i18next';
import React from 'react';
import {TooltipProps} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import {IExplorerType} from 'types/dashboard/dashboard-explorer.types';
import {ChartSvgGroup} from '../chart-svg/ChartSvgGroup';
import {ChartSvgIcon} from '../chart-svg/ChartSvgIcon';
import {ChartSvgText} from '../chart-svg/ChartSvgText';
import {formatDate} from 'helpers/general/general.helper';
import {translateValue} from 'helpers/translation.helper';

export const getTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: 'var(--separator)',
          padding: 8,
          borderRadius: 8,
        }}>
        {payload.map((item) => {
          const _label = translateValue(item.dataKey?.toString() || 'total');
          return (
            <p key={item.dataKey} style={{color: item.color}}>
              {_label}: {item.value}
            </p>
          );
        })}
      </div>
    );
  }

  return null;
};

export const getXAxisTick = (
  type: IExplorerType,
  props: any,
  value: any,
  sublabel?: string | number | Date,
) => {
  const {x, y} = props;

  // const value = payload.value;
  let content = null;

  switch (type) {
    case IExplorerType.MATCHES:
      content = (
        <ChartSvgIcon
          src={(sublabel as string) || ''}
          value={formatDate(value as Date, 'DD/MM')}
        />
      );
      break;
    case IExplorerType.FORMATIONS:
      content = <ChartSvgText value={value} />;
      break;
    case IExplorerType.TIME_BLOCKS:
      content = <ChartSvgText value={value} />;
      break;
    case IExplorerType.PLAYERS:
      content = <ChartSvgIcon src={(sublabel as string) || ''} value={value} />;
      break;
    case IExplorerType.GAME_POSITION:
      content = <ChartSvgText value={t(`gamePosition.${value}`)} />;
      break;
    default:
      content = <ChartSvgText value={value} />;
  }

  return (
    <ChartSvgGroup x={x} y={y}>
      {content}
    </ChartSvgGroup>
  );
};
