import {ConfirmOptions} from 'material-ui-confirm';
import i18n from 'localization';

export const DEFAULT_MODAL_CONFIG: ConfirmOptions = {
  hideCancelButton: true,
  confirmationText: i18n.t('general.close'),
  confirmationButtonProps: {
    variant: 'text',
    color: 'secondary',
  },
};

export const DELETE_MODAL_CONFIG: ConfirmOptions = {
  confirmationButtonProps: {
    color: 'error',
  },
  confirmationText: i18n.t('general.delete'),
};
