import {FITrackerSubject} from '@my-game-plan/types';
import React from 'react';

import CloseIcon from '@mui/icons-material/Close';

import TeamCellContent from './team-cell-content';
import PlayerCellContent from './player-cell-content';
import {TCommonExecutorType} from 'types/trackers/tracker-builder/tracker-executor.types';
import ScoutingCellContent from './scouting-cell-content';

import ManageTrackersPreviewTooltip from '../preview-tooltip/manage-trackers-preview-tooltip';

interface IManageTrackersExecutorCellProps {
  executorType: TCommonExecutorType;
  data?: FITrackerSubject[];
  against?: boolean;
}

interface IManageTrackersScoutingCellProps {
  executorType: 'scouting_profile_ids';
  data?: string[];
  against?: boolean;
}

function ManageTrackersExecutorCell(
  props: IManageTrackersExecutorCellProps | IManageTrackersScoutingCellProps,
): JSX.Element {
  /*
   * Hooks n State
   */

  /*
   * Side Effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */

  let _Content = (
    <CloseIcon color="secondary" fontSize="small" sx={{opacity: 0.6}} />
  );

  if (
    props.data?.length &&
    (props.executorType === 'team_performance' ||
      props.executorType === 'opponent_analysis')
  ) {
    _Content = (
      <TeamCellContent
        executorType={props.executorType}
        data={props.data}
        against={props.against}
      />
    );
  }

  if (props.data?.length && props.executorType === 'player_development') {
    _Content = <PlayerCellContent data={props.data} />;
  }

  if (props.data?.length && props.executorType === 'scouting_profile_ids') {
    _Content = <ScoutingCellContent data={props.data} />;
  }

  return (
    <ManageTrackersPreviewTooltip
      executorType={props.executorType}
      data={props.data}>
      <span>{_Content}</span>
    </ManageTrackersPreviewTooltip>
  );
}

export default ManageTrackersExecutorCell;
