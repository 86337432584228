import {REQUEST_ERRORS} from 'config/errors.config';
import {useAPI} from 'hooks/api.hooks';
import {
  FIPerformanceProfilePostData,
  FIStyleOfPlayPerformance,
  FITeamPreferences,
} from '@my-game-plan/types';

const _api = useAPI();

/*
 * Fetch TEAM performance across default profiles
 */
export async function fetchTeamProfiles(): Promise<FIStyleOfPlayPerformance[]> {
  try {
    const ROUTE = 'performance-profiles/team';

    const {data} = await _api.get<FIStyleOfPlayPerformance[]>({
      url: ROUTE,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
}

/*
 * Save team profile
 */

export async function saveTeamProfile(
  postData: FIPerformanceProfilePostData,
): Promise<FITeamPreferences> {
  try {
    const ROUTE = 'performance-profiles/team';

    const {data} = await _api.post<FITeamPreferences>({
      url: ROUTE,
      data: postData,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
}
