import React from 'react';
import Stack from '@mui/material/Stack';
import HeaderFilterMenu from '../filter/header-filter-bar/buttons/header-filter-menu.view';
import {useCompetitions} from 'context/competitions.context';
import {useMatchVideos} from 'context/match-videos-context';

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ShieldIcon from '@mui/icons-material/Shield';

function MatchVideosFilterBar(): JSX.Element {
  /*
   * Hooks n State
   */
  const _competitionsContext = useCompetitions();
  const _matchVideosContext = useMatchVideos();

  /*
   * Side effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */
  return (
    <Stack direction="row" alignItems="center" gap={4}>
      {/* Competitions */}
      <HeaderFilterMenu
        options={_competitionsContext.competitionDropdownOptions}
        value={_matchVideosContext.selectedCompetition?._id || 0}
        onChange={_matchVideosContext.onCompetitionChange}
        buttonProps={{
          ButtonIcon: EmojiEventsIcon,
          buttonValues: [[{type: 'value', value: 'League'}]],
        }}
      />

      {/* Seasons */}
      <HeaderFilterMenu
        options={_matchVideosContext.seasonOptions}
        value={_matchVideosContext.selectedSeasonId}
        onChange={_matchVideosContext.onSeasonChange}
        buttonProps={{
          ButtonIcon: DateRangeIcon,
          buttonValues: [[{type: 'value', value: 'Season'}]],
        }}
      />

      {/* Teams */}
      <HeaderFilterMenu
        multiple
        canSelectAll
        options={_matchVideosContext.teamOptions}
        value={_matchVideosContext.selectedTeamIds}
        onChange={_matchVideosContext.onTeamChange}
        buttonProps={{
          ButtonIcon: ShieldIcon,
          buttonValues: [[{type: 'value', value: 'Teams'}]],
        }}
        buttonLabelCountTranslationKey="team.teamsCount"
      />
    </Stack>
  );
}

export default MatchVideosFilterBar;
