import React from 'react';

import {useTranslation} from 'react-i18next';

import DataDisplayPreferences from 'components/preferences/data-display/data-display-preferences.view';
import Screen from 'components/screen/screen.view';
import {Navigate, Route, Routes} from 'react-router-dom';
import {PREFERENCES_ROUTES} from 'config/navigation.config';
import ManageTrackersView from 'components/preferences/manage-trackers/manage-trackers.view';

export function PreferencesView(): JSX.Element {
  const {t} = useTranslation();

  return (
    <Screen
      title={t('preferences.title')}
      coloredHeader
      tabs={PREFERENCES_ROUTES}>
      <Routes>
        <Route path="" element={<Navigate to="trackers" />} />
        <Route path="trackers" element={<ManageTrackersView />} />
        <Route path="data-display" element={<DataDisplayPreferences />} />

        {/* Redirect */}
        <Route path="*" element={<Navigate to="trackers" />} />
      </Routes>
    </Screen>
  );
}
