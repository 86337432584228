import {useTheme} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Area, AreaChart, ResponsiveContainer, XAxis, YAxis} from 'recharts';

import ArrowDropdownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import Stack from '@mui/material/Stack';
import {TIcon} from 'types/filter.types';
import {FITrackerForm} from '@my-game-plan/types';

interface IChartData {
  value: number;
}

interface IFormIcon {
  color: string;
  icon: TIcon;
}

interface ITrackersTableSparkChartProps {
  form?: FITrackerForm;
}

function TrackersTableSparkChart(
  props: ITrackersTableSparkChartProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const [_data, _setData] = useState<IChartData[]>();
  const [_formIndicator, _setFormIndicator] = useState<IFormIcon>();
  const _theme = useTheme();

  /*
   * Side Effects
   */
  useEffect(() => {
    if (!props.form) {
      _setFormIndicator(undefined);
      _setData([]);
      return;
    }

    const _generatedData: IChartData[] = props.form.history.map((v) => ({
      value: v,
    }));
    _setData(_generatedData);

    if (props.form?.trend === 'up') {
      _setFormIndicator({
        color: 'success',
        icon: ArrowDropUpIcon,
      });
    } else if (props.form?.trend === 'down') {
      _setFormIndicator({
        color: 'error',
        icon: ArrowDropdownIcon,
      });
    } else {
      _setFormIndicator(undefined);
    }
  }, [props.form]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  const _color = _theme.palette.text.primary;
  return (
    <Stack gap={1} direction="row" alignItems="center">
      <Stack width="100%" maxWidth={200}>
        <ResponsiveContainer width="100%" height={48}>
          <AreaChart data={_data}>
            <defs>
              <linearGradient id="area-gradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={_color} stopOpacity={0.2} />
                <stop offset="100%" stopColor={_color} stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis hide />
            <YAxis hide />
            <Area dataKey="value" stroke={_color} fill="url(#area-gradient)" />
          </AreaChart>
        </ResponsiveContainer>
      </Stack>
      {_formIndicator && (
        <_formIndicator.icon color={_formIndicator.color} sx={{fontSize: 24}} />
      )}
    </Stack>
  );
}

export default TrackersTableSparkChart;
