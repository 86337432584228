const SCOUTING_TRANSLATIONS = {
  title: 'Scouting',

  search: 'Search players',
  searchResults: 'Discover players',
  changeFilters: 'Change filters',
  advancedSearch: 'Advanced search',
  follow: 'Shortlist',

  empty: {
    default: {
      header: 'You haven’t created any profile yet',
      description:
        "After you've created a profile and shortlisted some players, you'll see them here.",
      cta: 'Search players',
    },
    search: {
      header: 'No players found',
      description: 'Try searching by different criteria',
      cta: 'Search again',
    },
    following: {
      header: 'You haven’t shortlisted any players yet',
      description: '',
    },
  },

  searchForm: {
    name: 'Search by name',
    namePlaceholder: "Start typing a player's name",
    or: 'or search by characteristics',
    anyX: 'Any {{field}}',
    restoreDefaults: 'Restore defaults',
    noResults: {},
  },

  showingResults_one: 'Showing 1 player',
  showingResults_other: 'Showing {{value}} of {{count}} players',

  actions: {
    clearSearches: {
      title: 'Clear list of scouted players',
      description:
        "Clearing your team's list of scouted players will also remove all trackers assigned to them.",
      cta: 'Remove player',
    },
  },

  mirroring: {
    rankByPerformance: 'Rank players based on performance',
    helperText:
      'Trackers assigned to the player you select here will be applied to found players, allowing them to be ranked by overall performance',
    form: {
      rankByIn: 'Rank by performance in',
      rankBySince: 'Rank by performance since',
      usingTrackers: 'using trackers assigned to',
      playerOfTeam: 'player of your team',
    },
  },

  followActions: {
    followPlayer: 'Shortlist player',
    stopFollowing: 'Remove from shortlist',
    startedFollowing: 'Shortlisted {{name}}',
    stoppedFollowing: 'Removed {{name}} from shortlist',
  },

  profile: {
    profilesCount_one: '{{count}} profile',
    profilesCount_other: '{{count}} profiles',
    followingPlayers: 'shortlist',
    newProfile: 'New scouting profile',
    edit: {
      title: 'Edit search profile',
      titleShort: 'Edit search',
      cta: 'Save changes',
    },
    create: {
      title: 'Advanced search',
      cta: 'Search players',

      description: 'Desciption*',
    },
    noFollowingPlayers: 'Not following any players yet',
    title: 'Scouting profile',
    discoverPlayers: 'Discover players',
    allPlayers: 'All players',
    delete: {
      title: 'Delete search?',
      description:
        'Deleting this search will also remove all players shortlisted in it.',
      cta: 'Delete search',
    },
    add: {
      title: 'Add player to profile',
      cta: 'Add to profile',
    },
  },

  view: {
    viewAs: 'View as',
    cards: 'cards',
    table: 'table',
  },
  pagination: {
    showing: 'Showing',
    of: 'of',
    found: 'found',

    searchResults: 'search results',
    players_other: 'players',
    players_one: 'player',
  },

  filters: {
    filtering: 'Filtering',
    properties_one: 'property',
    properties_other: 'properties',
    mirroring: 'mirroring',
  },
};

export default SCOUTING_TRANSLATIONS;
