import React from 'react';

import GppBadIcon from '@mui/icons-material/GppBad';
import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';

import {FIFormattedAutomation} from '@my-game-plan/types';

import TrackerSentence from '../../sentence/tracker-sentence';
import ManageTrackersExecutorCell from './cell/manage-trackers-executor-cell';
import {SxProps, Theme} from '@mui/material';
import ManageTrackersActions from './actions/manage-trackers-actions';
import {useTranslation} from 'react-i18next';

interface IManageTrackersTableRowProps {
  tracker: FIFormattedAutomation;
}

function ManageTrackersTableRow(
  props: IManageTrackersTableRowProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Side Effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */

  const _tdStyle: SxProps<Theme> = {
    py: 3,
  };
  return (
    <TableRow>
      <TableCell
        sx={{
          ..._tdStyle,
          width: {
            xs: undefined,
            lg: 320,
          },
        }}>
        <Stack direction="row" alignItems="center" gap={1}>
          {props.tracker.against && (
            <Tooltip title={t('sentenceForm.observing.observingAgainst')}>
              <GppBadIcon />
            </Tooltip>
          )}
          <TrackerSentence size="small" tracker={props.tracker} readonly />
        </Stack>
      </TableCell>

      <TableCell sx={_tdStyle}>
        <ManageTrackersExecutorCell
          executorType="team_performance"
          data={props.tracker.team_performance}
          against={props.tracker.against}
        />
      </TableCell>
      <TableCell sx={_tdStyle}>
        <ManageTrackersExecutorCell
          executorType="player_development"
          data={props.tracker.player_development}
          against={props.tracker.against}
        />
      </TableCell>
      <TableCell sx={_tdStyle}>
        <ManageTrackersExecutorCell
          executorType="opponent_analysis"
          data={props.tracker.opponent_analysis}
          against={props.tracker.against}
        />
      </TableCell>
      {/* <TableCell sx={_tdStyle}>
        <ManageTrackersExecutorCell
          executorType="scouting_profile_ids"
          data={props.tracker.scouting_profile_ids}
        />
      </TableCell> */}

      <TableCell sx={_tdStyle} align="right">
        <ManageTrackersActions tracker={props.tracker} />
      </TableCell>
    </TableRow>
  );
}

export default ManageTrackersTableRow;
