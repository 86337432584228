import React, {useEffect, useRef, useState} from 'react';
import moment, {Moment} from 'moment';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

import RestartAltIcon from '@mui/icons-material/RestartAlt';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import {useTranslation} from 'react-i18next';

import {useScouting} from 'context/scouting.context';
import {
  FIPlayerSearchFilters,
  FIPlayerSearchMirrorParams,
  FIScoutingProfile,
  FIScoutingProfilePostData,
  MAX_AGE,
  MAX_HEIGHT,
  MAX_MARKET_VALUE,
  MAX_WEIGHT,
  MIN_AGE,
  MIN_HEIGHT,
  MIN_MARKET_VALUE,
  MIN_WEIGHT,
} from '@my-game-plan/types';
import SearchDropdown from '../input/search-dropdown.view';
import SearchRangeInput from '../input/search-range-input.view';
import {LOADING_STATE} from 'types/screen.types';

import {useNavigate} from 'react-router-dom';

import {NAV_LEVEL_1_ROUTE} from 'config/navigation.config';
import {
  formatPlayerValue,
  generatePlayerMarketValueMarks,
} from 'helpers/player.helper';

import SearchMirrorInfo from '../search-mirror-info/search-mirror-info.view';
import {TError} from 'types/sentence-form.types';
import {useSnackbar} from 'notistack';

interface ICreateScoutingProfileDialogProps {
  open: boolean;
  onClose: () => void;
  onSave?: () => void;
  scoutingProfile?: FIScoutingProfile;
}

function CreateScoutingProfileDialog(
  props: ICreateScoutingProfileDialogProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const _scoutingContext = useScouting();
  const {t} = useTranslation();
  const _navigate = useNavigate();
  const _snackbar = useSnackbar();

  const [_selectedFilters, _setSelectedFilters] =
    useState<FIPlayerSearchFilters>({});
  const [_description, _setDescription] = useState<string>('');
  const [_loadingState, _setLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.SUCCESS,
  );
  const [_errors, _setErrors] = useState<TError<FIScoutingProfile>>({});
  const _hasSubmitted = useRef(false);

  /* Clear on close */
  useEffect(() => {
    return () => {
      if (!props.open) {
        _hasSubmitted.current = false;
        _setErrors({});
        _setLoadingState(LOADING_STATE.SUCCESS);
      }
    };
  }, [props.open]);

  /* Set default selected filters */
  useEffect(() => {
    _setSelectedFilters(props.scoutingProfile?.filters || {});
    _setDescription(props.scoutingProfile?.description || '');
  }, [props.scoutingProfile, props.open]);

  /*
   * Handlers
   */
  function _onUpdateFilter(data: Partial<FIPlayerSearchFilters>) {
    _setSelectedFilters({..._selectedFilters, ...data});
    _validateForm();
  }

  function _onRestoreDefaults() {
    if (props.scoutingProfile) {
      _setSelectedFilters(props.scoutingProfile.filters || {});
    } else {
      _setSelectedFilters({});
    }
  }

  function _onExpiryDateChange(date: Moment | null) {
    _onUpdateFilter({
      contract_expiry_date: date?.toDate() || undefined,
    });
  }

  function _onDateClear() {
    _onExpiryDateChange(null);
  }

  function _validateForm(): boolean {
    if (!_hasSubmitted.current) {
      return true;
    }

    const _errorsAfterValidation: TError<FIScoutingProfile> = {};
    let _isValid = true;

    if (!_description) {
      _errorsAfterValidation.description = t('error-states.required');
      _isValid = false;
    }

    const {mirroring, ..._otherFilters} = _selectedFilters;
    if (!Object.keys(_otherFilters).length) {
      _errorsAfterValidation.filters = t('error-states.required');
      _isValid = false;
    }

    _setErrors(_errorsAfterValidation);
    return _isValid;
  }

  async function _onSubmit() {
    _hasSubmitted.current = true;
    const _isValid = _validateForm();

    if (!_isValid) {
      return;
    }

    try {
      const _postData: FIScoutingProfilePostData = {
        mirroring_player_id: _selectedFilters.mirroring?.player_id,
        description: _description,
        filters: _selectedFilters,
      };

      _setLoadingState(LOADING_STATE.LOADING);

      let _updatedProfile: FIScoutingProfile | undefined = undefined;
      if (props.scoutingProfile) {
        _updatedProfile = await _scoutingContext.updateProfile(
          _postData,
          props.scoutingProfile._id,
        );

        _setLoadingState(LOADING_STATE.SUCCESS);
        props.onClose();
        if (props.onSave) {
          props.onSave();
        }
      } else {
        _updatedProfile = await _scoutingContext.createProfile(_postData);
      }
      return _navigate(
        `${NAV_LEVEL_1_ROUTE.SCOUTING}/profiles/${_updatedProfile._id}/search`,
      );
    } catch (error) {
      _setLoadingState(LOADING_STATE.SUCCESS);
      _snackbar.enqueueSnackbar(t('error-states.default'), {
        variant: 'error',
      });
    }
  }

  function _onCancel() {
    props.onClose();

    _setSelectedFilters(_scoutingContext.searchFilters);
    _setLoadingState(LOADING_STATE.SUCCESS);
  }

  function _onMirrorInfoChange(data: FIPlayerSearchMirrorParams | undefined) {
    _onUpdateFilter({mirroring: data});
  }

  function _onDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
    _setDescription(event.target.value);
    _validateForm();
  }

  /*
   * Render
   */

  let _titleText = t('scouting.profile.create.title');
  let _ctaText = t('scouting.profile.create.cta');
  if (props.scoutingProfile) {
    _titleText = t('scouting.profile.edit.title');
    _ctaText = t('scouting.profile.edit.cta');
  }
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={props.open}
      onClose={_onCancel}
      scroll="body">
      <DialogTitle>{_titleText}</DialogTitle>
      <DialogContent>
        {/* CONTENT */}
        <Stack gap={5} pt={1}>
          {/* Name input  */}
          <TextField
            fullWidth
            value={_description}
            label={t('scouting.profile.create.description')}
            onChange={_onDescriptionChange}
            error={Boolean(_errors.description)}
          />
          <Divider />
          {/* CONTENT - Search by props */}
          {/* POSITION - FOOT - COUNTRY - COMPETITION  */}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <SearchDropdown
                label={t('players.info.position')}
                property="position"
                value={_selectedFilters.position}
                onChange={_onUpdateFilter}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SearchDropdown
                label={t('players.info.foot')}
                property="preferred_foot"
                value={_selectedFilters.preferred_foot}
                onChange={_onUpdateFilter}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SearchDropdown
                label={t('players.info.nationality')}
                property="country"
                value={_selectedFilters.country}
                onChange={_onUpdateFilter}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SearchDropdown
                label={t('players.info.competition')}
                property="competition_id"
                value={_selectedFilters.competition_id}
                onChange={_onUpdateFilter}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* AGE - HEIGHT - WEIGHT  */}
            <Grid item xs={12} sm={6} md={4}>
              <SearchRangeInput
                min={MIN_AGE}
                max={MAX_AGE}
                label={t('players.info.age')}
                property="age"
                value={_selectedFilters.age || [MIN_AGE, MAX_AGE]}
                onChange={_onUpdateFilter}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SearchRangeInput
                min={MIN_HEIGHT}
                max={MAX_HEIGHT}
                label={t('players.info.height')}
                property="height"
                value={_selectedFilters.height || [MIN_HEIGHT, MAX_HEIGHT]}
                onChange={_onUpdateFilter}
                unit={t('physical.units.cm')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SearchRangeInput
                min={MIN_WEIGHT}
                max={MAX_WEIGHT}
                label={t('players.info.weight')}
                property="weight"
                value={_selectedFilters.weight || [MIN_WEIGHT, MAX_WEIGHT]}
                onChange={_onUpdateFilter}
                unit={t('physical.units.kg')}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* MARKET VALUE - CONTRACT EXPIRY */}
            <Grid item xs={12} sm={6} lg={8}>
              <SearchRangeInput
                min={MIN_MARKET_VALUE}
                max={MAX_MARKET_VALUE}
                label={t('players.info.market_value')}
                property="market_value"
                value={
                  _selectedFilters.market_value || [
                    MIN_MARKET_VALUE,
                    MAX_MARKET_VALUE,
                  ]
                }
                onChange={_onUpdateFilter}
                valueFormatter={formatPlayerValue}
                marks={generatePlayerMarketValueMarks()}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <DatePicker
                label={t('players.contract.expiry_date_max')}
                value={
                  _selectedFilters.contract_expiry_date
                    ? moment(_selectedFilters.contract_expiry_date as Date)
                    : null
                }
                onChange={_onExpiryDateChange}
                disablePast
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                  field: {clearable: true, onClear: _onDateClear},
                }}
              />
            </Grid>
          </Grid>

          <SearchMirrorInfo
            data={_selectedFilters.mirroring}
            onChange={_onMirrorInfoChange}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Box flex={1}>
          <IconButton
            onClick={_onRestoreDefaults}
            title={t('scouting.searchForm.restoreDefaults')}
            color="secondary">
            <RestartAltIcon />
          </IconButton>
        </Box>
        <Button color="secondary" variant="text" onClick={_onCancel}>
          {t('general.cancel')}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={_onSubmit}
          loading={_loadingState === LOADING_STATE.LOADING}>
          {_ctaText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default CreateScoutingProfileDialog;
