import React, {useEffect, useState} from 'react';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useTeams} from 'context/team.context';
import Logo from 'components/common/Logo/Logo';
import {lighten, useTheme} from '@mui/material';
import {Link, useParams} from 'react-router-dom';
import {ITypedOption} from 'types/option.types';

function DashboardTeamPicker(): JSX.Element | null {
  /*
   * Hooks n State
   */
  const _theme = useTheme();
  const _teamsContext = useTeams();
  const _params = useParams();
  const [_options, _setOptions] = useState<ITypedOption<string>[]>([]);
  const [_dropdownAnchorEl, _setDropdownAnchorEl] =
    useState<null | HTMLElement>(null);

  /*
   * Side effects
   */
  useEffect(() => {
    const _options: ITypedOption<string>[] = [
      ..._teamsContext.opponentsOptions,
    ];

    const _sortedOptions = _options.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
    _setOptions(_sortedOptions);
  }, [_teamsContext.opponentsOptions, _teamsContext.ownTeam]);

  /*
   * Handlers
   */
  function _onDropdownOpen(event: React.MouseEvent<HTMLElement>) {
    _setDropdownAnchorEl(event.currentTarget);
  }

  function _onDropdownClose() {
    _setDropdownAnchorEl(null);
  }

  function _onOpponentChange() {
    _onDropdownClose();
  }

  /*
   * Render
   */
  const _isDropdownOpen = Boolean(_dropdownAnchorEl);
  if (!_teamsContext.current) {
    return null;
  }
  const PAPER_BG = lighten(_theme.palette.background.paper, 0.05);
  return (
    <Stack>
      <Button
        sx={{'& .MuiButton-endIcon': {marginLeft: 0.5}}}
        endIcon={
          _isDropdownOpen ? (
            <KeyboardArrowUpIcon sx={{color: 'text.secondary'}} />
          ) : (
            <KeyboardArrowDownIcon sx={{color: 'text.secondary'}} />
          )
        }
        onClick={_onDropdownOpen}>
        <Logo size="medium" src={_teamsContext.current?.image_url} />
      </Button>
      <Menu
        anchorEl={_dropdownAnchorEl}
        open={_isDropdownOpen}
        onClose={_onDropdownClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              bgcolor: PAPER_BG,
            },
          },
        }}>
        {_options.map((opponent) => {
          return (
            <MenuItem
              key={opponent.value}
              selected={opponent.value === _teamsContext.current?._id}
              onClick={_onOpponentChange}
              component={Link}
              to={`/dashboards/${opponent.value}/${_params.dashboard}`}>
              {opponent.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Stack>
  );
}

export default DashboardTeamPicker;
