import TrackerDetailView from 'views/automations/tracker_detail.view';
import PlayerTrackerView from 'views/player/player-tracker.view';
import PlayerView from 'views/player/player.view';
import PlayersOverviewScreen from 'views/player/players-overview.view';
import React from 'react';

import {Route, Routes} from 'react-router-dom';

function PlayersRoutes() {
  return (
    <Routes>
      <Route path="*" element={<PlayersOverviewScreen />} />

      <Route path="trackers/:id" element={<TrackerDetailView />} />
      <Route
        path=":player_id/*"
        element={<PlayerView executorType="player_development" />}
      />
      <Route path=":player_id/statistics/:id" element={<PlayerTrackerView />} />
    </Routes>
  );
}

export default PlayersRoutes;
