import React from 'react';
import {useTranslation} from 'react-i18next';

import {IDashboardScoreData} from 'types/dashboard/dashboard-score.types';
import {
  IFormationsWidgetData,
  IPlayerStatisticsWidgetData,
  IWidgetType,
} from 'types/dashboard/overview-dashboards.types';
import {CenteredSpinner} from '../../common/centered-spinner/CenteredSpinner';
import styles from './OverviewDashboard.module.scss';
import {FormationsWidget} from './widgets/formations/FormationsWidget';
import {PlayerStatisticsWidget} from './widgets/player-statistics/PlayerStatisticsWidget';
import {ScoresWidget} from './widgets/scores/ScoresWidget';
import {useOverviewDashboards} from 'context/dashboard/overview-dashboards.context';
import CustomEmptyState from 'components/error-states/CustomEmptyState';
import {EmailButton} from 'components/buttons/EmailButton';

export const OverviewDashboard = () => {
  const {t} = useTranslation();
  const {isLoading, current, hasError} = useOverviewDashboards();

  // Error
  // if (hasError) return <p>{t('overview-dashboards.error')}</p>;
  if (hasError) {
    return (
      <CustomEmptyState
        header={t('error-states.not-found.header')}
        description={t('error-states.not-found.description')}
        imageType="default"
        primaryAction={<EmailButton />}
      />
    );
  }

  // Loading
  if (isLoading || !current) return <CenteredSpinner />;

  // Empty
  if (current.widgets.length === 0) {
    return (
      <CustomEmptyState
        header={t('overview-dashboards.empty.header')}
        description={t('overview-dashboards.empty.description')}
        imageType="default"
      />
    );
  }

  // Overview
  return (
    <div className={styles.container}>
      {current.widgets.map((widget) => {
        switch (widget.type) {
          case IWidgetType.PLAYER_STATISTICS:
            return (
              <PlayerStatisticsWidget
                key={widget.key}
                action={widget.action}
                data={widget.data as IPlayerStatisticsWidgetData}
                label={widget.key}
              />
            );
          case IWidgetType.SCORES:
            return (
              <ScoresWidget
                key={widget.key}
                action={widget.action}
                data={widget.data as IDashboardScoreData}
                label={widget.key}
              />
            );
          case IWidgetType.FORMATIONS:
            return (
              <FormationsWidget
                key={widget.key}
                label={widget.key}
                data={widget.data as IFormationsWidgetData}
              />
            );
          default:
            break;
        }
      })}
    </div>
  );
};
