import React, {useEffect} from 'react';
import {Navigate, Route, Routes, useParams} from 'react-router-dom';
import {DashboardView} from 'views/dashboard/dashboard.view';
import {IOverviewDashboardIntial} from 'types/dashboard/overview-dashboards.types';
import {useTeams} from 'context/team.context';
import {useOverviewDashboards} from 'context/dashboard/overview-dashboards.context';
import {ACTION} from '@my-game-plan/types';

export const TeamRoutes = () => {
  const _dashboards = useOverviewDashboards();
  const _params = useParams();
  const _teamsContext = useTeams();

  function getFirstAvailableDashboardRoute(
    _overviewDashboards: IOverviewDashboardIntial[] | undefined,
  ) {
    const _defaultAction: ACTION = 'goal_kick';
    if (!_overviewDashboards) return _defaultAction;
    return _overviewDashboards.length === 0
      ? _defaultAction
      : _overviewDashboards[0]._id;
  }

  useEffect(() => {
    const _matchingTeam = _teamsContext.all.find(
      (team) => team._id === _params.team,
    );
    if (_matchingTeam) _teamsContext.setCurrent(_matchingTeam._id);
  }, [_params.team]);

  return (
    <Routes>
      <Route
        path=""
        element={
          <Navigate
            to={getFirstAvailableDashboardRoute(_dashboards.all)}
            replace
          />
        }
      />
      <Route path=":dashboard" element={<DashboardView />} />
    </Routes>
  );
};
