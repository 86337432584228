import React, {PropsWithChildren, useEffect, useState} from 'react';

import {TClipRequestMode} from 'types/clips.types';
import {editVideoConstants} from 'config/clips.config';
import {useTranslation} from 'react-i18next';
import {
  calcEditHandlePosition,
  timestampToSecondsString,
} from 'helpers/video.helper';
import {useVideo} from 'context/video/video.context';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from 'helpers/general/context_generators.helper';
import {useAuth} from 'context/auth.context';
import {saveEditedClip} from 'controllers/video/video.controller';
import {useSnackbar} from 'notistack';
import {useAnalytics} from '../analytics.context';
import {IWatchedVideoProps} from 'config/analytics/events.config';
import ANALYTICS_EVENT from 'config/analytics/event-names.config';

interface VideoEditAPI {
  saveEdit: (useTrimPos?: boolean) => void;
  editLeftTrimPos: number;
  setEditLeftTrimPos: (newPos: number) => void;
  editRightTrimPos: number;
  setEditRightTrimPos: (newPos: number) => void;
  changeVideoDuration: (
    requestMode: TClipRequestMode,
    xSecondsBackward: number,
    xSecondsForward: number,
  ) => void;
  xSecondsForward: (
    requestMode: TClipRequestMode,
    xSecondsForward?: number,
  ) => void;
  xSecondsBackward: (
    requestMode: TClipRequestMode,
    xSecondsBackward?: number,
  ) => void;
  tmpVideoOffsets: [number, number];
  totalVideoOffsets: [number, number];
  cancelEdit: () => void;
  playFromStart: () => void;
  isWatchingCustomOffsets: boolean;
  timeString: string;
}

const context = createCustomContext<VideoEditAPI>();
export const useVideoEdit = createContextHook(context);
const DEFAULT_VIDEO_TIME = '0:00 - 0:00';
export const VideoEditProvider = (
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element => {
  const video = useVideo();
  const _auth = useAuth();
  const _analyticsContext = useAnalytics();

  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  const [_editLeftTrimPos, _setEditLeftTrimPos] = useState<number>(0);
  const [_editRightTrimPos, _setEditRightTrimPos] = useState<number>(1);

  //The video offsets that are temporarily increased (for this session)
  const [_tmpVideoOffsets, _setTmpVideoOffsets] = useState<[number, number]>([
    0, 0,
  ]);
  const [_totalVideoOffsets, _setTotalVideoOffsets] = useState<
    [number, number]
  >([0, 0]);

  const [_isWatchingCustomOffsets, _setIsWatchingCustomOffsets] =
    useState(false);

  const [_videoPlayerTimeString, _setVideoPlayerTimeString] =
    useState(DEFAULT_VIDEO_TIME);

  useEffect(() => {
    if (!video.isEditMenuOpen) _setTmpVideoOffsets([0, 0]);
    else {
      video.setVideoBufferingStatus('settingTime'); // initiate loading screen
      /* Initialize editor */
      _changeVideoDuration(
        'edit',
        editVideoConstants.startBackwardIncreaseTime,
        editVideoConstants.startForwardIncreaseTime,
      );
    }
  }, [video.isEditMenuOpen]);

  useEffect(() => {
    if (!video.isVideoPlayerOpen) {
      _setTmpVideoOffsets([0, 0]);
      _setTotalVideoOffsets([0, 0]);
    }
  }, [video.isVideoPlayerOpen]);

  const [timeSetInitiated, setTimeSetInitiated] = useState(false);

  // Effect to initiate setTime when video is ready
  useEffect(() => {
    if (!(video.isVideoReady && video.isEditMenuOpen)) return;

    video.setTime(0);
    setTimeSetInitiated(true); // Indicate that setTime has been initiated
  }, [video.isVideoReady]);

  // Effect to listen to timeSetInitiated and act on video.played updates
  useEffect(() => {
    if (!timeSetInitiated) return; // Only act if setTime has been initiated

    if (
      video.played.loaded < 0.3 &&
      video.videoBufferingStatus !== 'buffering'
    ) {
      video.setVideoBufferingStatus('buffering');
      setTimeSetInitiated(false);
    }
  }, [timeSetInitiated, video.played, video.videoBufferingStatus]);

  // // Once we start buffering we should put the video to the beginning
  // useEffect(() => {
  //   video.setTime(0);
  // }, [video.videoBuffering]);

  // Set total video offsets when tmp offsets change
  useEffect(() => {
    const _newTotalVideoOffsets: [number, number] = [
      video.videoOffsets[0] + _tmpVideoOffsets[0],
      video.videoOffsets[1] + _tmpVideoOffsets[1],
    ];

    _setTotalVideoOffsets(_newTotalVideoOffsets);
  }, [
    video.videoOffsets,
    _tmpVideoOffsets,
    _editLeftTrimPos,
    _editRightTrimPos,
  ]);

  // Calculate video duration
  useEffect(() => {
    if (!video.isEditMenuOpen && video.currentClip) {
      const _startOffset = video.videoOffsets[0] + _tmpVideoOffsets[0];
      const _endOffset = video.videoOffsets[1] + _tmpVideoOffsets[1];
      const _calculatedDuration = _endOffset + _startOffset;

      const _startString = timestampToSecondsString(
        video.currentClip.timestamp -
          _startOffset +
          Math.floor(video.played.playedSeconds),
        video.currentClip.period,
      );

      const _endString = timestampToSecondsString(
        video.currentClip.timestamp - _startOffset + _calculatedDuration,
        video.currentClip.period,
      );

      const _timeString = `${_startString} - ${_endString}`;
      _setVideoPlayerTimeString(_timeString);

      video.setDuration(_calculatedDuration);
    } else if (video.currentClip) {
      const _loadedVideoStartTimestamp =
        video.currentClip.timestamp -
        video.videoOffsets[0] -
        _tmpVideoOffsets[0];
      const _loadedVideoEndTimestamp =
        video.currentClip.timestamp +
        video.videoOffsets[1] +
        _tmpVideoOffsets[1];

      const _calculatedStartTimestamp =
        _loadedVideoStartTimestamp +
        (_loadedVideoEndTimestamp - _loadedVideoStartTimestamp) *
          _editLeftTrimPos;
      const _calculatedEndTimestamp =
        _loadedVideoStartTimestamp +
        (_loadedVideoEndTimestamp - _loadedVideoStartTimestamp) *
          _editRightTrimPos;

      const _calculatedDuration = Math.round(
        _calculatedEndTimestamp - _calculatedStartTimestamp,
      );
      const _startString = timestampToSecondsString(
        _loadedVideoStartTimestamp + Math.floor(video.played.playedSeconds),
        video.currentClip.period,
      );
      const _endString = timestampToSecondsString(
        _calculatedEndTimestamp,
        video.currentClip.period,
      );
      const _timeString = `${_startString} - ${_endString}`;
      _setVideoPlayerTimeString(_timeString);

      video.setDuration(_calculatedDuration);
    }
    //
  }, [
    video.played.playedSeconds,
    video.currentClip,
    video.setDuration,
    _editLeftTrimPos,
    _editRightTrimPos,
    video.videoOffsets,
    // _totalVideoOffsets,
    _tmpVideoOffsets,
    video.isEditMenuOpen,
  ]);

  // Init
  useEffect(() => {
    _setIsWatchingCustomOffsets(false);
    _setTmpVideoOffsets([0, 0]);
  }, [video.currentClip]);

  async function _changeVideoDuration(
    requestMode: TClipRequestMode,
    xSecondsBackward = 0,
    xSecondsForward = 0,
  ) {
    if (requestMode === 'add-time') {
      _setIsWatchingCustomOffsets(true);
    }

    const _prevOffsets = video.videoOffsets;

    const _offsetsToSave: [number, number] = [
      _tmpVideoOffsets[0] + xSecondsBackward,
      _tmpVideoOffsets[1] + xSecondsForward,
    ];

    // if (_offsetsToSave[0] < 0) _offsetsToSave[0] = 0;

    /* Calculate full offsets and fetch new clip */
    const _newOffsets: [number, number] = [
      _prevOffsets[0] + _offsetsToSave[0],
      _prevOffsets[1] + _offsetsToSave[1],
    ];

    _setTmpVideoOffsets(_offsetsToSave);
    let _leftTrimPosToSet = _editLeftTrimPos;
    let _rightTrimPosToSet = _editRightTrimPos;

    if (requestMode === 'edit') {
      _leftTrimPosToSet = editVideoConstants.editLeftTrimPos;
      _rightTrimPosToSet = editVideoConstants.editRightTrimPos;
    }

    _setEditLeftTrimPos(
      calcEditHandlePosition(_leftTrimPosToSet, _prevOffsets, _newOffsets),
    );
    _setEditRightTrimPos(
      calcEditHandlePosition(_rightTrimPosToSet, _prevOffsets, _newOffsets),
    );

    /* Fetch extended clip */
    await video.fetchNewClip(_newOffsets, video.currentClip, requestMode);

    /* Send Mixpanel Event */
    const _analyticsProps: IWatchedVideoProps = {
      minute: video.currentClip.minute,
      second: video.currentClip.second,
      match_id: video.currentClip.match._id,
      wyscout_id: video.currentClip.match._id,
      ...(_newOffsets && {
        duration: _newOffsets[0] + _newOffsets[1],
      }),
    };

    if (requestMode === 'edit') {
      // User started editing
      _analyticsContext.trackEvent(
        ANALYTICS_EVENT.VIDEO_EDIT_START,
        _analyticsProps,
      );
    } else if (requestMode === 'add-time') {
      // User added seconds while watching video
      _analyticsContext.trackEvent(
        ANALYTICS_EVENT.WATCHED_VIDEO_TIME_ADDED,
        _analyticsProps,
      );
    } else if (requestMode === 'edit-add-time') {
      // User added seconds in editor
      _analyticsContext.trackEvent(
        ANALYTICS_EVENT.VIDEO_EDIT_TIME_ADDED,
        _analyticsProps,
      );
    }
  }

  function _xSecondsForward(
    requestMode: TClipRequestMode,
    xSecondsForward = editVideoConstants.videoDurationIncrease,
  ) {
    _changeVideoDuration(requestMode, 0, xSecondsForward);
  }

  function _xSecondsBackward(
    requestMode: TClipRequestMode,
    xSecondsBackward = editVideoConstants.videoDurationIncrease,
  ) {
    _changeVideoDuration(requestMode, xSecondsBackward);
  }

  async function _cancelEdit() {
    video.setIsEditMenuOpen(false);
    await video.fetchNewClip(video.videoOffsets, video.currentClip, 'initial');
  }

  function _playFromStart() {
    let _time = 0;
    if (video.isEditMenuOpen) {
      _time = _editLeftTrimPos || 0;
    }
    video.setTime(_time);
  }

  async function _saveEdit(useTrimPos = true) {
    try {
      const [user_id, event_id] = [_auth.user?._id, video.currentClip._id];

      if (user_id && event_id) {
        const duration = _totalVideoOffsets[0] + _totalVideoOffsets[1];
        let newOffsets: [number, number] = [..._totalVideoOffsets];
        if (useTrimPos) {
          newOffsets = [
            _totalVideoOffsets[0] - _editLeftTrimPos * duration,
            _editRightTrimPos * duration - _totalVideoOffsets[0],
          ];
        } else {
          _setTmpVideoOffsets([0, 0]);
        }

        saveEditedClip({
          user_id,
          event_id,
          offsets: newOffsets,
        });

        video.setIsEditMenuOpen(false);

        const _videoDuration = Math.round(newOffsets[0] + newOffsets[1]);

        _analyticsContext.trackEvent(ANALYTICS_EVENT.VIDEO_EDIT_SAVED, {
          minute: video.currentClip.minute,
          second: video.currentClip.second,
          match_id: video.currentClip.match._id,
          wyscout_id: video.currentClip.match._id,
          duration: _videoDuration,
        });

        if (useTrimPos) {
          await video.fetchNewClip(
            newOffsets,
            video.currentClip,
            'done-editing',
          );
        }
        enqueueSnackbar(t('video-player.edit.success.title'), {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar(t('video-player.edit.error.title'), {
        variant: 'error',
      });
    }
  }

  return createProvider(context, props, {
    saveEdit: _saveEdit,
    editLeftTrimPos: _editLeftTrimPos,
    editRightTrimPos: _editRightTrimPos,
    setEditLeftTrimPos: _setEditLeftTrimPos,
    setEditRightTrimPos: _setEditRightTrimPos,
    changeVideoDuration: _changeVideoDuration,
    xSecondsForward: _xSecondsForward,
    xSecondsBackward: _xSecondsBackward,
    tmpVideoOffsets: _tmpVideoOffsets,
    totalVideoOffsets: _totalVideoOffsets,
    cancelEdit: _cancelEdit,
    playFromStart: _playFromStart,
    isWatchingCustomOffsets: _isWatchingCustomOffsets,
    timeString: _videoPlayerTimeString,
  });
};
