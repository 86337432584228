import classNames from 'classnames';
import React from 'react';
import {
  IPlayerCell,
  TRowClickPlayers,
} from 'types/dashboard/dashboard-player-stats.types';
import {AllTeams} from '../../common/all-teams/AllTeams';
import {PlayerDetails} from '../../common/player-details/PlayerDetails';
import {ReceivingPlayersDetails} from '../../common/receiving-player-details/ReceivingPlayerDetails';
import styles from './stylesheet/players-list.module.scss';
import {hoverBackgroundColor} from 'config/general.config';
import {hoverEffect} from 'helpers/general/general.helper';
import {useDashboard} from 'context/dashboard/dashboard.context';

export const PlayerCell = ({
  player,
  receivingPlayer,
  onRowClick,
  rowIndex,
  columnsCount,
  tablesCount,
  isInteractive,
}: IPlayerCell) => {
  const _dashboard = useDashboard();

  const players: TRowClickPlayers = {
    player: player,
    receiving_player: receivingPlayer,
  };

  const _shouldTruncate = tablesCount > 1 && columnsCount > 2;

  if (_dashboard.current?.isOpponentAction) {
    return <AllTeams />;
  }

  return (
    <div
      className={classNames(styles.playerCell, {
        [styles.playerCellInteractive]: isInteractive,
      })}
      onMouseOver={(e) =>
        isInteractive && hoverEffect(e, hoverBackgroundColor, '')
      }
      onMouseLeave={(e) => isInteractive && hoverEffect(e, '', '')}
      onClick={() => {
        isInteractive && onRowClick(rowIndex, players, []);
      }}>
      {receivingPlayer !== undefined ? (
        <ReceivingPlayersDetails
          player={player}
          receivingPlayer={receivingPlayer}
          shouldTruncate={_shouldTruncate}
          size="small"
        />
      ) : (
        <PlayerDetails
          player={player}
          shouldTruncate={_shouldTruncate}
          size="small"
        />
      )}
    </div>
  );
};
