export enum AUTH_STATE {
  NOT_LOGGED_IN = 'not-logged-in',
  LOADING = 'loading',
  INITING = 'initing',
  LOGGED_IN = 'logged-in',
  DATA_LOADED = 'data-loaded',
  ONBOARDING = 'onboarding',
  READY = 'ready',
}

export interface ISwitchAdminUserTeamPostData {
  team_id: string;
  user_id: string;
}
