import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {TeamRoutes} from './team.routes';
import {useTeams} from 'context/team.context';
import {useAuth} from 'context/auth.context';

export const DashboardRoutes = () => {
  const _auth = useAuth();
  const _teamsContext = useTeams();

  return (
    <Routes>
      <Route
        path=""
        element={
          <Navigate
            to={
              _teamsContext.currentlyObservingOpponentId ||
              _auth.user?.team.toString() ||
              ''
            }
            replace
          />
        }
      />
      <Route path=":team/*" element={<TeamRoutes />}></Route>
    </Routes>
  );
};
