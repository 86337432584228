import React from 'react';

import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import {IPlayersColDef} from '../players-table-row.view';
import {TSortParams} from '@my-game-plan/types';
import {SxProps, Theme} from '@mui/material';

interface IPlayersTableThProps {
  colDef: IPlayersColDef;
  sort?: TSortParams;
  onSort: (sortParams: TSortParams) => void;
}

function PlayersTableTh(props: IPlayersTableThProps): JSX.Element {
  /*
   * Hooks n State
   */

  /*
   * Side effects
   */

  /*
   * Handlers
   */
  function _onSortClick() {
    if (!props.colDef.sortKey) {
      return;
    }

    const _directionToSet = props.sort?.order === 1 ? -1 : 1;

    props.onSort({
      key: props.colDef.sortKey,
      order: _directionToSet,
    });
  }

  /*
   * Render
   */
  let _Icon = null;
  const _iconStyle: SxProps<Theme> = {
    position: 'absolute',
    right: -16,
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: 12,
  };
  if (props.sort?.key === props.colDef.sortKey && props.sort?.order === 1) {
    _Icon = <ArrowUpwardIcon sx={_iconStyle} />;
  } else if (
    props.sort?.key === props.colDef.sortKey &&
    props.sort?.order === -1
  ) {
    _Icon = <ArrowDownwardIcon sx={_iconStyle} />;
  }
  return (
    <TableCell
      key={props.colDef.key}
      align={props.colDef.align}
      sortDirection="asc">
      {props.colDef.label && (
        <Stack
          bgcolor="transparent"
          border="none"
          direction="row"
          alignItems="center"
          display="inline-flex"
          component="button"
          p={0}
          onClick={_onSortClick}
          position="relative"
          textTransform="uppercase"
          color="secondary.main"
          fontWeight={500}
          fontSize={12}>
          {props.colDef.label}
          {_Icon}
        </Stack>
      )}
    </TableCell>
  );
}

export default PlayersTableTh;
