import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Screen from '../screen/screen.view';
import Stack from '@mui/material/Stack';
import {LOADING_STATE, TOverviewScreenViewType} from 'types/screen.types';
import {useTranslation} from 'react-i18next';
import TrackerCreateButton from '../trackers/misc/tracker-create-button/tracker-create-button.view';
import {
  FIAutomationDataQueryParams,
  FIEventAutomationPostData,
  FIEventFilters,
  FIFormattedAutomation,
  FIPlayerWithData,
  TTrackerExecutorType,
} from '@my-game-plan/types';

import {
  IAutomationFilters,
  TFilterOptions,
} from 'types/event-automations-misc.types';
import CustomEmptyState from '../error-states/CustomEmptyState';
import PerformanceCardsOverview from './cards/performance-cards-overview.view';

import {
  OverviewScreenProvider,
  useOverviewScreen,
} from 'context/overview-screen-context';
import HeaderFilterBar from '../filter/header-filter-bar/header-filter-bar.view';
import OverviewScreenHero from './overview-screen-hero';

const LOADING_STATES_SHOWING_DATA: LOADING_STATE[] = [
  LOADING_STATE.LOADING,
  LOADING_STATE.SUCCESS,
];
export interface IOverviewScreenView<
  T = FIPlayerWithData | FIFormattedAutomation,
> {
  loadingState: LOADING_STATE;
  type: TOverviewScreenViewType;
  data: T[];
  error?: string;
  availableFilterOptions: TFilterOptions;
  getData: (
    queryParams?: FIAutomationDataQueryParams,
    optionalEventFilters?: FIEventFilters,
    subjectId?: string,
  ) => void;
}
interface IOverviewScreenProps {
  title?: string;
  executorType: TTrackerExecutorType;
  views: IOverviewScreenView[];
  filters: FIAutomationDataQueryParams | null;
  onFiltersChange: (filters: FIAutomationDataQueryParams | null) => void;
  canSelectPlayers?: boolean;
  canSelectOpponent?: boolean;
  newTrackerData: Partial<FIEventAutomationPostData>;
}

function OverviewScreenWithoutContext(
  props: IOverviewScreenProps,
): JSX.Element {
  /*
   * Hooks n State
   */

  const _overviewScreenContext = useOverviewScreen();
  const {t} = useTranslation();

  const NAMESPACE =
    _overviewScreenContext.executorType === 'opponent_analysis'
      ? 'opponentAutomations'
      : 'trackers';

  /*
   * Handlers
   */

  /*
   * Render
   */

  let _Content = null;

  const _filters: IAutomationFilters = {
    players: _overviewScreenContext.selectedPlayerIds,
  };

  if (
    _overviewScreenContext.currentView?.loadingState === LOADING_STATE.ERROR
  ) {
    _Content = (
      <CustomEmptyState
        imageType="error"
        header={t('error-states.not-found.header')}
        description={t('error-states.not-found.description')}
      />
    );
  } else if (
    _overviewScreenContext.currentView?.loadingState ===
      LOADING_STATE.SUCCESS &&
    !_overviewScreenContext.currentView.data.length
  ) {
    _Content = (
      <CustomEmptyState
        header={t(`eventAutomations.empty.${NAMESPACE}.header`)}
        description={t(`eventAutomations.empty.${NAMESPACE}.description`)}
      />
    );
  } else if (
    _overviewScreenContext.currentView &&
    LOADING_STATES_SHOWING_DATA.includes(
      _overviewScreenContext.currentView?.loadingState,
    ) &&
    _overviewScreenContext.currentView.type === 'cards'
  ) {
    _Content = (
      <PerformanceCardsOverview
        selectedFilters={_filters}
        data={_overviewScreenContext.currentView.data as FIPlayerWithData[]}
      />
    );
  }

  // Render main content based on current view state
  // Create button
  const _createButtonTitle = t('eventAutomations.trackersNew');

  return (
    <Screen
      loadingState={
        _overviewScreenContext.currentView?.loadingState ||
        LOADING_STATE.INITING
      }
      title={props.title}
      // displayHeaderBorder
      coloredHeader={props.title ? true : false}
      HeaderBottomContent={
        <Stack direction="row" gap={4}>
          <Box flex={1}>
            <HeaderFilterBar
              timeFilters={{
                filters:
                  _overviewScreenContext.timeAndBenchmarkFilters?.own_data
                    ?.time,
                onFiltersChange: _overviewScreenContext.timeFilterChangeHandler,
              }}
              benchmark={{
                benchmarkOptions:
                  _overviewScreenContext.timeAndBenchmarkFilters?.benchmark,
                onBenchmark: _overviewScreenContext.benchmarkHandler,
              }}
              playerFilter={
                props.canSelectPlayers
                  ? {
                      includeTeam: props.executorType === 'team_performance',
                      selectedPlayerIds:
                        _overviewScreenContext.selectedPlayerIds,
                      onPlayerSelect: _overviewScreenContext.onPlayerSelect,
                    }
                  : undefined
              }
              onOpponentSelect={
                props.canSelectOpponent
                  ? _overviewScreenContext.onOpponentSelect
                  : undefined
              }
              screen={`overview-${_overviewScreenContext.executorType}`}
            />
          </Box>
        </Stack>
      }>
      <Stack gap={4}>
        {/* Optional Hero */}
        {_overviewScreenContext.shouldIncludeTeamPerformance && (
          <OverviewScreenHero
            parentLoadingState={
              _overviewScreenContext.currentView?.loadingState
            }
            filters={
              _overviewScreenContext.timeAndBenchmarkFilters || undefined
            }
          />
        )}

        {/* Screen */}
        <Stack position="relative">
          {_Content}
          <TrackerCreateButton
            type={_overviewScreenContext.executorType}
            optionalActionInfo={props.newTrackerData}
            Button={
              <Fab
                color="primary"
                aria-label="add"
                sx={{position: 'fixed', bottom: '1rem', right: '1rem'}}
                title={_createButtonTitle}>
                <AddIcon />
              </Fab>
            }
          />
        </Stack>
      </Stack>
    </Screen>
  );
}

function OverviewScreen(props: IOverviewScreenProps): JSX.Element {
  return (
    <OverviewScreenProvider {...props}>
      <OverviewScreenWithoutContext {...props} />
    </OverviewScreenProvider>
  );
}

export default OverviewScreen;
