import React from 'react';

import overviewStyles from 'components/dashboard/stylesheet/overview.module.scss';

import Loader from 'components/common/Loader/Loader.view';

import {TGeneratedContentType} from '@my-game-plan/types';
import CustomEmptyState from 'components/error-states/CustomEmptyState';
import {useTranslation} from 'react-i18next';

import ScreenContent from 'components/screen/screen-content.view';

interface IOverviewScreenProps<T> {
  title: string;
  data: T[];
  renderCard: (item: T) => JSX.Element;
  keyExtractor: (item: T) => string;
  isLoading?: boolean;
  isInitialised?: boolean;
  error?: boolean;
  CreateButton?: JSX.Element;
  namespace: TGeneratedContentType;
}

const NAMESPACE_MAP: Record<TGeneratedContentType, string> = {
  'single-send': 'singleSends.empty',
  'opponent-automation': 'eventAutomations.opponentAutomations',
  tracker: 'eventAutomations.trackers',
  'shadow-tracker': 'eventAutomations.trackers',
};

function OverviewCardsScreen<T>(props: IOverviewScreenProps<T>): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Handlers
   */

  /*
   * Render
   */

  let _Content = null;
  if (props.data.length) {
    _Content = (
      <div className={overviewStyles.grid}>
        {props.data.map((item) => {
          const _key = props.keyExtractor(item);
          return (
            <div className={overviewStyles.gridItem} key={_key}>
              {props.renderCard(item)}
            </div>
          );
        })}
      </div>
    );
  } else if (!props.isLoading && props.isInitialised) {
    _Content = (
      <div className={overviewStyles.content}>
        <CustomEmptyState
          header={t(`${NAMESPACE_MAP[props.namespace]}.header`)}
          description={t(`${NAMESPACE_MAP[props.namespace]}.description`)}
        />
      </div>
    );
  }

  return (
    <ScreenContent>
      {props.CreateButton}
      {_Content}
      <Loader isVisible={Boolean(props.isLoading || !props.isInitialised)} />
    </ScreenContent>
  );
}

export default OverviewCardsScreen;
