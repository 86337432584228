import React, {useEffect} from 'react';
import ExecutorDeepDive from 'components/executor-deep-dive/executor-deep-dive';
import {useAnalytics} from 'context/analytics.context';
import ANALYTICS_EVENT from 'config/analytics/event-names.config';

function OpponentAutomationsView(): JSX.Element {
  /*
   * Hooks n State
   */
  const _analyticsContext = useAnalytics();

  /*
   * Side effects
   */
  useEffect(() => {
    _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_OPPONENT_AUTOMATIONS);
  }, []);

  /*
   * Render
   */
  return <ExecutorDeepDive executorType="opponent_analysis" />;
}

export default OpponentAutomationsView;
