import {
  FITrackerSubject,
  PlayerPosition,
  TTrackerExecutorType,
} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExecutorShareButton from './share/executor-share-button';
import ExecutorDeleteButton from './executor-delete-button';
import {useTeams} from 'context/team.context';
import {
  formatSingleExecutor,
  getPositionOptions,
} from 'helpers/automation/automation-form.helper';

import ExecutorPreviewToggle from './list-item/executor-preview-toggle';
import CustomAutocomplete from 'components/material-customised/autocomplete/autocomplete.view';
import {ITypedOption} from 'types/option.types';
import {useTranslation} from 'react-i18next';

interface IExecutorListItemProps {
  subject: FITrackerSubject;
  subjectIndex: number;
  onChange: (subject: FITrackerSubject, subjectIndex: number) => void;
  executorType: TTrackerExecutorType;
  onDelete: (subjectIndex: number) => void;
  against: boolean;
}

function ExecutorListItem(props: IExecutorListItemProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const [_positionOptions, _setPositionOptions] = useState<
    ITypedOption<PlayerPosition>[]
  >([]);
  const [_selectedPositionOptions, _setSelectedPositionOptions] = useState<
    ITypedOption<PlayerPosition>[]
  >([]);

  /*
   * Side effects
   */
  // Define positions options
  useEffect(() => {
    _setPositionOptions(getPositionOptions());
  }, []);

  useEffect(() => {
    const _newSelectedPositionOptions: ITypedOption<PlayerPosition>[] = [];
    if (props.subject.executor.team?.executor_positions) {
      props.subject.executor.team.executor_positions.forEach((position) => {
        const _option = _positionOptions.find(
          (option) => option.value === position,
        );
        if (_option) {
          _newSelectedPositionOptions.push(_option);
        }
      });
    }

    _setSelectedPositionOptions(_newSelectedPositionOptions);
  }, [props.subject, _positionOptions]);

  /*
   * Handlers
   */
  function _onDelete() {
    props.onDelete(props.subjectIndex);
  }

  function _onPositionsChange(
    data: ITypedOption<PlayerPosition>[] | ITypedOption<PlayerPosition> | null,
  ) {
    const _updatedSubjectData = {...props.subject};
    if (data && Array.isArray(data)) {
      const _selectedPositions = data.map((option) => option.value);
      let _team = {};
      if (_selectedPositions.length) {
        _team = {executor_positions: _selectedPositions};
      }

      _updatedSubjectData.executor.team = _team;
    } else {
      // TODO - Handle single pos
    }

    _onChange(_updatedSubjectData);
  }

  function _onChange(subject: FITrackerSubject) {
    props.onChange(subject, props.subjectIndex);
  }

  /*
   * Render
   */

  function _groupHeaderKey(text: string): string {
    return t(`playerPosition.${text}s`);
  }

  let _PrimaryListItemContent = null;
  const _formattedValue = formatSingleExecutor(
    props.subject.executor,
    _teamsContext.ownPlayers,
  );

  if (props.executorType === 'player_development') {
    _PrimaryListItemContent = _formattedValue;
  } else {
    // Not sure if CustomAutocomplete should be used. We need a more common component to select positions and/or players
    _PrimaryListItemContent = (
      <CustomAutocomplete<PlayerPosition, true>
        disableCloseOnSelect
        multiple
        isInlineInput
        options={_positionOptions}
        value={_selectedPositionOptions}
        onChange={_onPositionsChange}
        groupHeader={_groupHeaderKey}
        tagsSentenceValue={_formattedValue}
        size="small"
        placeholder={t('sentenceForm.players.any')}
      />
    );
  }

  return (
    <ListItem>
      <ListItemIcon sx={{minWidth: 0}}>
        <ExecutorPreviewToggle subjectIndex={props.subjectIndex} />
      </ListItemIcon>
      <ListItemText
        primary={_PrimaryListItemContent}
        sx={{pl: 1, minWidth: 200, flexGrow: 1}}
      />
      <ListItemIcon sx={{pl: 2, minWidth: 0}}>
        <ExecutorShareButton
          subject={props.subject}
          executorType={props.executorType}
          onChange={_onChange}
          against={props.against}
        />
      </ListItemIcon>
      <ListItemIcon sx={{minWidth: 0}}>
        <ExecutorDeleteButton onClick={_onDelete} />
      </ListItemIcon>
    </ListItem>
  );
}

export default ExecutorListItem;
