import React, {useEffect} from 'react';
import {useAuth} from './context/auth.context';
import {AUTH_STATE} from './types/user/user.types';
import {Navigation} from './routes/navigation';
import {initAnalytics} from './config/analytics';
import {useTeams} from './context/team.context';
import {useMatches} from './context/matches.context';
import {useZones} from './context/zones.context';
import {useCompetitions} from './context/competitions.context';
import AppLoader from './components/common/app-loader';

import LoginScreen from './views/onboarding/login-screen';
import TeamProfileSetup from './views/onboarding/team-profile-setup';

function App(): JSX.Element {
  /*
   * Hooks n State
   */
  const _authContext = useAuth();
  const _teamsContext = useTeams();
  const _matchesContext = useMatches();
  const _zonesContext = useZones();
  const _competitionsContext = useCompetitions();

  /*
   * Side effects
   */

  /* On mount, init analytics */
  useEffect(() => {
    initAnalytics();
  }, []);

  /* Dashboards - Handle team change. To be removed.*/
  useEffect(() => {
    if (_teamsContext.current) {
      _teamsContext.getFormations();
      _teamsContext.getPlayers();
      _matchesContext.getMatchesByTeam(_teamsContext.current._id);
    }
  }, [_teamsContext.current]);

  /* Load resources on user change */
  useEffect(() => {
    _fetchResources();
  }, [_authContext.user]);

  /* If resources are loaded and team preferences exist, show app or onboarding */
  useEffect(() => {
    if (
      !_teamsContext.preferences ||
      _authContext.authState !== AUTH_STATE.DATA_LOADED
    ) {
      return;
    }

    const _teamProfile = _teamsContext.preferences?.profile_play_style?.find(
      (p) => p.profile_type === 'team',
    );

    if (!_teamProfile) {
      _authContext.setAuthState(AUTH_STATE.ONBOARDING);
      return;
    }

    _authContext.setAuthState(AUTH_STATE.READY);
  }, [_teamsContext.preferences, _authContext.authState]);

  /*
   * Handlers
   */
  async function _fetchResources() {
    if (!_authContext.user) {
      return;
    }

    await _teamsContext.getAll(_authContext.user._id);
    await _teamsContext.getOwnPlayers();
    await _zonesContext.getZones();
    await _matchesContext.getMatchesByTeam(_authContext.user.team, true);
    await _competitionsContext.getAll();

    _authContext.setAuthState(AUTH_STATE.DATA_LOADED);
  }

  /*
   * Render
   */
  /* Login screen */
  if (_authContext.authState === AUTH_STATE.NOT_LOGGED_IN) {
    return <LoginScreen />;
  }

  /* Onboarding */
  if (_authContext.authState === AUTH_STATE.ONBOARDING) {
    return <TeamProfileSetup />;
  }

  /* Ready */
  if (_authContext.authState === AUTH_STATE.READY) {
    return <Navigation />;
  }

  /* Default state, return loader */
  return <AppLoader />;
}

export default App;
