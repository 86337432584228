import React from 'react';
import {useTranslation} from 'react-i18next';
import {useOverviewDashboards} from 'context/dashboard/overview-dashboards.context';
import CreateIcon from '@mui/icons-material/Create';
import IconButton from '@mui/material/IconButton';

export const EditOvervieDashboardButton = () => {
  const {t} = useTranslation();
  const {setIsEditing, setIsModalOpen} = useOverviewDashboards();

  const handleEdit = () => {
    setIsEditing(true);
    setIsModalOpen(true);
  };

  return (
    <IconButton
      color="secondary"
      onClick={handleEdit}
      title={t('overview-dashboards.edit.button')}>
      <CreateIcon />
    </IconButton>
  );
};
