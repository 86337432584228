import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import AddIcon from '@mui/icons-material/Add';

import PlayerObjectiveCard from 'components/player-objectives/player-objective-card.view';
import PlayerObjectiveCreateButton from 'components/player-objectives/player-objective-create-button.view';
import {LOADING_STATE} from 'types/screen.types';
import CustomEmptyState from 'components/error-states/CustomEmptyState';
import {useExecutorDeepDive} from 'context/executor-deep-dive.context';
import {useAnalytics} from 'context/analytics.context';
import ANALYTICS_EVENT from 'config/analytics/event-names.config';

function ExecutorObjectivesTab(): JSX.Element {
  /* Hooks n State
   */
  const {t} = useTranslation();
  const _analyticsContext = useAnalytics();
  const _executorDeepDiveContext = useExecutorDeepDive();

  /*
   * Side effects
   */
  /* Log initial mixpanel event */
  useEffect(() => {
    if (_executorDeepDiveContext.analyticsData) {
      _analyticsContext.trackEvent(ANALYTICS_EVENT.PLAYER_PAGE_VIEWED_TAB, {
        ..._executorDeepDiveContext.analyticsData,
        tab: 'objectives',
      });
    }
  }, [_executorDeepDiveContext.analyticsData]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  let _Content = null;
  if (_executorDeepDiveContext.objectivesLoadingState === LOADING_STATE.ERROR) {
    _Content = (
      <CustomEmptyState
        imageType="error"
        header={t('error-states.not-found.header')}
        description={t('error-states.not-found.description')}
      />
    );
  } else if (
    _executorDeepDiveContext.objectivesLoadingState === LOADING_STATE.SUCCESS &&
    !_executorDeepDiveContext.objectives.length
  ) {
    _Content = (
      <CustomEmptyState
        header={t('playerObjectives.noObjectives')}
        primaryAction={
          <PlayerObjectiveCreateButton
            playerId={_executorDeepDiveContext.player?._id || ''}
            Button={
              <Button
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}>
                {t('playerObjectives.new')}
              </Button>
            }
          />
        }
      />
    );
  } else {
    _Content = (
      <Grid container spacing={2} alignItems="stretch">
        {_executorDeepDiveContext.objectives.map((objective) => {
          return (
            <Grid item lg={4} md={6} xs={12} key={objective._id} display="flex">
              <PlayerObjectiveCard objective={objective} />
            </Grid>
          );
        })}

        <PlayerObjectiveCreateButton
          playerId={_executorDeepDiveContext.player?._id || ''}
          Button={
            <Fab
              title={t('playerObjectives.new')}
              color="primary"
              aria-label="add"
              sx={{position: 'fixed', bottom: '1rem', right: '1rem'}}>
              <AddIcon />
            </Fab>
          }
        />
      </Grid>
    );
  }
  return _Content;
}

export default ExecutorObjectivesTab;
