import React, {useEffect, useState} from 'react';
import TrackerSentenceSelect from '../../variants/select/sentence-select-segment';
import {ITypedOption} from 'types/option.types';
import {ITrackerSentenceInputProps} from 'types/trackers/tracker-sentence.types';
import {useTeams} from 'context/team.context';
import {useTranslation} from 'react-i18next';
import SentenceTextSegment from '../../variants/text/sentence-text-segment';

type TObservingTeam = 'by' | 'against';
function TeamSelectSegment(props: ITrackerSentenceInputProps) {
  /*
   * Hooks n State
   */
  const _teamsContext = useTeams();
  const {t} = useTranslation();
  const [_options, _setOptions] = useState<ITypedOption<TObservingTeam>[]>([]);
  const [_selectedValue, _setSelectedValue] = useState<TObservingTeam>(
    props.value?.against ? 'against' : 'by',
  );
  const [_teamOptions, _setTeamOptions] = useState<ITypedOption<string>[]>([]);

  /*
   * Side Effects
   */
  // Define options
  useEffect(() => {
    let _teamLabel = t('sentenceForm.observing.entireTeam');
    const _againstPrefix = t('sentenceForm.observing.teamPlaying');

    if (_teamsContext.ownTeam) {
      _teamLabel = _teamsContext.ownTeam.name;
    }

    if (props.currentExecutorType === 'opponent_analysis') {
      _teamLabel = t('sentenceForm.observing.nextOpponent');

      const _opponent = _teamsContext.all.find(
        (team) => team._id === _teamsContext.currentlyObservingOpponentId,
      );
      if (_opponent) {
        _teamLabel = _opponent.name;
      }
    }

    const _generatedOptions: ITypedOption<TObservingTeam>[] = [
      {
        label: _teamLabel,
        value: 'by',
      },
      {
        label: `${_againstPrefix} ${_teamLabel}`,
        value: 'against',
      },
    ];

    _setOptions(_generatedOptions);
  }, [
    props.currentExecutorType,
    _teamsContext.all,
    _teamsContext.currentlyObservingOpponentId,
  ]);

  // Define selected value
  useEffect(() => {
    _setSelectedValue(props.value?.against ? 'against' : 'by');
  }, [props.value]);

  // Remove own team from team options
  useEffect(() => {
    const _filteredOptions = _teamsContext.opponentsOptions.filter(
      (option) => option.value !== _teamsContext.ownTeam?._id,
    );
    _setTeamOptions(_filteredOptions);
  }, [_teamsContext.opponentsOptions, _teamsContext.ownTeam]);

  /*
   * Handlers
   */

  function _onTeamSelect(value: TObservingTeam) {
    const _againstValue = value === 'against';
    if (props.onChange) {
      props.onChange({against: _againstValue});
    }
  }

  function _valueFormatter(value: TObservingTeam) {
    let _formattedValue = t('sentenceForm.entireTeam');
    const _matchingOptions = _options.filter((o) => o.value === value);
    if (_matchingOptions.length) {
      _formattedValue = _matchingOptions[0].label;
    }
    return _formattedValue;
  }

  /*
   * Render
   */

  const {value, valueFormatter, ..._commonProps} = props;

  if (props.canSelectOpponent) {
    let _TeamPlayingPrefixText = null;
    if (props.tracker?.against) {
      _TeamPlayingPrefixText = (
        <SentenceTextSegment
          {..._commonProps}
          value={t('sentenceForm.observing.teamPlaying')}
        />
      );
    }
    return (
      <>
        {_TeamPlayingPrefixText}
        <TrackerSentenceSelect
          {..._commonProps}
          options={_teamOptions}
          onChange={_teamsContext.setCurrentlyObservingOpponentId}
          value={_teamsContext.currentlyObservingOpponentId}
          readonly={false}
        />
      </>
    );
  }

  return (
    <TrackerSentenceSelect
      {..._commonProps}
      options={_options}
      value={_selectedValue}
      onChange={_onTeamSelect}
      valueFormatter={_valueFormatter}
    />
  );
}

export default TeamSelectSegment;
