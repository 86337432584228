import React, {useEffect, useState} from 'react';

import Logo from '../Logo/Logo';
import {FIMatch, FMatch, TCompetitionType} from '@my-game-plan/types';
import CompetitionIcon from '../competition-icon/competition-icon.view';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import {isNullOrUndefined} from 'helpers/general/general.helper';

type MatchScoreProps = {
  match: FIMatch | FMatch;
  displayDateIfNotPlayed?: boolean;
  displayTeamNames?: boolean;
};

export const MatchScore = ({
  match,
  displayDateIfNotPlayed = false,
  displayTeamNames = false,
}: MatchScoreProps) => {
  /*
   * Hooks n State
   */
  const [_competitionType, _setCompetitionType] =
    useState<TCompetitionType>('domestic_league');

  /*
   * Side Effects
   */
  // Define whether to show cup icon
  useEffect(() => {
    if ('competition_type' in match) {
      _setCompetitionType(match.competition_type);
    }
  }, [match]);

  /*
   * Render
   */
  let _ScoreContent = (
    <Typography color="text.disabled" variant="body2">
      -
    </Typography>
  );

  if (
    !isNullOrUndefined(match.home_team_end_score) &&
    !isNullOrUndefined(match.away_team_end_score)
  ) {
    _ScoreContent = (
      <Typography variant="body2">{`${match.home_team_end_score} - ${match.away_team_end_score}`}</Typography>
    );
  } else if (displayDateIfNotPlayed) {
    _ScoreContent = (
      <Typography color="text.disabled" variant="body2">
        {moment(match.date).format('D MMM')}
      </Typography>
    );
  }

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Logo src={match.home_team?.image_url || ''} size="small" />
      {_ScoreContent}
      <Logo src={match.away_team?.image_url || ''} size="small" />
      {displayTeamNames && (
        <Typography variant="body2">
          {match.home_team.name} - {match.away_team.name}
        </Typography>
      )}
      <CompetitionIcon competition_type={_competitionType} size="small" />
    </Stack>
  );
};
