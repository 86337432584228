import {Resource} from 'types/api.types';
import {MATCHES_API_CONFIG} from 'config/api.config';
import {useAPI} from 'hooks/api.hooks';
import {
  FIMatch,
  FIMatchVideoUploadPostData,
  TVideoSourceType,
} from '@my-game-plan/types';
import {REQUEST_ERRORS} from 'config/errors.config';

const api = useAPI();

export const getMatchesByTeam = async (
  _team: string | undefined,
): Promise<FIMatch[]> => {
  try {
    const {data} = await api.get<FIMatch[]>({
      resource: Resource.matches,
      url: MATCHES_API_CONFIG.getMatchesByTeam(_team),
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch {
    return [];
  }
};

export async function updateMatchVideo(
  matchId: string,
  postData: Partial<FIMatchVideoUploadPostData>,
): Promise<FIMatch> {
  try {
    const _URL = `matches/${matchId}/video`;
    const {data} = await api.put<FIMatch>({
      data: postData,
      url: _URL,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function updateMatchVideoOffsets(
  matchId: string,
  sourceType: TVideoSourceType,
  offsets: number[],
): Promise<FIMatch> {
  try {
    const _URL = `matches/${matchId}/video/${sourceType}/offsets`;
    const {data} = await api.put<FIMatch>({
      data: offsets,
      url: _URL,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}
