import React, {useEffect, useState} from 'react';

import {ButtonProps, ButtonTypeMap} from '@mui/material/Button';

import {
  FIEventAutomationPostData,
  FIFormattedAutomation,
  TTrackerExecutorType,
} from '@my-game-plan/types';
import {formattedAutomationToPostData} from 'helpers/event-automations.helper';
import {
  AutomationFormProvider,
  useAutomationForm,
} from 'context/event-automations/automations-form.context';

import {ExtendButtonBase} from '@mui/material';
import TrackerBuilderDialog from 'components/trackers/builder/tracker-builder-dialog';
import {ILinkState} from 'types/navigation.types';
import {hasMultipleExecutors} from 'helpers/automation/automation-form.helper';
import PreEditExecutorDialog from '../../builder/dialog/pre-edit-executor-dialog';

interface ITrackerCreateButtonProps {
  data?: FIFormattedAutomation;
  type: TTrackerExecutorType;
  backLink?: ILinkState;
  displayPreEditDialog?: boolean;
  onClose?: () => void;
  onSubmit?: (
    savedAutomation: FIFormattedAutomation | null,
    wasDeleted?: boolean,
  ) => void;
  optionalActionInfo?: Partial<FIEventAutomationPostData>;
  Button?: React.ReactElement<ButtonProps, ExtendButtonBase<ButtonTypeMap>>;
}

function TrackerCreateButtonContent(props: ITrackerCreateButtonProps) {
  /*
   * Hooks n State
   */

  const _automationFormContext = useAutomationForm();
  const [_data, _setData] = useState<
    Partial<FIEventAutomationPostData> | undefined
  >(undefined);
  const [_shouldDisplayPreEditDialog, _setShouldDisplayPreEditDialog] =
    useState<boolean>(false);
  const [_isPreEditDialogOpen, _setIsPreEditDialogOpen] =
    useState<boolean>(false);

  /*
   * Format data
   */

  useEffect(() => {
    if (props.data) {
      let _newData: Partial<FIEventAutomationPostData> | undefined = undefined;
      if (props.data) {
        _newData = formattedAutomationToPostData(props.data);
      }

      _setData(_newData);
    }
  }, [props.data]);

  useEffect(() => {
    if (_automationFormContext.isOpened) {
      _automationFormContext.initForm(
        props.type,
        _data,
        props.optionalActionInfo,
      );
    }
  }, [
    _data,
    props.type,
    _automationFormContext.isOpened,
    props.optionalActionInfo,
  ]);

  useEffect(() => {
    const _newShouldDisplayDialog = Boolean(
      props.displayPreEditDialog &&
        props.data &&
        hasMultipleExecutors(props.data),
    );
    _setShouldDisplayPreEditDialog(_newShouldDisplayDialog);
  }, [props.type, props.data, props.displayPreEditDialog]);

  /*
   * Handlers: Builder Modal
   */
  function _handleOpenBuilderModal() {
    if (props.onClose) {
      props.onClose();
    }
    _automationFormContext.initForm(
      props.type,
      _data,
      props.optionalActionInfo,
    );
  }

  function _onButtonClick() {
    if (_shouldDisplayPreEditDialog) {
      _setIsPreEditDialogOpen(true);
    } else {
      _handleOpenBuilderModal();
    }

    // if (props.Button?.props.onClick) {
    //   props.Button.props.onClick(event);
    // }
  }

  function _onPreEditDialogClose() {
    _setIsPreEditDialogOpen(false);
  }

  function _onPreEditDialogEditAll() {
    _setIsPreEditDialogOpen(false);
    _handleOpenBuilderModal();
  }

  function _onPreEditDialogEditSingle(
    data: Partial<FIEventAutomationPostData>,
  ) {
    _setIsPreEditDialogOpen(false);
    console.log('Edit single');
    _automationFormContext.initForm(props.type, undefined, data);
  }
  /*
   * Render
   */

  return (
    <>
      {/* {_Button} */}
      {props.Button &&
        React.cloneElement(props.Button, {
          onClick: () => _onButtonClick(),
        })}

      <TrackerBuilderDialog />

      {_data && (
        <PreEditExecutorDialog
          open={_isPreEditDialogOpen}
          onClose={_onPreEditDialogClose}
          onEditAllExecutors={_onPreEditDialogEditAll}
          onEditSingleExecutor={_onPreEditDialogEditSingle}
          executorType={props.type}
          data={_data}
        />
      )}
    </>
  );
}
function TrackerCreateButton(props: ITrackerCreateButtonProps) {
  return (
    <AutomationFormProvider onSubmit={props.onSubmit} backLink={props.backLink}>
      <TrackerCreateButtonContent {...props} />
    </AutomationFormProvider>
  );
}
export default TrackerCreateButton;
