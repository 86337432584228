import {REQUEST_ERRORS} from 'config/errors.config';
import {useAPI} from 'hooks/api.hooks';
import {FIPitchZone} from '@my-game-plan/types';

const api = useAPI();

export async function getZones(): Promise<FIPitchZone[]> {
  try {
    const URL = 'zones';
    const {data} = await api.get<FIPitchZone[]>({
      url: URL,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    return [];
  }
}
