import React from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';

import {ITypedOption} from 'types/option.types';

import styles from './segmented-control.module.scss';

interface ISegmentedControlProps<T> {
  options: ITypedOption<T>[];
  onChange: (value: T) => void;
  value?: T;
  info?: React.ReactNode;
  infoTitle?: string;
}

function SegmentedControl<T extends string | number>(
  props: ISegmentedControlProps<T>,
) {
  /* Hooks n State */

  /* Handlers */
  function _onChange(event: React.MouseEvent<HTMLElement>, value: string) {
    props.onChange(value as unknown as T);
  }

  /* Render */
  return (
    <div className={styles.container}>
      <ToggleButtonGroup exclusive onChange={_onChange} value={props.value}>
        {props.options.map((option) => {
          const _ButtonComponent = (
            <ToggleButton
              sx={{
                br: 1,
                paddingLeft: 2,
                paddingRight: 2,
                textTransform: 'none',
                color: 'text.disabled',
                '&.Mui-selected': {
                  color: 'text.primary',
                },
              }}
              size="small"
              color="secondary"
              key={option.value}
              value={option.value}>
              {option.label}
            </ToggleButton>
          );

          if (option.tooltipText) {
            return (
              <Tooltip
                key={option.value}
                title={option.tooltipText}
                placement="bottom">
                {_ButtonComponent}
              </Tooltip>
            );
          }

          return _ButtonComponent;
        })}
      </ToggleButtonGroup>
    </div>
  );
}

export default SegmentedControl;
