const PROFILE_SETUP_TRANSLATIONS = {
  skip: {
    cta: 'Skip',
    title: "Are you sure you want to skip setting up your team's  profile?",
    text: 'This step will help you get started and create a set of trackers to calculate team performance.',
    confirm: 'Skip setup',
  },
  team: {
    intro: {
      title: 'Set up style of play',
      subtitle:
        "Before exploring the platform, we recommend setting up your team's style of play to create a set of trackers.",
      cta: 'Get started',
    },
    profile: {
      title: 'Select team profile',
      subtitle:
        'To get started, choose a profile that best describes your style of play.',
      cta: 'Select profile',
    },
    trackers: {
      title: 'Select trackers per phase',
      subtitle:
        "For each phase of play, select the performance trackers you'd like to kick off with. You can customize all of these later.",
      cta: 'Create trackers',
    },
    done: {
      title: "You're all set!",
      subtitle:
        'A basic set of trackers has been created based on selected style of play.',
      cta: 'Dive right in',
    },
  },

  styleOfPlay: {
    control_possession: {
      title: 'Control possession',
      description: 'Focuses on maintaining possession to dominate the game.',
    },
    tiki_taka: {
      title: 'Tiki-taka',
      description:
        'High-tempo, intricate passing with constant movement to break down defenses.',
    },
    route_one: {
      title: 'Route one',
      description:
        'Direct long balls to quickly transition from defense to attack.',
    },
    gegenpress: {
      title: 'Gegenpress',
      description:
        'Intense pressing to win the ball back high up the pitch and counter quickly.',
    },
    counter: {
      title: 'Counter',
      description:
        'Soaks up pressure before launching fast, direct attacks on the break.',
    },
    wing_play: {
      title: 'Wing play',
      description:
        'Utilizes wide players to deliver crosses into the box for attacking opportunities.',
    },
  },
  categories: {
    chance_creation: 'Chance creation',
    defending_the_box: 'Defending the box',
    high_defending: 'High defending',
    build_up: 'Build up',
    defensive_transition: 'Defensive transition',
    offensive_transition: 'Offensive transition',
    defending_build_up: 'Defending build-up',
  },

  trackers: {
    trackersSelected_one: '{{count}} tracker selected',
    trackersSelected_other: '{{count}} trackers selected',
  },
};

export default PROFILE_SETUP_TRANSLATIONS;
