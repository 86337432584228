import React, {useEffect, useState} from 'react';
import {useDashboard} from 'context/dashboard/dashboard.context';
import {useOverviewDashboards} from 'context/dashboard/overview-dashboards.context';
import {useTeams} from 'context/team.context';
import {translateAction} from 'helpers/translation.helper';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubHeader from '@mui/material/ListSubheader';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {capitalize} from 'lodash';
import {lighten, useTheme} from '@mui/material';
import SegmentedControl from 'components/common/segmented-control/segmented-control.view';
import {ITypedOption} from 'types/option.types';
import i18n from 'localization';
import {NavLink, useParams} from 'react-router-dom';
import {getDashboardsOptionsForProvider} from 'helpers/dashboard/dashboard.helper';
import {useTranslation} from 'react-i18next';
import {ACTION_DASHBOARD_CATEGORIES} from 'config/dashboards.config';
import {IDashboardCategory} from 'types/action.types';
import {useCompetitions} from 'context/competitions.context';

type TDashboardType = 'overview' | 'dashboard';

const TYPE_OPTIONS: ITypedOption<TDashboardType>[] = [
  {
    label: i18n.t('overview-dashboards.nav'),
    value: 'overview',
  },
  {
    label: i18n.t('dashboard.deepDive'),
    value: 'dashboard',
  },
];

function DashboardActionPicker(): JSX.Element {
  /*
   * Hooks n State
   */
  const _theme = useTheme();
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const _competitionsContext = useCompetitions();
  const _dashboardsContext = useDashboard();
  const _overviewDashboardsContext = useOverviewDashboards();
  const _params = useParams();
  const [_title, _setTitle] = useState<string>('');
  const [_dropdownAnchorEl, _setDropdownAnchorEl] =
    useState<null | HTMLElement>(null);

  const [_currentDashboardType, _setCurrentDashboardType] =
    useState<TDashboardType>('overview');
  const [_overviewDashboardsOptions, _setOverviewDashboardsOptions] = useState<
    ITypedOption<string>[]
  >([]);
  const [_actionDashboardCategories, _setActionDashboardCategories] = useState<
    IDashboardCategory[]
  >([]);

  const [_activeDashboardId, _setActiveDashboardId] = useState<string>('');

  /*
   * Side effects
   */
  useEffect(() => {
    if (
      _overviewDashboardsContext.current &&
      _overviewDashboardsContext.isCustomDashboard(_activeDashboardId)
    ) {
      _setTitle(_overviewDashboardsContext.current.title);
    } else if (_dashboardsContext.current) {
      const _translatedAction = capitalize(
        translateAction(_dashboardsContext.current.action),
      );
      _setTitle(_translatedAction);
    }
  }, [
    _activeDashboardId,
    _dashboardsContext.current,
    _overviewDashboardsContext.current,
    _overviewDashboardsContext.isCustomDashboard,
  ]);

  // Set overview dashboards options
  useEffect(() => {
    if (!_overviewDashboardsContext.all || !_teamsContext.current) {
      return;
    }

    const _options = _overviewDashboardsContext.all.map((dashboard) => {
      return {
        label: dashboard.title,
        value: `/dashboards/${_teamsContext.current?._id}/${dashboard._id}`,
      };
    });
    _setOverviewDashboardsOptions(_options);
  }, [_overviewDashboardsContext.all, _teamsContext.current]);

  // Set action dashboard options
  useEffect(() => {
    if (!_competitionsContext.domesticCompetition || !_teamsContext.current) {
      return;
    }

    const _availableActions = getDashboardsOptionsForProvider(
      _competitionsContext.domesticCompetition.event_provider,
    );

    const _newCategories = ACTION_DASHBOARD_CATEGORIES.map((category) => {
      const _newActions = category.actions.filter((action) =>
        _availableActions.includes(action),
      );
      return {
        ...category,
        actions: _newActions,
      };
    });

    _setActionDashboardCategories(_newCategories);
  }, [_competitionsContext.domesticCompetition, _teamsContext.current]);

  // Define active dashboard type based on URL
  useEffect(() => {
    if (!_params.dashboard) {
      return;
    }
    if (_overviewDashboardsContext.isCustomDashboard(_params.dashboard)) {
      _setCurrentDashboardType('overview');
    } else {
      _setCurrentDashboardType('dashboard');
    }

    _setActiveDashboardId(_params.dashboard);
  }, [_params.dashboard]);

  /*
   * Handlers
   */
  function _onDropdownOpen(event: React.MouseEvent<HTMLElement>) {
    _setDropdownAnchorEl(event.currentTarget);
  }
  function _onDropdownClose() {
    _setDropdownAnchorEl(null);
  }

  function _onDashboardTypeChange(value: TDashboardType) {
    _setCurrentDashboardType(value);
  }

  function _onNewDashboardClick() {
    _overviewDashboardsContext.setIsEditing(false);
    _overviewDashboardsContext.setIsModalOpen(true);
  }

  /*
   * Render
   */
  const PAPER_BG = lighten(_theme.palette.background.paper, 0.05);
  const _isDropdownOpen = Boolean(_dropdownAnchorEl);
  return (
    <Stack direction="row" gap={2}>
      <Button
        variant="text"
        onClick={_onDropdownOpen}
        endIcon={
          _isDropdownOpen ? (
            <KeyboardArrowUpIcon sx={{color: 'text.secondary'}} />
          ) : (
            <KeyboardArrowDownIcon sx={{color: 'text.secondary'}} />
          )
        }>
        <Typography
          fontSize={24}
          fontWeight={600}
          textTransform="none"
          color="text.primary">
          {_title}
        </Typography>
      </Button>
      <Popover
        open={_isDropdownOpen}
        anchorEl={_dropdownAnchorEl}
        onClose={_onDropdownClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              maxHeight: 480,
              bgcolor: PAPER_BG,
              py: 2,
            },
          },
        }}>
        <Box px={2} pb={2}>
          <SegmentedControl
            options={TYPE_OPTIONS}
            value={_currentDashboardType}
            onChange={_onDashboardTypeChange}
          />
        </Box>
        <List
          subheader={<li />}
          sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: PAPER_BG,
            positliion: 'relative',
            overflow: 'auto',
            maxHeight: 300,
            '& ul': {padding: 0},
          }}>
          {_currentDashboardType === 'overview'
            ? _overviewDashboardsOptions.map((option) => {
                return (
                  <ListItem key={option.value} disablePadding>
                    <ListItemButton
                      component={NavLink}
                      to={option.value}
                      onClick={_onDropdownClose}
                      selected={option.value.includes(_activeDashboardId)}>
                      {option.label}
                    </ListItemButton>
                  </ListItem>
                );
              })
            : _actionDashboardCategories.map((category) => {
                return (
                  <li key={category.key}>
                    <ul>
                      <ListSubHeader>{category.label}</ListSubHeader>
                      {category.actions.map((action) => {
                        return (
                          <ListItem key={action} disablePadding>
                            <ListItemButton
                              component={NavLink}
                              to={`/dashboards/${_teamsContext.current?._id}/${action}`}
                              onClick={_onDropdownClose}
                              selected={action === _activeDashboardId}>
                              {capitalize(translateAction(action))}
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}

          {_currentDashboardType === 'overview' && (
            <ListItem disablePadding>
              <ListItemButton onClick={_onNewDashboardClick}>
                <ListItemIcon sx={{minWidth: 32}}>
                  <AddIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  primary={t('overview-dashboards.add.button')}
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'secondary.light',
                  }}
                />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Popover>
    </Stack>
  );
}

export default DashboardActionPicker;
