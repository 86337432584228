import {ITrackerSentenceInputProps} from 'types/trackers/tracker-sentence.types';
import React, {useEffect, useState} from 'react';
import SentenceTextSegment, {
  FONT_SIZE_MAP,
} from '../text/sentence-text-segment';

import ButtonBase from '@mui/material/ButtonBase';

import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import {MAX_POPUP_HEIGHT} from 'config/event-automation-builder.config';

interface ITrackerSentencePopupProps<T> extends ITrackerSentenceInputProps<T> {
  children?: React.ReactNode;
  onClose?: () => void;
  anchorEl?: Element | null;
  disablePadding?: boolean;
  isEmptyValue?: (value: T) => boolean;
}

interface ITrackerSentencePopupButtonProps<T>
  extends ITrackerSentenceInputProps<T> {
  LeftIcon?: React.ReactNode;
  onOpen: (element: HTMLElement) => void;
  isPopupOpen: boolean;
  isEmptyValue?: (value?: T) => boolean;
}

function TrackerSentencePopupPopover<T>(
  props: ITrackerSentencePopupProps<T>,
): JSX.Element {
  /*
   * Hooks n State
   */

  /*
   * Handlers
   */

  function _onClosePopup() {
    if (!props.onClose) {
      return;
    }
    props.onClose();
  }

  /*
   * Render
   */

  const _padding = props.disablePadding ? undefined : 1;
  return (
    <Popover
      open={Boolean(props.anchorEl)}
      anchorEl={props.anchorEl}
      onClose={_onClosePopup}
      anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
      slotProps={{
        paper: {
          sx: {maxHeight: MAX_POPUP_HEIGHT, p: _padding},
        },
      }}>
      {props.children || null}
    </Popover>
  );
}

function TrackerSentencePopupButton<T>(
  props: ITrackerSentencePopupButtonProps<T>,
) {
  /*
   * Hooks n State
   */
  const [_formattedValue, _setFormattedValue] = useState<string>('');

  /*
   * Side Effects
   */
  // Define formatted value
  useEffect(() => {
    let _newFormattedValue = '';
    let _hasValue = Boolean(props.value);

    if (props.isEmptyValue) {
      _hasValue = !props.isEmptyValue(props.value);
    }

    if (_hasValue && props.value && props.valueFormatter) {
      _newFormattedValue = props.valueFormatter(props.value);
    } else if (!_hasValue && props.placeholder) {
      _newFormattedValue = props.placeholder;
    }

    if (!props.readonly && props.required && !_hasValue) {
      _newFormattedValue += '*';
    }

    _setFormattedValue(_newFormattedValue);
  }, [
    props.value,
    props.placeholder,
    props.required,
    props.extraData,
    props.readonly,
    props.isEmptyValue,
  ]);

  /*
   * Handlers
   */
  function _onOpenPopup(event: React.MouseEvent<HTMLElement>) {
    props.onOpen(event.currentTarget);
  }

  /*
   * Render
   */
  if (props.readonly) {
    const {onChange, ..._commonProps} = props;
    return (
      <SentenceTextSegment
        {..._commonProps}
        value={_formattedValue}
        type="value"
        disabled={props.disabled}
      />
    );
  }

  let _hasValue = Boolean(props.value);

  if (props.isEmptyValue) {
    _hasValue = !props.isEmptyValue(props.value);
  }
  let _color = 'text.primary';
  if (props.disabled && !props.readonly) {
    _color = 'text.disabled';
  } else if (props.required && !_hasValue) {
    _color = 'secondary.main';
  }

  let _LeftIcon = null;
  if (props.LeftIcon) {
    _LeftIcon = props.LeftIcon;
  }

  let _borderColor = 'secondary.main';
  if (props.error) {
    _borderColor = 'error.main';
  } else if (props.isPopupOpen) {
    _borderColor = 'primary.main';
  }

  return (
    <ButtonBase
      onClick={_onOpenPopup}
      disabled={props.disabled}
      sx={{
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderColor: _borderColor,
        gap: 0.5,
        // flexWrap: 'wrap',
        alignItems: 'center',

        '&:hover': {
          borderColor: 'text.primary',
        },
        '&:disabled': {
          borderColor: 'transparent',
        },
      }}>
      {_LeftIcon}
      <Typography
        color={_color}
        fontSize={FONT_SIZE_MAP[props.size]}
        fontWeight={500}
        textTransform="none">
        {_formattedValue}
      </Typography>
    </ButtonBase>
  );
}

const TrackerSentencePopup = {
  Button: TrackerSentencePopupButton,
  Popup: TrackerSentencePopupPopover,
};

export default TrackerSentencePopup;
