import {REQUEST_ERRORS} from 'config/errors.config';
import {useAPI} from 'hooks/api.hooks';

import {
  FIPlayerObjective,
  FIPlayerObjectiveComment,
  FIPlayerObjectivePostData,
} from '@my-game-plan/types';

const api = useAPI();

/*
 * Player Objectives
 */
export async function getPlayerObjectives(
  playerId: string,
): Promise<FIPlayerObjective[]> {
  try {
    const URL = `player-objectives/all/${playerId}`;
    const {data} = await api.get<FIPlayerObjective[]>({
      url: URL,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function createPlayerObjective(
  postData: Partial<FIPlayerObjectivePostData>,
): Promise<FIPlayerObjective> {
  try {
    const URL = 'player-objectives';
    const {data} = await api.post<FIPlayerObjective>({
      url: URL,
      data: postData,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function editPlayerObjective(
  id: string,
  postData: Partial<FIPlayerObjectivePostData>,
): Promise<FIPlayerObjective> {
  try {
    const URL = `player-objectives/${id}`;
    const {data} = await api.put<FIPlayerObjective>({
      url: URL,
      data: postData,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function deletePlayerObjective(id: string): Promise<void> {
  try {
    const URL = `player-objectives/${id}`;

    await api.del({
      url: URL,
    });
  } catch (error) {
    throw new Error((error as any).message);
  }
}

/*
 * Comments
 */
export async function createObjectiveComment(
  objectiveId: string,
  comment: Partial<FIPlayerObjectiveComment>,
): Promise<FIPlayerObjective> {
  try {
    const URL = `player-objectives/${objectiveId}/comments`;
    const {data} = await api.post<FIPlayerObjective>({
      data: comment,
      url: URL,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function editObjectiveComment(
  objectiveId: string,
  commentId: string,
  postData: Partial<FIPlayerObjectiveComment>,
): Promise<FIPlayerObjective> {
  try {
    const URL = `player-objectives/${objectiveId}/comments/${commentId}`;

    const {data} = await api.put<FIPlayerObjective>({
      url: URL,
      data: postData,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function deleteObjectiveComment(
  objectiveId: string,
  commentId: string,
): Promise<void> {
  try {
    const URL = `player-objectives/${objectiveId}/comments/${commentId}`;
    await api.del({
      url: URL,
    });
  } catch (error) {
    throw new Error((error as any).message);
  }
}
