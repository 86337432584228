import React, {useEffect, useState} from 'react';

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';

import {DataGrid, GridColDef, GridRowSelectionModel} from '@mui/x-data-grid';

import ProfileTeamInfo from '../profile-team-info';
import {useTranslation} from 'react-i18next';
import {useProfileSetup} from 'context/profile-setup.context';

import TrackersTableSparkChart from 'components/trackers/table/cells/trackers-table-spark-chart';
import TrackersTablePercentileCell from 'components/trackers/table/cells/trackers-table-percentile-cell';
import {formatSingleScore} from 'helpers/automation/automation-detail-history.helper';
import {shouldInvertScore} from '@my-game-plan/types';

import TrackerSentence from 'components/trackers/sentence/tracker-sentence';
import {darken, useTheme} from '@mui/material';

function ProfileTrackersSelector(): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _theme = useTheme();

  const _profileSetupContext = useProfileSetup();
  const [_subtitle, _setSubtitle] = useState<string>('');
  const [_columns, _setColumns] = useState<GridColDef[]>([]);

  /*
   * Side effects
   */
  /* Define subtitle */
  useEffect(() => {
    if (!_profileSetupContext.selectedProfile) {
      _setSubtitle('');
      return;
    }
    const _translatedProfileName = t(
      `profileSetup.styleOfPlay.${_profileSetupContext.selectedProfile.style_of_play}.title`,
    );
    _setSubtitle(_translatedProfileName);
  }, [_profileSetupContext.selectedProfile]);

  /* Generate columns for DataGrid */
  useEffect(() => {
    const _generatedColumns: GridColDef[] = [
      {
        field: 'title',
        headerName: t('eventAutomations.trackers', {count: 1}),
        flex: 1,
        // width: 120,
      },
      {
        field: 'form',
        headerName: t('eventAutomations.properties.form'),
        // valueFormatter: (params) => {
        //   return 'Form';
        // },
        renderCell: (params) => {
          return (
            <div style={{width: '100%'}}>
              <TrackersTableSparkChart form={params.row.form} />
            </div>
          );
        },
        flex: 1,
      },
      {
        field: 'ranking',
        headerName: t('eventAutomations.benchmark.ranking.title'),
        renderCell: (params) => {
          if (
            typeof params.row.benchmark_scores?.benchmarked_score ===
            'undefined'
          ) {
            return (
              <Typography fontSize="inherit" color="text.disabled">
                {t('eventAutomations.values.notAvailable')}
              </Typography>
            );
          }
          return (
            <div style={{width: '100%'}}>
              <TrackersTablePercentileCell
                score={params.row.benchmark_scores?.benchmarked_score}
              />
            </div>
          );
        },
        flex: 1,
      },
      {
        field: 'average',
        headerName: t('eventAutomations.benchmark.ranking.averagePer90'),

        renderCell: (params) => {
          const _generatedFormattedAverage = formatSingleScore(
            'average',
            '',
            params.row.benchmark_scores?.average,
            params.row.benchmark_scores?.average -
              params.row.benchmark_scores?.benchmark_average,
            params.row.calculation === 'ratio' ||
              params.row.calculation === 'rule',
            undefined,
            shouldInvertScore(params.row),
          );
          if (!_generatedFormattedAverage) {
            return (
              <Typography fontSize="inherit" color="text.disabled">
                {t('eventAutomations.values.notAvailable')}
              </Typography>
            );
          }

          return (
            <Typography fontSize="inherit">
              {_generatedFormattedAverage?.formattedValue}
            </Typography>
          );
        },
      },
      {
        field: 'info',
        headerName: '',
        renderCell: (params) => {
          return (
            <>
              <Tooltip
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: darken(_theme.palette.background.default, 0.1),
                    },
                  },
                }}
                placement="bottom-end"
                title={
                  <TrackerSentence
                    readonly
                    tracker={params.row}
                    currentExecutorType="team_performance"
                    size="small"
                    hideExecutorIfPossible
                  />
                }>
                <IconButton size="small" color="secondary">
                  <InfoOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    ];
    _setColumns(_generatedColumns);
  }, []);

  function _onRowSelectionModelChange(newSelection: GridRowSelectionModel) {
    _profileSetupContext.onTrackersSelect(newSelection as string[]);
  }

  /*
   * Handlers
   */

  /*
   * Render
   */
  if (
    !_profileSetupContext.selectedProfile ||
    !_profileSetupContext.activeCategory
  ) {
    return null;
  }

  const _category =
    _profileSetupContext.selectedProfile.performance.categories.find(
      (category) => category.name === _profileSetupContext.activeCategory,
    );

  return (
    <Stack flexGrow={1}>
      <ProfileTeamInfo subtitle={_subtitle} />
      {/* Trackers */}
      <Stack flexGrow={1}>
        <DataGrid
          rows={_category?.automations || []}
          columns={_columns}
          checkboxSelection
          rowSelectionModel={
            _profileSetupContext.selectedTrackersPerCategory[
              _profileSetupContext.activeCategory
            ] || []
          }
          onRowSelectionModelChange={_onRowSelectionModelChange}
          getRowId={(row) => row._id}
          getRowHeight={() => 'auto'}
          disableColumnResize
          disableColumnFilter
          disableColumnSelector
          disableColumnSorting
          disableColumnMenu
          hideFooter
          sx={{
            border: 0,
            '& .MuiDataGrid-cell': {
              display: 'flex',
              alignItems: 'center',
              py: 2,
              px: 2,
              borderColor: 'divider',
              // width: 'auto',
            },
            '& .MuiDataGrid-cellCheckbox': {
              width: 'auto',
              pr: 0,
            },

            '& .MuiDataGrid-columnHeader': {
              // borderBottom: `1px solid ${_theme.palette.divider} !important`,
              borderBottom: 'none !important',
              px: 2,
              color: 'secondary.main',
              textTransform: 'uppercase',
              fontSize: 12,
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              // border: 0,
            },
            '& .MuiDataGrid-columnHeaderCheckbox': {
              width: 'auto !important',
              pr: 0,
            },
            // '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox)':
            //   {
            //     px: 2,
            //     color: 'secondary.main',
            //     textTransform: 'uppercase',
            //     fontSize: 12,
            //   },
            '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within':
              {
                outline: 'none',
              },

            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: 'transparent',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-filler > div': {
              borderColor: 'divider',
            },
          }}
        />
      </Stack>
    </Stack>
  );
}

export default ProfileTrackersSelector;
