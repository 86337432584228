import React from 'react';

import ExecutorDeepDive from 'components/executor-deep-dive/executor-deep-dive';

function TeamView(): JSX.Element {
  /*
   * Hooks n State
   */

  /*
   * Handlers
   */

  /*
   * Render
   */

  return <ExecutorDeepDive executorType="team_performance" />;
}

export default TeamView;
