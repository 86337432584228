import {FIDisplayPlayer, FIFormattedAutomation} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';
import ScoredTabs, {IScoredTab} from '../scored-tabs';
import {useTranslation} from 'react-i18next';
import {useExecutorDeepDive} from 'context/executor-deep-dive.context';
import {capitalize} from 'lodash';

import Stack from '@mui/material/Stack';
import {LOCAL_STORAGE_LAST_VIEWED_TAG_ID} from 'config/api.config';
import {ILinkState} from 'types/navigation.types';
import {useLocation} from 'react-router-dom';
import {useAnalytics} from 'context/analytics.context';
import ANALYTICS_EVENT from 'config/analytics/event-names.config';
import TrackersTable from 'components/trackers/table/trackers-table';

function ExecutorTrackers(): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _location = useLocation();
  const _analyticsContext = useAnalytics();
  const _executorDeepDiveContext = useExecutorDeepDive();
  const [_tabs, _setTabs] = useState<IScoredTab[]>([]);
  const [_selectedTab, _setSelectedTab] = useState<IScoredTab | null>(null);
  const [_trackers, _setTrackers] = useState<FIFormattedAutomation[]>([]);

  const [_concreteExecutorInfo, _setConcreteExecutorInfo] =
    useState<FIDisplayPlayer | null>(null);

  /*
   * Side effects
   */
  /* Define tabs */
  useEffect(() => {
    if (!_executorDeepDiveContext.performanceStats?.automations_count) {
      _setTabs([]);
      _setSelectedTab(null);
      return;
    }

    const _generatedTabs: IScoredTab[] =
      _executorDeepDiveContext.performanceStats.categories.map((category) => {
        return {
          value: category._id,
          label: capitalize(category.name) || t('players.performance.other'),
          benchmarked_score: category.benchmarked_score,
          isOtherCat: !category.name,
        };
      });
    _setTabs(_generatedTabs);

    if (_generatedTabs.length) {
      _setSelectedTab(_generatedTabs[0]);
    }
  }, [_executorDeepDiveContext.performanceStats]);

  /* Set active tab from local storage => better UX when navigating back from deep dive */
  useEffect(() => {
    const _lastTabFromStorage = localStorage.getItem(
      LOCAL_STORAGE_LAST_VIEWED_TAG_ID,
    );
    const _matchingTab = _tabs.find((tab) => tab.value === _lastTabFromStorage);
    const _locationState = _location.state as ILinkState | undefined;
    if (_matchingTab && _locationState?.from === 'tracker-deep-dive') {
      _setSelectedTab(_matchingTab);
    }
  }, [_tabs, _location.state]);

  /* Define list of trackers */
  useEffect(() => {
    if (_selectedTab && _executorDeepDiveContext.performanceStats) {
      const _tab = _executorDeepDiveContext.performanceStats.categories.find(
        (cat) => cat._id === _selectedTab?.value,
      );
      _setTrackers(_tab?.automations || []);
    }
  }, [_selectedTab, _executorDeepDiveContext.performanceStats]);

  /* Define concrete executor info - used in scouting to display player name */
  useEffect(() => {
    let _newExecutorInfo: FIDisplayPlayer | null = null;
    if (
      _executorDeepDiveContext.player &&
      _executorDeepDiveContext.executorType === 'scouting_profile_ids'
    ) {
      _newExecutorInfo = {
        _id: _executorDeepDiveContext.player._id,
        name: _executorDeepDiveContext.player.name,
        display_name: _executorDeepDiveContext.player.display_name,
        image_url: _executorDeepDiveContext.player.image_url,
      };
    }

    _setConcreteExecutorInfo(_newExecutorInfo);
  }, [_executorDeepDiveContext.executorType, _executorDeepDiveContext.player]);

  /*
   * Handlers
   */
  function _onTabChange(value: string) {
    const _matchingTab = _tabs.find((tab) => tab.value === value);
    _setSelectedTab(_matchingTab || null);
    localStorage.setItem(LOCAL_STORAGE_LAST_VIEWED_TAG_ID, value);

    if (_executorDeepDiveContext.analyticsData) {
      _analyticsContext.trackEvent(
        ANALYTICS_EVENT.PLAYER_PAGE_VIEWED_CATEGROY,
        {
          ..._executorDeepDiveContext.analyticsData,
          tab: 'performance',
          category: value,
        },
      );
    }
  }

  /*
   * Render
   */
  if (!_tabs.length || !_selectedTab) {
    return null;
  }

  return (
    <Stack gap={2} pt={3}>
      {/* Tabs */}
      <Stack
        py={3}
        position="sticky"
        top={0}
        bgcolor="background.default"
        zIndex={1}>
        <ScoredTabs
          tabs={_tabs}
          activeTabId={_selectedTab.value}
          onTabChange={_onTabChange}
        />
      </Stack>

      {/* Trackers */}
      <TrackersTable
        executorType={_executorDeepDiveContext.executorType}
        trackers={_trackers}
        concreteExecutorInfo={_concreteExecutorInfo || undefined}
        per90={
          _executorDeepDiveContext.benchmarkAndDateFilters?.benchmark
            .calculation === 'per_90'
        }
        backLink={_executorDeepDiveContext.trackerBackLink || undefined}
        matches={_executorDeepDiveContext.matches}
      />
    </Stack>
  );
}

export default ExecutorTrackers;
