import {FIUploadedFile} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import PitchtureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import WarningIcon from '@mui/icons-material/Warning';

import moment from 'moment';
import {useTranslation} from 'react-i18next';

import * as styles from './files-table-row.styles';

import AvatarGroup from '../common/avatar-group/avatar-group.view';
import {useConfirm} from 'material-ui-confirm';
import {useSnackbar} from 'notistack';
import {deleteFile, getSingleFile} from 'controllers/file-upload.controller';
import {useFileUploads} from 'context/file-uploads.context';
import {LOADING_STATE} from 'types/screen.types';
import BareVideoPlayerView from '../video/bare-video-player/bare-video-player.view';
import FileUploadForm from '../file-upload/file-upload-form.view';
import {useAnalytics} from 'context/analytics.context';
import ANALYTICS_EVENT from 'config/analytics/event-names.config';

interface IFilesTableRowProps {
  file: FIUploadedFile;
}

const MAX_DESCRIPTION_CHARS = 64;
function FilesTableRow(props: IFilesTableRowProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _confirm = useConfirm();
  const _snackbar = useSnackbar();
  const _fileUploadContext = useFileUploads();
  const _analyticsContext = useAnalytics();

  const [_description, _setDescription] = useState<string>('');
  const [_isVideo, _setIsVideo] = useState<boolean>(false);
  const [_editMenuAnchor, _setEditMenuAnchor] = useState<null | HTMLElement>(
    null,
  );
  const [_fileLoadingState, _setFileLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.SUCCESS,
  );
  const [_awsUrl, _setAwsUrl] = useState<string>('');
  const [_isPlayingVideo, _setIsPlayingVideo] = useState<boolean>(false);

  const [_isEditModalOpen, _setIsEditModalOpen] = useState<boolean>(false);

  /*
   * Side effects
   */
  useEffect(() => {
    let _newDescription = props.file.description;

    if (_newDescription && _newDescription?.length > MAX_DESCRIPTION_CHARS) {
      _newDescription = `${_newDescription.substring(
        0,
        MAX_DESCRIPTION_CHARS,
      )}...`;
    }

    _setDescription(_newDescription || '');
  }, [props.file.description]);

  useEffect(() => {
    _setIsVideo(props.file.type === 'video');
  }, [props.file.type]);

  /*
   * Handlers
   */
  async function _fetchFile(): Promise<FIUploadedFile | null> {
    _setFileLoadingState(LOADING_STATE.LOADING);
    try {
      const _fetchedFile = await getSingleFile(props.file._id);

      if (_fetchedFile.aws_url) {
        _setFileLoadingState(LOADING_STATE.SUCCESS);
        _setAwsUrl(_fetchedFile.aws_url);
        return _fetchedFile;
      }

      throw new Error('No aws url');
    } catch (error) {
      _setFileLoadingState(LOADING_STATE.SUCCESS);
      _snackbar.enqueueSnackbar(t('error-states.default'), {
        variant: 'error',
      });
      return null;
    }
  }
  async function _onWatchVideo() {
    const _file = await _fetchFile();
    if (_file) {
      _setIsPlayingVideo(true);
      _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_UPLOADED_FILE, {
        file_id: _file._id,
        type: _file.type,
        size: _file.file_metadata.size,
      });
    }
  }

  function _onVideoPlayerClose() {
    _setIsPlayingVideo(false);
  }

  async function _onViewFile() {
    const _file = await _fetchFile();
    if (_file) {
      _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_UPLOADED_FILE, {
        file_id: _file._id,
        type: _file.type,
        size: _file.file_metadata.size,
      });
      window.open(_file.aws_url, '_blank');
    }
  }

  function _onEditMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
    _setEditMenuAnchor(event.currentTarget);
  }

  function _onEditMenuClose() {
    _setEditMenuAnchor(null);
  }

  function _onEdit() {
    _onEditMenuClose();
    _setIsEditModalOpen(true);
  }

  function _onEditModalClose() {
    _setIsEditModalOpen(false);
  }

  async function _onDelete() {
    _onEditMenuClose();
    try {
      await _confirm({
        confirmationText: t('uploads.actions.delete.title'),
        confirmationButtonProps: {
          color: 'error',
        },
        title: t('uploads.actions.delete.text'),
        description: t('uploads.actions.delete.description'),
      });

      try {
        _fileUploadContext.setLoadingState(LOADING_STATE.LOADING);
        await deleteFile(props.file._id);
        _fileUploadContext.getAllFiles();
      } catch (error) {
        _fileUploadContext.setLoadingState(LOADING_STATE.SUCCESS);
        _snackbar.enqueueSnackbar(t('uploads.actions.delete.error'), {
          variant: 'error',
        });
      }
    } catch (error) {
      //User dismissed
    }
  }

  /*
   * Render
   */

  /* Type Icon */
  const _TypeIcon = _isVideo ? VideoLibraryIcon : PitchtureAsPdfIcon;

  /* Button */
  let _Button = null;
  if (props.file.type === 'video') {
    _Button = (
      <LoadingButton
        startIcon={<PlayArrowIcon />}
        onClick={_onWatchVideo}
        loading={_fileLoadingState === LOADING_STATE.LOADING}>
        {t('uploads.actions.watchVideo')}
      </LoadingButton>
    );
  } else if (props.file.type === 'document') {
    _Button = (
      <LoadingButton
        startIcon={<FileOpenIcon />}
        onClick={_onViewFile}
        loading={_fileLoadingState === LOADING_STATE.LOADING}>
        {t('uploads.actions.viewFile')}
      </LoadingButton>
    );
  }

  /* Description */
  let _DescriptionNode = null;
  if (props.file.description) {
    const _BaseDescription = (
      <Typography
        component="span"
        color="text.disabled"
        variant="body2"
        sx={styles.titleDescriptionTextStyles}>
        {_description}
      </Typography>
    );

    if (props.file.description.length > MAX_DESCRIPTION_CHARS) {
      _DescriptionNode = (
        <Tooltip
          title={props.file.description}
          componentsProps={{
            tooltip: {
              sx: {
                maxWidth: 320,
              },
            },
          }}>
          {_BaseDescription}
        </Tooltip>
      );
    }
  }

  return (
    <TableRow>
      {/* Type icon */}
      <TableCell sx={styles.typeIconStyles}>
        {props.file.upload_status === 'completed' ? (
          <_TypeIcon color="secondary" />
        ) : (
          <Tooltip title={t('uploads.uploadForm.file.uploadPending')}>
            <WarningIcon color="warning" />
          </Tooltip>
        )}
      </TableCell>

      {/* Title */}
      <TableCell>
        <Typography component="span" sx={styles.titleTextStyles}>
          {props.file.title}
        </Typography>
        {_DescriptionNode}
      </TableCell>

      {/* Category */}
      <TableCell>
        <Chip label={t(`uploads.categories.${props.file.category}`)} />
      </TableCell>

      {/* Shared with players */}
      <TableCell>
        {props.file.shared_with_players?.length ? (
          <AvatarGroup players={props.file.shared_with_players} />
        ) : (
          <Typography color="text.disabled" variant="body2">
            {t('uploads.values.notShared')}
          </Typography>
        )}
      </TableCell>

      {/* Upload date */}
      <TableCell>{moment(props.file.created_at).format('L')}</TableCell>

      {/* Expiration date */}
      <TableCell>
        {props.file.expiration_date ? (
          moment(props.file.expiration_date as Date).format('L')
        ) : (
          <Typography variant="body2" color="text.disabled">
            {t('uploads.values.noExpiration')}
          </Typography>
        )}
      </TableCell>

      {/* View action */}
      <TableCell>{_Button}</TableCell>

      {/* Other actions */}
      <TableCell sx={styles.typeIconStyles}>
        <IconButton color="secondary" onClick={_onEditMenuOpen}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          open={Boolean(_editMenuAnchor)}
          anchorEl={_editMenuAnchor}
          onClose={_onEditMenuClose}>
          <MenuItem onClick={_onEdit} divider>
            <ListItemIcon>
              <EditIcon color="secondary" />
            </ListItemIcon>
            <ListItemText>{t('uploads.actions.edit.title')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={_onDelete}>
            <ListItemIcon>
              <DeleteIcon color="error" />
            </ListItemIcon>
            <ListItemText>{t('uploads.actions.delete.title')}</ListItemText>
          </MenuItem>
        </Menu>
      </TableCell>
      <BareVideoPlayerView
        isOpened={_isPlayingVideo}
        onClose={_onVideoPlayerClose}
        videoUrl={_awsUrl}
      />
      <FileUploadForm
        isOpened={_isEditModalOpen}
        onClose={_onEditModalClose}
        data={props.file}
      />
    </TableRow>
  );
}

export default FilesTableRow;
