import {FIPlayerWithData, TSortParams} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import PlayersTableRow, {IPlayersColDef} from './players-table-row.view';
import {formatPlayerValue, getPlayerMarketValue} from 'helpers/player.helper';
import PlayerCell from './cells/player-cell.view';
import ScoreCell from './cells/score-cell.view';
import moment from 'moment';
import {toPercentage} from 'helpers/general/general.helper';
import PlayerTrackersCountCell from './cells/player-trackers-count-cell.view';
import PlayersTableTh from './cells/players-table-th.view';
import PlayerActionsCell from './cells/player-actions-cell.view';

interface IPlayersTableProps {
  players: FIPlayerWithData[];
  displayPlayerPerformance?: boolean;
  scoutingProfileId?: string;
  sort?: TSortParams;
  onSort: (sortParams: TSortParams) => void;
}

function PlayersTable(props: IPlayersTableProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  const [_columns, _setColumns] = useState<IPlayersColDef[]>([]);

  /*
   * Side effects
   */
  useEffect(() => {
    const _generatedCols: IPlayersColDef[] = [
      {
        key: 'player',
        label: t('players.players', {count: 1}),
        renderCell: (player: FIPlayerWithData) => {
          return <PlayerCell player={player} />;
        },
        sortKey: 'name',
      },
      {
        key: 'market_value',
        label: t('players.info.market_value'),
        formatValue: (player: FIPlayerWithData) => {
          const _currentMarketValue = getPlayerMarketValue(player.market_value);
          if (!_currentMarketValue) {
            return '';
          }

          return formatPlayerValue(_currentMarketValue, true);
        },
        align: 'right',
        sortKey: 'market_value',
      },
      {
        key: 'contract_expiry',
        label: t('players.info.contract_expiry'),
        formatValue: (player: FIPlayerWithData) => {
          if (!player.contract_expiry_date) {
            return '';
          }

          return moment(player.contract_expiry_date as Date).format(
            'MMMM YYYY',
          );
        },
        align: 'right',
        sortKey: 'contract_expiry_date',
      },
      {
        key: 'age',
        label: t('players.info.age'),
        formatValue: (player: FIPlayerWithData) => {
          const _age = moment().diff(moment(player.birth_date), 'years');
          return _age;
        },
        align: 'center',
        sortKey: 'age',
      },
      {
        key: 'position',
        label: t('players.info.position'),
        formatValue: (player: FIPlayerWithData) => {
          return player.position.join(', ');
        },
        align: 'center',
        sortKey: 'position',
      },
      {
        key: 'min_played',
        label: t('players.info.minutesPlayed'),
        formatSecondaryValue: (player: FIPlayerWithData) => {
          if (player.minutes_played) {
            const _percentagePlayed = toPercentage(
              player.minutes_played.percentage_played || 0,
            );
            return `(${_percentagePlayed})`;
          }
          return '';
        },
        formatValue: (player: FIPlayerWithData) => {
          return `${player.minutes_played.minutes_played}'`;
        },

        align: 'right',
        sortKey: 'min_played',
      },
      {
        key: 'actions',
        label: '',
        renderCell: (player: FIPlayerWithData) => {
          return (
            <PlayerActionsCell
              player={player}
              scoutingProfileId={props.scoutingProfileId}
            />
          );
        },
        align: 'right',
      },
    ];

    if (props.displayPlayerPerformance) {
      _generatedCols.splice(1, 0, {
        key: 'score',
        label: t('players.info.score'),
        align: 'center',
        renderCell: (player: FIPlayerWithData) => {
          if (typeof player.performance?.benchmarked_score === 'undefined') {
            return '';
          }
          return <ScoreCell performance={player.performance} />;
        },
        sortKey: 'score',
      });
      _generatedCols.splice(7, 0, {
        key: 'trackers',
        label: t('eventAutomations.trackersShort', {count: 0}),
        renderCell: (player: FIPlayerWithData) => {
          if (!player.performance) {
            return '';
          }

          return <PlayerTrackersCountCell performance={player.performance} />;
        },
        align: 'center',
        sortKey: 'nb_trackers',
      });
    }

    _setColumns(_generatedCols);
  }, [props.displayPlayerPerformance]);

  /*
   * Render
   */
  return (
    <Table>
      <TableHead>
        <TableRow>
          {_columns.map((colDef) => {
            return (
              <PlayersTableTh
                key={colDef.key}
                colDef={colDef}
                onSort={props.onSort}
                sort={props.sort}
              />
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.players.map((player) => {
          return (
            <PlayersTableRow
              key={player._id}
              player={player}
              colDefinitions={_columns}
              displayPlayerPerformance={props.displayPlayerPerformance}
            />
          );
        })}
      </TableBody>
    </Table>
  );
}

export default PlayersTable;
