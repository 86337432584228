import React, {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import {TTrackerExecutorType} from '@my-game-plan/types';
import {ITrackerTypeConfig, TRACKER_TYPES_CONFIG} from 'config/trackers.config';

import {useAutomationForm} from 'context/event-automations/automations-form.context';
import {useTranslation} from 'react-i18next';

interface ITrackerExecutorTypeToggleButtonProps {
  type: TTrackerExecutorType;
}

type TExecutorDisabledReason = 'default' | 'player-details' | 'against';
function TrackerExecutorTypeToggleButton(
  props: ITrackerExecutorTypeToggleButtonProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _trackerBuilderContext = useAutomationForm();
  const [_isActive, _setIsActive] = useState(false);
  const [_disabledReason, _setDisabledReason] =
    useState<TExecutorDisabledReason | null>(null);
  const [_typeConfig, _setTackerTypeConfig] =
    useState<ITrackerTypeConfig | null>(
      TRACKER_TYPES_CONFIG[props.type] || null,
    );
  const [_tooltipText, _setTooltipText] = useState<string>(
    TRACKER_TYPES_CONFIG[props.type]?.title || '',
  );
  const [_valuesCount, _setValuesCount] = useState<number>(0);

  /*
   * Side Effects
   */
  useEffect(() => {
    // Set type config (icon + title)
    _setTackerTypeConfig(TRACKER_TYPES_CONFIG[props.type] || null);

    // Define active state
    const _newActiveState =
      props.type === _trackerBuilderContext.currentExecutorType;
    _setIsActive(_newActiveState);
  }, [props.type, _trackerBuilderContext.currentExecutorType]);

  // Disable executor type based on data
  useEffect(() => {
    if (!_typeConfig) {
      return;
    }
    let _newDisabledReason: TExecutorDisabledReason | null = null;

    // Disable "Player development" + "Scouting" for against trackers
    if (
      _trackerBuilderContext.data?.against &&
      (props.type === 'player_development' ||
        props.type === 'scouting_profile_ids')
    ) {
      _newDisabledReason = 'against';
    }

    _setDisabledReason(_newDisabledReason);

    // Define tooltip text
    const _newTooltipText = _newDisabledReason
      ? t(`sentenceForm.executor.disabled.${_newDisabledReason}`, {
          type: _typeConfig?.title,
        })
      : _typeConfig.title;

    _setTooltipText(_newTooltipText);

    // Define values count
    const _size = _trackerBuilderContext.data?.[props.type]?.length || 0;
    _setValuesCount(_size);
  }, [_trackerBuilderContext.data, props.type, _typeConfig]);

  /*
   * Handlers
   */
  function _onClick() {
    _trackerBuilderContext.setCurrentExecutorType(props.type);
  }

  /*
   * Render
   */
  if (!_typeConfig) {
    return null;
  }

  return (
    <Stack>
      <Tooltip title={_tooltipText} arrow>
        <Box component="span">
          <IconButton
            disabled={!!_disabledReason}
            color={_isActive ? 'primary' : 'secondary'}
            onClick={_onClick}
            sx={{
              '&.Mui-disabled': {
                opacity: 0.3,
              },
              borderColor: _isActive ? 'primary.main' : 'transparent',
              borderWidth: 1,
              borderStyle: 'solid',
            }}>
            <Badge
              // color={_isActive ? 'primary' : 'secondary'}
              color="secondary"
              badgeContent={_valuesCount}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}>
              <_typeConfig.icon />
            </Badge>
          </IconButton>
        </Box>
      </Tooltip>
    </Stack>
  );
}

export default TrackerExecutorTypeToggleButton;
