import ProfileSetupScreen from 'components/profile-setup-screen/profile-setup-screen';
import React, {useState} from 'react';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {useAuth} from 'context/auth.context';

function LoginScreen() {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _authContext = useAuth();
  const _snackbar = useSnackbar();
  const [_isLoading, _setIsLoading] = useState(false);

  const [_userName, _setUserName] = useState('');
  const [_password, _setPassword] = useState('');

  /*
   * Side Effects
   */

  /*
   * Handlers
   */
  async function _onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      _setIsLoading(true);
      await _authContext.loginUser(_userName, _password);
    } catch (error) {
      _setIsLoading(false);
      _snackbar.enqueueSnackbar(
        t([`error-states.${(error as any).message}`, 'error-states.default']),
        {variant: 'error'},
      );
      _setPassword('');
    }
  }

  /*
   * Render
   */
  const _Content = (
    <Stack
      px={3}
      component="form"
      onSubmit={_onSubmit}
      alignItems="flex-start"
      gap={5}
      pt={1}>
      <Stack gap={3} width="100%">
        <TextField
          fullWidth
          required
          InputLabelProps={{required: false}}
          label={t('login.form.name')}
          autoComplete="name"
          autoFocus
          name="name"
          value={_userName}
          onChange={(e) => _setUserName(e.target.value)}
        />
        <TextField
          fullWidth
          required
          InputLabelProps={{required: false}}
          label={t('login.form.password')}
          type="password"
          autoComplete="current-password"
          name="password"
          value={_password}
          onChange={(e) => _setPassword(e.target.value)}
        />
      </Stack>
      <LoadingButton
        type="submit"
        variant="contained"
        size="large"
        loading={_isLoading}>
        {t('login.form.button')}
      </LoadingButton>
    </Stack>
  );

  const _Footer = (
    <Stack maxWidth={280}>
      <Typography variant="body2" color="text.disabled">
        <span>{t('privacy.body')}</span>{' '}
        <Typography
          fontSize="inherit"
          component="a"
          color="primary.dark"
          href="https://www.mygameplan.ai/privacy-policy"
          target="_blank"
          rel="noreferrer">
          {t('privacy.privacy-policy')}
        </Typography>{' '}
        <span>{t('privacy.and')}</span>{' '}
        <Typography
          component="a"
          fontSize="inherit"
          color="primary.dark"
          href="https://www.mygameplan.ai/terms-and-conditions"
          target="_blank"
          rel="noreferrer">
          {t('privacy.terms-and-conditions')}
        </Typography>
      </Typography>
    </Stack>
  );
  return (
    <ProfileSetupScreen
      leftCentered
      leftHeader={{displayLogo: true, title: t('login.title')}}
      LeftContent={_Content}
      LeftFooter={_Footer}
    />
  );
}

export default LoginScreen;
