import {ITrackerSentenceInputProps} from 'types/trackers/tracker-sentence.types';
import React, {useEffect, useState} from 'react';
import TrackerSentencePopup from '../../variants/popup/sentence-popup-segment';
import {FITrackerSubject} from '@my-game-plan/types';
import {useTranslation} from 'react-i18next';
import ExecutorPopupContent from './executor-popup-content';

import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import {EXECUTOR_COLOR} from 'theme';
import {useAutomationForm} from 'context/event-automations/automations-form.context';
import {formatSingleExecutor} from 'helpers/automation/automation-form.helper';
import {useTeams} from 'context/team.context';
import SentenceTextSegment from '../../variants/text/sentence-text-segment';

function SentenceExecutorSegment(
  props: ITrackerSentenceInputProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */

  const {t} = useTranslation();
  const _trackerBuilderForm = useAutomationForm();
  const _teamsContext = useTeams();
  const [_anchorEl, _setAnchorEl] = useState<Element | null>(null);

  const [_selectedSubjects, _setSelectedSubjects] = useState<
    FITrackerSubject[]
  >([]);
  // const [_selectedStrings, _setSelectedStrings] = useState<string[]>([]);
  const [_placeholder, _setPlaceholder] = useState<string>('');
  /*
   * Side effects
   */
  useEffect(() => {
    if (!props.currentExecutorType) {
      _setSelectedSubjects([]);
      return;
    }

    if (props.currentExecutorType === 'scouting_profile_ids') {
      // _setSelectedStrings(props.value?.scouting_profile_ids || []);
    } else {
      _setSelectedSubjects(props.tracker?.[props.currentExecutorType] || []);
    }
  }, [props.currentExecutorType, props.tracker]);

  useEffect(() => {
    let _newPlaceholder = t('sentenceForm.observing.players', {count: 1});
    if (props.concreteExecutorInfo && props.readonly) {
      _newPlaceholder = props.concreteExecutorInfo.display_name;
    }
    _setPlaceholder(_newPlaceholder);
  }, [props.concreteExecutorInfo, props.readonly]);

  /*
   * Handlers
   */
  function _onPopupClose() {
    _setAnchorEl(null);
  }

  function _onSubjectChange(value: FITrackerSubject[]) {
    if (!props.onChange || !props.currentExecutorType) {
      return;
    }

    props.onChange({
      [props.currentExecutorType]: value,
    });
  }

  function _valueFormatter(subjects: FITrackerSubject[]): string {
    if (props.readonly && props.tracker?.concrete_executor) {
      return formatSingleExecutor(
        props.tracker.concrete_executor, //TODO Use additional player info for scouting
        _teamsContext.ownPlayers,
      );
    }
    if (props.currentExecutorType === 'scouting_profile_ids') {
      return 'scouting - todo';
    }

    const _executor = subjects[_trackerBuilderForm.concreteExecutorIndex];

    if (!subjects?.length || !_executor) {
      return _placeholder;
    }

    const _formattedExecutor = formatSingleExecutor(
      _executor.executor,
      _teamsContext.ownPlayers,
    );

    return _formattedExecutor;
  }

  function _isEmptyValue(data: FITrackerSubject[] | undefined): boolean {
    return !data || !data.length;
  }

  /*
   * Render
   */

  const {onChange, ..._commonProps} = props;
  const _popupProps: ITrackerSentenceInputProps<FITrackerSubject[]> = {
    ..._commonProps,
    placeholder: _placeholder,
    value: _selectedSubjects,

    onChange: _onSubjectChange,
    valueFormatter: _valueFormatter,
    extraData: [
      props.currentExecutorType,
      _trackerBuilderForm.concreteExecutorIndex,
      props.readonly,
      props.tracker?.concrete_executor,
    ],
  };

  if (!props.currentExecutorType) {
    // This should never be shown, component should not be included when currentExecutorType is not set
    return (
      <SentenceTextSegment
        {..._commonProps}
        value={t('sentenceForm.executor.selectedPlayersPreview')}
        type="value"
      />
    );
  }

  let _Content = null;
  if (props.currentExecutorType === 'scouting_profile_ids') {
    _Content = <div>scouting profiles list</div>;
  } else if (props.currentExecutorType) {
    _Content = (
      <ExecutorPopupContent
        onChange={_onSubjectChange}
        subjects={_selectedSubjects}
        executorType={props.currentExecutorType}
        against={props.tracker?.against || false}
      />
    );
  }

  return (
    <>
      <TrackerSentencePopup.Button
        {..._popupProps}
        onOpen={_setAnchorEl}
        onChange={_onSubjectChange}
        isPopupOpen={Boolean(_anchorEl)}
        required
        LeftIcon={
          <CenterFocusStrongIcon
            sx={{color: EXECUTOR_COLOR}}
            fontSize="small"
          />
        }
        isEmptyValue={_isEmptyValue}
      />
      <TrackerSentencePopup.Popup
        {..._popupProps}
        disablePadding
        onClose={_onPopupClose}
        anchorEl={_anchorEl}
        isEmptyValue={_isEmptyValue}>
        {_Content}
      </TrackerSentencePopup.Popup>
    </>
  );
}

export default SentenceExecutorSegment;
