import {FIBenchmarkOptions, FIPerformanceOverview} from '@my-game-plan/types';
import React from 'react';

import Grid from '@mui/material/Grid';
import PerformanceSummaryText from './performance-summary-text';
import PerformanceHeroCharts from './charts/performance-hero-charts';

interface IPerformanceHeroProps {
  performanceOverview?: FIPerformanceOverview;
  benchmarkOptions?: FIBenchmarkOptions;
  isPreview?: boolean;
  subject: string;
  SummaryButton?: React.ReactNode;
}

function PerformanceHero(props: IPerformanceHeroProps): JSX.Element {
  /*
   * Hooks n State
   */

  /*
   * Side effects
   */

  /*
     * Handlers

    /*
     * Render
     */
  return (
    <Grid container columnSpacing={10} alignItems={'center'}>
      <Grid item xs={12} md lg={4} xl={3}>
        {props.performanceOverview && (
          <PerformanceSummaryText
            performanceOverview={props.performanceOverview}
            benchmarkOptions={props.benchmarkOptions}
            isPreview={props.isPreview}
            subject={props.subject}
            Button={props.SummaryButton}
          />
        )}
      </Grid>
      <Grid item xs={12} lg={8} xl={9}>
        <PerformanceHeroCharts
          performanceOverview={props.performanceOverview}
          benchmarkOptions={props.benchmarkOptions}
          isPreview={props.isPreview}
        />
      </Grid>
    </Grid>
  );
}

export default PerformanceHero;
