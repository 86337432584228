import {
  FIMatch,
  IClipResponse,
  IMatchVideoSourceData,
  TVideoSourceType,
} from '@my-game-plan/types';
import React, {useState} from 'react';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import TableCell from '@mui/material/TableCell';

import EditIcon from '@mui/icons-material/Edit';
import HourglassTop from '@mui/icons-material/HourglassTop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import {useTranslation} from 'react-i18next';
import MatchVideosUploadForm from '../form/match-videos-upload-form.view';
import {getFullMatchVideo} from 'controllers/video/video.controller';
import {useSnackbar} from 'notistack';
import BareVideoPlayerView from 'components/video/bare-video-player/bare-video-player.view';
import MatchVideosOffsetsForm from '../form/match-videos-offsets-form.view';
import ChunkedBareVideoPlayerView, {
  CHUNK_DURATION,
} from 'components/video/bare-video-player/chunked-bare-video-player.view';
import {IGetClipQueryParams} from 'types/clips.types';
import ANALYTICS_EVENT from 'config/analytics/event-names.config';
import {useAnalytics} from 'context/analytics.context';

interface IMatchVideoStatusCellProps {
  sourceType: TVideoSourceType;
  videoConfig?: IMatchVideoSourceData;
  canManage?: boolean;
  match: FIMatch;
  hasAutomaticOffsets?: boolean;
}

function MatchVideoStatusCell(props: IMatchVideoStatusCellProps) {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _snackbar = useSnackbar();
  const _analyticsContext = useAnalytics();

  const [_isUploadFormOpened, _setUploadIsFormOpened] =
    useState<boolean>(false);
  const [_isOffsetsFormOpened, _setOffsetsFormOpened] =
    useState<boolean>(false);

  const [_clipResponse, _setClipResponse] = useState<IClipResponse | null>(
    null,
  );

  /*
   * Side effects
   */

  /*
   * Side effects
   */

  /*
   * Handlers
   */
  function _onUploadFormClose() {
    _setUploadIsFormOpened(false);
  }

  async function _onPlayVideoClick() {
    try {
      const _clipParams: IGetClipQueryParams = {source: props.sourceType};
      if (props.videoConfig?.stream_service === 'wyscout') {
        _clipParams.offsets = [0, CHUNK_DURATION];
      }
      const _fullMatchVideo = await getFullMatchVideo(
        props.match._id,
        _clipParams,
      );
      _setClipResponse(_fullMatchVideo);

      _analyticsContext.trackEvent(ANALYTICS_EVENT.WATCHED_FULL_MATCH, {
        minute: 0,
        second: 0,
        match_id: props.match._id,
        wyscout_id: props.match.provider_ids.wyscout || '',
        video_source: props.sourceType,
      });
    } catch (error) {
      _snackbar.enqueueSnackbar(t('error-states.defaultError.header'));
    }
  }

  function _onUploadVideoClick() {
    _setUploadIsFormOpened(true);
  }

  function _onCloseVideo() {
    _setClipResponse(null);
  }

  function _onEditVideoClick() {
    //
    _setOffsetsFormOpened(true);
  }

  function _onOffsetsFormClose() {
    _setOffsetsFormOpened(false);
  }

  /*
   * Render
   */

  let _Content = (
    // <IconButton color="primary" disabled>
    //   <CloseIcon />
    // </IconButton>
    <Typography color="text.disabled">
      {t('matches.properties.video.status.noVideo')}
    </Typography>
  );
  // if (props.videoConfig?.)
  if (props.canManage && !props.videoConfig) {
    _Content = (
      <Button variant="contained" color="primary" onClick={_onUploadVideoClick}>
        {t('matches.uploadVideo.upload')}
      </Button>
    );
  } else if (
    props.videoConfig?.offsets &&
    props.videoConfig?.status === 'finished'
  ) {
    _Content = (
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        component={'span'}
        justifyContent="center">
        <IconButton color="primary" onClick={_onPlayVideoClick}>
          <PlayArrowIcon />
        </IconButton>
        {props.canManage && (
          <IconButton color="secondary" onClick={_onEditVideoClick}>
            <EditIcon />
          </IconButton>
        )}
      </Stack>
    );
  } else if (
    props.videoConfig?.status === 'finished' &&
    !props.videoConfig?.offsets
  ) {
    _Content = (
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        component={'span'}
        justifyContent="center">
        <IconButton color="primary" onClick={_onPlayVideoClick}>
          <PlayArrowIcon />
        </IconButton>
        {props.canManage && (
          <Tooltip title={t('matches.uploadVideo.noOffsets')}>
            <IconButton color="warning" onClick={_onEditVideoClick}>
              <WarningAmberIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    );
  } else if (props.videoConfig?.status === 'processing') {
    _Content = (
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        component={'span'}
        justifyContent="center">
        <Tooltip title={t('matches.properties.video.status.processing')}>
          <HourglassTop color="secondary" />
        </Tooltip>
        {props.canManage && (
          <Tooltip title={t('matches.uploadVideo.updateOffsets')}>
            <IconButton color="secondary" onClick={_onEditVideoClick}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    );
  }

  return (
    <TableCell align="center">
      {_Content}
      <MatchVideosUploadForm
        match={props.match}
        isOpened={_isUploadFormOpened}
        onClose={_onUploadFormClose}
        canDisableOffsets={props.hasAutomaticOffsets}
      />
      <MatchVideosOffsetsForm
        match={props.match}
        isOpened={_isOffsetsFormOpened}
        onClose={_onOffsetsFormClose}
        offsets={props.videoConfig?.offsets}
        videoSource={props.sourceType}
      />
      {props.videoConfig?.stream_service === 'wyscout' ? (
        <ChunkedBareVideoPlayerView
          isOpened={Boolean(_clipResponse?.clip_url)}
          startClipResponse={_clipResponse}
          onClose={_onCloseVideo}
          matchId={props.match._id}
          sourceType={props.sourceType}
          forceHLS={false} // Only need hls when stream_service is mygameplan
        />
      ) : (
        <BareVideoPlayerView
          isOpened={Boolean(_clipResponse?.clip_url)}
          videoUrl={_clipResponse?.clip_url || ''}
          onClose={_onCloseVideo}
          forceHLS={props.videoConfig?.stream_service === 'mygameplan'}
        />
      )}
    </TableCell>
  );
}

export default MatchVideoStatusCell;
