import React, {useEffect, useState} from 'react';

import TextField from '@mui/material/TextField';

import {ITrackerSentenceInputProps} from 'types/trackers/tracker-sentence.types';
import SentenceTextSegment, {
  FONT_SIZE_MAP,
} from '../text/sentence-text-segment';

interface ISentenceNumberSegmentProps
  extends ITrackerSentenceInputProps<number> {
  isRatio?: boolean;
}

function SentenceNumberSegment(
  props: ISentenceNumberSegmentProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const [_inputSize, _setInputSize] = useState<number>(0);

  /*
   * Side effects
   */
  /* Handlers */
  useEffect(() => {
    const _calculatedInputSize = props.value
      ? (props.value?.toString().length || 0) + 2
      : 4;

    _setInputSize(_calculatedInputSize);
  }, [props.value]);

  /*
   * Handlers
   */
  function _onChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!props.onChange) {
      return;
    }
    const _inputValue = parseInt(event.target.value);
    props.onChange(_inputValue);
  }

  function _onBlur(event: React.FocusEvent<HTMLInputElement>) {
    const _inputValue = parseInt(event.target.value);
    const _maxValue = props.isRatio ? 100 : undefined;
    if (typeof _maxValue !== 'undefined' && _inputValue > _maxValue) {
      event.target.value = _maxValue.toString();
      _onChange(event);
    }
  }

  /*
   * Render
   */
  const {isRatio, onChange, ..._commonProps} = props;

  const _widthFactor = props.size === 'large' ? 12 : 10;
  const _inputWidth = _inputSize * _widthFactor;

  if (props.readonly) {
    return (
      <SentenceTextSegment
        {..._commonProps}
        type="value"
        value={props.value?.toString() || '0'}
      />
    );
  }

  const _fontSize = FONT_SIZE_MAP[props.size];
  return (
    <TextField
      value={props.value || ''}
      onChange={_onChange}
      onBlur={_onBlur}
      placeholder={props.placeholder || '0'}
      type="number"
      variant="standard"
      inputProps={{
        min: 0,
        max: isRatio ? 100 : undefined,
        step: isRatio ? 5 : 1,
        size: _inputSize,

        sx: {
          fontSize: _fontSize,
          height: 'auto',
          width: _inputWidth,
          '&::placeholder': {
            color: 'secondary.main',
            opacity: 1, // otherwise firefox shows a lighter color
          },
        },
      }}
    />
  );
}

export default SentenceNumberSegment;
