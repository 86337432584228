import ANALYTICS_EVENT from 'config/analytics/event-names.config';
import {useAnalytics} from 'context/analytics.context';
import React, {useEffect} from 'react';
import TrackersView from '../automations/trackers.view';
import {useTranslation} from 'react-i18next';

function PlayersOverviewScreen(): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _analyticsContext = useAnalytics();

  /*
   * Side effects
   */
  useEffect(() => {
    _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_PLAYERS_OVERVIEW);
  }, []);
  return (
    <TrackersView
      title={t('playerPerformance.title')}
      executorType="player_development"
    />
  );
}

export default PlayersOverviewScreen;
