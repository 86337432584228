import React, {useState} from 'react';
import {FIMatch, TVideoSourceType} from '@my-game-plan/types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {MatchScore} from 'components/common/match-score/MatchScore';
import {useFormik} from 'formik';
import {LOADING_STATE} from 'types/screen.types';
import {useSnackbar} from 'notistack';
import {useConfirm} from 'material-ui-confirm';

import {useMatchVideos} from 'context/match-videos-context';

interface IMatchVideosOffsetsFormProps {
  match: FIMatch;
  isOpened: boolean;
  onClose: () => void;
  videoSource: TVideoSourceType;
  offsets?: number[];
}

interface IOffsets {
  offsets: number[];
}

function MatchVideosOffsetsForm(
  props: IMatchVideosOffsetsFormProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _matchVideosContext = useMatchVideos();

  const _formData: IOffsets = {
    offsets: props.offsets || [0, 0],
  };
  const _snackbar = useSnackbar();
  const _confirm = useConfirm();
  const [_loadingState, _setLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );

  const _validationSchema = yup.object<IOffsets>({
    offsets: yup
      .array()
      .of(yup.number().min(0))
      .length(2)
      .test(
        'second-half-positive',
        'Second half video start must be greater than 0',
        (value) =>
          Array.isArray(value) &&
          typeof value[1] !== 'undefined' &&
          value[1] > 0,
      ),
  });

  const _formik = useFormik<IOffsets>({
    onSubmit: _onSubmit,
    initialValues: _formData,
    validationSchema: _validationSchema,
    enableReinitialize: true,
  });

  /*
   * Side effects
   */

  /*
   * Handlers
   */
  async function _onSubmit() {
    try {
      _setLoadingState(LOADING_STATE.LOADING);
      _matchVideosContext.onOffsetsChange(
        props.match._id,
        props.videoSource,
        _formik.values.offsets,
      );
      _onClose(false);
    } catch (error) {
      _setLoadingState(LOADING_STATE.INITING);
      _snackbar.enqueueSnackbar(t('matches.uploadVideo.error'), {
        variant: 'error',
      });
    }
  }

  async function _onClose(shouldConfirm = true) {
    if (!shouldConfirm) {
      props.onClose();
      return;
    }

    try {
      if (_formik.dirty) {
        await _confirm({
          title: t('uploads.uploadForm.closeWarning.title'),
          confirmationText: t('uploads.uploadForm.closeWarning.text'),
          description: t('uploads.uploadForm.closeWarning.description'),
        });

        _clearForm();
        props.onClose();
      } else {
        props.onClose();
      }
    } catch (error) {
      // user closed alert model
    }
  }

  function _onExited() {
    _clearForm();
  }

  function _clearForm() {
    _formik.resetForm();

    _setLoadingState(LOADING_STATE.INITING);
    _matchVideosContext.setUploadProgress(0);
  }

  /*
   * Render
   */

  return (
    <Dialog
      open={props.isOpened}
      scroll="body"
      onClose={() => _onClose()}
      maxWidth="xs"
      PaperProps={{
        component: 'form',
        onSubmit: _formik.handleSubmit,
      }}
      fullWidth
      TransitionProps={{
        onExited: _onExited,
      }}>
      <DialogTitle>{t('matches.uploadVideo.updateOffsets')}</DialogTitle>
      <DialogContent>
        <Stack gap={6}>
          <MatchScore match={props.match} displayTeamNames />

          {/* Offsets: checkbox + 2 inputs */}
          <Stack gap={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('matches.uploadVideo.firstHalfVideoStart')}
                  onChange={_formik.handleChange}
                  name="offsets[0]"
                  type="number"
                  value={
                    typeof _formik.values.offsets?.[0] === 'undefined'
                      ? ''
                      : _formik.values.offsets[0]
                  }
                  inputProps={{min: 0}}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('matches.uploadVideo.secondHalfVideoStart')}
                  onChange={_formik.handleChange}
                  name="offsets[1]"
                  type="number"
                  value={
                    typeof _formik.values.offsets?.[1] === 'undefined'
                      ? ''
                      : _formik.values.offsets[1]
                  }
                  inputProps={{min: 0}}
                  error={
                    _formik.touched.offsets && Boolean(_formik.errors.offsets)
                  }
                />
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => _onClose()}>
          {t('general.cancel')}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          loading={_loadingState === LOADING_STATE.LOADING}
          type="submit">
          {t('matches.uploadVideo.cta')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default MatchVideosOffsetsForm;
