import React from 'react';
import {
  IWidgetPlaceholder,
  IWidgetType,
} from 'types/dashboard/overview-dashboards.types';

import styles from './PlaceholderWidget.module.scss';
import IMG_FORMATIONS from '../../../../../../assets/images/widgets/formations.svg';
import IMG_PLAYER_STATISTICS_SCORE from '../../../../../../assets/images/widgets/player-statistics-score.svg';
// import IMG_PLAYER_STATISTICS from '../../../../../../assets/images/widgets/player-statistics.svg';
import IMG_SCORES from '../../../../../../assets/images/widgets/scores.svg';
import {useDragAndDrop} from 'context/drag-and-drop.context';
import {translateValue} from 'helpers/translation.helper';

interface PlaceholderWidgetProps {
  widget: IWidgetPlaceholder;
}

type TImageMap = {
  type: IWidgetType;
  imageUrl: string;
};
const IMAGE_MAP: TImageMap[] = [
  {
    type: IWidgetType.PLAYER_STATISTICS,
    imageUrl: IMG_PLAYER_STATISTICS_SCORE,
  },
  {
    type: IWidgetType.SCORES,
    imageUrl: IMG_SCORES,
  },
  {
    type: IWidgetType.FORMATIONS,
    imageUrl: IMG_FORMATIONS,
  },
];

export const PlaceholderWidget = ({widget}: PlaceholderWidgetProps) => {
  const _dragAndDrop = useDragAndDrop();

  const title = translateValue(widget.key);
  let image = '';
  const _matchingImageConfig = IMAGE_MAP.find(
    (config) => config.type === widget.type,
  );
  if (_matchingImageConfig) {
    image = _matchingImageConfig.imageUrl;
  }

  return (
    <div style={_dragAndDrop}>
      <div className={styles.widget}>
        <div className={styles.header}>
          <h2>{title}</h2>
        </div>
        <div className={styles.imageWrapper}>
          <div
            className={styles.imageBg}
            style={{backgroundImage: `url(${image})`}}></div>
          {/*<img alt="" src={image} className={styles.image} />*/}
        </div>
      </div>
    </div>
  );
};
