import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface IAddActionButtonProps {
  onChange: () => void;
}
function AddActionButton(props: IAddActionButtonProps): JSX.Element | null {
  /* Hooks n State */
  const {t} = useTranslation();

  /* Render */
  const _addActionLabel = t('sentenceForm.sequence.addAction');
  return (
    <>
      <Button
        variant="text"
        color="secondary"
        //onClick={_onPopupTrigger}
        startIcon={<AddCircleIcon />}
        onClick={props.onChange}
        size="small"
        sx={{mt: 0, alignSelf: 'flex-start'}}>
        {_addActionLabel}
      </Button>
    </>
  );
}

export default AddActionButton;
