import _ from 'lodash';
import React, {PropsWithChildren, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {
  getTranslatedSuccessColumn,
  translateAction,
} from 'helpers/translation.helper';
import {
  IDataExplorer,
  IExplorerGroup,
} from 'types/dashboard/dashboard-explorer.types';
import {IPitchVisual} from 'types/dashboard/dashboard-pitch-visual.types';
import {
  IPlayerStats,
  THighlightedCell,
  TRowClickPlayers,
} from 'types/dashboard/dashboard-player-stats.types';
import {
  BlocksRow,
  IDashboard,
  IDashboardBlockType,
} from 'types/dashboard/dashboard.types';
import {IFilters} from 'types/filter.types';
import {StringMap} from 'types/map.types';
import {deepCopy, includesObject} from 'helpers/general/general.helper';
import {filterEventsForPitchVisualGroup} from 'helpers/dashboard/dashboard.helper';
import {useTeams} from 'context/team.context';
import {useMatches} from 'context/matches.context';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from 'helpers/general/context_generators.helper';
import {
  getDashboard,
  getDashboardDataExplorer as getDashboardExplorer,
} from 'controllers/dashboard/dashboard.controller';
import {useSnackbar} from 'notistack';
import {FIEventFilters} from '@my-game-plan/types';
import {useAuth} from '../auth.context';
import {useAnalytics} from '../analytics.context';
import ANALYTICS_EVENT from 'config/analytics/event-names.config';

export enum DASHBOARD_STATE {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

interface DashboardAPI {
  current: IDashboard | null;
  getDashboard: (filters?: IFilters, initialLoad?: boolean) => void;
  setActivePlayerStatsTab: (index: number) => void;
  activePlayerStatsTab: number;
  state: DASHBOARD_STATE;
  rows: BlocksRow[];
  updateFilters: (
    tableKey: string,
    filters: StringMap,
    label: StringMap,
    keepPlayers?: boolean,
    keepFilters?: boolean,
  ) => void;
  clearFilters: (tableKey: string, keepPlayers?: boolean) => void;
  highlighted: Record<
    string,
    {rows: number[]; column: number; cell: THighlightedCell}
  >;
  updateHighlightedRows: (tableKey: string, rowIndex: number) => void;
  clearHighlightedRows: (tableKey: string) => void;
  updateHighlightedColumn: (tableKey: string, columnIndex: number) => void;
  updateHighlightedCell: (tableKey: string, cell: THighlightedCell) => void;
  getDataExplorerTitle: (title: string) => string;
  getVideoPlayerTitle: () => string;
  filters: StringMap;
}
let filtersIntialState = {};

const context = createCustomContext<DashboardAPI>();
export const useDashboard = createContextHook(context);

export const DashboardProvider = (
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element => {
  const [_dashboard, _setDashboard] = useState<IDashboard | null>(null);
  const [_activePlayerStatsTab, _setActivePlayerStatsTab] = useState<number>(0);
  const [_blocksRowsOriginal, _setBlocksRowsOriginal] = useState<
    BlocksRow[] | undefined
  >(undefined);
  const [_blocksRows, _setBlocksRows] = useState<BlocksRow[]>([]);
  const [_state, _setState] = useState<DASHBOARD_STATE>(
    DASHBOARD_STATE.LOADING,
  );

  const [_highlighted, _setHighlighted] = useState<
    Record<string, {rows: number[]; column: number; cell: THighlightedCell}>
  >({});

  const [_startedFiltering, _setStartedFiltering] = useState<boolean>(false);
  const [_globalFilters, _setGlobalFilters] = useState<IFilters | null>(null);
  const [_filters, _setFilters] = useState<StringMap>(filtersIntialState);
  const [_filtersLabels, _setFiltersLabels] = useState<
    Record<number, string[]>
  >({});

  const _params = useParams();
  const _teamsContext = useTeams();
  const _matches = useMatches();
  const _auth = useAuth();
  const _analyticsContext = useAnalytics();

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    _setActivePlayerStatsTab(0);
    _setStartedFiltering(false);
  }, [_dashboard]);

  useEffect(() => {
    const _rows: BlocksRow[] = [];
    let _currentRowIndex = 0;
    if (_dashboard) {
      _dashboard.blocks.forEach((block, blockIndex) => {
        const _shouldStartANewRow = !!((blockIndex + 1) % 2);

        if (!_shouldStartANewRow) {
          _rows[_currentRowIndex].push(block);
          _currentRowIndex += 1;
        } else {
          const _newRow: BlocksRow = [block];
          _rows.push(_newRow);
        }
      });

      _setFiltersLabels({});
      _setFilterTableKeys(_rows);
      _setBlocksRowsOriginal([..._rows]);
      _setBlocksRows([..._rows]);
      _setState(DASHBOARD_STATE.SUCCESS);
    }
  }, [_dashboard]);

  useEffect(() => {
    if (_matches.error) {
      _setState(DASHBOARD_STATE.ERROR);
    }
  }, [_matches.error]);

  useEffect(() => {
    if (!_blocksRows.length && _state !== DASHBOARD_STATE.LOADING) {
      _setState(DASHBOARD_STATE.ERROR);
    }
  }, [_blocksRows, _state]);

  const _setFilterTableKeys = (rows: BlocksRow[]) => {
    // Get all table keys
    const keys = [];
    for (const row of rows) {
      for (const block of row) {
        if (block.type === IDashboardBlockType.PLAYER_STATISTICS) {
          const data = block.data as IPlayerStats;
          for (const tab of data.tabs) {
            for (const table of tab.tables) keys.push(table.key);
          }
        }
      }
    }

    // Make filters state for each table key
    const filters: StringMap = {};
    const highlighted: StringMap = {};
    for (const key of keys) {
      filters[key] = {players: []};
      highlighted[key] = {rows: [], column: -1, cell: {row: -1, column: -1}};
    }

    // Set filters state
    filtersIntialState = filters;
    _setFilters(filters);
    _setHighlighted(highlighted);
  };

  useEffect(() => {
    const _filter = async () => {
      if (_blocksRowsOriginal === undefined) return;

      // Get pitch visuals
      const blocksRowsCopy: BlocksRow[] = deepCopy(_blocksRowsOriginal);
      const pitchVisuals = blocksRowsCopy
        .flat()
        .filter((row) => row.type === IDashboardBlockType.PITCH_VISUAL)
        .map((row) => row.data as IPitchVisual);

      // Filter for every pitch visual
      for (const pitchVisual of pitchVisuals) {
        for (const tab of pitchVisual.tabs) {
          for (const group of tab.groups) {
            group.events = filterEventsForPitchVisualGroup(
              group,
              _filters[tab.linkedTable],
            );
          }
        }
      }

      // Set filtered data explorer block
      const _hasDataExplorer =
        _dashboard &&
        _dashboard.blocks.findIndex(
          (block) => block.type === IDashboardBlockType.DATA_EXPLORER,
        ) > -1;

      if (_hasDataExplorer) {
        const _activeTab = _activePlayerStatsTab;

        const _dataExplorerGroups = await getDashboardDataExplorer(_activeTab);

        if (_dataExplorerGroups) {
          for (const [rowIndex, row] of blocksRowsCopy.entries()) {
            const _dateExplorerIndex = row.findIndex(
              (block) => block.type === IDashboardBlockType.DATA_EXPLORER,
            );
            if (_dateExplorerIndex > -1) {
              // row[_dateExplorerIndex].data.tabs[_activePlayerStatsTab].groups = _dataExplorerBlock;
              const _dataExplorerData = {
                ..._blocksRows[rowIndex][_dateExplorerIndex].data,
              } as IDataExplorer;
              _dataExplorerData.tabs[_activeTab].groups = _dataExplorerGroups;
              row[_dateExplorerIndex].data = _dataExplorerData;
            }
          }
        }
      }

      // Set state
      _setBlocksRows(blocksRowsCopy);
    };

    if (_startedFiltering) {
      _filter();
    }
  }, [_filters, _startedFiltering]);

  /* Handlers */
  const getDashboardDataExplorer = async (
    tab = 0,
  ): Promise<IExplorerGroup[] | null | undefined> => {
    if (_params.dashboard && _teamsContext.current && _dashboard) {
      const _explorerBlock = _dashboard.blocks.find(
        (block) => block.type === IDashboardBlockType.DATA_EXPLORER,
      );
      const _linkedPlayerStatsBlock = _dashboard.blocks.find(
        (block) =>
          block.type === IDashboardBlockType.PLAYER_STATISTICS &&
          (block.data as IPlayerStats).key ===
            (_explorerBlock?.data as IDataExplorer).linkedBlock,
      );

      const _linkedTable = (_linkedPlayerStatsBlock?.data as IPlayerStats).tabs[
        tab
      ].tables[0];

      const _dataExplorerFilters = {
        ..._getMergedFilters(_filters, _linkedTable.key, _linkedTable.filters),
        'own_team._id': _auth.user?.team,
      } as FIEventFilters;

      return await getDashboardExplorer(
        _teamsContext.current._id,
        _params.dashboard,
        _dataExplorerFilters,
      );
    }
  };

  function _getMergedFilters(
    filters: StringMap,
    linkedTableKey: string,
    tableFilters?: StringMap,
  ) {
    const _mergedFilters: StringMap = {};

    for (const key in filters[linkedTableKey]) {
      let _values = filters[linkedTableKey][key];
      let _property = key;
      if (key === 'players' && _values) {
        _property = 'player._id';
        _values = _values.map((value: TRowClickPlayers) => {
          let _playerValue = value.player._id.toString();
          if (value.receiving_player) {
            _property = 'player_id_pair';
            _playerValue += `_${value.receiving_player._id}`;
          }

          return _playerValue;
        });
      }

      if (_mergedFilters[_property] !== undefined) {
        _mergedFilters[_property] = [..._mergedFilters[_property], ..._values];
      } else {
        _mergedFilters[_property] = _values;
      }
    }

    for (const key in tableFilters) {
      if (_mergedFilters[key]) {
        _mergedFilters[key] = _mergedFilters[key].concat(tableFilters[key]);
      } else {
        _mergedFilters[key] = tableFilters[key];
      }
    }

    return {
      ..._globalFilters,
      ..._mergedFilters,
    } as IFilters;
  }

  async function _getDashboard(
    filters?: IFilters,
    initialLoad?: boolean,
  ): Promise<IDashboard | null> {
    _setState(DASHBOARD_STATE.LOADING);
    if (_params.dashboard && _teamsContext.current && _matches.all) {
      _setDashboard(null);

      if (initialLoad) {
        _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_DASHBOARD, {
          action: _params.dashboard,
          team_id: _teamsContext.current._id,
          filters: filters,
        });
      }
      try {
        _setGlobalFilters(filters || null);
        const _requestFilters = {
          ...filters,
          'own_team._id': _auth.user?.team,
        } as FIEventFilters;

        const _data = await getDashboard(
          _teamsContext.current._id,
          _params.dashboard,
          _requestFilters,
        );

        if (_data) {
          _setDashboard(_data);
          return _data;
        } else {
          _setState(DASHBOARD_STATE.ERROR);
          _setDashboard(null);
          return null;
        }
      } catch (error) {
        _setState(DASHBOARD_STATE.ERROR);
        _setDashboard(null);
        enqueueSnackbar('Error fetching dashboard', {
          variant: 'error',
        });
        return null;
      }
    }

    return null;
  }

  async function _updateFilters(
    tableKey: string,
    filters: StringMap,
    labels: StringMap,
    keepPlayers = false,
    keepFilters = false,
  ) {
    // Filters copy
    let _filtersCopy: StringMap = {};

    if (keepPlayers && keepFilters) _filtersCopy = {..._filters[tableKey]};
    else if (keepPlayers)
      _filtersCopy = {
        players: [..._filters[tableKey].players],
      };
    else if (keepFilters)
      _filtersCopy = {
        ..._filters[tableKey],
        players: [],
      };
    else _filtersCopy = {players: []};

    // Filters labels copy
    const _filtersLabelsCopy = keepFilters ? {..._filtersLabels} : {};

    // Update filters
    Object.keys(filters).forEach((key) => {
      // If filter is a player (and dashboard is not a defending dashboard -> is for the whole team so no specific player can be selected)
      if (key === 'players' && !_dashboard?.isOpponentAction) {
        const filter = filters[key] as TRowClickPlayers;

        // If players in filters, remove players
        if (includesObject(filter, _filtersCopy[key])) {
          // Remove players
          _filtersCopy[key] = _filtersCopy[key].filter(
            (players: TRowClickPlayers) => !_.isEqual(players, filter),
          );
        } else {
          // Else, add players
          _filtersCopy[key].push(filter);
        }
      } else if (key !== 'players') {
        // If filter is not a player, update the filter
        _filtersCopy[key] = filters[key];
        _filtersLabelsCopy[_activePlayerStatsTab] = [labels.qualifier];
      }
    });

    const _newFilters = {..._filters, [tableKey]: _filtersCopy};

    _setFilters(_newFilters);
    _setFiltersLabels(_filtersLabelsCopy);

    _setStartedFiltering(true);
  }

  function _clearFilters(tableKey: string, keepPlayers = false) {
    const _filtersCopy: StringMap = {players: []};
    if (keepPlayers) _filtersCopy.players = [..._filters[tableKey].players];

    _setFilters({..._filters, [tableKey]: _filtersCopy});
    _setFiltersLabels({});
  }

  function _updateHighlightedRows(tableKey: string, rowIndex: number) {
    const highlightedCopy = {..._highlighted};

    if (highlightedCopy[tableKey].rows.includes(rowIndex)) {
      highlightedCopy[tableKey].rows = highlightedCopy[tableKey].rows.filter(
        (row: number) => row !== rowIndex,
      );
    } else {
      highlightedCopy[tableKey].rows.push(rowIndex);
    }

    _setHighlighted(highlightedCopy);
  }

  function _clearHighlightedRows(tableKey: string) {
    const highlightedCopy = {..._highlighted};
    highlightedCopy[tableKey].rows = [];
    _setHighlighted(highlightedCopy);
  }

  function _updateHighlightedColumn(tableKey: string, columnIndex: number) {
    const highlightedCopy = {..._highlighted};

    if (highlightedCopy[tableKey].column === columnIndex) {
      highlightedCopy[tableKey].column = -1;
    } else {
      highlightedCopy[tableKey].column = columnIndex;
    }

    _setHighlighted(highlightedCopy);
  }

  function _updateHighlightedCell(tableKey: string, cell: THighlightedCell) {
    const highlightedCopy = {..._highlighted};
    const highlightedCell = highlightedCopy[tableKey].cell;

    if (_.isEqual(cell, highlightedCell)) {
      highlightedCopy[tableKey].cell = {row: -1, column: -1};
    } else {
      highlightedCopy[tableKey].cell = cell;
    }

    _setHighlighted(highlightedCopy);
  }

  function _getDataExplorerTitle(title: string) {
    const action = translateAction(_dashboard?.action || '');
    const titleTranslated = t(`dashboard.dataExplorer.${title}`);
    const labels = [];

    // Push labels if not falsy
    labels.push(titleTranslated);
    labels.push(action);

    const qualifiers = _getQualifiers();
    if (qualifiers.length > 0) labels.push(qualifiers.join(', '));

    const playerNames = _getPlayerNames();
    if (playerNames.length > 0) labels.push(playerNames.join(', '));

    // Join labels
    return labels.join(' - ');
  }

  function _getVideoPlayerTitle() {
    const action = translateAction(_dashboard?.action || '');
    const tab = _getSelectedTableTabLabel();
    const labels = [];

    // Push labels if not falsy
    labels.push(action);

    if (tab) labels.push(_getTranslatedQualifier(tab));

    const qualifiers = _getQualifiers();
    if (qualifiers.length > 0) labels.push(qualifiers.join(', '));

    const playerNames = _getPlayerNames();
    if (playerNames.length > 0) labels.push(playerNames.join(', '));

    // Join labels

    return labels.join(' - ');
  }

  function _getSelectedTableTabLabel() {
    const playerStats = _blocksRows
      .flat()
      .find((row) => row.type === IDashboardBlockType.PLAYER_STATISTICS);
    if (playerStats === undefined) return '';

    const data = playerStats.data as IPlayerStats;
    return data.tabs[_activePlayerStatsTab].label;
  }

  function _getTranslatedQualifier(qualifier: string) {
    return getTranslatedSuccessColumn(t, qualifier, _dashboard?.action);
  }

  function _getQualifiers() {
    const qualifiers: string[] = [];

    if (!_filtersLabels[_activePlayerStatsTab]) return qualifiers;

    for (const qualifier of _filtersLabels[_activePlayerStatsTab]) {
      const translatedQualifier = _getTranslatedQualifier(qualifier);

      if (!qualifiers.includes(translatedQualifier))
        qualifiers.push(translatedQualifier);
    }

    return qualifiers;
  }

  function _getPlayerNames() {
    const names: string[] = [];

    for (const block of _blocksRows.flat()) {
      if (block.type === IDashboardBlockType.PLAYER_STATISTICS) {
        const data = block.data as IPlayerStats;
        const tab = data.tabs[_activePlayerStatsTab];

        for (const table of tab.tables) {
          const key = table.key;

          for (const filter of _filters[key].players) {
            const name = filter.player.display_name;
            if (!names.includes(name)) names.push(name);

            if (filter.receiving_player) {
              const name = filter.receiving_player.display_name;
              if (!names.includes(name)) names.push(name);
            }
          }
        }
      }
    }

    return names;
  }

  return createProvider(context, props, {
    current: _dashboard,
    getDashboard: _getDashboard,
    rows: _blocksRows,
    setActivePlayerStatsTab: _setActivePlayerStatsTab,
    activePlayerStatsTab: _activePlayerStatsTab,
    state: _state,
    updateFilters: _updateFilters,
    clearFilters: _clearFilters,
    highlighted: _highlighted,
    updateHighlightedRows: _updateHighlightedRows,
    clearHighlightedRows: _clearHighlightedRows,
    updateHighlightedColumn: _updateHighlightedColumn,
    updateHighlightedCell: _updateHighlightedCell,
    getDataExplorerTitle: _getDataExplorerTitle,
    getVideoPlayerTitle: _getVideoPlayerTitle,
    filters: _filters,
  });
};
