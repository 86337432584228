import DefaultDropdown from './dropdown/default-dropdown.view';

import ObservingDropdown from './dropdown/observing-dropdown/observing-dropdown.view';
import PlayersDropdown from './dropdown/players-dropdown.view';

import NumberInputSegment from './number-input/number-input.view';
import TextSegment from './text/text.view';

import CompetitionsDropdown from './dropdown/competitions-dropdown.view';
import TimeRangeDropdown from './dropdown/time-range-dropdown.view';
import PositionsDropdown from './dropdown/positions-dropdown.view';

import DateInputSegment from './date-input/date-input.view';
import BenchmarkSubjectSegment from './benchmark-subject/benchmark-subject.view';

const Segment = {
  Text: TextSegment,
  Players: PlayersDropdown,
  NumberInput: NumberInputSegment,
  Dropdown: DefaultDropdown,
  Observing: ObservingDropdown,
  Positions: PositionsDropdown,

  Competitions: CompetitionsDropdown,
  TimeRange: TimeRangeDropdown,
  Date: DateInputSegment,
  BenchmarkSubject: BenchmarkSubjectSegment,
};

export default Segment;
