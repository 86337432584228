import {TError} from 'types/sentence-form.types';
import {ITrackerSentenceProps} from 'types/trackers/tracker-sentence.types';
import {FIConditionalEventFilters, FISequenceAction} from '@my-game-plan/types';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Stack from '@mui/material/Stack';
import SentenceBlock from '../../blocks/sentence-block';
import SentenceTextSegment from '../../segment/variants/text/sentence-text-segment';
import ActionSegment from '../../segment/instances/action/action-segment';
import DetailsSegment from '../../segment/instances/details/details-segment';
import SequenceActionIndex from './sequence-action-index';
import DeleteConditionButton from '../../conditions/delete-condition-button';

interface ISequenceActionProps
  extends Omit<ITrackerSentenceProps, 'onChange' | 'errors'> {
  actionIndex: number;
  onChange?: (index: number, data: Partial<FISequenceAction>) => void;
  onDelete?: (index: number) => void;
  errors?: TError<FISequenceAction>;
  sequenceAction: FISequenceAction;
  against?: boolean;
}

function SequenceAction(props: ISequenceActionProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  /* Handlers */
  function _onChange(data: Partial<FISequenceAction>) {
    if (!props.onChange) {
      return;
    }
    props.onChange(props.actionIndex, data);
  }

  function _onDelete() {
    if (!props.onDelete) {
      return;
    }
    props.onDelete(props.actionIndex);
  }

  function _onDetailsChange(details: FIConditionalEventFilters) {
    _onChange({
      ...props.sequenceAction,
      details: details,
    });
  }

  /*
   * Render
   */
  const {errors, onChange, ..._commonProps} = props;

  /* Action */
  const _ActionSegment = (
    <ActionSegment
      {..._commonProps}
      value={props.sequenceAction}
      onChange={_onChange}
      displayArticle
      error={props.errors?.action}
    />
  );

  /* "that is"  text */
  const _ThatisTextSegment = (
    <SentenceTextSegment
      {..._commonProps}
      value={t('sentenceForm.details.thatAre', {count: 1})}
    />
  );

  /* Details */
  const _DetailsSegment = (
    <DetailsSegment
      {..._commonProps}
      value={props.sequenceAction.details}
      action={props.sequenceAction.action}
      onChange={_onDetailsChange}
    />
  );

  return (
    <Stack
      direction="row"
      py={2}
      gap={1}
      alignItems={'center'}
      justifyContent={'flex-start'}
      position={'relative'}
      alignSelf={'flex-start'}
      sx={{
        '&:hover': {
          '.deleteButton': {
            display: 'flex',
          },
        },
      }}>
      <SequenceActionIndex index={props.actionIndex} />
      <SentenceBlock.Line {..._commonProps}>
        {_ActionSegment}
        {_ThatisTextSegment}
        {_DetailsSegment}
        {!props.readonly && props.onDelete && (
          <DeleteConditionButton onClick={_onDelete} />
        )}
      </SentenceBlock.Line>
    </Stack>
  );
}

export default SequenceAction;
