import React, {useEffect, useRef, useState} from 'react';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {FIFormattedAutomation, TTrackerExecutorType} from '@my-game-plan/types';
import TrackerCreateButton from 'components/trackers/misc/tracker-create-button/tracker-create-button.view';
import {useTranslation} from 'react-i18next';
import {ILinkState} from 'types/navigation.types';
import {NAV_LEVEL_1_ROUTE} from 'config/navigation.config';
import {
  findConcreteObserverIfPossible,
  getSingleExecutorTypeWithOneSubject,
  hasMultipleExecutors,
} from 'helpers/automation/automation-form.helper';
import {
  generateStatisticDeepDiveURL,
  generateStatisticDeepdiveURLPrefix,
} from 'helpers/automation/automation.helper';
import {useNavigate} from 'react-router-dom';
import DeepDiveLinkSelectorDialog from './deep-dive-link-selector-dialog';
import {useManageTrackers} from 'context/manage-trackers.context';

interface IManageTrackersActionsProps {
  tracker: FIFormattedAutomation;
}

function ManageTrackersActions(
  props: IManageTrackersActionsProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const navigate = useNavigate();

  const _manageTrackersContext = useManageTrackers();
  const [_selectedExecutorType, _setSelectedExecutorType] =
    useState<TTrackerExecutorType | null>(null);

  const _backLink = useRef<ILinkState>({
    route: NAV_LEVEL_1_ROUTE.PREFERENCES,
    label: t('preferences.manageTrackers.title'),
  });
  const [_deepDiveURL, _setDeepDiveURL] = React.useState<string>('');
  const [_isDialogOpen, _setIsDialogOpen] = React.useState<boolean>(false);

  /*
   * Side Effects
   */
  useEffect(() => {
    const _singleExecutor = getSingleExecutorTypeWithOneSubject(props.tracker);
    let _newExecutorType = _singleExecutor?.property || 'team_performance';
    if (!_singleExecutor && props.tracker.team_performance?.length) {
      _newExecutorType = 'team_performance';
    } else if (!_singleExecutor && props.tracker.player_development?.length) {
      _newExecutorType = 'player_development';
    } else if (!_singleExecutor && props.tracker.opponent_analysis?.length) {
      _newExecutorType = 'opponent_analysis';
    } else if (!_singleExecutor && props.tracker.scouting_profile_ids?.length) {
      _newExecutorType = 'scouting_profile_ids';
    }
    _setSelectedExecutorType(_newExecutorType);
  }, [props.tracker]);

  useEffect(() => {
    if (!_selectedExecutorType) {
      return;
    }

    const _redirectURL = _generateDeepDiveURL(_selectedExecutorType, 0);

    _setDeepDiveURL(_redirectURL);
  }, [props.tracker, _selectedExecutorType]);

  /*
   * Handlers
   */
  function _onNavigateToDeepDive() {
    const _hasMultipleExecutors = hasMultipleExecutors(props.tracker);
    const _singleExecutor = getSingleExecutorTypeWithOneSubject(props.tracker);

    if (_singleExecutor && !_hasMultipleExecutors) {
      navigate(_deepDiveURL, {state: _backLink.current});
      return;
    }

    _setIsDialogOpen(true);
  }

  function _onDialogClose() {
    _setIsDialogOpen(false);
  }

  function _generateDeepDiveURL(
    executorType: TTrackerExecutorType,
    executorIndex: number,
  ): string {
    const _trackerData = {...props.tracker};
    const _defaultConcreteExecutor = findConcreteObserverIfPossible(
      props.tracker,
      executorType,
      executorIndex,
    );

    if (_defaultConcreteExecutor.executor) {
      _trackerData.concrete_executor = _defaultConcreteExecutor.executor;
    }

    const _urlPrefix = generateStatisticDeepdiveURLPrefix(
      _defaultConcreteExecutor.executorType,
    );
    const _redirectURL = generateStatisticDeepDiveURL(
      _trackerData,
      _defaultConcreteExecutor.subject_id,
      undefined,
      _urlPrefix,
    );

    return _redirectURL;
  }

  /*
   * Render
   */
  return (
    <Stack direction="row" gap={1} justifyContent="flex-end">
      <TrackerCreateButton
        data={props.tracker}
        type={_selectedExecutorType || 'team_performance'}
        Button={
          <IconButton size="medium" color="secondary">
            <EditIcon fontSize="medium" />
          </IconButton>
        }
        onSubmit={_manageTrackersContext.fetchTrackers}
        backLink={_backLink.current}
      />
      <IconButton size="medium" color="primary" onClick={_onNavigateToDeepDive}>
        <ArrowForwardIcon />
      </IconButton>
      <DeepDiveLinkSelectorDialog
        tracker={props.tracker}
        open={_isDialogOpen}
        onClose={_onDialogClose}
        defaultExecutorType={_selectedExecutorType || 'team_performance'}
        generateDeepDiveURL={_generateDeepDiveURL}
        backLinkState={_backLink.current}
      />
    </Stack>
  );
}

export default ManageTrackersActions;
