import React, {useEffect} from 'react';
import {
  createContextHook,
  createCustomContext,
} from 'helpers/general/context_generators.helper';
import {LOADING_STATE} from 'types/screen.types';
import {FIFormattedAutomation, FITag} from '@my-game-plan/types';
import {PropsWithChildren, useState} from 'react';
import {fetchTrackerLibrary} from 'controllers/trackers.controller';
import {useTeams} from './team.context';
import {useTranslation} from 'react-i18next';

interface ITagWithTrackers {
  tag: FITag;
  trackers: FIFormattedAutomation[];
}
export interface IManageTrackersContextAPI {
  loadingState: LOADING_STATE;
  groupedTrackers: ITagWithTrackers[];
  fetchTrackers: () => void;
}

const context = createCustomContext<IManageTrackersContextAPI>();
export const useManageTrackers = createContextHook(context);

export function MangeTrackersProvider(
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const [_groupedTrackers, _setGroupedTrackers] = useState<ITagWithTrackers[]>(
    [],
  );
  const [_loadingState, _setLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );

  /*
   * Side Effects
   */
  useEffect(() => {
    _fetchAndGroupTrackers();
  }, [_teamsContext.preferences]);

  /*
   * Handlers
   */
  async function _fetchAndGroupTrackers() {
    try {
      if (_loadingState !== LOADING_STATE.INITING) {
        _setLoadingState(LOADING_STATE.LOADING);
      }
      const _generatedTrackerGroups: ITagWithTrackers[] = [];

      // Fetch trackers
      const _fetchedTrackers = await fetchTrackerLibrary();

      // Create group for trackers without tags
      const _noTagGroup: ITagWithTrackers = {
        tag: {
          label: t('eventAutomations.noTags'),
          _id: '',
        },
        trackers: _fetchedTrackers.filter((tracker) => !tracker.tags?.length),
      };

      // For each tag, create a group and find trackers
      _teamsContext.preferences?.tags?.forEach((tag) => {
        const _tagGroup: ITagWithTrackers = {
          tag: tag,
          trackers: _fetchedTrackers.filter((tracker) =>
            tracker.tags?.includes(tag._id),
          ),
        };
        _generatedTrackerGroups.push(_tagGroup);
      });

      if (_noTagGroup.trackers.length) {
        _generatedTrackerGroups.push(_noTagGroup);
      }

      _setGroupedTrackers(_generatedTrackerGroups);
      _setLoadingState(LOADING_STATE.SUCCESS);
    } catch (error) {
      _setLoadingState(LOADING_STATE.ERROR);
    }
  }

  /*
   * Return context
   */
  const _value: IManageTrackersContextAPI = {
    loadingState: _loadingState,
    groupedTrackers: _groupedTrackers,
    fetchTrackers: _fetchAndGroupTrackers,
  };

  return <context.Provider value={_value}>{props.children}</context.Provider>;
}
