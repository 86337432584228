import React, {memo, useEffect, useRef, useState} from 'react';
import cn from 'classnames';

import Forward10 from '@mui/icons-material/Forward10';
import Fullscreen from '@mui/icons-material/Fullscreen';
import FullscreenExit from '@mui/icons-material/FullscreenExit';
import ModeEdit from '@mui/icons-material/ModeEdit';
import Pause from '@mui/icons-material/Pause';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Replay from '@mui/icons-material/Replay';
import Replay10 from '@mui/icons-material/Replay10';
import SkipNext from '@mui/icons-material/SkipNext';
import SkipPrevious from '@mui/icons-material/SkipPrevious';

import Typography from '@mui/material/Typography';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import SaveIcon from '@mui/icons-material/Save';

import styles from './VideoControlBar.module.scss';
import {formatVideoTime, timestampToSecondsString} from 'helpers/video.helper';

import screenfull from 'screenfull';
import {useTranslation} from 'react-i18next';
import {
  DEFAULT_CLIP_BEFORE_OFFSET,
  editVideoConstants,
  MAX_CLIP_OFFSET,
} from 'config/clips.config';
import {useVideoEdit} from 'context/video/video-edit.context';

import {useVideo} from 'context/video/video.context';
import {translateAction, translateValue} from 'helpers/translation.helper';
import {capitalize} from 'lodash';
import {isNullOrUndefined} from 'helpers/general/general.helper';
import VideoControlSettings from './video-control-settings.view';
import VideoSourceSelector from './video-source-selector.view';
import {alpha, darken, Popover, useTheme} from '@mui/material';
import {
  FHomeTeam,
  FIMatchEvent,
  formatMatchTimeFromTimestamp,
} from '@my-game-plan/types';
import EventPopup from 'components/dashboard/event-popup/EventPopup';

function VideoControlBar() {
  /*
   * Hooks n State
   */
  const {
    currentClip,
    previousClip,
    nextClip,
    flipVideoState,
    playing,
    fullscreen,
    played,
    setTime,
    duration,
    setIsEditMenuOpen,
    isEditMenuOpen,
    playlist,
    openPlaylistBar,
    actionInfo,
    isShowingSequences,
    eventsPerSequence,
    videoOffsets,
  } = useVideo();

  const {
    cancelEdit,
    totalVideoOffsets,
    saveEdit,
    playFromStart,
    xSecondsBackward,
    xSecondsForward,
    isWatchingCustomOffsets,
    timeString,
  } = useVideoEdit();
  const _theme = useTheme();

  const [_xMouseOnProgressBar, _setxMouseOnProgressBar] = useState(0);
  const [showTimeOnProgressBar, _setShowTimeOnProgressBar] = useState(false);
  const [_canLoadVideoBefore, _setCanLoadVideoBefore] =
    useState<boolean>(false);
  const [_team, _setTeam] = useState<FHomeTeam | null>(null);
  const [_canLoadVideoAfter, _setCanLoadVideoAfter] = useState<boolean>(false);
  const [_isFullScreen, _setIsFullscreen] = useState(false);
  const [_hasPrevVideo, _setHasPrevVideo] = useState<boolean>(false);
  const [_hasNextVideo, _setHasNextVideo] = useState<boolean>(false);
  const [_infoLine, _setInfoLine] = useState<string[]>([]);
  const [_hoveredBar, _setHoveredBar] = useState<number | null>(null);
  const [_whiteBars, _setWhiteBars] = React.useState<
    {position: number; clip: FIMatchEvent}[]
  >([]);

  const {t} = useTranslation();
  const _progressBarWidth = useRef<HTMLDivElement>(null);

  const _bgColor = useRef<string>(
    alpha(darken(_theme.palette.background.default, 0.3), 0.9),
  );

  screenfull.onchange(() => {
    _setIsFullscreen(screenfull.isFullscreen);
  });

  useEffect(() => {
    /* User should not be able to request extra seconds when editing */
    let _newCanLoadVideoBeforeState = true;
    let _newCanLoadVideoAfterState = true;
    if (isEditMenuOpen) {
      return;
    } else {
      if (totalVideoOffsets[0] >= MAX_CLIP_OFFSET) {
        _newCanLoadVideoBeforeState = false;
      }

      if (totalVideoOffsets[1] >= MAX_CLIP_OFFSET) {
        _newCanLoadVideoAfterState = false;
      }
    }

    _setCanLoadVideoBefore(_newCanLoadVideoBeforeState);
    _setCanLoadVideoAfter(_newCanLoadVideoAfterState);
  }, [isEditMenuOpen, totalVideoOffsets]);

  /* Define whether next/previous should be disabled */
  useEffect(() => {
    let _tmpHasPrevVideo = true;
    let _tmpHasNextVideo = true;
    if (playlist.length === 1) {
      _tmpHasPrevVideo = false;
      _tmpHasNextVideo = false;
    } else {
      const _index = playlist.findIndex((clip) => clip._id === currentClip._id);

      if (_index === 0) {
        _tmpHasPrevVideo = false;
      }

      if (_index === playlist.length - 1) {
        _tmpHasNextVideo = false;
      }
    }

    _setHasPrevVideo(_tmpHasPrevVideo);
    _setHasNextVideo(_tmpHasNextVideo);
  }, [playlist, currentClip]);

  useEffect(() => {
    const _newInfoLine = [];
    if (isShowingSequences && currentClip.sequence) {
      _newInfoLine.push(
        `${eventsPerSequence[currentClip.sequence?._id].length} ${t(
          'eventAutomations.sequence.events',
        )}`,
      );
    }
    if (currentClip.sequence?.result) {
      const _translatetionKey = `eventAutomations.sequence.sequence_result.${currentClip.sequence?.result}`;
      _newInfoLine.push(`${t(_translatetionKey)}`);
    }
    _setInfoLine(_newInfoLine);
  }, [isShowingSequences, currentClip]);

  useEffect(() => {
    if (isShowingSequences) {
      let _newTeam;
      if (currentClip.match.home_team._id === currentClip.sequence?.team_id) {
        _newTeam = currentClip.match.home_team;
      } else {
        _newTeam = currentClip.match.away_team;
      }
      _setTeam(_newTeam);
    }
  }, [currentClip, isShowingSequences]);

  useEffect(() => {
    if (isShowingSequences && currentClip.sequence) {
      const calculatedBars = eventsPerSequence[currentClip.sequence._id]
        .map((event) => {
          if (currentClip.sequence?.start) {
            const _timeInVideo =
              videoOffsets[0] + event.timestamp - currentClip.sequence.start;
            const _total = videoOffsets[0] + videoOffsets[1];

            const _position = (_timeInVideo / _total) * 100;
            return {position: _position, clip: event};
          }
          return null;
        })
        .filter(
          (bar): bar is {position: number; clip: FIMatchEvent} => bar !== null,
        );
      _setWhiteBars(calculatedBars);
    } else {
      _setWhiteBars([]); // Clear whiteBars if conditions are not met
    }
  }, [isShowingSequences, currentClip, eventsPerSequence, videoOffsets]);

  /*
   * Handlers
   */

  function _calcXMouseOnProgressBar(e: React.MouseEvent<HTMLDivElement>) {
    const target = e.target as HTMLDivElement;
    const rect = target?.getBoundingClientRect();
    const x_pos = (e.clientX - rect.left) / rect.width;
    _setxMouseOnProgressBar(x_pos);
  }

  function _onMouseEnterProgressBar(e: React.MouseEvent<HTMLDivElement>) {
    _setShowTimeOnProgressBar(true);
    _calcXMouseOnProgressBar(e);
  }

  function _calcXProgressBarPopup() {
    const threshold = 10;
    const marginRight = 15;
    let res =
      _xMouseOnProgressBar *
      (_progressBarWidth.current ? _progressBarWidth.current.clientWidth : 0);
    res -= marginRight;

    return res <= threshold ? threshold : res;
  }

  function _onPlayFromSequenceStart() {
    if (currentClip?.sequence?.start) {
      const _currentStartOffset = totalVideoOffsets[0];

      const _diff =
        currentClip.timestamp -
        currentClip.sequence.start -
        _currentStartOffset +
        DEFAULT_CLIP_BEFORE_OFFSET;

      xSecondsBackward('add-time', _diff);
    }
  }

  function _onPlayFromSequenceEnd() {
    if (currentClip?.sequence?.end) {
      const _currentEndOffset = totalVideoOffsets[1];

      // Calculate end offset. Do "-currentEndOffset" to reset clip length
      const _diff =
        currentClip.sequence.end -
        currentClip.timestamp -
        _currentEndOffset +
        DEFAULT_CLIP_BEFORE_OFFSET;

      xSecondsForward('add-time', _diff);
    }
  }

  function _onSaveCurrentOffsets() {
    saveEdit(false);
  }

  /*
   * Render
   */
  const _playIcon = playing ? (
    <Pause className={styles.control_bar_icon} />
  ) : (
    <PlayArrow className={styles.control_bar_icon} />
  );

  const _screenFullIcon = _isFullScreen ? (
    <FullscreenExit className={styles.control_bar_icon}></FullscreenExit>
  ) : (
    <Fullscreen className={styles.control_bar_icon} />
  );

  let _playerActionValue = '';
  if (currentClip?.event_type) {
    _playerActionValue = capitalize(translateValue(currentClip.event_type));
  } else if (actionInfo?.action) {
    _playerActionValue = capitalize(translateAction(actionInfo.action, 1));
  }

  const _barClassNames = cn(styles.control_bar, {
    [styles.control_bar_sidebar_open]: openPlaylistBar,
  });

  return (
    <Box
      className={_barClassNames}
      style={{justifyContent: 'center'}}
      sx={{
        bgcolor: _bgColor.current,
      }}>
      <div
        ref={_progressBarWidth}
        className={styles.progress_bar_div}
        onMouseEnter={(x) => _onMouseEnterProgressBar(x)}
        onMouseOut={() => _setShowTimeOnProgressBar(false)}
        onMouseMove={(x) => _calcXMouseOnProgressBar(x)}
        onClick={() => setTime(_xMouseOnProgressBar)}>
        {/* Event Indicators */}
        {_whiteBars.map((bar, index) => (
          <div
            className={styles.event_indicator}
            key={index}
            style={{
              left: `${bar.position}%`, // Position indicator at the event point
            }}
            onMouseEnter={() => _setHoveredBar(index)}
            onMouseLeave={() => _setHoveredBar(null)}
            onClick={(event) => {
              event.stopPropagation(); // Prevent propagation to not trigger again time setting
              setTime(bar.position / 100);
            }}>
            {_hoveredBar === index && _whiteBars[index]?.clip && (
              <Popover
                sx={{pointerEvents: 'none'}}
                PaperProps={{
                  sx: {
                    bgcolor: 'background.default',
                  },
                }}
                disableAutoFocus
                open={true}
                anchorEl={_progressBarWidth.current} // Targeting the current event bar
                anchorReference="anchorPosition" // Use the anchorEl as reference for positioning
                anchorPosition={{
                  left: _progressBarWidth.current?.clientWidth
                    ? (_progressBarWidth.current?.clientWidth *
                        _whiteBars[index].position) /
                      100
                    : 0,
                  top:
                    (_progressBarWidth.current?.getBoundingClientRect()
                      .bottom || 0) -
                    44 * 4, // Position just above the controll bar
                }}>
                {_hoveredBar === index && (
                  <EventPopup
                    action={_whiteBars[index].clip.event_type}
                    event={_whiteBars[index].clip}
                  />
                )}
              </Popover>
            )}
          </div>
        ))}

        <div className={styles.no_pointer_events}>
          <div
            className={styles.progress_time_hover}
            style={{left: _calcXProgressBarPopup()}}>
            {showTimeOnProgressBar
              ? formatVideoTime(
                  currentClip,
                  _xMouseOnProgressBar * duration,
                  totalVideoOffsets[0],
                )
              : ''}
          </div>

          <LinearProgress
            color={'primary'}
            variant="determinate"
            value={played.played * 100}
            valueBuffer={(played.played + played.loaded) * 100}
            sx={{
              '& .MuiLinearProgress-bar': {transition: 'transform 0s linear'},
            }}
          />
        </div>
      </div>

      <Box className={styles.action_bar}>
        <Stack
          direction="row"
          justifyContent={'space-between'}
          sx={{height: '100%'}}>
          <Stack
            pl={2}
            spacing={1}
            direction="row"
            alignItems={'center'}
            divider={
              <Divider
                className={styles.custom_divider}
                orientation="vertical"
                flexItem
              />
            }>
            <Box>
              {/* Previous clip */}
              <Tooltip
                placement="top"
                title={t('video-player.tooltip.previous_clip')}>
                <span>
                  <IconButton
                    sx={{color: 'text.secondary'}}
                    onClick={previousClip}
                    className={styles.IconButton}
                    disabled={!_hasPrevVideo}>
                    <SkipPrevious className={styles.control_bar_icon} />
                  </IconButton>
                </span>
              </Tooltip>

              {/* Play / Pause */}
              <Tooltip
                placement="top"
                title={
                  playing
                    ? t('video-player.tooltip.pause')
                    : t('video-player.tooltip.play')
                }>
                <IconButton
                  sx={{color: 'text.primary'}}
                  onClick={flipVideoState}
                  className={styles.IconButton}>
                  {_playIcon}
                </IconButton>
              </Tooltip>

              {/* Play from start */}
              <Tooltip
                placement="top"
                title={t('video-player.tooltip.play_from_start')}>
                <IconButton
                  sx={{color: 'text.secondary'}}
                  onClick={playFromStart}
                  className={styles.IconButton}>
                  <Replay className={styles.control_bar_icon} />
                </IconButton>
              </Tooltip>

              {/* Next Clip */}
              <Tooltip
                placement="top"
                title={t('video-player.tooltip.next_clip')}>
                <span>
                  <IconButton
                    sx={{color: 'text.secondary'}}
                    onClick={nextClip}
                    className={styles.IconButton}
                    disabled={!_hasNextVideo}>
                    <SkipNext className={styles.control_bar_icon} />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>

            <Box>
              {/* Play from sequence start */}
              {!isNullOrUndefined(currentClip?.sequence?.start) &&
                !isShowingSequences && (
                  <Tooltip
                    placement="top"
                    title={t('video-player.tooltip.sequence_start')}>
                    <span>
                      <IconButton
                        sx={{color: 'text.secondary'}}
                        onClick={_onPlayFromSequenceStart}
                        className={styles.IconButton}>
                        <FirstPageIcon className={styles.control_bar_icon} />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              {/* Add video: 10 seconds earlier */}
              <Tooltip
                placement="top"
                title={t('video-player.tooltip.ten_seconds_backward')}>
                <span>
                  <IconButton
                    sx={{color: 'text.secondary'}}
                    disabled={!_canLoadVideoBefore}
                    onClick={() =>
                      xSecondsBackward(
                        'add-time',
                        editVideoConstants.videoDurationIncrease,
                      )
                    }
                    className={styles.IconButton}>
                    <Replay10 className={styles.control_bar_icon} />
                  </IconButton>
                </span>
              </Tooltip>

              {/* Open editor */}
              <Tooltip placement="top" title={t('video-player.tooltip.edit')}>
                <IconButton
                  sx={{color: 'text.secondary'}}
                  onClick={() =>
                    isEditMenuOpen ? cancelEdit : setIsEditMenuOpen(true)
                  }
                  className={styles.IconButton}>
                  <ModeEdit className={styles.control_bar_icon} />
                </IconButton>
              </Tooltip>

              {/* Add video: 10 seconds later */}
              <Tooltip
                placement="top"
                title={t('video-player.tooltip.ten_seconds_forward')}>
                <span>
                  <IconButton
                    sx={{color: 'text.secondary'}}
                    disabled={!_canLoadVideoAfter}
                    onClick={() =>
                      xSecondsForward(
                        'add-time',
                        editVideoConstants.videoDurationIncrease,
                      )
                    }
                    className={styles.IconButton}>
                    <Forward10 className={styles.control_bar_icon} />
                  </IconButton>
                </span>
              </Tooltip>

              {/* Show sequence end */}
              {!isNullOrUndefined(currentClip?.sequence?.end) &&
                !isShowingSequences && (
                  <Tooltip
                    placement="top"
                    title={t('video-player.tooltip.sequence_end')}>
                    <span>
                      <IconButton
                        sx={{color: 'text.secondary'}}
                        onClick={_onPlayFromSequenceEnd}
                        className={styles.IconButton}>
                        <LastPageIcon className={styles.control_bar_icon} />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
            </Box>

            {/* Player info */}
            {isShowingSequences ? (
              <Stack spacing={2} direction="row">
                <Box className={styles.v_center}>
                  <Avatar
                    alt={_team?.name}
                    src={_team?.image_url}
                    sx={{width: 28, height: 28}}
                  />
                </Box>

                <Stack direction="row" gap={0.5} alignItems="center">
                  <Typography color="default" fontWeight="600" variant="body2">
                    {_infoLine.join(' -')}
                  </Typography>

                  {currentClip.sequence?.start && currentClip.sequence.end && (
                    <Typography
                      color="text.secondary"
                      variant="body2">{`${formatMatchTimeFromTimestamp(
                      currentClip.sequence?.period ?? 1,
                      currentClip.sequence?.start,
                    )} - ${formatMatchTimeFromTimestamp(
                      currentClip.sequence?.period ?? 1,
                      currentClip.sequence?.end,
                    )}`}</Typography>
                  )}
                </Stack>
              </Stack>
            ) : (
              <Stack spacing={2} direction="row">
                <Box className={styles.v_center}>
                  <Avatar
                    alt={currentClip.player?.display_name}
                    src={currentClip.player.image_url}
                    sx={{width: 28, height: 28}}
                  />
                </Box>

                <Stack direction="row" gap={0.5} alignItems="center">
                  <Typography color="default" fontWeight="600" variant="body2">
                    {currentClip.player?.display_name}
                  </Typography>
                  {!openPlaylistBar && (
                    <Typography
                      color="default"
                      variant="body2">{`- ${_playerActionValue}`}</Typography>
                  )}
                  <Typography
                    color="text.secondary"
                    variant="body2">{`(${timestampToSecondsString(
                    currentClip.timestamp,
                    currentClip.period,
                  )})`}</Typography>
                </Stack>
              </Stack>
            )}

            {/* Time indicator */}
            <Box px={1}>
              <Typography
                fontStyle={'italic'}
                variant="body2"
                className={styles.time}
                color="text.secondary">
                {timeString}
              </Typography>
            </Box>
          </Stack>

          {/* Editor */}

          {isEditMenuOpen ? (
            <Stack direction="row" alignItems={'center'} spacing={2} pr={2}>
              <Button
                className={styles.custom_button}
                onClick={() => saveEdit()}
                color="secondary"
                variant={'contained'}>
                {t('video-player.edit.saveEditedClip')}
              </Button>

              <Button
                className={styles.custom_button}
                onClick={cancelEdit}
                color="secondary"
                variant={'outlined'}>
                {t('general.cancel')}
              </Button>
            </Stack>
          ) : (
            <Stack
              pr={2}
              direction="row"
              spacing={1}
              alignItems={'center'}
              divider={
                <Divider
                  className={styles.custom_divider}
                  orientation="vertical"
                  flexItem
                />
              }>
              {/* Save current offsets */}
              {isWatchingCustomOffsets && (
                <Tooltip placement="top" title={t('video-player.tooltip.save')}>
                  <span>
                    <IconButton
                      color="primary"
                      onClick={_onSaveCurrentOffsets}
                      className={styles.IconButton}>
                      <SaveIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              {/* Angle */}
              <VideoSourceSelector />

              {/* Settings */}
              {/* Contains volume, autoplay and angle */}
              <VideoControlSettings />

              {/* Full screen toggle */}
              <Tooltip
                placement="top"
                title={t('video-player.tooltip.fullscreen')}>
                <IconButton
                  sx={{color: 'text.secondary'}}
                  onClick={fullscreen}
                  className={styles.IconButton}>
                  {_screenFullIcon}
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </Box>
    </Box>
  );
}

export default memo(VideoControlBar);
