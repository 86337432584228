import {REQUEST_ERRORS} from 'config/errors.config';
import {useAPI} from 'hooks/api.hooks';
import {
  FIAutomationDataQueryParams,
  FIBenchmarkData,
  FIEventAutomationPostData,
  FIFormattedAutomation,
  FIMatchEvent,
  FITrackersOverview,
  FITrackersOverviewList,
} from '@my-game-plan/types';

const api = useAPI();

/*
 * Fetch all trackers
 */
export async function fetchAllTrackers(
  params?: FIAutomationDataQueryParams,
): Promise<FITrackersOverviewList> {
  const URL = 'trackers/all';
  try {
    const {data} = await api.get<FITrackersOverviewList>({
      url: URL,
      params: params,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function fetchPlayersWithPerformance(
  params?: FIAutomationDataQueryParams,
): Promise<FITrackersOverview> {
  const URL = 'trackers/players';
  try {
    const {data} = await api.get<FITrackersOverview>({
      url: URL,
      params: params,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

/*
 * Tracker deep dive
 */
export async function fetchTrackerByStatisticId(
  statisticId: string,
  subjectId: string,
  filters?: FIAutomationDataQueryParams,
): Promise<FIFormattedAutomation> {
  try {
    const _URL = `statistics/${statisticId}/${subjectId}`;
    const {data} = await api.get<FIFormattedAutomation>({
      url: _URL,
      params: filters,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function fetchEventsForTracker(
  statisticId: string,
  subjectId: string,
  filters?: FIAutomationDataQueryParams,
): Promise<FIMatchEvent[]> {
  try {
    const _URL = `statistics/${statisticId}/${subjectId}/events`;
    const {data} = await api.get<FIMatchEvent[]>({
      url: _URL,
      params: filters,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function fetchBenchmarkForStatistic(
  statisticId: string,
  subjectId: string,
  filters?: FIAutomationDataQueryParams,
): Promise<FIBenchmarkData> {
  const _URL = `statistics/${statisticId}/${subjectId}/benchmark`;
  try {
    const {data} = await api.get<FIBenchmarkData>({
      url: _URL,
      params: filters,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function createTracker(
  opponentAutomation: Partial<FIEventAutomationPostData>,
): Promise<FIFormattedAutomation> {
  const _URL = 'trackers';
  try {
    const {data} = await api.post<FIFormattedAutomation>({
      url: _URL,
      data: opponentAutomation,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function editTracker(
  postData: Partial<FIEventAutomationPostData>,
): Promise<FIFormattedAutomation> {
  const _URL = `trackers/${postData._id}`;
  try {
    const {data} = await api.put<FIFormattedAutomation>({
      url: _URL,
      data: postData,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function deleteTracker(id: string): Promise<void> {
  const _URL = `trackers/${id}`;

  try {
    await api.del<void>({
      url: _URL,
    });

    return;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

/*
 * Library
 */
export async function fetchTrackerLibrary(): Promise<FIFormattedAutomation[]> {
  const _URL = 'trackers/library';
  try {
    const {data} = await api.get<FIFormattedAutomation[]>({
      url: _URL,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}
