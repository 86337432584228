import React from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  FIAutomationPlayerMatch,
  FIPhysicalMatch,
  getDecimalsForUnit,
  TCompetitionType,
  TPhysicalCategoryUnit,
} from '@my-game-plan/types';
import {ChartSvgText} from '../chart-svg/ChartSvgText';
import CompetitionIcon from '../competition-icon/competition-icon.view';
import {formatDate, getOpponent} from 'helpers/general/general.helper';
import {TooltipProps} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import {formatSingleScore} from 'helpers/automation/automation-detail-history.helper';
import Stat from '../stat/stat.view';
import {IHistoricalTrendLine} from './historical-trend.view';

export function getImageUrlAndDateByMatchId<
  T extends FIAutomationPlayerMatch | FIPhysicalMatch,
>(matchId: string, history: T[], observingTeamId: string): [string, Date] {
  const _historyEntry = history.find(
    (historyEntry) => historyEntry.match._id === matchId,
  );
  if (_historyEntry) {
    const _opponentTeam = getOpponent(
      (_historyEntry as FIAutomationPlayerMatch).own_team_id ?? observingTeamId,
      _historyEntry.match,
    );
    return [_opponentTeam?.image_url || '', _historyEntry.match.date];
  }

  return ['', new Date()];
}
export function getXAxisTick<
  T extends FIAutomationPlayerMatch | FIPhysicalMatch,
>(
  tick: any,
  history: T[],
  observingTeamId?: string,
  matchesCount?: number,
  isObservingMatch?: boolean,
  competitionType?: TCompetitionType,
) {
  if (!observingTeamId) {
    return <g />;
  }

  const {value} = tick.payload;
  const _imageURLAndDate = getImageUrlAndDateByMatchId(
    value,
    history,
    observingTeamId,
  );

  const _displaySmall = matchesCount && matchesCount > 16;
  const _displayXSmall = matchesCount && matchesCount > 24;

  let _date = '';
  if (!_displaySmall) {
    _date = formatDate(_imageURLAndDate[1], 'DD/MM');
  }

  const _imageRadius = _displayXSmall ? 10 : 12;
  return (
    <g transform={`translate(${tick.x},${tick.y + 16})`}>
      <defs>
        <clipPath id="circleView">
          <circle r={_imageRadius} fill="#fff" />
        </clipPath>
      </defs>
      <image
        width={_imageRadius * 2}
        height={_imageRadius * 2}
        x={_imageRadius * -1}
        y={_imageRadius * -1}
        opacity={isObservingMatch ? 1 : 0.2}
        xlinkHref={_imageURLAndDate[0]}
        clipPath="url(#circleView)"
      />
      {Boolean(_date) && <ChartSvgText value={_date} dy={30} />}
      <g transform={'translate(4, -16)'}>
        <CompetitionIcon
          elevation={10}
          competition_type={competitionType || 'domestic_cup'}
        />
      </g>
    </g>
  );
}

export function renderPhysicalDataTooltip(
  props: TooltipProps<ValueType, NameType>,
  trendlines: IHistoricalTrendLine[],
  teamId?: string,
  unit?: string,
): JSX.Element | null {
  if (!props.active || !props.payload?.length) {
    return null;
  }

  let _opponentTeam = '';
  if (teamId && props.payload[0].payload?.match) {
    _opponentTeam = getOpponent(teamId, props.payload[0].payload.match)
      ?.name as string;
  }

  const _minutesPlayed = props.payload[0].payload?.minutes_played;

  return (
    <Stack bgcolor="background.paper" p={1} spacing={1} borderRadius={1}>
      <Stack direction="row" gap={3}>
        {_opponentTeam && <Typography>{_opponentTeam}</Typography>}
        {Boolean(_minutesPlayed) && (
          <Stack direction={'row'} gap={0.5} alignItems={'center'}>
            <AccessTimeIcon color="secondary" />
            <Typography
              color="text.secondary"
              fontWeight={500}>{`${_minutesPlayed}'`}</Typography>
          </Stack>
        )}
      </Stack>
      {props.payload.map((entry, index) => {
        if (!entry.dataKey || typeof entry.value === 'undefined') {
          return null;
        }

        const _trendline = trendlines[index];

        const _decimals = getDecimalsForUnit(
          unit as TPhysicalCategoryUnit | undefined,
        );

        const _score = formatSingleScore(
          entry.dataKey,
          (entry.name as string) || '',
          entry.value as number,
          // entry.payload?.,
          undefined,
          undefined,
          undefined,
          undefined,
          _decimals,
        );

        const _isBenchmarkState = _score?.key.includes('benchmark');
        let _color = _trendline.color;
        if (_isBenchmarkState && typeof _trendline.benchmark !== 'undefined') {
          _color = _trendline.benchmark.color;
        }

        if (!_score?.formattedValue) return null;

        return (
          <Stat
            key={entry.dataKey}
            label={_score.label}
            value={_score.formattedValue}
            valueSuffix={unit}
            legendInfo={{
              color: _color,
              shape: _isBenchmarkState ? 'line' : 'dot',
            }}
          />
        );
      })}
    </Stack>
  );
}
