import React from 'react';

import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';

interface IScoutingCellContentProps {
  data: string[];
}

function ScoutingCellContent(props: IScoutingCellContentProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Render
   */
  return (
    <Typography variant="body2" color="text.secondary">
      {t('scouting.profile.profilesCount', {
        count: props.data.length,
      })}
    </Typography>
  );
}

export default ScoutingCellContent;
