import {LOADING_STATE} from 'types/screen.types';
import {TStyleOfPlay} from '@my-game-plan/types';
import React from 'react';

import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import ListItemSkeleton from './list-item-skeleton';
import ProfileListItem from './profile-list-item';
import {useProfileSetup} from 'context/profile-setup.context';

function ProfilesList() {
  /*
   * Hooks n State
   */
  const _profileSetupContext = useProfileSetup();

  /*
   * Side effects
   */

  /*
   * Handlers
   */
  function _onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const _value = event.target.value as TStyleOfPlay;

    const _matchingProfile = _profileSetupContext.profiles.find(
      (profile) => profile.style_of_play === _value,
    );
    if (!_matchingProfile) {
      return;
    }

    _profileSetupContext.setSelectedProfile(_matchingProfile);
  }

  /*
   * Render
   */

  /* Return loading skeleton */
  if (
    _profileSetupContext.profilesLoadingState === LOADING_STATE.LOADING ||
    _profileSetupContext.profilesLoadingState === LOADING_STATE.INITING ||
    !_profileSetupContext.selectedProfile
  ) {
    return (
      <Stack>
        {[0, 1, 2, 3, 4, 5].map((index) => {
          return <ListItemSkeleton key={index} />;
        })}
      </Stack>
    );
  }

  /* Actual component */
  return (
    <RadioGroup
      value={_profileSetupContext.selectedProfile?.style_of_play}
      onChange={_onChange}>
      {_profileSetupContext.profiles.map((profile, profileIndex) => {
        return (
          <ProfileListItem
            key={profile.style_of_play}
            profile={profile}
            isSelected={
              profile.style_of_play ===
              _profileSetupContext.selectedProfile?.style_of_play
            }
            isLast={profileIndex === _profileSetupContext.profiles.length - 1}
          />
        );
      })}
    </RadioGroup>
  );
}

export default ProfilesList;
