import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
interface ISequenceActionIndexProps {
  index: number;
}

function SequenceActionIndex(props: ISequenceActionIndexProps): JSX.Element {
  return (
    <Box
      display={'flex'}
      sx={{
        '&:before': {
          position: 'absolute',
          display: 'block',
          content: '""',
          width: '1px',
          borderLeftWidth: 1,
          borderLeftStyle: 'dashed',
          borderLeftColor: 'secondary.main',
          top: 0,
          bottom: 0,
          left: 10,
        },
      }}>
      <Stack
        alignItems="center"
        justifyContent="center"
        height={20}
        width={20}
        borderRadius="50%"
        position="relative"
        zIndex={1}
        bgcolor="background.default"
        sx={{
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'secondary.main',
        }}>
        <Typography
          fontSize={12}
          fontWeight={700}
          lineHeight={1}
          color="secondary.main">
          {props.index + 1}
        </Typography>
      </Stack>
    </Box>
  );
}

export default SequenceActionIndex;
