import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import MGP_LOGO_ICON from 'assets/images/logo/logomark.svg';
import BACKGROUND_IMAGE from 'assets/images/onboarding/onboarding-hero.png';

import ProfileSetupBottomControls, {
  ISetupScreenButton,
} from './profile-setup-bottom-controls';

const LOGO_SIZE = 24;

interface ISetupScreenHeader {
  displayLogo?: boolean;
  title?: string;
  subtitle?: string;
  button?: ISetupScreenButton;
}

export interface IProfileSetupScreenProps {
  leftCentered?: boolean;
  leftHeader?: ISetupScreenHeader;
  LeftContent?: React.ReactNode;
  LeftFooter?: React.ReactNode;
  RightContent?: React.ReactNode;

  currentStepIndex?: number;
  totalSteps?: number;
  prevButton?: ISetupScreenButton;
  nextButton?: ISetupScreenButton;
}

function ProfileSetupScreen(props: IProfileSetupScreenProps): JSX.Element {
  /*
   * Render
   */

  /* Left - Header */
  let _Logo = null;
  if (props.leftHeader?.displayLogo) {
    _Logo = (
      <Box p={3} pb={0}>
        <img
          src={MGP_LOGO_ICON}
          style={{height: `${LOGO_SIZE}px`}}
          alt="MyGamePlan"
        />
      </Box>
    );
  }
  let _LeftHeader = null;
  if (props.leftHeader?.title || props.leftHeader?.subtitle) {
    let _Title = null;
    let _Description = null;
    let _Button = null;

    if (props.leftHeader.title) {
      _Title = <Typography variant="h6">{props.leftHeader.title}</Typography>;
    }

    if (props.leftHeader.subtitle) {
      _Description = (
        <Typography variant="body2" color="text.disabled">
          {props.leftHeader.subtitle}
        </Typography>
      );
    }

    if (props.leftHeader.button) {
      _Button = (
        <Button
          onClick={props.leftHeader.button.onClick}
          {...props.leftHeader.button.muiButtonProps}>
          {props.leftHeader.button.cta}
        </Button>
      );
    }

    _LeftHeader = (
      <Stack p={3} pb={0} gap={4} alignItems="flex-start">
        <Stack gap={0.5}>
          {_Title}
          {_Description}
        </Stack>
        {_Button}
      </Stack>
    );
  }

  /* Left - Content */
  let _LeftContent = null;
  if (props.LeftContent) {
    _LeftContent = (
      <Stack flex={props.leftCentered ? undefined : 1} overflow="hidden">
        <Stack
          flexGrow={1}
          pb={4}
          sx={{
            minHeight: 0,
            overflowY: 'auto',
          }}>
          {props.LeftContent}
        </Stack>
        {/* </Box> */}
      </Stack>
    );
  }

  /* Left - Footer */
  let _LeftFooter = null;
  if (props.LeftFooter) {
    _LeftFooter = (
      <Box p={3} pt={0}>
        {props.LeftFooter}
      </Box>
    );
  }

  /* Right - Content */
  let _BackgroundHero = null;

  if (!props.RightContent) {
    _BackgroundHero = (
      <Box
        position="absolute"
        left="0"
        top="0"
        // zIndex={0}
        height="100%"
        width="100%"
        sx={{
          opacity: 0.4,
          backgroundImage: `url(${BACKGROUND_IMAGE})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    );
  }

  let _RightContent = null;
  if (props.RightContent || props.totalSteps) {
    _RightContent = (
      <Box position="relative" zIndex={1} height="100%">
        <Stack height="100%">
          <Box display="flex" flexGrow={1}>
            {props.RightContent}
          </Box>
          <ProfileSetupBottomControls
            totalSteps={props.totalSteps}
            currentStepIndex={props.currentStepIndex}
            prevButton={props.prevButton}
            nextButton={props.nextButton}
          />
        </Stack>
      </Box>
    );
  }
  return (
    <Grid container height="100%">
      {/* Left */}
      <Grid
        item
        xs={12}
        md={6}
        lg={3}
        xl={4}
        height="100%"
        overflow="hidden"
        bgcolor="background.paper">
        <Stack height="100%">
          {_Logo}
          <Stack
            gap={3}
            // flexGrow={1}
            flexGrow={1} // <-- This ensures it fills the parent height
            minHeight={0} // <-- Prevents flex issues with overflow
            justifyContent={props.leftCentered ? 'center' : 'flex-start'}>
            {_LeftHeader}
            {_LeftContent}
          </Stack>
          {_LeftFooter}
        </Stack>
      </Grid>

      {/* Right */}
      <Grid item xs={12} md={6} lg={9} xl={8} position="relative">
        {_BackgroundHero}
        {_RightContent}
      </Grid>
    </Grid>
  );
}

export default ProfileSetupScreen;
