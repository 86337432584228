import React, {useEffect, useState} from 'react';
import cn from 'classnames';

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LabelIcon from '@mui/icons-material/Label';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import styles from 'components/dashboard/stylesheet/overview.module.scss';

import {
  FIEventAutomationPostData,
  FITag,
  FIDisplayPlayer,
} from '@my-game-plan/types';

import {useTeams} from 'context/team.context';
import {deepCopy} from 'helpers/general/general.helper';
import {hashtagify} from 'helpers/translation.helper';
import {useAuth} from 'context/auth.context';
import {formattedAutomationToPostData} from 'helpers/event-automations.helper';

import TrackerCreateButton from '../misc/tracker-create-button/tracker-create-button.view';
import {useTranslation} from 'react-i18next';
import Indicator from 'components/dashboard/Indicator/Indicator';
import {useAutomationDetail} from 'context/event-automations/automation-detail.context';
import {Link} from 'react-router-dom';

import HeaderFilterBar from 'components/filter/header-filter-bar/header-filter-bar.view';
import {SxProps, Theme} from '@mui/material';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TrackerSentence from '../sentence/tracker-sentence';

export const LABEL_STYLE: SxProps<Theme> = {
  whiteSpace: 'pre',
  textTransform: 'none',
  fontWeight: '300',
  lineHeight: 1,
  fontSize: 14,
};

function AutomationDetailHeader(): JSX.Element {
  // props: IAutomationDetailHeaderProps,
  /*
   * Hooks n State
   */
  const [_sentenceInfo, _setSentenceInfo] =
    useState<Partial<FIEventAutomationPostData> | null>(null);
  const [_additionalPlayerInfo, _setAdditionalPlayerInfo] = useState<
    FIDisplayPlayer | undefined
  >(undefined);

  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const _authContext = useAuth();
  const _automationDetailContext = useAutomationDetail();
  const [_tags, _setTags] = useState<FITag[]>([]);

  /* Generate tags */
  useEffect(() => {
    const _filteredTags: FITag[] = [];
    if (
      _automationDetailContext.automation &&
      'tags' in _automationDetailContext.automation &&
      _automationDetailContext.automation.tags?.length
    ) {
      _automationDetailContext.automation.tags.forEach((t) => {
        const foundTag = (
          deepCopy(_teamsContext.preferences?.tags) as FITag[]
        )?.find((tag) => tag._id == t);
        if (foundTag) {
          foundTag.label = `#${hashtagify(foundTag.label, false)}`;

          _filteredTags.push(foundTag);
        }
      });

      _setTags(_filteredTags.sort());
    }
  }, [_automationDetailContext.automation, _teamsContext.preferences]);

  /* Generate sentenceInfo for title */
  useEffect(() => {
    if (_automationDetailContext.automation && _authContext.user) {
      _setSentenceInfo(
        formattedAutomationToPostData(_automationDetailContext.automation),
      );

      if (
        _automationDetailContext.executorType === 'scouting_profile_ids' &&
        _automationDetailContext.automation.players
      ) {
        const _playersOfAutomation: FIDisplayPlayer[] =
          _automationDetailContext.automation.players.map((p) => {
            return {
              _id: p._id,
              name: p.name,
              display_name: p.display_name,
              image_url: p.image_url,
            };
          });

        _setAdditionalPlayerInfo(_playersOfAutomation[0]);
      }
    } else {
      _setSentenceInfo(null);
    }
  }, [
    _automationDetailContext.automation,
    _authContext.user,
    _automationDetailContext.executorType,
  ]);

  /*
   * Handlers
   */
  function _onObjectiveCheckboxChange() {
    _automationDetailContext.setIsOnlyShowingObjectiveEvents(
      !_automationDetailContext.isOnlyShowingObjectiveEvents,
    );
  }

  /*
   * Render
   */
  let _Title = null;
  if (_automationDetailContext.automation && _sentenceInfo) {
    _Title = (
      <TrackerSentence
        tracker={_sentenceInfo}
        currentExecutorType={_automationDetailContext.executorType}
        size="large"
        readonly
        canSelectOpponent={
          _automationDetailContext.executorType === 'opponent_analysis'
        }
        concreteExecutorInfo={_additionalPlayerInfo}
      />
    );
  }

  const _editButtonTitle = t('eventAutomations.trackersNew');

  let _ObjectiveCheckbox = undefined;
  if (_automationDetailContext.automation?.calculation !== 'occurrences') {
    _ObjectiveCheckbox = (
      <FormControlLabel
        label={
          <Typography sx={LABEL_STYLE} color="secondary">
            {t('eventAutomations.displayObjectiveOnly')}
          </Typography>
        }
        control={
          <Checkbox
            size="small"
            checked={_automationDetailContext.isOnlyShowingObjectiveEvents}
            onChange={_onObjectiveCheckboxChange}
          />
        }
      />
    );
  }

  return (
    <div className={cn(styles.header, styles.headerWithBorder)}>
      {_automationDetailContext.backButton && (
        <Button
          variant="text"
          color="secondary"
          component={Link}
          size="small"
          to={_automationDetailContext.backButton.route}
          state={{from: _automationDetailContext.backButton.from}}
          startIcon={<ArrowBackIcon />}>
          {_automationDetailContext.backButton.label}
        </Button>
      )}
      <div className={styles.topRow}>
        <div className={styles.titleContainer}>
          {_Title}
          {/* Edit button */}
          {_automationDetailContext.automation &&
            _automationDetailContext.executorType !==
              'scouting_profile_ids' && (
              <TrackerCreateButton
                data={_automationDetailContext.automation}
                type={_automationDetailContext.executorType}
                onSubmit={_automationDetailContext.onEditAutomation}
                displayPreEditDialog
                Button={
                  <IconButton title={_editButtonTitle} color="secondary">
                    <EditIcon />
                  </IconButton>
                }
              />
            )}
        </div>
        <div className={styles.topRowButtons}>
          <Stack direction="row" alignItems="center" gap={2}>
            {/* Score */}
            {typeof _automationDetailContext.benchmarkedScore !==
              'undefined' && (
              <Box sx={{width: 240, pr: 1}}>
                <Indicator
                  value={_automationDetailContext.benchmarkedScore}
                  valueDisplay="percentile"
                />
              </Box>
            )}

            {/* Tags */}
            {Boolean(_tags.length) && (
              <Tooltip title={_tags?.map((tag) => tag.label).join(' ')}>
                <Stack direction="row" alignItems="center" gap={0.5} pr={0.5}>
                  <LabelIcon color="secondary" />
                  <Typography fontSize={14} color="secondary">
                    {_tags.length}
                  </Typography>
                </Stack>
              </Tooltip>
            )}
          </Stack>
        </div>
      </div>
      {_automationDetailContext.automation?.players &&
        Boolean(_automationDetailContext.automation?.players?.length) && (
          <div className={styles.bottomRow}>
            <HeaderFilterBar
              timeFilters={{
                filters:
                  _automationDetailContext.benchmarkAndDateFilters?.own_data
                    ?.time || undefined,
                onFiltersChange:
                  _automationDetailContext.onOwnDataFiltersChange,
                observingMatchIds: _automationDetailContext.observingMatchIds,
                onObservingMatchIdsChange:
                  _automationDetailContext.onObservingMatchIdChange,
                includedMatches: _automationDetailContext.allMatches,
                canCheckMatches: true,
              }}
              benchmark={{
                benchmarkOptions:
                  _automationDetailContext.benchmarkAndDateFilters?.benchmark,
                onBenchmark: _automationDetailContext.getBenchmarkInfo,
              }}
              trackerInfo={{
                isObservingPlayer:
                  _automationDetailContext.isSinglePlayerTracker,
                isRatioOrRule:
                  _automationDetailContext.automation?.calculation ===
                    'ratio' ||
                  _automationDetailContext.automation?.calculation === 'rule',
              }}
              ExtraComponent={_ObjectiveCheckbox}
              screen={`automation-deep-dive-${_automationDetailContext.executorType}`}
            />
          </div>
        )}
    </div>
  );
}

export default AutomationDetailHeader;
