import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {useTeams} from 'context/team.context';
import Logo from '../common/Logo/Logo';

interface IProfileTeamInfoProps {
  subtitle: string;
  RightContent?: React.ReactNode;
}

function ProfileTeamInfo(props: IProfileTeamInfoProps): JSX.Element | null {
  /*
   * Hooks n State
   */
  const _teamsContext = useTeams();

  /*
   * Side effects
   */

  /*
   * Render
   */
  if (!_teamsContext.ownTeam) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" p={3}>
      {/* Left: team logo + screen info */}
      <Stack flexGrow={1} direction="row" alignItems="center" gap={1}>
        <Logo src={_teamsContext.ownTeam.image_url} size="large" />
        <Stack gap={0.5}>
          <Typography lineHeight={1}>{_teamsContext.ownTeam.name}</Typography>
          <Typography
            lineHeight={1}
            variant="caption"
            color="text.secondary"
            letterSpacing={0.3}>
            {props.subtitle}
          </Typography>
        </Stack>
      </Stack>

      {/* Optional right content */}
      {props.RightContent && <Stack>{props.RightContent}</Stack>}
    </Stack>
  );
}

export default ProfileTeamInfo;
