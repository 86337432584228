import React from 'react';

import styles from './stylesheet/players-list.module.scss';
import {createKey} from 'helpers/general/general.helper';
import {translateValue} from 'helpers/translation.helper';
import Tabs from 'components/material-customised/tabs/tabs.view';
import Tab from '@mui/material/Tab';

interface IPlayersStatsTabsProps {
  playerStatsTabs: string[];
  setSelectedTabIndex: (arg0: number) => void;
  selectedTabIndex: number;
}

const PlayersStatsTabs = ({
  playerStatsTabs,
  setSelectedTabIndex,
  selectedTabIndex,
}: IPlayersStatsTabsProps): JSX.Element | null => {
  if (playerStatsTabs.length < 1) return null;

  const _handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    setSelectedTabIndex(newValue);
  };

  return (
    <Tabs
      value={selectedTabIndex}
      onChange={_handleTabChange}
      aria-label="tabs">
      {playerStatsTabs.map((tab) => {
        const _label = (
          <div className={styles.tabLabel}>{translateValue(tab)}</div>
        );
        return <Tab key={createKey(tab)} label={_label}></Tab>;
      })}
    </Tabs>
  );
};

export default PlayersStatsTabs;
