import React, {useEffect, useState} from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {FIPlayerWithData} from '@my-game-plan/types';
import {getPlayerDeepDivePath} from 'helpers/navigation.helpers';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import ScoutPlayerButton from 'components/scouting/scout-player-button.view';

interface IPlayerActionsCellProps {
  player: FIPlayerWithData;
  scoutingProfileId?: string;
}

function PlayerActionsCell(props: IPlayerActionsCellProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_link, _setLink] = useState<string>('');

  /*
   * Side effects
   */
  useEffect(() => {
    const _newLink = getPlayerDeepDivePath(
      props.player._id,
      true,
      props.scoutingProfileId,
    );
    _setLink(_newLink);
  }, [props.player, props.scoutingProfileId]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <ScoutPlayerButton
        player={props.player}
        scouting={props.player.scouting}
        layout="icon"
      />
      <Button variant="text" color="secondary" component={Link} to={_link}>
        {t('players.cta.viewPlayer')}
      </Button>
    </Stack>
  );
}

export default PlayerActionsCell;
