import {t} from 'i18next';
import React, {useEffect, useState} from 'react';
import {IPitchVisual} from 'types/dashboard/dashboard-pitch-visual.types';

import {Card} from '../../../common/card/Card';
import {CenteredSpinner} from '../../../common/centered-spinner/CenteredSpinner';
import PitchVisual from '../../../dashboard/pitch-visual/PitchVisual';

import {
  ACTION,
  FICompactMatchInfo,
  FIConditionalEventFilters,
  FIEventFilters,
  FIMatch,
  FIMatchEvent,
  FIPitchZone,
  TEventCalculationType,
  eventsArrayToPitchVisualGroups,
  getPitchVisualForAction,
} from '@my-game-plan/types';

interface TrackerDetailPitchVisualProps {
  id: string;
  action: ACTION;
  details?: FIConditionalEventFilters;
  metric?: keyof FIEventFilters;
  observingMatchIDs: string[];
  zones?: FIPitchZone[];
  events: FIMatchEvent[];
  loading: boolean;
  observingTeamImage?: string;
  against?: boolean;
  isOnlyShowingObjectiveEvents?: boolean;
  automationCalculation?: TEventCalculationType;
  matches: FIMatch[] | FICompactMatchInfo[];
  isShowingSequences?: boolean;
}

export const TrackerDetailPitchVisual = ({
  action,
  details,
  metric,
  zones,
  events,
  loading,
  observingTeamImage,
  against,
  automationCalculation,
  matches,
  isShowingSequences,
}: TrackerDetailPitchVisualProps) => {
  const [_pitchVisualData, _setPitchVisualData] = useState<
    IPitchVisual | undefined
  >(undefined);

  useEffect(() => {
    const _groups = eventsArrayToPitchVisualGroups(action, events);

    const _pitchVisualConfig = getPitchVisualForAction(action);

    _setPitchVisualData({
      ..._pitchVisualConfig,
      linkedBlock: 'trackers',
      tabs: [
        {
          linkedTable: 'trackers',
          groups: _groups,
        },
      ],
    });
  }, [events]);

  return (
    <Card>
      {loading && <CenteredSpinner />}
      {((!loading && !_pitchVisualData) || !action) && (
        <p>{t('eventAutomations.noData')}</p>
      )}
      {!loading && _pitchVisualData && action && (
        <PitchVisual
          activeTab={0}
          details={details}
          data={_pitchVisualData}
          action={action}
          metric={metric}
          zones={zones}
          teamLogo={observingTeamImage}
          against={against}
          calculation={automationCalculation}
          matches={matches}
          isShowingSequencs={isShowingSequences}
        />
      )}
    </Card>
  );
};
