import {
  FIPlayerObjective,
  FIPlayerObjectiveComment,
  FIPlayerObjectivePostData,
  PLAYER_OBJECTIVE_CATEGORIES,
} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import {useTranslation} from 'react-i18next';
import {useFormik} from 'formik';
import {LOADING_STATE} from 'types/screen.types';
import * as yup from 'yup';
import {useSnackbar} from 'notistack';
import {
  createPlayerObjective,
  deletePlayerObjective,
  editPlayerObjective,
} from 'controllers/player-objectives.controller';
import {useAuth} from 'context/auth.context';

import {useConfirm} from 'material-ui-confirm';
import {DELETE_MODAL_CONFIG} from 'config/modal.config';
import PlayerObjectiveCommentCreateButton from '../comments/player-objective-comment-create-button.view';
import PlayerObjectiveCommentCard from '../comments/player-objective-comment-card.view';
import {useAnalytics} from 'context/analytics.context';
import ANALYTICS_EVENT from 'config/analytics/event-names.config';
import {useExecutorDeepDive} from 'context/executor-deep-dive.context';

interface IPlayerObjectivesFormProps {
  data?: FIPlayerObjective;
  isOpen: boolean;
  onClose: () => void;
  playerId: string;
}

function PlayerObjectivesForm(props: IPlayerObjectivesFormProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _snackbar = useSnackbar();
  const _confirm = useConfirm();
  const _authContext = useAuth();
  const _executorDeepDiveContext = useExecutorDeepDive();
  const _analyticsContext = useAnalytics();

  const _validationSchema = yup.object<FIPlayerObjectivePostData>({
    title: yup.string().required(),
    category: yup.string().required(),
    description: yup.string(),
  });
  const _formik = useFormik<Partial<FIPlayerObjectivePostData>>({
    onSubmit: _onSubmit,
    initialValues: {
      player_id: props.playerId,
      title: props.data?.title || '',
      category: props.data?.category || undefined,
      description: props.data?.description || '',
      created_by: _authContext.user?._id || undefined,
      team_id: _authContext.user?.team || undefined,
    },
    enableReinitialize: true,
    validationSchema: _validationSchema,
  });

  const [_loadingState, _setLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );
  const [_comments, _setComments] = useState<FIPlayerObjectiveComment[]>([]);

  /*
   * Side effects
   */
  // Flip comments order
  useEffect(() => {
    if (props.data) {
      _setComments(props.data.comments.reverse());
      _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_PLAYER_OBJECTIVE, {
        player_id: props.data.player_id,
        objective_id: props.data._id,
        category: props.data.category,
      });
    } else {
      _setComments([]);
    }
  }, [props.data]);

  /*
   * Handlers
   */

  async function _onSubmit() {
    try {
      _setLoadingState(LOADING_STATE.LOADING);
      let _successMessage = 'playerObjectives.success.create';
      if (props.data) {
        _successMessage = 'playerObjectives.success.edit';
        await editPlayerObjective(props.data._id, _formik.values);
      } else {
        const _createdObjective = await createPlayerObjective(_formik.values);
        _analyticsContext.trackEvent(ANALYTICS_EVENT.CREATED_PLAYER_OBJECTIVE, {
          player_id: _createdObjective.player_id,
          objective_id: _createdObjective._id,
          category: _createdObjective.category,
        });
      }

      await _executorDeepDiveContext.fetchObjectives();
      _snackbar.enqueueSnackbar(t(_successMessage), {variant: 'success'});

      _onClose(false);
    } catch (error) {
      _setLoadingState(LOADING_STATE.INITING);

      const _error = props.data
        ? t('playerObjectives.error.update')
        : t('playerObjectives.error.create');
      _snackbar.enqueueSnackbar(_error, {variant: 'error'});
    }
  }

  function _onDiscard() {
    _onClose();
  }

  async function _onClose(shouldConfirm = true) {
    if (!shouldConfirm) {
      _formik.resetForm();
      _setLoadingState(LOADING_STATE.INITING);
      props.onClose();
      return;
    }

    try {
      if (_formik.dirty) {
        await _confirm({
          title: t('playerObjectives.form.closeWarning.title'),
          confirmationText: t('playerObjectives.form.closeWarning.text'),
          description: t('playerObjectives.form.closeWarning.description'),
        });
      }
      _formik.resetForm();
      props.onClose();
    } catch (error) {
      // user closed modal
    }
  }

  async function _onDelete() {
    if (!props.data) {
      return;
    }

    try {
      await _confirm({
        ...DELETE_MODAL_CONFIG,
        title: t('playerObjectives.form.deleteWarning.title'),
        confirmationText: t('playerObjectives.form.deleteWarning.text'),
        description: t('playerObjectives.form.deleteWarning.description'),
      });
    } catch (error) {
      // user closed modal
    }

    try {
      await deletePlayerObjective(props.data._id);
      _snackbar.enqueueSnackbar(t('playerObjectives.success.delete'), {
        variant: 'success',
      });
      await _executorDeepDiveContext.fetchObjectives();
      _onClose(false);
    } catch (error) {
      _snackbar.enqueueSnackbar(t('playerObjectives.error.delete'), {
        variant: 'error',
      });
    }
  }

  /*
   * Render
   */

  const _title = props.data
    ? t('playerObjectives.edit')
    : t('playerObjectives.new');

  const _submitButtonText = props.data
    ? t('playerObjectives.form.save')
    : t('playerObjectives.form.create');
  return (
    <Dialog
      open={props.isOpen}
      onClose={_onDiscard}
      fullWidth
      maxWidth="lg"
      scroll="paper"
      PaperProps={{component: 'form', onSubmit: _formik.handleSubmit}}>
      <DialogTitle>{_title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={8}>
          <Grid item xs={5}>
            <Stack gap={3}>
              <Typography variant="caption" color="text.secondary" py={1}>
                {t('playerObjectives.form.info')}
              </Typography>

              {/* Title */}
              <TextField
                fullWidth
                label={t('playerObjectives.fields.title')}
                placeholder={t('playerObjectives.fields.title')}
                name="title"
                onChange={_formik.handleChange}
                value={_formik.values.title}
                onBlur={_formik.handleBlur}
                error={_formik.touched.title && Boolean(_formik.errors.title)}
              />

              {/* Category */}
              <FormControl fullWidth>
                <InputLabel id="category-label">
                  {t('playerObjectives.fields.category')}
                </InputLabel>
                <Select
                  labelId="category-label"
                  label={t('playerObjectives.fields.category')}
                  name="category"
                  onChange={_formik.handleChange}
                  value={_formik.values.category || ''}
                  onBlur={_formik.handleBlur}
                  inputProps={{
                    MenuProps: {
                      MenuListProps: {
                        sx: {bgcolor: 'background.default'},
                      },
                    },
                  }}
                  error={
                    _formik.touched.category && Boolean(_formik.errors.category)
                  }>
                  {PLAYER_OBJECTIVE_CATEGORIES.map((category) => {
                    return (
                      <MenuItem key={category} value={category}>
                        {t(`playerObjectives.category.${category}`)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              {/* Description */}
              <TextField
                multiline
                fullWidth
                minRows={4}
                label={t('playerObjectives.fields.description')}
                placeholder={t('playerObjectives.fields.description')}
                name="description"
                onChange={_formik.handleChange}
                value={_formik.values.description}
                onBlur={_formik.handleBlur}
                error={
                  _formik.touched.description &&
                  Boolean(_formik.errors.description)
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={7}>
            {/* Comments */}
            <Stack gap={3}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Typography variant="caption" color="text.secondary" py={1}>
                  {t('playerObjectives.form.comments')}
                </Typography>
                {/* "Add comment" button */}
                {props.data && (
                  <div>
                    <PlayerObjectiveCommentCreateButton
                      objectiveId={props.data._id}
                      Button={
                        <Button
                          color="primary"
                          startIcon={<AddIcon />}
                          size="small">
                          {t('playerObjectives.comments.add')}
                        </Button>
                      }
                    />
                  </div>
                )}
              </Stack>

              {/* Comments list */}
              {props.data && _comments.length > 0 ? (
                <Stack gap={1}>
                  {props.data.comments.map((comment) => {
                    return (
                      <PlayerObjectiveCommentCard
                        comment={comment}
                        key={comment._id}
                        objectiveId={props.data?._id || ''}
                      />
                    );
                  })}
                </Stack>
              ) : (
                <Typography variant="body2" color="text.disabled">
                  {t('playerObjectives.comments.noComments')}
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box style={{flex: 1}}>
          {Boolean(props.data) && (
            <IconButton
              color="secondary"
              title={t('playerObjectives.delete')}
              onClick={_onDelete}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
        <Button onClick={_onDiscard} color="secondary">
          {t('general.cancel')}
        </Button>
        <LoadingButton
          disabled={!_formik.isValid || !_formik.dirty}
          type="submit"
          variant="contained"
          loading={_loadingState === LOADING_STATE.LOADING}>
          {_submitButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default PlayerObjectivesForm;
