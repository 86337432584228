import {IDetailsSegmentProps} from 'types/trackers/tracker-sentence.types';
import React, {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AddDetailButton from './add-detail-button';
import {useTranslation} from 'react-i18next';
import {
  FIConditionalEventFilter,
  FIConditionalEventFilters,
  FIEventFilters,
  generateActionConfigs,
  IActionConfigDetails,
  IEventFilters,
  TQueryOperatorType,
} from '@my-game-plan/types';
import {useTeams} from 'context/team.context';
import {useCompetitions} from 'context/competitions.context';
import {useZones} from 'context/zones.context';
import {generateGeneralMatchDetails} from 'helpers/automation/automation-form.helper';
import DetailsValuesSelectRow from './details-values-select-row';
import TrackerSentenceSelect from '../../variants/select/sentence-select-segment';
import {OPERATOR_OPTIONS} from 'config/event-automation-builder.config';
import SentenceTextSegment from '../../variants/text/sentence-text-segment';
import {translateAction} from 'helpers/translation.helper';

interface IDetailsPopupContentProps extends IDetailsSegmentProps {
  onNestedPopupToggle: (hasOpenPopup: boolean) => void;
  against?: boolean;
}
function DetailsPopupContent(
  props: IDetailsPopupContentProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_possibleDetails, _setPossibleDetails] =
    useState<IActionConfigDetails>({});
  const _teamsContext = useTeams();
  const _competitionsContext = useCompetitions();
  const _zonesContext = useZones();
  const [_detailsCount, _setDetailsCount] = useState(0);
  const [_title, _setTitle] = useState<string>('');

  /*
   * Side effects
   */

  // Define possible details
  useEffect(() => {
    if (!props.action) {
      return;
    }
    const _actionConfigs = generateActionConfigs(
      _competitionsContext.domesticCompetition?.event_provider,
      _competitionsContext.domesticCompetition?.available_metrics,
    );

    const _actionConfig = _actionConfigs[props.action];

    if (
      _actionConfig &&
      !props.isGameStateCondition &&
      _actionConfig.possibleDetails
    ) {
      // Manually override details for coordinates and received by
      Object.keys(_actionConfig.possibleDetails).forEach((key) => {
        const _key = key as keyof IEventFilters;
        if (_actionConfig.possibleDetails && _key.includes('coordinates')) {
          _actionConfig.possibleDetails[_key] = _zonesContext.all.map(
            (z) => z.name,
          );
        }
      });
      _setPossibleDetails(_actionConfig.possibleDetails);
    } else if (props.isGameStateCondition && _teamsContext.ownTeamFormations) {
      const _generatedFilters = generateGeneralMatchDetails(
        _teamsContext.ownTeamFormations,
      );
      _setPossibleDetails(_generatedFilters);
    }
  }, [
    _competitionsContext.domesticCompetition?.event_provider,
    _competitionsContext.domesticCompetition?.available_metrics,
    _teamsContext.ownTeamFormations,
    _zonesContext.all,
  ]);

  // Define details count
  useEffect(() => {
    _setDetailsCount(props.value?.filters.length || 0);
  }, [props.value]);

  // Define title
  useEffect(() => {
    if (!props.action || props.isGameStateCondition) {
      _setTitle('');
      return;
    }

    const _titleActionLabel = translateAction(props.action);
    const _fullTitle = t('sentenceForm.details.title', {
      action: _titleActionLabel,
    });
    _setTitle(_fullTitle);
  }, [props.action, props.isGameStateCondition]);

  /*
   * Handlers
   */
  function _updateFilters(filtersToSave: FIConditionalEventFilters) {
    if (props.onChange) {
      props.onChange(filtersToSave);
    }
  }
  function _onAddFilterClick(field: keyof FIEventFilters) {
    const _emptyFilter: FIConditionalEventFilter = {
      property: field,
      values: [],
      operator: 'or',
    };

    const _newFilters: FIConditionalEventFilters = {
      operator: props.value?.operator || 'and',
      filters: [...(props.value ? props.value.filters : []), _emptyFilter],
    };

    _updateFilters(_newFilters);
  }

  function _onDeleteFilter(field: keyof FIEventFilters) {
    if (props.value) {
      const _index = props.value.filters.findIndex(
        (filter) => filter.property === field,
      );

      if (_index > -1) {
        const _newFilters: FIConditionalEventFilters = {
          ...props.value,
        };
        _newFilters.filters.splice(_index, 1);

        _updateFilters(_newFilters);
      }
    }
  }

  function _onFilterChange(filter: FIConditionalEventFilter) {
    if (props.value) {
      const _filterIndex = props.value.filters.findIndex(
        (propFilter) => propFilter.property === filter.property,
      );
      if (_filterIndex > -1) {
        const _newFilters: FIConditionalEventFilters = {
          ...props.value,
        };
        _newFilters.filters.splice(_filterIndex, 1, filter);

        _updateFilters(_newFilters);
      }
    }
  }

  function _onOperatorChange(value: TQueryOperatorType) {
    if (props.value) {
      const _newFilters: FIConditionalEventFilters = {
        ...props.value,
        operator: value,
      };

      _updateFilters(_newFilters);
    }
  }

  /*
   * Render
   */

  if (!props.action) {
    return null;
  }

  let _DetailsList = null;
  const _operatorLabel = t(`sentenceForm.${props.value?.operator}`);
  if (props.value?.filters && _detailsCount) {
    _DetailsList = (
      <Stack gap={1.5} width="100%">
        {props.value.filters.map((filter, index) => {
          let _OperatorContent = null;
          if (_detailsCount > 1 && index === 0 && !props.readonly) {
            _OperatorContent = (
              <Box alignItems="flex-start">
                <TrackerSentenceSelect
                  options={OPERATOR_OPTIONS}
                  onChange={_onOperatorChange}
                  size="small"
                  value={props.value?.operator || 'or'}
                  currentExecutorType={props.currentExecutorType}
                />
              </Box>
            );
          } else if (_detailsCount && index < _detailsCount - 1) {
            _OperatorContent = (
              <Box alignItems="flex-start">
                <SentenceTextSegment
                  value={_operatorLabel}
                  size="small"
                  currentExecutorType={props.currentExecutorType}
                />
              </Box>
            );
          }
          return (
            <React.Fragment key={index}>
              <DetailsValuesSelectRow
                key={index}
                action={props.action}
                filter={filter}
                possibleDetails={_possibleDetails}
                onChange={_onFilterChange}
                onDelete={_onDeleteFilter}
                onPopupToggle={props.onNestedPopupToggle}
                isGameStateCondition={props.isGameStateCondition}
                readonly={props.readonly}
                against={props.against}
              />
              {_OperatorContent}
            </React.Fragment>
          );
        })}
      </Stack>
    );
  }

  return (
    <Stack
      p={2}
      gap={3}
      alignItems="flex-start"
      minWidth={props.readonly ? undefined : 480}>
      {_title && (
        <Typography variant="caption" color="text.disabled">
          {_title}
        </Typography>
      )}
      {_DetailsList}
      {!props.readonly && (
        <AddDetailButton
          onChange={_onAddFilterClick}
          action={props.action}
          possibleDetails={_possibleDetails}
          selectedFilters={props.value?.filters}
          onPopupToggle={props.onNestedPopupToggle}
          isGameStateCondition={props.isGameStateCondition}
        />
      )}
    </Stack>
  );
}

export default DetailsPopupContent;
