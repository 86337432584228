import {useAPI} from 'hooks/api.hooks';
import {Resource} from 'types/api.types';
import {TEAMS_API_CONFIG} from 'config/api.config';
import {
  FIAutomationDataQueryParams,
  FIPlayerOverview,
  FITeam,
  FITeamFormatted,
  ITeamFormations,
} from '@my-game-plan/types';
import {DEFAULT_FORMATIONS} from 'types/team.types';
import {REQUEST_ERRORS} from 'config/errors.config';

const api = useAPI();

export const getTeams = async (
  userID: string | undefined,
): Promise<FITeamFormatted[]> => {
  try {
    const {data} = await api.get<FITeam[]>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.getTeams,
      params: {
        user_id: userID,
      },
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch {
    return new Promise<FITeamFormatted[]>((res) => res([]));
  }
};

export const getTeam = async (teamID: string): Promise<FITeam> => {
  try {
    const {data} = await api.get<FITeam>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.getTeam(teamID),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    // return new Promise<FITeamFormatted>((res) => res([]));
    throw new Error((error as any).message);
  }
};

export const getTeamFormations = async (
  teamID: string | undefined,
): Promise<ITeamFormations> => {
  try {
    const {data} = await api.get<ITeamFormations>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.getTeamFormations(teamID),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch {
    return new Promise<ITeamFormations>((res) => res(DEFAULT_FORMATIONS));
  }
};

interface ISearchTeamQuery {
  q?: string;
  team_id?: string[];
}
export async function searchTeams(query: ISearchTeamQuery): Promise<FITeam[]> {
  try {
    const {data} = await api.get<FITeam[]>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.search(),
      params: query,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

// Get team overview
export async function getTeamOverview(
  filters?: FIAutomationDataQueryParams,
): Promise<FIPlayerOverview> {
  try {
    const URL = 'teams/overview';
    const {data} = await api.get<FIPlayerOverview>({
      url: URL,
      params: filters,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}
