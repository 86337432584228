import React, {useEffect, useState} from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {ITypedOption} from 'types/option.types';
import {
  COUNT_UNITS,
  DEFAULT_COUNT_UNIT,
  DEFAULT_PHYSICAL_DISTANCE_UNIT,
  DEFAULT_PHYSICAL_SPEED_UNIT,
  FITeamPreferences,
  PHYSICAL_DISTANCE_UNITS,
  PHYSICAL_SPEED_UNITS,
  TCountUnit,
  TPhysicalDistanceUnit,
  TPhysicalSpeedUnit,
} from '@my-game-plan/types';
import {useTeams} from 'context/team.context';
import DataDisplayOption from './data-display-option.view';
import {LOADING_STATE} from 'types/screen.types';
import {useSnackbar} from 'notistack';
import {useAnalytics} from 'context/analytics.context';
import ANALYTICS_EVENT from 'config/analytics/event-names.config';

function DataDisplayPreferences(): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const _snackbar = useSnackbar();
  const _analyticsContext = useAnalytics();

  const [_speedOptions, _setSpeedOptions] = useState<
    ITypedOption<TPhysicalSpeedUnit>[]
  >([]);
  const [_distanceOptions, _setDistanceOptions] = useState<
    ITypedOption<TPhysicalDistanceUnit>[]
  >([]);
  const [_countOptions, _setCountOptions] = useState<
    ITypedOption<TCountUnit>[]
  >([]);
  const [_preferences, _setPreferences] = useState<FITeamPreferences | null>();

  const [_isFormDirty, _setIsFormDirty] = useState<boolean>(false);
  const [_submitLoadingState, _setSubmitLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.SUCCESS,
  );

  /*
   * Side effects
   */
  useEffect(() => {
    _analyticsContext.trackEvent(
      ANALYTICS_EVENT.VIEWED_PREFERENCES_DATA_DISLAY,
    );
  }, []);
  // Define available options
  useEffect(() => {
    const _generatedSpeedOptions: ITypedOption<TPhysicalSpeedUnit>[] =
      PHYSICAL_SPEED_UNITS.map((unit) => {
        return {
          value: unit,
          label: t(`physical.unitsLong.${unit}`),
        };
      });
    _setSpeedOptions(_generatedSpeedOptions);

    const _generatedDistanceOptions: ITypedOption<TPhysicalDistanceUnit>[] =
      PHYSICAL_DISTANCE_UNITS.map((unit) => {
        return {
          value: unit,
          label: t(`physical.unitsLong.${unit}`),
        };
      });
    _setDistanceOptions(_generatedDistanceOptions);

    const _generatedCountOptions: ITypedOption<TCountUnit>[] = COUNT_UNITS.map(
      (unit) => {
        return {
          value: unit,
          label: t(`eventData.unitsLong.${unit}`),
        };
      },
    );
    _setCountOptions(_generatedCountOptions);
  }, []);

  // Set preferences
  useEffect(() => {
    _setPreferences(_teamsContext.preferences);
  }, [_teamsContext.preferences]);

  /*
   * Handlers
   */
  function _onSpeedUnitChange(value: TPhysicalSpeedUnit) {
    if (!_preferences?.units) {
      return;
    }
    _setIsFormDirty(true);

    _setPreferences({
      ..._preferences,
      units: {
        ..._preferences.units,
        physical_speed_unit: value,
      },
    });
  }

  function _onDistanceUnitChange(value: TPhysicalDistanceUnit) {
    if (!_preferences?.units) {
      return;
    }
    _setIsFormDirty(true);

    _setPreferences({
      ..._preferences,
      units: {
        ..._preferences.units,
        physical_distance_unit: value,
      },
    });
  }

  function _onCountUnitChange(value: TCountUnit) {
    if (!_preferences?.units) {
      return;
    }
    _setIsFormDirty(true);

    _setPreferences({
      ..._preferences,
      units: {
        ..._preferences.units,
        count_unit: value,
      },
    });
  }

  async function _onSubmit() {
    if (!_preferences) {
      return;
    }
    try {
      _setSubmitLoadingState(LOADING_STATE.LOADING);
      await _teamsContext.updatePreferences(_preferences);
      _snackbar.enqueueSnackbar(t('preferences.general.success'), {
        variant: 'success',
      });
      _setSubmitLoadingState(LOADING_STATE.SUCCESS);
    } catch (error) {
      _snackbar.enqueueSnackbar(t('preferences.general.error'), {
        variant: 'error',
      });
      _setSubmitLoadingState(LOADING_STATE.SUCCESS);
    }
  }

  /*
   * Render
   */
  return (
    <Stack spacing={6}>
      <Stack
        direction="row"
        gap={4}
        alignItems="center"
        justifyContent="space-between">
        <Typography color="text.secondary" maxWidth={800}>
          {t('preferences.dataDisplay.intro')}
        </Typography>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={!_isFormDirty}
          onClick={_onSubmit}
          loading={_submitLoadingState === LOADING_STATE.LOADING}>
          {t('preferences.general.save')}
        </LoadingButton>
      </Stack>
      <Card>
        <CardHeader title={t('preferences.dataDisplay.units.title')} />
        <CardContent>
          <Stack spacing={4}>
            <DataDisplayOption
              value={
                _preferences?.units?.physical_distance_unit ||
                DEFAULT_PHYSICAL_DISTANCE_UNIT
              }
              title={t('preferences.dataDisplay.units.distance.title')}
              description={t('preferences.dataDisplay.units.distance.text')}
              options={_distanceOptions}
              onChange={_onDistanceUnitChange}
            />
            <Divider />
            <DataDisplayOption
              value={
                _preferences?.units?.physical_speed_unit ||
                DEFAULT_PHYSICAL_SPEED_UNIT
              }
              title={t('preferences.dataDisplay.units.speed.title')}
              description={t('preferences.dataDisplay.units.speed.text')}
              options={_speedOptions}
              onChange={_onSpeedUnitChange}
            />
            <Divider />
            <DataDisplayOption
              value={_preferences?.units?.count_unit || DEFAULT_COUNT_UNIT}
              title={t('preferences.dataDisplay.units.count.title')}
              description={t('preferences.dataDisplay.units.count.text')}
              options={_countOptions}
              onChange={_onCountUnitChange}
            />
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

export default DataDisplayPreferences;
