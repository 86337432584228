import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import React, {Fragment, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './OverviewDashboardModal.module.scss';
import {PlaceholderWidgets} from './placeholder-widgets/PlaceholderWidgets';
import {WidgetSelector} from './widget-selector/WidgetSelector';
import {useOverviewDashboards} from 'context/dashboard/overview-dashboards.context';
import {ModalDeleteButton} from 'components/common/modal/modal-delete-button/modal-delete-button.view';

export const CreateOverviewDashboardModal = () => {
  const {t} = useTranslation();
  const {
    isModalOpen,
    setIsModalOpen,
    isEditing,
    current,
    deleteOverviewDashboard,
    setSelectedPlaceholderWidgets,
    submitOverviewDashboard,
  } = useOverviewDashboards();

  const [_title, _setTitle] = React.useState('');
  const [_error, _setError] = React.useState(false);

  useEffect(() => {
    if (isEditing && current) {
      _setTitle(current.title);
      setSelectedPlaceholderWidgets(current.widgets);
    } else {
      _setTitle('');
      setSelectedPlaceholderWidgets([]);
    }
  }, [isEditing, isModalOpen]);

  const closeModal = () => {
    _setError(false);
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    const titleTrimmed = _title.trim();

    if (titleTrimmed.length <= 0) {
      _setError(true);
      return;
    }

    submitOverviewDashboard(titleTrimmed);
    closeModal();
  };

  const _onDelete = async () => {
    await deleteOverviewDashboard();
    closeModal();
  };

  return (
    <Dialog open={isModalOpen} onClose={closeModal} fullWidth maxWidth="xl">
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {t(`overview-dashboards.${isEditing ? 'edit' : 'add'}.title`)}
        <IconButton
          onClick={closeModal}
          title={t('general.discard')}
          sx={{alignSelf: 'flex-end'}}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <>
          {_error && (
            <Typography color="error">
              {t('overview-dashboards.add.error')}
            </Typography>
          )}
        </>
        <div className={styles.container} id="drawer-container">
          <div className={styles.content}>
            <TextField
              id="outlined"
              value={_title}
              placeholder={t('overview-dashboards.add.title-placeholder')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                _setTitle(e.target.value)
              }
              fullWidth
              sx={{mb: 2}}
            />
            <PlaceholderWidgets />
          </div>
          <WidgetSelector />
        </div>
      </DialogContent>
      <DialogActions>
        <ModalDeleteButton
          title={t('overview-dashboards.delete.title')}
          description={t('overview-dashboards.delete.body')}
          onConfirm={_onDelete}
        />
        <Button variant="contained" onClick={handleSubmit}>
          {t(`general.${isEditing ? 'save' : 'create'}`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
