import React, {useCallback, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import {IDashboardScore} from 'types/dashboard/dashboard-score.types';
import Logo from '../../common/Logo/Logo';
import {IndicatorModal} from './indicator-modal/IndicatorModal';
import {IndicatorPopup} from './indicator-popup/IndicatorPopup';
import {normalize, roundAndFormatNumber} from 'helpers/general/general.helper';

import {useTeams} from 'context/team.context';
import styles from './Indicator.module.scss';
import {isOpponentAction} from 'helpers/action.helper';
import {SxProps, Theme, alpha} from '@mui/material';
import {
  getColorForScore,
  getPercentileGroupForScore,
  getPercentileTextForScore,
} from 'helpers/benchmark.helper';

type TValueDisplay = 'percentile' | 'score' | 'none';

type IndicatorPropsT = {
  value?: number;
  min?: number;
  max?: number;
  teamId?: string;
  scores?: IDashboardScore[];
  action?: string;
  matchLimit?: number;
  showInfo?: boolean;
  shouldFlipSubscore?: boolean;
  coloredValue?: boolean;
  bigValue?: boolean;
  valueDisplay: TValueDisplay;
  valueSuffix?: string;
  width?: number;
};

const Indicator = ({
  value,
  min = 0,
  max = 10,
  teamId,
  scores = [],
  action,
  matchLimit,
  showInfo,
  shouldFlipSubscore,
  coloredValue,
  bigValue,
  valueDisplay,
  width,
  valueSuffix,
}: IndicatorPropsT) => {
  /*
   * Hooks n State
   */
  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const _teamsContext = useTeams();

  const [_formattedValue, _setFormattedValue] = useState<
    string | number | undefined
  >(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [_imageURL, _setImageURL] = useState<string>('');
  const [_leftOffset, _setLeftOffset] = useState<number>(0);

  useEffect(() => {
    const _team = _teamsContext.all.find((team) => team._id === teamId);
    if (_team) {
      _setImageURL(_team.image_url);
    }
  }, [teamId, _teamsContext.all]);

  useEffect(() => {
    if (typeof value === 'undefined') {
      _setLeftOffset(0);
      return;
    }
    const _isOpponentAction = action && isOpponentAction(action);
    const _shouldFlip = _isOpponentAction && shouldFlipSubscore;

    let _min = min;
    let _max = max;

    if (_shouldFlip) {
      _min = max;
      _max = min;
    }
    const _left = Math.max(0, normalize(value, _min, _max) * 100 - 2.5);
    _setLeftOffset(_left);
  }, [value, min, max, action, shouldFlipSubscore]);

  useEffect(() => {
    if (typeof value === 'undefined' || valueDisplay === 'none') {
      _setFormattedValue(undefined);
      return;
    }
    let _newFormattedValue: string | undefined = roundAndFormatNumber(value, 2);

    if (valueDisplay === 'percentile') {
      //
      const _percentileGroup = getPercentileGroupForScore(value);
      let _percentileText = '';
      if (_percentileGroup.group !== 'average') {
        _percentileText = getPercentileTextForScore(value);
      }
      _newFormattedValue = _percentileText || '';
    }

    if (_newFormattedValue && valueSuffix) {
      _newFormattedValue += valueSuffix;
    }

    _setFormattedValue(_newFormattedValue);
  }, [value, valueDisplay, valueSuffix]);

  /*
   * Handlers
   */
  const getShowInfo = () => {
    return (
      <>
        <IndicatorModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          scores={scores}
          action={action}
          matchLimit={matchLimit}
        />
        <IndicatorPopup openModal={openModal} scores={scores} />
      </>
    );
  };

  /*
   * Render
   */

  /* Define color */
  const _endColor = getColorForScore(value);
  const _startColor = alpha(_endColor, 0);

  /* Define handle */
  let _Handle = null;
  if (_imageURL && typeof value !== 'undefined') {
    _Handle = (
      <div className={styles.icon} style={{left: `${_leftOffset}%`}}>
        <Logo src={_imageURL} size="xsmall" />
      </div>
    );
  } else if (!teamId && typeof value !== 'undefined') {
    _Handle = (
      // <div
      //   className={classNames(styles.icon, styles.iconDummy)}
      //   style={{left: `${_leftOffset}%`}}>
      //   <div className={styles.handle} />
      // </div>
      <Box
        position="absolute"
        bgcolor={_endColor}
        height={10}
        width={4}
        borderRadius={2}
        top={'50%'}
        left={`${_leftOffset}%`}
        sx={{
          transform: 'translateY(-50%)',
        }}
      />
    );
  }

  /* Define value style */
  const _valueStyle: SxProps<Theme> = {
    fontSize: bigValue ? 16 : 14,
    fontWeight: valueDisplay === 'score' ? 600 : 400,
    color: coloredValue ? _endColor : 'text.secondary',
    whiteSpace: 'nowrap',
  };

  let _ValueContent = null;

  if (valueDisplay !== 'none' && typeof _formattedValue !== 'undefined') {
    _ValueContent = <Typography sx={_valueStyle}>{_formattedValue}</Typography>;
  } else if (valueDisplay !== 'none' && typeof value === 'undefined') {
    _ValueContent = <Skeleton variant="text" width={12} height={16} />;
  }

  // const _bgColor = alpha(_theme.palette.common.white, 0.08);
  return (
    <Stack alignItems="center" direction="row" gap={2} minHeight={16}>
      <Box
        // sx={{
        //   backgroundColor: _bgColor,
        // }}
        bgcolor="divider"
        height={6}
        borderRadius={3}
        width={width || '100%'}
        position="relative">
        {typeof value !== 'undefined' && (
          <Box
            // bgcolor="primary.main"
            height={'100%'}
            width={`${_leftOffset}%`}
            sx={{
              borderTopLeftRadius: 3,
              borderBottomLeftRadius: 3,
              background: `linear-gradient(90deg, ${_startColor} 0%, ${_endColor} 100%)`,
            }}
          />
        )}
        {_Handle}
      </Box>

      {_ValueContent}
      {showInfo && getShowInfo()}
    </Stack>
  );
};

export default Indicator;
