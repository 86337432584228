import {FIBenchmarkTimeOptions, formatSeason} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import {ITypedOption} from 'types/option.types';
import CustomAutocomplete from '../material-customised/autocomplete/autocomplete.view';
import {t} from 'i18next';
import {useCompetitions} from 'context/competitions.context';
import Segment from '../trackers/_______sentence/segment';

interface ITimeFilterContentProps {
  value?: FIBenchmarkTimeOptions;
  onChange: (value: FIBenchmarkTimeOptions) => void;
  selectedCompetitions?: number[];
}

const DEFAULT_MATCH_LIMIT = 10;

function TimeFilterContent(props: ITimeFilterContentProps): JSX.Element {
  /*
   * Hooks n State
   */
  const _competitionsContext = useCompetitions();
  const [_selectedRadioButton, _setSelectedRadioButton] = useState<
    keyof FIBenchmarkTimeOptions | null
  >(null);

  const [_seasonOptions, _setSeasonOptions] = useState<ITypedOption<number>[]>(
    [],
  );
  const [_selectedSeasonOptions, _setSelectedSeasonOptions] = useState<
    ITypedOption<number>[]
  >([]);

  const [_placeholderValues, _setPlaceholderValues] =
    useState<FIBenchmarkTimeOptions>({});

  /*
   * Side effects
   */
  // Define available season options
  useEffect(() => {
    let _selectedCompetitions = props.selectedCompetitions;
    if (
      !_selectedCompetitions?.length &&
      _competitionsContext.domesticCompetition
    ) {
      _selectedCompetitions = [_competitionsContext.domesticCompetition._id];
    }
    // Try showing seasons available for selected competitions
    let _competitionsToCheck = _competitionsContext.competitionSeasons.filter(
      (c) => _selectedCompetitions?.includes(c.competition_id),
    );
    if (!_competitionsToCheck.length) {
      // If no competitions selected, show all available seasons
      _competitionsToCheck = _competitionsContext.competitionSeasons;
    }

    const _availableSeasonIds = Array.from(
      new Set(_competitionsToCheck.map((c) => c.season_id)),
    ).sort((a, b) => b - a);

    const _generatedSeasonOptions: ITypedOption<number>[] =
      _availableSeasonIds.map((year) => ({
        label: formatSeason(year, _competitionsContext.displayMultiYear),
        value: year,
      }));

    _setSeasonOptions(_generatedSeasonOptions);

    const _sinceDate = new Date();
    _sinceDate.setMonth(_sinceDate.getMonth() - 3);

    _setPlaceholderValues({
      season_id: [_availableSeasonIds[0]],
      match_limit: DEFAULT_MATCH_LIMIT,
      since_date: _sinceDate,
      until_date: new Date(),
    });
  }, [
    props.selectedCompetitions,
    _competitionsContext.competitionSeasons,
    _competitionsContext.displayMultiYear,
    _competitionsContext.domesticCompetition,
  ]);

  // Set default selected radio button
  useEffect(() => {
    if (props.value?.match_limit) {
      _setSelectedRadioButton('match_limit');
    } else if (props.value?.season_id?.length) {
      _setSelectedRadioButton('season_id');
    } else if (props.value?.since_date) {
      _setSelectedRadioButton('since_date');
    }
  }, [props.value]);

  // Define selected season options
  useEffect(() => {
    if (!props.value?.season_id?.length) {
      const _matchingOptions = _seasonOptions.filter((seasonOption) =>
        _placeholderValues.season_id?.includes(seasonOption.value),
      );
      _setSelectedSeasonOptions(_matchingOptions);
      return;
    }
    const _matchingOptions = _seasonOptions.filter((seasonOption) =>
      props.value?.season_id?.includes(seasonOption.value),
    );
    _setSelectedSeasonOptions(_matchingOptions);
  }, [props.value?.season_id, _seasonOptions, _placeholderValues.season_id]);

  /*
   * Handlers
   */
  function _onRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
    const _value = event.target.value as keyof FIBenchmarkTimeOptions;
    _setSelectedRadioButton(_value);

    const _newPlaceholderValues = {
      ..._placeholderValues,
    };
    if (typeof props.value?.match_limit === 'undefined') {
      _newPlaceholderValues.match_limit = props.value?.match_limit || 0;
    } else if (props.value?.season_id?.length) {
      _newPlaceholderValues.season_id = props.value.season_id;
    } else if (props.value?.since_date) {
      _newPlaceholderValues.since_date = props.value.since_date;
    }

    _setPlaceholderValues(_newPlaceholderValues);

    if (!props.onChange) return;

    if (_value === 'match_limit') {
      props.onChange({
        match_limit: props.value?.match_limit || DEFAULT_MATCH_LIMIT,
      });
    } else if (_value === 'season_id') {
      props.onChange({
        season_id: props.value?.season_id || [],
      });
    } else if (_value === 'since_date') {
      props.onChange({
        since_date:
          props.value?.since_date ||
          _placeholderValues.since_date ||
          new Date(),
        until_date:
          props.value?.until_date ||
          _placeholderValues.until_date ||
          new Date(),
      });
    }
  }

  function _onSeasonChange(
    value: ITypedOption<number>[] | ITypedOption<number> | null,
  ) {
    if (!props.onChange) return;
    if (value && Array.isArray(value)) {
      const _newSeasons = value.map((v) => v.value);
      props.onChange({
        season_id: _newSeasons,
      });
    } else if (value) {
      props.onChange({
        season_id: [value.value],
      });
    }
  }

  function _onUntilDateChange(data: Partial<FIBenchmarkTimeOptions>) {
    _onDateChange('until_date', data.until_date as Date);
  }

  function _onSinceDateChange(data: Partial<FIBenchmarkTimeOptions>) {
    _onDateChange('since_date', data.since_date as Date);
  }
  function _onDateChange(property: 'since_date' | 'until_date', value: Date) {
    const _dateValues: FIBenchmarkTimeOptions = {
      since_date: props.value?.since_date || _placeholderValues.since_date,
      until_date: props.value?.until_date || _placeholderValues.until_date,
      [property]: value,
    };

    props.onChange(_dateValues);
  }

  /*
   * Render
   */
  return (
    <Stack>
      {/* <div>Season</div>
      <div>Date</div>
      <div>Last X matches</div> */}
      <FormControl>
        <RadioGroup value={_selectedRadioButton} onChange={_onRadioChange}>
          <FormControlLabel
            value="season_id"
            control={<Radio />}
            label={
              <Stack direction="row" gap={1} alignItems="center">
                <span>
                  {t('eventAutomations.filter.time.seasons', {count: 1})}:
                </span>
                <CustomAutocomplete
                  multiple
                  disableCloseOnSelect
                  isInlineInput
                  size="small"
                  options={_seasonOptions}
                  value={_selectedSeasonOptions}
                  required
                  onChange={_onSeasonChange}
                  placeholder={t('eventAutomations.filter.time.seasons', {
                    count: 1,
                  })}
                  disabled={_selectedRadioButton !== 'season_id'}
                  tagsSentenceValue={_selectedSeasonOptions
                    .map((option) => option.label)
                    .join(', ')}
                />
              </Stack>
            }
          />
          <FormControlLabel
            value="since_date"
            control={<Radio />}
            label={
              <Stack direction="row" gap={1} alignItems="center">
                <span>{t('eventAutomations.filter.time.since')}</span>
                {/* <input
                  type="date"
                  onChange={_onDateChange}
                  value={
                    ((props.value?.since_date as Date) || new Date())
                      .toISOString()
                      .split('T')[0]
                  }
                /> */}
                <Segment.Date
                  size="small"
                  data={{}}
                  property="since_date"
                  value={
                    (props.value?.since_date as Date) ||
                    _placeholderValues.since_date
                  }
                  onChange={_onSinceDateChange}
                  disabled={_selectedRadioButton !== 'since_date'}
                />
                <span>{t('eventAutomations.filter.time.until')}</span>
                <Segment.Date
                  size="small"
                  data={{}}
                  property="until_date"
                  value={
                    (props.value?.until_date as Date) ||
                    _placeholderValues.until_date
                  }
                  onChange={_onUntilDateChange}
                  disabled={_selectedRadioButton !== 'since_date'}
                />
              </Stack>
            }
          />
          <FormControlLabel
            value="match_limit"
            control={<Radio />}
            label={
              <Stack direction="row" gap={1} alignItems="center">
                <span>{t('eventAutomations.filter.time.last')}</span>
                <Segment.NumberInput<FIBenchmarkTimeOptions>
                  value={
                    props.value?.match_limit || _placeholderValues.match_limit
                  }
                  onChange={props.onChange}
                  property="match_limit"
                  data={{}}
                  size="small"
                  disabled={_selectedRadioButton !== 'match_limit'}
                />
                <span>
                  {t('eventAutomations.filter.time.games', {
                    count: props.value?.match_limit || 0,
                  })}
                </span>
              </Stack>
            }
          />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
}

export default TimeFilterContent;
