const SENTENCE_FORM_TRANSLATIONS = {
  a: 'a',
  an: 'an',
  and: 'and',
  or: 'or',
  all: 'all',
  is: 'is',
  atLeast: 'at least',
  count: 'count',
  amount: 'number',
  amountOf: 'number of',
  howOften: 'how often',
  percentOf: '% of',
  everyWeek: 'Every week, share',
  visitsTo: 'visits to',
  preview: 'Preview',
  // countPercent: 'count percent of',
  share: {
    shareVideos: "Every week, share video's of opponent's last",
    matches_one: 'match',
    matches_other: 'matches',
    inTheirLast: 'in their last',
    placeholder: 'staff only',
    staffOnly: 'staff only',
    with: 'with',
    shareWith: 'Share with',
    shareWithPlayers: 'Share with players',
    shareWithAllPlayers: 'Share with all players',
    position: 'position',
    positions: 'positions',

    shareInApp: 'Share in PlayerApp',
    sharedInApp: 'Shared in PlayerApp',
    notSharedInApp: 'Not shared in PlayerApp',
    notSharedInAppShort: 'Not shared',
    sharedWithValue: 'Shared with {{value}}',
    sharedWithAllPlayers: 'Shared with all players',

    playersCount_one: '{{count}} player',
    playersCount_other: '{{count}} players',
    positionsCount_one: '{{count}} position',
    positionsCount_other: '{{count}} positions',
  },
  notify: {
    notify: 'Notify them',
    daysBefore_one: 'day before matchday',
    daysBefore_other: 'days before matchday',
  },
  details: {
    title: 'Only count {{action}} where',
    add: 'Add filter',
    remove: 'Remove filter',
    placeholder: {
      details: 'of any kind',
      successDetails: 'objective details',
    },
    is: 'is',
    isAllOf: 'is all of',
    isOneOf: 'is one of',
    thatAre_one: 'that is',
    thatAre_other: 'that are',
    mustBe: 'must be',
    error: {
      successDetails: 'Please select objective details',
    },
    valuePrefix: {
      coordinates: 'in',
      'carry.end_coordinates': 'ending in',
      'opponent_team.defence': 'playing against',
      'team.formation': 'playing in',
      time_block: 'time is',
      'pass.receiving_player._id': 'received by',
      'pass.receiving_player.position': 'received by',
      'pass.end_coordinates': 'ending in',
      'run.end_coordinates': 'ending in',
    },
  },
  of: 'of',
  by: 'by',
  against: 'against',
  byOpponent: 'by next opponent',

  must: 'must',
  perMatch: 'per match',
  timesPerMatch_one: 'time per match',
  timesPerMatch_other: 'times per match',
  players: {
    all: 'all players',
    any: 'any player',
    playerX: 'Player X',
    error: 'Please select at least 1 player',
  },
  action: {
    placeholder_one: 'event',
    placeholder_other: 'events',
    error: 'Please select an event action',
  },
  pitchZone: {
    placeholder_one: 'entry in zone',
    placeholder_other: 'entries in zone',
  },
  target: {
    error: 'Please set a target',
  },
  conditions: {
    add: 'Add condition',
    within: 'within',
    after: 'after',
    afterTitle: 'After - Matching event X seconds after',
    before: 'before',
    beforeTitle: 'Before - Matching event X seconds before',
    conditions_one: 'condition',
    conditions_other: 'conditions',
    delete: 'Remove condition',
    gameStates: {
      placeholder: 'in game state',
    },
    seconds_one: 'second',
    seconds_other: 'seconds',
    when: 'when',
    whenTitle: 'When in game state',
    error: {
      when: 'Please select at least one game state',
      seconds: 'Please select the amount of seconds',
    },
  },
  observing: {
    any: 'any',
    anyPlayer: 'any player',
    anyTeamMate: 'any team mate',
    anyOpponent: 'any opponent',
    entireTeam: 'team',
    own: 'own team',
    players_one: 'player',
    players_other: 'players',
    opponent: 'opponent',
    opponents_one: 'opponent',
    opponents_other: 'opponents',
    teamMates_one: 'team mate',
    teamMates_other: 'team mates',
    name: 'name',
    position: 'position',
    positionsPlaceholder: 'Select position(s)',
    player: 'player',
    playerX: 'Player X',
    playersPlaceholder: 'Select player(s)',
    of: 'of',
    nextOpponent: 'next opponent',
    nextOpponentOpponent: 'team playing next opponent',
    teamPlaying: 'team playing',
    playing: 'playing',
    ofTeamPlaying: 'of team playing',
    // nextOpponent_by: "next opponent's",
    // nextOpponentOpponent_by: "opponents of your next opponent's",
    playersByName: 'players by name',
    playersByPosition: 'players by position',
    observingAgainst: 'Observing conceded actions',
    observing: 'Observing',
  },
  tracking: {
    visitsTo: 'visits to',
    visitVerb: 'visit',
    visitVerbThird: 'visits',
    visitVerb_one: 'visits',
    visitVerb_other: 'visit',
    zoneVisits_one: 'visit',
    zoneVisits_other: 'visits',
    zone_one: 'zone',
    zone_other: 'zones',
    zone_error: 'Please select a zone',
    deletedZone: '[Deleted zone]',
    isIn: 'is in',
    beInBoxVerb: 'be in',

    entriesIn: 'entries in',
  },
  comment: {
    add: 'Add a comment',
    placeholder: 'Comment',
  },
  extraSettings: {
    sectionHeader: 'Extra Settings',
    title: 'Custom title',
    comment: 'Additional comment',
    tags: 'Category',
  },
  tags: {
    label: 'Category',
    placeholder: 'Select a category',
  },
  automationType: {
    default: {
      event: {
        title: 'Events',
        text: 'Count on-the-ball and off-the-ball actions by player or team',
      },
      zone_visit: {
        title: 'Zone presense',
        text: 'Count how often by player is in a zone',
        disabled: 'Please switch to "Occurrences" to measure zone presence.',
      },
      sequence: {
        title: 'Event sequences',
        text: 'Count sequences of actions by player or team.',
      },
    },
    rule_condition: {
      event: {
        title: 'Events',
        text: '"When a player performs an event, ..."',
      },
      zone_visit: {
        title: 'Zone presence',
        text: '"When a player is in zone, ..."',
      },
    },
    rule: {
      event: {
        title: 'Events',
        text: '"Player must perform an on-the-ball or of-the-ball action"',
      },
      zone_visit: {
        title: 'Zone presence',
        text: '"Player must be in zone"',
      },
    },
  },
  rule: {
    when: 'When',
    must: 'must',
  },
  byAgainstToggle: {
    label: {
      by: 'Observing own actions',
      against: 'Observing actions by opposing team',
    },
  },
  sequence: {
    countSequences: 'Count sequences containing',
    addAction: 'Add action',
  },
  executor: {
    error:
      'Please select at least one subject in either team performance, player development or opponent analysis',
    empty: 'No players selected',
    noPlayersForType: 'No players selected for {{type}}',
    teamPerformance: {
      title: 'Team performance',
      empty: 'No players selected',
    },

    playerDevelopment: {
      title: 'Player development',
      empty: 'No players selected',
      select: 'Select players',
    },

    scouting: {
      title: 'Scouting',
      empty: 'No scouting profiles selected',
      select: 'Select profiles',
    },

    opponentAnalysis: {
      title: 'Opponent analysis',
      empty: 'No positions selected',
      select: 'Add observer',
    },

    disabled: {
      default: '{{type}} is not available',
      'player-details':
        '{{type}} is not available with player-specific details selected',
      against:
        '{{type}} is not available for trackers observing conceded events',
    },

    selectedPlayersPreview: '[selected players]',
    selectPlayers: 'Select players',
    addPlayers: 'Add players',
  },
};

export default SENTENCE_FORM_TRANSLATIONS;
