import TrackerCreateButton from 'components/trackers/misc/tracker-create-button/tracker-create-button.view';
import {
  defaultExecutorForPlayer,
  defaultExecutorForTeam,
} from 'config/trackers.config';
import {useExecutorDeepDive} from 'context/executor-deep-dive.context';

import {FIEventAutomationPostData} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';

import AddIcon from '@mui/icons-material/Add';
import {useAnalytics} from 'context/analytics.context';
import ANALYTICS_EVENT from 'config/analytics/event-names.config';
import PlayerMirrorBuilder from './mirrorring/player-mirror-builder.view';
import CustomEmptyState from 'components/error-states/CustomEmptyState';
import ExecutorTrackers from './executor-trackers';
import PerformanceStatsBar from './performance-stats-bar';
import PerformanceHero from './hero/performance-hero';

function ExecutorPerformanceTab(): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _analyticsContext = useAnalytics();
  const _executorDeepDiveContext = useExecutorDeepDive();
  const [_newTrackerData, _setNewTrackerData] =
    useState<Partial<FIEventAutomationPostData> | null>(null);

  const [_isEmpty, _setIsEmpty] = useState<boolean>(false);

  /*
   * Side effects
   */
  /* Mixpanel event */
  useEffect(() => {
    if (_executorDeepDiveContext.analyticsData) {
      _analyticsContext.trackEvent(ANALYTICS_EVENT.PLAYER_PAGE_VIEWED_TAB, {
        ..._executorDeepDiveContext.analyticsData,
        tab: 'performance',
      });
    }
  }, [_executorDeepDiveContext.analyticsData]);

  /* Define data for "create tracker" button */
  useEffect(() => {
    let _emptyTrackerData: Partial<FIEventAutomationPostData> | null = null;
    const _defaultTeamExecutor = defaultExecutorForTeam();
    if (_executorDeepDiveContext.executorType === 'team_performance') {
      _emptyTrackerData = {
        team_performance: [_defaultTeamExecutor],
      };
    } else if (_executorDeepDiveContext.executorType == 'opponent_analysis') {
      _emptyTrackerData = {
        opponent_analysis: [_defaultTeamExecutor],
      };
    } else if (
      _executorDeepDiveContext.executorType == 'player_development' &&
      _executorDeepDiveContext.player
    ) {
      _emptyTrackerData = {
        player_development: [
          defaultExecutorForPlayer(_executorDeepDiveContext.player._id),
        ],
      };
    }

    _setNewTrackerData(_emptyTrackerData);
  }, [_executorDeepDiveContext.executorType, _executorDeepDiveContext.player]);

  /* Define whether "isEmpty" */
  useEffect(() => {
    _setIsEmpty(!_executorDeepDiveContext.performanceStats?.automations_count);
  }, [_executorDeepDiveContext.performanceStats]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  let _Content = null;
  if (_executorDeepDiveContext.isSettingMirrorInfo) {
    _Content = <PlayerMirrorBuilder />;
  } else if (_isEmpty) {
    let _translationKey = 'noPlayerData';
    if (_executorDeepDiveContext.executorType === 'team_performance') {
      _translationKey = 'noTeamData';
    } else if (_executorDeepDiveContext.executorType === 'opponent_analysis') {
      _translationKey = 'noOpponentData';
    }

    _Content = (
      <CustomEmptyState
        header={t(`players.performance.${_translationKey}.header`)}
        description={t(`players.performance.${_translationKey}.description`)}
        primaryAction={
          <TrackerCreateButton
            type={_executorDeepDiveContext.executorType}
            optionalActionInfo={_newTrackerData || {}}
            Button={
              <Button variant="contained" startIcon={<AddIcon />}>
                {t('eventAutomations.trackersNew')}
              </Button>
            }
          />
        }
      />
    );
  } else if (_executorDeepDiveContext.performanceStats) {
    _Content = (
      <Stack>
        <Stack pb={6}>
          <PerformanceHero
            performanceOverview={_executorDeepDiveContext.performanceStats}
            benchmarkOptions={
              _executorDeepDiveContext.benchmarkAndDateFilters?.benchmark
            }
            subject={
              _executorDeepDiveContext.player?.display_name ||
              _executorDeepDiveContext.team?.name ||
              ''
            }
          />
        </Stack>
        <Divider flexItem />
        <ExecutorTrackers />
      </Stack>
    );
  }

  return (
    <Stack gap={5}>
      <PerformanceStatsBar />
      {_Content}
      {_newTrackerData && (
        <TrackerCreateButton
          optionalActionInfo={_newTrackerData}
          type={_executorDeepDiveContext.executorType}
          Button={
            <Fab
              title={t('eventAutomations.trackersNew')}
              color="primary"
              aria-label="add"
              sx={{position: 'fixed', bottom: '1rem', right: '1rem'}}>
              <AddIcon />
            </Fab>
          }
        />
      )}
    </Stack>
  );
}

export default ExecutorPerformanceTab;
