import {ITrackerSentenceProps} from 'types/trackers/tracker-sentence.types';
import React, {useEffect, useState} from 'react';
import SentenceBlock from '../blocks/sentence-block';
import SentenceTextSegment from '../segment/variants/text/sentence-text-segment';
import {useTranslation} from 'react-i18next';
import SequenceActions from './actions/sequence-actions';
import SentenceExecutorSegment from '../segment/instances/executor/executor-segment';
import {TTrackerExecutorType} from '@my-game-plan/types';
import TeamSelectSegment from '../segment/instances/team/team-select-segment';

function SequenceSentence(props: ITrackerSentenceProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_shouldDisplayExecutor, _setShouldDisplayExecutor] = useState(false);
  const [_shouldDisplayTeam, _setShouldDisplayTeam] = useState(false);

  /*
   * Side Effects
   */
  useEffect(() => {
    let _newShouldDisplayExecutor = true;
    if (props.readonly && !props.currentExecutorType) {
      _newShouldDisplayExecutor = false;
    }

    if (
      props.readonly &&
      props.hideExecutorIfPossible &&
      !props.tracker?.concrete_executor?.team?.executor_positions?.length
    ) {
      _newShouldDisplayExecutor = false;
    }

    _setShouldDisplayExecutor(_newShouldDisplayExecutor);

    //Define whether "by [team]" should be displayed
    const _typesWithoutTeam: TTrackerExecutorType[] = [
      'player_development',
      'scouting_profile_ids',
    ];
    let _shouldDisplay =
      !props.currentExecutorType ||
      !_typesWithoutTeam.includes(props.currentExecutorType);

    if (
      props.hideExecutorIfPossible &&
      props.readonly &&
      !props.tracker?.against
    ) {
      _shouldDisplay = false;
    }

    _setShouldDisplayTeam(_shouldDisplay && _newShouldDisplayExecutor);
  }, [
    props.readonly,
    props.currentExecutorType,
    props.tracker?.against,
    props.tracker?.concrete_executor,
    props.hideExecutorIfPossible,
  ]);

  /*
   * Render
   */

  let _OfTeamSegment = null;
  if (_shouldDisplayTeam) {
    _OfTeamSegment = (
      <>
        <SentenceTextSegment {...props} value={t('sentenceForm.of')} />
        <TeamSelectSegment {...props} value={props.tracker} />
      </>
    );
  }
  return (
    <SentenceBlock.Container {...props}>
      <SentenceBlock.Section {...props}>
        {/* Base line */}
        <SentenceTextSegment
          {...props}
          value={t('sentenceForm.sequence.countSequences')}
        />

        {/* Actions */}
        <SequenceActions {...props} />

        {/* By X */}
        {_shouldDisplayExecutor && (
          <SentenceBlock.Line {...props}>
            <SentenceTextSegment {...props} value={t('sentenceForm.by')} />
            <SentenceExecutorSegment {...props} />
            {_OfTeamSegment}
          </SentenceBlock.Line>
        )}
      </SentenceBlock.Section>
    </SentenceBlock.Container>
  );
}

export default SequenceSentence;
