import React, {PropsWithChildren, useEffect, useState} from 'react';
import {
  authenticateUser,
  fetchUser,
  logoutUser,
} from 'controllers/auth.controller';
import {identifyAnalyticsUser, logoutAnalyticsUser} from 'config/analytics';

import {AUTH_STATE} from 'types/user/user.types';

import {
  createContextHook,
  createCustomContext,
  createProvider,
} from 'helpers/general/context_generators.helper';
import {LOGIN_ERRORS} from 'config/errors.config';
import {LOCAL_STORAGE_USER_ID} from 'config/api.config';
import {FIUserFormatted, USER_ROLE} from '@my-game-plan/types';

export interface AuthAPI {
  user: FIUserFormatted | null;
  authState: AUTH_STATE;
  setAuthState: (state: AUTH_STATE) => void;
  loginUser: (
    name: string,
    password: string,
  ) => Promise<string | undefined> | void;
  logoutUser: () => void;
}

const context = createCustomContext<AuthAPI>();
export const useAuth = createContextHook(context);

export const AuthProvider = (
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element => {
  const [_user, _setUser] = useState<FIUserFormatted | null>(null);
  const [_authState, _setAuthState] = useState<AUTH_STATE>(AUTH_STATE.INITING);

  /* Effects */
  useEffect(() => {
    _tryLoggingInUser();
  }, []);

  /* Handlers */
  async function _tryLoggingInUser() {
    const userId = localStorage.getItem(LOCAL_STORAGE_USER_ID);

    if (userId) {
      _fetchUser(userId);
      return;
    }
    _setAuthState(AUTH_STATE.NOT_LOGGED_IN);
  }
  async function _fetchUser(userId: string) {
    const _data = await fetchUser(userId, true);

    if (!_data) {
      _logoutUser();
      return;
    }

    _handleUserLogin(_data);
  }

  async function _loginUser(
    name: string,
    password: string,
  ): Promise<string | undefined> {
    const user = await authenticateUser(name, password);
    if (user === null) {
      throw new Error(LOGIN_ERRORS.DEFAULT);
    }
    if (user?.role !== USER_ROLE.ANALYST && user?.role !== USER_ROLE.ADMIN) {
      throw new Error(LOGIN_ERRORS.NOT_ANALYST);
    }

    localStorage.setItem(LOCAL_STORAGE_USER_ID, user._id);
    _fetchUser(user._id);
    return '';
  }

  async function _handleUserLogin(user: FIUserFormatted) {
    await identifyAnalyticsUser(user);

    /* Set user */
    _setUser(user);
  }

  async function _logoutUser() {
    await logoutUser();
    _setUser(null);
    localStorage.removeItem(LOCAL_STORAGE_USER_ID);
    logoutAnalyticsUser();
    _setAuthState(AUTH_STATE.NOT_LOGGED_IN);
  }

  function _changeAuthState(state: AUTH_STATE) {
    _setAuthState(state);
  }

  return createProvider(context, props, {
    user: _user,
    authState: _authState,
    setAuthState: _changeAuthState,
    loginUser: _loginUser,
    logoutUser: _logoutUser,
  });
};
