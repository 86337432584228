import ShadowTrackerDetailView from 'views/automations/shadow-tracker_detail.view';
import PlayerView from 'views/player/player.view';
import ScoutingProfileView from 'views/scouting/scouting-profile.view';

import ScoutingView from 'views/scouting/scouting.view';
import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

function ScoutingRoutes() {
  return (
    <Routes>
      <Route path="" element={<ScoutingView />} key="scouted-players" />
      {/* Scouting profile */}
      <Route
        path="profiles/:scouting_profile_id/*"
        element={<ScoutingProfileView />}
      />

      <Route
        path="profiles/:scouting_profile_id/players/:player_id/*"
        element={<PlayerView executorType="scouting_profile_ids" />}
      />

      <Route
        path="profiles/:scouting_profile_id/players/:player_id/statistics/:id"
        element={<ShadowTrackerDetailView />}
      />
      {/* No scouting profile */}
      <Route
        path="players/:player_id/*"
        element={<PlayerView executorType="scouting_profile_ids" />}
      />
      <Route
        path="players/:player_id/statistics/:id"
        element={<ShadowTrackerDetailView />}
      />

      {/* Redirect */}
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}

export default ScoutingRoutes;
