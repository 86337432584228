import {INavigationRoute} from 'types/navigation.types';
import React, {useEffect, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Tooltip from '@mui/material/Tooltip';
import {useApp} from 'context/app.context';

interface ISidebarButtonProps {
  route: INavigationRoute;
}

function SidebarButton(props: ISidebarButtonProps): JSX.Element {
  /*
   * Hooks n State
   */
  const [_isActive, _setIsActive] = useState<boolean>(false);
  const _location = useLocation();
  const _appContext = useApp();

  /*
   * Side effects
   */

  // Handle active state
  useEffect(() => {
    const _doesPathIncludeRoute = _location.pathname.startsWith(
      props.route.path,
    );
    _setIsActive(_doesPathIncludeRoute);
  }, [_location.pathname]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  const _ButtonComponent = (
    <ListItem sx={{display: 'block'}} disablePadding>
      <ListItemButton
        component={NavLink}
        to={props.route.path}
        selected={_isActive}
        sx={[
          {
            minHeight: 48,
            px: 2.5,
            py: 2,
          },
          _appContext.navigation.isSidebarExpanded
            ? {
                justifyContent: 'initial',
              }
            : {
                justifyContent: 'center',
              },
        ]}>
        <ListItemIcon
          sx={[
            {
              minWidth: 0,
              justifyContent: 'center',
            },
            _appContext.navigation.isSidebarExpanded
              ? {
                  mr: 2,
                }
              : {
                  mr: 'auto',
                },
          ]}>
          {props.route.icon && (
            <props.route.icon color={_isActive ? 'primary' : 'secondary'} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={props.route.label}
          primaryTypographyProps={{
            variant: 'body2',
            color: _isActive ? 'text.primary' : 'secondary.light',
            fontWeight: '500',
          }}
          sx={[
            _appContext.navigation.isSidebarExpanded
              ? {
                  opacity: 1,
                }
              : {
                  opacity: 0,
                },
          ]}
        />
      </ListItemButton>
    </ListItem>
  );

  if (_appContext.navigation.isSidebarExpanded) {
    return _ButtonComponent;
  }

  return (
    <Tooltip title={props.route.label} arrow placement="right">
      {_ButtonComponent}
    </Tooltip>
  );
}

export default SidebarButton;
