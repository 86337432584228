import {
  FICompactMatchInfo,
  FIDisplayPlayer,
  FIEventAutomationPostData,
  FIFormattedAutomation,
  FIFormattedAutomationPlayerScore,
  shouldInvertScore,
  TTrackerExecutorType,
} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import {formattedAutomationToPostData} from 'helpers/event-automations.helper';
import TrackerSentence from '../sentence/tracker-sentence';
import {useTranslation} from 'react-i18next';

import {formatSingleExecutor} from 'helpers/automation/automation-form.helper';
import {useTeams} from 'context/team.context';
import {formatSingleScore} from 'helpers/automation/automation-detail-history.helper';
import TrackersTableActionsCell from './cells/trackers-table-actions-cell';
import {ILinkState} from 'types/navigation.types';
import TrackersTableSparkChart from './cells/trackers-table-spark-chart';
import TrackersTablePercentileCell from './cells/trackers-table-percentile-cell';

export interface ITrackerTableCell {
  label: string;
  key: 'title' | 'percentile' | 'form' | 'average' | 'buttons';
  align?: 'left' | 'right' | 'center';
  hideOnSmallScreen?: boolean;
}

interface ITrackersTableRowProps {
  tracker: FIFormattedAutomation;
  executorType: TTrackerExecutorType;
  cells: ITrackerTableCell[];
  per90?: boolean;
  concreteExecutorInfo?: FIDisplayPlayer;
  backLink?: ILinkState;
  matches: FICompactMatchInfo[];
}

function TrackersTableRow(props: ITrackersTableRowProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _teamsContext = useTeams();

  const [_trackerData, _setTrackerData] = useState<
    Partial<FIEventAutomationPostData>
  >({});
  const [_executorSubtitle, _setExecutorSubtitle] = useState<string>('');
  const [_formattedAverage, _setFormattedAverage] =
    useState<FIFormattedAutomationPlayerScore | null>(null);

  /*
   * Side Effects
   */
  /* Define tracker data */
  useEffect(() => {
    const _postData = formattedAutomationToPostData(props.tracker);
    _setTrackerData(_postData);
  }, [props.tracker]);

  /* Define subtitle */
  useEffect(() => {
    // Define subtitle if tracker has multiple executors
    const _executorsForType = props.tracker?.[props.executorType];
    const _hasMultipleExecutorsInExecutorType =
      _executorsForType && _executorsForType?.length > 1;
    if (
      props.executorType !== 'player_development' &&
      _hasMultipleExecutorsInExecutorType &&
      props.tracker.concrete_executor
    ) {
      const _formattedExecutor = formatSingleExecutor(
        props.tracker.concrete_executor,
        _teamsContext.ownPlayers,
      );
      _setExecutorSubtitle(
        `${t('sentenceForm.observing.observing')} ${_formattedExecutor}`,
      );
    } else {
      _setExecutorSubtitle('');
    }
  }, [props.tracker, props.executorType, _teamsContext.ownPlayers]);

  /* Format average */
  useEffect(() => {
    if (props.tracker.benchmark_scores?.average !== undefined) {
      const _generatedFormattedAverage = formatSingleScore(
        'average',
        '',
        props.tracker.benchmark_scores?.average,
        props.tracker.benchmark_scores?.average -
          props.tracker.benchmark_scores?.benchmark_average,
        props.tracker.calculation === 'ratio' ||
          props.tracker.calculation === 'rule',
        undefined,
        shouldInvertScore(props.tracker),
      );
      _setFormattedAverage(_generatedFormattedAverage);
    } else {
      _setFormattedAverage(null);
    }
  }, [props.tracker]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  function _renderNotAvailableLabel(text?: string): JSX.Element {
    return (
      <Typography
        sx={{fontSize: 'inherit', color: 'text.disabled', opacity: 0.4}}>
        {text || t('eventAutomations.values.notAvailable')}
      </Typography>
    );
  }

  return (
    <TableRow>
      {props.cells.map((cell) => {
        let _CellContent = null;

        switch (cell.key) {
          case 'title':
            _CellContent = props.tracker.title;
            if (!props.tracker.title) {
              _CellContent = (
                <TrackerSentence
                  tracker={_trackerData}
                  readonly
                  size="small"
                  currentExecutorType={props.executorType}
                  concreteExecutorInfo={props.concreteExecutorInfo}
                  hideExecutorIfPossible
                />
              );
              break;
            }

            _CellContent = (
              <Stack>
                <Typography fontSize="inherit">
                  {props.tracker.title}
                </Typography>
                {_executorSubtitle && (
                  <Typography variant="body2" color="text.secondary">
                    {_executorSubtitle}
                  </Typography>
                )}
              </Stack>
            );
            // _CellContent = 'title';

            break;

          case 'percentile':
            if (
              typeof props.tracker.benchmark_scores?.benchmarked_score ===
              'undefined'
            ) {
              _CellContent = _renderNotAvailableLabel();
              break;
            }

            _CellContent = (
              <TrackersTablePercentileCell
                score={props.tracker.benchmark_scores?.benchmarked_score}
              />
            );
            // _CellContent = 'percentile';
            break;

          case 'form':
            if (!props.tracker.form) {
              _CellContent = _renderNotAvailableLabel();
              break;
            }
            _CellContent = (
              <TrackersTableSparkChart form={props.tracker.form} />
            );
            // _CellContent = 'form';
            break;

          case 'average':
            if (!_formattedAverage) {
              _CellContent = _renderNotAvailableLabel();
              break;
            }
            _CellContent = (
              <Typography fontSize="inherit">
                {_formattedAverage.formattedValue}
              </Typography>
            );
            // _CellContent = 'average';
            break;

          case 'buttons':
            _CellContent = (
              <TrackersTableActionsCell
                tracker={props.tracker}
                executorType={props.executorType}
                backLink={props.backLink}
                matches={props.matches}
              />
            );
            // _CellContent = 'buttons';
            break;

          default:
            _CellContent = _renderNotAvailableLabel();
            break;
        }

        return (
          <TableCell
            key={cell.key}
            align={cell.align || 'left'}
            sx={{py: 3, width: cell.key === 'title' ? '30%' : undefined}}>
            {_CellContent}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

export default TrackersTableRow;
