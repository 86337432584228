import React, {useEffect, useState} from 'react';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {FICategoryPerformance} from '@my-game-plan/types';
import Indicator from 'components/dashboard/Indicator/Indicator';
import {getPercentileTextForScore} from 'helpers/benchmark.helper';
import {useTranslation} from 'react-i18next';
import TrackersTableSparkChart from 'components/trackers/table/cells/trackers-table-spark-chart';

interface IPerformanceChartTooltipProps {
  data: FICategoryPerformance;
}
function PerformanceChartTooltip(
  props: IPerformanceChartTooltipProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_percentileText, _setPercentileText] = useState<string>('');

  /*
   * Side effects
   */
  useEffect(() => {
    if (typeof props.data.benchmarked_score === 'undefined') {
      _setPercentileText('');
      return;
    }

    const _newPercentileText = getPercentileTextForScore(
      props.data.benchmarked_score,
    );

    _setPercentileText(_newPercentileText);
  }, [props.data]);

  /*
   * Render
   */
  const _benchmarkingText = t(
    'eventAutomations.benchmark.benchmarkTrackersCount',
    {
      count: props.data.automations_count,
    },
  );
  return (
    <Stack
      gap={2}
      p={2}
      bgcolor="background.paper"
      borderRadius={1}
      minWidth={200}>
      <Typography variant="body1">{props.data.name}</Typography>

      <Stack gap={1}>
        <Indicator value={props.data.benchmarked_score} valueDisplay="none" />
        <Typography variant="body2" color="text.secondary">
          {_percentileText}
        </Typography>
      </Stack>
      {props.data.form && <TrackersTableSparkChart form={props.data.form} />}
      <Divider />
      <Typography variant="body2" color="text.disabled" fontStyle="italic">
        {_benchmarkingText}
      </Typography>
    </Stack>
  );
}

export default PerformanceChartTooltip;
