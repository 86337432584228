import PerformanceHeroCharts from 'components/executor-deep-dive/performance/hero/charts/performance-hero-charts';
import {FIPerformanceOverview} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Stack from '@mui/material/Stack';
import {useTranslation} from 'react-i18next';
import ProfileTeamInfo from '../profile-team-info';
import Indicator from 'components/dashboard/Indicator/Indicator';
import {useCompetitions} from 'context/competitions.context';
import {useProfileSetup} from 'context/profile-setup.context';

function ProfileChart(): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _profileSetupContext = useProfileSetup();
  const _competitionsContext = useCompetitions();
  const [
    _overviewWithTranslatedCategories,
    _setOverviewWithTranslatedCategories,
  ] = useState<FIPerformanceOverview | null>(null);
  const [_competitionSuffix, _setCompetitionSuffix] = useState<string>('');

  /*
   * Side effects
   */
  /* Translate categories */
  useEffect(() => {
    if (!_profileSetupContext.selectedProfile) {
      return;
    }

    let _filteredCategories =
      _profileSetupContext.selectedProfile.performance.categories.filter(
        (cat) => typeof cat.benchmarked_score !== 'undefined',
      );
    _filteredCategories = _filteredCategories.map((category) => {
      return {
        ...category,
        name: t(`categories.${category.name}`),
      };
    });
    const _formattedOverview = {
      ..._profileSetupContext.selectedProfile.performance,
      categories: _filteredCategories,
    };

    _setOverviewWithTranslatedCategories(_formattedOverview);
  }, [_profileSetupContext.selectedProfile]);

  useEffect(() => {
    if (!_competitionsContext.domesticCompetition) {
      return;
    }

    _setCompetitionSuffix(
      ` ${t('eventAutomations.in')} ${
        _competitionsContext.domesticCompetition.name
      }`,
    );
  }, [_competitionsContext.domesticCompetition]);

  /*
   * Render
   */
  if (
    !_overviewWithTranslatedCategories ||
    !_profileSetupContext.selectedProfile
  ) {
    return null;
  }

  return (
    <Stack flexGrow={1}>
      <ProfileTeamInfo
        subtitle={t(
          `profileSetup.styleOfPlay.${_profileSetupContext.selectedProfile.style_of_play}.title`,
        )}
        RightContent={
          <Indicator
            value={_overviewWithTranslatedCategories.benchmarked_score}
            valueDisplay="percentile"
            valueSuffix={_competitionSuffix}
            width={160}
          />
        }
      />
      <Stack flexGrow={1} p={4}>
        <PerformanceHeroCharts
          performanceOverview={_overviewWithTranslatedCategories}
        />
      </Stack>
    </Stack>
  );
}

export default ProfileChart;
