import React from 'react';

import {Route, Routes} from 'react-router-dom';

import TeamView from 'views/team/team.view';
import TeamTrackerView from 'views/team/team-tracker.view';

function TeamRoutes() {
  return (
    <Routes>
      <Route path="*" element={<TeamView />} />

      <Route path="statistics/:id" element={<TeamTrackerView />} />
    </Routes>
  );
}

export default TeamRoutes;
