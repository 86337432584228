import {FIFormattedAutomation} from '@my-game-plan/types';
import React from 'react';

import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ManageTrackersTableRow from './manage-trackers-table-row';
import ManageTrackersTH from './manage-trackers-th';
import {useTranslation} from 'react-i18next';
import CustomEmptyState from 'components/error-states/CustomEmptyState';
import {generateUniqueTrackerKey} from 'helpers/automation/automation-form.helper';
import {EXECUTOR_TYPES} from 'config/trackers.config';

interface IManageTrackersTableProps {
  trackers: FIFormattedAutomation[];
}

function ManageTrackersTable(props: IManageTrackersTableProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Side Effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */
  if (!props.trackers.length) {
    return (
      <Stack pb={4}>
        <CustomEmptyState
          size="small"
          header={t('preferences.manageTrackers.noTrackersInCategory')}
        />
      </Stack>
    );
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{verticalAlign: 'bottom', pt: 0}}>
            {t('eventAutomations.trackersShort', {count: 1})}
          </TableCell>
          {EXECUTOR_TYPES.map((executorType) => {
            return (
              <ManageTrackersTH
                key={executorType}
                executorType={executorType}
              />
            );
          })}

          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.trackers.map((tracker) => {
          const _key = generateUniqueTrackerKey(tracker);
          return <ManageTrackersTableRow key={_key} tracker={tracker} />;
        })}
      </TableBody>
    </Table>
  );
}

export default ManageTrackersTable;
