import {FIPlayerWithData} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Grid from '@mui/material/Grid';
import Stat from 'components/common/stat/stat.view';
import {useTranslation} from 'react-i18next';
import {formatPlayerValue, getPlayerMarketValue} from 'helpers/player.helper';
import {toPercentage} from 'helpers/general/general.helper';
import moment from 'moment';

interface IPlayerCardBioProps {
  player: FIPlayerWithData;
  displayPerformance?: boolean;
}

function PlayerCardBio(props: IPlayerCardBioProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_age, _setAge] = useState<number>(0);
  const [_marketValueText, _setMarketValueText] = useState<string>('');
  const [_percentagePlayedText, _setPercentagePlayedText] =
    useState<string>('');

  /*
   * Side effects
   */
  useEffect(() => {
    // Age

    const _calculatedAge = moment().diff(
      moment(props.player.birth_date),
      'years',
    );

    _setAge(_calculatedAge);

    // Formatted market value
    const _latestMarketValue = getPlayerMarketValue(props.player.market_value);
    if (_latestMarketValue) {
      _setMarketValueText(formatPlayerValue(_latestMarketValue));
    }

    // Percentage played
    if (props.player.minutes_played) {
      const _percentagePlayed = toPercentage(
        props.player.minutes_played.percentage_played || 0,
      );
      _setPercentagePlayedText(_percentagePlayed);
    }
  }, [props.player, props.displayPerformance]);

  /*
   * Render
   */
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} md={3}>
        <Stat
          label={t('players.info.position')}
          value={props.player.position.join(', ')}
          variant="secondary"
        />
      </Grid>

      {/* Age */}
      <Grid item xs={6} md={3}>
        <Stat label={t('players.info.age')} value={_age} variant="secondary" />
      </Grid>

      {/* Market value */}
      {_marketValueText && (
        <Grid item xs={6} md={3}>
          <Stat
            label={t('players.info.market_value')}
            value={_marketValueText}
            variant="secondary"
          />
        </Grid>
      )}

      {_percentagePlayedText && (
        <Grid item xs={6} md={3}>
          <Stat
            label={t('players.info.minutesPlayed')}
            value={_percentagePlayedText}
            variant="secondary"
          />
        </Grid>
      )}
    </Grid>
  );
}

export default PlayerCardBio;
