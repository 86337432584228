import React from 'react';
import {
  FIShotAnalysisCategory,
  FIShotAnalysisInsight,
  FITeam,
  ShotCategory,
} from '@my-game-plan/types';

import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ShieldIcon from '@mui/icons-material/Shield';
import BoltIcon from '@mui/icons-material/Bolt';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import {useTranslation} from 'react-i18next';
import Logo from 'components/common/Logo/Logo';
import {alpha, lighten, useTheme} from '@mui/material';
import {roundAndFormatNumber} from 'helpers/general/general.helper';

interface IInsightDetailsGeneralProps {
  // team: TShotAnalysisTeam;
  insight?: FIShotAnalysisInsight;
  data: FIShotAnalysisCategory | null;
  team?: FITeam;
  benchmarkLabel: string;
  compact?: boolean;
  onPlayVideo?: (shotCategory?: ShotCategory) => void;
}

function InsightDetailsGeneral(
  props: IInsightDetailsGeneralProps,
): JSX.Element {
  /*
   * Hooks n State
   */

  const {t} = useTranslation();
  const _theme = useTheme();

  /*
   * Handlers
   */
  function _onPlayVideo() {
    if (props.onPlayVideo) {
      props.onPlayVideo();
    }
  }

  /*
   * Render
   */
  return (
    <Stack gap={props.compact ? 2 : 5}>
      {props.insight && (
        <Stack
          direction="row"
          gap={2}
          sx={{
            ...(!props.compact && {
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: alpha(_theme.palette.warning.main, 0.6),
              borderRadius: 1,
              p: 2,
              pb: 1,
            }),
          }}>
          {!props.compact && (
            <LightbulbIcon sx={{color: 'warning.main', mt: 2}} />
          )}
          <Stack>
            <Stack gap={2}>
              <Typography
                fontSize={props.compact ? 14 : undefined}
                color={
                  props.compact
                    ? undefined
                    : lighten(_theme.palette.warning.light, 0.9)
                }>
                {props.insight.text}
              </Typography>
              {!props.compact && (
                <Stack direction="row" alignItems="center" gap={0.5}>
                  <BoltIcon sx={{color: 'text.disabled', fontSize: 16}} />
                  <Typography color="text.disabled" fontSize={12}>
                    {t('shotAnalysis.insight.aiGenerated')}
                  </Typography>
                  {/* <Button
                    variant="text"
                    size="small"
                    sx={{fontSize: 12, textTransform: 'none'}}>
                    {t('shotAnalysis.insight.shareFeedback')}
                  </Button> */}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}

      {/* <Divider /> */}
      <Table
        sx={{
          '& .MuiTableCell-root': {
            px: 1,
            ...(props.compact && {
              borderBottom: 'none',
              py: 0.5,
            }),

            '&:first-of-type': {
              pl: 0,
            },
            '&:last-of-type': {
              pr: 0,
            },
          },
        }}>
        <TableHead
          sx={{
            '& .MuiTableCell-root': {
              pt: 0,
            },
          }}>
          <TableRow>
            <TableCell>{t('shotAnalysis.benchmark.title')}</TableCell>
            <TableCell align="right">
              {t('shotAnalysis.result.shots', {count: 0})}
            </TableCell>
            <TableCell align="right">
              {t('shotAnalysis.result.goals', {count: 0})}
            </TableCell>
            {!props.compact && (
              <TableCell align="right">{t('shotAnalysis.goalRate')}</TableCell>
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {props.data?.team && (
            <TableRow>
              <TableCell
                sx={{
                  color: 'text.secondary',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}>
                <Logo src={props.team?.image_url || ''} />
                {props.team?.name || t('shotAnalysis.team')}
              </TableCell>
              <TableCell align="right">
                {roundAndFormatNumber(props.data.team.shots || 0, 2)}
              </TableCell>
              <TableCell align="right">
                {roundAndFormatNumber(props.data.team.goals || 0, 2)}
              </TableCell>
              {!props.compact && (
                <TableCell align="right">
                  {roundAndFormatNumber(props.data.team.goal_rate || 0, 2)}
                </TableCell>
              )}
            </TableRow>
          )}
          <TableRow>
            <TableCell
              sx={{
                color: 'text.secondary',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}>
              <ShieldIcon sx={{color: 'secondary.main'}} />
              {props.benchmarkLabel}
            </TableCell>
            <TableCell align="right">
              {roundAndFormatNumber(props.data?.benchmark.shots || 0, 2)}
            </TableCell>
            <TableCell align="right">
              {roundAndFormatNumber(props.data?.benchmark.goals || 0, 2)}
            </TableCell>
            {!props.compact && (
              <TableCell align="right">
                {roundAndFormatNumber(props.data?.benchmark.goal_rate || 0, 2)}
              </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
      {props.onPlayVideo && (
        <Box mt={2}>
          <Button
            variant="outlined"
            size={props.compact ? 'small' : 'medium'}
            startIcon={<PlayArrowIcon />}
            onClick={_onPlayVideo}>
            {t('video-player.button.watch')}
          </Button>
        </Box>
      )}
    </Stack>
  );
}

export default InsightDetailsGeneral;
