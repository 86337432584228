import {FITrackerSubject, TTrackerExecutorType} from '@my-game-plan/types';
import React from 'react';

import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ExecutorListItem from './executor-list-item';
import AddExecutorButton from './add-executor-button';
import {useTranslation} from 'react-i18next';

interface IExecutorPopupProps {
  subjects: FITrackerSubject[];
  onChange: (data: FITrackerSubject[]) => void;
  executorType: TTrackerExecutorType;
  against: boolean;
}

function ExecutorPopupContent(props: IExecutorPopupProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Handlers
   */
  function _onSubjectChange(subject: FITrackerSubject, subjectIndex: number) {
    const _newSubjects = [...props.subjects];
    _newSubjects[subjectIndex] = subject;
    props.onChange(_newSubjects);
  }

  function _onSubjectDelete(subjectIndex: number) {
    const _newSubjects = [...props.subjects];
    _newSubjects.splice(subjectIndex, 1);
    props.onChange(_newSubjects);
  }

  /*
   * Render
   */
  let _Content = (
    <List>
      {props.subjects.map((subject, index) => {
        return (
          <ExecutorListItem
            key={index}
            subject={subject}
            subjectIndex={index}
            onChange={_onSubjectChange}
            executorType={props.executorType}
            onDelete={_onSubjectDelete}
            against={props.against}
          />
        );
      })}
    </List>
  );

  if (!props.subjects.length) {
    _Content = (
      <Stack p={2}>
        <Typography color="text.secondary">
          {t('sentenceForm.executor.empty')}
        </Typography>
      </Stack>
    );
  }
  return (
    <Stack gap={1} alignItems="flex-start">
      {_Content}
      <Stack px={2} pb={2}>
        <AddExecutorButton
          subjects={props.subjects}
          onChange={props.onChange}
        />
      </Stack>
    </Stack>
  );
}

export default ExecutorPopupContent;
