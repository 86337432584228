import {ISentenceConditionProps} from 'types/trackers/tracker-sentence.types';

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import SentenceTextSegment from '../segment/variants/text/sentence-text-segment';
import SentenceBlock from '../blocks/sentence-block';

import TeamSelectSegment from '../segment/instances/team/team-select-segment';
import {
  FIConditionalEventFilters,
  FIEventCondition,
  FITrackerAbstractExecutor,
} from '@my-game-plan/types';
import SentenceNumberSegment from '../segment/variants/number/sentence-number-segment';
import DetailsSegment from '../segment/instances/details/details-segment';
import ActionSegment from '../segment/instances/action/action-segment';
import ConditionExecutorSegment from '../segment/instances/condition-executor/condition-executor-segment';

function SentenceConditionBaseLine(
  props: ISentenceConditionProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_shouldDisplayTeam, _setShouldDisplayTeam] = useState(false);

  /*
   * Side effects
   */
  useEffect(() => {
    _setShouldDisplayTeam(props.condition.executor?.player_id === undefined);
  }, [props.condition]);

  /*
   * Handlers
   */

  function _onChange(data: Partial<FIEventCondition>) {
    if (!props.onChange) {
      return;
    }

    props.onChange(props.conditionIndex, data);
  }

  function _onSecondsChange(seconds: number) {
    _onChange({seconds: seconds});
  }

  function _onDetailsChange(data?: FIConditionalEventFilters) {
    _onChange({details: data});
  }

  function _onObserverChange(executor: FITrackerAbstractExecutor) {
    _onChange({executor: executor});
  }

  /*
   * Render
   */
  const {onChange, onDelete, condition, errors, action, ..._commonProps} =
    props;

  /* Prefix: "When" or "Within [X] seconds before/after" */
  let _SentencePrefixSegment = (
    <SentenceTextSegment
      {..._commonProps}
      value={t('sentenceForm.conditions.when')}
      capitalize={condition.type === 'rule'}
    />
  );

  if (condition.type === 'after' || condition.type === 'before') {
    _SentencePrefixSegment = (
      <>
        <SentenceTextSegment
          {..._commonProps}
          value={t('sentenceForm.conditions.within')}
        />
        <SentenceNumberSegment
          {..._commonProps}
          value={condition.seconds}
          onChange={_onSecondsChange}
        />
        <SentenceTextSegment
          {..._commonProps}
          value={t('sentenceForm.conditions.seconds', {
            count: props.condition.seconds || 0,
          })}
        />

        <SentenceTextSegment
          {..._commonProps}
          value={t(`sentenceForm.conditions.${condition.type}`)}
        />
      </>
    );
  }

  if (condition.type === 'when') {
    return (
      <SentenceBlock.Line {..._commonProps}>
        {_SentencePrefixSegment}
        <DetailsSegment
          {..._commonProps}
          action={props.tracker?.action}
          value={condition.details}
          isGameStateCondition
          placeholder={t('sentenceForm.conditions.gameStates.placeholder')}
          required
          onChange={_onDetailsChange}
          error={props.errors?.details}
          against={props.condition.against}
        />
      </SentenceBlock.Line>
    );
  }

  /* Executor */
  const _ExecutorSegment = (
    // <SentenceTextSegment {..._commonProps} value={'any player'} type="value" />
    <ConditionExecutorSegment
      {..._commonProps}
      value={condition.executor}
      against={condition.against}
      onChange={_onObserverChange}
    />
  );

  /* "of team" segment */
  let _OfTeamSegment = null;
  if (_shouldDisplayTeam) {
    _OfTeamSegment = (
      <>
        <SentenceTextSegment {..._commonProps} value={t('sentenceForm.of')} />
        <TeamSelectSegment
          {..._commonProps}
          value={props.condition}
          onChange={_onChange}
        />
      </>
    );
  }

  /* Verb + Action */
  const _VerbActionSegment = (
    <ActionSegment
      {..._commonProps}
      value={props.condition}
      onChange={_onChange}
      verb="third"
      includeTrackingActions
      error={errors?.action || errors?.pitch_zone}
    />
  );

  /*
   * Handlers
   */
  return (
    <SentenceBlock.Line {..._commonProps}>
      {_SentencePrefixSegment}
      {_ExecutorSegment}
      {_OfTeamSegment}
      {_VerbActionSegment}
    </SentenceBlock.Line>
  );
}

export default SentenceConditionBaseLine;
