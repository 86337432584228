import React, {useEffect, useState} from 'react';
import ScoutingProfilePreview from 'components/scouting/profile/scouting-profile-preview.view';
import Screen from 'components/screen/screen.view';
import {useScouting} from 'context/scouting.context';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import SearchAsyncAutocomplete from 'components/scouting/input/search-async-autocomplete.view';
import SearchDialog from 'components/scouting/dialogs/create-scouting-profile-dialog.view';
import {LOADING_STATE} from 'types/screen.types';
import CustomEmptyState from 'components/error-states/CustomEmptyState';
import {useAnalytics} from 'context/analytics.context';
import ANALYTICS_EVENT from 'config/analytics/event-names.config';

function ScoutingView() {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _scoutingContext = useScouting();
  const _analyticsContext = useAnalytics();
  const [_isSearchDialogOpen, _setIsSearchDialogOpen] =
    useState<boolean>(false);

  /*
   * Side effects
   */
  useEffect(() => {
    _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_SCOUTING_OVERVIEW);
    _scoutingContext.getScoutingProfiles();
  }, []);

  /*
   * Handlers
   */
  function _onSearchClick() {
    _setIsSearchDialogOpen(true);
  }

  function _onSearchDialogClose() {
    _setIsSearchDialogOpen(false);
  }

  /*
   * Render
   */

  const _CreateProfileButton = (
    <Button
      color="primary"
      variant="contained"
      // size="large"
      onClick={_onSearchClick}
      startIcon={<SavedSearchIcon />}>
      {t('scouting.profile.create.title')}
    </Button>
  );
  const _HeaderRightContent = (
    <Stack direction="row" spacing={1} alignItems="center">
      <SearchAsyncAutocomplete label={t('scouting.searchForm.name')} />
      {_CreateProfileButton}
    </Stack>
  );

  let _Content = null;
  if (_scoutingContext.profiles.length) {
    _Content = (
      <Stack gap={8}>
        {_scoutingContext.profiles.map((profile) => {
          return (
            <ScoutingProfilePreview
              key={profile._id}
              scoutingProfile={profile}
            />
          );
        })}
      </Stack>
    );
  } else if (_scoutingContext.profilesLoadingState === LOADING_STATE.ERROR) {
    _Content = (
      <CustomEmptyState
        header={t('error-states.not-found.header')}
        description={t('error-states.not-found.description')}
        imageType="error"
      />
    );
  } else {
    _Content = (
      <CustomEmptyState
        header={t('scouting.empty.default.header')}
        description={t('scouting.empty.default.description')}
        imageType="default"
        primaryAction={_CreateProfileButton}
      />
    );
  }

  return (
    <Screen
      coloredHeader
      title={t('scouting.title')}
      loadingState={_scoutingContext.profilesLoadingState}
      HeaderRightContent={_HeaderRightContent}>
      {_Content}

      <SearchDialog open={_isSearchDialogOpen} onClose={_onSearchDialogClose} />
    </Screen>
  );
}

export default ScoutingView;
