import React, {useEffect, useState} from 'react';

// import OverviewScreen from 'components/overview-screen/groups/overview-groups-screen.view';
import {
  FIEventAutomationPostData,
  FIFormattedAutomation,
  FIPlayerWithData,
  TTrackerExecutorType,
} from '@my-game-plan/types';
import {useTrackers} from 'context/event-automations/tracker.context';
import OverviewScreen, {
  IOverviewScreenView,
} from 'components/overview-screen/overview-screen.view';
import {defaultExecutorForTeam} from 'config/trackers.config';

interface ITrackersViewProps {
  title?: string;
  executorType: TTrackerExecutorType;
}

function TrackersView(props: ITrackersViewProps): JSX.Element {
  /*
   * Hooks n State
   */
  const _trackerContext = useTrackers();
  const [_defaultTrackerData, _setDefaultTrackerData] = useState<
    Partial<FIEventAutomationPostData>
  >({});

  /*
   * Side effects
   */
  useEffect(() => {
    if (props.executorType === 'player_development') {
      _setDefaultTrackerData({
        player_development: [],
      });

      return;
    }

    const _defaultTeamExecutor = defaultExecutorForTeam();
    _setDefaultTrackerData({
      team_performance: [_defaultTeamExecutor],
    });
  }, [props.executorType]);

  /*
   * Handlers
   */

  /*
   * Render
   */

  const _tableView: IOverviewScreenView<FIFormattedAutomation> = {
    loadingState: _trackerContext.getAllLoadingState,
    type: 'table',
    data: _trackerContext.trackers,
    availableFilterOptions: {
      tags: [],
      actions: [],
      players: [],
    },
    getData: _trackerContext.getAll,
  };
  const _cardView: IOverviewScreenView<FIPlayerWithData> = {
    loadingState: _trackerContext.getAllPerPlayerLoadingState,
    type: 'cards',
    data: _trackerContext.players,
    availableFilterOptions: {
      tags: [],
      actions: [],
      players: [],
    },
    getData: _trackerContext.getAllPerPlayer,
  };

  return (
    <OverviewScreen
      title={props.title}
      executorType={props.executorType}
      views={[_cardView, _tableView]}
      filters={_trackerContext.filters}
      onFiltersChange={_trackerContext.setFilters}
      canSelectPlayers
      newTrackerData={_defaultTrackerData}
    />
  );
}

export default TrackersView;
