import {
  FITrackerAbstractExecutor,
  FITrackerSharedSubjects,
} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';
import TrackerSentencePopup from '../../variants/popup/sentence-popup-segment';
import {useTranslation} from 'react-i18next';
import {IConditionExecutorSegmentProps} from 'types/trackers/tracker-sentence.types';
import {getSelectedPositionOptions} from 'helpers/automation/automation-form.helper';
import {useTeams} from 'context/team.context';
import TeamPositionPlayerPicker from 'components/trackers/misc/team-position-player-picker/team-position-player-picker';
import SentenceTextSegment from '../../variants/text/sentence-text-segment';

function ConditionExecutorSegment(
  props: IConditionExecutorSegmentProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const [_popupAnchorEl, _setPopupAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  const [_teamPlayerPositionValue, _setTeamPlayerPositionValue] = useState<
    FITrackerSharedSubjects | undefined
  >(undefined);

  /*
   * Side effects
   */
  // Set value to pass to popup
  useEffect(() => {
    let _newTeamPlayerPositionValue: FITrackerSharedSubjects | undefined;
    if (props.value?.team?.executor_positions) {
      _newTeamPlayerPositionValue = {
        player_positions: props.value.team.executor_positions,
      };
    } else if (typeof props.value?.player_id !== 'undefined') {
      _newTeamPlayerPositionValue = {
        player_ids: [props.value.player_id],
      };
    } else if (props.value?.team) {
      _newTeamPlayerPositionValue = {
        team: true,
      };
    }

    _setTeamPlayerPositionValue(_newTeamPlayerPositionValue);
  }, [props.value]);

  /*
   * Handlers
   */
  function _onOpenPopup(anchorEl: HTMLElement) {
    _setPopupAnchorEl(anchorEl);
  }

  function _onClosePopup() {
    _setPopupAnchorEl(null);
  }

  function _valueFormatter(value: FITrackerAbstractExecutor): string {
    let _formattedValue = t('sentenceForm.observing.anyPlayer');

    if (value.team?.executor_positions?.length) {
      const _selectPositions = getSelectedPositionOptions(
        value.team.executor_positions,
        false,
        true,
        false,
        true,
      );
      _formattedValue = _selectPositions.join(', ');
    } else if (value.player_id) {
      const _matchingPlayer = _teamsContext.ownPlayers.find(
        (p) => p._id === value.player_id,
      );
      if (_matchingPlayer) {
        _formattedValue = _matchingPlayer.display_name;
      } else {
        _formattedValue = t('playerPosition.transferredPlayer');
      }
    }

    return _formattedValue;
  }

  function _onConditionExecutorChange(value?: FITrackerSharedSubjects) {
    if (!props.onChange || !value) {
      return;
    }
    //  Format FITrackerSharedSubjects to FITrackerAbstractExecutor
    const _formattedExecutor: FITrackerAbstractExecutor = {};
    if (value.team) {
      _formattedExecutor.team = {};
    } else if (value.player_positions) {
      _formattedExecutor.team = {
        executor_positions: value.player_positions,
      };
    } else if (value.player_ids) {
      _formattedExecutor.player_id = value.player_ids[0] || '';
    }

    // console.log(
    //   '[ConditionExecutorSegment] _onConditionExecutorChange',
    //   _formattedExecutor,
    // );
    props.onChange(_formattedExecutor);
  }

  function _isEmptyValue(value?: FITrackerAbstractExecutor): boolean {
    return !value?.team && !value?.player_id;
  }

  /*
   * Render
   */

  const {onChange, ..._commonProps} = props;
  if (!props.currentExecutorType) {
    const _formattedValue = props.value
      ? _valueFormatter(props.value)
      : t('sentenceForm.observing.anyPlayer');
    return (
      <SentenceTextSegment
        {..._commonProps}
        value={_formattedValue}
        type="value"
      />
    );
  }

  return (
    <>
      <TrackerSentencePopup.Button
        {...props}
        onOpen={_onOpenPopup}
        isPopupOpen={Boolean(_popupAnchorEl)}
        placeholder={t('sentenceForm.observing.anyPlayer')}
        value={props.value}
        isEmptyValue={_isEmptyValue}
        valueFormatter={_valueFormatter}
      />
      <TrackerSentencePopup.Popup
        {...props}
        anchorEl={_popupAnchorEl}
        onClose={_onClosePopup}>
        <TeamPositionPlayerPicker
          onChange={_onConditionExecutorChange}
          value={_teamPlayerPositionValue}
          currentExecutorType={props.currentExecutorType}
        />
      </TrackerSentencePopup.Popup>
    </>
  );
}

export default ConditionExecutorSegment;
