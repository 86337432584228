import React from 'react';
import {MatchScore} from '../../../common/match-score/MatchScore';
import ListItemText from '@mui/material/ListItemText';

import {useMatches} from 'context/matches.context';

type FilterMatchOptionProps = {
  value: string;
  label: string;
};

export const FilterMatchOption = ({value, label}: FilterMatchOptionProps) => {
  const _matches = useMatches();
  const match = _matches.all.find((match) => match._id.toString() === value);

  if (match === undefined) return <></>;

  return (
    // <ListItemText>
    //   <div className={styles.filterOptionContainer}>
    //     <MatchScore match={match} />
    //     <span className={styles.filterOptionLabel}>{label}</span>
    //   </div>
    // </ListItemText>
    <ListItemText
      primary={<MatchScore match={match} />}
      secondary={label}
      sx={{display: 'flex', gap: 2, justifyContent: 'space-between'}}
    />
  );
};
