import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {useConfirm} from 'material-ui-confirm';
import {DELETE_MODAL_CONFIG} from 'config/modal.config';

import styles from './modal-delete-button.module.scss';

interface ModalDeleteButtonProps {
  title: string;
  description: string;
  onConfirm: () => void;
  isLoading?: boolean;
}

export function ModalDeleteButton(props: ModalDeleteButtonProps): JSX.Element {
  /*
   * Hooks n State
   */
  const _confirm = useConfirm();

  /*
   * Handlers
   */
  async function _onClick() {
    try {
      await _confirm({
        ...DELETE_MODAL_CONFIG,
        title: props.title,
        description: props.description,
      });
      props.onConfirm();
    } catch (error) {
      // User cancelled
    }
  }

  /*
   * Render
   */

  return (
    <div className={styles.container}>
      <IconButton color="secondary" title={props.title} onClick={_onClick}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
}
