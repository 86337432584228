import React from 'react';
import {useParams} from 'react-router-dom';

import AutomationDetail from 'components/trackers/deep-dive/automation-detail.view';

// import {Tab, Tabs} from '@mui/material';

function ShadowTrackerDetailView() {
  /* Hooks n State */
  const _params = useParams();

  /* Render */
  return (
    <AutomationDetail
      id={_params.id}
      executorType="scouting_profile_ids"
      subjectId={_params.player_id}
    />
  );
}

export default ShadowTrackerDetailView;
