import {
  Dimensions,
  IRect,
  XYDimensions,
} from 'types/dashboard/dashboard-pitch-visual.types';
import {
  BEFORE_STRIKERS,
  BEHIND_DEFENSIVE_LINE,
  BETWEEN_DEFENSE_AND_MIDFIELD,
  BETWEEN_MIDFIELD_AND_ATTACK,
  FCarry,
  FIMatchEvent,
  FPass,
  FRun,
  TBox,
  TPolygon,
} from '@my-game-plan/types';

function flattenPosition(value: number): number {
  let _flattenedValue = value;
  if (_flattenedValue < 1.5) {
    _flattenedValue = 1.5;
  } else if (_flattenedValue > 98.5) {
    _flattenedValue = 98.5;
  }

  return _flattenedValue;
}

function _pos(total: number, value: number): number {
  return Math.round(total * (value / 100));
}

export function calculatePosition(
  event: FIMatchEvent,
  dimensions: Dimensions,
  isHalf?: boolean,
  isOpponentAction?: boolean,
  shouldDisplayOnOwnHalf?: boolean,
): XYDimensions {
  const _startX = flattenPosition(
    isOpponentAction ? event.coordinates[0] : 100 - event.coordinates[0],
  );
  let _startY = flattenPosition(
    isOpponentAction ? 100 - event.coordinates[1] : event.coordinates[1],
  );

  let _endX = null;
  let _endY = null;

  const eventTypeFields = (event.pass || event.run || event.carry) as
    | FPass
    | FRun
    | FCarry;

  if (typeof eventTypeFields?.end_coordinates?.[0] !== 'undefined') {
    _endX = flattenPosition(
      (isOpponentAction
        ? eventTypeFields.end_coordinates[0]
        : 100 - eventTypeFields.end_coordinates[0]) || 0,
    );
  }

  if (typeof eventTypeFields?.end_coordinates?.[1] !== 'undefined') {
    _endY = flattenPosition(
      (isOpponentAction
        ? 100 - eventTypeFields.end_coordinates[1]
        : eventTypeFields.end_coordinates[1]) || 0,
    );
  }

  let _heightDimension = dimensions.height;
  if (isHalf) {
    _heightDimension = dimensions.height * 2;

    if (!isOpponentAction && !shouldDisplayOnOwnHalf) {
      if (_startY !== null) _startY -= 50;
      if (_endY !== null) _endY -= 50;
    }
  }

  const _position = {
    startX: _pos(dimensions.width, _startX),
    endX: _endX === null ? null : _pos(dimensions.width, _endX),
    startY: _pos(_heightDimension, _startY),
    endY: _endY === null ? null : _pos(_heightDimension, _endY),
  };

  return _position;
}

const SIDE_OFFSET = 40;
const OFFSET_LEFT = SIDE_OFFSET; //x1
const OFFSET_RIGHT = 100 - SIDE_OFFSET; //x2
const OFFSET_BOTTOM = 0; //x1
const OFFSET_TOP = 80; //x2
const START_LEFT = 0; //y1
const START_RIGHT = 100; //y2
export function calculateFinishingPosition(
  event: FIMatchEvent,
  dimensions: Dimensions,
  // isHalf?: boolean,
  // isOpponentAction?: boolean,
): XYDimensions {
  let _endY = null;
  let _endZ = null;
  if (typeof event.shot?.shot_coordinate_y !== 'undefined') {
    _endY =
      ((event.shot.shot_coordinate_y - OFFSET_LEFT) /
        (OFFSET_RIGHT - OFFSET_LEFT)) *
      (START_RIGHT - START_LEFT);
  }

  const _z = event.shot?.shot_coordinate_z || 1;
  if (typeof event.shot?.shot_coordinate_z !== 'undefined') {
    _endZ =
      ((_z - OFFSET_BOTTOM) / (OFFSET_TOP - OFFSET_BOTTOM)) *
      (START_RIGHT - START_LEFT);
  }

  const _position = {
    startX: 0,
    endX: _endY === null ? null : _pos(dimensions.width, 100 - _endY),
    startY: 0,
    endY: _endZ === null ? null : _pos(dimensions.height, 100 - _endZ),
  };

  return _position;
}

export function calculateBoxDimensions(
  box: TBox,
  dimensions: Dimensions,
  isObservingOpponent?: boolean,
): IRect {
  const [[_x1, _y1], [_x2, _y2]] = box;

  let _adjustedY1 = 100 - _y1;
  let _adjustedY2 = 100 - _y2;
  let _adjustedX1 = 100 - _x1;
  let _adjustedX2 = 100 - _x2;
  if (isObservingOpponent) {
    _adjustedX1 = _x1;
    _adjustedX2 = _x2;
    _adjustedY1 = _y1;
    _adjustedY2 = _y2;
  }

  const _startX = _pos(dimensions.width, _adjustedX1);
  const _endX = _pos(dimensions.width, _adjustedX2);
  const _startY = _pos(dimensions.height, _adjustedY1);
  const _endY = _pos(dimensions.height, _adjustedY2);

  const _rect: IRect = {
    x: _startX,
    y: _startY,
    width: _endX - _startX,
    height: _endY - _startY,
  };

  // console.log(`---> [[${_startX}, ${_startY}], [${_endX}, ${_endY}]]`);

  return _rect;
}
export function calculatePolygonDimensions(
  polygon: TPolygon, // Array of points defining the polygon
  dimensions: Dimensions,
  isObservingOpponent?: boolean,
): TPolygon {
  // Map each point in the polygon to its adjusted position
  return polygon.map(([x, y]) => {
    const adjustedX = 100 - x; // Adjust x for mirroring
    const adjustedY = isObservingOpponent ? y : 100 - y; // Adjust y based on observation

    // Scale the adjusted coordinates to fit within the specified dimensions
    const scaledX = _pos(dimensions.width, adjustedX);
    const scaledY = _pos(dimensions.height, adjustedY);

    return [scaledX, scaledY];
  });
}

export function calculateRelativeZoneDimensions(
  zone_id: string,
  dimensions: Dimensions,
  isObservingOpponent?: boolean,
): IRect {
  const nameToBoxMapping: Record<string, number[][]> = {
    [BEHIND_DEFENSIVE_LINE]: [
      [0, 0],
      [100, 23],
    ],
    [BETWEEN_DEFENSE_AND_MIDFIELD]: [
      [0, 27],
      [100, 53],
    ],
    [BETWEEN_MIDFIELD_AND_ATTACK]: [
      [0, 57],
      [100, 78],
    ],
    [BEFORE_STRIKERS]: [
      [0, 82],
      [100, 100],
    ],
  };

  const [[_x1, _y1], [_x2, _y2]] =
    nameToBoxMapping[zone_id as keyof typeof nameToBoxMapping];

  let _adjustedY1 = 100 - _y1;
  let _adjustedY2 = 100 - _y2;
  let _adjustedX1 = 100 - _x1;
  let _adjustedX2 = 100 - _x2;
  if (isObservingOpponent) {
    _adjustedX1 = _x1;
    _adjustedX2 = _x2;
    _adjustedY1 = _y1;
    _adjustedY2 = _y2;
  }

  const _startX = _pos(dimensions.width, _adjustedX1);
  const _endX = _pos(dimensions.width, _adjustedX2);
  const _startY = _pos(dimensions.height, _adjustedY1);
  const _endY = _pos(dimensions.height, _adjustedY2);

  const _rect: IRect = {
    x: _startX,
    y: _startY,
    width: _endX - _startX,
    height: _endY - _startY,
  };

  // console.log(`---> [[${_startX}, ${_startY}], [${_endX}, ${_endY}]]`);

  return _rect;
}
export function calculatePoint(
  x: number,
  y: number,
  dimensions: Dimensions,
  isObservingOpponent = false,
) {
  const adjustedX = 100 - x; // Adjust x for mirroring
  const adjustedY = isObservingOpponent ? y : 100 - y; // Adjust y based on observation
  const scaledX = _pos(dimensions.width, adjustedX);
  const scaledY = _pos(dimensions.height, adjustedY);

  return [scaledX, scaledY];
}
